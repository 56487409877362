// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Select } from 'antd';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormValues,
} from 'components/form/form-wrapper';

const FIELD_TYPE = 'role';

interface Props {
  config: FormFieldConfig;
  field: FormField;
  originalState: FormValues[];
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    values: Record<string, string | number>[] | [],
    config: FormFieldConfig,
    column?: string,
  ): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  isDisabled?: boolean;
  border?: boolean;
};

interface State {};

class RoleField extends React.Component<Props, State> {
  state: State = {};

  componentDidMount = () => {
    const { field } = this.props;
    this.validate(field.values);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(field.values);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (values: any, shouldClear = false) => {
    const { field, config, originalState, setFieldModifiedMessage } = this.props;
    const key = `${field.id}_${config.fieldIndex || 0}_${FIELD_TYPE}`;

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const isModified: boolean = !shouldClear && !_.isEqual(values, originalState);
    const modifiedMessage = isModified ? { ...generalMessageInfo, modified: {} } : undefined;

    setFieldModifiedMessage(key, modifiedMessage);
  };

  renderRoles = () => {
    const { field, config, fieldModifiedMessages, isDisabled, onChange } = this.props;

    const key = `${field.id}_${config.fieldIndex || 0}_${FIELD_TYPE}`;

    const isModified = _.has(fieldModifiedMessages, key);
    const readOnly: boolean = !!isDisabled;

    const onChangeHandler = (recordIds: Array<string | number>) => {
      let newValues = [];

      // Select all logic
      if (recordIds.includes('all') && field.resources?.length) {
        newValues = field.resources.map((role: any) => ({ role_id: role.id }));
      } else {
        newValues = recordIds.map(roleId => ({ role_id: roleId }));
      }

      onChange(field, newValues, config);
    };

    return (
      <Select
        showSearch
        mode='multiple'
        allowClear={ !readOnly }
        disabled={ isDisabled }
        open={ (readOnly && _.isEmpty(field.resources)) ? false : undefined }
        dropdownMatchSelectWidth={ false }
        maxTagCount={ 1 }
        maxTagTextLength={ field.values?.length === 1 ? 25 : 15 }
        placeholder={ '-' }
        className={ classNames('Select-Field', {
          'Select-Field--has-warning border-warning': isModified,
        }) }
        filterOption={(input: any, option: any) => {
          return !!field.resources?.find((record: any) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
        } }
        value={ field.values?.map(value => value.role_id) }
        onChange={ !readOnly ? onChangeHandler : undefined }
      >
        { !_.isEmpty(field.resources) &&
          <Select.Option key={ 'all' } value={ 'all' }>{ 'Select all' }</Select.Option>
        }
        { field.resources?.map( (role: any) => (
          <Select.Option key={ role.id } value={ role.id }>{ role.title }</Select.Option>
        )) }
      </Select>
    );
  };

  render = () => {
    const { field, config, border } = this.props;

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        description={ !!field.description && field.description }
        required={ field.config.required }
        border={ border }
      >
        { this.renderRoles() }
      </FieldWrapper>
    );
  };
};

export default RoleField;
