// Libs
import * as React from 'react';

// Components
import RecordView from 'views/common/RecordView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  setRecord: (record: RecordFormEntity) => void;
};

class RentReviewRecord extends React.Component<Props> {
  render = () => {
    return (
      <RecordView
        verboseTabs
        id={ this.props.record.id }
        entity={ 'transaction' }
        type={ 'rent-review' }
        record={ this.props.record }
        setRecord={ this.props.setRecord }
      />
    );
  };
};

export default RentReviewRecord;
