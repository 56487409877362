// Libs
import React, { BaseSyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Components
import { Modal, Input, Form, Select } from 'antd';
import Jumbotron from "components/jumbotron";
import BasicList from 'components/basic-list';
import { EditOutlined, TeamOutlined, DeleteOutlined } from '@ant-design/icons';
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import Badge, { getBadgeType } from 'components/badge';
import Dropdown from 'components/dropdown';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { RoleRecord, RoleStatus, RoleTypes } from './Role.interfaces';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";

const { TextArea } = Input;

interface GroupRecord {
  id: number;
  title: string;
  description: string;
  users: Array<any>;
};

interface PlaceholderRole {
  title?: string;
  description?: string;
  type?: RoleTypes | string;
  group_id?: number;
};

interface Props {
  intl: IntlShape;
  user: UserEntity;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
}

interface State {
  roles: RoleRecord[];
  groups: GroupRecord[];
  isLoading: boolean;
  activeRoleId: number | null;
  newRolePlaceholder: PlaceholderRole;
  editRolePlaceholder: PlaceholderRole;
  isEditing: boolean;
  showAddDialog: boolean;
  showDeleteDialog: boolean;
  showEditDialog: boolean;
  isFetchingRole: boolean;
};

const API: Api = new Api();

class Roles extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    roles: [],
    groups: [],
    activeRoleId: null,
    newRolePlaceholder: {},
    editRolePlaceholder: {},
    isEditing: false,
    isLoading: false,
    showAddDialog: false,
    showEditDialog: false,
    showDeleteDialog: false,
    isFetchingRole: false,
  };

  componentDidMount = async () => {
    const { user: { active_client }, setBreadcrumbs } = this.props;

    this.mounted = true;
    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Roles', path: '/admin/roles' },
    ], false);

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const roles = await API.getRoles(active_client);
      const groups = await API.get(`client/${active_client}/admin/groups`);

      this.mounted && this.setState({
        roles: roles,
        groups: groups
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevState.isEditing !== this.state.isEditing) {

      const currentRole: any = this.state.roles.find((role: RoleRecord) => role.id === this.state.activeRoleId);

      this.mounted && currentRole && this.setState({
        editRolePlaceholder: {
          title: currentRole.title,
          description: currentRole.description,
          type: currentRole.type,
          group_id: currentRole.group_id,
        }
      });

    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderRoles = () => {
    const { roles } = this.state;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Title',
          render: (title: any, record: any) => {
            return <><TeamOutlined className="mR-10" style={{ fontSize: 18 }} /><Link to={`/admin/roles/${record.id}`} style={{ 'color': '#2196f3' }}>{title}</Link></>;
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'type',
          dataIndex: 'type',
          title: 'Type',
          render: (type: RoleTypes) => {
            const formattedType = _.startCase(_.toLower(type)).split('_').join(' ');
            return <Badge type={ getBadgeType(formattedType || '') } text={ formattedType } />;
          },
          sorter: true,
          ellipsis: true,
          filterable: true,
        },
        {
          key: 'status',
          dataIndex: 'status',
          title: 'Status',
          render: (status: RoleStatus) => {
            return <Badge type={ getBadgeType(status || '') } text={ _.startCase(_.toLower(status)).split('_').join(' ') } />;
          },
          sorter: true,
          ellipsis: true,
          filterable: true,
        },
        {
          key: 'users',
          dataIndex: 'users',
          title: 'Global Users',
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
        {
          key: 'permissions',
          dataIndex: 'permissions',
          title: 'Permissions',
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
        {
          key: 'actions',
          dataIndex: 'actions',
          title: '',
          render: (_: any, role: any) => {
            return (
              <>
                <EditOutlined
                  className="link"
                  style={{ fontSize: 18 }}
                  onClick={ () => this.setState({
                    showEditDialog: true,
                    isEditing: true,
                    activeRoleId: role.id
                  })}
                />
                <DeleteOutlined
                  className="mL-20 link"
                  style={{ fontSize: 18 }}
                  onClick={ () => this.setState({
                    showDeleteDialog: true,
                    activeRoleId: role.id
                  })}
                />
              </>
            );
          },
          width: 100,
          sorter: false,
          ellipsis: true,
          filterable: false,
          align: 'center'
        },
      ];
    };

    const mapData = (roles: any) => {
      return roles.map((role: any) => {
        const permissionCount = role.permissions.reduce((previousValue: any, permission: any) => {
          return previousValue + permission.permissions.length || 0;
        }, 0);

        return {
          'key': role.id,
          'id': role.id,
          'title': role.title,
          'description': role.description,
          'type': role.type,
          'status': role.status,
          'users': role.users.length,
          'permissions': permissionCount
        };
      });
    };

    return (
      <BasicList
        rawData
        columns={ mapColumns() }
        items={ mapData(roles) }
      />
    );
  };

  renderAddDialog = () => {
    const { user: { active_client } } = this.props;
    const { newRolePlaceholder, isFetchingRole } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Create Role' }
        maskClosable={ !isFetchingRole }
        okText={ 'Create' }
        onOk={() => this.mounted && this.setState({
            isFetchingRole: true,
          }, async () => {
            try {
              const roles = await API.post(`client/${active_client}/admin/roles`, {
                data: newRolePlaceholder
              });

              this.mounted && this.setState({
                roles: roles,
                showAddDialog: false,
                newRolePlaceholder: {}
              }, () => {
                Notification('success', '', 'Role created');
              });
            } catch (error) {
              Notification('error', '', error.data);
            } finally {
              this.mounted && this.setState({
                isFetchingRole: false
              });
            }
          }
        ) }
        onCancel={() => this.setState({
          showAddDialog: false,
          newRolePlaceholder: {}
        }) }
        cancelButtonProps={{
          disabled: isFetchingRole
        }}
        okButtonProps={{
          disabled: !newRolePlaceholder?.title || !newRolePlaceholder?.description || !newRolePlaceholder?.type,
          loading: isFetchingRole,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Title" required>
            <Input
              value={ newRolePlaceholder?.title || '' }
              onChange={(event: BaseSyntheticEvent) => {
                this.setState({
                  newRolePlaceholder: Object.assign(newRolePlaceholder, { title: event.target.value })
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Description" required>
            <TextArea
              rows={4}
              onChange={(event: BaseSyntheticEvent) => {
                this.setState({
                  newRolePlaceholder: Object.assign(newRolePlaceholder, { description: event.target.value })
                });
              } }
              value={ newRolePlaceholder?.description }
            />
          </Form.Item>
          <Form.Item label="Type" required>
            <Select
              onChange={(type: any) => {
                this.setState({
                  newRolePlaceholder: Object.assign(newRolePlaceholder, { type: type })
                });
              }}
            >
              <Select.Option value="INTERNAL">Internal</Select.Option>
              <Select.Option value="EXTERNAL">External</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderEditDialog = (active_role_id: any) => {
    const { user: { active_client } } = this.props;
    const { editRolePlaceholder, isFetchingRole, groups } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Edit Role' }
        maskClosable={ !isFetchingRole }
        okText={ 'Edit' }
        onOk={() => this.mounted && this.setState({
            isFetchingRole: true,
          }, async () => {
            try {
              const roles = await API.put(`client/${active_client}/admin/roles/${active_role_id}`, {
                data: !!editRolePlaceholder.group_id ? editRolePlaceholder : { ...editRolePlaceholder, group_id: '' }
              });

              this.mounted && this.setState({
                roles: roles,
              }, () => {
                Notification('success', '', 'Role edited');
              });
            } catch (error) {
              Notification('error', '', 'Failed to edit role');
            } finally {
              this.mounted && this.setState({
                isFetchingRole: false,
                isEditing: false,
                showEditDialog: false,
                editRolePlaceholder: {},
              });
            }
          }
        ) }
        onCancel={() => this.setState({
          isEditing: false,
          showEditDialog: false,
          activeRoleId: null,
          editRolePlaceholder: {}
        }) }
        cancelButtonProps={{
          disabled: isFetchingRole
        }}
        okButtonProps={{
          disabled: !editRolePlaceholder?.title || editRolePlaceholder?.title === ' ' || !editRolePlaceholder.description || editRolePlaceholder.description === ' ',
          loading: isFetchingRole,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Title" required>
            <Input
              value={ editRolePlaceholder?.title }
              onChange={(event: BaseSyntheticEvent) => {
                this.setState({
                  editRolePlaceholder: Object.assign(editRolePlaceholder, { title: event.target.value })
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Description" required>
            <TextArea
              rows={4}
              onChange={(event: BaseSyntheticEvent) => {
                this.setState({
                  editRolePlaceholder: Object.assign(editRolePlaceholder, { description: event.target.value })
                });
              } }
              value={ editRolePlaceholder?.description }
            />
          </Form.Item>
          <Form.Item label="Type" required>
            <Select
              disabled
              value={ editRolePlaceholder?.type }
            >
              <Select.Option value="INTERNAL">Internal</Select.Option>
              <Select.Option value="EXTERNAL">External</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Selection Pool">
            <Select
              allowClear
              showSearch
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              onChange={ (groupId: number) => {
                this.setState({ editRolePlaceholder: Object.assign(editRolePlaceholder, { group_id: groupId }) });
              } }
              value={ editRolePlaceholder?.group_id }
            >
              { groups.map(group => (
                <Select.Option value={ group.id } key={ group.id }>{ group.title }</Select.Option>
              )) }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderDeleteDialog = (role_id: any) => {
    const { user: { active_client } } = this.props;
    const { roles, isFetchingRole } = this.state;

    // Current role
    const role: any = roles.find((role: RoleRecord) => role.id === role_id);

    if (!role) return;

    return (
      <Modal
        visible
        centered
        title={ 'Remove Role' }
        maskClosable={ !isFetchingRole }
        onOk={() => this.mounted && this.setState({
          isFetchingRole: true,
        }, () => {
            API.deleteRole(active_client, role_id)
            .then((roles) => {
              this.mounted && this.setState({
                roles: roles,
                showDeleteDialog: false,
              });
              Notification('success', '', 'Removed Role');
            })
            .catch((err) => {
              console.error(err);
              Notification('error', '', 'Failed to remove role');
            })
            .finally(() => this.mounted && this.setState({
              isFetchingRole: false,
            }));
          }
        )}
        okText={ 'Remove' }
        onCancel={() => this.setState({ showDeleteDialog: false, activeRoleId: null }) }
        okButtonProps={{
          danger: true,
          disabled: isFetchingRole,
          loading: isFetchingRole,
        }}
      >
        <p>Are you sure you want to remove <b>{ role.title || '' }</b>?</p>
      </Modal>
    );
  };

  render = () => {
    const {
      isLoading,
      activeRoleId,
      showDeleteDialog,
      showEditDialog,
      showAddDialog,
    } = this.state;
    return (
      <BlockingSpinner isLoading={isLoading}>
        <Jumbotron
          content={ 'Roles' }
          tabs={ [
            {
              label: '',
              node: this.renderRoles(),
            }
          ] }
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Create Role',
                      onClick: () => this.setState({ showAddDialog: true })
                    }
                  ] }
                />
              )
            }
          ] }
        />
        { showDeleteDialog && this.renderDeleteDialog(activeRoleId) }
        { showEditDialog && this.renderEditDialog(activeRoleId) }
        { showAddDialog &&  this.renderAddDialog() }
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    user: store.UserState.user,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(Roles), 'access_admin_roles'));