// Libs
import React, { BaseSyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import BasicList, { Action } from 'components/basic-list';
import Jumbotron from 'components/jumbotron';
import { RestrictionHoC } from 'components/restriction';
import { Modal, Form, Tooltip, InputNumber, Input, Select, Button } from 'antd';

// Services
import { Api } from 'services/api';
import { getFormatedDate } from 'services/settings';
import Notification from 'services/notification';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';

// Icons
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';
import { Tool } from 'views/admin/notifications/Notifications.interfaces';
import { Category, Configuration, ContentRecord, SelectList, SliderRange } from './Content.interfaces';

// Utils
import history from 'utils/history';
import { isBlank } from 'utils/utils';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

export const CONTENT_TYPES = ['categories', 'select-lists', 'slider-ranges', 'configurations'];

interface Props {
  client_id: number;
  permissions: UserPermissions;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  record: ContentRecord[];
  tools: Tool[];
  activeOption: string | null;
  newSelectList: Partial<{ title: string, tool_id: number }> | null;
  newSliderRange: Partial<{ title: string, min: number, max: number, step: number, tool_id: number }> | null;
  isEditing: boolean;
  isLoading: boolean;
  isLoadingTools: boolean;
  isCreatingSelectList: boolean;
  isCreatingSliderRange: boolean;
  showAddSelectDialog: boolean;
  showAddSliderDialog: boolean;
};

class Content extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;
  formRef: any = React.createRef();

  state: State = {
    record: [],
    tools: [],
    activeOption: null,
    newSelectList: null,
    newSliderRange: null,
    isEditing: false,
    isLoading: false,
    isLoadingTools: false,
    isCreatingSelectList: false,
    isCreatingSliderRange: false,
    showAddSelectDialog: false,
    showAddSliderDialog: false,
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.match.params.content_type !== prevProps.match.params.content_type) {
      this.handleFetch();
    }
  };

  componentDidMount = () => {
    this.mounted = true;
    this.handleFetch();
    this.fetchTools();
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  handleFetch = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const content_type = this.props.match.params.content_type;

    if (content_type === 'configurations') return;

    try {

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Content Management', path: '/admin/content-manager' },
        { title: _.startCase(_.toLower(content_type)), path: null },
      ], false);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const record = await API.get(`client/${client_id}/admin/content-manager/${_.kebabCase(content_type)}`);

      this.mounted && this.setState({
        record: record,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  fetchTools = async () => {
    const { client_id } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isLoadingTools: true }, () => resolve(null)));
      const tools = await API.get(`client/${client_id}/admin/entity/tools`);
      this.setState({ tools: tools });
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.setState({ isLoadingTools: false });
    }
  };

  handleTabChange = (tab: string) => {
    history.push(_.kebabCase(tab.toLowerCase()));
  };

  renderEditSliderDialog = (record: SliderRange[], option_id: string) => {
    const { client_id } = this.props;
    const { isEditing } = this.state;
    const option = record.find((option: SliderRange) => option.id === option_id);
    return (
      <Modal
        visible
        centered
        title={ `Editing - ${option?.title}` }
        onOk={ () => {
          this.formRef
            .current
            .validateFields()
            .then( async (values: any) => {
              try {

                await new Promise((resolve) => this.setState({ isEditing: true }, () => resolve(null)));

                const record = await API.put(`client/${client_id}/admin/content-manager/slider-ranges/${_.kebabCase(option_id)}`, {
                  data: {
                    min: values.min,
                    max: values.max,
                    step: parseFloat(values.step),
                  }
                });

                this.mounted && this.setState({
                  activeOption: null,
                  record: record,
                }, () => {
                  Notification('success', 'The range has been changed', 'Range Changed');
                });

              } catch (error) {
                Notification('error', '', 'Failed');
              } finally {
                this.mounted && this.setState({
                  isEditing: false
                });
              }
            })
            .catch(() => {
              console.error('Invalid state');
            });
        } }
        okText={ 'Save' }
        onCancel={() => this.mounted && this.setState({
          activeOption: null,
        })}
        okButtonProps={{
          disabled: isEditing,
          loading: isEditing,
        }}
      >
        <Form
          ref={ this.formRef }
          layout="vertical"
          initialValues={{
            "min": option?.min,
            "max": option?.max,
            "step": option?.step,
          }}
        >
          <Form.Item
            label="Min"
            name="min"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label="Max"
            name="max"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label="Step"
            name="step"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber style={{ width: '100%' }} step="0.10" />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderAddSliderDialog = () => {
    const { client_id } = this.props;
    const { tools, newSliderRange, isCreatingSliderRange } = this.state;

    const isOkButtonDisabled = () => {
      if (isCreatingSliderRange) return true;
      const schema = ['title', 'min', 'max', 'step', 'tool_id'];
      return schema.some(key => isBlank(_.get(newSliderRange, key, '')));
    };

    return (
      <Modal
        visible
        centered
        title={ 'Create Slider Range' }
        onOk={ () => this.mounted && this.setState({ isCreatingSliderRange: true }, async () => {
          try {
            const response = await API.post(`client/${client_id}/admin/content-manager/slider-ranges`, {
              data: newSliderRange
            });
            this.setState({ record: response });
            Notification('success', 'The Slider Range created', 'Successful');
          } catch (error: any) {
            console.error('Error: ', error);
            Notification('error', _.capitalize(error.data || `Failed to create Slider Range`), 'Failed');
          } finally {
            this.setState({
              newSliderRange: null,
              isCreatingSliderRange: false,
              showAddSliderDialog: false,
            });
          }
        }) }
        okText={ 'Create' }
        onCancel={ () => this.mounted && this.setState({
          showAddSliderDialog: false,
          newSliderRange: null,
        }) }
        okButtonProps={{
          disabled: isOkButtonDisabled(),
          loading: isCreatingSliderRange,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Title" required>
            <Input
              placeholder="Please enter the title for the new slider."
              onChange={ (event) => {
                this.setState({ newSliderRange: Object.assign({}, newSliderRange, { title: event.target.value }) });
              } }
              value={ newSliderRange?.title }
            />
          </Form.Item>
          <Form.Item label="Min" required>
            <NumberFormat
              style={{ width: '100%' }}
              decimalScale={ 0 }
              customInput={ Input }
              onChange={ (e: BaseSyntheticEvent) => {
                this.setState({ newSliderRange: Object.assign({}, newSliderRange, { min: e.target.value }) });
              } }
              value={ newSliderRange?.min }
            />
          </Form.Item>
          <Form.Item label="Max" required>
            <NumberFormat
              style={{ width: '100%' }}
              decimalScale={ 0 }
              customInput={ Input }
              onChange={ (e: BaseSyntheticEvent) => {
                this.setState({ newSliderRange: Object.assign({}, newSliderRange, { max: e.target.value }) });
              } }
              value={ newSliderRange?.max }
            />
          </Form.Item>
          <Form.Item label="Step" required>
            <NumberFormat
              style={{ width: '100%' }}
              decimalScale={ 2 }
              customInput={ Input }
              onChange={ (e: BaseSyntheticEvent) => {
                this.setState({ newSliderRange: Object.assign({}, newSliderRange, { step: e.target.value }) });
              } }
              value={ newSliderRange?.step }
            />
          </Form.Item>
          <Form.Item label="Tool Selection" required>
            <Select
              showSearch
              filterOption={ (input: any, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              } }
              onChange={ (toolId: number) => {
                this.setState({ newSliderRange: Object.assign({}, newSliderRange, { tool_id: toolId }) });
              } }
              value={ newSliderRange?.tool_id || undefined }
            >
              { tools.map((tool: Tool) => (
                <Select.Option key={ tool.id } value={ tool.id }>
                  { tool.label }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderAddSelectDialog = () => {
    const { client_id } = this.props;
    const { newSelectList, isCreatingSelectList, tools } = this.state;

    const isOkButtonDisabled = () => {
      if (isCreatingSelectList) return true;
      const schema = ['title', 'tool_id'];
      return schema.some(key => isBlank(_.get(newSelectList, key, '')));
    };

    return (
      <Modal
        visible
        centered
        title={ 'Create Select List' }
        onOk={ () => this.mounted && this.setState({ isCreatingSelectList: true }, async () => {
          try {
            const response = await API.post(`client/${client_id}/admin/content-manager/select-lists`, {
              data: newSelectList
            });
            this.setState({ record: response });
            Notification('success', 'The Select List created', 'Successful');
          } catch (error: any) {
            console.error('Error: ', error);
            Notification('error', _.capitalize(error.data || `Failed to create Select List`), 'Failed');
          } finally {
            this.setState({
              newSelectList: null,
              isCreatingSelectList: false,
              showAddSelectDialog: false,
            });
          }
        }) }
        okText={ 'Create' }
        onCancel={ () => this.mounted && this.setState({
          showAddSelectDialog: false,
          newSelectList: null,
        }) }
        okButtonProps={{
          loading: isCreatingSelectList,
          disabled: isOkButtonDisabled()
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Title" required>
            <Input
              placeholder="Please enter the title for the new list."
              onChange={ (event) => {
                this.setState({ newSelectList: Object.assign({}, newSelectList, { title: event.target.value }) });
              } }
              value={ newSelectList?.title }
            />
          </Form.Item>
          <Form.Item label="Tool Selection" required>
            <Select
              showSearch
              filterOption={ (input: any, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              } }
              onChange={ (toolId: number) => {
                this.setState({ newSelectList: Object.assign({}, newSelectList, { tool_id: toolId }) });
              } }
              value={ newSelectList?.tool_id || undefined }
            >
              { tools.map((tool: Tool) => (
                <Select.Option key={ tool.id } value={ tool.id }>
                  { tool.label }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderContent = (type: string) => {
    switch (type.toLowerCase()) {
      case 'categories':
        return this.renderCategories();
      case 'select-lists':
        return this.renderSelectLists();
      case 'slider-ranges':
        return this.renderSliderRanges();
      case 'configurations':
        return this.renderConfigurations();
      default:
        return <>Content type not found</>;
    }
  };

  renderCategories = () => {
    const { record, isLoading } = this.state;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Title',
          render: (title: string, record: Partial<Category> & { id: string }) => {
            return (
              <Link className='primaryColor' to={`/admin/content-manager/categories/${_.kebabCase(record.id)}`}>
                { title }
              </Link>
            );
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'tool',
          dataIndex: 'tool',
          title: 'Tool',
          render: (tool: string | null) => {
            if (!tool) {
              return <span>-</span>;
            }
            return <span>{ tool }</span>;
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: string) => getFormatedDate(created_at, undefined, true),
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
      ];
    };

    const mapData = (categories: Category[]) => {
      return categories.map((category: Category, index: number) => {
        return {
          'key': index,
          'id': category.type,
          'title': category.label,
          'tool': !!category.tool ? category.tool.label : null,
          'created_at': category.created_at,
        };
      });
    };

    return <BasicList rawData columns={ mapColumns() } items={ mapData(record as Category[]) } />;
  };

  renderSelectLists = () => {
    const { record, isLoading, showAddSelectDialog } = this.state;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Title',
          render: (title: string, record: SelectList) => {
            return (
              <Link className='primaryColor' to={`/admin/content-manager/select-lists/${record.id?.replaceAll('_', '-')}`}>
                { title }
              </Link>
            );
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'tool',
          dataIndex: 'tool',
          title: 'Tool',
          render: (tool: string | null) => {
            if (!tool) {
              return <span>-</span>;
            }
            return <span>{ tool }</span>;
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: string) => getFormatedDate(created_at, undefined, true),
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
      ];
    };

    const mapData = (record: SelectList[]) => {
      return record.map((list: SelectList, index: number) => {
        return {
          'key': index,
          'id': list.id,
          'title': list.title,
          'tool': !!list.tool ? list.tool.label : null,
          'created_at': list.created_at,
        };
      });
    };

    const actions: Action[] = [
      {
        node: (
          <Button onClick={ () => this.mounted && this.setState({ showAddSelectDialog: true }) }>
            Create Select List
          </Button>
        )
      }
    ];

    return (
      <>
        <BasicList
          rawData
          rightActions={ actions }
          columns={ mapColumns() }
          items={ mapData(record as SelectList[]) }
        />
        { showAddSelectDialog && this.renderAddSelectDialog() }
      </>
    );
  };

  renderSliderRanges = () => {
    const { record, activeOption, isLoading, showAddSliderDialog } = this.state;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Title',
          render: (title: string, record: SliderRange) => {
            return (
              <Link className='primaryColor' to={`/admin/content-manager/slider-ranges/${record.id?.replaceAll('_', '-')}`}>
                { title }
              </Link>
            );
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'tool',
          dataIndex: 'tool',
          title: 'Tool',
          render: (tool: string | null) => {
            if (!tool) {
              return <span>-</span>;
            }
            return <span>{ tool }</span>;
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'min',
          dataIndex: 'min',
          title: 'Min',
          width: 100,
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'max',
          dataIndex: 'max',
          title: 'Max',
          width: 100,
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'step',
          dataIndex: 'step',
          title: 'Step',
          width: 100,
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          width: 150,
          render: (created_at: string) => getFormatedDate(created_at, undefined, true),
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
        {
          key: 'actions',
          dataIndex: 'actions',
          title: '',
          render: (__: any, option: SliderRange) => {
            return (
              <>
                <EditOutlined
                  className="link"
                  style={{ fontSize: 18 }}
                  onClick={ () => this.setState({
                    activeOption: option.id
                  })}
                />
                <Tooltip placement={ 'top' } title={ 'Protected' }>
                  <DeleteOutlined
                    disabled
                    className="mL-20 cur-na text-ant-disabled"
                    style={{ fontSize: 18 }}
                  />
                </Tooltip>
              </>
            );
          },
          width: 100,
          sorter: false,
          ellipsis: true,
          filterable: false,
          align: 'center'
        },
      ];
    };

    const mapData = (record: SliderRange[]) => {
      return record.map((list: SliderRange, index: number) => {
        return {
          'key': index,
          'id': list.id,
          'title': list.title,
          'tool': !!list.tool ? list.tool.label : null,
          'min': list.min,
          'max': list.max,
          'step': list.step,
          'created_at': list.created_at,
        };
      });
    };

    const actions: Action[] = [
      {
        node: (
          <Button onClick={ () => this.mounted && this.setState({ showAddSliderDialog: true }) }>
            Create Slider Range
          </Button>
        )
      }
    ];

    return (
      <>
        <BasicList
          rawData
          rightActions={ actions }
          columns={ mapColumns() }
          items={ mapData(record as SliderRange[]) }
        />
        { activeOption && this.renderEditSliderDialog(record as SliderRange[], activeOption) }
        { showAddSliderDialog && this.renderAddSliderDialog() }
      </>
    );
  };

  renderConfigurations = () => {
    const configurations: Configuration[] = [
      {
        title: 'Probability / Consequence Matrix',
        ref: 'probability-consequence-matrix'
      },
    ];

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Title',
          render: (__: any, record: Configuration) => {
            return (
              <Link className='primaryColor' to={ `/admin/content-manager/configurations/${record.ref}` }>
                { record.title }
              </Link>
            );
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: any) => getFormatedDate(created_at, undefined, true),
          sorter: true,
          ellipsis: true,
          filterable: false,
        }
      ];
    };

    const mapData = (configurations: Configuration[]) => {
      return configurations.map((configuration: Configuration, index: number) => {
        return {
          'key': index,
          'title': configuration.title,
          'ref': configuration.ref
        };
      });
    };

    return (
      <BasicList
        rawData
        columns={ mapColumns() }
        items={ mapData(configurations) }
      />
    );
  };

  render = () => {
    const tabs = CONTENT_TYPES.map((type: string) => {
      return {
        key: type,
        label: _.startCase(_.toLower(type)),
        node: this.renderContent(type)
      };
    });

    return (
      <Jumbotron
        content={ 'Content Manager' }
        tabs={ tabs }
        defaultActiveTab={ this.props.match.params.content_type }
        onChange={ (tab: string) => this.handleTabChange(tab) }
      />
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(Content), 'access_admin_content_manager'));
