// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';

// Components
import Jumbotron from "components/jumbotron";
import BasicList from 'components/basic-list';
import BlockingSpinner from 'components/blocking-spinner';
import { Menu, Modal, Dropdown as AntDropdown, Spin, Select, Input, Pagination, Table, Button } from 'antd';
import { RestrictionHoC } from 'components/restriction';
import CoverModal from 'components/cover-modal';
import CreateTemplateDialog from './CreateTemplateDialog';
import Badge, { BadgeType } from 'components/badge';

// Icons
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

// Views
import NotificationView from 'views/notification/Notification';

// Service
import { getFormatedDate } from 'services/settings';
import Notification from 'services/notification';
import { Api } from 'services/api';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { INotification }  from 'store/Notification/State.interface';
import { NotificationTemplate, NotificationCondition, RecipientType, DeliveryMethodType } from './Notifications.interfaces';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";

interface Props {
  client_id: number,
  intl: IntlShape;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  active_notification_id: number | null;
  notifications: INotification[];
  templates: NotificationTemplate[];
  currentPage: number;
  total: number;
  itemsPerPage: number;
  search: string | null;
  isLoading: boolean;
  isFetchingLogs: boolean;
  isLoadingTemplates: boolean;
  showDeleteDialog: boolean;
  showPreview: boolean;
  showAddTemplateDialog: boolean;
};

const API: Api = new Api();
const { Option } = Select;
const { Search } = Input;

class Notifications extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    active_notification_id: null,
    notifications: [],
    templates: [],
    currentPage: 1,
    itemsPerPage: 25,
    total: 0,
    search: null,
    isLoading: false,
    isFetchingLogs: false,
    isLoadingTemplates: false,
    showDeleteDialog: false,
    showPreview: false,
    showAddTemplateDialog: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Notifications', path: '/admin/notifications' },
    ], false);

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));
      const templates = await API.get(`client/${client_id}/admin/notifications/template`);;
      this.fetchLogs();

      this.mounted && this.setState({
        templates: templates,
      });
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { currentPage, itemsPerPage, search } = this.state;

    // Fetch logs
    if (prevState.currentPage !== currentPage || prevState.itemsPerPage !== itemsPerPage || prevState.search !== search) {
      this.fetchLogs();
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  fetchLogs = async () => {
    const { client_id } = this.props;
    const { currentPage, itemsPerPage, search } = this.state;
    try {
      await new Promise((resolve) => this.setState({ isFetchingLogs: true }, () => resolve(null) ));
      const notifications = await API.get(`client/${client_id}/admin/notifications`, {
        search: search,
        page: currentPage,
        items_per_page: itemsPerPage,
      });
      this.mounted && this.setState({
        total: notifications.total,
        notifications: notifications.results,
      });
    } catch(e) {
      console.error('Failed to fetch logs');
    } finally {
      this.mounted && this.setState({
        isFetchingLogs: false,
      });
    }
  };

  renderDeleteDialog = () => {
    const { client_id } = this.props;
    const { notifications, active_notification_id, isLoading } = this.state;
    const notification = notifications.find((notification: INotification) => notification.id === active_notification_id);

    if (!notification) return;

    return (
      <Modal
        visible
        centered
        title={ 'Remove Notification' }
        maskClosable={ !isLoading }
        closable={ !isLoading }
        okButtonProps={{
          danger: true,
          disabled: isLoading,
          loading: isLoading,
        }}
        cancelButtonProps={{
          disabled: isLoading,
        }}
        onOk={() => this.mounted && this.setState({
          isLoading: true,
          showDeleteDialog: false,
        }, async () => {

          try {

            const notifications = await API.delete(`client/${client_id}/admin/notifications/${active_notification_id}`);

            this.mounted && this.setState({
              notifications: notifications
            });

          } catch (error) {
            Notification('error', `Failed to remove notification`);
            console.error('Error: ', error);
          } finally {
            Notification('success', `Sucessfully removed notification`);
            this.mounted && this.setState({
              active_notification_id: null,
              isLoading: false,
            });
          }

        } )}
        onCancel={() => this.setState({ showDeleteDialog: false, active_notification_id: null })}
      >
        <p>Are you sure you want to remove this notification?</p>
      </Modal>
    );
  };

  renderPreview = () => {
    const { active_notification_id, notifications } = this.state;
    const notification = notifications.find((notification: INotification) => notification.id === active_notification_id);

    if (!notification) return;

    return (
      <CoverModal style={{ width: '50%', height: '80%' }} middleContent={ `Notification` } onClose={ () => this.setState({ showPreview: false }) }>
        <NotificationView notification_id={ notification.id } user_id={ notification.user_id } />
      </CoverModal>
    );
  };

  renderLog = () => {
    const { notifications, currentPage, total, itemsPerPage, isFetchingLogs } = this.state;

    const columns: any = [
      {
        key: 'user',
        dataIndex: 'user',
        title: 'User',
        render: (user: string, notification: any) => {
          return <Link className="primaryColor" to={`/admin/users/${notification.user_id}`}>{ user }</Link>;
        },
        width: 200,
        filterable: false,
        sorter: false,
        ellipsis: true,
      },
      {
        key: 'subject',
        dataIndex: 'subject',
        title: 'Subject',
        width: 350,
        render: (subject: any, notification: any) => {
          return <span className="link primaryColor" onClick={ () => this.setState({ active_notification_id: notification.id, showPreview: true }) }>{ subject }</span>;
        },
        sorter: false,
        ellipsis: true,
        filterable: false,
      },
      {
        key: 'created_at',
        dataIndex: 'created_at',
        title: 'Created',
        render: (created_at: any) => getFormatedDate(created_at, undefined, true),
        width: 150,
        sorter: false,
        ellipsis: true,
        filterable: false,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        render: (_: any, notificaiton: any) => {
          return (
            <DeleteOutlined
              className="link"
              style={{ fontSize: 18 }}
              onClick={() => this.setState({ showDeleteDialog: true, active_notification_id: notificaiton.id })}
            />
          );
        },
        width: 50,
        sorter: false,
        ellipsis: true,
        filterable: false,
        align: 'center'
      },
    ];

    const mapData: any = (notifications: any[]) => {
      return notifications.map((notification: any) => {
        return {
          'key': notification.id,
          'id': notification.id,
          'user': notification?.user ? `${notification.user?.full_name} (${notification.user.email})` : '-',
          'user_id': notification.user_id,
          'subject': notification.subject,
          'created_at': notification.created_at,
        };
      });
    };

    return (
      <>
        <div className="d-f jc-sb ai-c">
          <div className="d-if" style={{ margin: '5px 0 20px 0' }}>
            <Search
              allowClear
              loading={ isFetchingLogs }
              placeholder={ 'Quick Search' }
              style={{ width: 300 }}
              onBlur={ event => {
                this.setState({
                  search: event.target.value || null,
                  currentPage: 1,
                });
              }}
              onSearch={ value => {
                this.setState({
                  search: value || null,
                  currentPage: 1,
                });
              }}
            />
          </div>
        </div>
        <div
          className="d-f jc-sb ai-c mB-10"
          style={{ userSelect: 'none' }}
        >
          <div className="d-if mL-10">
            <span>Show</span>
            <span className="mL-10 mR-10">
              <Select
                size={'small'}
                onChange={(value: number) => {
                  this.setState({
                    currentPage: 1,
                    itemsPerPage: value
                  });
                }}
                defaultValue={ itemsPerPage }
              >
                <Option value={ 25 }>25</Option>
                <Option value={ 50 }>50</Option>
                <Option value={ 100 }>100</Option>
              </Select>
            </span>
            <span>entries of <b>{ total }</b></span>
          </div>
          <div className="d-if">
            <Pagination
              showSizeChanger={ false }
              current={ currentPage }
              total={ total }
              pageSize={ itemsPerPage }
              onChange={ page => {
                this.setState({
                  currentPage: page
                });
              } }
            />
          </div>
        </div>
        <div className='Layout-box'>
          <Table
            loading={ isFetchingLogs }
            size={ 'small' }
            columns={ columns }
            dataSource={ mapData(notifications) }
            pagination={ false }
          />
        </div>
      </>
    );
  };

  renderTemplates = () => {
    const { client_id } = this.props;
    const { templates, isLoadingTemplates } = this.state;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Title',
          render: (title: string, tamplate: NotificationTemplate) => {
            return <Link to={ `/admin/notifications/template/${tamplate.id}` } className="primaryColor">{ title }</Link>;
          },
          width: 200,
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'subject',
          dataIndex: 'subject',
          title: 'Subject',
          width: 350,
          sorter: true,
          ellipsis: true,
          filterable: true,
        },
        {
          key: 'tool_label',
          dataIndex: 'tool_label',
          title: 'Tool',
          render: (tool: string | undefined) => <span>{ !!tool ? tool : '-' }</span>,
          width: 350,
          sorter: true,
          ellipsis: true,
          filterable: true,
        },
        {
          key: 'updated_at',
          dataIndex: 'updated_at',
          title: 'Updated',
          render: (updated_at: string) => getFormatedDate(updated_at, undefined, true),
          width: 150,
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: string) => getFormatedDate(created_at, undefined, true),
          width: 150,
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
        {
          key: 'disabled',
          dataIndex: 'disabled',
          title: 'Status',
          width: 100,
          render: (disabled: boolean) => {
            return <Badge type={ !!disabled ? BadgeType.Danger : BadgeType.Success } text={ !!disabled ? 'Disabled' : 'Enabled' } />;
          },
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
        {
          key: 'actions',
          dataIndex: 'actions',
          title: '',
          render: (__: any, record: NotificationTemplate) => {
            return (
              <AntDropdown.Button
                overlay={
                  <Menu>
                    <Menu.Item
                      key="toogle"
                      onClick={ () => {
                        this.setState({ isLoadingTemplates: true }, async () => {
                          try {
                            const response = await API.put(`client/${client_id}/admin/notifications/template/toggle/${record.id}`, {
                              data: { disabled: !record.disabled }
                            });
                            const updatedTemplates = this.state.templates.map(t => t.id === response.id ? response : t);
                            this.setState({ templates: updatedTemplates });
                          } catch(e) {
                            console.log(e);
                          } finally {
                            this.setState({ isLoadingTemplates: false });
                          }
                        });
                      } }
                    >
                      { !!record.disabled ? 'Enable' : 'Disable' }
                    </Menu.Item>
                    <Menu.Item
                      key="archive"
                      danger
                      onClick={ () => {
                        this.setState({ isLoadingTemplates: true }, async () => {
                          try {
                            await API.delete(`client/${client_id}/admin/notifications/template/${record.id}`);
                            const updatedTemplates = templates.filter(t => t.id !== record.id);
                            this.setState({ templates: updatedTemplates });
                          } catch(e) {
                            console.log(e);
                          } finally {
                            this.setState({ isLoadingTemplates: false });
                          }
                        });
                      } }
                    >
                      Archive
                    </Menu.Item>
                  </Menu>
                }
                trigger={ ['click'] }
              />
            );
          },
          width: 100,
          sorter: false,
          ellipsis: true,
          filterable: false,
          align: 'center',
        },
      ];
    };

    const mapData = (tamplates: NotificationTemplate[]) => {
      return tamplates.map((template) => {
        return {
          'key': template.id,
          'id': template.id,
          'title': template.title,
          'subject': template.subject,
          'updated_at': template.updated_at,
          'created_at': template.created_at,
          'disabled': template.disabled,
          'tool_label': template.tool_label
        };
      });
    };

    const actions = [
      {
        node: (
          <Button onClick={ () => this.mounted && this.setState({ showAddTemplateDialog: true }) }>
            Create Notification Template
          </Button>
        ),
      }
    ];

    return (
      <Spin indicator={ <LoadingOutlined style={{ fontSize: 24 }} spin /> } spinning={ isLoadingTemplates }>
        <BasicList
          rawData
          rightActions={ actions }
          columns={ mapColumns() }
          items={ mapData(templates) }
        />
      </Spin>
    );
  };

  renderCreateTemplateDialog = () => {
    const { client_id } = this.props;

    return (
      <CreateTemplateDialog
        clientId={ client_id }
        onCreate={ async (payload: {
          template: Partial<NotificationTemplate>;
          conditions?: NotificationCondition[];
          recipients?: Array<{ type: RecipientType; target_id: number }>;
          deliveryMethods?: Array<keyof typeof DeliveryMethodType>;
        }) => {
          const { template, conditions = [], recipients = [], deliveryMethods = [] } = payload;
          const templates = await API.post(`client/${client_id}/admin/notifications/template`, {
            data: { template: {...template, disabled: true }, conditions, recipients, delivery_methods: deliveryMethods }
          });
          this.setState({ showAddTemplateDialog: false, templates: templates });
        } }
        onClose={ () => this.setState({ showAddTemplateDialog: false }) }
      />
    );
  };

  render = () => {
    const { isLoading, showDeleteDialog, showPreview, showAddTemplateDialog } = this.state;
    const tabs = [
      { label: 'Templates', node: this.renderTemplates() },
      { label: 'Log', node: this.renderLog() },
    ];

    return (
      <BlockingSpinner isLoading={ isLoading }>
        <Jumbotron
          content={ 'Notifications' }
          tabs={ tabs }
        />
        { showDeleteDialog && this.renderDeleteDialog() }
        { showPreview && this.renderPreview() }
        { showAddTemplateDialog &&  this.renderCreateTemplateDialog() }
      </BlockingSpinner>
    );

  };

};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(Notifications), 'access_admin_notifications'));
