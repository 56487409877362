// Libs
import * as React from 'react';
import { Badge, Tooltip } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';

// Styles
import './Badge.scss';

export enum BadgeType {
  Default = 'default',
  Warning = 'warning',
  Danger = 'danger',
  Success = 'success',
  Disabled = 'disabled',
};

export enum BadgeSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
};

interface Props {
  type: BadgeType;
  size?: BadgeSize | undefined;
  text: number | string | undefined;
  tooltip?: string | undefined;
  className?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
};

export const getBadgeType = (status: string): BadgeType => {
  switch (_.toUpper(status)) {
    case 'TODO':
      return BadgeType.Default;
    case 'PENDING':
    case 'PROGRESS':
    case 'INTERNAL':
    case 'EXTERNAL':
      return BadgeType.Warning;
    case 'REJECTED':
    case 'DEACTIVATED':
    case 'PROBLEM':
      return BadgeType.Danger;
    case 'CLOSED':
      return BadgeType.Disabled;
    case 'APPROVED':
    case 'ACTIVE':
    case 'RESOLVED':
      return BadgeType.Success;
    default:
      return BadgeType.Default;
  }
};

const getColorClass = ((type: BadgeType) => {
  switch (type) {
    case BadgeType.Success:
      return 'Badge--success';
    case BadgeType.Danger:
      return 'Badge--danger';
    case BadgeType.Warning:
      return 'Badge--warning';
    case BadgeType.Default:
      return 'Badge--default';
    case BadgeType.Disabled:
      return 'Badge--disabled';
  }
});

export default function BadgeComponent({ type, text, tooltip, className = '', fullWidth, children, onClick, size = BadgeSize.Medium }: Props) {

  const badge = (
    <span
      className={ classNames(`Badge Badge--${size} ${className}`, {
        'Badge--fullwidth': fullWidth
      }) }
      onClick={ onClick && onClick }
    >
      { children ? (
          <Badge
            title={ '' }
            className={ getColorClass(type) }
            showZero
            count={ text }
            offset={[5, -5]}
          >
            { children }
          </Badge>
        ) : (
          <Badge
            title={ '' }
            className={ getColorClass(type) }
            showZero
            count={ text }
          />
        )
      }
    </span>
  );

  return !tooltip ? <Tooltip placement={ 'top' } title={ tooltip }>{ badge }</Tooltip> : badge;
};