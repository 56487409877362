// Libs
import * as React from 'react';
import { Popover } from 'antd';
import classNames from 'classnames';

// Interfaces
import { WorkflowPreview } from './Workflow.interface';

// Styles
import './WorkflowPane.scss';

interface Props {
  workflowPreview: WorkflowPreview[];
};

const getStageColor = (context: string): string => {
  switch (context) {
    case 'TODO':
      return 'Workflow__step--color-todo';
    case 'PROGRESS':
      return 'Workflow__step--color-progress';
    case 'RESOLVED':
      return 'Workflow__step--color-resolved';
    case 'PROBLEM':
      return 'Workflow__step--color-problem';
    case 'CLOSED':
      return 'Workflow__step--color-closed';
    default:
      return 'Workflow__step--color-closed';
  }
};

export function WorkflowPane({ workflowPreview }: Props) {
  return (
    <div className="pos-st bg-white bdB pT-5 pB-5 t-0" style={{ zIndex: 4 }}>
      <nav className="pL-30 pR-30">
        <ol className="Workflow">
          { workflowPreview.map((stage: WorkflowPreview, index: number) => {
            const isFirst = index === 0;
            const isLast = index === (workflowPreview.length - 1);
            const isCurrent = stage.current;
            return (
              <li
                key={ index }
                className={ classNames(`Workflow__step ${ isCurrent ? getStageColor(stage.context) : '' }`, {
                  'Workflow__step--is-first': isFirst,
                  'Workflow__step--is-last': isLast
                  })
                }
              >
                { stage.description ? (
                  <Popover content={ <span>{ stage.description }</span> } placement='bottom'>
                    <span>{ stage.title }</span>
                  </Popover>
                ) : (
                  <span>{ stage.title }</span>
                ) }
              </li>
            );
          } ) }
        </ol>
      </nav>
    </div>
  );
};