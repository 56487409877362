// Libs
import React from 'react';
import _ from 'lodash';

// Components
import Dynamic from 'components/form/field/dynamic';
import CoverModal from 'components/cover-modal';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

// Interfaces
import { IDynamicFieldTemplate, IDynamicFieldTemplateComponent, IDynamicFieldTemplateSet } from 'views/admin/templates/Templates.interfaces';

// Utils
import { findFirst } from 'utils/utils';

// Styles
import './DynamicFieldTemplate.scss';

const mockFC: any = () => {};
const emptyObject: any = {};
const API: Api = new Api();

interface Props {
  clientId: number;
  templateTypeId: number;
  templateId: number;
  onReady?: () => void;
  onCancel: () => void;
};

interface State {
  template: IDynamicFieldTemplate | null;
  isLoading: boolean;
};

class PreviewDynamicField extends React.Component<Props, State> {

  state: State = {
    template: null,
    isLoading: false,
  };

  componentDidMount = async () => {
    const { clientId, templateTypeId, templateId, onReady } = this.props;

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const template = await API.get(`client/${clientId}/admin/templates/dynamic-field/types/${templateTypeId}/templates/${templateId}/custom`);

      this.setState({
        template: template,
      });

    } catch (error) {
      Notification('error', 'Failed to fetch template', 'Failed');
    } finally {
      this.setState({
        isLoading: false
      }, () => {
        onReady && onReady();
      });
    }
  };

  getField = (template: IDynamicFieldTemplate): any => {
    return {
      config: { locked: false },
      template: template,
      dynamic_template_sets: template.sets,
      options: [],
      action_list: [],
      values: this.flattenComponents(template)
    };
  };

  flattenComponents = (template: IDynamicFieldTemplate) => {
    const components: IDynamicFieldTemplateComponent[] = [];

    _.has(template, 'sets') && template.sets.forEach((set: IDynamicFieldTemplateSet) => {
      _.has(set, 'components') && set.components.forEach((component: IDynamicFieldTemplateComponent) => {
        components.push(component);
      });
    });

    return components;
  };

  modifyTemplate = (template: IDynamicFieldTemplate, components: IDynamicFieldTemplateComponent[]) => {
    return {
      ...template,
      sets: template.sets.map((set: IDynamicFieldTemplateSet) => {
        return {
          ...set,
          components: set.components.map((component:IDynamicFieldTemplateComponent) => {

            const newComponents = findFirst({ children: components }, 'children', { id: component.id });

            if (newComponents) {
              return newComponents;
            }

            return component;
          })
        };
      })
    };
  };

  render = () => {
    const { clientId, onCancel } = this.props;
    const { template } = this.state;

    if (!template) return <></>;

    return (
      <CoverModal
        style={{ minWidth: '80vw', minHeight: '80vh', maxHeight: '80vh', maxWidth: '80vw' }}
        middleContent={ 'Preview' }
        onClose={ () => onCancel() }
      >
        <div className="p-20">
          <Dynamic
            clientId={ clientId }
            record={ emptyObject }
            onChange={ (__: any, components: IDynamicFieldTemplateComponent[]) => {
              this.setState({
                template: this.modifyTemplate(template, components)
              });
            } }
            field={ this.getField(template) }
            originalState={ this.flattenComponents(template) }
            state={ this.flattenComponents(template) }
            config={ emptyObject }
            isDisabled={ false }
            fieldErrorMessages={ mockFC }
            fieldModifiedMessages={ mockFC }
            setFieldModifiedMessage={ mockFC }
            setFieldErrorMessage={ mockFC }
            validate={ mockFC }
            isPreviewing
            border
          />
        </div>
      </CoverModal>
    );
  };
}

export default PreviewDynamicField;
