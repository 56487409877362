function getConsole() {
  if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
    return {
      error() {},
      group() {},
      groupEnd() {},
      log() {},
      info() {},
      table() {},
      time() {},
      trace() {},
      warn() {},
    };
  }

  return window.console;
}

const Console = getConsole();

export default Console;
