// Libs
import React, { Component } from 'react';
import _, { set, isEqual, cloneDeep, isEmpty } from 'lodash';

// Components
import { Empty } from 'antd';
import OverlaySpinner from "components/overlay-spinner";
import FieldWrapper from 'components/form/field/field-wrapper';
import { hasPermission } from 'components/restriction';

import DateTime from 'components/form/field/date-time';
import Time from 'components/form/field/time';
import Dynamic from 'components/form/field/dynamic';
import Email from 'components/form/field/email';
import GeoLocation from 'components/form/field/geo-location';
import Group from 'components/form/group';
import Link from 'components/form/field/link';
import NumberField from 'components/form/field/number';
import Text from 'components/form/field/text';
import Address from 'components/form/field/address';
import TextArea from 'components/form/field/text-area';
import List from 'components/form/field/list';
import KpiPriority from 'components/form/field/kpi_priority/KpiPriority';
import Relationship from 'components/form/field/relationship';
import Divider from 'components/form/field/divider';
import PropertyRelationship from 'components/form/field/property-relationship';
import OpexCostSummary from 'components/form/field/opex_cost_summary';
import CapexCostSummary from 'components/form/field/capex_cost_summary';
import CapexPricingSummary from 'components/form/field/capex_price_summary';
import OpexPricingSummary from 'components/form/field/opex_price_summary';
import FinanceTemplate from 'components/form/field/finance_template';
import Phone from 'components/form/field/phone';
import Currency from 'components/form/field/currency';
import CurrencyUnit from 'components/form/field/currency-unit';
import AreaUnit from 'components/form/field/area-unit';
import Slider from 'components/form/field/slider';
import ServiceScope from 'components/form/field/service-scope';
import SpaceRelationship from 'components/form/field/space-relationship';
import SpecificationRelationship from 'components/form/field/specification-relationship';
import Category from 'components/form/field/category';
import CategoryParent from 'components/form/field/category-parent';
import Contact from 'components/form/field/contact';
import ComprehensiveThreshold from 'components/form/field/comprehensive-threshold';
import Area from 'components/form/field/area';
import ResourceSetup from 'components/form/field/resource-setup';
import Country from 'components/form/field/country';
import Editor from 'components/form/field/editor';
import SpaceDefinition from 'components/form/field/space-definition';
import View from 'components/form/field/view';
import Calculated from 'components/form/field/calculated';
import File from 'components/form/field/file';
import DynamicFieldTemplateSelect from 'components/form/field/dynamic-field-template';
import Clause from 'components/form/field/clause';
import KeyDate from 'components/form/field/key-date';
import ActivityManagement from 'components/form/field/activity_management';
import ActivityTemplateSelect from 'components/form/field/activity-template-select';
import Role from 'components/form/field/role';
import MaintenanceSchedule from 'components/form/field/maintenance-schedule';
import AssetTypeSelection from 'components/form/field/asset-type-selection';
import Cost from 'components/form/field/cost';
import CostTemplate from "components/form/field/cost-template";
import Relation from 'components/form/field/relation';
import DynamicFieldComponentRelationship from 'components/form/field/dynamic-field-component-relationship';
import Color from 'components/form/field/color';
import Frequency from 'components/form/field/frequency';
import Timeline from 'components/form/field/timeline';
import CoaSelection from 'components/form/field/coa-selection';
import Resource from 'components/form/field/resource';
import FinanceTemplateModal from 'components/form/field/finance_template_modal';

// Interfaces
import { RecordFormEntity } from 'types/entities';
import {
  FormGroup,
  FormElement,
  FormField,
  FormRecord,
  FormValues,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
  APIFieldErrorMessage,
  DEPENDENCY_MODE_AND,
  DEPENDENCY_MODE_OR
} from 'components/form/form-wrapper';

// Utils
import { parseBasicFieldValueToString } from 'utils/utils';
import Console from 'utils/console';

// Services
import { Api } from 'services/api';

interface Props {
  clientId: number;
  entity: string;
  numberFormat: any;
  apiErrors: Record<string, APIFieldErrorMessage>;
  canCreate?: boolean;
  canEdit?: boolean;
  canView?: boolean;
  record: RecordFormEntity;
  isNew: boolean;
  config: FormRecord;
  isSaving: boolean;
  returnData(formData: FormRecord, formTabIndex: number, title?: string, isLockedTitle?: boolean, callback?: () => void): void;
  originalState: FormRecord;
  showInfoBox?: boolean;
  tabIndex: number;
  validate(field: FormField, column: string, value: string | number): string[];
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  fieldModifiedMessages: Record<string, FormFieldInfoBoxModifiedMessage>;
  fieldErrorMessages: Record<string, FormFieldInfoBoxErrorMessage>;
  getRecord?: (silent?: boolean) => void;
  updateFormRecord?: (formRecord: FormRecord, formTabIndex: number) => void;
  updateModifiedRecord?: (record: RecordFormEntity) => void;
};

interface State {
  form: FormRecord;
  values: FormRecord;
  type: string | null;
  bundle: string | null;
  isModifying: boolean;
};

const API: Api = new Api();

class FormBuilder extends Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    form: this.props.config,
    values: this.props.originalState,
    type: null,
    bundle: null,
    isModifying: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.originalState !== this.props.originalState) {
      this.setState({
        form: this.props.config,
        values: _.cloneDeep(this.props.originalState)
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  hasRequiredFields = (group: FormGroup): boolean => {
    let hasRequiredFields = false;

    group.elements.forEach((element: FormElement) => {
      if (element.data && element.data.config && element.data.config.required) {
        hasRequiredFields = true;
      }
    });

    return hasRequiredFields;
  };

  isModified = (newValue: any, pastValue: any): boolean => {
    if (this.props.isNew && (newValue !== '' && pastValue !== '')) {
      return !isEqual(newValue, pastValue);
    }

    return false;
  };

  isDisabled = () => {
    const { canCreate, canEdit, isSaving, isNew } = this.props;

    if (this.state.isModifying) {
      return true;
    }

    if (isSaving) {
      return true;
    }

    if (canEdit) {
      return false;
    }

    if (isNew && canCreate) {
      return false;
    }

    return true;
  };

  manipulateFieldValue = (state: FormRecord, field: string, key: string, value: string | number) => {
    return {
      ...state,
      groups: [
        ...state.groups.map((group: FormGroup) => {
          return {
            ...group,
            elements: [
              ...group.elements.map((element: FormElement) => {
                if (_.has(element, 'data.type') && element.data.type === field) {
                  if (_.has(element, 'data.values')) {
                    return set(element, ['data', 'values', 0, key], value);
                  }
                }
                return element;
              })
            ]
          };
        })
      ]
    };
  };

  getFieldState = (
    state: FormValues[],
    config: FormFieldConfig
  ): FormValues => {
    const { fieldIndex = 0 } = config;

    if (state[fieldIndex]) {
      const item = state[fieldIndex];

      if (!!item) {
        return item;
      }
    }

    return {};
  };

  getFormField = (form: FormRecord, field_id: string) => {
    const field = form.groups.filter((group: FormGroup) => {
      return group.elements.some((element: FormElement) => element.data.id === field_id);
    }).map((group: FormGroup) => {
      return group.elements.find((element: FormElement) => element.data.id === field_id);
    });

    return !!field && !_.isEmpty(field) ? field[0] : false;
  };

  /**
   * This handles setting up variables and the configuration specifics for each field
   *
   * @remarks
   * This method should only be used by getField function
   *
   * @param field - This is the field object
   * @param config - Field config object
   *
   */
  getField = (
    element: FormElement,
    config: FormFieldConfig
  ): React.ReactNode => {
    const { groupIndex, elementIndex } = config;
    const original: FormField | undefined = this.state.values.groups[groupIndex].elements[elementIndex].data;
    const field = { ...element.data, ...{ 'dependencies': element.dependencies, 'dependencies_mode': !!element.config.dependencies_mode ? element.config.dependencies_mode : DEPENDENCY_MODE_AND } };
    const state: FormValues[] = field.values ? field.values : [];

    config.fieldColSpan = element.config.column_span;

    switch (field.type) {
      case 'address':
      case 'datetime':
      case 'dynamic':
      case 'email':
      case 'geolocation':
      case 'link':
      case 'number':
      case 'text':
      case 'textarea':
      case 'time':
      case 'editor':
        return this.renderChildren(field, state, original, config, true);
      default:
        return this.renderChildren(field, state, original, config, false);
    }
  };

  handleRefreshForm = async (clientId: number, record: RecordFormEntity, config: FormFieldConfig, field_id: string) => {
    try {

      await new Promise((resolve) => this.setState({ isModifying: true }, () => resolve(null)));

      const modifiedRecord = set(cloneDeep(record), ['form', config.tabIndex], this.state.form);
      const manipulatedRecord = await API.put(`client/${clientId}/${record.bundle.replaceAll('_', '-')}/${record.type.replaceAll('_', '-')}/${ !!record.id ? `${record.id}/` : '' }form/manipulate`, {
        triggering_field: field_id,
        data: JSON.stringify(modifiedRecord)
      });

      this.props.updateModifiedRecord && this.props.updateModifiedRecord(manipulatedRecord);

    } catch (error) {
      Console.error(error);
    } finally {
      this.setState({
        isModifying: false
      });
    }
  };

  /**
   * This handles the change event for all fields
   *
   * @remarks
   * This method should only be used by the form fields
   *
   * @param field - This is the field object
   * @param value - This is the value from the form field
   * @param config - This is the form field config
   * @param column - field column (optional)
   *
   * @returns FormField
   */
  handleChange = (
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
    callback?: () => void,
  ): FormField => {
    let state = cloneDeep(this.state.form);
    let updatedValue = cloneDeep(field);
    let valueHasChanged: boolean = false;

    if (!updatedValue.values) {
      updatedValue.values = [];
    }

    if (!!column) {

      if (!!config.fieldIndex || config.fieldIndex === 0) {

        if (!_.isEmpty(field.values)) {
          updatedValue.values[config.fieldIndex] = {
            ...field.values[config.fieldIndex],
            [column]: value,
          };
        } else {
          updatedValue.values[config.fieldIndex] = {
            [column]: value,
          };
        }

        if (!_.isEqual(field.values, updatedValue.values)) {
          valueHasChanged = true;
        }
      } else {
        if (field.values) {
          updatedValue.values[0] = { ...field.values[0], [column]: value };
        } else {
          updatedValue.values[0] = { [column]: value };
        }

        if (!_.isEqual(field.values, updatedValue.values)) {
          valueHasChanged = true;
        }
      }
    } else {
      if (updatedValue.values !== value) {
        updatedValue.values = value;
        valueHasChanged = true;
      }
    }

    if (valueHasChanged) {

      // If default currency is changed, update the currency fields
      if (field.id === 'default_currency') {
        state = this.manipulateFieldValue(state, 'currency', 'currency', value);
      }

      // If default area is changed, update the area fields unit
      if (field.id === 'default_area_unit') {
        state = this.manipulateFieldValue(state, 'area', 'unit', value);
      }

      const newForm = set(
        state,
        ['groups', config.groupIndex, 'elements', config.elementIndex, 'data'],
        updatedValue
      );

      this.setState({
        form: newForm
      }, () => {
        this.props.returnData(newForm, config.tabIndex, undefined, undefined, callback);
      });
    }

    return updatedValue;
  };

  /**
   * This handles rendering of form groups
   *
   * @remarks
   * This method should only be used when running a map on the form groups
   *
   * @param groupKey - This is the field group array key
   *
   * @returns Field Component
   */
  renderGroup = (group: FormGroup, groupIndex: number): React.ReactNode => {
    const { fieldErrorMessages, fieldModifiedMessages, tabIndex, isNew } = this.props;
    const modifiedFields = Object.values(fieldModifiedMessages);
    const errors = Object.values(fieldErrorMessages).find((error: FormFieldInfoBoxErrorMessage) => (error.group === group.id && !error.isHidden));
    const isModified = isNew ? !isEmpty(modifiedFields.find((modifiedField: FormFieldInfoBoxModifiedMessage) => modifiedField.group === group.id)) : false;
    const hasRequiredFields = this.hasRequiredFields(group);

    return (
      <Group
        key={ `form-section-${group.id}-${group.title}` }
        title={ group.title }
        hasRequiredFields={ hasRequiredFields }
        isOpen={ group.config.open }
        hasErrors={ !_.isEmpty(errors) }
        hasWarnings={ isModified }
      >
        { !_.isEmpty(group?.elements) && group.elements.map((element: any, elementIndex: number) => this.renderElement(group.id, groupIndex, tabIndex, elementIndex, element)) }
      </Group>
    );
  };

  renderElement = (groupID: number, groupIndex: number, tabIndex: number, elementIndex: number, element: FormElement): React.ReactNode => {
    switch (element.type) {
      case 'field':
      case 'foreign':
        return this.getField(element, {
          tabID: this.props.config.id,
          tabIndex: tabIndex,
          groupID: groupID,
          groupIndex: groupIndex,
          elementIndex: elementIndex,
        });
      case 'relation':
        return this.renderRelation(element);
      case 'divider':
        return this.renderDivider(element);
      case 'view':
      case 'report':
        return this.renderView(element);
      case 'empty':
        return this.renderEmptyElement(element);
      default:
        return <div key={ element.id } />;
    }
  };

  /**
   * This handles rendering of the specific fields
   *
   * @remarks
   * This method should only be used by getField function
   *
   * @param field - This is the field object
   * @param columnKeys - string array of all the column keys
   * @param state - current field values state
   * @param errors - current field errors state
   * @param modified - current state of the original values
   * @param renderMultiple - specifies if the form should render multiple
   *
   * @returns Field Component render callback
   */
  renderChildren = (
    children: FormField,
    state: FormValues[],
    original: FormField,
    config: FormFieldConfig,
    renderMultiple: boolean
  ): React.ReactNode => {
    if (renderMultiple) {
      const array = Array.from(Array(children.config.cardinality).keys());
      return array.map((item: any, index: number) => {
        return this.renderField(children, state, original, {
          ...config,
          fieldIndex: index,
        });
      });
    }

    return this.renderField(children, state, original, config);
  };

  /**
     * This handles rendering of the specific fields
     *
     * @remarks
     * This method should only be used by renderField function
     *
     * @param field - This is the field object
     * @param state - current field values state
     * @param original - original field object
     * @param config - the configuration of the field (location)
     *
     * @returns Field Component
     */
  renderField = (
    field: FormField,
    stateArray: FormValues[],
    originalFormField: FormField,
    config: FormFieldConfig
  ): React.ReactNode => {
    const {
      fieldModifiedMessages,
      fieldErrorMessages,
      setFieldModifiedMessage,
      setFieldErrorMessage,
      validate,
      numberFormat,
      entity,
      clientId,
      record,
      isNew
    } = this.props;
    const cardinality = config.fieldIndex || 0;
    const fieldKey = `${field.id}_${cardinality}`;
    const errors = !isEmpty(fieldErrorMessages[fieldKey]) && !fieldErrorMessages[fieldKey].isHidden ? fieldErrorMessages[fieldKey].errors : {};
    const state: FormValues = this.getFieldState(stateArray, config);
    const isDisabled = this.isDisabled();
    const originalStateArray = originalFormField.values;
    const originalState: FormValues = this.getFieldState(
      originalStateArray,
      config
    );
    const isModifiedField = !_.isEqual(_.omit(field, 'dependencies', 'dependencies_mode'), originalFormField);

    // Will describe field dependancy logic
    //
    // null = no dependencies
    // false = dependencies not met
    // true = dependencies met
    let dependenciesMet: null | boolean = null;
    if (!_.isEmpty(field.dependencies)) {

      if (field.dependencies_mode === DEPENDENCY_MODE_AND) {
        dependenciesMet = field.dependencies.every((dependency: any) => {
          const _field = this.getFormField(this.state.form, dependency.field);
          const _key: any = Object.keys(dependency.value);
          if (!!_field && !!dependency.value && _.has(_field, 'data.values') && !!_field.data.values) {
            return _field.data.values.some((_value: any) => dependency['value'][_key] === _value[_key]);
          } else {
            return false;
          }
        });
      } else if (field.dependencies_mode === DEPENDENCY_MODE_OR) {
        dependenciesMet = field.dependencies.some((dependency: any) => {
          const _field = this.getFormField(this.state.form, dependency.field);
          const _key: any = Object.keys(dependency.value);
          if (!!_field && !!dependency.value && _.has(_field, 'data.values') && !!_field.data.values) {
            return _field.data.values.some((_value: any) => dependency['value'][_key] === _value[_key]);
          } else {
            return false;
          }
        });
      }
    }

    // Render empty fragment if the field is hidden
    if (!!field.config?.hide_in_form || dependenciesMet === false) {
      return <React.Fragment key={`${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}`}></React.Fragment>;
    }

    switch (field.type) {
      case 'address':
        return (
          <Address
            key={`${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}`}
            clientId={ clientId }
            onChange={ this.handleChange }
            originalState={ originalState }
            field={ field }
            state={ state }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'area':
        return (
          <Area
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            numberFormat={ numberFormat }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            originalState={ originalState }
            field={ field }
            state={ state }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'area_unit':
        return (
          <AreaUnit
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            onChange={ this.handleChange }
            originalState={ originalState }
            field={ field }
            state={ state }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'category':
        return (
          <Category
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            config={ config }
            field={ field }
            state={ stateArray }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            originalState={ originalStateArray }
            validate={ validate }
            border
          />
        );

      case 'category_parent':
        return (
          <CategoryParent
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            config={ config }
            field={ field }
            state={ stateArray }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            originalState={ originalStateArray }
            validate={ validate }
            border
          />
        );

      case 'contact':
        return (
          <Contact
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            originalValues={ originalStateArray }
            numberFormat={ numberFormat }
            field={ field }
            clientId={ clientId }
            entity={ entity }
            record={ record }
            errors={ errors }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onValueChange={ (modifiedValues: FormValues) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data', 'values'], modifiedValues), config.tabIndex);
            } }
            onFieldChange={ (field: FormField) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data'], field), config.tabIndex);
            } }
          />
        );

      case 'comprehensive_threshold':
        return (
          <ComprehensiveThreshold
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            originalValues={ originalStateArray }
            numberFormat={ numberFormat }
            field={ field as any }
            clientId={ clientId }
            entity={ entity }
            record={ record }
            errors={ errors }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onValueChange={ (modifiedValues: FormValues) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data', 'values'], modifiedValues), config.tabIndex);
            } }
          />
        );

      case 'country':
        return (
          <Country
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            onChange={ this.handleChange }
            originalState={ originalStateArray }
            field={ field }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'currency':
        return (
          <Currency
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            numberFormat={ numberFormat }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            originalState={ originalState }
            field={ field }
            state={ state }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'currency_unit':
        return (
          <CurrencyUnit
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            originalState={ originalState }
            field={ field }
            state={ state }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'datetime':
        const dateColumnKeys = Object.keys(field.columns);
        return dateColumnKeys.map((columnKey: string, index: number) => {
          return (
            <DateTime
              key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${index}` }
              columnKey={ columnKey }
              onChange={ this.handleChange }
              onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
              field={ field }
              state={ state }
              config={ config }
              isDisabled={ isDisabled || !!field.config.locked }
              originalState={ originalState }
              fieldErrorMessages={ fieldErrorMessages }
              fieldModifiedMessages={ fieldModifiedMessages }
              setFieldModifiedMessage={ setFieldModifiedMessage }
              setFieldErrorMessage={ setFieldErrorMessage }
              validate={ validate }
              border
            />
          );
        });

      case 'dynamic':
        return (
          <Dynamic
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            record={ record }
            onChange={ this.handleChange }
            onFieldChange={ (field: FormField) => {
              this.props.updateFormRecord && this.props.updateFormRecord(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data'], field), config.tabIndex);
            } }
            getRecord={ this.props.getRecord }
            field={ field }
            originalState={ originalStateArray }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'editor':
        return (
          <Editor
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            onChange={ this.handleChange }
            field={ field }
            state={ state }
            originalState={ originalState }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'email':
        return (
          <Email
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            onChange={ this.handleChange }
            originalState={ originalState }
            field={ field }
            state={ state }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'file':
        return (
          <File
            clientId={ clientId }
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            onChange={ this.handleChange }
            field={ field }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            originalState={ originalStateArray }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'finance_template':
        return (
          <FinanceTemplate
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            numberFormat={ numberFormat }
            clientId={ clientId }
            record={ record }
            field={ field }
            originalValues={ originalStateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ (modifiedValues: FormValues) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data', 'values'], modifiedValues), config.tabIndex);
            } }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'finance_template_modal':
        return (
          <FinanceTemplateModal
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            numberFormat={ numberFormat }
            clientId={ clientId }
            record={ record }
            field={ field }
            originalValues={ originalStateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ (modifiedValues: FormValues) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data', 'values'], modifiedValues), config.tabIndex);
            } }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'geolocation':
        return (
          <GeoLocation
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            onChange={ this.handleChange }
            originalState={ originalState }
            field={ field }
            state={ state }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'link':
        return (
          <Link
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            onChange={ this.handleChange }
            originalState={ originalState }
            field={ field }
            state={ state }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'number':
        return (
          <NumberField
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            numberFormat={ numberFormat }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            field={ field }
            state={ state }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            originalState={ originalState }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'opex_cost_summary':
        return (
          <OpexCostSummary
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            record={ record }
            field={ field }
            numberFormat={ numberFormat }
            originalState={ originalStateArray }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            getRecord={ this.props.getRecord }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'capex_cost_summary':
        return (
          <CapexCostSummary
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            record={ record }
            field={ field }
            numberFormat={ numberFormat }
            originalState={ originalStateArray }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'opex_price_summary':
        return (
          <OpexPricingSummary
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            record={ record }
            field={ field }
            numberFormat={ numberFormat }
            originalState={ originalStateArray }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            getRecord={ this.props.getRecord }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'capex_price_summary':
        return (
          <CapexPricingSummary
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            record={ record }
            field={ field }
            numberFormat={ numberFormat }
            originalState={ originalStateArray }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'property_relationship':
        return (
          <PropertyRelationship
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            record={ record }
            field={ field }
            originalState={ originalStateArray }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'phone':
        return (
          <Phone
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            onChange={ this.handleChange }
            originalState={ originalState }
            field={ field }
            state={ state }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'relationship':
        return (
          <Relationship
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            entity={ entity }
            config={ config }
            field={ field }
            modifiedForm={ this.state.form }
            originalState={ originalStateArray }
            state={ stateArray }
            onChange={ this.handleChange }
            isDisabled={ isDisabled || !!field.config.locked }
            isModifying={ this.state.isModifying }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            record={ record }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            border
          />
        );

      case 'select':
        return (
          <List
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            record={ record }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            originalState={ originalStateArray }
            field={ field }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            onFieldChange={ (field: FormField) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data'], field), config.tabIndex);
            } }
            border
          />
        );

      case 'kpi_priority':
        return (
          <KpiPriority
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            onChange={ this.handleChange }
            originalState={ originalStateArray }
            field={ field }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'service_scope':
        return (
          <ServiceScope
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            config={ config }
            field={ field }
            state={ stateArray }
            originalState={ originalStateArray }
            isDisabled={ isDisabled || !!field.config.locked }
            onChange={ this.handleChange }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
          />
        );

      case 'slider':
        return (
          <Slider
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            onChange={ this.handleChange }
            originalState={ originalState }
            field={ field }
            state={ state }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'space_relationship':
        return (
          <SpaceRelationship
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            record={ record }
            field={ field }
            originalState={ originalStateArray }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            validate={ validate }
            border
          />
        );

      case 'specification_relationship':
        return (
          <SpecificationRelationship
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            record={ record }
            field={ field }
            originalState={ originalStateArray }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            validate={ validate }
            border
          />
        );

      case 'text':
        const textColumnKeys = Object.keys(field.columns);
        return textColumnKeys.map((column: string, index: number) => {
          const stringValue: string | null = state[column]
            ? parseBasicFieldValueToString(state[column])
            : '';
          const originalStringValue: string = originalState && originalState[column]
            ? parseBasicFieldValueToString(originalState[column])
            : '';

          return (
            <Text
              key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}-${column}-${index}` }
              columnKey={ column }
              onChange={ this.handleChange }
              field={ field }
              state={ stringValue }
              config={ config }
              isDisabled={ isDisabled || !!field.config.locked }
              originalState={ originalStringValue }
              fieldErrorMessages={ fieldErrorMessages }
              fieldModifiedMessages={ fieldModifiedMessages }
              setFieldModifiedMessage={ setFieldModifiedMessage }
              setFieldErrorMessage={ setFieldErrorMessage }
              validate={ validate }
              border
            />
          );
        });

      case 'textarea':
        const textAreaColumnKeys = Object.keys(field.columns);
        return textAreaColumnKeys.map((column: string, index: number) => {
          const stringValue: string | null = state[column] ? parseBasicFieldValueToString(state[column]) : '';
          const originalStringValue: string = originalState && originalState[column]
            ? parseBasicFieldValueToString(originalState[column])
            : '';

          return (
            <TextArea
              key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}-${column}-${index}` }
              columnKey={ column }
              onChange={ this.handleChange }
              field={ field }
              state={ stringValue }
              config={ config }
              isDisabled={ isDisabled || !!field.config.locked }
              originalState={ originalStringValue }
              fieldErrorMessages={ fieldErrorMessages }
              fieldModifiedMessages={ fieldModifiedMessages }
              setFieldModifiedMessage={ setFieldModifiedMessage }
              setFieldErrorMessage={ setFieldErrorMessage }
              validate={ validate }
              border
            />
          );
        });

      case 'time':
        return (
          <Time
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            onChange={ this.handleChange }
            field={ field }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            originalState={ originalStateArray }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'resource_setup':
        return (
          <ResourceSetup
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            record={ record }
            field={ field }
            originalState={ originalStateArray }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'space_definition':
        return (
          <SpaceDefinition
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            isModifiedField={ isModifiedField }
            numberFormat={ numberFormat }
            field={ field }
            originalField={ originalFormField }
            errors={ errors }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ (modifiedValues: FormValues) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data', 'values'], modifiedValues), config.tabIndex);
            } }
            onFieldChange={ (field: FormField) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data'], field), config.tabIndex);
            } }
          />
        );

      case 'calculated':
        return (
          <Calculated
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            originalState={ originalState }
            field={ field }
            state={ state }
            config={ config }
            border
          />
        );

      case 'dynamic_field_template':
        return (
          <DynamicFieldTemplateSelect
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            field={ field }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            originalState={ originalStateArray }
            border
            canEdit={ hasPermission(record.permissions, 'dynamic_field_template_edit') }
          />
        );

      case 'clause':
        return (
          <Clause
            isNew={ isNew }
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            originalValues={ originalStateArray }
            field={ field }
            clientId={ clientId }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onValueChange={ (modifiedValues: FormValues) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data', 'values'], modifiedValues), config.tabIndex);
            } }
            onFieldChange={ (field: FormField) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data'], field), config.tabIndex);
            } }
            setLoading={ (isLoading: boolean) => this.setState({ isModifying: isLoading }) }
          />
        );

      case 'key_date':
        return (
          <KeyDate
            isNew={ isNew }
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            originalValues={ originalStateArray }
            field={ field }
            clientId={ clientId }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onValueChange={ (modifiedValues: FormValues) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data', 'values'], modifiedValues), config.tabIndex);
            } }
            onFieldChange={ (field: FormField) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data'], field), config.tabIndex);
            } }
            setLoading={ (isLoading: boolean) => this.setState({ isModifying: isLoading }) }
          />
        );

      case 'activity_management':
        return (
          <ActivityManagement
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            originalValues={ originalStateArray }
            field={ field }
            record={ record }
            clientId={ clientId }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            canEditActualCompletionDate={ hasPermission(record.permissions, 'bypass_access_checks') }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onValueChange={ (modifiedValues: FormValues, callback: () => void) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data', 'values'], modifiedValues), config.tabIndex, undefined, undefined, callback);
            } }
          />
        );

      case 'activity_template':
        return (
          <ActivityTemplateSelect
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            field={ field }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            originalState={ originalStateArray }
            border
          />
        );

      case 'role':
        return (
          <Role
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            field={ field }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            originalState={ originalStateArray }
            border
          />
        );

      case 'maintenance_schedule':
        return (
          <MaintenanceSchedule
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            originalValues={ originalStateArray }
            field={ field }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onValueChange={ (modifiedValues: FormValues) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data', 'values'], modifiedValues), config.tabIndex);
            } }
          />
        );

      case 'asset_type':
        return (
          <AssetTypeSelection
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            record={ record }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            field={ field }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            originalValues={ originalStateArray }
            border
          />
        );

      case 'cost':
        return (
          <Cost
            isNew={ isNew }
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            originalValues={ originalStateArray }
            field={ field }
            numberFormat={ numberFormat }
            height={ 400 }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onValueChange={ (modifiedValues: FormValues[]) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data', 'values'], modifiedValues), config.tabIndex);
            } }
            border
          />
        );

      case 'cost_template':
        return (
          <CostTemplate
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            originalValues={ originalStateArray }
            field={ field }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            border
          />
        );

      case 'dynamic_field_component_relationship':
        return (
          <DynamicFieldComponentRelationship
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            field={ field }
            state={ state }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            border
          />
        );

      case 'color':
        return (
          <Color
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            field={ field }
            config={ config }
            originalValues={ originalStateArray }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            onChange={ this.handleChange }
          />
        );

      case 'frequency':
        return (
          <Frequency
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            numberFormat={ numberFormat }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            originalState={ originalState }
            field={ field }
            state={ state }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );

      case 'timeline':
        return (
          <Timeline
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            numberFormat={ numberFormat }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            originalStateArray={ originalStateArray }
            field={ field }
            stateArray={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onFieldChange={ (field: FormField) => {
              this.props.returnData(set(cloneDeep(this.state.form), ['groups', config.groupIndex, 'elements', config.elementIndex, 'data'], field), config.tabIndex);
            } }
            border
          />
        );

      case 'coa':
        return (
          <CoaSelection
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            field={ field }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            originalValues={ originalStateArray }
            border
          />
        );

      case 'resource':
        return (
          <Resource
            key={ `${field.type}-${field.id}-${config.tabIndex}-${config.groupIndex}-${config.elementIndex}` }
            clientId={ clientId }
            record={ record }
            field={ field }
            originalState={ originalStateArray }
            state={ stateArray }
            config={ config }
            isDisabled={ isDisabled || !!field.config.locked }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            onChange={ this.handleChange }
            onRefreshForm={ (field_id: string) => this.handleRefreshForm(clientId, record, config, field_id) }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            validate={ validate }
            border
          />
        );
    }
  };

  renderRelation = (element: FormElement): React.ReactNode => {
    const { record, clientId } = this.props;
    return(
      <Relation
        key={ `${element.type}-${element.id}` }
        clientId={ clientId }
        record={ record }
        element={ element }
      />
    );
  };

  renderDivider = (element: FormElement): React.ReactNode => {
    return (
      <FieldWrapper
        key={ `${element.type}-${element.id}` }
        col={ element.config.column_span }
        style={{ minHeight: 'inherit' }}
      >
        <Divider
          orientation={ element.config.orientation }
          text={ element.config.text }
        />
      </FieldWrapper>
    );
  };

  renderView = (element: FormElement): React.ReactNode => {
    const { clientId } = this.props;
    let viewType = element?.config?.view_type;

    if (element.type === 'report') {
      viewType = 'report';
    }

    return (
      <FieldWrapper
        key={ `${element.type}-${element.id}` }
        col={ element.config.column_span }
      >
        <View
          viewType={ viewType }
          clientId={ clientId }
          element={ element }
          record={ this.props.record }
          getRecord={ this.props.getRecord }
        />
      </FieldWrapper>
    );
  };

  renderEmptyElement = (element: FormElement): React.ReactNode => {
    return (
      <FieldWrapper
        key={ `${element.type}-${element.id}` }
        col={ element.config.column_span }
      >
        <Empty
          image={ Empty.PRESENTED_IMAGE_SIMPLE }
          description={ element?.config?.description || 'No data' }
        />
      </FieldWrapper>
    );
  };

  render = () => {
    const { form, isModifying } = this.state;
    const groups = form.groups || [];
    return (
      <div id='FormBuilder'>
        { isModifying && <OverlaySpinner /> }
        { groups.map(this.renderGroup) }
      </div>
    );
  };
}

export default FormBuilder;
