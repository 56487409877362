// Libs
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

// Views
import Properties from 'views/portfolio/property/Properties';
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

class PropertyContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Route exact path="/portfolio/properties" component={ Properties } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

export default PropertyContainer;
