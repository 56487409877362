// Libs
import React from 'react';

// Components
import { Divider, Space, Input, Typography } from 'antd';

// Icons
import { PlusOutlined } from '@ant-design/icons';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

// Styles
import './Clause.scss';


const API: Api = new Api();

interface Props {
  clientId: number;
  onUpdate: (clauses: any[], targetId: number) => void;
  setLoading: (value: boolean) => void;
};

interface State {
  newClauseTitle: string;
};

export default class CreateClauseDropdown extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    newClauseTitle: '',
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  onCreate = async (title: string) => {
    const { clientId, onUpdate, setLoading } = this.props;
    try {
      setLoading(true);
      const response = await API.post(`client/${clientId}/category/lease-clause/create`, {
        data: { title: title, type: 'lease_clause', bundle: 'category' }
      });
      onUpdate(response.data, response.clause_id);
      Notification('success', '', 'Clause successfully created');
    } catch (error) {
      console.error(`Error: `, error);
      Notification('error', 'Failed to create Clause', 'Failed');
    } finally {
      setLoading(false);
      this.mounted && this.setState({ newClauseTitle: '' });
    }
  };

  render = () => {
    const { newClauseTitle } = this.state;

    return (
      <>
        <Divider className={ 'mY-8' } />
        <Space align={ 'center' } className={ 'pX-8 pB-4' }>
          <Input
            placeholder={ 'Please enter title' }
            value={ newClauseTitle }
            onChange={ (e) => this.mounted && this.setState({ newClauseTitle: e.target.value }) }
          />
          <Typography.Link
            onClick={ (e) => {
              e.preventDefault();
              if (newClauseTitle.trim().length) {
                this.onCreate(newClauseTitle);
              }
            } }
            className={ 'whs-nw' }
          >
            <PlusOutlined /> Add item
          </Typography.Link>
        </Space>
      </>
    );
  };
}
