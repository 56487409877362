// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import TimeRangePicker from 'components/timerangepicker';
import { Switch, TimePicker, Table, Dropdown, Menu, Button } from 'antd';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
} from "components/form/form-wrapper";
import { Types } from './Time.interface';

const presets = [
  {
    title: '9-5',
    reference: '9_5',
    preset: [
      { day: 1, start: '09:00:00', end: '17:00:00' },
      { day: 2, start: '09:00:00', end: '17:00:00' },
      { day: 3, start: '09:00:00', end: '17:00:00' },
      { day: 4, start: '09:00:00', end: '17:00:00' },
      { day: 5, start: '09:00:00', end: '17:00:00' },
    ],
  },
  {
    title: '24/7',
    reference: '24_7',
    preset: [
      { day: 1, start: '00:00:00', end: '24:00:00' },
      { day: 2, start: '00:00:00', end: '24:00:00' },
      { day: 3, start: '00:00:00', end: '24:00:00' },
      { day: 4, start: '00:00:00', end: '24:00:00' },
      { day: 5, start: '00:00:00', end: '24:00:00' },
      { day: 6, start: '00:00:00', end: '24:00:00' },
      { day: 7, start: '00:00:00', end: '24:00:00' },
    ],
  }
];

interface Props {
  field: FormField;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string
  ): void;
  originalState: any;
  state: any;
  type?: Types;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

class TimeField extends Component<Props> {

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, state, config, onChange } = this.props;

    if (!_.isEqual(originalState, state)) {
      onChange(field, [originalState], config);
      this.validate(originalState);
    }
  };

  validate = (state: any) => {
    const { originalState } = this.props;

    this.generateModifiedState(originalState, state);
    this.generateErrorState(state);
  };

  getWeekDay = (day: number): string => {
    const days: any = {
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      7: 'Sunday',
    };
    return days[day];
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string = 'value') => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue)) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: field.id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (newValue: any, columnKey: string = 'value') => {
    const { field, config, setFieldErrorMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    let errors: string[] = [];

    if (field.config.required && !newValue) {
      errors.push('Cannot be empty');
    }

    if (!_.isEmpty(errors)) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: field.id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  renderStartEnd = (value: any, errors: any[], isModified: boolean) => {
    const { field, config, isDisabled, onChange } = this.props;
    const start: any = _.has(value, 'start') && !!value.start ? moment(value.start, 'HH:mm') : null;
    const end: any = _.has(value, 'end') && !!value.end ? moment(value.end, 'HH:mm') : null;
    return (
      <TimePicker.RangePicker
        className={ classNames('Field', {
          'Field--has-warning border-warning': isModified && _.isEmpty(errors),
        }) }
        format={ 'HH:mm' }
        allowClear={ !field.config.required }
        value={ start && end ? [start, end] : undefined }
        disabled={ isDisabled }
        onChange={ (values: any) => {
          onChange(field, [{
            start: !!values ? values[0].format('HH:mm:ss') : null,
            end: !!values ? values[1].format('HH:mm:ss') : null,
            day: null
          }], config);
        }}
      />
    );
  };

  renderStartOnly = (value: any, errors: any[], isModified: boolean) => {
    const { field, config, isDisabled, onChange } = this.props;
    return (
      <TimePicker
        className={ classNames('Field', {
          'Field--has-warning border-warning': isModified && _.isEmpty(errors),
        }) }
        format={ 'HH:mm' }
        allowClear={ !field.config.required }
        value={ _.has(value, 'start') && !!value.start ? moment(value.start, 'HH:mm') : undefined }
        disabled={ isDisabled }
        onChange={ (time: moment.Moment | null) => {
          onChange(field, [{
            start: time ? time.format('HH:mm:ss') : null,
            end: null,
            day: null
          }], config);
        }}
      />
    );
  };

  renderWeeklySchedule = (values: any[], errors: any[], isModified: boolean) => {
    const { field, config, isDisabled, onChange } = this.props;
    const data: any = [1, 2, 3, 4, 5, 6, 7].map((day: number) => {
      const _day = !_.isEmpty(values) && values.find((_day: any) => _day.day === day);
      if (!!_day) {
        return {
          key: _day.day,
          ..._day,
          disabled: false,
        };
      } else {
        return {
          key: day,
          ...{
            day: day,
            start: '09:00:00',
            end: '17:30:00',
            disabled: true,
          }
        };
      }
    });
    const columns = [
      {
        title: '',
        width: 150,
        render: (row: any) => {
          return <span>{ this.getWeekDay(row.day) }</span>;
        }
      },
      {
        title: '',
        width: 150,
        render: (row: any) => {
          return (
            <span>
              <Switch
                disabled={ isDisabled }
                checked={ !row.disabled }
                onChange={ (checked: boolean) => {
                  let _values = _.cloneDeep(values);

                  // Delete or Create
                  if (!checked) {
                    _values = values.filter((value: any) => value.day !== row.day);
                  } else {
                    _values.push({
                      day: row.day,
                      start: '09:00:00',
                      end: '17:30:00',
                    });
                  }

                  onChange(field, _values, config);
                }}
              />
              <span className="mL-5">{ !row.disabled ? 'Open' : 'Closed' }</span>
            </span>
          );
        }
      },
      {
        title: '',
        render: (row: any) => {
          if (!!row.disabled) return <></>;

          const start: any = _.has(row, 'start') && !!row.start ? row.start : null;
          const end: any = _.has(row, 'end') && !!row.end ? row.end : null;

          return (
            <TimeRangePicker
              isDisabled={ !!isDisabled }
              onChangeTime={ (times: any[]) => {
                const index = values.findIndex((value: any) => value.day === row.day);
                const _values = _.cloneDeep(values);
                _values[index] = {
                  day: row.day,
                  start: !!times ? times[0]: null,
                  end: !!times ? times[1]: null,
                };
                onChange(field, _values, config);
              } }
              values={[start, end]}
            />
          );
        }
      },
    ];
    return (
      <Table
        size={ 'small' }
        showHeader={ false }
        columns={ columns }
        dataSource={ data }
        pagination={ false }
      />
    );
  };

  renderComponent = (state: any, format: string, errors: any[], isModified: boolean) => {
    switch (format) {
      case 'start_end':
        return this.renderStartEnd(state[0], errors, isModified);
      case 'start_only':
        return this.renderStartOnly(state[0], errors, isModified);
      case 'weekly_schedule':
        return this.renderWeeklySchedule(state, errors, isModified);
    }
  };

  render = () => {
    const {
      field,
      state,
      config,
      isDisabled,
      border,
      fieldErrorMessages,
      fieldModifiedMessages,
      onChange,
    } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_value`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const format: string = field.config.format || '';

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ errors }
        required={ !!field.config.required }
        border={ border }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
        isModified={ isModified }
        rightActions={ format === 'weekly_schedule' && !isDisabled? [
          {
            node: (
              <Dropdown
                className="mB-10"
                trigger={ ['click'] }
                overlay={
                  <Menu>
                    { presets.map((preset: any) => (
                      <Menu.Item
                        key={ preset.reference }
                        onClick={ () => {
                          onChange(field, preset.preset, config);
                        } }
                      >
                        { preset.title }
                      </Menu.Item>
                    ) ) }
                  </Menu>
                }
              >
                <Button>Presets</Button>
              </Dropdown>
            )
          }
        ] : [] }
      >
        { this.renderComponent(state, format, errors, isModified) }
      </FieldWrapper>
    );
  };

};

export default TimeField;
