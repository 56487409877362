// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Button, Form, Tooltip, TreeSelect, Upload } from 'antd';

// Icons
import { InboxOutlined } from '@ant-design/icons';

// Utils
import { findFirst, isBlank } from 'utils/utils';

// Interfaces
import { Folder, Document } from './UploadDialog.interfaces';
import { UploadFile } from 'antd/lib/upload/interface';

interface Props {
  onSave: (files: UploadFile[], folder: Folder) => void;
  documents: Document[];
  defaultFolderId?: number;
};

interface State {
  fileList: UploadFile<any>[];
  folder_id: number | null;
};

class UploadForm extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    fileList: [],
    folder_id: null,
  };

  componentDidMount = async () => {
    this.mounted = true;

    const { defaultFolderId, documents } = this.props;

    if (defaultFolderId) {
      // Set Default Folder
      this.setDefaultFolder(defaultFolderId, documents);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  setDefaultFolder = (defaultFolderId: number, documents: Document[]) => {
    const folders = this.mapFolders(documents);
    const folderId = folders.find(folder => folder.id === defaultFolderId)?.id;

    this.mounted && !!folderId && this.setState({
      folder_id: folderId
    });
  };

  mapFolders = (data: Document[] = []): Folder[] => {
    if (_.isEmpty(data)) return [];

    return data
      .filter(
        (document: Document) => document.type === 'folder' && document.id !== null
      )
      .map((entity: Document) => {
        return {
          key: `${entity.id}`,
          id: entity.id,
          value: entity.id,
          title: entity.title,
          context: entity.context,
          selectable: !entity.config.view_only,
          disabled: !!entity.config.view_only,
          children: this.mapFolders(entity.children),
        };
      });
  };

  renderUploadForm = (): JSX.Element => {
    const { onSave, defaultFolderId, documents } = this.props;
    const { fileList, folder_id } = this.state;

    const folders: Folder[] = this.mapFolders(documents);

    const disabledUploadBtn: boolean = _.isEmpty(fileList) || isBlank(folder_id);

    // should not be possible to change the download folder if a default folder is passed
    // and only one folder is available
    const disabledFolderSelect: boolean = !isBlank(folder_id) && (folder_id === defaultFolderId) && folders.length === 1;

    const onUpload = () => {
      const folder: Folder = findFirst({ children: folders }, 'children', { id: folder_id });
      onSave(fileList, folder);
    };

    return (
      <Form
        className="pL-100 pR-100"
        name="upload"
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item label="Folder">
          <TreeSelect
            open={ disabledFolderSelect ? false : undefined }
            style={{ width: '100%' }}
            treeDefaultExpandAll
            treeData={ folders }
            onChange={ (folder_id: Folder['id']) => this.mounted && this.setState({ folder_id: folder_id }) }
            placeholder="Please select"
            value={ folder_id || undefined }
          />
        </Form.Item>
        <Form.Item
          name="files"
          valuePropName="fileList"
          getValueFromEvent={ (e: any) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          } }
          rules={[
            {
              required: true,
              message: 'Required',
            },
          ]}
        >
          <Upload.Dragger
            multiple
            fileList={ fileList }
            beforeUpload={ () => false }
            onChange={ ({ fileList }) => this.mounted && this.setState({ fileList }) }
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item className="ta-r">
          <Tooltip
            placement="left"
            title={ 'Please select Folder and File(s) to upload.' }
            visible={ disabledUploadBtn ? undefined : false }
          >
            <Button
              type="primary"
              onClick={ onUpload }
              disabled={ disabledUploadBtn }
            >
              Upload
            </Button>
          </Tooltip>
        </Form.Item>
      </Form>
    );
  };

  render = () => {
    return this.renderUploadForm();
  };
}

export default UploadForm;