// Store
import { Reducer } from 'redux';

import NotificationActions from './Actions.type';
import NotificationActionTypes from './ActionTypes.enum';
import NotificationState from './State.interface';

const initialNotificationState: NotificationState = {
  total: null,
  unread_total: 0,
  notifications: [],
  isFetching: false,
};

const NotificationReducer: Reducer<NotificationState, NotificationActions> = (
  state = initialNotificationState,
  action
) => {
  switch (action.type) {
    case NotificationActionTypes.GET_NOTIFICATIONS_START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case NotificationActionTypes.GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        total: action.payload.total,
        unread_total: action.payload.unread_total,
        notifications: action.payload.notifications,
      };
    }
    case NotificationActionTypes.GET_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }
    case NotificationActionTypes.READ_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        total: action.payload.total,
        unread_total: action.payload.unread_total,
        notifications: action.payload.notifications,
      };
    }
    case NotificationActionTypes.DELETE_NOTIFICATIONS_START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case NotificationActionTypes.DELETE_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        total: action.payload.total,
        unread_total: action.payload.unread_total,
        notifications: action.payload.notifications,
      };
    }
    case NotificationActionTypes.DELETE_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }
    case NotificationActionTypes.POLL_NOTIFICATIONS_START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case NotificationActionTypes.POLL_NOTIFICATIONS_END: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case NotificationActionTypes.GET_NOTIFICATIONS_COUNT_START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case NotificationActionTypes.GET_NOTIFICATIONS_COUNT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        unread_total: action.payload.unread_total,
      };
    }
    case NotificationActionTypes.GET_NOTIFICATIONS_COUNT_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }
    default:
      return state;
  }
};

export default NotificationReducer;
