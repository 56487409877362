// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import _ from 'lodash';

// Components
import Jumbotron from "components/jumbotron";
import BlockingSpinner from 'components/blocking-spinner';
import Dropdown from 'components/dropdown';
import { DeleteOutlined } from '@ant-design/icons';
import BasicList from 'components/basic-list';
import { Tree, Modal, Form, Select, Table, Checkbox, Button } from 'antd';
import { RestrictionHoC } from 'components/restriction';
import Badge, { getBadgeType } from 'components/badge';

// Views
import CoverageTab from 'views/admin/roles/CoverageTab';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { RoleRecord } from './Role.interfaces';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";

// Api
import { Api } from 'services/api';

// Styles
import 'assets/styles/_layout.scss';

interface Props {
  user: UserEntity;
  permissions: UserPermissions;
  history: Record<string, any>;
  intl: IntlShape;
  match: { params: Record<string, any> };
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  isLoading: boolean;
  isFetchingUsers: boolean;
  roleRecord: RoleRecord | null;
  availableUsers: any[] | null;
  newUsers: any[];
  activeUserId: number | null;
  showAddDialog: boolean;
  showDeleteDialog: boolean;
  availiblePermissions: any[];
  isEditingPermissions: boolean;
  showEditPermissionsDialog: boolean;
  isFetchingPermissions: boolean;
  mutatedPermissions: any;
};

const API: Api = new Api();
const { Option } = Select;

class Role extends React.Component<Props, State> {

  mounted: boolean = false;
  addCoverageFormRef: any = React.createRef();

  state: State = {
    isLoading: false,
    isFetchingUsers: false,
    showAddDialog: false,
    showDeleteDialog: false,
    availableUsers: [],
    newUsers: [],
    activeUserId: null,
    roleRecord: null,
    availiblePermissions: [],
    isEditingPermissions: false,
    showEditPermissionsDialog: false,
    isFetchingPermissions: false,
    mutatedPermissions: null,
  };

  componentDidMount = async () => {
    const { user: { active_client }, setBreadcrumbs } = this.props;
    const role_id = this.props.match.params.id;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Roles', path: '/admin/roles' },
    ], false);

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));

      const role = await API.get(`client/${active_client}/admin/roles/${role_id}`);

      this.mounted && this.setState({
        roleRecord: role,
      }, () => {
        setBreadcrumbs([
          { title: 'Home', path: '/' },
          { title: 'Admin', path: '/admin' },
          { title: 'Roles', path: '/admin/roles' },
          { title: role.title, path: null },
        ], false);
      });

    } catch {
      console.error('Failed');
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { user: { active_client } } = this.props;

    if (prevState.isFetchingUsers !== this.state.isFetchingUsers) {
      const role_id = this.props.match.params.id;

      API.getAvailableRoleUsers(active_client, role_id)
        .then((availableUsers) => {
          this.mounted && this.setState({
            availableUsers: availableUsers,
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => this.mounted && this.setState({
          isFetchingUsers: false
        }));
    }

    if (prevState.isFetchingPermissions !== this.state.isFetchingPermissions && !!this.state.isFetchingPermissions) {
      try {
        const role_id = this.props.match.params.id;
        const availiblePermissions = await API.get(`client/${active_client}/admin/roles/available_permissions?role_id=${role_id}`);

        this.mounted && this.setState({
          availiblePermissions: availiblePermissions,
        });

      } catch {
        console.error('Failed');
      } finally {
        this.mounted && this.setState({
          isFetchingPermissions: false
        });
      }
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  convertFolderPermissionMap = (folders: any) => {
    return folders && folders.map((folder: any) => {
      return {
        'key': `ref_${folder.reference}`,
        'id': folder.id,
        'title': folder.title,
        'children': folder.permissions.map((permission: any) => {
          return {
            'key': permission.id,
            'id': permission.id,
            'title': permission.title,
          };
        })
      };
    });
  };

  renderAddDialog = () => {
    const { user: { active_client } } = this.props;
    const {
      availableUsers,
      newUsers,
      isFetchingUsers,
      roleRecord
    } = this.state;
    return (
      <Modal
        visible
        title={'Add User(s)'}
        onOk={() => this.mounted && this.setState({
            isLoading: true,
            showAddDialog: false,
            isFetchingUsers: false
          }, () => {

            if (!roleRecord) return;

            // Get new users record from ids
            const newUsersRecord = newUsers.map((user_id: number) => {
              return availableUsers && availableUsers.find((availableUser: UserEntity) => {
                return user_id === availableUser.id;
              });
            });

            // Merge in users to role record
            const updatedRecord = _.set(roleRecord, 'users', _.unionBy(newUsersRecord, roleRecord.users, 'id'));

            roleRecord && roleRecord.id && API.updateRole(active_client, roleRecord.id, updatedRecord)
              .then((roles) => {
                this.mounted && this.setState({
                  roleRecord: roles.find((role: RoleRecord) => role.id === roleRecord.id),
                  newUsers: []
                });
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => this.mounted && this.setState({
                isLoading: false
              }));
          }
        )}
        onCancel={() => this.mounted && this.setState({
          showAddDialog: false,
          isFetchingUsers: false,
          newUsers: [],
        })}
        okButtonProps={{
          disabled: _.isEmpty(newUsers)
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Users" required>
            <Select
              mode="multiple"
              showSearch
              style={{ width: '100%' }}
              loading={ isFetchingUsers }
              disabled={ isFetchingUsers }
              placeholder={'Select User(s)'}
              value={newUsers}
              filterOption={(input: string, option: any) => {
                return option?.children.some((child: any) => {
                  return child.toLowerCase().includes(input.toLowerCase());
                });
              }}
              onChange={(value: any) => {
                this.mounted && this.setState({
                  newUsers: value
                });
              }}
            >
              {availableUsers && availableUsers.map((user: UserEntity) => (
                <Option key={user.id} value={user.id}>{user.first_name} {user.last_name} ({user.email})</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderDeleteUserDialog = (user_id: any) => {
    const { user: { active_client } } = this.props;
    const { roleRecord } = this.state;

    const user: any = roleRecord && roleRecord.users && roleRecord.users.find((user: UserEntity) => user.id === user_id);

    return (
      <Modal
        visible
        title={'Remove User'}
        onOk={() => this.mounted && this.setState({
          isLoading: true,
          showDeleteDialog: false,
        }, () => {
          roleRecord && roleRecord.id && API.deleteUserFromRole(active_client, roleRecord.id, user_id)
            .then((role) => {
              this.mounted && this.setState({
                roleRecord: role
              });
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => this.mounted && this.setState({
              isLoading: false
            }));
        }
        )}
        onCancel={() => this.setState({ showDeleteDialog: false, activeUserId: null })}
      >
        <p>Are you sure you want to remove <b>{user.first_name} {user.last_name}</b> from the <b>{ roleRecord && roleRecord.title ? `${roleRecord.title} ` : '' }</b>role?</p>
      </Modal>
    );
  };

  renderEditPermissionsDialog = (availiblePermissions: any[] | null ) => {
    const { user: { active_client } } = this.props;
    const role_id = this.props.match.params.id;
    const {
      roleRecord,
      isFetchingPermissions,
      mutatedPermissions,
      isEditingPermissions
    } = this.state;

    if (!availiblePermissions || _.isEmpty(availiblePermissions)) return <></>;

    const defaultKeys: any = [];
    roleRecord && roleRecord.permissions && roleRecord.permissions.forEach((folder: any) => {
      return folder.permissions.forEach((_permission: any) => {
        defaultKeys.push(_permission.id);
      });
    });

    const folders = this.convertFolderPermissionMap(availiblePermissions);

    return (
      <Modal
        visible
        title={ 'Permissions' }
        onOk={() => this.mounted && this.setState({
            isEditingPermissions: true,
          }, async () => {
            try {

              // Filter out folders: i.e `ref_` strings
              const newPermissions = mutatedPermissions.filter((permission: any) => _.isNumber(permission));

              await API.put(`client/${active_client}/admin/roles/${role_id}/permissions`, { data: newPermissions });
              const role = await API.get(`client/${active_client}/admin/roles/${role_id}`);

              this.mounted && this.setState({
                roleRecord: role,
              });

            } catch (error) {
              console.error(error);
            } finally {
              this.mounted && this.setState({
                isEditingPermissions: false,
                showEditPermissionsDialog: false,
                mutatedPermissions: null,
                availiblePermissions: [],
              });
            }
          }
        )}
        onCancel={() => this.setState({
          showEditPermissionsDialog: false,
          mutatedPermissions: null,
          availiblePermissions: [],
        })}
        maskClosable={ !isFetchingPermissions || !isEditingPermissions }
        okButtonProps={{
          loading: isFetchingPermissions || isEditingPermissions,
          disabled: isFetchingPermissions || !mutatedPermissions,
        }}
        cancelButtonProps={{
          disabled: isFetchingPermissions || isEditingPermissions,
        }}
        okText={ 'Save' }
      >
        <div className="ov-s" style={{ maxHeight: '40vh' }}>
          <Tree
            selectable={ false }
            defaultCheckedKeys={ defaultKeys || [] }
            checkable
            onCheck={ (permissions: any) => this.setState({ mutatedPermissions: permissions }) }
            treeData={ folders || [] }
          />
        </div>
      </Modal>
    );
  };

  renderUsers = (roleRecord: RoleRecord | null) => {
    const columns = [
      {
        key: 'first_name',
        dataIndex: 'first_name',
        title: 'First Name',
        filterable: true,
        sorter: true,
        ellipsis: true,
      },
      {
        key: 'last_name',
        dataIndex: 'last_name',
        title: 'Last name',
        filterable: true,
        sorter: true,
        ellipsis: true,
      },
      {
        key: 'email',
        dataIndex: 'email',
        title: 'Email',
        sorter: true,
        ellipsis: true,
        filterable: true,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        render: (_: any, user: any) => {
          return <DeleteOutlined className="mL-10 link" style={{ fontSize: 18 }} onClick={() => this.setState({ showDeleteDialog: true, activeUserId: user.id })} />;
        },
        sorter: false,
        ellipsis: true,
        filterable: false,
        align: 'right'
      },
    ];

    const items = roleRecord && roleRecord.users && roleRecord.users.map((user: any, index: number) => {
      return {
        'key': index,
        'id': user.id,
        'first_name': user.first_name,
        'last_name': user.last_name,
        'email': user.email,
      };
    });

    const actions = [
      {
        node: (
          <Button onClick={ () => this.setState({ showAddDialog: true, isFetchingUsers: true }) }>
            Add User
          </Button>
        )
      }
    ];

    return (
      <div className="Layout-box pB-20">
        <BasicList
          rawData
          columns={ columns }
          items={ items || [] }
          rightActions={ actions }
        />
      </div>
    );
  };

  renderPermissions = (roleRecord: any) => {
    const { isFetchingPermissions } = this.state;

    const folders = this.convertFolderPermissionMap(roleRecord?.permissions);

    const columns = [
      {
        title: 'Permissions',
        render: (record: any) => {
          return <>{ record.title }</>;
        }
      },
      {
        title: 'In Use',
        render: () => {
          return <Checkbox disabled checked />;
        }
      },
    ];

    return (
      <div className="Layout-box pB-20">
        <div className="ta-r">
          <Dropdown
            actions={[
              {
                isLoading: isFetchingPermissions,
                node: 'Edit Permissions',
                onClick: () => this.setState({ showEditPermissionsDialog: true, isFetchingPermissions: true })
              }
            ]}
          />
        </div>
          <Table
            size={ 'small' }
            className="cur-p"
            columns={ columns }
            dataSource={ folders || [] }
            expandable={{
              defaultExpandAllRows: true,
              expandRowByClick: true,
            }}
            pagination={ false  }
          />
      </div>
    );
  };

  render = () => {
    const { user: { active_client } } = this.props;
    const {
      roleRecord,
      isLoading,
      showAddDialog,
      showDeleteDialog,
      activeUserId,
      availiblePermissions,
      showEditPermissionsDialog,
    } = this.state;

    if (!isLoading && !roleRecord) return <p>No role found</p>;

    return (
      <BlockingSpinner isLoading={ isLoading }>
        { roleRecord &&
          <Jumbotron
            content={
              <>
                <p className="mB-0">
                  { roleRecord.title } <Badge type={ getBadgeType(roleRecord.type || '') } text={ _.startCase(_.toLower(roleRecord.type || '')).split('_').join(' ') } />
                </p>
                <p className="mB-0" style={{ fontSize: 12 }}>
                  { roleRecord.description }
                </p>
              </>
            }
            tabs={[
              {
                label: 'Global Users',
                node: this.renderUsers(roleRecord),
              },
              {
                label: 'Permissions',
                node: this.renderPermissions(roleRecord),
              },
              {
                label: 'Coverage',
                node: <CoverageTab roleRecord={ roleRecord } clientId={ active_client } />,
              }
            ]}
          />
        }
        { showDeleteDialog && this.renderDeleteUserDialog(activeUserId) }
        { showAddDialog && this.renderAddDialog() }
        { showEditPermissionsDialog && availiblePermissions && this.renderEditPermissionsDialog(availiblePermissions) }
      </BlockingSpinner>
    );

  };

};

const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(Role), 'access_admin_roles'));