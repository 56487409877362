// Libs
import React from 'react';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import DragSortingList from 'components/drag-sorting-list';
import { Menu, Dropdown as AntDropdown, Button, Spin } from 'antd';
import AddTabDialog from './AddTabDialog';
import AddGroupDialog from './AddGroupDialog';
import AddElementDialog from './AddElementDialog';
import Badge, { BadgeType } from 'components/badge';

// Service
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import { Config, TableType } from 'components/drag-sorting-list/DragSortingList.interfaces';
import {
  ElementTypes,
  EntityField,
  EntityRole,
  Field,
  FormElement,
  FormGroup,
  FormTab,
  Relation,
  RecordKey
} from 'views/admin/entity/Entity.interfaces';

// Utils
import { getOrder, moveRow, moveRowInEmptyTable } from 'utils/formSetup';
import { transformFieldTitle } from 'utils/workflow';

interface Props {
  clientId: number;
  entityType: string;
  entityBundle: string;
  mappedFields: EntityField[];
  fields: Field[];
  formTabs: FormTab[];
  entityFields: EntityField[];
  relations: Relation[];
  entityRoles: EntityRole[];
  isFetchingFormTabs: boolean;
  onFormChange: (data: FormTab[]) => void;
};

interface State {
  expandedTabKeys: string[];
  expandedGroupKeys: string[];
  newTab: Partial<{ id: number; title: string; config: any }>;
  newGroup: Partial<{ id: number; tabId: number; title: string; config: any }>;
  newElement: Partial<{
    id: number;
    tabId: number;
    groupId: number;
    type: ElementTypes;
    hidden: boolean;
    config: any;
    dependencies: Array<any>;
  }>;
  isReordering: boolean;
  isEditingTab: boolean;
  isEditingGroup: boolean;
  isEditingElement: boolean;
  showAddTabDialog: boolean;
  showAddGroupDialog: boolean;
  showAddElementDialog: boolean;
};

const API: Api = new Api();

class FormSetupTab extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    expandedTabKeys: [],
    expandedGroupKeys: [],
    newTab: {},
    newGroup: {},
    newElement: {},
    isReordering: false,
    isEditingTab: false,
    isEditingGroup: false,
    isEditingElement: false,
    showAddTabDialog: false,
    showAddGroupDialog: false,
    showAddElementDialog: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  moveRowHandler = (dragIndex: number, hoverIndex: number, sourceTableConfig: Config, targetTableConfig: Config) => {
    const { formTabs } = this.props;
    if (!_.isNumber(dragIndex) || !_.isNumber(hoverIndex)) {
      return;
    }
    const updatedFormTabs = moveRow(formTabs, dragIndex, hoverIndex, sourceTableConfig, targetTableConfig);
    this.reorderFormHandler(sourceTableConfig.type, updatedFormTabs);
  };

  moveRowInEmptyTableHandler = (dragIndex: number, sourceTableConfig: Config, targetTableConfig: Config) => {
    const { formTabs } = this.props;
    const updatedFormTabs = moveRowInEmptyTable(formTabs, dragIndex, sourceTableConfig, targetTableConfig);
    this.reorderFormHandler(sourceTableConfig.type, updatedFormTabs);
  };

  reorderFormHandler = (type: TableType, newFormState: FormTab[]) => {
    const { clientId, formTabs, onFormChange } = this.props;

    if(!_.isEqual(getOrder(newFormState), getOrder(formTabs))){
      this.mounted && this.setState({ isReordering: true }, async () => {
        try {
          const payload = getOrder(newFormState);
          const response = await API.put(`client/${clientId}/admin/entity/form/reorder`, { data: payload });
          onFormChange(response);
          Notification('success', `${type} Moved`, 'Successful');
        } catch (error) {
          Notification('error', `Failed To Save`, 'Failed');
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({ isReordering: false });
        }
      });
    }
  };

  renderFormTabs = () => {
    const { formTabs } = this.props;
    const { isReordering, expandedTabKeys } = this.state;

    const columns: any = [
      {
        key: 'title',
        dataIndex: 'title',
        title: 'Tabs',
        ellipsis: true,
      },
      {
        key: 'config',
        dataIndex: 'config',
        title: 'Default State',
        render: (config: { open: boolean }) => (
          <Badge
            type={ !!config.open ? BadgeType.Success : BadgeType.Disabled }
            text={ !!config.open ? 'Opened' : 'Closed' }
          />
        ),
        ellipsis: true,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: () => <Button onClick={ () => this.setState({ showAddTabDialog: true }) }>Add Tab</Button>,
        render: (__: any, tab: FormTab) => {
          return (
            <AntDropdown.Button
              overlay={
                <Menu>
                  <Menu.Item
                    key="edit"
                    disabled={ false }
                    onClick={ () => {
                      this.setState({
                        showAddTabDialog: true,
                        isEditingTab: true,
                        newTab: {
                          id: tab.id,
                          title: tab.title,
                          config: tab.config,
                        },
                      });
                    } }
                  >
                    Edit Tab
                  </Menu.Item>
                  <Menu.Item
                    key="add"
                    onClick={ () => {
                      this.setState({
                        showAddGroupDialog: true,
                        newGroup: { tabId: tab.id },
                      });
                    } }
                  >
                    Add Group
                  </Menu.Item>
                </Menu>
              }
              trigger={ ['click'] }
            />
          );
        },
        width: 100,
        ellipsis: true,
        align: 'center',
      },
    ];

    const sortedFormTabs = formTabs.sort((a: FormTab, b: FormTab) => a.order - b.order);

    const items = sortedFormTabs.map((row: FormTab, index: number) => {
      return {
        key: row.id,
        id: row.id,
        title: row.title,
        config: row.config,
        groups: row.groups,
      };
    });

    return (
      <Spin indicator={ <BlockingSpinner isLoading /> } spinning={ isReordering }>
        <DragSortingList
          columns={ columns }
          items={ items }
          isParent
          config={{
            type: TableType.Tab,
            references: []
          }}
          moveRow={ this.moveRowHandler }
          moveRowInEmptyTable={ this.moveRowInEmptyTableHandler }
          pagination={ false }
          expandable={{
            expandedRowKeys: expandedTabKeys,
            onExpand: (expanded: boolean, record: FormTab & RecordKey) => {
              const updatedExpandedTabKeys = expanded ? [...expandedTabKeys, record.key] : expandedTabKeys.filter((rowKey) => rowKey !== record.key);
              this.setState({ expandedTabKeys: [...updatedExpandedTabKeys] });
            },
            expandedRowRender: (record: FormTab & RecordKey) => expandedTabKeys.includes(record.key) && this.renderFormGroups(record.groups, [{ type: TableType.Tab, id: record.id }]),
          }}
        />
      </Spin>
    );
  };

  renderFormGroups = (groups: FormGroup[], references: Array<{ type: TableType; id: number }>) => {
    const { entityRoles } = this.props;
    const { expandedGroupKeys } = this.state;
    const columns: any = [
      {
        key: 'title',
        dataIndex: 'title',
        title: 'Groups',
        ellipsis: true,
      },
      {
        key: 'config',
        dataIndex: 'config',
        title: 'Default State',
        render: (config: { open: boolean }) => (
          <Badge
            type={ !!config.open ? BadgeType.Success : BadgeType.Disabled }
            text={ !!config.open ? 'Opened' : 'Closed' }
          />
        ),
        ellipsis: true,
      },
      {
        key: 'visibility',
        dataIndex: 'visibility',
        title: 'Role Visibility',
        render: (__: any, record: any) => {
          if (_.has(record, 'config.roles') && !_.isEmpty(record.config.roles)) {
            const roles = entityRoles.filter((entityRole: EntityRole) => record.config.roles.includes(entityRole.role_id));
            if (roles) {
              return (
                <span>
                  { roles
                     .map<React.ReactNode>(role => <span key={ role.id }>{ role.title }</span>)
                     .reduce((prev, curr) => [prev, ', ', curr]) }
                </span>
              );
            }
          }
          return (
            <span className='color-grey-500'>Visible for everyone</span>
          );
        },
        ellipsis: false,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        render: (__: any, group: FormGroup) => {
          return (
            <AntDropdown.Button
              overlay={
                <Menu>
                  <Menu.Item
                    key="edit"
                    disabled={ false }
                    onClick={ () => {
                      this.setState({
                        showAddGroupDialog: true,
                        isEditingGroup: true,
                        newGroup: {
                          id: group.id,
                          tabId: references[0].id,
                          title: group.title,
                          config: group.config,
                        },
                      });
                    } }
                  >
                    Edit Group
                  </Menu.Item>
                  <Menu.Item
                    key="create"
                    disabled={ false }
                    onClick={ () => {
                      this.setState({
                        showAddElementDialog: true,
                        newElement: {
                          tabId: references[0].id,
                          groupId: group.id,
                        },
                      });
                    } }
                  >
                    Add Element
                  </Menu.Item>
                </Menu>
              }
              trigger={ ['click'] }
            />
          );
        },
        width: 100,
        ellipsis: true,
        align: 'center',
      },
    ];

    const sortedGroups = groups.sort((a: FormGroup, b: FormGroup) => a.order - b.order);

    const items = sortedGroups.map((row: FormGroup, index: number) => {
      return {
        key: row.id,
        id: row.id,
        title: row.title,
        config: row.config,
        elements: row.elements,
      };
    });

    return (
      <DragSortingList
        columns={ columns }
        items={ items }
        pagination={ false }
        config={{
          type: TableType.Group,
          references: references
        }}
        moveRow={ this.moveRowHandler }
        moveRowInEmptyTable={ this.moveRowInEmptyTableHandler }
        expandable={{
          expandedRowKeys: expandedGroupKeys,
          onExpand: (expanded: boolean, record: FormTab & RecordKey) => {
            const updatedExpandedGroupKeys = expanded ? [...expandedGroupKeys, record.key] : expandedGroupKeys.filter((rowKey) => rowKey !== record.key);
            this.setState({ expandedGroupKeys: [...updatedExpandedGroupKeys] });
          },
          expandedRowRender: (record: FormGroup & RecordKey) => expandedGroupKeys.includes(record.key) && this.renderFormElements(record.elements, [...references, { type: TableType.Group, id: record.id }])
        }}
      />
    );
  };

  renderFormElements = (elements: FormElement[], references: Array<{ type: TableType; id: number }>) => {
    const getConfig = (elementType: ElementTypes, config: any): string | null => {
      if (elementType === ElementTypes.Field && !!config?.field) {
        return transformFieldTitle(config.field);
      }
      if (elementType === ElementTypes.Relation && !!config?.field && !!config?.type) {
        return `${ transformFieldTitle(config.type) } - ${ transformFieldTitle(config.field) }`;
      }
      if (elementType === ElementTypes.Foreign && !!config?.relationship && !!config?.field) {
        return `${ transformFieldTitle(config.field) } - ${ transformFieldTitle(config.relationship) }`;
      }
      if (elementType === ElementTypes.View && config?.view_type) {
        return transformFieldTitle(config.view_type);
      }
      if (elementType === ElementTypes.Divider && !!config?.orientation) {
        return _.capitalize(config.orientation);
      }
      if (elementType === ElementTypes.Empty && !!config?.description) {
        return config.description;
      }
      return null;
    };

    const columns: any = [
      {
        key: 'type',
        dataIndex: 'type',
        title: 'Elements',
        render: (type: ElementTypes) => _.capitalize(type),
        ellipsis: true,
      },
      {
        key: 'config',
        dataIndex: 'config',
        title: 'Config',
        render: (config: any, record: FormElement) => getConfig(record.type, config),
        ellipsis: true,
      },
      {
        key: 'columnSpan',
        dataIndex: 'columnSpan',
        title: 'Field Width',
        ellipsis: true,
        align: 'center',
        width: 150,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        render: (__: any, record: FormElement) => {
          if (record.type === ElementTypes.View) return null;
          return (
            <AntDropdown.Button
              overlay={
                <Menu>
                  <Menu.Item
                    key="edit"
                    disabled={ false }
                    onClick={ () => {
                      this.setState({
                        showAddElementDialog: true,
                        isEditingElement: true,
                        newElement: {
                          tabId: references[0].id,
                          groupId: references[1].id,
                          id: record.id,
                          type: record.type,
                          config: record.config,
                          hidden: record.hide_on_create,
                        },
                      });
                    } }
                  >
                    Edit Element
                  </Menu.Item>
                </Menu>
              }
              trigger={ ['click'] }
            />
          );
        },
        width: 100,
        sorter: false,
        ellipsis: true,
        filterable: false,
        align: 'center',
      },
    ];

    const sortedElements = elements.sort((a: FormElement, b: FormElement) => a.order - b.order);

    const items = sortedElements.map((row: FormElement, index: number) => {
      return {
        key: index,
        id: row.id,
        type: row.type,
        config: row.config,
        columnSpan: row.config?.column_span,
        dependencies: row.dependencies,
        hide_on_create: row.hide_on_create,
      };
    });

    return (
      <DragSortingList
        columns={ columns }
        items={ items }
        pagination={ false }
        moveRow={ this.moveRowHandler }
        moveRowInEmptyTable={ this.moveRowInEmptyTableHandler }
        config={{
          type: TableType.Element,
          references: references,
        }}
      />
    );
  };

  renderAddTabDialog = () => {
    const { clientId, entityType, entityBundle, formTabs, onFormChange } = this.props;
    const { newTab, isEditingTab } = this.state;

    const submitHandler = async (title: string, config: any) => {
      try {
        const cloneFormTabs = _.cloneDeep(formTabs);
        const newTabIdx = cloneFormTabs.findIndex((tab) => tab.id === newTab.id);

        if (isEditingTab) {
          const payload = { title, config };
          await API.put(`client/${clientId}/admin/entity/tabs/${newTab.id}`, { data: payload });
          cloneFormTabs[newTabIdx] = { ...cloneFormTabs[newTabIdx], ...payload };
        } else {
          const response = await API.post(`client/${clientId}/admin/entity/tabs`, {
            data: {
              entity_bundle: entityBundle,
              entity_type: entityType,
              title: title,
              config: { open: !!config?.open },
              order: formTabs.length,
              protected: false,
            },
          });
          cloneFormTabs.push(Object.assign(response, { groups: [] }));
        }

        onFormChange(cloneFormTabs);
        Notification('success', `Tab was ${isEditingTab ? 'updated' : 'created'}`, 'Successful');
      } catch (error) {
        Notification('error', `Failed to ${isEditingTab ? 'update' : 'create'} tab`, 'Failed');
        console.error('Error: ', error);
      }
    };

    return (
      <AddTabDialog
        onCancel={ () => {
          this.setState({
            isEditingTab: false,
            showAddTabDialog: false,
            newTab: {},
          });
        } }
        onSubmit={ submitHandler }
        isEditing={ isEditingTab }
        initalState={ _.cloneDeep(newTab) }
        title={ 'Tab' }
      />
    );
  };

  renderAddGroupDialog = () => {
    const { clientId, entityType, entityBundle, formTabs, entityRoles, onFormChange } = this.props;
    const { newGroup, isEditingGroup } = this.state;

    const submitHandler = async (title: string, config: any) => {
      try {
        const cloneFormTabs = _.cloneDeep(formTabs);
        const tabIndex = cloneFormTabs.findIndex((tab) => tab.id === newGroup.tabId);

        if (isEditingGroup) {
          const payload = { title, config };
          await API.put(`client/${clientId}/admin/entity/groups/${newGroup.id}`, { data: payload });
          const groupIndex = cloneFormTabs[tabIndex].groups.findIndex((group) => group.id === newGroup.id);
          cloneFormTabs[tabIndex].groups[groupIndex] = {
            ...cloneFormTabs[tabIndex].groups[groupIndex],
            ...payload,
          };
        } else {
          const response = await API.post(`client/${clientId}/admin/entity/groups`, {
            data: {
              entity_bundle: entityBundle,
              entity_type: entityType,
              title: title,
              config: { open: !!config?.open, roles: config?.roles },
              tab_id: newGroup.tabId,
              order: cloneFormTabs[tabIndex]?.groups?.length || 0,
              protected: false,
            },
          });
          cloneFormTabs[tabIndex].groups.push(Object.assign(response, { elements: [] }));
        }

        onFormChange(cloneFormTabs);
        Notification('success', `Group was ${isEditingGroup ? 'updated' : 'created'}`, 'Successful');
      } catch (error) {
        Notification('error', `Failed to ${isEditingGroup ? 'update' : 'create'} group`, 'Failed');
        console.error('Error: ', error);
      }
    };

    return (
      <AddGroupDialog
        onCancel={ () => {
          this.setState({
            isEditingGroup: false,
            showAddGroupDialog: false,
            newGroup: {},
          });
        } }
        onSubmit={ submitHandler }
        isEditing={ isEditingGroup }
        initalState={ _.cloneDeep(newGroup) }
        title={ 'Group' }
        entityRoles={ entityRoles }
      />
    );
  };

  renderAddElementDialog = () => {
    const { clientId, entityType, entityBundle, mappedFields, fields, formTabs, entityFields, relations, onFormChange } = this.props;
    const { newElement, isEditingElement } = this.state;

    const submitHandler = async (type: string, config: any, hideOnCreate: boolean) => {
      try {
        const { tabId, groupId, id: elementId } = newElement;
        const cloneFormTabs = _.cloneDeep(formTabs);
        const tabIdx = formTabs.findIndex((tab) => tab.id === tabId);
        const groups = cloneFormTabs[tabIdx].groups;
        const groupIdx = groups.findIndex((group) => group.id === groupId);

        if (isEditingElement) {
          const response = await API.put(`client/${clientId}/admin/entity/elements/${elementId}`, {
            data: { type: type, config: config, hide_on_create: hideOnCreate },
          });
          const elementIdx = groups[groupIdx].elements.findIndex((element) => element.id === elementId);
          groups[groupIdx].elements[elementIdx] = response;
        } else {
          const payload = {
            entity_bundle: entityBundle,
            entity_type: entityType,
            group_id: groupId,
            type: type,
            config: config,
            dependencies: [],
            hide_on_create: hideOnCreate,
            order: groups[groupIdx].elements?.length || 0,
            protected: false,
          };
          const response = await API.post(`client/${clientId}/admin/entity/elements`, { data: payload });
          groups[groupIdx].elements.push(response);
        }

        onFormChange(cloneFormTabs);
        Notification('success', `Element was ${isEditingElement ? 'updated' : 'created'}`, 'Successful');
      } catch (error) {
        Notification('error', `Failed to ${isEditingElement ? 'update' : 'create'} element`, 'Failed');
        console.error('Error: ', error);
      }
    };

    return (
      <AddElementDialog
        mappedFields={ mappedFields }
        fields={ fields }
        clientId={ clientId }
        entityType={ entityType }
        entityBundle={ entityBundle }
        entityFields={ entityFields }
        relations={ relations }
        initalState={ _.cloneDeep(newElement) }
        isEditingElement={ isEditingElement }
        onCancel={ () => {
          this.setState({
            showAddElementDialog: false,
            isEditingElement: false,
            newElement: {},
          });
        } }
        onSubmit={ submitHandler }
      />
    );
  };

  render = () => {
    const { isFetchingFormTabs } = this.props;
    const { showAddTabDialog, showAddGroupDialog, showAddElementDialog } = this.state;

    return (
      <BlockingSpinner isLoading={ isFetchingFormTabs } style={{ height: '65vh' }}>
        { this.renderFormTabs() }
        { showAddTabDialog && this.renderAddTabDialog() }
        { showAddGroupDialog && this.renderAddGroupDialog() }
        { showAddElementDialog && this.renderAddElementDialog() }
      </BlockingSpinner>
    );
  };
};

export default FormSetupTab;
