// Libs
import * as React from 'react';
import { connect } from 'react-redux';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";

// Interfaces
import { Breadcrumb } from 'store/UI/State.interface';

interface Props {
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
}

class Help extends React.Component<Props> {

  mounted: boolean = false;

  componentDidMount = () => {
    this.mounted = true;
    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Help', path: '/help' }
    ], false);
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  render = () => {
    return (
      <div>Help</div>
    );
  };

};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(null, mapDispatchToProps)(Help);