// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Views
import Settings from 'views/user/Settings';
import NotFound from 'views/NotFound';

class UserContainer extends React.Component {

  render = () => {
    return (
      <Switch>

        <Redirect exact from="/user" to="/user/settings" />

        <Route exact path="/user/settings" component={ Settings } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

export default UserContainer;
