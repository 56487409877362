// Libs
import * as React from 'react';
import { Timeline } from 'antd';

// Interfaces
import { TimelineItem } from './Timeline.interface';

// Styles
import './Timeline.scss';

interface Props {
  timeline: TimelineItem[];
};

export default function TimelineComponent({ timeline }: Props) {

  if (!timeline) return <>No timeline found.</>;

  return (
    <Timeline>
      { timeline.map((item: TimelineItem, index: number) => {
        return (
          <Timeline.Item key={ index } className={ item.colorClass }>
            <div className='Timeline'>{ item.node }</div>
          </Timeline.Item>
        );
      }) }
    </Timeline>
  );
};