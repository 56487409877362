// Libs
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

// Components
import { Table, TreeSelect, Typography, Input, Tooltip, Checkbox, Dropdown, Menu, Button, Modal, Form, Select } from 'antd';

// Icons
import Icon, { QuestionCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
} from "components/form/form-wrapper";
import { RecordFormEntity } from 'types/entities';
import FieldWrapper from 'components/form/field/field-wrapper';

// Services
import { getFormatedNumber } from 'services/settings';
import Notification from 'services/notification';
import { Api } from 'services/api';

const { Option } = Select;
const { Text } = Typography;
const { SHOW_PARENT } = TreeSelect;
const API: Api = new Api();

interface Props {
  numberFormat: any;
  clientId: number;
  record: RecordFormEntity;
  field: any;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  originalState: any;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  excludeZeros: boolean;
  showChanged: boolean;
  expandedRowKeys: string[];
  filter: number[];
  showDuplicationDialog: boolean;
  showExportModal: boolean;
  showFilter: boolean;
  isLoadingAction: boolean;
  exportOptions: {
    finance_template: [],
    coa: []
  } | null;
};

const nestedSet = (data: any = []) => {
  return !_.isEmpty(data) && data.map((entity: any) => {
    const appendChildrenKeys = (children: any) => {

      // Prevent nesting
      if (_.isEmpty(children)) return null;

      return children.map((childEntity: any) => {
        return {
          ...childEntity,
          'key': childEntity.id,
          'id': childEntity.id,
          'value': childEntity.id,
          'title': childEntity.title,
          'children': appendChildrenKeys(childEntity.children),
        };
      });
    };

    return {
      ...entity,
      'key': entity.id,
      'id': entity.id,
      'value': entity.id,
      'title': entity.title,
      'children': appendChildrenKeys(entity.children),
    };
  });
};

const getBlankState: any = (default_currency_code: string = 'EUR') => {
  return {
    capex_id: null,
    currency_code: default_currency_code,
    forecast: 0,
    value: 0,
    comment: '',
  };
};

class CapexCostSummary extends Component<Props> {

  state: State = {
    excludeZeros: false,
    showChanged: false,
    expandedRowKeys: [],
    filter: [],
    showDuplicationDialog: false,
    showExportModal: false,
    showFilter: false,
    isLoadingAction: false,
    exportOptions: null
  };

  componentDidMount = () => {
    this.validate(this.props.state);

    this.setState({
      expandedRowKeys: this.getFlatten(this.getCapexTree(this.props.field)).map((capex: any) => capex.key)
    });
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;
    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);
  };

  validate = (state: any) => {
    this.generateModifiedState(this.props.originalState, state, 'capex_cost_summary');
  };

  generateModifiedState = (pastValue: string | number, newValue: string | number, columnKey: string) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue)) {

      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  handleExport = async () => {
    const { clientId, field, record } = this.props;
    const { exportOptions } = this.state;

    try {

      await new Promise((resolve) => this.setState({ isLoadingAction: true }, () => resolve(null) ));

      await API.download(`client/${clientId}/field/${field.type}/${field.id}/export`, `pacs_budget_export_${moment().format('YYYY-MM-DD')}.xlsx`, {
        id: record.id,
        coa_id: exportOptions?.coa ? exportOptions.coa : [],
        template_id: exportOptions?.finance_template ? exportOptions.finance_template : [],
      });

      Notification('success', `Budget exported`);

    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isLoadingAction: false
      });
    }
  };

  getFlatten = (data: any) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({ ..._value });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  getRolledUpTotal = (capexTree: any, values: any[], key: string) => {
    const capexList = this.getFlatten([values]);
    return capexList.reduce((acc: any, capex: any) => {
      const _capex = capexTree.find((branch: any) => branch.capex_id === capex.id);
      const _value: any = _capex ? parseFloat(_capex[key]) : 0;
      return parseFloat(acc) + parseFloat(_value);
    }, 0);
  };

  getPreviousBudgetTotal = (field: any, capexTree: any[]) => {
    const capexList = this.getFlatten(capexTree).map((branch: any) => branch.id);
    if (_.has(field, 'previous_values') && !_.isEmpty(field.previous_values)) {
      return field.previous_values.reduce((acc: any, previousBudget: any) => {
        if (capexList.includes(previousBudget.capex_id)) {
          return parseFloat(acc) + parseFloat(previousBudget['value'] || 0);
        }
        return parseFloat(acc);
      }, 0);
    }
    return 0;
  };

  getCalculatedTotal = (values: any, key: string, capexTree: any) => {
    const capexList = this.getFlatten(capexTree).map((branch: any) => branch.id);
    return values.reduce((acc: any, value: any) => {
      if (capexList.includes(value.capex_id)) {
        return parseFloat(acc) + parseFloat(value[key] || 0);
      }
      return parseFloat(acc);
    }, 0);
  };

  getCapexTree = (field: any) => {
    const generateTreeList = (value: any) => {
      return {
        key: `${value.id}`,
        value: `${value.id}`,
        ...value,
        children: _.has(value, 'children') && !_.isEmpty(value.children) ? value.children
          .map((_value: any) => generateTreeList(_value))
          .filter((_value: any) => _value) : null
      };
    };

    return _.has(field, 'capex_tree') && field.capex_tree
      .map((capex: any) => generateTreeList(capex))
      .filter((capex: any) => capex);
  };

  filterTree = (tree: any[], filters: number[]) => {
    const collector: any = [];

    tree.forEach((branch: any) => {

      const filter = (_branch: any, filters: any[]) => {

        if (filters.includes(_branch.id)) {
          collector.push({
            ..._branch,
          });
        }

        if (_.has(_branch, 'children') && !_.isEmpty(_branch.children)) {
          _branch.children.forEach((__branch: any) => {
            filter(__branch, filters);
          });
        }
      };

      return filter(branch, filters);
    });

    return collector;
  };

  filterChangedValues = (values: any[], state: any[], field: any) => {
    const recordDataMapping = (data: any[]) => {
      return data.map((entity: any) => {
        const appendChildrenKeys = (children: any) => {
          return children
            .filter((childEntity: any) => {
              const stateCapex = state.find((value: any) => value.capex_id === childEntity.id);
              const previousBudget = field.previous_values.find((value: any) => value.capex_id === childEntity.id);
              const previousBudgetValue = _.has(previousBudget, 'value') ? parseFloat(previousBudget.value) : 0;
              const currentBudgetValue = _.has(stateCapex, 'value') ? parseFloat(stateCapex.value) : 0;

              if (stateCapex && (previousBudgetValue !== currentBudgetValue)) {
                return true;
              }
              return false;
            })
            .map((childEntity: any) => {
              return {
                ...childEntity,
                'children': !_.isEmpty(childEntity.children) ? appendChildrenKeys(childEntity.children) : null,
              };
            });
        };
        return {
          ...entity,
          'children': !_.isEmpty(entity.children) ? appendChildrenKeys(entity.children) : null,
        };
      });
    };

    return recordDataMapping(values);
  };

  filterExcludeZeros = (tree: any[], state: any[], field: any) => {
    const recordDataMapping = (data: any[]) => {
      return data.map((entity: any) => {
        const appendChildrenKeys = (children: any) => {
          return children
            .filter((childEntity: any) => {
              const total = this.getRolledUpTotal(state, childEntity, 'value');

              if (!total) {
                return false;
              }
              return true;
            })
            .map((childEntity: any) => {
              return {
                ...childEntity,
                'children': !_.isEmpty(childEntity.children) ? appendChildrenKeys(childEntity.children) : null,
              };
            });
        };
        return {
          ...entity,
          'children': !_.isEmpty(entity.children) ? appendChildrenKeys(entity.children) : null,
        };
      });
    };

    return recordDataMapping(tree);
  };

  renderDuplicationDialog = (field: any) => {
    return (
      <Modal
        visible
        centered
        title={ 'Duplicate Previous Values' }
        okText={ 'Yes' }
        onOk={ () => {
          this.setState({
            showDuplicationDialog: false
          }, () => {
            this.props.onChange(field, _.cloneDeep(this.props.state.map((value: any) => {
              const previousBudget = field.previous_values.find((previous_values: any) => previous_values.capex_id === value.capex_id);
              value['value'] = previousBudget ? previousBudget.value : 0;
              return value;
            })), field.config);
          });
        } }
        onCancel={() => this.setState({
          showDuplicationDialog: false,
        }) }
        okButtonProps={{
          danger: true
        }}
      >
        <p>This will copy all <b>previous</b> column values into the <b>current</b> column.</p>
        <p className="mT-10">Any existing values will be replaced. Are you sure you want to duplicate?</p>
      </Modal>
    );
  };

  renderExportModal = () => {
    const { field } = this.props;
    const { isLoadingAction, exportOptions } = this.state;
    const financeTemplates: any = field?.templates;
    const opexCoas: any = this.getCapexTree(field);
    return (
      <Modal
        centered
        visible
        closable={ false }
        title={ 'Export Budget' }
        onCancel={ () => this.setState({ showExportModal: false, exportOptions: null }) }
        okText={ 'Export' }
        okButtonProps={ {
          loading: isLoadingAction
        } }
        onOk={ this.handleExport }
        style={ { minWidth: 500 } }
      >
        <Form
          layout="vertical"
        >
          <Form.Item
            label="Finance Template"
            name="template"
          >
            <Select
              allowClear
              showSearch
              mode={ 'multiple' }
              style={ { width: '100%' } }
              placeholder={ 'All' }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              onChange={ (value: any) => {
                this.setState({ exportOptions: { ...exportOptions, finance_template: value } });
              } }
              value={ exportOptions?.finance_template ? exportOptions.finance_template : null }
            >
              { financeTemplates.map((financeTemplate: any) => (
                <Option key={ financeTemplate.id } value={ financeTemplate.id }>
                  { financeTemplate.title }
                </Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item
            label="COA"
            name="coa"
          >
            <TreeSelect
              showSearch
              treeCheckable
              dropdownMatchSelectWidth={ false }
              maxTagCount={ 5 }
              style={ { width: '100%' } }
              placeholder={ 'All' }
              value={ exportOptions?.coa ? exportOptions.coa : null }
              treeData={ opexCoas }
              filterTreeNode={ (input: string, option: any) => {
                if (option) {
                  const filteredInput = input.toLocaleLowerCase();
                  const title = option.title && option.title.toLowerCase();

                  if (title.includes(filteredInput)) {
                    return true;
                  }
                }
                return false;
              } }
              onChange={ (selectedTarget: any) => {
                this.setState({ exportOptions: { ...exportOptions, coa: selectedTarget } });
              } }
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  render = () => {
    const {
      field,
      state,
      originalState,
      config,
      numberFormat,
      isDisabled,
      fieldModifiedMessages,
      onChange
    } = this.props;
    const {
      excludeZeros,
      showChanged,
      showDuplicationDialog,
      showExportModal,
      expandedRowKeys,
      showFilter,
      filter,
      isLoadingAction,
    } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_capex_cost_summary`;
    const decimal = _.has(field, 'config.decimal') ? field.config.decimal : 2;
    const isFieldModified = _.has(fieldModifiedMessages, key);
    const currencySymbol = _.has(field, 'currency') && !_.isEmpty(field.currency) ? field.currency.symbol : '';
    const currencyCode = _.has(field, 'currency') && !_.isEmpty(field.currency) ? field.currency.code : '';
    const showForcecast = _.has(field, 'forecast_visible') ? !!field.forecast_visible : false;
    const editableForcecast = _.has(field, 'forecast_editable') ? !!field.forecast_editable : false;
    const rawCapexTree = this.getCapexTree(field);
    let capexTree = rawCapexTree;

    const previousBudgetTotal = this.getPreviousBudgetTotal(field, capexTree);
    const currentBudgetTotal = this.getCalculatedTotal(field.values, 'value', capexTree);
    const forecastTotal = this.getCalculatedTotal(field.values, 'forecast', capexTree);
    const budgetVarianceTotal = currentBudgetTotal - previousBudgetTotal;
    const forecastVarianceTotal =  forecastTotal - currentBudgetTotal;
    const hasPreviousBudget = _.has(field, 'previous_values') && !_.isEmpty(field.previous_values);
    const showPOs =  _.has(field, 'pos_visible') ? !!field.pos_visible : false;
    const showCVOs =  _.has(field, 'cvos_visible') ? !!field.cvos_visible : false;
    const actions: { title: string, onClick: () => void }[] = [
      {
        title: 'Export',
        onClick: () => {
          if (!isDisabled) {
            this.setState({ showExportModal: true });
          }
        }
      }
    ];

    if (!_.isEmpty(filter)) {
      capexTree = this.filterTree(capexTree, filter);
    }

    if (excludeZeros) {
      capexTree = this.filterExcludeZeros(capexTree, state, field);
    }

    if (showChanged) {
      capexTree = this.filterChangedValues(capexTree, state, field);
    }

    if (!isDisabled && hasPreviousBudget) {
      actions.push(
        {
          title: 'Duplicate Previous Values',
          onClick: () => this.setState({ showDuplicationDialog: true })
        }
      );
    };

    const columns: any = [
      {
        key: 'title',
        dataIndex: 'title',
        title: (
          <>
            <span>Title</span>
            <Tooltip
              placement="top"
              title={ _.isEmpty(expandedRowKeys) ? 'Expand all' : 'Collapse all' }
            >
              <button
                type="button"
                style={{
                  marginTop: '2.5005px',
                  marginRight: '8px',
                  backgroundColor: '#f5f5f5',
                }}
                className={ classNames('ant-table-row-expand-icon', {
                  'ant-table-row-expand-icon-collapsed': _.isEmpty(expandedRowKeys),
                  'ant-table-row-expand-icon-expanded': !_.isEmpty(expandedRowKeys),
                }) }
                onClick={ () => {
                  if (_.isEmpty(expandedRowKeys)) {
                    this.setState({ expandedRowKeys: this.getFlatten(capexTree).map((capex: any) => capex.key) });
                  } else {
                    this.setState({ expandedRowKeys: [] });
                  }
                } }
              />
            </Tooltip>
          </>
        ),
        width: 400,
        fixed: 'left',
        ellipsis: true,
        render: (__: any, row: any) => {

          let previousVersionBudgetChanged = false;

          if (!!field.config.version_changed) {
            const previousBudget = field.previous_values.find((value: any) => value.capex_id === row.id);
            const stateCapex = state.find((value: any) => value.capex_id === row.id);
            const previousBudgetValue = _.has(previousBudget, 'value') ? parseFloat(previousBudget.value) : 0;
            const currentBudgetValue = _.has(stateCapex, 'value') ? parseFloat(stateCapex.value) : 0;

            if (stateCapex && (previousBudgetValue !== currentBudgetValue)) {
              previousVersionBudgetChanged = true;
            }
          }

          return (
            <div
              className={ classNames({
                'text-warning': previousVersionBudgetChanged,
              }) }
            >
              { _.has(row, 'description') && !!row.description ?
                (
                  <Tooltip
                    placement="top"
                    title={ row.description }
                  >
                    { row.title }
                  </Tooltip>
                ) : (
                  row.title
                )
              }
            </div>
          );
        }
      },
      {
        key: 'current_budget',
        dataIndex: 'current_budget',
        width: 200,
        title: (
          <>
            <span>{ _.has(field, 'current_budget_label') ? field.current_budget_label : 'Current Budget' }</span>
            <Tooltip
              className="mL-5"
              placement="top"
              title={ 'Proposed budget' }
            >
              <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
            </Tooltip>
          </>
        ),
        render: (__: any, row: any) => {
          const stateCapex = state.find((value: any) => value.capex_id === row.id);
          const originalStateCapex = originalState.find((value: any) => value.capex_id === row.id);
          const newValue = stateCapex ? parseFloat(stateCapex.value) : 0;
          const oldValue = originalStateCapex ? parseFloat(originalStateCapex.value) : 0;
          const isModified = newValue !== oldValue;
          const canEdit = !row.children;
          const total = this.getRolledUpTotal(state, row, 'value');

          return (
            <NumberFormat
              { ...numberFormat }
              fixedDecimalScale={ !!decimal }
              decimalScale={ decimal }
              customInput={ Input }
              className={ classNames('Field pR-20 ta-r', {
                'Field--has-warning border-warning': isModified,
              }) }
              prefix={ currencySymbol }
              required={ field.config.required }
              disabled={ !canEdit || isDisabled }
              value={ canEdit ? (_.has(stateCapex, 'value') ? stateCapex.value : 0) : total }
              onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                const index = state.findIndex((value: any) => value.capex_id === row.id);
                const value = parseFloat(event.target.value.replace(currencySymbol, '').replaceAll(',', ''));
                if (index !== -1) {
                  onChange(field, _.set(_.cloneDeep(state), index, {
                    ...stateCapex,
                    value: value || 0
                  }), config);
                } else {
                  onChange(field, _.cloneDeep(state).concat([{
                    ...getBlankState(currencyCode),
                    capex_id: row.id,
                    value: value || 0
                  }]), config);
                }
              } }
            />
          );
        }
      },
      {
        key: 'comments',
        dataIndex: 'comments',
        title: 'Comments',
        width: 300,
        render: (__: any,row: any) => {
          const stateCapex = state.find((value: any) => value.capex_id === row.id);
          const originalStateCapex = originalState.find((value: any) => value.capex_id === row.id);
          const newValue = stateCapex ? stateCapex.comment : '';
          const oldValue = originalStateCapex ? originalStateCapex.comment : '';
          const isModified = newValue !== oldValue;

          return (
            <Input
              autoComplete="off"
              className={ classNames('Field', {
                'Field--has-warning border-warning': isModified,
              }) }
              defaultValue={ _.has(stateCapex, 'comment') ? stateCapex.comment : '' }
              disabled={ isDisabled }
              onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                const index = state.findIndex((value: any) => value.capex_id === row.id);
                if (index !== -1) {
                  onChange(field, _.set(_.cloneDeep(state), index, {
                    ...stateCapex,
                    comment: event.target.value || ''
                  }), config);
                } else {
                  const newState = _.cloneDeep(state).concat([{
                    ...getBlankState(currencyCode),
                    capex_id: row.id,
                    comment: event.target.value || '',
                  }]);
                  onChange(field, newState, config);
                }
              } }
            />
          );
        }
      }
    ];

    if (hasPreviousBudget) {

      // Add previous budget
      columns.splice(columns.findIndex((column: any) => column.key === 'title') + 1, 0,
        {
          key: 'previous_values',
          dataIndex: 'previous_values',
          width: 200,
          title: (
            <>
              <span>{ _.has(field, 'previous_values_label') ? field.previous_values_label : 'Previous Budget' }</span>
              <Tooltip
                className="mL-5"
                placement="top"
                title={ 'Last Approved Budget' }
              >
                <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
              </Tooltip>
            </>
          ),
          render: (__: any, row: any) => {
            const previousBudgetValue = this.getRolledUpTotal(field.previous_values, row, 'value');
            return (
              <div className='ta-r'>{ getFormatedNumber(`${previousBudgetValue}`, null, currencySymbol) }</div>
            );
          }
        }
      );

      // Add budget variance
      columns.splice(columns.findIndex((column: any) => column.key === 'current_budget') + 1, 0,
        {
          key: 'budget_variance',
          dataIndex: 'budget_variance',
          width: 200,
          title: (
            <>
              <span>Budget Variance</span>
              <Tooltip
                className="mL-5"
                placement="top"
                title={ 'The difference between the previous and current budget' }
              >
                <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
              </Tooltip>
            </>
          ),
          render: (__:any, row: any) => {
            const stateCapex = state.find((value: any) => value.capex_id === row.id);
            const previousBudget = field.previous_values.find((value: any) => value.capex_id === row.id);
            const canEdit = !row.children;

            let previousBudgetValue = 0;
            let currentBudgetValue = 0;

            if (canEdit) {
              currentBudgetValue = _.has(stateCapex, 'value') ? stateCapex.value : 0;
            } else {
              currentBudgetValue = this.getRolledUpTotal(state, row, 'value');
            }

            if (previousBudget) {
              previousBudgetValue = previousBudget.value;
            }

            const sum = currentBudgetValue - previousBudgetValue;

            return (
              <div
                className={ classNames('ta-r', {
                  'text-danger': sum > 0,
                  'text-success': sum < 0,
                }) }
              >
                { getFormatedNumber(`${sum}`, null, currencySymbol) }
              </div>
            );
          }
        },
      );
    }

    // Add forecast
    if (showForcecast) {
      columns.splice(columns.findIndex((column: any) => column.key === (hasPreviousBudget ? 'budget_variance' : 'current_budget')) + 1, 0,
        {
          key: 'forecast',
          dataIndex: 'forecast',
          width: 200,
          title: (
            <>
              <span>Forecast</span>
              <Tooltip
                className="mL-5"
                placement="top"
                title={ 'Enter forecast for current budget year' }
              >
                <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
              </Tooltip>
            </>
          ),
          render: (__: any, row: any) => {
            const stateCapex = state.find((value: any) => value.capex_id === row.id);
            const originalStateCapex = originalState.find((value: any) => value.capex_id === row.id);
            const newValue = stateCapex ? parseFloat(stateCapex.forecast) : 0;
            const oldValue = originalStateCapex ? parseFloat(originalStateCapex.forecast) : 0;
            const isModified = newValue !== oldValue;
            const canEdit = !row.children;

            let forecastValue = 0;

            if (canEdit) {
              forecastValue = _.has(stateCapex, 'forecast') ? stateCapex.forecast : 0;
            } else {
              forecastValue = this.getRolledUpTotal(state, row, 'forecast');
            }

            return (
              <NumberFormat
                { ...numberFormat }
                fixedDecimalScale={ !!decimal }
                decimalScale={ decimal }
                customInput={ Input }
                className={ classNames('Field pR-20 ta-r', {
                  'Field--has-warning border-warning': isModified,
                }) }
                prefix={ currencySymbol }
                required={ field.config.required }
                disabled={ !canEdit || !editableForcecast }
                value={ forecastValue }
                onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                  const index = state.findIndex((value: any) => value.capex_id === row.id);
                  const value = parseFloat(event.target.value.replace(currencySymbol, '').replaceAll(',', ''));
                  if (index !== -1) {
                    onChange(field, _.set(_.cloneDeep(state), index, {
                      ...stateCapex,
                      forecast: value || 0
                    }), config);
                  } else {
                    const newState = _.cloneDeep(state).concat([{
                      ...getBlankState(currencyCode),
                      capex_id: row.id,
                      forecast: value || 0,
                    }]);
                    onChange(field, newState, config);
                  }
                } }
              />
            );
          }
        },
        {
          key: 'forecast_variance',
          dataIndex: 'forecast_variance',
          width: 200,
          title: (
            <>
              <span>Forecast Variance</span>
              <Tooltip
                className="mL-5"
                placement="top"
                title={ 'Last Approved Budget - Forecast' }
              >
                <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
              </Tooltip>
            </>
          ),
          render: (__: any, row: any) => {
            const stateCapex = state.find((value: any) => value.capex_id === row.id);
            const canEdit = !row.children;
            let currentBudgetValue = 0;
            let forecastValue = 0;

            if (canEdit) {
              currentBudgetValue = _.has(stateCapex, 'value') ? stateCapex.value : 0;
              forecastValue = _.has(stateCapex, 'forecast') ? stateCapex.forecast : 0;
            } else {
              currentBudgetValue = this.getRolledUpTotal(state, row, 'value');
              forecastValue = this.getRolledUpTotal(state, row, 'forecast');
            }

            const sum = forecastValue - currentBudgetValue;

            return (
              <div
                className={ classNames('ta-r', {
                  'text-danger': sum > 0,
                  'text-success': sum < 0,
                }) }
              >
                { getFormatedNumber(`${sum}`, null, currencySymbol) }
              </div>
            );
          }
        }
      );
    }

    if (showPOs) {
      columns.splice(columns.findIndex((column: any) => column.key === (hasPreviousBudget ? 'budget_variance' : 'current_budget')) + 1, 0,
        {
          key: 'po_raised',
          dataIndex: 'po_raised',
          width: 100,
          title: (
            <>
              <span>PO's Raised</span>
            </>
          ),
          render: (__: any, row: any) => {
            return (
              <div className="ta-r">
                { getFormatedNumber(`${0}`, null, currencySymbol) }
              </div>
            );
          }
        }
      );
    }

    if (showCVOs) {
      columns.splice(columns.findIndex((column: any) => column.key === (showPOs ? 'po_raised' : 'current_budget')) + 1, 0,
        {
          key: 'cvo',
          dataIndex: 'cvo',
          width: 100,
          title: (
            <>
              <span>CVO's</span>
            </>
          ),
          render: (__: any, row: any) => {
            return (
              <div className="ta-r">
                { getFormatedNumber(`${0}`, null, currencySymbol) }
              </div>
            );
          }
        }
      );
    }

    return (
      <>
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ 12 }
          label={ (
            <div>
              <div className="mT-5">
                <span>{ field.label }</span>
                <span
                  className={ classNames('mL-15', {
                    'link': true,
                    'active': showFilter
                  }) }
                  onClick={ () => {
                    this.setState({
                      showFilter: !showFilter
                    });
                  } }
                >
                  <Icon component={ FilterIcon } />
                  <span>Filter</span>
                </span>
              </div>
              { showFilter &&
                <div className='mT-15'>
                  <span>
                    <TreeSelect
                      style={{ width: 300 }}
                      dropdownMatchSelectWidth={ false }
                      placeholder={ 'Select filter' }
                      showCheckedStrategy={ SHOW_PARENT }
                      maxTagCount={ 2 }
                      treeCheckable
                      multiple
                      treeData={ nestedSet(rawCapexTree) }
                      filterTreeNode={ (input: string, option: any) => {
                        if (option) {
                          const filteredInput = input.toLocaleLowerCase();
                          const title = option.title && option.title.toLowerCase();

                          if (title.includes(filteredInput)) {
                            return true;
                          }
                        }

                        return false;
                      } }
                      onChange={(items: any[]) => {
                        this.setState({
                          filter: items
                        });
                      }}
                    />
                  </span>
                  <span className="mL-10">
                    <Checkbox
                      onChange={ (event) => {
                        this.setState({
                          showChanged: event.target.checked
                        });
                      } }
                      checked={ showChanged }
                    >
                      Show Changed
                    </Checkbox>
                    <Checkbox
                      onChange={ (event) => {
                        this.setState({
                          excludeZeros: event.target.checked
                        });
                      } }
                      checked={ excludeZeros }
                    >
                      Exclude Zeros
                    </Checkbox>
                  </span>
                </div>
              }
            </div>
          ) }
          hideErrorInfo
          required={ field.config.required }
          border
          description={ !!field.description && field.description }
          isModified={ isFieldModified }
          refreshOnChange={ !!field.config.refresh_on_change }
          versionChanged={ !!field.config.version_changed }
          rightActions={ [
            {
              node: (
                <Dropdown
                  placement={ 'bottomRight' }
                  overlay={ () => (
                    <Menu>
                      { actions.map((action: { title: string, onClick: () => void }, index: number) => (
                        <Menu.Item
                          key={ index }
                          onClick={ () => action.onClick() }
                        >
                          { action.title }
                        </Menu.Item>
                      ) ) }
                    </Menu>
                  ) }
                  trigger={ ['click'] }
                >
                  <Button
                    loading={ isLoadingAction }
                    disabled={ isLoadingAction }
                    style={{
                      marginLeft: 5,
                      padding: '4px 7px',
                      width: '32px',
                    }}
                    onClick={ () => {} }
                  >
                    { !isLoadingAction && <EllipsisOutlined className="fsz-def text-ant-default" /> }
                  </Button>
                </Dropdown>
              )
            }
          ] }
        >
          <>
            <Table
              size={ 'small' }
              sticky
              bordered
              columns={ columns }
              dataSource={ capexTree || [] }
              pagination={ false }
              scroll={{
                x: columns.length * 200,
                y: 600,
              }}
              expandable={{
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded: boolean, row: any) => {
                  if (expanded) {
                    this.setState({ expandedRowKeys: [...expandedRowKeys, row.key] });
                  } else {
                    this.setState({ expandedRowKeys: !_.isEmpty(expandedRowKeys) ? expandedRowKeys.filter((rowKey) => rowKey !== row.key) : expandedRowKeys });
                  }
                },
              }}
              summary={ () => {
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={ 0 } colSpan={ 1 } className="fw-600">
                        <Text>{ 'Total' }</Text>
                      </Table.Summary.Cell>
                      { hasPreviousBudget &&
                        <Table.Summary.Cell index={ 1 } colSpan={ 1 } className="ta-r fw-600">
                          <Text>
                            { getFormatedNumber(`${previousBudgetTotal}`, null, currencySymbol) }
                          </Text>
                        </Table.Summary.Cell>
                      }
                      <Table.Summary.Cell index={ 2 } colSpan={ 1 } className="ta-r fw-600">
                        <Text>
                          { getFormatedNumber(`${currentBudgetTotal}`, null, currencySymbol) }
                        </Text>
                      </Table.Summary.Cell>
                      { hasPreviousBudget &&
                        <Table.Summary.Cell index={ 3 } colSpan={ 1 } className="ta-r fw-600">
                          <Text
                            className={ classNames('ta-r', {
                              'text-danger': budgetVarianceTotal > 0,
                              'text-success': budgetVarianceTotal < 0,
                            }) }
                          >
                            { getFormatedNumber(`${budgetVarianceTotal}`, null, currencySymbol) }
                          </Text>
                        </Table.Summary.Cell>
                      }
                      { showForcecast &&
                        <>
                          <Table.Summary.Cell index={ 4 } colSpan={ 1 } className="ta-r fw-600">
                            <Text>
                              { getFormatedNumber(`${forecastTotal}`, null, currencySymbol) }
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={ 5 } colSpan={ 1 } className="ta-r fw-600">
                            <Text
                              className={ classNames('ta-r', {
                                'text-danger': forecastVarianceTotal > 0,
                                'text-success': forecastVarianceTotal < 0,
                              }) }
                            >
                              { getFormatedNumber(`${forecastVarianceTotal}`, null, currencySymbol) }
                            </Text>
                          </Table.Summary.Cell>
                        </>
                      }
                      <Table.Summary.Cell index={ 6 } colSpan={ 1 } className="ta-r fw-600" />
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
            { showDuplicationDialog && this.renderDuplicationDialog(field) }
          </>
        </FieldWrapper>
        { showExportModal && this.renderExportModal() }
      </>
    );
  };

};

export default CapexCostSummary;
