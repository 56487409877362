// Libs
import * as React from "react";
import { connect } from "react-redux";
import parse from 'html-react-parser';

// Components
import BlockingSpinner from "components/blocking-spinner";
import Badge, { BadgeType } from 'components/badge';

// Services
import { Api } from "services/api";
import { getFormatedDate } from 'services/settings';
import NotificationMessage from 'services/notification';

// Interfaces
import AppState from "store/AppState.interface";

// Styles
import 'assets/styles/_notification.scss';

const API: Api = new Api();

interface Props {
  email_id: number;
  client_id?: number;
};

interface State {
  email: any | null;
  isFetching: boolean;
}

class Email extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    email: null,
    isFetching: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.fetchEmail();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.email_id !== this.props.email_id) {
      this.fetchEmail();
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchEmail = async () => {
    const { email_id, client_id } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));
      const email = await API.get(`client/${client_id}/admin/emails/${email_id}`);

      this.mounted && this.setState({
        email: email,
      });

    } catch (error) {
      NotificationMessage('error', '', 'Failed to get email');
      console.error(`Failed to get email: ${email_id}`);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  render = () => {
    const { email, isFetching } = this.state;

    if (isFetching) return (
      <div className="d-f jc-c ai-c h-100p">
        <BlockingSpinner isLoading />
      </div>
    );

    if (!email) return <></>;

    return (
      <>
        <div className="bdB pX-30 pT-30" style={{ minHeight: '170px' }}>
          <div>
            <div className="d-f ai-c">
              <div>
                <p className="fsz-lg lh-1">{ email.subject }</p>
                <p className="mT-10">
                  { email.type && <span className="mR-10"><Badge type={ email.type === 'PORT' ? BadgeType.Disabled : BadgeType.Default } text={ email.type.toUpperCase() } /></span> }
                  <span className="va-m">{ getFormatedDate(email.created_at, undefined, true) }</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Email-content-body ovY-s h-100p pB-160">
          { parse(email.message) }
        </div>
      </>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id
  };
};

export default connect(mapStateToProps)(Email);