// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Views
import Content from 'views/admin/content-manager/Content';
import NotFound from 'views/NotFound';

// Containers
import CategoryContainer from 'containers/admin/content-manager/CategoryContainer';
import SelectListContainer from 'containers/admin/content-manager/SelectListContainer';
import SliderRangeContainer from 'containers/admin/content-manager/SliderRangeContainer';
import ConfigurationContainer from 'containers/admin/content-manager/ConfigurationContainer';

class ContentContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Redirect exact from="/admin/content-manager" to="/admin/content-manager/categories" />

        <Route exact path="/admin/content-manager/:content_type" component={ Content } />

        <Route path="/admin/content-manager/categories/:type" component={ CategoryContainer } />
        <Route path="/admin/content-manager/categories/:type/:id" component={ CategoryContainer } />

        <Route path="/admin/content-manager/select-lists/:type" component={ SelectListContainer } />
        <Route path="/admin/content-manager/select-lists/:type/:id" component={ SelectListContainer } />

        <Route path="/admin/content-manager/slider-ranges/:type" component={ SliderRangeContainer } />
        <Route path="/admin/content-manager/slider-ranges/:type/:id" component={ SliderRangeContainer } />

        <Route path="/admin/content-manager/configurations/:type" component={ ConfigurationContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

export default ContentContainer;
