// Libs
import React from 'react';

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import { RestrictionHoC } from 'components/restriction';
import DynamicFieldTemplate from 'views/admin/templates/dynamic-field/DynamicFieldTemplate';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';

interface Props extends RouteComponentProps {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
};

class DynamicFieldTemplateWrapper extends React.Component<Props> {

  render = () => {
    const template_type_id: any = this.props.match.params.type_id;
    const template_id: any = this.props.match.params.template_id;

    if (!template_id || !template_type_id) return;

    return (
      <DynamicFieldTemplate
        template_type_id={ template_type_id }
        template_id={ template_id }
      />
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(DynamicFieldTemplateWrapper), 'access_admin_content_manager'));
