// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import SupplierContainer from 'containers/supplier/SupplierContainer';
import ContractContainer from 'containers/supplier/ContractContainer';

// Views
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

class SupplyChainContainer extends React.Component {

  render = () => {
    return (
      <Switch>

        <Redirect exact from="/suppliers" to="/suppliers/suppliers" />

        <Route exact path="/suppliers/suppliers" component={ SupplierContainer } />
        <Route path="/suppliers/suppliers/:record_id" component={ SupplierContainer } />

        <Route exact path="/suppliers/contracts" component={ ContractContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

export default SupplyChainContainer;
