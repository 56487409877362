// Libs
import React, { Component } from 'react';
import _ from 'lodash';

// Components
import { Empty, Select, Table, Tooltip, Badge } from 'antd';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import CoverModal from 'components/cover-modal';

// Services
import { getFormatedDate } from 'services/settings';

// Styles
import 'gantt-task-react/dist/index.css';
import './ActivityManagement.scss';

const GANTT_ROW_HEIGHT: number = 50;
const TABLE_BORDER_HEIGHT: number = 1;
const TABLE_HEADER_HEIGHT: number = GANTT_ROW_HEIGHT - TABLE_BORDER_HEIGHT;
const TABLE_ROW_HEIGHT: number = GANTT_ROW_HEIGHT - TABLE_BORDER_HEIGHT;

const MODE_OPTIONS: ViewMode[] = [ViewMode.Day, ViewMode.Week, ViewMode.Month, ViewMode.Year];

interface Props {
  title: string;
  tasks: Task[];
  onCancel: () => void;
  shouldShowTaskLabel?: boolean;
  maxTagTextLength?: number;
};

interface State {
  mode: ViewMode;
};

class GanttView extends Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    mode: ViewMode.Day,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  ganttRowWidth = (mode: any): number => {
    switch (mode) {
      case ViewMode.Day:
        return 60;
      case ViewMode.Week:
        return 80;
      case ViewMode.Month:
        return 100;
      case ViewMode.Year:
        return 150;
      default:
        return 100;
    }
  };

  renderTaskListTableColumnTitle = (rows: string[]): JSX.Element => {
    return (
      <div className="d-f fxd-c jc-c" style={{ height: TABLE_HEADER_HEIGHT }}>
        { rows.map((row, idx) => <span key={ `${row}_${idx}` }>{ row }</span>) }
      </div>
    );
  };

  renderTaskListTableCell = (value: string, visibleTooltip?: boolean): JSX.Element => {
    return (
      <div className="d-f ai-c" style={{ height: TABLE_ROW_HEIGHT }}>
        <Tooltip
          placement={ 'topRight' }
          title={ value }
          visible={ !!visibleTooltip ? undefined : false }
        >
          <p className="whs-nw ov-h tov-e">{ value }</p>
        </Tooltip>
      </div>
    );
  };

  renderTaskListTable = (tasks: Task[]): JSX.Element => {
    const columns = [
      {
        key: 'name',
        dataIndex: 'name',
        title: this.renderTaskListTableColumnTitle(['Activity Title']),
        render: (activityTitle: string) => this.renderTaskListTableCell(activityTitle, true),
        width: 200
      },
      {
        key: 'start',
        dataIndex: 'start',
        title: this.renderTaskListTableColumnTitle(['Start Date']),
        render: (startDate: Date) => this.renderTaskListTableCell(getFormatedDate(startDate.toISOString())),
        width: 110,
        ellipsis: true,
      },
      {
        key: 'end',
        dataIndex: 'end',
        title: this.renderTaskListTableColumnTitle(['Completion Date']),
        render: (endDate: Date) => this.renderTaskListTableCell(getFormatedDate(endDate.toISOString())),
        width: 130,
        ellipsis: true,
      },
    ];

    return (
      <Table
        className="GanttTaskTable"
        rowKey={ (row: Task) => row.id }
        dataSource={ tasks }
        columns={ columns }
        pagination={ false }
        style={{ width: 450 }}
      />
    );
  };

  renderGanttView = (tasks: Task[], mode: ViewMode, shouldShowTaskLabel: boolean, maxTagTextLength?: number): JSX.Element => {
    if (_.isEmpty(tasks)) {
      return <div className="d-f jc-c ai-c mH-400"><Empty /></div>;
    }

    let displayedTasks = tasks;

    // hide task labels in the Gantt view
    if (!shouldShowTaskLabel) {
      displayedTasks = tasks.map((task: Task) => ({ ...task, name: '' }));
    }

    // trim task label
    if (shouldShowTaskLabel && !!maxTagTextLength) {
      displayedTasks = tasks.map((task: Task) => {
        if (task.name.length > maxTagTextLength) {
          return { ...task, name: `${task.name?.slice(0, maxTagTextLength)}...` };
        }
        return task;
      });
    }

    return (
      <div className="pX-20 pB-20">
        <div className="mB-15">
          <Select
            placeholder="Mode"
            style={{ width: 200 }}
            value={ mode }
            onChange={ (value: ViewMode) => this.mounted && this.setState({ mode: value }) }
          >
            { MODE_OPTIONS.map((modeOption: ViewMode) => (
              <Select.Option key={ modeOption } value={ modeOption } >{ modeOption }</Select.Option>
            )) }
          </Select>

          <div className="fl-r">
            Key:
            <span className="mR-15 mL-5">
              <Badge count={ 'Planned' } style={{ backgroundColor: '#9ccddd', fontSize: 10 }} />
            </span>
            <Badge count={ 'Actual' } style={{ backgroundColor: '#2f639f', fontSize: 10 }} />
          </div>
        </div>
        <Gantt
          tasks={ displayedTasks }
          headerHeight={ GANTT_ROW_HEIGHT }
          rowHeight={ GANTT_ROW_HEIGHT }
          columnWidth={ this.ganttRowWidth(mode) }
          viewMode={ mode }
          TaskListHeader={ () => null } // hide header
          TooltipContent={ () => null } // hide tooltip
          TaskListTable={ () => this.renderTaskListTable(tasks) }
        />
      </div>
    );
  };

  render() {
    const { title, tasks, shouldShowTaskLabel, maxTagTextLength, onCancel } = this.props;
    const { mode } = this.state;

    return (
      <CoverModal
        style={{ width: '95%', minHeight: '600px', maxHeight: '90vh' }}
        middleContent={ title }
        onClose={ onCancel }
      >
        { this.renderGanttView(tasks, mode, !!shouldShowTaskLabel, maxTagTextLength) }
      </CoverModal>
    );
  };
};

export default GanttView;