import React, { Fragment } from 'react';
import { isEmpty } from 'lodash';
import { defineMessages, useIntl, IntlShape } from 'react-intl';

// Interfaces
import {
  FormRecord,
  FormGroup,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxModifiedMessageContent,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxErrorMessageContentChild,
} from 'components/form/form-wrapper';

// Styles
import './InfoBox.scss';

interface Props {
  record: FormRecord[];
  showModified?: boolean;
  fieldErrorMessages: Record<string, FormFieldInfoBoxErrorMessage>;
  fieldModifiedMessages: Record<string, FormFieldInfoBoxModifiedMessage>;
}

interface ValueChange {
  from: string;
  to: string;
}

interface ColumnChange {
  changes: ValueChange;
  label: string;
}

const InfoBox: React.FC<Props> = ({
  showModified,
  fieldErrorMessages,
  fieldModifiedMessages,
  record,
}) => {
  const intl: IntlShape = useIntl();
  const { formatMessage } = intl;

  const messages = defineMessages({
    clear_title: {
      id: 'form.info.clear_title',
      defaultMessage: 'All clear',
      description: '',
    },
    error_title: {
      id: 'form.info.error_title',
      defaultMessage: 'Form has errors',
      description: '',
    },
    modified_title: {
      id: 'form.info.modified_title',
      defaultMessage: 'Form has modified fields',
      description: '',
    },
    modified_label: {
      id: 'form.info.modified_label',
      defaultMessage: 'Changed from "{from}" to "{to}"',
      description: '',
    },
  });

  const renderErrorElement = (fieldError: FormFieldInfoBoxErrorMessage) => {
    return (
      <div key={`${fieldError.id}_${fieldError.cardinality}_error_info_change`} className="mB-5">
        <span className="fw-400">
          {fieldError.content.label}
        </span>
        <ul className="pL-10 list-h">
          {
            (fieldError.content.content as Array<string | FormFieldInfoBoxErrorMessageContentChild>).map((message: string | FormFieldInfoBoxErrorMessageContentChild, index: number) => {
              if (typeof message === 'string') {
                return (<li key={`${fieldError.id}-${index}`}>{message}</li>);
              }

              return (
                <li key={`${fieldError.id}-${message.columnKey}`}>
                  <div>
                    {message.label && <span className="fw-700">{message.label}:</span>}
                    <ul className="pL-10 list-h">
                      { message.content.map((error: string, childIndex: number) => <li key={`${fieldError.id}-${message.label}-${childIndex}`}>{error}</li>) }
                    </ul>
                  </div>
                </li>
              );
            })
          }
        </ul>
      </div>
    );
  };

  const renderModifiedElement = (change: FormFieldInfoBoxModifiedMessage) => {
    return (
      <div key={`${change.id}_${change.cardinality}_modified_info_change`} className="mB-5">
        <span className="fw-400">
          {change.content.label}
        </span>
        <ul className="pL-10 list-h">
          {
            change.content.content ?
              change.content.content.map((message: FormFieldInfoBoxModifiedMessageContent) => {
                return (
                  <li key={`${change.id}-${message.columnKey}`}>
                    <div>
                      {message.label && <span className="fw-700">{message.label}: </span>}
                      <span className="fw-700">{`"${message.from}"`}</span> to <span className="fw-700">{`"${message.to}"`}</span>
                    </div>
                  </li>
                );
              })
            :
              <li>
                <span className="fw-700">{`"${change.content.from}"`}</span> to <span className="fw-700">{`"${change.content.to}"`}</span>
              </li>
          }
        </ul>
      </div>
    );
  };

  const renderGroups = () => {
    let modifiedEl: React.ReactNodeArray = [];
    let errorEl: React.ReactNodeArray = [];

    if (!isEmpty(fieldErrorMessages)) {
      record.forEach((tab: FormRecord) => {
        const errorGroupEl: React.ReactNodeArray = [];

        tab.groups.forEach((group: FormGroup) => {
          const errorFieldEl: React.ReactNodeArray = [];
          const fieldErrorMessagesArray = ((Object.values(fieldErrorMessages)).filter((entry) => entry.isHidden !== true)).sort((a: FormFieldInfoBoxErrorMessage, b: FormFieldInfoBoxErrorMessage) => a.order - b.order);

          fieldErrorMessagesArray.forEach((fieldErrorMessages: FormFieldInfoBoxErrorMessage) => {
            if (tab.id === fieldErrorMessages.tab && group.id === fieldErrorMessages.group) {
              errorFieldEl.push(renderErrorElement(fieldErrorMessages));
            }
          });

          if (!isEmpty(errorFieldEl)) {
            errorGroupEl.push(
              <div className="mB-5" key={`${tab.id}_${group.id}_modified_info`}>
                <h4 className="fsz-def text-danger mB-0">{group.title}</h4>
                <ul className="pL-10 list-h">{errorFieldEl}</ul>
              </div>
            );
          }
        });

        if (!isEmpty(errorGroupEl)) {
          errorEl.push(
            <div className="mB-5" key={`${tab.id}_modified_info`}>
              <h4 className="fsz-md text-danger mB-0">{tab.title}</h4>
              <ul className="pL-10 list-h">{errorGroupEl}</ul>
            </div>
          );
        }
      });
    }

    if (showModified && !isEmpty(fieldModifiedMessages)) {
      if (!isEmpty(fieldModifiedMessages.title)) {
        modifiedEl.push(
          <div className="mB-5" key='title_modified_info'>
            {renderModifiedElement(fieldModifiedMessages.title)}
          </div>
        );
      }

      record.forEach((tab: FormRecord) => {
        const modifiedGroupEl: React.ReactNodeArray = [];

        tab.groups.forEach((group: FormGroup) => {
          const modifiedFieldEl: React.ReactNodeArray = [];
          const fieldModifiedMessagesArray = (Object.values(fieldModifiedMessages)).sort((a: FormFieldInfoBoxModifiedMessage, b: FormFieldInfoBoxModifiedMessage) => a.order - b.order);

          fieldModifiedMessagesArray.forEach((fieldModifiedMessages: FormFieldInfoBoxModifiedMessage) => {
            if (tab.id === fieldModifiedMessages.tab && group.id === fieldModifiedMessages.group) {
              modifiedFieldEl.push(renderModifiedElement(fieldModifiedMessages));
            }
          });

          if (!isEmpty(modifiedFieldEl)) {
            modifiedGroupEl.push(
              <div className="mB-5" key={`${tab.id}_${group.id}_modified_info`}>
                <h4 className="fsz-def text-warning mB-0">{group.title}</h4>
                <ul className="pL-10 list-h">{modifiedFieldEl}</ul>
              </div>
            );
          }
        });

        if (!isEmpty(modifiedGroupEl)) {
          modifiedEl.push(
            <div className="mB-5" key={`${tab.id}_modified_info`}>
              <h4 className="fsz-md text-warning mB-0">{tab.title}</h4>
              <ul className="pL-10 list-h">{modifiedGroupEl}</ul>
            </div>
          );
        }
      });
    }

    return { errorEl, modifiedEl };
  };

  const { errorEl, modifiedEl } = renderGroups();

  return (
    <section className="InfoBox">
      <Fragment>
        {errorEl.length > 0 && (
          <div
            className={`InfoBox-Section ${
              modifiedEl.length > 0 &&
              'InfoBox-Section--with-border pB-20 mB-20'
            } `}
          >
            <h3 className="fsz-lg mB-0 text-danger">
              {formatMessage(messages.error_title)}
            </h3>
            {errorEl}
          </div>
        )}
        {modifiedEl.length > 0 && (
          <div className="InfoBox-Section">
            <h3 className="fsz-lg mB-0 text-warning">
              {formatMessage(messages.modified_title)}
            </h3>
            {modifiedEl}
          </div>
        )}
        {modifiedEl.length === 0 && errorEl.length === 0 && (
          <div className="InfoBox-Section">
            <h3 className="fsz-lg mB-0">
              {formatMessage(messages.clear_title)}
            </h3>
          </div>
        )}
      </Fragment>
    </section>
  );
};

export default InfoBox;
