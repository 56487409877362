// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// Components
import Jumbotron from 'components/jumbotron';
import AdvancedList from "components/advanced-list";
import { CreateOption } from "components/basic-list";
import { hasPermission } from 'components/restriction';
import { Action as DropdownAction } from 'components/dropdown';

// Views
import CreateRecordView from 'views/common/CreateRecordView';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';

// Services
import Notification from 'services/notification';
import { getUserSetting } from 'services/settings';
import { Api } from 'services/api';

const API: Api = new Api();

interface Props {
  id?: number;
  type: string;
  entity: string;
  client_id: number;
  permissions?: any;
  record: RecordFormEntity;
  pure?: boolean;
  config?: any;
};

interface State {
  list: any;
  createOption: CreateOption | null;
  isFetching: boolean;
  isCreateLoading: boolean;
};

class ReportView extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    list: [],
    createOption: null,
    isFetching: false,
    isCreateLoading: false,
  };

  componentDidMount = async () => {
    const { client_id, record, config } = this.props;

    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const list = await API.get(`client/${client_id}/report/${config?.report_id}`, {
        entity_id: record.id,
        entity_type: record.type,
        entity_bundle: record.bundle,
        filters: {},
        currency: getUserSetting('currency'),
        measurement: getUserSetting('measurement'),
      });

      this.mounted && this.setState({
        list: list,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  getFlatten = (data: any[] = []) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({ ..._value });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  filterReport = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { client_id, record, config } = this.props;

    try {
      const list = await API.get(`client/${client_id}/report/${config?.report_id}`, {
        entity_id: record.id,
        entity_type: record.type,
        entity_bundle: record.bundle,
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      this.setState({
        list: list
      }, () => {
        callback(true);
      });
    } catch (error) {
      callback(false);
      Notification('error', 'Filter Failed');
    }
  };

  exportFile = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { client_id, record, config } = this.props;
    const { list } = this.state;

    try {
      await API.download(`client/${client_id}/report/${config?.report_id}`, `pacs_report_${_.snakeCase(list.title)}_${moment().format('YYYY-MM-DD')}.xlsx`, {
        entity_id: record.id,
        entity_type: record.type,
        entity_bundle: record.bundle,
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      callback(true);
    } catch (error) {
      callback(false);
      Notification('error', 'Export Failed');
    }
  };

  renderList = (list: any, isFetching: boolean) => {
    const { permissions } = this.props;

    const createOptions = _.has(list?.config, 'create_options') ? list?.config?.create_options : [];
    const actions: DropdownAction[] = [];

    const defaultExpandedRowKeys = this.getFlatten(list?.data)
      .reduce((acc: any, curr: any) => {
        if (curr?.expanded) {
          acc.push(curr?.key);
        }
        return acc;
      }, []);

    createOptions.forEach((createOption: CreateOption) => {
      if (hasPermission(permissions, `${createOption.bundle}_${_.snakeCase(createOption.type)}_create`)) {
        actions.push({
          node: `Create ${createOption.label}`,
          onClick: () => this.setState({
            createOption: createOption,
            isCreateLoading: true,
          }),
          isLoading: this.state.isCreateLoading
        });
      }
    });

    return (
      <AdvancedList
        clientId={ this.props.client_id }
        filters={ list?.filters || [] }
        columns={ list?.columns || [] }
        rightActions={ actions }
        items={ list?.data || [] }
        config={ list?.config }
        isFetching={ isFetching }
        defaultExpandedRowKeys={ defaultExpandedRowKeys }
        onExport={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.exportFile(filters, currency, measurement, callback) }
        onFilter={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.filterReport(filters, currency, measurement, callback) }
      />
    );
  };

  render = () => {
    const { pure, record, type, entity } = this.props;
    const { list, isFetching, createOption } = this.state;

    return (
      <>
        { pure ? (
            <div>
              { this.renderList(list, isFetching) }
            </div>
          ) : (
            <Jumbotron
              content={ 'Report' }
              title={ this.props.record?.title }
              tabs={[
                {
                  label: '',
                  node: this.renderList(list, isFetching),
                },
              ]}
            />
          )
        }
        { createOption &&
          <CreateRecordView
            type={ _.kebabCase(createOption.type) }
            entity={ createOption.bundle }
            parent_id={ record ? record?.id : undefined }
            parent_type={ type }
            parent_bundle={ entity }
            onReady={ () => this.mounted && this.setState({
              isCreateLoading: false
            }) }
            onClose={ () => this.mounted && this.setState({
              createOption: null
            }) }
          />
        }
      </>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps)(ReportView);
