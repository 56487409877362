// Libs
import * as React from 'react';
import classNames from 'classnames';

// Components
import CoverModal from 'components/cover-modal';
import BlockingSpinner from 'components/blocking-spinner';

// Styles
import './TermsModal.scss';

// Services
import { Api } from 'services/api';

interface Props {
  type: string;
  onClose(): void;
};

interface State {
  content: string | null;
  isLoading: boolean;
};

const API: Api = new Api();

class TermsModal extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    content: null,
    isLoading: false,
  };

  componentDidMount = async () => {
    const { type } = this.props;

    this.mounted = true;

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));

      const content = await API.get(`terms`, { type: type });

      this.mounted && this.setState({
        content: content
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render = () => {
    const { type, onClose } = this.props;
    const { isLoading, content } = this.state;

    let title = 'Terms and Conditions';

    switch (type) {
      case 'terms':
        title = 'Terms and Conditions';
      break;
      case 'privacy':
        title = 'Privacy Policy';
      break;
      case 'gdpr':
        title = 'GDPR';
      break;
    }

    return (
      <CoverModal
        closeOnTop
        middleContent={ <h2 className='mT-40'>{ title }</h2> }
        style={{ maxWidth: 1200, width: '80%', height: '80%' }}
        enableCloseOutside
        onClose={ onClose }
      >
        <div
          className={ classNames('mH-350', {
            'd-f jc-c ai-c': isLoading
          }) }
        >
          <BlockingSpinner isLoading={ isLoading }>
            { !!content &&
              <div className="TermsModal ta-l m-50" dangerouslySetInnerHTML={{ __html: content }} />
            }
          </BlockingSpinner>
        </div>
      </CoverModal>
    );
  };
};

export default TermsModal;
