import { FormRecord } from "components/form/form-wrapper";
import { Workflow, WorkflowPreview, WorkflowHistory } from 'components/workflow/Workflow.interface';
import { UserPermissions } from "./permissions";

export enum UserType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
}

export enum UserStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Pending = 'PENDING'
}

export interface EntityConfig {
  audit: boolean;
  comment: boolean;
  form: boolean;
  nestable?: boolean;
  role: boolean;
  root_entity: boolean;
  version: boolean;
  workflow: boolean;
  resource_region: boolean;
};

export interface FormConfig {
  can_view: boolean;
  can_create: boolean;
  can_edit: ICanEdit;
  can_version: boolean;
  can_archive: boolean;
  can_hide: boolean;
  can_roll_out_specification: boolean;
  can_clone: boolean;
  can_export: boolean;
  is_locked_title: boolean;
};

export interface ICanEdit {
  status: boolean,
  tooltip: string | null,
};

export enum EntityStatus {
  Active = 'ACTIVE',
  Hidden = 'HIDDEN',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT'
};

export enum EntityStatusLabels {
  Active = "Active",
  Hidden = "Hidden",
  Archived = "Archived",
  Draft = "Draft",
};

export interface EntityVersion {
  config: EntityConfig;
  created_at: string;
  id: number;
  path: string;
  status: EntityStatus;
  title: string;
  updated_at: string;
  version: number;
  is_current: boolean;
};

export enum ResourceStatus {
  Approved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED",
};

export enum ResourceStatusLabels {
  Approved = "Approved",
  Pending = "Pending Approval",
  Rejected = "Rejected",
};

export enum ResourceType {
  Direct = "direct",
  Inherited = "inherited",
};

export enum ResourceTypeLabels {
  Direct = "Direct",
  Inherited = "Inherited",
};

export interface ResourceEntity {
  id: number;
  role_id?: number;
  user_id: number;
  type: ResourceType;
  primary?: boolean;
  status?: ResourceStatus;
  user: UserEntity;
};

export interface RoleEntity {
  created_at: string;
  description: string;
  id: number;
  reference: string;
  title: string;
  updated_at: string;
};

export interface RoleResourcesEntity extends RoleEntity {
  created_at: string;
  description: string;
  id: number;
  reference: string;
  resources: ResourceEntity[];
  title: string;
  updated_at: string;
};

export interface Entity {
  id: number;
  created_at: string;
  deleted_at: string;
  path: string;
  updated_at: string;
};

export interface UserEntity {
  id: number;
  access_type: UserAccessType;
  first_name: string;
  last_name: string;
  full_name: string;
  last_login: string | null;
  email: string;
  status: string;
  active_client: number;
  is_masquerading: boolean;
  clients: ClientEntity[];
  settings: any;
  created_at: string;
  updated_at: string;
  phone_object: PhoneObject | null;
  company: CompanyEntity;
  permissions: UserPermissions;
  type: UserType;
  default_dashboard_id: number | null;
};

export interface BreadcrumbEntity {
  title: string;
  path: string | null;
};

export interface CompanyEntity extends Entity {
  config: EntityConfig;
  title: string;
};

export interface PhoneObject {
  country_code: string;
  dial_code: string;
  phone_number: string;
};

export interface RecordEntity extends Entity {
  breadcrumbs: BreadcrumbEntity[];
  comments?: any[];
  config: EntityConfig;
  form_config: FormConfig;
  roles: RoleResourcesEntity[];
  title: string;
  display_title: string;
  status: RecordEntityStatus;
  values: Record<string, any[]>;
  version: number;
  permissions: any[];
  workflow: Workflow;
  staged_entities: StagedEntity[];
  workflow_preview: WorkflowPreview[];
  workflow_history: WorkflowHistory[];
  type: string;
  bundle: string;
  new_content: any;
};

export interface RecordFormEntity extends RecordEntity {
  form: FormRecord[];
};

export interface StagedEntity {
  'label': string;
  'id': number;
  'title': string;
  'version': number;
  'path': string;
  'created_at': string;
  'updated_at': string;
  'config': EntityConfig;
  'status': string;
  'bundle': string;
  'type': string;
};

export enum RecordEntityStatus {
  Archived = 'ARCHIVED',
  Hidden = 'HIDDEN'
}

export interface ClientEntity extends Entity {
  name: string;
  pivot: {
    client_id: number;
    user_id: number;
  };
};

export interface AuditChangeEntity {
  field_id: string;
  before: Record<string, any>[];
  after: Record<string, any>[];
  display: {
    before: Record<string, any>[];
    after: Record<string, any>[];
    field: string;
  };
};

export interface AuditEntity {
  action: string;
  entity_title: string;
  changes: AuditChangeEntity[];
  created_at: string;
  id: number;
  type: {
    id: string;
    title: string;
  };
  user: UserEntity;
};

export interface MyPermissions {
  create: boolean;
  create_comment: boolean;
  delete: boolean;
  delete_all_comment: boolean;
  delete_own_comment: boolean;
  edit: boolean;
  edit_all_comment: boolean;
  edit_own_comment: boolean;
  manage_resource: boolean;
  view: boolean;
  view_audit: boolean;
};

export enum UserAccessType {
  Contact = 'CONTACT',
  User = 'USER'
}
