// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import { RestrictionHoC, hasPermission } from 'components/restriction';

// Containers
import ContentContainer from 'containers/admin/content-manager/ContentContainer';
import TemplateContainer from 'containers/admin/template/TemplateContainer';
import EntityContainer from 'containers/admin/entity/EntityContainer';

// Views
import GeneralSettings from 'views/admin/general-settings/GeneralSettings';
import Currency from 'views/admin/general-settings/currencies/Currency';
import Emails from 'views/admin/emails/Emails';
import Notifications from 'views/admin/notifications/Notifications';
import NotificationTemplate from 'views/admin/notifications/NotificationTemplate';
import Users from 'views/admin/users/Users';
import User from 'views/admin/users/User';
import Workflows from 'views/admin/workflows/Workflows';
import Workflow from 'views/admin/workflows/Workflow';
import Roles from 'views/admin/roles/Roles';
import Role from 'views/admin/roles/Role';
import Groups from 'views/admin/groups/Groups';
import Group from 'views/admin/groups/Group';
import ExportImportListView from 'views/admin/export-import/ExportImportListView';
import ExportImportItem from 'views/admin/export-import/ExportImportItem';
import Insights from 'views/admin/insights/Insights';
import NotFound from 'views/NotFound';

// Interfaces
import { Breadcrumb } from 'store/UI/State.interface';
import AppState from 'store/AppState.interface';

// Actions
import { setSecondarySidebarRoutes, setBreadcrumbs } from 'store/UI/ActionCreators';

interface Props {
  user_id: number,
  permissions: any;
  routes: any;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
};

class AdminContainer extends React.Component<Props> {

  mounted: boolean = false;

  constructor(props: Props) {
    super(props);
    this.instantiate();
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  componentDidUpdate = (prevProps: Props) => {
    if (_.isEmpty(prevProps.routes)) {
      this.instantiate();
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.props.setSecondarySidebarRoutes([]);
    this.mounted = false;
  };

  instantiate = () => {
    this.props.setSecondarySidebarRoutes([
      { title: 'General Settings', path: '/admin/general-settings', isDisabled: !hasPermission(this.props.permissions, 'access_admin_general_settings') },
      { title: 'Emails', path: '/admin/emails', isDisabled: !hasPermission(this.props.permissions, 'access_admin_emails') },
      { title: 'Notifications', path: '/admin/notifications', isDisabled: !hasPermission(this.props.permissions, 'access_admin_notifications') },
      { title: 'Users', path: '/admin/users', isDisabled: !hasPermission(this.props.permissions, 'access_admin_users') },
      { title: 'Workflows', path: '/admin/workflows', isDisabled: !hasPermission(this.props.permissions, 'access_admin_workflows') },
      { title: 'Roles', path: '/admin/roles', isDisabled: !hasPermission(this.props.permissions, 'access_admin_roles') },
      { title: 'Groups', path: '/admin/groups', isDisabled: !hasPermission(this.props.permissions, 'access_admin_groups') },
      { title: 'Content Manager', path: '/admin/content-manager', isDisabled: !hasPermission(this.props.permissions, 'access_admin_content_manager') },
      { title: 'Record Settings', path: '/admin/record-settings', isDisabled: !hasPermission(this.props.permissions, 'access_admin_entity_config') },
      { title: 'Templates', path: '/admin/templates', isDisabled: !hasPermission(this.props.permissions, 'access_admin_templates') },
      { title: 'Export - Import', path: '/admin/export-import', isDisabled: !hasPermission(this.props.permissions, 'access_admin_import') && this.props.user_id !== 1 },
      { title: 'Insights', path: '/admin/insight-settings', isDisabled: !hasPermission(this.props.permissions, 'access_admin_insights') },
    ]);
  };

  render = () => {
    return (
      <Switch>

        <Redirect exact from="/admin" to="/admin/general-settings" />

        <Redirect exact from="/admin/general-settings" to="/admin/general-settings/overview" />
        <Route exact path="/admin/general-settings/:type" component={ GeneralSettings } />
        <Route exact path="/admin/general-settings/currencies/:currency" component={ Currency } />

        <Route exact path="/admin/emails" component={ Emails } />

        <Route exact path="/admin/notifications" component={ Notifications } />
        <Route exact path="/admin/notifications/template/:id" component={ NotificationTemplate } />

        <Route exact path="/admin/users" component={ Users } />
        <Route exact path="/admin/users/:id" component={ User } />

        <Route exact path="/admin/workflows" component={ Workflows } />
        <Route exact path="/admin/workflows/:workflow_id" component={ Workflow } />

        <Route exact path="/admin/roles" component={ Roles } />
        <Route exact path="/admin/roles/:id" component={ Role } />

        <Route exact path="/admin/groups" component={ Groups } />
        <Route exact path="/admin/groups/:id" component={ Group } />

        <Route path="/admin/content-manager" component={ ContentContainer } />

        <Route path="/admin/templates" component={ TemplateContainer } />

        <Route exact path="/admin/export-import" component={ ExportImportListView } />

        <Route exact path="/admin/export-import/:id" component={ ExportImportItem } />

        <Route path="/admin/record-settings" component={ EntityContainer } />

        <Route exact path="/admin/insight-settings" component={ Insights } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    user_id: store.UserState.user.id,
    routes: store.UIState.secondarySidebarRoutes,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(AdminContainer, 'access_admin'));