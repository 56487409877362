// Libs
import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, NavLink, Link } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import _ from 'lodash';

// Components
import { Badge, Menu, Tooltip } from 'antd';
import Base64Image from 'components/base64-image';

// Icons
import Icon, { MenuOutlined } from '@ant-design/icons';

import { ReactComponent as LeftMenuArrowIcon } from 'assets/svg/left-menu-arrow.svg';
import { ReactComponent as RightMenuArrowIcon } from 'assets/svg/right-menu-arrow.svg';
import { ReactComponent as CustomerIcon } from 'assets/svg/customer.svg';
import { ReactComponent as PortfolioIcon } from 'assets/svg/portfolio.svg';
import { ReactComponent as WorkspaceIcon } from 'assets/svg/workspace.svg';
import { ReactComponent as FinanceIcon } from 'assets/svg/finance.svg';
import { ReactComponent as WorkspaceServiceIcon } from 'assets/svg/workspace-service.svg';
import { ReactComponent as ProjectIcon } from 'assets/svg/project.svg';
import { ReactComponent as SupplierIcon } from 'assets/svg/supplier.svg';
import { ReactComponent as EhsIcon } from 'assets/svg/ehs.svg';
import { ReactComponent as DocumentIcon } from 'assets/svg/documents.svg';
import { ReactComponent as FallbackToolIcon } from 'assets/svg/fallback-tool-icon.svg';

// Services
import { getActiveSubmenu } from 'services/route';
import { getClientSetting } from 'services/settings';

// Data
import app from '../../../package.json';

// Interfaces
import ClientState from 'store/Client/State.interface';

// Styles
import './Sidebar.scss';

const { SubMenu } = Menu;

interface Props {
  client: ClientState;
  intl: IntlShape;
  router: RouteComponentProps;
  isPrimarySidebarCollapsed: boolean;
  isSecondarySidebarCollapsed: boolean;
  togglePrimarySidebar: any;
  toggleSecondarySidebar: any;
  routes: any;
  secondarySidebarRoutes: any;
};

interface State {
  openKeys: any[];
};

class Sidebar extends React.Component<Props, State> {

  state: State = {
    openKeys: getActiveSubmenu(this.props.routes, this.props.router.location.pathname)
  };

  componentDidUpdate = (prevProps: Props) => {
    const { router, routes } = this.props;

    // Keeps the menus state relative to the route
    if (prevProps.router.location.pathname !== router.location.pathname) {
      this.setState({
        openKeys: getActiveSubmenu(routes, router.location.pathname)
      });
    }

  };

  onOpenChange = (openKeys: any) => {
    this.setState({
      openKeys: openKeys.length > 1 ? openKeys.slice(1) : openKeys // Make sure you can only have one item open a time
    });
  };

  render = () => {
    const {
      client,
      isPrimarySidebarCollapsed,
      isSecondarySidebarCollapsed,
      togglePrimarySidebar,
      toggleSecondarySidebar,
      secondarySidebarRoutes,
      routes,
    } = this.props;
    const { openKeys } = this.state;

    const getMenuIconComponent = (icon: any) => {
      const icons: any = {
        'customers': CustomerIcon,
        'portfolio': PortfolioIcon,
        'workspaces': WorkspaceIcon,
        'projects': ProjectIcon,
        'workplace-services': WorkspaceServiceIcon,
        'finance': FinanceIcon,
        'suppliers': SupplierIcon,
        'ehs': EhsIcon,
        'document-library': DocumentIcon,
      };
      return icons[icon] || FallbackToolIcon;
    };

    const logotype = getClientSetting('logotype');
    const activeTool = getActiveSubmenu(this.props.routes, this.props.router.location.pathname);

    return (
      <div className='Sidebar'>
        <nav className={ classNames('Sidebar-primary', { 'is-collapsed': isPrimarySidebarCollapsed }) }>
          <div className='Sidebar-primary-header'>
            <div className='Sidebar-primary-wrapper'>
              <div className='Sidebar-primary-nav'>
                <MenuOutlined
                  className='Sidebar-primary-icon'
                  onClick={ () => togglePrimarySidebar() }
                />
              </div>
              <div className={ classNames('Sidebar-primary-logo', { 'd-n': isPrimarySidebarCollapsed }) }>
                <Link to='/' className="ta-c">
                  { logotype ? (
                      <Base64Image client_id={ client.client_id } image_id={ logotype } height={ 'auto' } width={ 'auto' } />
                    ) : (
                      <img alt='Logotype' width='auto' height='auto' src={ '/pacs-logo.png' } />
                    )
                  }
                </Link>
              </div>
            </div>
          </div>
          <div className='Sidebar-primary-menu'>
            { !isPrimarySidebarCollapsed ?
              <Menu
                mode='inline'
                openKeys={ openKeys }
                selectable={ false }
                onOpenChange={ this.onOpenChange }
                style={{ borderColor: 'transparent' }}
              >
                { routes && routes.map((tool: any, index: number) => (
                  <SubMenu
                    key={ index }
                    disabled={ isEmpty(tool.modules) }
                    className={ classNames('defaultColor', { 'Sidebar-Primary-submenu--no-modules': isEmpty(tool.modules) }) }
                    title={
                      <span>
                        <Icon
                          style={{ fontSize: 20 }}
                          className={ classNames({ 'primaryColor': activeTool.includes(index.toString()) }) }
                          component={ getMenuIconComponent(tool.route) }
                        />
                        <span>{tool.label}</span>
                      </span>
                    }
                  >
                    { !tool.disabled && tool.modules && tool.modules.map((module: any, index: number) => (
                      <Menu.Item key={`module-${index}-${module.label}`} disabled={ !!module.disabled || !module.route }>
                        <NavLink
                          activeClassName="Sidebar-primary-item-is-active"
                          style={{ paddingLeft: '7px' }}
                          to={ `/${tool.route}/${module.route}` }
                          isActive={ (__: any, location: any) => {

                            // TODO: replace with a proper solution when there's time
                            if (location.pathname.match(/sublease\/[0-9]+/) && ['leases', 'subleases'].includes(module.route)) {
                              return module.route === 'subleases';
                            }

                            const parts = location.pathname.split('/').filter((part: any) => {
                              return part.length;
                            });

                            return parts && parts[1] === module.route;
                          }}
                        >
                          <span>{ module.label }</span>
                        </NavLink>
                      </Menu.Item>
                    )) }
                  </SubMenu>
                )) }
              </Menu>
            :
              <React.Fragment>
                { routes && routes.map((tool: any, index: number) => (
                  <div
                    key={ index }
                    className='Sidebar-primary-menu-item'
                  >
                    <Tooltip key={ index } title={ tool.label } placement="right">
                      <Icon
                        className={ classNames('m-a pT-15 pB-15', { 'defaultColor': !!tool.modules.length && !activeTool.includes(index.toString()), 'primaryColor': activeTool.includes(index.toString()) } ) }
                        style={{ ...{ fontSize: 20 }, ...(isEmpty(tool.modules) ? { cursor: 'not-allowed' } : {}) }}
                        component={ getMenuIconComponent(tool.route) }
                        onClick={ () => !!tool.modules.length && togglePrimarySidebar() }
                      />
                    </Tooltip>
                  </div>
                )) }
              </React.Fragment>
            }
          </div>
          <div className='Sidebar-primary-footer'>
            { !isPrimarySidebarCollapsed &&
              <span className='d-n fw-600'>{ app.version }</span>
            }
          </div>
        </nav>
        { !isEmpty(secondarySidebarRoutes) &&
          <nav className={ classNames('Sidebar-secondary', { 'is-collapsed': isSecondarySidebarCollapsed }) }>
            <div className='Sidebar-secondary-header'>
              <div className='Sidebar-secondary-nav'>
                <Icon
                  className='Sidebar-secondary-icon'
                  component={ isSecondarySidebarCollapsed ? RightMenuArrowIcon : LeftMenuArrowIcon }
                  onClick={ () => toggleSecondarySidebar() }
                />
              </div>
            </div>
            { !isSecondarySidebarCollapsed &&
              <div className='Sidebar-secondary-menu'>
                { secondarySidebarRoutes.map((route: any, index: number) => {
                  if (!!route?.isDisabled) {
                    return (
                      <Tooltip key={ index } title={ 'You do not have permission to view this page' } placement='right'>
                        <div key={ index } className='Sidebar-secondary-menu-item Sidebar-secondary-menu-item-disabled'>
                          { route.isIndented &&
                            <span className='pR-10 Sidebar-secondary-item-is-disabled'>-</span>
                          }
                          <span className='Sidebar-secondary-item-is-disabled'>
                            { route.title }
                          </span>
                        </div>
                      </Tooltip>
                    );
                  } else if (!!route?.isHidden) {
                    return null; // Hidden, don't render the item
                  }

                  return (
                    <div key={ index } className="Sidebar-secondary-menu-item">
                      { route.isIndented &&
                        <span className="pR-10">-</span>
                      }
                      <NavLink
                        to={ route.path }
                        activeClassName='Sidebar-secondary-item-is-active'
                      >
                        { route.title }
                      </NavLink>
                      <Badge dot={ !!route.hasUpdates } />
                    </div>
                  );
                })}
              </div>
            }
          </nav>
        }
      </div>
    );
  };

};

export default injectIntl(Sidebar);