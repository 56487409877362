// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Table, Modal, InputNumber, Tooltip, Typography } from 'antd';

// Icons
import { QuestionCircleOutlined } from '@ant-design/icons';

// Interfaces
import { FormField } from 'components/form/form-wrapper';

// Utils
import { isBlank } from 'utils/utils';

const { Text } = Typography;

interface Props {
  field: FormField;
  measurementUnit: string;
  spaceRecord: any;
  numberFormat: any;
  isDisabled?: boolean;
  onSave(field: FormField): void;
  onClose(): void;
};

interface State {
  spaceRecord: any;
  errors: any[];
};

class FteModal extends React.Component<Props, State> {

  state: State = {
    spaceRecord: _.cloneDeep(this.props.spaceRecord),
    errors: [],
  };

  handleChange = (spaceRecord: any) => {
    this.setState({
      spaceRecord: spaceRecord,
      errors: this.getErrors(spaceRecord),
    });
  };

  getErrors = (spaceRecord: any) => {
    const schema = {
      fte_direct: (value: any) => !isBlank(value) && value !== '' && value !== null,
      fte_indirect: (value: any) => !isBlank(value) && value !== ''&& value !== null,
      fte_capacity: (value: any) => !isBlank(value) && value !== ''&& value !== null,
      workstations: (value: any) => !isBlank(value) && value !== ''&& value !== null,
    };

    const validate = (businessUnit: any, schema: any) => Object
      .keys(schema)
      .filter(key => !schema[key](businessUnit[key]))
      .map(key => key);

    return validate(spaceRecord, schema);
  };

  getInheritedFtes = (spaceRecord: any) => {
    const collector: any = {
      key: 'inherited',
      label: 'Inherited Rows',
      fte_direct: 0,
      fte_indirect: 0,
      fte_capacity: 0,
      workstations: 0,
      disabled: true,
    };

    _.has(spaceRecord, 'children') && !_.isEmpty(spaceRecord.children) && spaceRecord.children.forEach((space: any) => {
      const sumFtes = (_space: any) => {

        collector.fte_direct += _space.fte_direct || 0;
        collector.fte_indirect += _space.fte_indirect || 0;
        collector.fte_capacity += _space.fte_capacity || 0;
        collector.workstations += _space.workstations || 0;

        if (_.has(_space, 'children') && !_.isEmpty(_space.children)) {
          _space.children.forEach((__space: any) => {
            sumFtes(__space);
          });
        }

      };

      return sumFtes(space);
    });

    return collector;
  };

  hasError = (errors: any[], key: string) => {
    return errors.includes(key);
  };

  renderSummaryTable = (spaceRecord: any) => {

    if (!_.has(spaceRecord, 'children') || _.isEmpty(spaceRecord.children)) {
      return <></>;
    }

    const inheritedFtes = this.getInheritedFtes(spaceRecord);
    const direct = parseInt(spaceRecord.fte_direct || 0) + parseInt(inheritedFtes.fte_direct || 0);
    const indirect = parseInt(spaceRecord.fte_indirect || 0) + parseInt(inheritedFtes.fte_indirect || 0);
    const capacity = parseInt(spaceRecord.fte_capacity || 0) + parseInt(inheritedFtes.fte_capacity || 0);
    const ratio = Math.round(((direct + indirect) / (capacity || 1)) * 100);
    const workstations = parseInt(spaceRecord.workstations || 0) + parseInt(inheritedFtes.workstations || 0);

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={ 0 }>
          <Text>{ 'Total' }</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={ 1 } className="ta-r fw-600">
          <Text>{ direct }</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={ 2 } className="ta-r fw-600">
          <Text>{ indirect }</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={ 3 } className="ta-r fw-600">
          <Text>{ capacity }</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={ 5 } className="ta-r fw-600">
          <Text>{ `${ratio}%` }</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={ 5 } className="ta-r fw-600">
          <Text>{ workstations }</Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  render = () => {
    const { field, isDisabled }: any = this.props;
    const { spaceRecord, errors }: any = this.state;
    const data = [{
      ...spaceRecord,
      key: 'current',
      label: 'Current Row',
      children: null,
    }];
    const columns: any = [
      {
        key: 'fte_direct',
        title: (
          <>
            { _.has(field, 'labels.ftes_direct.label') ? field.labels.ftes_direct.label : 'FTEs' }
            { _.has(field, 'labels.ftes_direct.tooltip') &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ field.labels.ftes_direct.tooltip }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }
          </>
        ),
        render: (row: any) => {
          return (
            <InputNumber
              style={{ width: '100%' }}
              autoComplete="newpassword" // hack
              className={classNames('', {
                'border-danger Field--has-error': this.hasError(errors, 'fte_direct') && !row.disabled,
              })}
              disabled={ isDisabled || !!row.disabled }
              onChange={ (value: any) => this.handleChange(_.set(spaceRecord, ['fte_direct'], value)) }
              min={ 0 }
              placeholder={ _.has(field, 'labels.ftes_direct.label') ? field.labels.ftes_direct.label : 'Direct FTE' }
              defaultValue={ row.fte_direct }
            />
          );
        }
      },
      {
        key: 'ftes_indirect',
        title: (
          <>
            { _.has(field, 'labels.ftes_indirect.label') ? field.labels.ftes_indirect.label : "Indirect FTE's" }
            { _.has(field, 'labels.ftes_indirect.tooltip') &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ field.labels.ftes_indirect.tooltip }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }
          </>
        ),
        render: (row: any) => {
          return (
            <InputNumber
              style={{ width: '100%' }}
              autoComplete="newpassword" // hack
              className={classNames('', {
                'border-danger Field--has-error': this.hasError(errors, 'fte_indirect') && !row.disabled,
              })}
              disabled={ isDisabled || !!row.disabled }
              onChange={ (value: any) => this.handleChange(_.set(spaceRecord, ['fte_indirect'], value)) }
              min={ 0 }
              placeholder={ _.has(field, 'labels.ftes_indirect.label') ? field.labels.ftes_indirect.label : 'Indirect FTE' }
              defaultValue={ row.fte_indirect }
            />
          );
        }
      },
      {
        key: 'ftes_capacity',
        title: (
          <>
            { _.has(field, 'labels.ftes_capacity.label') ? field.labels.ftes_capacity.label : "Capacity FTE's" }
            { _.has(field, 'labels.ftes_capacity.tooltip') &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ field.labels.ftes_capacity.tooltip }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }
          </>
        ),
        render: (row: any) => {
          return (
            <InputNumber
              style={{ width: '100%' }}
              autoComplete="newpassword" // hack
              className={classNames('', {
                'border-danger Field--has-error': this.hasError(errors, 'fte_capacity') && !row.disabled,
              })}
              disabled={ isDisabled || !!row.disabled }
              onChange={ (value: any) => this.handleChange(_.set(spaceRecord, ['fte_capacity'], value)) }
              min={ 0 }
              placeholder={ _.has(field, 'labels.ftes_capacity.label') ? field.labels.ftes_capacity.label : 'Capacity FTE' }
              defaultValue={ row.fte_capacity }
            />
          );
        }
      },
      {
        key: 'occupancy_ratio',
        title: (
          <>
            { _.has(field, 'labels.occupancy_ratio.label') ? field.labels.occupancy_ratio.label : 'Occupancy Ratio' }
            { _.has(field, 'labels.occupancy_ratio.tooltip') &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ field.labels.occupancy_ratio.tooltip }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }
          </>
        ),
        render: (row: any) => {
          return (
            <InputNumber
              style={{ width: '100%' }}
              disabled
              value={ Math.round(((row.fte_direct + row.fte_indirect) / (row.fte_capacity || 1)) * 100) }
              min={ 0 }
              max={ 100 }
              formatter={ value => `${value}%` }
              parser={ (value: any) => value.replace('%', '') }
            />
          );
        }
      },
      {
        key: 'workstations',
        title: (
          <>
            { _.has(field, 'labels.workstations.label') ? field.labels.workstations.label : 'Workstations' }
            { _.has(field, 'labels.workstations.tooltip') &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ field.labels.workstations.tooltip }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }
          </>
        ),
        render: (row: any) => {
          return (
            <InputNumber
              style={{ width: '100%' }}
              autoComplete="newpassword" // hack
              className={classNames('', {
                'border-danger Field--has-error': this.hasError(errors, 'workstations') && !row.disabled,
              })}
              disabled={ isDisabled || !!row.disabled }
              onChange={ (value: any) => this.handleChange(_.set(spaceRecord, ['workstations'], value)) }
              min={ 0 }
              placeholder={ _.has(field, 'labels.workstations.label') ? field.labels.workstations.label : 'Workstations' }
              defaultValue={ row.workstations }
            />
          );
        }
      }
    ];

    if (_.has(spaceRecord, 'children') && !_.isEmpty(spaceRecord.children)) {
      columns.unshift(
        {
          key: 'label',
          title: '',
          width: 130,
          render: (row: any) => {
            return (
              <span>{ row.label }</span>
            );
          }
        }
      );

      data.push(this.getInheritedFtes(spaceRecord));
    }

    return (
      <Modal
        centered
        closable={ false }
        visible
        title={ 'FTEs' }
        onCancel={ () => this.props.onClose() }
        okButtonProps={{
          disabled: isDisabled || !_.isEmpty(errors)
        }}
        okText={ 'Save' }
        onOk={ () => {
          this.props.onSave(spaceRecord);
          this.props.onClose();
        } }
        style={{ minWidth: 1000 }}
      >
        <Table
          columns={ columns }
          dataSource={ data }
          pagination={ false }
          summary={ () => {
            return this.renderSummaryTable(this.state.spaceRecord);
          }}
        />
      </Modal>
    );
  };
};

export default FteModal;
