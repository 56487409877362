// Libs
import React, { BaseSyntheticEvent } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import CoverModal from 'components/cover-modal';
import {
  Button,
  Input,
  Form,
  Select,
  Steps,
  Spin
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Flag from 'components/flag';

// Services
import { validEmail, validPhone } from 'utils/utils';
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import { UserType } from 'types/entities';

interface existingUser {
  first_name: string;
  last_name: string;
  email: string;
  phone: phoneField;
};

interface phoneField {
  code?: string;
  number?: string;
};

interface Props {
  clientId: number;
  userId: number;
  intl: IntlShape;
  onClose(refetch: boolean): void;
};

interface State {
  activeStep: number;
  userPlaceholder: {
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    type?: UserType;
    company?: any;
    phone?: phoneField | null;
    roles?: any[];
  };
  stepOne: {
    isLoading?: boolean;
    isEmailValid?: boolean | null;
    user_request?: boolean | null;
    user_in_shard?: boolean | null;
    user?: existingUser | null;
  };
  stepTwo: {
    isFetchingPhone?: boolean,
    isCreatingUser?: boolean,
    invalidCountryCode?: boolean | null,
    invalidPhone?: boolean | null,
    isPhoneBusy?: boolean | null,
  };
  isFetchingCompanies: boolean;
  availableCompanies: any[];
  isFetchingUserTypes: boolean;
  availableUserTypes: any[];
  isFetchingCountryCodes: boolean;
  availableCountryCodes: any[];
  isFetchingRoles: boolean;
  availableCompanyRoles: any[];
  isFetchingRegions: boolean;
  availableCompanyRegions: any[];
};

const API: Api = new Api();
const { Option } = Select;
const { Search } = Input;
const { Step } = Steps;

class CreateUserDialog extends React.Component<Props, State> {
  mounted: boolean = false;
  stepTwoFormRef: any = React.createRef();

  state: State = {
    activeStep: 1,
    userPlaceholder: {
      email: null,
      phone: null,
      company: null,
      type: undefined,
      first_name: null,
      last_name: null,
      roles: [],
    },
    stepOne: {
      isLoading: false,
      isEmailValid: null,
      user_request: null,
      user_in_shard: null,
      user: null,
    },
    stepTwo: {
      isFetchingPhone: false,
      isCreatingUser: false,
      invalidCountryCode: null,
      invalidPhone: null,
      isPhoneBusy: null,
    },
    isFetchingCompanies: false,
    availableCompanies: [],
    isFetchingUserTypes: false,
    availableUserTypes: [],
    isFetchingCountryCodes: false,
    availableCountryCodes: [],
    isFetchingRoles: false,
    availableCompanyRoles: [],
    isFetchingRegions: false,
    availableCompanyRegions: [],
  };

  componentDidMount = async () => {
    const { clientId } = this.props;
    this.mounted = true;

    try {
      const newAvailableCountryCodes = await API.get(`client/${clientId}/field/phone/phone_number/country_codes`);

      this.mounted && this.setState({
        availableCountryCodes: newAvailableCountryCodes,
      });

    } catch {
      console.error('Failed to fetch country codes');
    } finally {
      this.mounted && this.setState({
        isFetchingCompanies: false,
        isFetchingCountryCodes: false,
      });
    }
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { clientId } = this.props;
    const { availableUserTypes, activeStep } = this.state;

    // Fetch companies
    if ((prevState.activeStep !== activeStep) && activeStep === 2) {

      if (_.isEmpty(availableUserTypes)) {
        try {
          await new Promise((resolve) => this.setState({ isFetchingUserTypes: true }, () => resolve(null) ));
          const availableUserTypes = await API.get(`client/${clientId}/user_types`);

          this.mounted && this.setState({
            availableUserTypes: availableUserTypes
          });
        } catch {
          console.error('Failed to fetch user types');
        } finally {
          this.mounted && this.setState({
            isFetchingUserTypes: false,
          });
        }
      }
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  changeStep = (step: number) => {
    this.setState({
      activeStep: step
    });
  };

  fetchCompanies = async (companyType: string = '') => {
    const { clientId } = this.props;

    // Fetch companies
    try {
      await new Promise((resolve) => this.setState({ isFetchingCompanies: true, isFetchingCountryCodes: true }, () => resolve(null) ));
      const newAvailableCompanies = await API.get(`client/${clientId}/available_companies?type=${companyType}`);

      this.mounted && this.setState({
        availableCompanies: newAvailableCompanies
      });

    } catch {
      console.error('Failed to fetch companies/country codes');
    } finally {
      this.mounted && this.setState({
        isFetchingCompanies: false,
        isFetchingCountryCodes: false,
      });
    }
  };

  handleCreateUser = async () => {
    const { clientId } = this.props;
    const { stepOne, stepTwo, userPlaceholder } = this.state;
    const isExistingUser = !!stepOne?.user;

    try {

      await new Promise((resolve) => this.setState({
        stepTwo: Object.assign(stepTwo, { isCreatingUser: true })
      }, () => resolve(null)) );

      let payload = {};

      if (!isExistingUser) {
        if (
          !userPlaceholder.type ||
          !userPlaceholder.company ||
          !userPlaceholder.email ||
          !userPlaceholder.first_name ||
          !userPlaceholder.last_name
        ) throw new Error();

        payload = {
          type: userPlaceholder.type,
          company_owner: {
            id: userPlaceholder.company.id,
            bundle: userPlaceholder.company.bundle,
            type: userPlaceholder.company.type
          },
          email: userPlaceholder.email,
          first_name: userPlaceholder.first_name,
          last_name: userPlaceholder.last_name,
          phone_number: {
            country_code: userPlaceholder?.phone?.code,
            value: userPlaceholder?.phone?.number
          }
        };
      } else {
        if (
          !userPlaceholder.type ||
          !userPlaceholder.company
        ) throw new Error();

        payload = {
          type: userPlaceholder.type,
          company_owner: {
            id: userPlaceholder.company.id,
            bundle: userPlaceholder.company.bundle,
            type: userPlaceholder.company.type
          },
          email: stepOne?.user?.email,
          first_name: stepOne?.user?.first_name,
          last_name: stepOne?.user?.last_name,
          phone_number: {
            country_code: stepOne?.user?.phone.code,
            value: stepOne?.user?.phone.number
          }
        };
      }

      await API.post(`client/${clientId}/record/user-request`, {
        mode: 'simplified',
        data: JSON.stringify({
          fields: payload
        })
      });

      this.mounted && this.setState({
        activeStep: 3
      }, () => {
        Notification('success', 'User Request Created', 'Successful');
      });

    } catch (err) {
      console.log(err);
      Notification('error', "Failed to create user request", 'Failed');
    } finally {
      this.mounted && this.setState({
        stepTwo: Object.assign(stepTwo, { isCreatingUser: false }),
      });
    }
  };

  handleEmailSearch = async (email: string) => {
    const { clientId } = this.props;
    const { userPlaceholder, stepOne } = this.state;

    // Nothings changed since last check
    if (userPlaceholder.email === email) return;

    // Check if email is valid
    if (!validEmail(email)) {
      return this.setState({
        stepOne: Object.assign(stepOne, {
          isLoading: false,
          isEmailValid: false
        }),
      });
    }

    try {

      await new Promise((resolve) => this.setState({
        userPlaceholder: Object.assign(userPlaceholder, { email: email }),
        stepOne: Object.assign(stepOne, { isLoading: true })
      }, () => resolve(null)) );

      const userRequestExists = await API.get(`client/${clientId}/record/user-request/exists?email=${email}`);

      this.setState({
        stepOne: {
          isEmailValid: true,
          user: userRequestExists.user,
          user_in_shard: !!userRequestExists.user_in_shard,
          user_request: !!userRequestExists.user_request,
        }
      });

    } catch {
      Notification('error', "Couldn't validate email", 'Failed');
    }
  };

  debouncedEmailSearch = _.debounce(this.handleEmailSearch, 500);

  renderStepOne = () => {
    const { stepOne, userPlaceholder } = this.state;

    let processButton = (
        <Button
          type="primary"
          loading={ !!stepOne.isLoading }
          onClick={ () => {
            this.setState({
              userPlaceholder: !!stepOne.user ? Object.assign(userPlaceholder, stepOne.user) : userPlaceholder,
              activeStep: 2,
            });
          } }
        >
          Next
        </Button>
      );

    if (!stepOne.isEmailValid || !!stepOne.user_in_shard || !!stepOne.user_request) {
      processButton = <Button disabled type="primary">Next</Button>;
    }

    let errorText = null;

    if (stepOne.isEmailValid === false) {
      errorText = 'Invalid email';
    } else if (!!stepOne.user_in_shard) {
      errorText = 'A user already exists with this email';
    } else if (!!stepOne.user_request) {
      errorText = 'A pending User Request for this email already exists';
    }

    return (
      <div className="d-f fxd-c">
        <div className="d-f fxd-c mB-50">
          <p className="mB-30">Please enter the email address for the user you would like to add.</p>
          <Form layout="vertical">
            <Form.Item
              label={
                <>
                  <span>Email</span>
                  { !!errorText === true && <span className="text-danger mL-10">({ errorText })</span> }
                </>
              }
              className="fx-1"
              required
            >
              <Search
                className={ classNames({
                  'border-danger': stepOne.isEmailValid === false || !!stepOne.user_in_shard || !!stepOne.user_request
                }) }
                autoFocus
                onFocus={ event => event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length) }
                loading={ !!stepOne.isLoading }
                onSearch={ (value: string) => this.handleEmailSearch(value) }
                onPressEnter={ (event: BaseSyntheticEvent) => this.handleEmailSearch(event.target.value) }
                onChange={ (event: BaseSyntheticEvent) => this.debouncedEmailSearch(event.target.value) }
              />
            </Form.Item>
          </Form>
        </div>
        <div className="d-f fxd-c ai-c">
          { processButton }
        </div>
      </div>
    );
  };

  renderStepTwo = () => {
    const {
      userPlaceholder,
      isFetchingCompanies,
      isFetchingUserTypes,
      isFetchingCountryCodes,
      availableCompanies,
      availableUserTypes,
      availableCountryCodes,
      stepTwo,
      stepOne,
    } = this.state;
    const {
      first_name,
      last_name,
      type,
      company,
    } = userPlaceholder;

    return (
      <div className="d-f fxd-c">
        <Form
          ref={ this.stepTwoFormRef }
          layout="vertical"
          autoComplete="off"
          initialValues={{
            "type": type || '',
            "first_name": first_name || '',
            "last_name": last_name || '',
            "country_code": !!stepOne?.user?.phone?.code ? !!stepOne?.user?.phone?.code : 'GB',
            "phone_number": !!stepOne.user?.phone?.number ? stepOne.user.phone.number : undefined,
          }}
          onFinish={ (fields: any) => {
            this.setState({
              userPlaceholder: Object.assign(userPlaceholder, {
                type: fields.type,
                first_name: fields.first_name,
                last_name: fields.last_name,
                phone: {
                  code: fields.country_code,
                  number: fields.phone_number,
                }
              })
            }, () => {
              this.handleCreateUser();
            });
          } }
        >
          <Form.Item
            required
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: 'Select type'
              },
            ]}
          >
            <Select
              disabled={ isFetchingUserTypes }
              loading={ isFetchingUserTypes }
              onChange={(value: any) => {
                this.stepTwoFormRef.current.setFieldsValue({
                  company: null
                });
                this.fetchCompanies(value);
              }}
              value={ type || undefined }
            >
              { availableUserTypes && availableUserTypes.map((userType: any) => (
                <Option key={userType.id} value={userType.id}>{ userType.title }</Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item
            required
            label="Company"
            name="company"
            rules={[
              {
                required: true,
                message: 'Select company'
              },
            ]}
          >
            <Select
              showSearch
              disabled={ isFetchingCompanies }
              loading={ isFetchingCompanies }
              onSearch={(value: string) => {
                if (availableCompanies) {
                  const option = availableCompanies.find(company => company.title.toLowerCase() === value.toLowerCase());
                  if (!!option) {
                    this.setState({
                      userPlaceholder: Object.assign(userPlaceholder, { company: option })
                    });
                  }
                }
              }}
              onChange={(company_id: any) => {
                const company = availableCompanies.find((company: any) => company.id === company_id);
                this.setState({
                  userPlaceholder: Object.assign(userPlaceholder, { company: company })
                });
              }}
              filterOption={(input: any, option: any) => {
                if (option && !!option.children) {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                return false;
              }}
              value={ company || '' }
            >
              { availableCompanies && availableCompanies.map((company: any) => (
                <Option key={company.id} value={company.id}>{ company.title }</Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item
            required
            name="first_name"
            label="First Name"
            rules={[
              {
                required: true,
                message: 'Enter first name'
              },
            ]}
          >
            <Input
              disabled={ !!stepOne.user }
              onBlur={(event: BaseSyntheticEvent) => {
                this.setState({
                  userPlaceholder: Object.assign(userPlaceholder, { first_name: event.target.value })
                });
              }}
            />
          </Form.Item>
          <Form.Item
            required
            name="last_name"
            label="Last Name"
            rules={[
              {
                required: true,
                message: 'Enter last name'
              },
            ]}
          >
            <Input
              disabled={ !!stepOne.user }
              onBlur={(event: BaseSyntheticEvent) => {
                this.setState({
                  userPlaceholder: Object.assign(userPlaceholder, { last_name: event.target.value })
                });
              }}
            />
          </Form.Item>
          { !!stepOne.user ? (
            <Form.Item
              name="phone_number"
              label="Phone Number"
            >
              <Input disabled />
            </Form.Item>
          ) : (
            <Form.Item
              name="phone_number"
              label="Phone Number"
              style={{ width: '100%' }}
              rules={[
                ({ getFieldValue }) => ({
                  validator: async (_, phoneNumber = '', callback) => {

                    if (!!phoneNumber) {

                      try {

                        // Check if country code is selected
                        if (!getFieldValue('country_code')) {
                          return Promise.reject(new Error('Select country code'));
                        }

                        const country = availableCountryCodes.find((country: any) => country.code === getFieldValue('country_code'));

                        // Check if phone number is valid
                        if (phoneNumber.length < 5 || !validPhone(`+${country.dial_code}${phoneNumber}`)) {
                          return Promise.reject(new Error('Invalid phone number'));
                        }

                        await new Promise((resolve) => this.setState({ stepTwo: Object.assign(stepTwo, { isFetchingPhone: true }) }, () => resolve(null)) );

                        const phoneExists = await API.get(`client/1/record/user-request/phone-exists`, {
                          phone: `+${country.dial_code} ${phoneNumber}`
                        });

                        if (!!phoneExists) {
                          return Promise.reject(new Error('The phone number is already in use'));
                        }

                      } catch (err: any) {
                        callback(err);
                      } finally {
                        this.setState({
                          stepTwo: Object.assign(stepTwo, { isFetchingPhone: false })
                        });
                      }

                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                addonBefore={ (
                  <Form.Item
                    name="country_code"
                    noStyle
                  >
                    <Select
                      style={{ minWidth: 120 }}
                      showSearch
                      className={ classNames('Select-Field', {
                        'border-danger Select-Field--has-error': stepTwo.invalidCountryCode === true,
                      }) }
                      dropdownMatchSelectWidth={ false }
                      optionLabelProp={ 'label' }
                      disabled={ isFetchingCountryCodes }
                      placeholder={ 'Code' }
                      filterOption={(input: any, option: any) => {
                        return availableCountryCodes.find((country: any) => {
                          return country.code.toLowerCase() === option.value.toLowerCase() && `${country.title} +${country.dial_code}`.toLowerCase().includes(input.toLowerCase());
                        });
                      } }
                      onChange={() => {
                        this.stepTwoFormRef.current.validateFields(['phone_number']);
                      }}
                      // @ts-ignore
                      autoComplete="none"
                    >
                      { availableCountryCodes
                        .sort((a: any, b: any) => a.title.localeCompare(b.title))
                        .map((country: any) => (
                          <Option key={ country.id } value={ country.code } label={ <span><Flag code={ country.code.toLowerCase() } /><span className="va-m mL-5">{ `+${country.dial_code}` }</span></span> }>
                            <Flag code={ country.code.toLowerCase() } /><span className="va-m mL-5">{ `${country.title} +${country.dial_code}` }</span>
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                ) }
                disabled={ !!stepOne.user }
                className={ classNames({
                  'border-danger': stepTwo.invalidPhone === true
                }) }
                prefix={<span className="op-50p">(0)</span>}
                placeholder="1234567890"
                suffix={ !!stepTwo.isFetchingPhone &&
                  <Spin size={ 'small' } indicator={ <LoadingOutlined /> } />
                }
              />
            </Form.Item>
          ) }
          <Form.Item>
            <div className="d-f fxd-r ai-c jc-c mT-50">
                <Button type="primary" disabled={ !!stepTwo.isFetchingPhone || !!stepTwo.isCreatingUser } loading={ !!stepTwo.isFetchingPhone || !!stepTwo.isCreatingUser } htmlType="submit">Next</Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  };

  renderStepThree = () => {
    return (
      <>
        <div className="d-f fxd-c jc-c ai-c mB-50">
          <p>The user request has been created.</p>
        </div>
        <div className="d-f fxd-r ai-c jc-c">
          <Button type="primary" onClick={ () => this.props.onClose(true) }>Close</Button>
        </div>
      </>
    );
  };

  render = () => {
    const { activeStep } = this.state;
    return (
      <CoverModal
        closeOnTop
        middleContent={
          <div className="mT-30">
            <Steps current={ activeStep - 1 }>
              <Step />
              <Step />
              <Step />
            </Steps>
          </div>
        }
        style={{ minWidth: '800px', maxHeight: '900px' }}
        onClose={ () => {
          this.props.onClose(activeStep === 4);
        }}
      >
        <div className="p-100">
          { activeStep === 1 && this.renderStepOne() }
          { activeStep === 2 && this.renderStepTwo() }
          { activeStep === 3 && this.renderStepThree() }
        </div>
      </CoverModal>
    );
  };

};

export default injectIntl(CreateUserDialog);