// Libs
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import BasicList from 'components/basic-list';

// Services
import { getClientSetting } from 'services/settings';
import { Api } from 'services/api';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';

// Icons
import { ArrowRightOutlined } from '@ant-design/icons';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();
export const TABS = ['overview', 'currencies'];

interface Currency {
  id: number;
  title: string;
  code: string;
  symbol: string;
};

interface Props {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  currencies: Currency[] | null;
  isLoading: boolean;
};

class Currencies extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    currencies: null,
    isLoading: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.fetch();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.match.params.type === 'currencies' && prevProps.match.params.type !== this.props.match.params.type) {
      this.fetch();
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  fetch = async () => {
    const { client_id, setBreadcrumbs } = this.props;

    try {

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'General Settings', path: '/admin/general-settings' },
        { title: 'Currencies', path: null },
      ], false);

      await new Promise((resolve) => this.setState({ isLoading: true, currencies: null }, () => resolve(null)));

      const currencies = await API.get(`client/${client_id}/admin/currencies`);

      this.mounted && this.setState({
        currencies: currencies,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  render = () => {
    const { isLoading, currencies } = this.state;

    if (isLoading || !currencies) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    return (
      <BasicList
        rawData
        columns={ [
          {
            key: 'title',
            dataIndex: 'title',
            title: 'Title',
            render: (__: any, currency: any) => {
              if (getClientSetting('currency') === currency.code) {
                return <span className="text-ant-disabled">{ currency.title }</span>;
              }
              return (
                <Link className='primaryColor' to={ `/admin/general-settings/currencies/${_.lowerCase(currency.code)}` }>
                  { `${currency.title}` }
                </Link>
              );
            },
            filterable: true,
            sorter: true,
            ellipsis: true,
          },
          {
            key: 'symbol',
            dataIndex: 'symbol',
            title: 'Symbol',
            filterable: true,
            sorter: false,
            ellipsis: false,
          },
          {
            key: 'code',
            dataIndex: 'code',
            title: 'Source Currency',
            filterable: false,
            sorter: false,
            width: 200,
          },
          {
            key: 'arrow',
            dataIndex: 'arrow',
            title: '',
            filterable: false,
            sorter: false,
            width: 100,
            render: () => {
              return <ArrowRightOutlined />;
            },
          },
          {
            key: 'base_currency',
            dataIndex: 'base_currency',
            title: 'Base Currency',
            filterable: true,
            sorter: false,
            ellipsis: false,
          },
          {
            key: 'active_rate',
            dataIndex: 'active_rate',
            title: 'Active Rate',
            filterable: false,
            sorter: false,
            ellipsis: false,
            render: (__: any, currency: any) => {
              return <span>{ getClientSetting('currency') === currency.code ? 'Default' : currency.active_rate }</span>;
            },
          },
        ] }
        items={ currencies.map((currency: Currency, index: number) => {
          return {
            'key': index,
            ...currency
          };
        }) }
      />
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(Currencies), 'access_admin_general_settings'));
