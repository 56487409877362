// Libs
import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import DragSortingList from 'components/drag-sorting-list';
import { DatePicker, Table } from 'antd';

// Services
import { getUserSetting } from 'services/settings';

// Icons
import { MenuOutlined } from '@ant-design/icons';

// Utils
import { arrayMoveImmutable } from 'utils/formSetup';
import { isBlank } from 'utils/utils';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
  FormFieldKeyDate,
  FormValues,
} from 'components/form/form-wrapper';
import { TableType } from 'components/drag-sorting-list/DragSortingList.interfaces';

// Styles
import 'components/form/field/key-date/KeyDate.scss';

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right',
};

interface Row {
  id: string | null;
  key: string;
  target_id: number | null;
  target_type: string | null;
  target_bundle: string | null;
  value: string | null;
  order: number;
};

const getBlankState = (order: number = 0): Row => {
  const uniqueKey = uuidv4();
  return {
    id: null,
    key: `key_date_${uniqueKey}`,
    target_id: null,
    target_type: null,
    target_bundle: null,
    value: null,
    order: order,
  };
};

interface Props {
  originalValues: any;
  field: FormField;
  clientId: number;
  onValueChange(state: any): void;
  onFieldChange(field: FormField): void;
  config: FormFieldConfig;
  isDisabled?: boolean;
  isNew: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  setLoading: (value: boolean) => void;
};

class KeyDate extends React.Component<Props> {

  componentDidMount = () => {
    const { field } = this.props;
    const ids = field.values.map((value: any) => `${value.target_id}-${value.target_type}-${value.target_bundle}`);

    const mandatoryKeys = !!field?.key_dates ? field.key_dates
      .filter((key: FormFieldKeyDate) => {
        return !ids.includes(`${key.id}-${key.type}-${key.bundle}`);
      })
      .map((key: FormFieldKeyDate, index: number) => {
        return {
          ...getBlankState(field.values.length + index),
          target_id: key.id,
          target_type: key.type,
          target_bundle: key.bundle,
        };
      }) : [];
    this.handleChange([...field.values, ...mandatoryKeys]);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, originalValues } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(field.values, originalValues);
    }
  };

  getErrors = (values: any, required: boolean) => {
    const errors: any = {};

    values.forEach((value: any) => {
      const schema = {
        value: (_value: any) => !isBlank(_value),
      };

      const _validate = (_value: any, schema: any) =>
        Object.keys(schema)
          .filter((key) => !schema[key](_value[key]))
          .map((key) => key);

      if (!_.isEmpty(_validate(value, schema)) && required) {
        errors[value.target_id || value.key] = _validate(value, schema);
      }
    });

    return errors;
  };

  getModified = (values: any, originalValues: any) => {
    const modified: any = {};

    if (!_.isEqual(values, originalValues)) {
      const fieldKeys = ['target_id', 'value', 'order'];

      values.forEach((newValue: any) => {
        if (originalValues.some((_value: any) => _value.target_id === newValue.target_id || _value.key === newValue.key)) {
          const oldValue = originalValues.find((_oldValue: any) => _oldValue.target_id === newValue.target_id || _oldValue.key === newValue.key);
          if (!_.isEqual(newValue, oldValue)) {
            Object.keys(newValue)
              .filter((key: string) => fieldKeys.includes(key))
              .forEach((key: string) => {
                if (!oldValue) {
                  modified[newValue.target_id || newValue.key] = fieldKeys;
                } else if (newValue[key] !== oldValue[key]) {
                  if (!!modified[newValue.target_id || newValue.key] && !modified[newValue.target_id || newValue.key].includes(key)) {
                    modified[newValue.target_id || newValue.key] = modified[newValue.target_id || newValue.key].concat(key);
                  } else {
                    modified[newValue.target_id || newValue.key] = [key];
                  }
                }
              });
          }
        } else {
          modified[newValue.target_id || newValue.key] = fieldKeys;
        }
      });
    }

    return modified;
  };

  validate = (values: any, originalValues: any) => {
    const { field, config, setFieldErrorMessage, setFieldModifiedMessage } = this.props;
    const errors = this.getErrors(values, !!field?.config?.required);
    const modified = this.getModified(values, originalValues);

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const errorMessage = _.isEmpty(errors) ? undefined : { ...generalMessageInfo, errors: errors };
    const modifiedMessage = _.isEmpty(modified) ? undefined : { ...generalMessageInfo, modified: modified };
    setFieldErrorMessage(`${field.id}`, errorMessage);
    setFieldModifiedMessage(`${field.id}`, modifiedMessage);
  };

  hasError = (fieldErrorMessages: any, fieldKey: string | number, fieldType: string) => {
    return (
      _.has(fieldErrorMessages, ['activity_key_date', 'errors', fieldKey]) &&
      fieldErrorMessages['activity_key_date']['errors'][fieldKey].includes(fieldType)
    );
  };

  isModified = (fieldModifiedMessages: any, fieldKey: string | number, fieldType: string) => {
    return (
      _.has(fieldModifiedMessages, ['activity_key_date', 'modified', fieldKey]) &&
      fieldModifiedMessages['activity_key_date']['modified'][fieldKey].includes(fieldType)
    );
  };

  handleChange = (values: any) => {
    this.props.onValueChange(values);
  };

  modifyValues = (identifier: string | number, values: any, newValue: any, key: any) => {
    return values.map((value: any) => {
      if (value.target_id === identifier || value.key === identifier) {
        value = _.set(value, [key], newValue);
      }

      return {
        ...value,
      };
    });
  };

  moveRowHandler = (dragIndex: number, hoverIndex: number, sourceTableConfig: any, targetTableConfig: any) => {
    const { isDisabled } = this.props;
    if (dragIndex !== hoverIndex && _.isEqual(sourceTableConfig, targetTableConfig) && !isDisabled) {
      const result: FormValues[] = arrayMoveImmutable(this.props.field.values, dragIndex, hoverIndex).filter((el: any) => !!el);
      this.handleChange(result.map((value, index: number) => ({ ...value, order: index })));
    }
  };

  renderList = (columns: any = [], items: any = [], config: any) => {

    if (!!config?.locked) {
      return (
        <Table
          sticky
          size={ 'small' }
          columns={ columns.filter((column: any) => column?.dataIndex !== 'sort') }
          dataSource={ items }
          pagination={ false }
        />
      );
    }

    return (
      <DragSortingList
        className={ 'KeyDate' }
        columns={ columns }
        items={ items }
        pagination={ false }
        isParent
        moveRow={ this.moveRowHandler }
        config={ {
          type: TableType.Tab,
          references: [],
        } }
      />
    );
  };

  render = () => {
    const { field, fieldModifiedMessages, fieldErrorMessages, config, isDisabled } = this.props;
    const columns = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        width: 30,
        render: () => <MenuOutlined className="KeyDate-DragMenu" />,
      },
      {
        key: 'date_type',
        title: 'Date Type',
        render: (row: Row) => {
          const keyDate = field?.key_dates?.find((key_date: FormFieldKeyDate) => `${key_date.id}-${key_date.type}-${key_date.bundle}` === `${row.target_id}-${row.target_type}-${row.target_bundle}`);
          if (!!keyDate) {
            return keyDate.title;
          }
          return '-';
        },
      },
      {
        key: 'value',
        title: (
          <>
            <span>Date</span>{ field?.config?.required && <span className="text-required mL-2 lh-1 fsz-md va-t">*</span> }
          </>
        ),
        width: 300,
        render: (row: Row) => {
          const hasErrors = this.hasError(fieldErrorMessages, row.target_id || row.key, 'value');
          const isModified = this.isModified(fieldModifiedMessages, row.target_id || row.key, 'value');
          const format = getUserSetting('date_format');

          if (!!isDisabled) {
            return (
              <span>
                { moment(row?.value).isValid() ? moment(row?.value).format(`${format}`).toString() : '-' }
              </span>
            );
          }

          return (
            <DatePicker
              className={ classNames('Field', {
                'Field--has-warning border-warning': isModified && !hasErrors,
                'Field--has-error border-danger': hasErrors,
              }) }
              allowClear={ !field?.config?.required }
              placeholder={ 'Select date' }
              format={ format }
              value={ moment(row?.value).isValid() ? moment(row?.value) : undefined }
              onChange={ (date: moment.Moment | null) => {
                this.handleChange(this.modifyValues(row.target_id || row.key, _.cloneDeep(field.values), date ? date.format('YYYY-MM-DD') : null, 'value'));
              } }
            />
          );
        },
      },
    ];

    const items = field.values ? field.values
      .sort((aValue: any, bValue: any) => aValue.order - bValue.order)
      .map((value: any) => {
        return {
          'key': value.key || value.target_id,
          ...value
        };
      }) : [];

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        border
        col={ config.fieldColSpan }
        label={ field.label }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        { this.renderList(columns, items, field.config) }
      </FieldWrapper>
    );
  };
}

export default KeyDate;
