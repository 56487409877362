// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Select, Tooltip, Modal, Form } from 'antd';
import { hasPermission } from 'components/restriction';

// Views
import CreateRecordView from 'views/common/CreateRecordView';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Icons
import {
  PlusCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

// Interfaces
import { RecordFormEntity } from 'types/entities';
import { UserPermissions } from 'types/permissions';

const API: Api = new Api();
const { Option } = Select;

interface Props {
  record: RecordFormEntity;
  clientId?: number;
  userPermissions: UserPermissions;
  services: any;
  service: any;
  onClose(scope: any | null): void;
};

interface State {
  createType: 'company' | 'contract' | null;
  companyTypes: any[];
  companies: any[];
  contracts: any[];
  selectedCompanyType: string | null;
  selectedCompany: number | null;
  selectedContract: number | null;
  isFetchingTypes: boolean;
  isFetchingCompanies: boolean;
  isFetchingContracts: boolean;
  isCreating: boolean;
};

class LinkCompanyModal extends React.Component<Props, State> {

  mounted: boolean = false;
  component: any = React.createRef();

  state: State = {
    createType: null,
    companyTypes: [],
    companies: [],
    contracts: [],
    selectedCompanyType: null,
    selectedCompany: null,
    selectedContract: null,
    isFetchingTypes: false,
    isFetchingCompanies: false,
    isFetchingContracts: false,
    isCreating: false,
  };

  componentDidMount = async () => {
    const { clientId, record } = this.props;

    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isFetchingTypes: true }, () => resolve(null)));

      const companyTypes = await API.get(`client/${clientId}/${_.kebabCase(record.bundle)}/${_.kebabCase(record.type)}/${record.id}/company-summary/company-types`);

      this.mounted && this.setState({
        companyTypes: companyTypes
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetchingTypes: false
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { clientId, record, service, services } = this.props;
    const { selectedCompanyType, selectedCompany } = this.state;

    // Fetch companies
    if (prevState.selectedCompanyType !== selectedCompanyType && !!selectedCompanyType) {
      try {

        await new Promise((resolve) => this.setState({ isFetchingCompanies: true }, () => resolve(null) ));
        const companies = await API.get(`client/${clientId}/${_.kebabCase(record.bundle)}/${_.kebabCase(record.type)}/${record.id}/company-summary/companies`, {
          company_type: selectedCompanyType
        });

        this.mounted && this.setState({
          companies: companies
        });

      } catch {
        console.error('Failed to fetch companies');
      } finally {
        this.mounted && this.setState({
          isFetchingCompanies: false,
        });
      }
    }

    // Fetch contracts
    if (prevState.selectedCompany !== selectedCompany && !!selectedCompany) {
      try {

        await new Promise((resolve) => this.setState({ isFetchingContracts: true }, () => resolve(null) ));

        let _services: any = [];

        if (!!service) {
          _services = [{
            id: service.id,
            type: service.type,
          }];
        } else if (!_.isEmpty(services)) {
          _services = services.map((_service: any) => {
            return {
              id: _service.id,
              type: _service.type,
            };
          });
        }

        const contracts = await API.get(`client/${clientId}/${_.kebabCase(record.bundle)}/${_.kebabCase(record.type)}/${record.id}/company-summary/contracts`, {
          company_id: selectedCompany,
          services: _services,
        });

        this.mounted && this.setState({
          contracts: contracts
        });

      } catch {
        console.error('Failed to fetch contracts');
      } finally {
        this.mounted && this.setState({
          isFetchingContracts: false,
        });
      }
    }
  };

  renderCreateCompanyButton = (isFetchingCompanies: boolean, userPermissions: UserPermissions, selectedCompanyType: string | null, companyTypes: any[]): JSX.Element => {
    const disableCompanyCreation = (selectedCompanyType && companyTypes.some((types: any) => types.type === selectedCompanyType && !!types.disable_company_creation)) || false;
    const canCreateCompany = !!selectedCompanyType && hasPermission(userPermissions, `company_${_.kebabCase(selectedCompanyType)}_create`);

    if (!!disableCompanyCreation) {
      return (
        <Tooltip placement={ 'top' } title={ "A Company can't be created for this Company Type" }>
          <PlusCircleOutlined className="fsz-def text-ant-disabled cur-na" />
        </Tooltip>
      );
    }

    if (!selectedCompanyType) {
      return (
        <Tooltip placement={ 'top' } title={ "Company Type not selected" }>
          <PlusCircleOutlined className="fsz-def text-ant-disabled cur-na" />
        </Tooltip>
      );
    }

    if (canCreateCompany) {

      if (isFetchingCompanies) {
        return <LoadingOutlined className="fsz-def text-ant-default" />;
      }

      return (
        <PlusCircleOutlined
          className="fsz-def text-ant-default"
          onClick={ () => {
            this.setState({
              isFetchingCompanies: true,
              createType: 'company',
            });
          } }
        />
      );
    }

    return (
      <Tooltip placement={ 'top' } title={ "You don't have permissions to create companies" }>
        <PlusCircleOutlined className="fsz-def text-ant-disabled cur-na" />
      </Tooltip>
    );
  };

  renderCreateContractButton = (isFetchingContracts: boolean, permissions: any[], selectedCompany: boolean): JSX.Element => {
    const canCreateContract = hasPermission(permissions, 'record_contract_create') || hasPermission(permissions, 'record_service_specification_manage_contracts');

    if (!canCreateContract || !selectedCompany) {
      return (
        <Tooltip placement={ 'top' } title={ !canCreateContract ? "You don't have permissions to create contracts" : 'Company not selected' }>
          <PlusCircleOutlined className="fsz-def text-ant-disabled cur-na" />
        </Tooltip>
      );
    }

    if (isFetchingContracts) {
      return <LoadingOutlined className="fsz-def text-ant-default" />;
    }

    return (
      <PlusCircleOutlined
        className="fsz-def text-ant-default"
        onClick={ () => {
          this.setState({
            isFetchingContracts: true,
            createType: 'contract',
          });
        } }
      />
    );
  };

  render = (): JSX.Element => {
    const { clientId, record, services, service, userPermissions, onClose } = this.props;
    const {
      companies,
      contracts,
      companyTypes,
      selectedCompany,
      selectedContract,
      selectedCompanyType,
      isCreating,
      isFetchingCompanies,
      isFetchingContracts,
      createType,
    } = this.state;

    let bundle = _.kebabCase(this.props.record.bundle);
    let type = _.kebabCase(this.props.record.type);
    let parent_id: any = null;
    let parent_type: any = null;
    let parent_bundle: any = null;
    const hideContract = (selectedCompanyType && companyTypes.some((types: any) => types.type === selectedCompanyType && !!types.hide_contracts)) || false;

    if (createType) {
      switch (createType) {
        case 'company':
          const companyType = companyTypes.find((type: any) => type.type === selectedCompanyType);
          if (!!companyType) {
            type = _.kebabCase(companyType.type);
            bundle = _.kebabCase(companyType.bundle);
          }
        break;
        case 'contract':
          const company = companies.find((company: any) => company.id === selectedCompany);
          if (!!company) {
            bundle = 'record';
            type = 'contract';
            parent_id = company.id;
            parent_type = _.kebabCase(company.type);
            parent_bundle = _.kebabCase(company.bundle);
          }
        break;
      }
    }

    return (
      <>
        <Modal
          visible
          centered
          zIndex={ 12 }
          title={ !!service ? `Link Contract To --> ${service.title}` : 'Link Contract To Services' }
          maskClosable={ !isCreating }
          okText={ 'Link' }
          onOk={ () => this.mounted && this.setState({
              isCreating: true,
            }, async () => {
              try {

                let contract_id = selectedContract;

                if (hideContract) {
                  contract_id = contracts[0].id;
                }

                let _services: any = [];

                if (!!service) {
                  _services = [{
                    id: service.id,
                    type: service.type,
                  }];
                } else if (!_.isEmpty(services)) {
                  _services = services.map((_service: any) => {
                    return {
                      id: _service.id,
                      type: _service.type,
                    };
                  });
                }

                const scope = await API.put(`client/${clientId}/${bundle}/${type}/${record.id}/scope-matrix/link-contract`, {
                  services: _services,
                  contract_id: contract_id
                });

                onClose(scope);

              } catch (error) {
                Notification('error', 'Failed to link Contract');
                onClose(null);
              }
            }
          ) }
          onCancel={ () => onClose(null) }
          okButtonProps={{
            loading: isCreating || isFetchingCompanies || isFetchingContracts,
            disabled: hideContract ? (!selectedCompanyType || !selectedCompany || _.isEmpty(contracts)) : (!selectedCompanyType || !selectedCompany || !selectedContract)
          }}
        >
          <Form layout="vertical">
            <Form.Item label="Company Type" required>
              <Select
                placeholder={ 'Please Select' }
                onChange={(companytype: string) => {
                  this.setState({
                    selectedCompanyType: companytype,
                    selectedCompany: null,
                    selectedContract: null,
                    companies: [],
                    contracts: [],
                  });
                } }
              >
                { companyTypes.map((company: any, index: number) => (
                  <Option
                    key={ index }
                    value={ company.type }
                  >
                    { company.label }
                  </Option>
                ) ) }
              </Select>
            </Form.Item>
            <Form.Item
              required
              label={ (
                <div className="d-f jc-sb w-100p">
                  <div>
                    <span>Company</span>
                  </div>
                  <div>
                    { this.renderCreateCompanyButton(isFetchingCompanies, userPermissions, selectedCompanyType, companyTypes) }
                  </div>
                </div>
              ) }
            >
              <Select
                showSearch
                loading={ isFetchingCompanies }
                disabled={ isFetchingCompanies || !selectedCompanyType }
                placeholder={ 'Please Select' }
                value={ selectedCompany || undefined }
                onChange={(company_id: number) => {
                  this.setState({
                    selectedCompany: company_id,
                    selectedContract: null,
                    contracts: [],
                  });
                } }
                filterOption={ (input: any, option: any) => {
                  return companies.find((company: any) => company.title.toLowerCase() === option.children.toLowerCase() && company.title.toLowerCase().includes(input.toLowerCase()) );
                }}
              >
                { companies.map((company: any, index: number) => (
                  <Option
                    key={ index }
                    value={ company.id }
                  >
                    { company.title }
                  </Option>
                ) ) }
              </Select>
            </Form.Item>
            { !hideContract &&
              <Form.Item
                required
                label={ (
                  <div className="d-f jc-sb w-100p">
                    <div>
                      <span>Contract</span>
                    </div>
                    <div>
                      { this.renderCreateContractButton(isFetchingContracts, record.permissions, !!selectedCompany) }
                    </div>
                  </div>
                ) }
              >
                <Select
                  showSearch
                  loading={ isFetchingCompanies || isFetchingContracts }
                  disabled={ isFetchingCompanies || isFetchingContracts || !selectedCompanyType || !selectedCompany }
                  placeholder={ 'Please Select' }
                  value={ selectedContract || undefined }
                  onChange={(contract_id: number) => {
                    this.setState({
                      selectedContract: contract_id
                    });
                  } }
                  filterOption={ (input: any, option: any) => {
                    return contracts.find((contact: any) => contact.title.toLowerCase() === option.children.toLowerCase() && contact.title.toLowerCase().includes(input.toLowerCase()) );
                  }}
                >
                  { contracts.map((contract: any, index: number) => (
                    <Option
                      key={ index }
                      value={ contract.id }
                    >
                      { contract.title }
                    </Option>
                  ) ) }
                </Select>
              </Form.Item>
            }
            { !service && !_.isEmpty(services) &&
              <Form.Item className="m-0">
                <b>Selected Services</b>
                <ul className="ov-s" style={{ maxHeight: 200 }}>
                  { services.map((service: any, index: number) => (
                    <li key={ index }>{ service?.label }</li>
                  )) }
                </ul>
              </Form.Item>
            }
          </Form>
        </Modal>
        { createType && !!type && !!bundle &&
          <CreateRecordView
            type={ type }
            entity={ bundle }
            parent_id={ parent_id }
            parent_type={ parent_type }
            parent_bundle={ parent_bundle }
            onClose={ () => this.mounted && this.setState({ createType: null, isFetchingCompanies: false, isFetchingContracts: false }) }
            onCreated={ async (record: any) => {
              try {

                switch (createType) {
                  case 'company':
                    await new Promise((resolve) => this.setState({ isFetchingCompanies: true }, () => resolve(null) ));

                    const companies = await API.get(`client/${clientId}/${_.kebabCase(this.props.record.bundle)}/${_.kebabCase(this.props.record.type)}/${this.props.record.id}/company-summary/companies`, {
                      company_type: selectedCompanyType
                    });

                    this.mounted && this.setState({
                      companies: companies,
                      selectedCompany: record.id,
                      isFetchingCompanies: false,
                    });
                  break;
                  case 'contract':
                    await new Promise((resolve) => this.setState({ isFetchingContracts: true }, () => resolve(null) ));

                    const contracts = await API.get(`client/${clientId}/${_.kebabCase(this.props.record.bundle)}/${_.kebabCase(this.props.record.type)}/${this.props.record.id}/company-summary/contracts`, {
                      company_id: selectedCompany
                    });

                    this.mounted && this.setState({
                      contracts: contracts,
                      selectedContract: record.id,
                      isFetchingContracts: false
                    });
                  break;
                }

              } catch (error) {
                console.error(error);
              } finally {
                this.setState({
                  createType: null
                });
              }
            } }
          />
        }
      </>
    );
  };
};

export default LinkCompanyModal;
