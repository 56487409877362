// Libs
import * as React from 'react';

// Components
import AuditView from 'views/common/AuditView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
};

class ContractPricingAudit extends React.Component<Props> {
  render = () => {
    return <AuditView type={ 'contract-pricing' } entity={ 'record' } record={ this.props.record } />;
  };
};

export default ContractPricingAudit;
