// Libs
import React, { Component } from 'react';
import _ from 'lodash';

// Components
import { Select, Slider } from 'antd';
import { getIconComponent } from 'views/admin/content-manager/slider-range/SliderRangeOptions';

// Interfaces
import { DynamicField, DynamicFieldScoringOption } from 'components/form/field/dynamic/Dynamic.interface';

// Icons
import Icon, { CloseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

interface Props {
  dynamicField: DynamicField;
  isLocked: boolean;
  isPreviewing: boolean;
  onScore(dynamicField: DynamicField): void;
};

class Scoring extends Component<Props> {

  renderSelect = (dynamicField: DynamicField, onScore: (dynamicField: DynamicField) => void, isLocked: boolean) => {
    return (
      <Select
        allowClear
        style={{ width: '100%' }}
        disabled={ isLocked }
        className="Select-Field mT-10"
        onChange={ (id: number | string | undefined) => {
          onScore(_.set(_.cloneDeep(dynamicField), ['scoring', 'value'], id ? id : null));
        } }
        value={ _.has(dynamicField, 'scoring.value') && dynamicField.scoring.value ? dynamicField.scoring.value : undefined }
        placeholder={ 'Please select' }
      >
        { dynamicField?.scoring?.list.options?.map((option: DynamicFieldScoringOption, index: number) => {
          return (
            <Option
              key={ index }
              value={ option.id }
            >
              <span
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 2,
                  paddingBottom: 2,
                  borderRadius: 5,
                  backgroundColor: option?.color || '#b9c2d0'
                }}
              />
              <span className="mL-10">{ option.option }</span>
            </Option>
          );
        }) }
      </Select>
    );
  };

  renderSlider = (dynamicField: DynamicField, onScore: (dynamicField: DynamicField) => void, isLocked: boolean) => {

    const marks: any = {};

    dynamicField?.scoring?.list?.options.forEach((option: DynamicFieldScoringOption) => {

      const iconComponent: any = option?.icon?.file && getIconComponent(option?.icon?.file);

      marks[option.value] = {
        'label': iconComponent ? (
          <Icon style={{ fontSize: 25 }} component={ iconComponent } />
        ) : (
          <span>
            { _.truncate(option.title, {
              'length': 30,
              'separator': /,? +/
            }) }
          </span>
        )
      };
    });

    return (
      <div className="pX-30 pY-20">
        <Slider
          handleStyle={ dynamicField?.scoring?.value === null ? { display: 'none' } : {} }
          min={ dynamicField?.scoring?.list?.min }
          max={ dynamicField?.scoring?.list?.max }
          step={ parseFloat(dynamicField?.scoring?.list?.step) }
          value={ dynamicField?.scoring?.value ? parseFloat(`${dynamicField?.scoring?.value}`) : undefined }
          marks={ marks }
          onAfterChange={ (value: any) => onScore(_.set(_.cloneDeep(dynamicField), ['scoring', 'value'], parseFloat(value))) }
        />
      </div>
    );
  };

  renderComponent = (type: string, dynamicField: DynamicField, onScore: (dynamicField: DynamicField) => void, isLocked: boolean) => {
    switch (type) {
      case 'SLIDER':
        return this.renderSlider(dynamicField, onScore, isLocked);
      case 'SELECT':
        return this.renderSelect(dynamicField, onScore, isLocked);
    }
  };

  render = () => {
    const { dynamicField, onScore, isLocked } = this.props;
    return (
      <>
        <div className="d-f jc-sb ai-c mB-20 mT-5">
          <label className="fw-500">
            Assessment
          </label>
          { dynamicField?.scoring?.mode === 'SLIDER' && _.has(dynamicField, 'scoring.value') && dynamicField.scoring.value !== null &&
            <span>
              <CloseCircleOutlined
                className='cur-p'
                onClick={ () => onScore(_.set(_.cloneDeep(dynamicField), ['scoring', 'value'], null)) }
                height={ 20 }
                width={ 20 }
              />
            </span>
          }
        </div>
        <div>
          { this.renderComponent(dynamicField?.scoring?.mode, dynamicField, onScore, isLocked) }
        </div>
      </>
    );
  };
};

export default Scoring;