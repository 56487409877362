// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { has } from 'lodash';

// Services
import { Api } from 'services/api';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Views
import MaintenancePlanTypeRecord from 'views/workplace-service/maintenance-plan/MaintenancePlanTypeRecord';
import MaintenancePlanTypeAudit from 'views/workplace-service/maintenance-plan/MaintenancePlanTypeAudit';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { RecordFormEntity } from 'types/entities';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbs,
  setBreadcrumbsLoading,
} from 'store/UI/ActionCreators';

// Utils
import Console from 'utils/console';

interface Props {
  client_id: number;
  permissions: any;
  match: { params: Record<string, any> };
  location: {
    pathname: string;
  };
  routes: any[];
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  record: RecordFormEntity | null;
  isFetching: boolean;
};

const API: Api = new Api();

class AssetTypeContainer extends React.Component<Props, State> {

  mounted: boolean = false;
  state: State = {
    record: null,
    isFetching: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.instantiate();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.params.type_id !== this.props.match.params.type_id) {
      this.instantiate();
    }
  };

  componentWillUnmount = () => {
    this.props.setSecondarySidebarRoutes([]);
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  instantiate = async () => {
    this.props.setSecondarySidebarRoutes([]);

    const maintenance_plan_id = this.props.match.params.maintenance_plan_id;
    const category_id = this.props.match.params.category_id;
    const type_id = this.props.match.params.type_id;

    try {
      if (this.props.client_id && maintenance_plan_id && category_id && type_id) {
        await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));
        this.props.setBreadcrumbsLoading(true);

        const record = await API.get(`client/${this.props.client_id}/category/asset-type/${type_id}`);
        this.setRecord(record);
      }
    } catch (error) {
      Console.error(error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.setState({
        isFetching: false
      });
    }
  };

  setRecord = (record: RecordFormEntity) => {
    const showLoadingState = this.state.record?.title !== record.title;

    if (showLoadingState) {
      this.props.setBreadcrumbsLoading(true);
    }

    if (has(record, 'breadcrumbs')) {
      this.props.setBreadcrumbs(record.breadcrumbs, false);
    }

    if (has(record, 'permissions')) {
      const { permissions } = record;

      this.props.setSecondarySidebarRoutes([
        { title: 'Asset Type', path: 'record', isDisabled: !hasPermission(permissions, 'category_asset_type_view') },
        { title: 'Audit', path: 'audit', isIndented: true, isDisabled: !hasPermission(permissions, 'category_asset_type_audit_view') },
      ]);
    }

    this.setState({
      record: record,
      isFetching: false
    });

    if (showLoadingState) {
      this.props.setBreadcrumbsLoading(false);
    }
  };

  render = () => {
    const { isFetching, record } = this.state;
    const permissions = record && has(record, 'permissions') ? record.permissions : this.props.permissions;

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Switch>

          <Redirect exact from="/workplace-services/maintenance-plan/:maintenance_plan_id/asset-category/:category_id/asset-type/:type_id" to="/workplace-services/maintenance-plan/:maintenance_plan_id/asset-category/:category_id/asset-type/:type_id/record" />

          { record &&
            <Route exact path="/workplace-services/maintenance-plan/:maintenance_plan_id/asset-category/:category_id/asset-type/:type_id/record" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, `category_asset_type_view`) && 'No access' }>
                  <MaintenancePlanTypeRecord record={ record } setRecord={ this.setRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/workplace-services/maintenance-plan/:maintenance_plan_id/asset-category/:category_id/asset-type/:type_id/audit" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'category_asset_type_audit_view') }>
                  <MaintenancePlanTypeAudit record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          <Route render={ () => <NotFound /> } />

        </Switch>
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    routes: store.UIState.secondarySidebarRoutes
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetTypeContainer);
