// Libs
import * as React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { Form, Tooltip } from 'antd';

// Components
import FieldLoadingSpinner from 'components/form/field/field-loading-spinner';
import { getColSpanClass } from 'components/form/form-wrapper';
import { ReactComponent as WarningIcon } from 'assets/svg/warning-triangle.svg';

// Icons
import { QuestionCircleOutlined, InfoCircleOutlined, LoadingOutlined, PartitionOutlined } from '@ant-design/icons';

// Styles
import './FieldWrapper.scss';

export interface Action {
  node: React.ReactElement;
  isLoading?: boolean;
  isDisabled?: boolean | string[];
};

interface Props {
  id?: string;
  col?: number;
  hasErrors?: boolean;
  errors?: string[];
  hideErrorInfo?: boolean;
  isLoading?: boolean;
  isModified?: boolean;
  label?: string | React.ReactNode;
  required?: boolean;
  border?: boolean;
  refreshOnChange?: boolean;
  versionChanged?: boolean;
  description?: string | boolean;
  style?: React.CSSProperties;
  leftActions?: Action[];
  rightActions?: Action[];
};

const Wrapper: React.FC<Props> = ({
  id,
  col = 12,
  children,
  label = '',
  isLoading,
  isModified,
  errors = [],
  hasErrors = false,
  hideErrorInfo,
  required,
  border,
  description,
  style = { minHeight: 100 },
  leftActions = [],
  rightActions,
  refreshOnChange,
  versionChanged,
}) => {
  const columnClassName = getColSpanClass(col);

  if (refreshOnChange) {
    leftActions.push({
      node: (
        <Tooltip
          className="mL-5"
          placement="top"
          title={ 'Changing the value in the field will make updates in the wider form' }
        >
          <PartitionOutlined className="fsz-def text-ant-default" />
        </Tooltip>
      )
    });
  }

  if (versionChanged) {
    leftActions.push({
      node: (
        <Tooltip
          className="mL-5"
          placement="top"
          title={ 'This value has been modified since the previous version' }
        >
          <InfoCircleOutlined className="fsz-def text-warning" />
        </Tooltip>
      )
    });
  }

  return (
    <Form.Item
      className={ `w-100p ${columnClassName}` }
    >
      <div
        id={ id }
        style={ style }
        className={ classNames({
          'bd p-15 bg-grey-100': border,
          'border-warning': versionChanged
        }) }
      >
        <div className="d-f jc-sb mB-5">
          <div className="d-if ai-c">
            { label &&
              <label
                className={ classNames('d-f fw-500', {
                  'text-danger': !isEmpty(errors) || hasErrors,
                  'text-warning': isModified && isEmpty(errors),
                }) }
              >
                { label }
                { required && <span className="text-required mL-2 fsz-md lh-1 va-t">*</span> }
              </label>
            }

            { !!description &&
              <Tooltip
                className="d-if ai-c mL-5"
                placement="top"
                title={ description }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }

            { !isEmpty(errors) && !hideErrorInfo &&
              <Tooltip
                overlayClassName="text-white Field-Error"
                placement="top"
                title={ errors.join(', ') }
              >
                <WarningIcon className="text-danger mL-5" height={ 20 } width={ 20 } />
              </Tooltip>
            }

            { !!leftActions && leftActions.map((action: Action, index: number) => (
              <span key={ index } className="d-if ai-c">
                { action.node }
              </span>
            ) ) }
          </div>
          <div className="d-if ai-c">
            { !!rightActions && rightActions.map((action: Action, index: number) => (
              <span key={ index } className="d-if ai-c">
                { !!action.isLoading ? (
                  <LoadingOutlined className="fsz-def text-ant-default" />
                ) : (
                  action.node
                ) }
              </span>
            ) ) }
          </div>
        </div>
        <div className="d-f as-c">
          <div className="pos-r w-100p">
            { children }
            { isLoading &&
              <div className="pos-a t-50p r-5">
                <FieldLoadingSpinner isLoading={ !!isLoading } />
              </div>
            }
          </div>
        </div>
      </div>
    </Form.Item>
  );
};

export default Wrapper;
