// Libs
import React, { BaseSyntheticEvent } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

// Components
import CoverModal from 'components/cover-modal';
import { Button, Input, Tooltip, InputNumber } from 'antd';
import { MentionsInput, Mention } from 'react-mentions';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Utils
import { flattenSet, modifyNestedSetItem } from 'utils/utils';

// Interfaces
import { IInsight, IColumn, IFormula, IModuleJoin, ColumnType } from 'components/insight/Insight.interfaces';

// Styles
import './AddFormula.scss';

// Icons
import { QuestionCircleOutlined } from '@ant-design/icons';

const API: Api = new Api();

interface Props {
  insight: IInsight;
  clientId: number;
  column?: IColumn;
  onSave(columns: IColumn[]): void;
  onClose(): void;
};

interface State {
  columns: IColumn[];
  formula: IFormula;
  isFetching: boolean;
};

const getBlankFormulaState = (): IFormula => {
  return {
    label: undefined,
    tooltip: undefined,
    width: undefined,
    formula: undefined
  };
};

const allowedSystemKeys = ['Tab', 'CapsLock', 'Backspace', 'Enter', 'Shift', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'];

class AddFormula extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    columns: [],
    formula: this.props.column?.settings as IFormula || getBlankFormulaState(),
    isFetching: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.fetchColumns();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchColumns = async () => {
    const { clientId, insight } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      let moduleIds: string[] = flattenSet(insight.module_joins).map((join: IModuleJoin) => join.target_module_id);

      if (!!insight.module_id) {
        moduleIds = moduleIds.concat([insight.module_id]);
      }

      const columns = await API.get(`client/${clientId}/insight/builder/columns`, {
        module_ids: moduleIds
      });

      this.mounted && this.setState({
        columns: columns
      });
    } catch (error) {
      Notification('error', 'Failed to fetch columns', 'Failed');
    } finally {
      this.mounted && this.setState({ isFetching: false });
    }
  };

  getFormulaRecord = (insight: IInsight): any => {
    const { formula } = this.state;
    const formulaId = uuidv4();

    return {
      id: `formula_${formulaId}`,
      module_id: !!insight.module_id ? insight.module_id: '',
      module_title: !!insight.module_id ? insight.module_id : '',
      column: null,
      column_type: ColumnType.Formula,
      title: !!formula.label ? formula.label: '',
      settings: !!formula ? formula : '',
      tooltip: formula.tooltip,
    };
  };

  handleChange = (formula: IFormula) => {
    this.setState({
      formula: formula
    });
  };

  handleSave = (insight: IInsight) => {
    const editColumn = this.props.column?.id ? insight.columns.find(column => column.id === this.props.column?.id) : undefined;
    const columns = editColumn ? modifyNestedSetItem(editColumn.id, {...editColumn, settings: _.cloneDeep(this.state.formula)}, _.cloneDeep(insight.columns)) : [...insight.columns, this.getFormulaRecord(insight)];
    this.props.onSave(columns);
  };

  getMentionColumns = (columns: IColumn[]): any => {
    return columns.map((column: IColumn) => {
      return ({
        id: column.id,
        display: column.title
      });
    });
  };

  validateFormula = (formula: string): boolean => {
    // Allow only math operators, '(', ')' and the mention trigger character'@'
    return allowedSystemKeys.includes(formula) || /^[0-9()+\.\-*\/\@\s]+$/.test(formula);
  };

  formatFormulaValue = (formula: string): string => {
    // Add empty space at the beginning of mention trigger symbol
    return /^.*[\@]+$/.test(formula) ? formula.replace(new RegExp('@$'), ' @') : formula;
  };

  render = () => {
    const { insight, onClose } = this.props;
    const { columns, formula, isFetching } = this.state;

    return (
      <CoverModal
        showCloseIcon={ false }
        isLoading={ isFetching }
        style={{ width: 750, minHeight: 500, maxHeight: 700 }}
        middleContent={ 'Formula' }
        onClose={ onClose }
      >
        <div className="pX-20 pB-20 w-70p d-f fxd-c h-100p" style={{ margin: '0 auto' }}>
          <div className='d-f fxd-c fxg-1 jc-c'>
            <div className='d-f jc-sb mT-20'>
              <span className="d-f ai-c">
                <span className="fw-600">Label</span><span className="text-required mL-2 fsz-md va-t">*</span>
                <span>
                  <Tooltip
                    className="mL-5"
                    placement="top"
                    title="Formula Label"
                  >
                    <QuestionCircleOutlined className="cur-p fsz-def text-ant-default va-m" />
                  </Tooltip>
                </span>
              </span>
              <span className="d-f ai-c">
                <Input
                  style={{ width: 300 }}
                  value={ formula.label }
                  onChange={ (event: BaseSyntheticEvent | null) => {
                    this.handleChange(_.set(_.cloneDeep(formula), 'label', event?.target.value));
                  } }
                />
              </span>
            </div>
            <div className='d-f jc-sb mT-20'>
              <span className="d-f ai-c">
                <span className="fw-600">Tooltip</span>
                <span>
                  <Tooltip
                    className="mL-5"
                    placement="top"
                    title="Formula Tooltip"
                  >
                    <QuestionCircleOutlined className="cur-p fsz-def text-ant-default va-m" />
                  </Tooltip>
                </span>
              </span>
              <span
                className="d-f ai-c"
              >
                <Input
                  style={{ width: 300 }}
                  value={ formula.tooltip }
                  onChange={ (event: BaseSyntheticEvent | null) => {
                    this.handleChange(_.set(_.cloneDeep(formula), 'tooltip', event?.target.value));
                  } }
                />
              </span>
            </div>
            <div className='d-f jc-sb mT-20'>
              <span className="d-f ai-c">
                <span className="fw-600">Width</span>
                <span>
                  <Tooltip
                    className="mL-5"
                    placement="top"
                    title="Formula Width"
                  >
                    <QuestionCircleOutlined className="cur-p fsz-def text-ant-default va-m" />
                  </Tooltip>
                </span>
              </span>
              <span className="d-f ai-c">
                <InputNumber
                  style={{ width: 300 }}
                  value={ formula.width }
                  addonAfter={ 'px' }
                  onChange={ (value: number | string | null) => {
                    this.handleChange(_.set(_.cloneDeep(formula), 'width', value));
                  } }
                />
              </span>
            </div>
            <div className='d-f jc-sb mT-20'>
              <span className="d-f ai-c">
                <span className="fw-600">Formula</span><span className="text-required mL-2 fsz-md va-t">*</span>
                <span>
                  <Tooltip
                    className="mL-5"
                    placement="top"
                    title="Formula"
                  >
                    <QuestionCircleOutlined className="cur-p fsz-def text-ant-default va-m" />
                  </Tooltip>
                </span>
              </span>
              <span className="d-b">
                <div className="InsightFormula">
                  <MentionsInput
                    value={ !!formula.formula ? formula.formula : '' }
                    onKeyDown={ (event: any) => {
                      if (!this.validateFormula(event.key)) {
                        event.preventDefault();
                      }
                    } }
                    onChange={ (event: any) => {
                      this.handleChange(_.set(_.cloneDeep(formula), 'formula', this.formatFormulaValue(event.target.value)));
                    } }
                    style={{ width: 300, minHeight: 80 }}
                    className="mentions"
                    placeholder={ "Select column to calculate using '@'" }
                    a11ySuggestionsListLabel={ 'Suggested columns' }
                  >
                    <Mention
                      appendSpaceOnAdd
                      trigger="@"
                      className="mentions__mention"
                      data={ this.getMentionColumns(columns) }
                    />
                  </MentionsInput>
                  <p className="d-b">(Allowed symbols: <b>@()/.+-*1234567890</b>)</p>
                </div>
              </span>
            </div>
          </div>
          <div className='mB-40 mT-50 ta-r'>
            <Button
              onClick={ () => this.props.onClose() }
            >
              Cancel
            </Button>
            <Button
              type='primary'
              className="mL-5"
              disabled={ _.isEmpty(formula.label?.trim()) || _.isEmpty(formula.formula?.trim()) }
              onClick={ () => this.handleSave(insight) }
            >
              Add
            </Button>
          </div>
        </div>
      </CoverModal>
    );
  };
};

export default AddFormula;
