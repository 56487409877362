// Libs
import * as React from "react";
import { connect } from "react-redux";
import _ from 'lodash';

// Components
import BlockingSpinner from "components/blocking-spinner";
import Jumbotron from "components/jumbotron";
import { hasPermission } from 'components/restriction';
import CreateRecordView from 'views/common/CreateRecordView';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import BasicList, { Config } from "components/basic-list";
import CalendarView from 'components/calendar-view';

// Services
import { Api } from "services/api";

// Actions
import { setIsBlocked, setBreadcrumbs } from 'store/UI/ActionCreators';

// Interfaces
import AppState from "store/AppState.interface";
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

const API: Api = new Api();

interface Props {
  client_id: number;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setIsBlocked(value: boolean): void;
};

interface State {
  columns: any;
  items: any;
  config: Config | null;
  isFetching: boolean;
  isCreateLoading: boolean;
  createType: string | null;
  activeTabKey: string;
  calendar: any;
};

class HelpdeskTickets extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    columns: [],
    items: [],
    config: null,
    isFetching: false,
    isCreateLoading: false,
    createType: null,
    activeTabKey: 'list_view',
    calendar: {},
  };

  componentDidMount = async () => {
    const { client_id } = this.props;

    this.mounted = true;

    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Workplace Services', path: '/workplace-services' },
      { title: 'Help Desk Tickets', path: '/workplace-services/help-desk-tickets' },
    ], false);

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const record = await API.get(`client/${client_id}/help-desk-ticket`);

      this.mounted && this.setState({
        columns: record.columns,
        items: record.data,
        config: record.config,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  handleTabChange = (tabKey: string) => {
    this.mounted && this.setState({ activeTabKey: tabKey });
  };

  renderListView = () => {
    return <BasicList config={ this.state.config } columns={ this.state.columns } items={ this.state.items } exportTitle={ 'Help Desk Tickets' } />;
  };

  renderCalendarView = () => {
    return (
      <CalendarView
        client_id={ this.props.client_id }
        customEndpoint={ 'help-desk-ticket' }
      />
    );
  };

  render = () => {
    const { permissions } = this.props;
    const { createType, isFetching, isCreateLoading, activeTabKey } = this.state;
    const actions: DropdownAction[] = [{ node: '', onClick: () => {} }];
    const tabs = [
      {
        label: 'List View',
        node: this.renderListView(),
      },
      {
        label: 'Calendar View',
        // The condition is necessary for the Calendar View tab to reload on every click.
        node: activeTabKey === 'calendar_view' ? this.renderCalendarView() : null,
      }
    ];

    if (hasPermission(permissions, 'help_desk_ticket_planned_ticket_create')) {
      actions.push({
        node: 'Create Planned Ticket',
        onClick: () => this.setState({ isCreateLoading: true, createType: 'planned-ticket' }),
        isLoading: isCreateLoading
      });
    }

    if (hasPermission(permissions, 'help_desk_ticket_statutory_ticket_create')) {
      actions.push({
        node: 'Create Statutory Ticket',
        onClick: () => this.setState({ isCreateLoading: true, createType: 'statutory-ticket' }),
        isLoading: isCreateLoading
      });
    }

    if (hasPermission(permissions, 'help_desk_ticket_reactive_ticket_create')) {
      actions.push({
        node: 'Create Reactive Ticket',
        onClick: () => this.setState({ isCreateLoading: true, createType: 'reactive-ticket' }),
        isLoading: isCreateLoading
      });
    }

    if (hasPermission(permissions, 'help_desk_ticket_scheduled_ticket_create')) {
      actions.push({
        node: 'Create Scheduled Ticket',
        onClick: () => this.setState({ isCreateLoading: true, createType: 'scheduled-ticket' }),
        isLoading: isCreateLoading
      });
    }

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ 'Help Desk Tickets' }
          tabs={ tabs }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
          onChange={ (tab: string) => this.handleTabChange(tab) }
        />
        { createType &&
          <CreateRecordView
            type={ createType }
            entity={ 'help-desk-ticket' }
            onReady={ () => this.mounted && this.setState({ isCreateLoading: false }) }
            onClose={ () => this.mounted && this.setState({ createType: null }) }
          />
        }
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setIsBlocked: (value: boolean) => dispatch(setIsBlocked(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskTickets);
