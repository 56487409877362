// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Select } from 'antd';

// Interfaces
import {
  FormField,
  FormValues,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
} from 'components/form/form-wrapper';

// Utils
import { isBlank } from 'utils/utils';

interface Props {
  numberFormat: any;
  field: FormField;
  originalState: FormValues;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
    callback?: () => void,
  ): void;
  onRefreshForm(field_id: string): void;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: any): void;
  setFieldErrorMessage(id: string, message?: any): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  state: any;
};

const { Option } = Select;

class Frequency extends Component<Props, State> {

  state: State = {
    state: this.props.state
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { field, onChange, config } = this.props;
    if (!_.isEqual(prevProps.state, this.props.state)) {
      // Set state downwards
      this.setState({
        state: this.props.state
      });

      if (this.props.state.frequency !== prevProps.state.frequency) {
        const options = field.options || [];
        // Change the frequency value if not in the selected frequency option list
        if (!options.find((option: any) => option.id === String(this.props.state.value) && option.frequencyType.includes(this.props.state.frequency))) {
          onChange(field, undefined, config, 'value');
        }
      }
    } else if (!_.isEqual(prevState.state, this.state.state)) {
      // Roll state upwards
      this.handleChange(this.state.state);
    }

    if (!_.isEqual(prevProps.field, this.props.field)) {
      this.validate(this.props.state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { field, originalState } = this.props;
    const columnKeys = Object.keys(field.columns);

    columnKeys.forEach((columnKey: string) => {
      this.generateModifiedState(originalState[columnKey], state[columnKey], columnKey, shouldClear);
      this.generateErrorState(state[columnKey], columnKey, shouldClear);
    });
  };

  handleChange = _.debounce((state: any) => {
    this.props.onChange(this.props.field, state.value, this.props.config, 'value');
  }, 500);

  generateModifiedState = (pastValue: string | number, newValue: string | number, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {

      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { setFieldErrorMessage, field, config, validate } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    let errors = validate(field, columnKey, value);

    // Validate frequency unit/frequency value dependency
    const dependenciesError = this.validateFrequencyDependency(columnKey);
    errors = [...errors, ...dependenciesError];

    if (!_.isEmpty(errors) && !shouldClear) {
      const message = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  validateFrequencyDependency = (columnKey: string): string[] => {
    const errors: string[] = [];

    const { state } = this.props;

    const frequencyUnit: string | undefined = state?.frequency;
    const frequencyValue: number | undefined = state?.value;

    if (columnKey === 'frequency' && _.isEmpty(frequencyUnit) && !isBlank(frequencyValue)) {
      errors.push('Frequency Unit is required');
    }

    if (columnKey === 'value' && !_.isEmpty(frequencyUnit) && isBlank(frequencyValue)) {
      errors.push('Frequency Value is required');
    }

    return errors;
  };

  render = () => {
    const { field, config, border, fieldErrorMessages, fieldModifiedMessages, onChange, isDisabled, numberFormat } = this.props;
    const { state } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const frequencyOptions = field.frequency_options || [];
    const options = field.options || [];
    const currentFrequency = state.frequency || undefined;
    const currentValue = state.value || undefined;

    const fieldValueErrors = _.has(fieldErrorMessages, `${id}_${cardinality}_value`) ? fieldErrorMessages[`${id}_${cardinality}_value`].errors : null;
    const fieldFrequencyErrors = _.has(fieldErrorMessages, `${id}_${cardinality}_frequency`) ? fieldErrorMessages[`${id}_${cardinality}_frequency`].errors : null;
    const fieldValueModified = _.has(fieldModifiedMessages, `${id}_${cardinality}_value`);
    const fieldFrequencyModified = _.has(fieldModifiedMessages, `${id}_${cardinality}_frequency`);

    if (!!isDisabled) {
      return (
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          border={ border }
          versionChanged={ !!field.config.version_changed }
          description={ !!field.description && field.description }
        >
          {
            _.has(state, 'value') && _.has(state, 'frequency') &&
            <span className="d-b pY-5">{ `Every ${state.value} ${state.frequency}(s)` }</span>
          }
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        required={ field.config.required }
        errors={ fieldFrequencyErrors || fieldValueErrors }
        border={ border }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <div className="d-f w-100p">
          <div className="w-50p">
            <Select
              allowClear
              className={ classNames('Select-Field', {
                'Select-Field--has-warning border-warning': fieldFrequencyModified,
                'Select-Field--has-error border-danger': !!fieldFrequencyErrors,
              }) }
              onChange={ (frequency: string) => {
                onChange(field, frequency, config, 'frequency');
              } }
              value={ currentFrequency }
              placeholder={ 'Unit' }
              disabled={ isDisabled }
            >
              { frequencyOptions.map((option: any) => {
                return (
                  <Option
                    key={ `${field.id}-list-frequency-option-${option.id}` }
                    value={ option.title }
                  >
                    { option.title }
                  </Option>
                );
              }) }
            </Select>
          </div>
          <div className="w-50p mL-10">
            <Select
              allowClear
              className={ classNames('Select-Field', {
                'Select-Field--has-warning border-warning': fieldValueModified,
                'Select-Field--has-error border-danger': !!fieldValueErrors,
              }) }
              onChange={ (value: string) => onChange(field, value, config, 'value') }
              value={ currentValue }
              placeholder={ 'Value' }
              disabled={ isDisabled }
            >
              { options
                .filter((option: any) => !!option.frequencyType && option.frequencyType.includes(currentFrequency))
                .map((option: any) => {
                  return (
                    <Option
                      key={ `${field.id}-list-frequency-option-${option.title}` }
                      value={ option.title }
                    >
                      { option.title }
                    </Option>
                  );
                })
              }
            </Select>
          </div>
        </div>
      </FieldWrapper>
    );
  };
};

export default Frequency;
