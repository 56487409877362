// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Upload as AntUpload, Modal } from 'antd';
import Base64Image from 'components/base64-image';
import Notification from 'services/notification';

// Icons
import { FileUnknownOutlined, FilePdfOutlined, FileExcelOutlined, UploadOutlined, LoadingOutlined } from "@ant-design/icons";

// Services
import { Api } from 'services/api';

// Styles
import './Upload.scss';

const API: Api = new Api();

interface Props {
  clientId: number;
  classes?: string;
  uploadEndpoint: string;
  allowedExtensions: string | false;
  onUpload: (file: any, callback?: () => void) => void;
  onRemove: (file: any) => void;
  onPreview: (file: any) => void;
  isDisabled: boolean;
  isLocked?: boolean;
  multiple?: boolean;
  files: any[];
  maxFileSize: number;
  showAllowedTypes?: boolean;
};

interface State {
  temporaryDeleteFile: any;
  showDeleteConfirm: boolean;
  isUploading: boolean;
  isDownloading: boolean;
};

class UploadComponent extends React.Component<Props, State> {

  state: State = {
    temporaryDeleteFile: null,
    showDeleteConfirm: false,
    isUploading: false,
    isDownloading: false,
  };

  handleDownload = async (file: any, clientId: number) => {
    try {

      if (!file) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isDownloading: true }, () => resolve(null) ));

      await API.download(`client/${clientId}/media/${file?.file_path}`, file?.name);

    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isDownloading: false
      });
    }
  };

  handleUpload = async (options: any) => {
    const { uploadEndpoint, onUpload } = this.props;
    const { onSuccess, onError, file } = options;

    try {
      await new Promise((resolve) => this.setState({ isUploading: true }, () => resolve(null) ));

      if (!file) throw new Error('Failed');

      const form = new FormData();

      form.append('file', file);

      const fileRecord = await API.post(uploadEndpoint, form);

      await onUpload(fileRecord, () => {
        onSuccess();
      });

    } catch (error) {
      Notification('error', '', 'Failed to upload');
      onError({ error });
    } finally {
      this.setState({
        isUploading: false
      });
    }
  };

  renderDeleteConfirmation = (file: any)  =>{
    return (
      <Modal
        visible
        centered
        title={ 'Delete file' }
        okText={ 'Yes' }
        onOk={ () => {
          this.setState({
            showDeleteConfirm: false,
            temporaryDeleteFile: null,
          }, () => {
            this.props.onRemove(file);
          });
        } }
        onCancel={() => this.setState({
          showDeleteConfirm: false,
          temporaryDeleteFile: null,
        }) }
        okButtonProps={{
          danger: true
        }}
      >
        <p>Are you sure you want to delete this file?</p>
      </Modal>
    );
  };

  render = () => {
    const {
      clientId,
      classes = '',
      allowedExtensions,
      files,
      maxFileSize,
      isDisabled,
      showAllowedTypes,
      onPreview,
      multiple = false,
      isLocked = false,
    } = this.props;

    const {
      temporaryDeleteFile,
      showDeleteConfirm,
      isUploading,
      isDownloading,
    } = this.state;

    const fileList: any[] = files?.map((fileRecord: any) => {
      return {
        uid: fileRecord.file.id,
        name: fileRecord.file.title,
        file_path: fileRecord.file.file_path,
        extension: fileRecord.file.extension,
        is_image: fileRecord.file.is_image,
        thumbUrl: '#',
        status: 'done',
      };
    }) || [];

    // Render upload card
    if (isUploading) {
      fileList.push({
        uid: 'fake',
        percent: 50,
        status: 'uploading',
      });
    }

    return (
      <>
        <AntUpload
          accept={ allowedExtensions ? `.${allowedExtensions}` : undefined }
          multiple={ multiple }
          className={ classNames(`Upload ${classes}`, {
            'is-disabled': isDisabled
          }) }
          disabled={ isDisabled }
          customRequest={ this.handleUpload }
          listType="picture"
          fileList={ fileList }
          isImageUrl={ () => false }
          onPreview={ (file: any) => {
            if (file?.is_image) {
              onPreview(file);
            } else {
              this.handleDownload(file, clientId);
            }
          } }
          onRemove={ (file: any) => this.setState({
            showDeleteConfirm: true,
            temporaryDeleteFile: file,
          }) }
          beforeUpload={ (file: any) => {
            const fileSizeInMB = file.size / 1024 / 1024;

            if (fileSizeInMB > maxFileSize) {
              Notification('error', '', `Files can't be greater than ${maxFileSize}MB`);
              return false;
            }

            return true;
          } }
          openFileDialogOnClick={ !isDisabled }
          iconRender={ (file: any) => {

            if (isDownloading) {
              return <LoadingOutlined />;
            }

            if (['done', 'removed'].includes(file.status)) {
              switch (file.extension) {
                case 'png':
                case 'jpeg':
                case 'jpg':
                  return <Base64Image client_id={ clientId } image_id={ file.file_path } styles={{ maxHeight: '100%', width: 'auto' }} />;
                case 'pdf':
                  return <FilePdfOutlined />;
                case 'xlsx':
                  return <FileExcelOutlined />;
                default:
                  return <FileUnknownOutlined />;
              }
            }
            return <LoadingOutlined />;
          } }
        >
          { !isLocked &&
            <div
              className={ classNames('d-f ai-c jc-c bd border-antd bdrs-2 mT-10', {
                'cur-p': !isDisabled,
                'cur-na text-ant-disabled': isDisabled,
              }) }
              style={{ width: 110, height: 66, marginRight: 8 }}
            >
              <UploadOutlined className="mR-5" /> Upload
            </div>
          }
        </AntUpload>
        { !isDisabled && showAllowedTypes &&
          <p className="fsz-xs">(Allowed file types: <b>{allowedExtensions ? allowedExtensions : 'all' }</b>)</p>
        }
        { !isDisabled &&
          <p className="fsz-xs">(Max file size: <b>{ maxFileSize }MB</b>)</p>
        }
        { showDeleteConfirm &&
          this.renderDeleteConfirmation(temporaryDeleteFile)
        }
      </>
    );
  };
};

export default UploadComponent;
