// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Modal, Form, Select } from 'antd';

// Api
import { Api } from 'services/api';

interface Props {
  clientId: number;
  onCreate(covarage: { bundle: string | null, type: string | null }): void;
  onClose(): void;
};

interface State {
  covarage: {
    bundle: string | null;
    type: string | null;
  };
  availableBundles: any[];
  availableTypes: any[];
  isCreating: boolean;
  isLoadingBundles: boolean;
  isLoadingTypes: boolean;
};

const API: Api = new Api();
const { Option } = Select;

class CreateCoverageDialog extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    covarage: {
      bundle: null,
      type: null,
    },
    availableBundles: [],
    availableTypes: [],
    isCreating: false,
    isLoadingBundles: false,
    isLoadingTypes: false,
  };

  componentDidMount = async () => {
    const { clientId } = this.props;
    const { covarage } = this.state;
    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isLoadingBundles: true }, () => resolve(null) ));
      const bundles = await API.get(`client/${clientId}/available_bundles`);

      let bundle = null;

      if (bundles.length === 1) {
        bundle = bundles[0].type;
      }

      this.setState({
        availableBundles: bundles,
        covarage: {
          ...covarage,
          bundle: bundle
        }
      });
    } catch (error) {
        console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoadingBundles: false
      });
    }

  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render = () => {
    const { clientId, onCreate, onClose } = this.props;
    const {
      covarage,
      availableBundles,
      availableTypes,
      isLoadingBundles,
      isLoadingTypes,
      isCreating,
    } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Create Coverage' }
        maskClosable={ !isCreating }
        closable={ !isCreating }
        okButtonProps={{
          disabled: !covarage?.bundle || !covarage?.type,
          loading: isCreating
        }}
        cancelButtonProps={{
          disabled: isCreating,
        }}
        onOk={ () => this.setState({
            isCreating: true,
          }, () => {
            onCreate(covarage);
          }
        ) }
        onCancel={ () => onClose() }
      >
        <Form layout="vertical">
          <Form.Item label="Bundle" required>
            <Select
              showSearch
              className={ classNames('Select-Field') }
              value={ covarage?.bundle }
              loading={ isLoadingBundles }
              disabled={ isLoadingBundles }
              placeholder={ 'Select bundle'}
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              onChange={ async (bundle: string) => {
                try {
                  await new Promise((resolve) => this.setState({ isLoadingTypes: true }, () => resolve(null)));
                  await new Promise((resolve) => this.setState({
                    covarage: {
                      ...covarage,
                      bundle: bundle,
                    }
                  }, () => resolve(null)));

                  let type = null;

                  const types = await API.get(`client/${clientId}/available_types`, {
                    bundle: bundle,
                  });

                  if (types.length === 1) {
                    type = types[0].type;
                  }

                  this.mounted && this.setState({
                    availableTypes: types,
                    covarage: {
                      bundle: bundle,
                      type: type,
                    }
                  });
                } catch (error) {
                  console.error('Error: ', error);
                } finally {
                  this.mounted && this.setState({
                    isLoadingTypes: false
                  });
                }
              } }
            >
              { availableBundles.map( (bundle: { bundle: string }) => (
                <Option key={ bundle.bundle } value={ bundle.bundle }>{ _.startCase(_.toLower(bundle.bundle)).split('_').join(' ') }</Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item label="Record Type" required>
            <Select
              showSearch
              className={ classNames('Select-Field') }
              disabled={ !covarage?.bundle || isLoadingTypes }
              loading={ isLoadingTypes }
              value={ covarage?.type }
              placeholder={ 'Select Type'}
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              onChange={ (type: string) => this.setState({
                covarage: {
                  ...covarage,
                  type: type
                }
              }) }
            >
              { availableTypes.map( (type: any) => (
                <Option key={ type.type } value={ type.type }>{ type.label }</Option>
              )) }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
};

export default CreateCoverageDialog;
