// Libs
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Components
import BasicList, { Action } from 'components/basic-list';
import BlockingSpinner from 'components/blocking-spinner';
import Dropdown from 'components/dropdown';

// Views
import CreateRecordView from 'views/common/CreateRecordView';

// Services
import { Api } from 'services/api';

// Interfaces
import { RecordFormEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { AssetCategory, AssetType, Type, AssetMaintenanceElement } from './AssetMaintenance.interfaces';

const API: Api = new Api();

interface Props {
  record: RecordFormEntity;
  client_id?: number;
};

interface State {
  dataTree: AssetCategory[];
  isFetching: boolean,
  showCreateAssetTypeModal: boolean;
  showCreateAssetCategoryModal: boolean;
  isCreateLoading: boolean;
};

class AssetMaintenance extends React.Component<Props> {

  mounted: boolean = false;

  state: State = {
    dataTree: [],
    isFetching: false,
    showCreateAssetTypeModal: false,
    showCreateAssetCategoryModal: false,
    isCreateLoading: false,
  };

  componentDidMount = async () => {
    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const dataTree = await this.getDataTree();

      this.mounted && this.setState({
        dataTree: dataTree,
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  // The method returns data from the desired endpoint depending on the scope
  getDataTree = async (): Promise<AssetCategory[]> => {
    const { client_id, record } = this.props;

    let endpoint = `client/${client_id}/category/asset-maintenance-plan/${record.id}/asset-maintenance-tree`;

    if (this.isAdminArea()) {
      endpoint = `client/${client_id}/admin/content-manager/categories/asset-maintenance-plan/${record.id}/asset-maintenance-tree`;
    }

    const response = await API.get(endpoint);

    return response.data;
  };

  // The method determines in which area (user or admin) the component is displayed
  isAdminArea = (): boolean => {
    return !!this.props.record.path.includes('/admin/');
  };

  renderListView = () => {
    const { dataTree, isCreateLoading } = this.state;

    const columns = [
      {
        key: 'title',
        dataIndex: 'title',
        title: 'Asset Category / Type',
        render: (title: string, row: AssetType | AssetCategory) => {
          return (
            <Link className='primaryColor' to={ row.path }>
              { title }
            </Link>
          );
        },
        filterable: true,
        sorter: true,
        ellipsis: true,
      },
      {
        key: 'asset_type_id',
        dataIndex: 'asset_type_id',
        title: 'Asset Type ID',
        render: (__: any, row: AssetType | AssetCategory) => {
          return (
            <span>{ row.type === Type.AssetType ? row.asset_type_id : '-' }</span>
          );
        },
        filterable: false,
        sorter: false,
        ellipsis: true,
      },
      {
        key: 'service_category',
        dataIndex: 'service_category',
        title: 'Service Category',
        render: (__: any, row: AssetType | AssetCategory) => {
          return (
            <span>{ row.type === Type.AssetType ? row.service_category.target_title : '-' }</span>
          );
        },
        filterable: false,
        sorter: false,
        ellipsis: true,
      },
    ];

    const appendUniqueKey = (items: Array<AssetCategory | AssetType>): any[] => {
      return items.map((item, index) => {
        return {
          key: `${item.bundle}-${item.type}-${item.id}-${index}`,
          ...item,
          children: (item.type === Type.AssetCategory && !_.isEmpty(item.children)) ? appendUniqueKey(item.children) : undefined,
        };
      }).sort((elementA: AssetMaintenanceElement, elementB: AssetMaintenanceElement) => elementA.order - elementB.order);
    };

    const actions: Action[] = [
      {
        node: (
          <Dropdown
            actions={ [
              // First element is blank to ensure create new appears in action dropdown
              {
                node: '',
                onClick: () => {},
              },
              {
                node: 'Create Asset Category',
                onClick: () => this.mounted && this.setState({ isCreateLoading: true, showCreateAssetCategoryModal: true }),
                isLoading: isCreateLoading,
              },
              {
                node: 'Create Asset Type',
                onClick: () => this.mounted && this.setState({ isCreateLoading: true, showCreateAssetTypeModal: true }),
                isLoading: isCreateLoading,
              },
            ] }
          />
        ),
      },
    ];

    return (
      <div>
        <BasicList rawData columns={ columns } items={ appendUniqueKey(dataTree) } rightActions={ actions } />
      </div>
    );
  };

  render = () => {
    const { record } = this.props;
    const { isFetching, showCreateAssetCategoryModal, showCreateAssetTypeModal } = this.state;

    return (
      <BlockingSpinner isLoading={ isFetching } style={{ minHeight: 300 }}>
        { this.renderListView() }
        { showCreateAssetCategoryModal &&
          <CreateRecordView
            parent_id={ record.id }
            parent_bundle={ record.bundle }
            parent_type={ record.type }
            entity={ this.isAdminArea() ? 'admin/content-manager/categories' : 'category' }
            type={ _.kebabCase(Type.AssetCategory) }
            onReady={ () => this.mounted && this.setState({ isCreateLoading: false }) }
            onClose={ () => this.mounted && this.setState({ showCreateAssetCategoryModal: false }) }
          />
        }
        { showCreateAssetTypeModal &&
          <CreateRecordView
            parent_id={ record.id }
            parent_bundle={ record.bundle }
            parent_type={ record.type }
            entity={ this.isAdminArea() ? 'admin/content-manager/categories' : 'category' }
            type={ _.kebabCase(Type.AssetType) }
            onReady={ () => this.mounted && this.setState({ isCreateLoading: false }) }
            onClose={ () => this.mounted && this.setState({ showCreateAssetTypeModal: false }) }
          />
        }
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
  };
};

export default connect(mapStateToProps, null)(AssetMaintenance);