// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Table, Switch, Tag, Button, Dropdown, Menu, Tooltip, Checkbox } from 'antd';
import TimeRangePicker from 'components/timerangepicker';
import ActionWrapper from 'components/form/field/dynamic/common/ActionWrapper';
import { getActionControls } from 'components/form/field/dynamic/common/ActionControls';

// Icons
import { PlusOutlined } from "@ant-design/icons";

// Interfaces
import { DynamicField } from 'components/form/field/dynamic/Dynamic.interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface Props {
  clientId: number;
  dynamicField: DynamicField;
  originalDynamicField: DynamicField;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  isLocked: boolean;
  isPreviewing: boolean;
  extensions: string[];
  setFieldModifiedMessage(id: string, message?: any): void;
  setFieldErrorMessage(id: string, message?: any): void;
  onChange(field: DynamicField): void;
  onComment(comment: string | null): void;
  onUpload(fieldRecord: any, callback?: () => void): void;
  onRemove(field: DynamicField): void;
  onCreateAction(field: DynamicField): void;
  onScore(field: DynamicField): void;
};

interface State {
  showComment: boolean;
  showAttachments: boolean;
  showActions: boolean;
  showScoring: boolean;
};

class ShiftField extends Component<Props, State> {

  state: State = {
    showComment: !!this.props.dynamicField?.attachments?.comment,
    showAttachments: !!this.props.dynamicField?.attachments?.files.length,
    showActions: !!this.props.dynamicField?.action_list?.data.length,
    showScoring: !!this.props.dynamicField?.scoring?.value,
  };

  componentDidMount = () => {
    if (!this.props.isLocked) {
      this.validate(this.props.dynamicField);
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    const { dynamicField } = this.props;

    if (!_.isEqual(prevProps.dynamicField, dynamicField)) {
      this.validate(dynamicField);
    }
  };

  validate = (dynamicField: DynamicField) => {
    const { originalDynamicField } = this.props;

    this.generateModifiedState(originalDynamicField, dynamicField);
  };

  generateModifiedState = (pastField: DynamicField, newField: DynamicField) => {
    const { dynamicField, setFieldModifiedMessage } = this.props;

    const id = dynamicField.id;
    const ref = dynamicField.reference;
    const key = `${id}_${ref}`;

    if (!_.isEqual(pastField, newField)) {
      const message = {
        id: id,
        cardinality: 0,
        content: {
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  handleChange = (values: any) => {
    this.props.onChange(_.set(_.cloneDeep(this.props.dynamicField), ['values'], values));
  };

  handleFieldChange = (dynamicField: any) => {
    this.props.onChange(_.cloneDeep(dynamicField));
  };

  getWeekDay = (day: number): string => {
    const days: any = {
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      7: 'Sunday',
    };
    return days[day];
  };

  render = () => {
    const { clientId, dynamicField, fieldErrorMessages, fieldModifiedMessages, isLocked, isPreviewing, extensions, onComment, onUpload, onRemove, onCreateAction, onScore } = this.props;
    const { showComment, showAttachments, showActions, showScoring } = this.state;

    const key = `${dynamicField.id}_${dynamicField.reference}`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);

    const data: any = [1, 2, 3, 4, 5, 6, 7].map((day: number) => {
      const _day = _.has(dynamicField, 'values') && dynamicField.values.find((_day: any) => _day.day === day);
      if (!!_day) {
        return {
          key: _day.day,
          ..._day,
          disabled: false,
        };
      } else {
        return {
          key: day,
          ...{
            day: day,
            shift: 1,
            start: '09:00:00',
            end: '17:30:00',
            disabled: true,
          }
        };
      }
    });

    const columns = [
      {
        title: '',
        width: 150,
        render: (row: any) => {
          return <span>{ this.getWeekDay(row.day) }</span>;
        }
      },
      {
        title: '',
        width: 200,
        render: (row: any) => {
          return (
            <span>
              <Switch
                disabled={ isLocked }
                checked={ !row.disabled }
                onChange={ (checked: boolean) => {
                  delete row.key;
                  delete row.disabled;
                  if (!checked) {
                    this.handleChange(_.cloneDeep(dynamicField.values).filter((value: any) => value.day !== row.day));
                  } else {
                    this.handleChange(_.cloneDeep(dynamicField.values || []).concat({ ...row }));
                  }
                }}
              />
              <span className="mL-5">{ !row.disabled ? 'Open' : 'Closed' }</span>
            </span>
          );
        }
      },
      {
        title: '',
        render: (row: any) => {

          if (!!row.disabled) return <></>;

          const shifts = dynamicField.values
            .filter((day: any) => day.day === row.day)
            .map((day: any) => day);

          return (
            <>
              { shifts.map((shift: any) => (
                <Tag
                  className="p-5"
                  key={ `${shift.day}-${shift.shift}-${uuidv4()}` }
                  closable
                  onClose={ (e: any) => {
                    e.preventDefault();

                    const currentDay = dynamicField.values.filter((day: any) => day.day === shift.day);
                    const otherDays = dynamicField.values.filter((day: any) => day.day !== shift.day);
                    const mutatedCurrentDay = currentDay
                      .filter((day: any) => day.shift !== shift.shift)
                      .map((day: any, index: number) => {
                        return {
                          ...day,
                          shift: index + 1
                        };
                      });

                    this.handleChange(otherDays.concat(mutatedCurrentDay));
                  } }
                >
                  <TimeRangePicker
                    isDisabled={ isLocked }
                    onChangeTime={ (values: string[]) => {
                      const fieldIndex = dynamicField.values.findIndex((day: any) => day.day === shift.day && day.shift === shift.shift);
                      const manipulatedField = _.set(_.cloneDeep(dynamicField), `values.[${fieldIndex}]`, {
                        day: shift.day,
                        shift: shift.shift,
                        start: values[0],
                        end: values[1],
                      });
                      this.handleChange(manipulatedField.values);
                    } }
                    values={ [shift.start, shift.end] }
                  />
                </Tag>
              ) ) }
              <Button
                style={{
                  marginLeft: 5,
                  padding: 0,
                  width: '24px',
                  height: '24px',
                }}
                onClick={ () => {
                  const maxShift = Math.max(...dynamicField.values.filter((day: any) => day.day === row.day).map((day: any) => day.shift), 0);
                  const shifts = _.cloneDeep(dynamicField).values.concat([{
                    day: row.day,
                    shift: maxShift + 1,
                    start: '09:00:00',
                    end: '17:30:00',
                  }]);
                  this.handleChange(shifts);
                } }
              >
                <PlusOutlined className="link" />
              </Button>
            </>
          );
        }
      },
    ];

    let rightActions: any = [];

    if (_.has(dynamicField, 'presets') && !_.isEmpty(dynamicField.presets)) {
      rightActions.push({
        node: (
          <Dropdown
            trigger={ ['click'] }
            overlay={
              <Menu>
                { dynamicField.presets.map((preset: any) => (
                  <Menu.Item
                    key={ preset.reference }
                    onClick={ () => {
                      const newShifts = preset.preset
                        .filter((_preset: any) => !_preset.disabled)
                        .map((_preset: any) => {
                          return {
                            shift: 1,
                            day: _preset.day,
                            start: _preset.start,
                            end: _preset.end,
                          };
                        });
                      this.handleChange(newShifts);
                    } }
                  >
                    { preset.title }
                  </Menu.Item>
                ) ) }
              </Menu>
            }
          >
            <Button>Presets</Button>
          </Dropdown>
        )
      });
    }

    if (dynamicField.config?.can_mark_not_applicable) {
      rightActions.push({
        node: (
          <Tooltip
            placement="top"
            title={ 'Select N/A if the question is not Applicable' }
          >
            <span className="mL-10"> { 'N/A' } </span>
            <Checkbox
              className="mL-5"
              onChange={ (e: CheckboxChangeEvent) => {
                let _dynamicField = _.cloneDeep(dynamicField);
                _dynamicField.not_applicable = !!e.target.checked;
                this.handleFieldChange(_dynamicField);
              }}
              checked={ !!dynamicField?.not_applicable }
            />
        </Tooltip>
        )
      });
    }

    rightActions = rightActions.concat(getActionControls(dynamicField, this.state, (state: any) => this.setState(state), isPreviewing));

    return (
      <FieldWrapper
        description={ dynamicField.description }
        label={ dynamicField.label }
        required={ dynamicField.config.required || dynamicField.config?.required }
        versionChanged={ !!dynamicField.config.version_changed }
        rightActions={ rightActions }
        errors={ errors }
        isModified={ isModified }
        border
      >
        { !!dynamicField?.not_applicable ? (
            <div> Not Applicable </div>
          ) : (
            <div>
              <Table
                size={ 'small' }
                showHeader={ false }
                columns={ columns }
                dataSource={ data }
                pagination={ false }
              />
              <ActionWrapper
                clientId={ clientId }
                dynamicField={ dynamicField }
                isLocked={ isLocked }
                isPreviewing={ isPreviewing }
                showAttachments={ showAttachments }
                showComment={ showComment }
                showActions={ showActions }
                showScoring={ showScoring }
                hideScoring={ () => this.setState({ showScoring: false }) }
                extensions={ extensions }
                onComment={ onComment }
                onUpload={ onUpload }
                onRemove={ onRemove }
                onCreateAction={ onCreateAction }
                onScore={ onScore }
              />
          </div>
          )
        }
      </FieldWrapper>
    );
  };
};

export default ShiftField;
