// Libs
import React from 'react';

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import { RestrictionHoC } from 'components/restriction';
import { Table, Spin, Checkbox, TreeSelect } from 'antd';
import DragSortingList from 'components/drag-sorting-list';
import Dropdown from 'components/dropdown';

// Views
import FinanceTemplateColumnModal from 'views/admin/templates/finance/FinanceTemplateColumnModal';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Icons
import { EditOutlined, MenuOutlined } from '@ant-design/icons';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { FinanceTemplate as IFinanceTemplate, FinanceTemplateColumn as IFinanceTemplateColumn, FinanceTemplateType as IFinanceTemplateType } from 'views/admin/templates/Templates.interfaces';
import { TableType } from 'components/drag-sorting-list/DragSortingList.interfaces';

// Utils
import { arrayMoveImmutable } from 'utils/formSetup';
import { findAndModifyFirst, findFirst } from 'utils/utils';

const API: Api = new Api();
const { SHOW_PARENT } = TreeSelect;

interface Props {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  template: IFinanceTemplate | null,
  templateType: IFinanceTemplateType | null,
  activeColumn: IFinanceTemplateColumn | null;
  availableTemplateColumnTypes: string[] | null;
  isLoading: boolean;
  isAddingTemplate: boolean;
  isDeletingTemplate: boolean;
  isEditMode: boolean;
  showTemplateColumnModal: boolean;
  isReordering: boolean;
  isSavingCoas: boolean;
  coaFilter: any[],
  originalCoas: any[],
  manipulatedCoas: any[],
  showFilter: boolean;
};

class FinanceTemplate extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    template: null,
    templateType: null,
    activeColumn: null,
    availableTemplateColumnTypes: null,
    isLoading: false,
    isAddingTemplate: false,
    isDeletingTemplate: false,
    isEditMode: false,
    showTemplateColumnModal: false,
    isReordering: false,
    isSavingCoas: false,
    coaFilter: [],
    originalCoas: [],
    manipulatedCoas: [],
    showFilter: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const finance_template_type_id = this.props.match.params.finance_template_type_id;
    const finance_template_id = this.props.match.params.finance_template_id;

    this.mounted = true;

    try {

      this.props.setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const template = await API.get(`client/${client_id}/admin/templates/finance/types/${finance_template_type_id}/templates/${finance_template_id}/columns`);
      const templateCoas = await API.get(`client/${client_id}/admin/templates/finance/types/${finance_template_type_id}/templates/${finance_template_id}/coas`);
      const templateType = await API.get(`client/${client_id}/admin/templates/finance/types/${finance_template_type_id}`);
      const structuredCoas = this.structureData(templateCoas.coas);

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Template Types', path: '/admin/templates/finance' },
        { title: `${templateType.title} Templates`, path: `/admin/templates/finance/type/${templateType.id}` },
        { title: `${template.title} Template`, path: null },
      ], false);

      this.mounted && this.setState({
        template: template,
        templateType: templateType,
        originalCoas: structuredCoas,
        manipulatedCoas: structuredCoas,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getAvailableTemplateColumnTypes = async () => {
    const { client_id } = this.props;
    try {

      await new Promise((resolve) => this.setState({ isReordering: true }, () => resolve(null)));

      const availableTemplateColumnTypes = await API.get(`client/${client_id}/admin/templates/finance/available_template_column_types`);

      this.mounted && this.setState({
        availableTemplateColumnTypes: availableTemplateColumnTypes
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isReordering: false
      });
    }
  };

  structureData = (data: any) => {
    const recordDataMapping = (data: any[]) => {
      return data.map((entity: any) => {
        const appendChildrenKeys = (children: any) => {
          return children
            .map((childEntity: any) => {
              return {
                ...childEntity,
                'key': `${childEntity.type}-${childEntity.id}`,
                'value': `${childEntity.type}-${childEntity.id}`,
                'label': childEntity.title,
                'children': !_.isEmpty(childEntity.children) ? appendChildrenKeys(childEntity.children) : null,
              };
            });
        };
        return {
          ...entity,
          'key': `${entity.type}-${entity.id}`,
          'value': `${entity.type}-${entity.id}`,
          'label': entity.title,
          'children': !_.isEmpty(entity.children) ? appendChildrenKeys(entity.children) : null,
        };
      });
    };

    return recordDataMapping(data);
  };

  getFlatten = (data: any) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({ ..._value });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  selectDeselectCoas = (manipulatedCoas: any, filteredCoas: any, selected: boolean) => {

    const recordDataMapping = (_manipulatedCoas: any[], _filteredCoas: any[]) => {

      const filteredCoaIds = this.getFlatten(_filteredCoas).map((_coa: any) => _coa.id);

      return manipulatedCoas
        .map((coa: any) => {
          const appendChildrenKeys = (children: any) => {
            return children
              .map((childEntity: any) => {
                return {
                  ...childEntity,
                  'selected': filteredCoaIds.includes(coa.id) ? selected : coa.selected,
                  'children': !_.isEmpty(childEntity.children) ? appendChildrenKeys(childEntity.children) : null,
                };
              });
          };
          return {
            ...coa,
            'selected': filteredCoaIds.includes(coa.id) ? selected : coa.selected,
            'children': appendChildrenKeys(coa.children),
          };
      });
    };

    return recordDataMapping(manipulatedCoas, filteredCoas);
  };

  filterTree = (tree: any[], filters: number[]) => {
    const collector: any = [];

    tree.forEach((branch: any) => {

      const filter = (_branch: any, filters: any[]) => {

        if (filters.includes(`${_branch.type}-${_branch.id}`)) {
          collector.push({
            ..._branch,
          });
        }

        if (_.has(_branch, 'children') && !_.isEmpty(_branch.children)) {
          _branch.children.forEach((__branch: any) => {
            filter(__branch, filters);
          });
        }
      };

      return filter(branch, filters);
    });

    return collector;
  };

  onReorder = async (template: IFinanceTemplate, dragIndex: number, hoverIndex: number) => {
    const { client_id } = this.props;
    const finance_template_type_id = this.props.match.params.finance_template_type_id;

    if (dragIndex !== hoverIndex) {
      try {

        await new Promise((resolve) => this.setState({ isReordering: true }, () => resolve(null)));

        const reorderedColumns = arrayMoveImmutable<IFinanceTemplateColumn>(template.columns, dragIndex, hoverIndex).filter((element: any) => !!element);
        const newTemplate = await API.put(`client/${client_id}/admin/templates/finance/types/${finance_template_type_id}/templates/${template.id}/columns`, {
          data: {
            ...template,
            columns: reorderedColumns
          }
        });

        this.mounted && this.setState({
          template: newTemplate
        });

      } catch (error) {
        console.error('Error: ', error);
      } finally {
        this.mounted && this.setState({
          isReordering: false
        });
      }
    }
  };

  renderTemplateColumns = () => {
    const { client_id } = this.props;
    const { template, isLoading, isReordering, showTemplateColumnModal, activeColumn } = this.state;
    const finance_template_type_id = this.props.match.params.finance_template_type_id;

    if (!template) return <></>;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const columns = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        width: 80,
        render: () => <MenuOutlined className="EntityRolesTab-Table-DragMenu" />,
      },
      {
        key: 'label',
        dataIndex: 'label',
        title: 'Label',
        width: 250,
        ellipsis: true,
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: 'Description',
        ellipsis: true,
      },
      {
        key: 'type',
        dataIndex: 'type',
        title: 'Type',
        width: 200,
        ellipsis: true,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        render: (__: any, column: any) => {
          return (
            <EditOutlined
              className="link"
              style={{ fontSize: 18 }}
              onClick={ () => {
                this.setState({ showTemplateColumnModal: true, activeColumn: _.cloneDeep(column) });
              } }
            />
          );
        },
        width: 130,
        ellipsis: true,
        align: 'center' as 'center',
      },
    ];

    return (
      <>
        <Spin indicator={ <BlockingSpinner isLoading /> } spinning={ isReordering }>
          <DragSortingList
            columns={ columns }
            items={ template.columns.sort((a: IFinanceTemplateColumn, b: IFinanceTemplateColumn) => a.order - b.order) }
            isParent
            pagination={ false }
            config={{
              type: TableType.Tab,
              references: [],
            }}
            moveRow={ (dragIndex: number, hoverIndex: number) => this.onReorder(template, dragIndex, hoverIndex) }
          />
        </Spin>
        { showTemplateColumnModal && (
          <FinanceTemplateColumnModal
            client_id={ client_id }
            templateColumn={ activeColumn || undefined }
            onSave={ async (templateColumn: IFinanceTemplateColumn) => {
              try {

                const index = template.columns.findIndex((column: IFinanceTemplateColumn) => column.id === templateColumn.id);
                const newTemplate = await API.put(`client/${client_id}/admin/templates/finance/types/${finance_template_type_id}/templates/${template.id}/columns`, {
                  data: {
                    ...template,
                    columns: _.set(_.cloneDeep(template.columns), index, templateColumn)
                  }
                });

                this.mounted && this.setState({
                  template: newTemplate,
                });

              } catch (error) {
                console.error('Error: ', error);
              } finally {
                this.mounted && this.setState({
                  showTemplateColumnModal: false,
                  activeColumn: null
                });
              }
            } }
            onClose={ () => this.setState({
              showTemplateColumnModal: false,
              activeColumn: null
            }) }
          />
        ) }
      </>
    );
  };

  renderCoaList = () => {
    const { client_id } = this.props;
    const { originalCoas, manipulatedCoas, coaFilter, template, isSavingCoas } = this.state;
    const finance_template_type_id = this.props.match.params.finance_template_type_id;
    const finance_template_id = this.props.match.params.finance_template_id;

    let coas =  this.structureData(manipulatedCoas);

    if (!_.isEmpty(coaFilter)) {
      coas = this.filterTree(coas, coaFilter);
    }

    const columns: any = [
      {
        key: 'label',
        dataIndex: 'label',
        title: 'Service',
        fixed: 'left',
        ellipsis: true,
      },
      {
        key: 'in_use',
        dataIndex: 'in_use',
        title: 'In Use',
        fixed: 'left',
        render: (__: any, coa: any) => {
          return (
            <Checkbox
              checked={ coa.selected }
              onChange={ (event) => {
                const _manipulatedCoas = findAndModifyFirst({ children: _.cloneDeep(manipulatedCoas) }, 'children', { key: coa.key }, {
                  ...findFirst({ children: _.cloneDeep(manipulatedCoas) }, 'children', { key: coa.key }),
                  selected: event.target.checked
                });

                this.setState({
                  manipulatedCoas: _manipulatedCoas.children
                });
              } }
            />
          );
        }
      },
    ];

    return (
      <div className='Layout-box'>
        <div className='mT-15 mB-15'>
          <div className='d-f jc-sb'>
            <div>
              <TreeSelect
                style={{ width: 300 }}
                dropdownMatchSelectWidth={ false }
                placeholder={ 'Filter' }
                showCheckedStrategy={ SHOW_PARENT }
                maxTagCount={ 2 }
                treeCheckable
                multiple
                treeData={ originalCoas }
                filterTreeNode={ (input: string, option: any) => {
                  if (option) {
                    const filteredInput = input.toLocaleLowerCase();
                    const title = option.title && option.title.toLowerCase();

                    if (title.includes(filteredInput)) {
                      return true;
                    }
                  }

                  return false;
                } }
                onChange={ (coaFilter: any[]) => {
                  this.setState({
                    coaFilter: coaFilter
                  });
                } }
              />
            </div>
            <div>
              <Dropdown
                actions={[
                  {
                    isLoading: isSavingCoas,
                    disabled: _.isEqual(originalCoas, manipulatedCoas),
                    node: 'Save',
                    onClick: async () => {
                      try {

                        await new Promise((resolve) => this.setState({ isSavingCoas: true }, () => resolve(null)));
                        await API.put(`client/${client_id}/admin/templates/finance/types/${finance_template_type_id}/templates/${finance_template_id}/coas`, {
                          data: {
                            ...template,
                           coas: manipulatedCoas
                          }
                        });

                        Notification('success', 'Saved');

                      } catch (error) {
                        console.error('Error: ', error);
                        Notification('error', 'Failed to save', 'Failed');
                      } finally {
                        this.setState({
                          originalCoas: manipulatedCoas,
                          manipulatedCoas: manipulatedCoas,
                          isSavingCoas: false
                        });
                      }
                    }
                  },
                  {
                    node: 'Select all',
                    onClick: () => {
                      this.setState({
                        manipulatedCoas: this.selectDeselectCoas(manipulatedCoas, coas, true)
                      });
                    }
                  },
                  {
                    node: 'Deselect all',
                    onClick: () => {
                      this.setState({
                        manipulatedCoas: this.selectDeselectCoas(manipulatedCoas, coas, false)
                      });
                    }
                  }
                ]}
              />
            </div>
          </div>
        </div>
        <Table
          size={ 'small' }
          columns={ columns }
          dataSource={ coas }
          pagination={ false }
          expandable={{
            defaultExpandAllRows: true
          }}
        />
      </div>
    );
  };

  render = () => {
    const { isLoading, template } = this.state;

    if (!template) return <></>;

    return (
      <BlockingSpinner isLoading={ isLoading }>
        <Jumbotron
          content={ `${template.title} Template` }
          tabs={[
            {
              label: 'Overview',
              node: this.renderTemplateColumns(),
            },
            {
              label: 'COA Mapping',
              node: this.renderCoaList(),
            }
          ]}
        />
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(FinanceTemplate), 'access_admin_content_manager'));
