// Libs
import React from 'react';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { DeleteOutlined } from '@ant-design/icons';
import BasicList from 'components/basic-list';
import { Button, Modal } from 'antd';

// Views
import CreateCoverageDialog from 'views/admin/groups/CreateCoverageDialog';

// Api
import { Api } from 'services/api';
import Notification from 'services/notification';

// Styles
import 'assets/styles/_layout.scss';

type RoleTypes = 'INTERNAL' | 'EXTERNAL';
type RoleStatus = 'APPROVED' | 'PENDING APPROVAL';

interface Record {
  id: number | null;
  title: string | null;
  description: string | null;
  type: RoleTypes | null,
  status: RoleStatus | null,
  users: any[] | null,
  permissions: any[] | null,
};

interface Props {
  record: Record;
  clientId: number;
};

interface State {
  isLoading: boolean;
  showCreateDialog: boolean;
  isCreating: boolean;
  isDeletingCoverage: boolean;
  showDeleteDialog: boolean;
  entities: any[];
  activeRecordId: null,
};

const API: Api = new Api();

class CoverageTab extends React.Component<Props, State> {

  mounted: boolean = false;
  createFormRef: any = React.createRef();

  state: State = {
    isLoading: false,
    showCreateDialog: false,
    isCreating: false,
    showDeleteDialog: false,
    isDeletingCoverage: false,
    entities: [],
    activeRecordId: null,
  };

  componentDidMount = async () => {
    const { clientId, record } = this.props;
    this.mounted = true;

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));

      const entities = await API.get(`client/${clientId}/admin/groups/${record.id}/entity_map`);

      this.mounted && this.setState({
        entities: entities,
      });
    } catch {
      console.error('Failed');
    } finally {
      this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderCreateCoverageDialog = () => {
    const { clientId, record } = this.props;
    return (
      <CreateCoverageDialog
        clientId={ clientId }
        onClose={ () => this.setState({ showCreateDialog: false }) }
        onCreate={ async (covarage: { bundle: string | null, type: string | null }) => {
          try {

            await new Promise((resolve) => this.setState({ isCreating: true }, () => resolve(null) ));

            const entities = await API.post(`client/${clientId}/admin/groups/${record.id}/entity_map`, {
              bundle: covarage.bundle,
              type: covarage.type,
            });

            this.mounted && this.setState({
              entities: entities,
            }, () => {
              Notification('success', '', 'Coverage added');
            });
          } catch (error: any) {
            Notification('error', '', _.has(error, 'data') ? error.data : 'Failed to create');
          } finally {
            this.mounted && this.setState({
              isCreating: false,
              showCreateDialog: false,
            });
          }
        }}
      />
    );
  };

  renderDeleteDialog = () => {
    const { clientId, record } = this.props;
    const { activeRecordId, isDeletingCoverage, entities } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Remove Coverage' }
        maskClosable={ !isDeletingCoverage }
        onOk={ async () => {
          try {

            await new Promise((resolve) => this.setState({ isDeletingCoverage: true }, () => resolve(null) ));

            const entity = entities.find((entity: any) => entity.id === activeRecordId);

            const _entities = await API.delete(`client/${clientId}/admin/groups/${record.id}/entity_map`, {
              bundle: entity.bundle,
              type: entity.type,
            });

            this.mounted && this.setState({
              entities: _entities,
            }, () => {
              Notification('success', '', 'Removed Coverage');
            });

          } catch (error) {
            console.error(error);
          } finally {
            this.setState({
              isDeletingCoverage: false,
              showDeleteDialog: false,
              activeRecordId: null,
            });
          }
        } }
        okText={ 'Remove' }
        onCancel={() => this.setState({ showDeleteDialog: false, activeRecordId: null }) }
        okButtonProps={{
          danger: true,
          disabled: isDeletingCoverage,
          loading: isDeletingCoverage,
        }}
      >
        <p>Are you sure you want to remove this coverage?</p>
      </Modal>
    );
  };

  render = () => {
    const { isLoading, isCreating, entities, showCreateDialog, showDeleteDialog } = this.state;

    const columns = [
      {
        key: 'label',
        dataIndex: 'label',
        title: 'Record Type',
        sorter: true,
        ellipsis: true,
        filterable: true,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        render: (__: any, record: any) => {
          return (
            <DeleteOutlined
              className="mL-20 link"
              style={{ fontSize: 18 }}
              onClick={ () => this.setState({
                showDeleteDialog: true,
                activeRecordId: record.id
              })}
            />
          );
        },
        width: 100,
        sorter: false,
        ellipsis: true,
        filterable: false,
        align: 'center'
      },
    ];

    const items = entities && entities.map((row: any, index: number) => {
      return {
        ...row,
        key: index,
      };
    });

    return (
      <BlockingSpinner isLoading={ isLoading } style={{ minHeight: 500 }}>
        <div className="Layout-box pB-20">
          <BasicList
            rawData
            rightActions={ [
              {
                node: (
                  <Button
                    onClick={ () => this.setState({ showCreateDialog: true }) }
                    loading={ isCreating }
                  >
                    Create Coverage
                  </Button>
                ),
              }
            ] }
            columns={ columns }
            items={ items }
          />
        </div>
        { showCreateDialog && this.renderCreateCoverageDialog() }
        { showDeleteDialog && this.renderDeleteDialog() }
      </BlockingSpinner>
    );
  };
};

export default CoverageTab;
