// Libs
import * as React from 'react';

// Components
import { Button, Carousel as AntCarousel } from 'antd';

// Styles
import './Carousel.scss';

// Icons
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

interface Props {
  autoplay?: boolean;
  arrows?: boolean;
  dots?: boolean | { className?: string };
  beforeChange?: (current: number, next: number) => void;
  onChange?: (current: number) => void;
};

class Carousel extends React.Component<Props> {

  carousel: any = React.createRef();

  componentDidMount() {
    document.addEventListener('keydown', this.navigate);
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.navigate);
    this.carousel = null;
  };

  navigate = (e: KeyboardEvent) => {
    if (this.carousel) {
      if (e.key === 'ArrowLeft') {
        this.carousel.prev();
      } else if (e.key === 'ArrowRight') {
        this.carousel.next();
      }
    }
  };

  renderNextArrow = () => {
    return (
      <Button
        icon={ <RightOutlined style={{ fontSize: 50 }} /> }
        onClick={ () => this.carousel.next() }
        className="Carousel-navigation-button h-100p bg-grey-100 color-grey-600"
      />
    );
  };

  renderPrevArrow = () => {
    return (
      <Button
        icon={ <LeftOutlined style={{ fontSize: 50 }} /> }
        onClick={ () => this.carousel.prev() }
        className="Carousel-navigation-button h-100p bg-grey-100 color-grey-600"
      />
    );
  };

  render = () => {
    const { children, onChange, arrows, dots } = this.props;

    return (
      <div className="Carousel-container d-b mH-350 h-100p">
        <AntCarousel arrows={ false } dots={ dots } ref={ node => (this.carousel = node) } afterChange={ onChange } className={ 'Carousel mH-350 h-100p' }>
          { children }
        </AntCarousel>
        { !!arrows &&
          <>
            <div className="Carousel-prev-arrow">{ this.renderPrevArrow() }</div>
            <div className="Carousel-next-arrow">{ this.renderNextArrow() }</div>
          </>
        }
      </div>
    );
  };
};

export default Carousel;
