// Libs
import { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { AvailableResourceRecord, ResourceRecord, ResourceRequest } from 'components/resources-table';
import fileDownload from 'js-file-download';
import moment from 'moment';
import _ from 'lodash';

// Services
import { api } from 'store/Store';
import { AuditEntity, EntityVersion, RecordEntity, RecordFormEntity, UserEntity } from 'types/entities';

// Utils
import history from 'utils/history';
import { getBase64FromBlob } from 'utils/utils';

export class Api {
  instance: AxiosInstance;

  constructor() {
    this.instance = api.instance;
  }

  get(endpoint: string, data: any = {}): Promise<any> {
    return this.instance
      .get(endpoint, {
        params: data,
      })
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => {

        // Redirect to another path
        if (error?.response && error?.response?.status === 303 && error.response?.data?.path) {
          history.push(error.response?.data?.path);
        }

        return Promise.reject({ callee: "apiGet", error: error });
      });
  }

  put(endpoint: string, data: any = {}): Promise<any> {
    return this.instance
      .put(endpoint, data)
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => {
        return Promise.reject(error.response);
      });
  }

  delete(endpoint: string, data: any = {}): Promise<any> {
    return this.instance
      .delete(endpoint, {
        data: data,
      })
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => {
        return Promise.reject({ error });
      });
  }

  post(endpoint: string, data: any): Promise<any> {
    return this.instance
      .post(endpoint, data)
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => {
        return Promise.reject(error.response);
      });
  }

  download(endpoint: string, filename: string, params: any = {}): Promise<any> {
    return this.instance
      .get(endpoint, {
        responseType: 'blob',
        params: params,
      })
      .then((response: AxiosResponse) => {
        fileDownload(response.data, filename);
      })
      .catch((error: AxiosError) => {
        return Promise.reject({ error });
      });
  };

  downloadSingle(endpoint: string, filename: string, params: any = {}): Promise<any> {
    return this.instance
      .post(endpoint, {
        data: params,
      },
      {
        responseType: 'blob',
      })
      .then((response: AxiosResponse) => {
        fileDownload(response.data, filename);
      })
      .catch((error: AxiosError) => {
        return Promise.reject({ error });
      });
  };

  downloadBundle(endpoint: string, filename: string, params: any = {}): Promise<any> {
    return this.instance
      .post(endpoint, {
        data: params,
      },
      {
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        fileDownload(response.data, filename);
      })
      .catch((error: AxiosError) => {
        return Promise.reject({ error });
      });
  };

  downloadList(endpoint: string, params: any = {}): Promise<any> {
    return this.instance
      .post(endpoint, {
        params: JSON.stringify(params),
      },
      {
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        fileDownload(new Blob([response.data]), `pacs_list_${_.snakeCase(params.title)}_${moment().format('YYYY-MM-DD')}.xlsx`);
      })
      .catch((error: AxiosError) => {
        return Promise.reject({ error });
      });
  };

  getGlobalUserByEmail(email: string): Promise<any> {
    return this.instance
      .get(`users/${email}`)
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) =>
        Promise.reject({ callee: "getGlobalUserByEmail", error: error })
      );
  }

  getClientUserByEmail(client_id: number, email: string): Promise<any> {
    return this.instance
      .get(`client/${client_id}/admin/users/user/email/${email}`)
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) =>
        Promise.reject({ callee: "getClientUserByEmail", error: error })
      );
  }

  getClient(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}`)
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) =>
        Promise.reject({ callee: "getClient", error: error })
      );
  };

  setClientSetting(client_id: number, key: string, value: string): Promise<any> {
    return this.instance
      .put(`client/${client_id}/admin/setting`, {
        key: key,
        value: value,
      })
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => {
        return Promise.reject({ error });
      });
  };

  setClientLogotype(client_id: number, logotype: any): Promise<any> {
    return this.instance
      .post(`client/${client_id}/admin/logotype`, logotype)
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => {
        return Promise.reject({ error });
      });
  };

  getClientMedia(client_id: number, media_id: string): Promise<any> {
    return this.instance
      .get(`client/${client_id}/media/${media_id}`, {
        responseType: 'blob',
      })
      .then((response: AxiosResponse) => {
        return getBase64FromBlob(response.data)
          .then(result => {
            return result;
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch((error: AxiosError) => {
        return Promise.reject({ error });
      });
  };

  getCostCentres(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/cost-centre`)
      .then((response: AxiosResponse) => response.data);
  }

  getCostCentre(client_id: number, record_id: any): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/cost-centre/${record_id}`)
      .then((response: AxiosResponse) => response.data);
  }

  getCostCentreForm(client_id: number, record_id: any): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/cost-centre/${record_id}/form`)
      .then((response: AxiosResponse) => response.data);
  }

  getBudget(client_id: number, budget_id: any): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/budget/${budget_id}`)
      .then((response: AxiosResponse) => response.data);
  }

  getBudgetForm(client_id: number, budget_id: any): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/budget/${budget_id}/form`)
      .then((response: AxiosResponse) => response.data);
  }

  getBudgetGroupForm(client_id: number, budget_group_id: any): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/budget-group/${budget_group_id}/form`)
      .then((response: AxiosResponse) => response.data);
  }

  getBudgetItemForm(client_id: number, budget_item_id: any): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/budget-item/${budget_item_id}/form`)
      .then((response: AxiosResponse) => response.data);
  }

  getLocations(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/location`)
      .then((response: AxiosResponse) => response.data);
  }

  getLocation(client_id: number, location_id: any): Promise<RecordFormEntity> {
    return this.instance
      .get(`client/${client_id}/record/location/${location_id}`)
      .then((response: AxiosResponse) => response.data);
  }

  getUsers(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/admin/users`)
      .then((response: AxiosResponse) => response.data);
  }

  getUser(client_id: number, user_id: number): Promise<UserEntity> {
    return this.instance
      .get(`client/${client_id}/admin/users/${user_id}`)
      .then((response: AxiosResponse) => response.data);
  }

  createUser(client_id: number, data: any): Promise<any> {
    return this.instance
      .post(`client/${client_id}/admin/users`, {
        data: data,
      })
      .then((response: AxiosResponse) => response.data);
  }

  changeClient(user_id: number, client_id: number): Promise<any> {
    return this.instance
      .put(`user/${user_id}/change_client`, {
        client_id: client_id,
      })
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) =>
        Promise.reject({ callee: "changeClient", error })
      );
  }

  updateUser(client_id: number, user_id: number, user: UserEntity): Promise<any> {
    return this.instance
      .put(`client/${client_id}/admin/users/${user_id}`, {
        data: {
          ...user,
          phone: `${user?.phone_object?.dial_code} ${user?.phone_object?.phone_number}`
        },
      })
      .then((response: AxiosResponse) => response.data);
  }

  getCategories(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/admin/content/categories`)
      .then((response: AxiosResponse) => response.data);
  }

  getCategory(client_id: number, category: string): Promise<any> {
    return this.instance
      .get(`client/${client_id}/admin/category/${category}`)
      .then((response: AxiosResponse) => response.data);
  }

  getAvailableUserCompanies(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/available_companies`)
      .then((response: AxiosResponse) => response.data);
  }

  getRoles(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/admin/roles`)
      .then((response: AxiosResponse) => response.data);
  }

  getAvailableRoleUsers(client_id: number, role_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/admin/roles/${role_id}/available_users`)
      .then((response: AxiosResponse) => response.data);
  }

  // TODO: mock data from NewRole interface
  createRole(client_id: number, data: any): Promise<any> {
    return this.instance
      .post(`client/${client_id}/admin/roles`, {
        data: data,
      })
      .then((response: AxiosResponse) => response.data);
  }

  getRole(client_id: number, role_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/admin/roles/${role_id}`)
      .then((response: AxiosResponse) => response.data);
  }

  updateRole(client_id: number, role_id: number, data: any): Promise<any> {
    return this.instance
      .put(`client/${client_id}/admin/roles/${role_id}`, {
        data: data,
      })
      .then((response: AxiosResponse) => response.data);
  }

  deleteUserFromRole(
    client_id: number,
    role_id: number,
    user_id: number
  ): Promise<any> {
    return this.instance
      .delete(`client/${client_id}/admin/roles/${role_id}/user/${user_id}`)
      .then((response: AxiosResponse) => response.data);
  }

  deleteRole(client_id: number, role_id: number): Promise<any> {
    return this.instance
      .delete(`client/${client_id}/admin/roles/${role_id}`)
      .then((response: AxiosResponse) => response.data);
  }

  getAuditLog(
    client_id: number,
    entity_type: string,
    entity_id: any,
    entity: string
  ): Promise<AuditEntity[]> {
    return this.instance
      .get(`client/${client_id}/${entity}/${_.kebabCase(entity_type)}/${entity_id}/audit`)
      .then((response: any) => response.data);
  }

  getLocationForm(client_id: number, location_id?: number): Promise<any> {
    if (location_id) {
      return this.instance
        .get(`client/${client_id}/record/location/${location_id}/form`)
        .then((response: AxiosResponse) => response.data);
    }

    return this.instance
      .get(`client/${client_id}/record/location/form`)
      .then((response: AxiosResponse) => {
        return {
          form: response.data,
        };
      });
  }

  postLocations(
    client_id: number,
    location: RecordFormEntity
  ): Promise<RecordFormEntity> {
    return this.instance
      .post(`client/${client_id}/record/location/form`, {
        data: JSON.stringify(location),
      })
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) => {
        return Promise.reject({
          callee: "postLocations",
          error: errorResponse.toJSON(),
        });
      });
  }

  putLocations(
    client_id: number,
    location_id: number,
    location: RecordFormEntity
  ): Promise<RecordFormEntity> {
    return this.instance
      .put(`client/${client_id}/record/location/${location_id}/form`, {
        data: JSON.stringify(location),
      })
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) => {
        return Promise.reject({
          callee: "putLocations",
          error: errorResponse.response,
        });
      });
  }

  getLocationsForm(
    client_id: number,
    location_id: number
  ): Promise<RecordFormEntity> {
    return this.instance
      .get(`client/${client_id}/record/location/${location_id}/form`)
      .then((response: AxiosResponse) => response.data);
  }

  getBusinessUnits(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/business-unit`)
      .then((response: AxiosResponse) => response.data);
  }

  getSpaces(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/space`)
      .then((response: AxiosResponse) => response.data);
  }

  getOwned(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/owned`)
      .then((response: AxiosResponse) => response.data);
  }

  getOwnedRecord(client_id: number, record_id: any): Promise<RecordEntity> {
    return this.instance
      .get(`client/${client_id}/record/owned/${record_id}`)
      .then((response: AxiosResponse) => response.data);
  }

  getOwnedForm(client_id: number, record_id: number): Promise<RecordFormEntity> {
    return this.instance
      .get(`client/${client_id}/record/owned/${record_id}/form`)
      .then((response: AxiosResponse) => response.data);
  }

  postOwned(
    client_id: number,
    record: RecordFormEntity
  ): Promise<RecordFormEntity> {
    return this.instance
      .post(`client/${client_id}/record/owned`, {
        data: JSON.stringify(record),
      })
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) => {
        return {
          callee: "postOwned",
          error: errorResponse,
        };
      });
  }

  putOwned(
    client_id: number,
    record_id: number,
    record: RecordFormEntity
  ): Promise<RecordFormEntity> {
    return this.instance
      .put(`client/${client_id}/record/owned/${record_id}`, {
        data: JSON.stringify(record),
      })
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) => {
        return {
          callee: "putOwned",
          error: errorResponse,
        };
      });
  }

  getLeases(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/lease`)
      .then((response: AxiosResponse) => response.data);
  }

  getLease(client_id: number, lease_id: any): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/lease/${lease_id}`)
      .then((response: AxiosResponse) => response.data);
  }

  getLeaseForm(client_id: number, lease: number): Promise<RecordFormEntity> {
    return this.instance
      .get(`client/${client_id}/record/lease/${lease}/form`)
      .then((response: AxiosResponse) => response.data);
  }

  postLeases(client_id: number, lease: Record<string, any>): Promise<any> {
    return this.instance
      .post(`client/${client_id}/record/lease`, {
        data: JSON.stringify(lease),
      })
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) => {
        return {
          callee: "postLeases",
          error: errorResponse,
        };
      });
  }

  putLeases(
    client_id: number,
    lease_id: number,
    lease: Record<string, any>
  ): Promise<any> {
    return this.instance
      .put(`client/${client_id}/record/lease/${lease_id}`, {
        data: JSON.stringify(lease),
      })
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) => {
        return {
          callee: "putLeases",
          error: errorResponse,
        };
      });
  }

  getSubleases(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/sublease`)
      .then((response: AxiosResponse) => response.data);
  }

  getContract(client_id: number, record_id: any): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/company/${record_id}`)
      .then((response: AxiosResponse) => response.data);
  }

  getSuppliers(client_id: number): Promise<any> {
    return this.instance
      .get(
        `client/${client_id}/record/company?field[company_type][reference]=supplier`
      )
      .then((response: AxiosResponse) => response.data);
  }

  getCompanies(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/company`)
      .then((response: AxiosResponse) => response.data);
  }

  getCompanyRecord(client_id: number, record_id: any): Promise<RecordEntity> {
    return this.instance
      .get(`client/${client_id}/record/company/${record_id}`)
      .then((response: AxiosResponse) => response.data);
  }

  getCompanyForm(
    client_id: number,
    record_id: number
  ): Promise<RecordFormEntity> {
    return this.instance
      .get(`client/${client_id}/record/company/${record_id}/form`)
      .then((response: AxiosResponse) => response.data);
  }

  postCompany(
    client_id: number,
    record: RecordFormEntity
  ): Promise<RecordFormEntity> {
    return this.instance
      .post(`client/${client_id}/record/company`, {
        data: JSON.stringify(record),
      })
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) => {
        return {
          callee: "postCompany",
          error: errorResponse,
        };
      });
  }

  putCompany(
    client_id: number,
    record_id: number,
    record: RecordFormEntity
  ): Promise<RecordFormEntity> {
    return this.instance
      .put(`client/${client_id}/record/company/${record_id}`, {
        data: JSON.stringify(record),
      })
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) => {
        return {
          callee: "putCompany",
          error: errorResponse,
        };
      });
  }

  // Service Tools
  getHelpDeskTickets(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/ticket/help-desk-ticket`)
      .then((response: AxiosResponse) => response.data);
  }

  getServiceSpecifications(client_id: number): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/service-specification`)
      .then((response: AxiosResponse) => response.data);
  }

  getServiceSpecification(client_id: number, service_specification_id: any): Promise<any> {
    return this.instance
      .get(`client/${client_id}/record/service-specification/${service_specification_id}`)
      .then((response: AxiosResponse) => response.data);
  }

  getEntityVersions(
    client_id: number,
    type: string,
    entity_id: number,
    entity: string,
  ): Promise<EntityVersion[]> {
    return this.instance
      .get(`client/${client_id}/${entity}/${type}/${entity_id}/versions`)
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) =>
        Promise.reject({ callee: "getEntityVersions", error: errorResponse })
      );
  }

  getAvailableEntityRoles(
    client_id: number,
    type: string,
    entity_id: number,
    entity: string,
  ): Promise<AvailableResourceRecord[]> {
    return this.instance
      .get(`client/${client_id}/${entity}/${type}/${entity_id}/available-roles`)
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) =>
        Promise.reject({
          callee: "getAvailableEntityRoles",
          error: errorResponse,
        })
      );
  }

  getEntityResources(
    client_id: number,
    type: string,
    entity_id: number,
    entity: string,
  ): Promise<any> {
    return this.instance
      .get(`client/${client_id}/${entity}/${type}/${entity_id}/resource`)
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) =>
        Promise.reject({ callee: "getEntityResources", error: errorResponse })
      );
  }

  getEntityResource(
    client_id: number,
    entity: string,
    entity_id: number,
    resource_id: number
  ): Promise<ResourceRecord> {
    return this.instance
      .get(
        `client/${client_id}/record/${entity}/${entity_id}/resource/${resource_id}`
      )
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) =>
        Promise.reject({ callee: "getEntityResource", error: errorResponse })
      );
  }

  postEntityResource(
    client_id: number,
    type: string,
    entity_id: number,
    resource: ResourceRequest,
    entity: string,
  ): Promise<any> {
    return this.instance
      .post(`client/${client_id}/${entity}/${type}/${entity_id}/resource`, {
        data: JSON.stringify(resource),
      })
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) =>
        Promise.reject({ callee: "postEntityResource", error: errorResponse })
      );
  }

  putEntityResource(
    client_id: number,
    type: string,
    entity_id: number,
    resource_id: number,
    resource: ResourceRequest,
    entity: string,
  ): Promise<any> {
    return this.instance
      .put(
        `client/${client_id}/${entity}/${type}/${entity_id}/resource/${resource_id}`,
        {
          data: JSON.stringify(resource),
        }
      )
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) =>
        Promise.reject({ callee: "putEntityResource", error: errorResponse })
      );
  }

  deleteEntityResource(
    client_id: number,
    type: string,
    entity_id: number,
    resource_id: number,
    entity: string,
  ): Promise<any> {
    return this.instance
      .delete(
        `client/${client_id}/${entity}/${type}/${entity_id}/resource/${resource_id}`
      )
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) =>
        Promise.reject({ callee: "deleteEntityResource", error: errorResponse })
      );
  }

  makeEntityResourcePrimary(
    client_id: number,
    type: string,
    entity_id: number,
    resource_id: number,
    entity: string,
  ): Promise<any> {
    return this.instance
      .put(
        `client/${client_id}/${entity}/${type}/${entity_id}/resource/${resource_id}/primary`
      )
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) =>
        Promise.reject({
          callee: "makeEntityResourcePrimary",
          error: errorResponse,
        })
      );
  }

  approveEntityResource(
    client_id: number,
    type: string,
    entity_id: number,
    resource_id: number,
    entity: string,
  ): Promise<any> {
    return this.instance
      .put(
        `client/${client_id}/${entity}/${type}/${entity_id}/resource/${resource_id}/approve`
      )
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) =>
        Promise.reject({
          callee: "approveEntityResource",
          error: errorResponse,
        })
      );
  }

  rejectEntityResource(
    client_id: number,
    type: string,
    entity_id: number,
    resource_id: number,
    comment: string,
    entity: string,
  ): Promise<any> {
    return this.instance
      .put(
        `client/${client_id}/${entity}/${type}/${entity_id}/resource/${resource_id}/reject`,
        {
          comment,
        }
      )
      .then((response: AxiosResponse) => response.data)
      .catch((errorResponse: AxiosError) =>
        Promise.reject({ callee: "rejectEntityResource", error: errorResponse })
      );
  }

  getAddressAutoCompleteOptions(
    client_id: number,
    field_type: string,
    field: string | number,
    entity_type: string,
    query: string
  ): Promise<any> {
    return this.instance
      .get(
        `client/${client_id}/field/${field_type}/${field}/${entity_type}?${query}`
      )
      .then((response: AxiosResponse) => response.data);
  }

  getCompaniesAutoCompleteOptions(
    client_id: number,
    type: string,
    bundle: string,
    entity_id: number,
    role_id: number
  ): any {
    return this.instance
      .get(
        `client/${client_id}/${bundle}/${type}/${entity_id}/resource/search/company?role_id=${role_id}`
      )
      .then((response: AxiosResponse) => response.data);
  }

  getUserAutoCompleteOptions(
    client_id: number,
    type: string,
    bundle: string,
    entity_id: number,
    role_id: number,
    company_id?: number,
    group_id?: number
  ): any {
    return this.instance
      .get(`client/${client_id}/${bundle}/${type}/${entity_id}/resource/search/user`, {
        params: { role_id: role_id, company_id: company_id, group_id: group_id }
      })
      .then((response: AxiosResponse) => response.data);
  };

  updatePassword(current_password: string, new_password: string): Promise<any> {
    return this.instance
      .put(`user/update_password`, {
        current_password: current_password,
        new_password: new_password,
      })
      .then((response: AxiosResponse) => response);
  }

  setUserSetting(key: string, value: string): Promise<any> {
    return this.instance
      .put(`user/setting`, {
        key: key,
        value: value,
      })
      .then((response: AxiosResponse) => response);
  }

  requestPasswordToken(email: string, active_client: number = 0): Promise<any> {
    return this.instance
      .put(`request_password_token`, {
        email: email,
        client_id: active_client || null,
      })
      .then((response: AxiosResponse) => response);
  }

  updatePasswordWithToken(
    token: string,
    email: string,
    password: string
  ): Promise<any> {
    return this.instance
      .put(`update_password_with_token`, {
        token: token,
        email: email,
        password: password,
      })
      .then((response: AxiosResponse) => response);
  }

  onboardUser(
    token: string,
    email: string,
    password: string,
    client_id: number,
  ): Promise<any> {
    return this.instance
      .put(`onboard_user`, {
        token: token,
        email: email,
        password: password,
        client_id: client_id
      })
      .then((response: AxiosResponse) => response);
  }

  sendUserInviteEmail(client_id: number, user_id: number): Promise<any> {
    return this.instance
      .post(`client/${client_id}/admin/users/${user_id}/invite`)
      .then((response: AxiosResponse) => {
        if (response.status === 204) {
          throw new Error();
        }

        return response.data;
      })
      .catch((errorResponse: AxiosError) =>
        Promise.reject({ callee: "sendUserInviteEmail", error: errorResponse })
      );
  }
}
