// Libs
import React, { BaseSyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

// Components
import { Button, Input, List, Modal, Select, Switch, Tooltip, TreeSelect, Badge as AntBadge, Table } from 'antd';
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import { RestrictionHoC } from 'components/restriction';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import PreviewModal from './PreviewModal';
import RearrangeModal from 'components/rearrange-modal';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Utils
import { modifyNestedSetItem, flattenSet, findFirst, removeNestedSetItem } from 'utils/utils';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Icons
import Icon, { QuestionCircleOutlined, PlusOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import {
  Activity,
  KeyDateType,
  Role,
  Milestone,
  Feature,
  Competence
} from 'views/admin/templates/Templates.interfaces';

// Styles
import './ActivityTemplate.scss';

const { TextArea } = Input;
const API: Api = new Api();
const { SHOW_PARENT } = TreeSelect;

// maximum number of days to continue activity
const MAX_DURATION = 365 * 30;

const getBlankState = (templateId: number): any => {
  const uniqueKey: string = uuidv4();
  return {
    id: uniqueKey,
    key: uniqueKey,
    title: '',
    tooltip: '',
    template_id: templateId,
    role_ids: [],
    milestone_ids: [],
    competency_ids: [],
    commencement_activity_id: null,
    commencement_offset: null,
    duration: null,
    reference: null,
    description: null,
    supporting_url: null,
    required: false,
    evidence: false,
    document: false,
    comments: false,
    isNewActivity: true, // The parameter is needed to recognize the new activity
    children: [],
  };
};

interface Props {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  recordTitle: string;
  originalActivities: Activity[];
  activities: Activity[];
  roles: Role[];
  milestones: Milestone[];
  competencies: Competence[];
  keyDateTypes: KeyDateType[];
  features: Feature[];
  originalFeatures: Feature[];
  isLoading: boolean;
  isSaving: boolean;
  showPreviewDialog: boolean;
  dialogInfo: { type: 'shared' | 'url' | null, activityId: number, activities: any[], featureLabel: string, featureReference: string };
  tmpValue: any;
  tableHeight: number;
  showRearrangeModal: boolean;
};

class ActivityTemplateActivity extends React.Component<RouteComponentProps<{}> & Props, State> {

  component: any = React.createRef(); // This is used to figure out when the component is rendered
  moveFormRef: any = React.createRef();
  mounted: boolean = false;
  scrollToBottomIndicator: boolean = false;

  state: State = {
    recordTitle: '',
    originalActivities: [],
    activities: [],
    roles: [],
    milestones: [],
    competencies: [],
    keyDateTypes: [],
    originalFeatures: [],
    features: [],
    isLoading: false,
    isSaving: false,
    showPreviewDialog: false,
    dialogInfo: { type: null, activityId: 0, activities: [], featureLabel: '', featureReference: '' },
    tmpValue: null,
    tableHeight: 0,
    showRearrangeModal: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const { type_id: templateTypeId, id: templateId } = this.props.match.params;
    this.mounted = true;

    if (this.component) {
      this.heightObserver();
    }

    try {

      this.props.setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const activityTemplate = await API.get(`client/${client_id}/admin/activities/templates/activities/${templateId}`);
      const options = await API.get(`client/${client_id}/admin/activities/templates/options`);
      const roles = !!options.roles ? options.roles : [];
      const milestones = !!options.milestones ? options.milestones : [];
      const competencies = !!options.competencies ? options.competencies : [];
      const keyDateTypes = _.has(options, 'key_dates') ? options.key_dates : [];

      const { title: templateTitle = 'Activities', activities = [], features = [] } = activityTemplate || {};

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Template Types', path: '/admin/templates' },
        { title: `Templates`, path: `/admin/templates/activity/${templateTypeId}` },
        { title: templateTitle, path: null },
      ], false);

      this.mounted && this.setState({
        recordTitle: activityTemplate?.title || 'Activity',
        originalActivities: activities,
        activities: activities,
        roles: roles,
        milestones: milestones,
        competencies: competencies,
        keyDateTypes: keyDateTypes,
        features: features,
        originalFeatures: features,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  componentDidUpdate = () => {
    // Scroll to bottom when a new item was added
    if (this.scrollToBottomIndicator) {
      this.scrollToBottom();
    }
  };

  scrollToBottom = () => {
    const table = document.querySelector('.ActivityTemplateActivity div.ant-table-body') as HTMLElement;
    if (table) {
      table.scrollTop = table.scrollHeight;
    }
    this.scrollToBottomIndicator = false;
  };

  heightObserver = () => {
    const root: number = document.getElementById('root')?.offsetHeight || 0;
    const header: number = document.getElementById('Header')?.offsetHeight || 0;
    const jumbotron: number = document.getElementById('Jumbotron')?.offsetHeight || 0;
    const tabViewBar: number = document.getElementById('TabView-bar')?.offsetHeight || 0;
    const tableHeight: number = root - (header + jumbotron + tabViewBar + 150);

    if (this.state.tableHeight !== tableHeight) {
      this.setState({
        tableHeight: tableHeight
      });
    }
  };

  isModified = (type?: string): boolean => {
    const { activities, originalActivities, features, originalFeatures } = this.state;

    if (type !== 'features') {
      if (!_.isEqual(activities, originalActivities)) {
        return true;
      }
    }

    if (type !== 'activities') {
      if (!_.isEqual(features, originalFeatures)) {
        return true;
      }
    }

    return false;
  };

  getErrors = () => {
    const { activities } = this.state;
    const flattenActivities = flattenSet(_.cloneDeep(activities));
    const errors: any[] = [];

    flattenActivities.forEach((activity: Activity) => {
      if (!activity.title) {
        errors.push(activity?.key);
      }
    });

    return errors;
  };

  isDuplicatedActivityID = (activityId: Activity['reference']): boolean => {
    const { activities } = this.state;

    if (!activityId) {
      return false;
    }

    return activities?.filter((record: Activity) => record.reference === activityId).length >= 2;
  };

  filterColumns = (columns: any[], features: Feature[]): any[] => {
    return columns
      .filter((column: any) => !this.isHiddenFeature(column.dataIndex, features, column?.dependencyFeatures || []))
      .map((column: any) => {
        return {
          ...column,
          children: !_.isEmpty(column?.children) ? this.filterColumns(column.children, features) : undefined
        };
      });
  };

  isHiddenFeature = (reference: string, features: Feature[], dependencyFeatures: string[] = []): boolean => {

    if (!_.isEmpty(dependencyFeatures)) {
      return !features
        .filter((feature: Feature) => dependencyFeatures.includes(feature.reference))
        .every((feature: Feature) => !!feature.enabled);
    }

    if (features.find((feature: Feature) => feature.reference === reference)) {
      return !features.find((feature: Feature) => feature.reference === reference)?.enabled;
    }

    return false;
  };

  delete = (identifier: number, values: Activity[]): Activity[] => {
    return values.filter((value: Activity) => value.id !== identifier);
  };

  modifyValues = (identifier: number, values: Activity[], newValue: any, key: string): Activity[] => {
    const currentActivity = findFirst({ children: values }, 'children', { id: identifier });

    if (currentActivity) {
      return modifyNestedSetItem(identifier, _.set(currentActivity, [key], newValue), values);
    }

    return values;
  };

  handleSave = async (): Promise<void> => {
    const { id: templateId } = this.props.match.params;
    const { client_id } = this.props;
    const { activities, features } = this.state;

    try {
      await new Promise((resolve) => this.setState({ isSaving: true }, () => resolve(null)));

      const processedActivities = activities.map((activity) => {
        // if don't pass an id, then the backend will know it's a new activity and create it.
        return _.has(activity, 'isNewActivity') ? _.omit(activity, ['id', 'isNewActivity']) : activity;
      });
      const enabledFeatures = features.filter(feature => feature.enabled);

      const response = await API.put(`client/${client_id}/admin/activities/templates/${templateId}/activities`, {
        data: { activities: processedActivities, features: enabledFeatures },
      });

      this.mounted && this.setState({
        activities: response.activities,
        originalActivities: response.activities,
        features: response.features,
        originalFeatures: response.features,
      });

      Notification('success', 'Saved');
    } catch (error) {
      Notification('error', 'Failed to update activities', 'Failed');
    } finally {
      this.mounted && this.setState({ isSaving: false });
    }
  };

  renderColumnTitle = (rows: string[], tooltip: string, required: boolean = false): JSX.Element => {
    return (
      <div className='d-f'>
        <div className='d-f fxd-c'>
          { rows.map((row, idx) => <span key={ `${row}_${idx}` }>{ row }</span>) }
        </div>
        { required && <span className="text-required mL-2 fsz-md va-t">*</span> }
        <Tooltip
          className="mL-5 pT-1"
          placement="top"
          title={ tooltip }
        >
          <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
        </Tooltip>
      </div>
    );
  };

  renderDialog = (type: 'shared' | 'url') => {
    const { tmpValue } = this.state;
    const { activityId, activities, featureLabel, featureReference } = this.state.dialogInfo || {};
    return (
      <Modal
        visible
        centered
        title={ 'Add '+ featureLabel }
        okText={ 'Add' }
        onOk={ () => {

          let updatedActivities = _.cloneDeep(activities);

          if (type === 'url') {
            if (!tmpValue?.url) {
              updatedActivities = this.modifyValues(activityId, _.cloneDeep(updatedActivities), null, 'supporting_url_label');
              updatedActivities = this.modifyValues(activityId, _.cloneDeep(updatedActivities), null, 'supporting_url');
            } else {
              updatedActivities = this.modifyValues(activityId, _.cloneDeep(updatedActivities), tmpValue?.label || null, 'supporting_url_label');
              updatedActivities = this.modifyValues(activityId, _.cloneDeep(updatedActivities), tmpValue.url, 'supporting_url');
            }
          } else {
            updatedActivities = this.modifyValues(activityId, _.cloneDeep(updatedActivities), _.cloneDeep(tmpValue), featureReference);
          }

          this.setState({
            activities: updatedActivities,
            dialogInfo: { type: null, activityId: 0, activities: [], featureLabel: '', featureReference: '' },
            tmpValue: '',
          });
        } }
        onCancel={ () => this.setState({ dialogInfo: { type: null, activityId: 0, activities: [], featureLabel: '', featureReference: '' }, tmpValue: '' }) }
      >
        { type === 'url' ? (
          <div>
            <div>
              <label>Label</label>
              <Input
                autoFocus
                onFocus={ event => event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length) }
                onBlur={ (event: BaseSyntheticEvent) => this.setState({ tmpValue: { ...tmpValue, label: event.target.value } }) }
                defaultValue={ tmpValue?.label }
              />
            </div>
            <div className="mT-10">
              <label>URL<span className="text-required fsz-md lh-1 va-t">*</span></label>
              <Input
                onBlur={ (event: BaseSyntheticEvent) => this.setState({ tmpValue: { ...tmpValue, url: event.target.value } }) }
                defaultValue={ tmpValue?.url }
              />
            </div>
          </div>
        ) : (
          <TextArea
            autoSize={{ minRows: 4 }}
            autoFocus
            onFocus={ event => event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length) }
            onBlur={ (event: BaseSyntheticEvent) => this.setState({ tmpValue: event.target.value }) }
            defaultValue={ tmpValue }
          />
        ) }
      </Modal>
    );
  };

  renderMoveDialog = (activities: Activity[]) => {

    const getTreeData: any = (_activities: Activity[]) => {
      return _activities.map((activity: Activity) => {
        return {
          ...activity,
          title: activity.title,
          value: activity?.key,
          children: !!activity?.children && !_.isEmpty(activity?.children) ? getTreeData(activity?.children) : [],
        };
      });
    };

    const treeData = getTreeData(_.cloneDeep(activities));

    return (
      <RearrangeModal
        isNestable
        treeData={ treeData }
        isLoading={ false }
        onOk={ async (treeData: any) => {
          this.setState({
            activities: treeData,
            showRearrangeModal: false,
          });
        } }
        onClose={ () => this.setState({ showRearrangeModal: false }) }
      />
    );
  };

  renderActivities = (errors: any[]): JSX.Element => {
    const {
      activities,
      roles,
      milestones,
      competencies,
      keyDateTypes,
      dialogInfo,
      features,
      tableHeight,
      showRearrangeModal,
    } = this.state;
    const { id: templateId } = this.props.match.params;

    const columns = [
      {
        key: 'title',
        dataIndex: 'title',
        width: 260,
        title: this.renderColumnTitle(['Title'], 'This title will be used to name the created activity', true),
        render: (title: string, row: Activity) => {

          const tooltip = row.tooltip;

          const onChange = _.debounce((value: string) => {
            this.setState({
              activities: this.modifyValues(row.id, _.cloneDeep(activities), value, 'title')
            });
          }, 300);

          return (
            <div className="d-f">
              <Input
                className="Field w-100p"
                onChange={ (event: BaseSyntheticEvent) => onChange(event.target.value) }
                defaultValue={ title }
                suffix={
                  <Tooltip
                    className="mL-5 pT-1"
                    placement="top"
                    title={ !!tooltip ? tooltip : 'Click to add a tooltip for the Title' }
                  >
                    <AntBadge dot={ !!tooltip } status="success" offset={[1, 3]}>
                      <QuestionCircleOutlined
                        className='link'
                        onClick={ () => {
                          if (row?.id) {
                            this.setState({
                              dialogInfo: {
                                type: 'shared',
                                activityId: row.id,
                                activities: activities,
                                featureLabel: 'Tooltip',
                                featureReference: 'tooltip'
                              },
                              tmpValue: tooltip
                            });
                          }
                        }}
                      />
                    </AntBadge>
                  </Tooltip>
                }
              />
            </div>
          );
        },
      },
      {
        key: 'reference',
        dataIndex: 'reference',
        dependencyFeatures: ['activity_id'],
        width: 150,
        title: this.renderColumnTitle(['ID'], 'This unique ID helps users identify the activity'),
        render: (reference: string, row: Activity) => {

          const onChange = _.debounce((value: string) => {
            const _value = _.isEmpty(value) ? null : value;
            this.setState({
              activities: this.modifyValues(row.id, _.cloneDeep(activities), _value, 'reference')
            });
          }, 300);

          return (
            <div className="d-f">
              <Input
                className="Field"
                onChange={ (event: BaseSyntheticEvent) => onChange(event.target.value) }
                defaultValue={ reference }
              />
            </div>
          );
        }
      },
      {
        key: 'milestones',
        dataIndex: 'milestone_ids',
        dependencyFeatures: ['milestone_type'],
        width: 260,
        ellipsis: true,
        title: this.renderColumnTitle(['Milestone'], 'Categorise the activity as a milestone'),
        render: (milestone_ids: number[], row: Activity & { isNewActivity?: boolean }) => {
          return (
            <Select
              className="Select-Field"
              showSearch
              mode='multiple'
              allowClear
              dropdownMatchSelectWidth={ false }
              maxTagCount={ 1 }
              maxTagTextLength={ milestone_ids?.length === 1 ? 22 : 12 }
              style={{ width: '100%' }}
              placeholder={ '-' }
              filterOption={(input: any, option: any) => {
                return !!milestones.find((record: Milestone) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
              } }
              value={ milestone_ids }
              onChange={ (recordIds: Array<string | number>) => {
                this.setState({
                  activities: this.modifyValues(row.id, _.cloneDeep(activities), recordIds, 'milestone_ids')
                });
              } }
            >
              { milestones.map( (milestone: Milestone) => (
                <Select.Option key={ milestone.id } value={ milestone.id }>{ milestone.title }</Select.Option>
              )) }
            </Select>
          );
        }
      },
      {
        key: 'description',
        dataIndex: 'description',
        dependencyFeatures: ['description'],
        width: 180,
        ellipsis: true,
        title: this.renderColumnTitle(['Description'], 'Allow users to write a useful description'),
        render: (description: string, row: Activity) => {
          return (
            <Input
              className="Field"
              onClick={ () => {
                this.setState({
                  dialogInfo: {
                    type: 'shared',
                    activityId: row.id,
                    activities: activities,
                    featureLabel: 'Description',
                    featureReference: 'description'
                  },
                  tmpValue: description
                });
              } }
              value={ description || '-' }
            />
          );
        },
      },
      {
        key: 'supporting_url',
        dataIndex: 'supporting_url',
        dependencyFeatures: ['supporting_url'],
        width: 180,
        ellipsis: true,
        title: this.renderColumnTitle(['Supporting URL'], 'Allows inclusion of a URL to direct the user to a page to complete the task. Supports smart tags'),
        render: (__: any, row: Activity) => {
          return (
            <Input
              className="Field"
              onClick={ () => {
                if (row?.id) {
                  this.setState({
                    dialogInfo: {
                      type: 'url',
                      activityId: row.id,
                      activities: activities,
                      featureLabel: 'Supporting URL',
                      featureReference: 'supporting_url'
                    },
                    tmpValue: {
                      label: row?.supporting_url_label || null,
                      url: row?.supporting_url || '',
                    }
                  });
                }
              } }
              value={ row?.supporting_url_label || row?.supporting_url || '-' }
            />
          );
        },
      },
      {
        key: 'key_date_id',
        dataIndex: 'key_date_id',
        dependencyFeatures: ['key_date'],
        width: 260,
        ellipsis: true,
        title: this.renderColumnTitle(['Key Date'], 'Categorise the activity as a key date'),
        render: (keyDateTypeId: number, row: Activity & { isNewActivity?: boolean }) => {
          return (
            <Select
              showSearch
              allowClear
              dropdownMatchSelectWidth={ false }
              style={{ width: '100%' }}
              placeholder={ '-' }
              className="Select-Field"
              filterOption={(input: any, option: any) => {
                return !!keyDateTypes.find((record: KeyDateType) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
              } }
              value={ keyDateTypeId }
              onChange={ (keyDateTypeId: number) => {
                this.setState({
                  activities: this.modifyValues(row.id, _.cloneDeep(activities), keyDateTypeId, 'key_date_id')
                });
              } }
            >
              { keyDateTypes.map((keyDateType: KeyDateType) => {

                // Prevent user from selecting the same key date twice
                const isDisabled = activities.some((activity: Activity) => activity.key_date_id === keyDateType.id);

                if (isDisabled) {
                  return (
                    <Select.Option
                      key={ keyDateType.id }
                      value={ keyDateType.id }
                      disabled
                    >
                      <Tooltip
                        placement="top"
                        title={ 'This date type is already in use by another activity.' }
                      >
                        { keyDateType.title }
                      </Tooltip>
                    </Select.Option>
                  );
                }

                return (
                  <Select.Option
                    key={ keyDateType.id }
                    value={ keyDateType.id }
                  >
                    { keyDateType.title }
                  </Select.Option>
                );
              } ) }
            </Select>
          );
        }
      },
      {
        key: 'roles',
        dataIndex: 'role_ids',
        dependencyFeatures: ['resource_allocation'],
        width: 260,
        ellipsis: true,
        title: this.renderColumnTitle(['Resource'], 'Should this activity be limited to a particular role? Please leave blank if no restrictions are required'),
        render: (role_ids: number[], row: Activity & { isNewActivity?: boolean }) => {
          return (
            <Select
              className="Select-Field"
              showSearch
              mode='multiple'
              allowClear
              dropdownMatchSelectWidth={ false }
              maxTagCount={ 'responsive' }
              maxTagTextLength={ role_ids?.length === 1 ? 22 : 12 }
              style={{ width: '100%' }}
              placeholder={ 'Everyone' }
              filterOption={(input: any, option: any) => {
                return !!roles.find((record: Role) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
              } }
              value={ role_ids }
              onChange={ (recordIds: Array<string | number>) => {
                let ids = recordIds;

                // Select all logic
                if (recordIds.includes('all')) {
                  ids = roles.map((role: Role) => role.id);
                }

                this.setState({
                  activities: this.modifyValues(row.id, _.cloneDeep(activities), ids, 'role_ids')
                });
              } }
            >
              { !_.isEmpty(roles) &&
                <Select.Option key={ 'all' } value={ 'all' }>{ 'Select all' }</Select.Option>
              }
              { roles.map( (role: Role) => (
                <Select.Option key={ role.id } value={ role.id }>{ role.title }</Select.Option>
              )) }
            </Select>
          );
        }
      },
      {
        key: 'offset',
        dataIndex: 'offset',
        width: 250,
        dependencyFeatures: ['planned_dates_start'],
        title: this.renderColumnTitle(['Offset', 'In Days'], 'The number of days from start that the activity should be scheduled for'),
        render: (__: any, row: Activity) => {

          const onChangeOffset = _.debounce((value: string) => {
            this.setState({
              activities: this.modifyValues(row.id, _.cloneDeep(activities), parseInt(value), 'commencement_offset')
            });
          }, 300);

          const getTreeData: any = (activities: Activity[]) => {
            return activities.map((activity: Activity) => {
              return {
                ...activity,
                key: activity?.id,
                value: activity?.id,
                title: activity.title,
                children: !_.isEmpty(activity?.children) && !!activity?.children ? getTreeData(activity.children) : null,
              };
            });
          };

          return (
            <>
              <TreeSelect
                showSearch
                className="Select-Field"
                style={{ width: '65%' }}
                dropdownMatchSelectWidth={ false }
                showCheckedStrategy={ SHOW_PARENT }
                treeDefaultExpandAll
                multiple={ false }
                treeData={ getTreeData(activities) }
                value={ row?.commencement_activity_id || undefined }
                placeholder={ 'Start Date' }
                onChange={ (id: number) => {
                  this.setState({
                    activities: this.modifyValues(row.id, _.cloneDeep(activities), id, 'commencement_activity_id')
                  });
                } }
                filterTreeNode={ (input: string, option: any) => {
                  if (option) {
                    const filteredInput = input.toLocaleLowerCase();
                    const title = option.title && option.title.toLowerCase();

                    if (title.includes(filteredInput)) {
                      return true;
                    }
                  }

                  return false;
                } }
              />
              <NumberFormat
                className="Field ta-r mL-5"
                decimalScale={ 0 }
                customInput={ Input }
                style={{ width: '30%' }}
                allowNegative={ true }
                value={ row?.commencement_offset || '0' }
                placeholder={ '0' }
                onChange={ (event: BaseSyntheticEvent) => onChangeOffset(event.target.value) }
              />
            </>
          );
        }
      },
      {
        key: 'duration',
        dataIndex: 'duration',
        width: 105,
        dependencyFeatures: ['planned_dates_completion'],
        title: this.renderColumnTitle(['Duration', 'In Days'], 'The number of days the activity should last'),
        render: (duration: number | undefined, row: Activity) => {

          const onChange = _.debounce((value: string) => {
            this.setState({
              activities: this.modifyValues(row.id, _.cloneDeep(activities), parseInt(value), 'duration')
            });
          }, 300);

          return (
            <NumberFormat
              className="Field pR-20 ta-r"
              decimalScale={ 0 }
              isAllowed={ ({ floatValue = 0 }) => floatValue <= MAX_DURATION }
              customInput={ Input }
              allowNegative={ false }
              value={ duration }
              placeholder={ '0' }
              onChange={ (event: BaseSyntheticEvent) => onChange(event.target.value) }
            />
          );
        }
      },
      {
        key: 'competencies',
        dataIndex: 'competency_ids',
        dependencyFeatures: ['competence_required'],
        width: 260,
        ellipsis: true,
        title: this.renderColumnTitle(['Competence', 'Required'], 'Skills required to complete the activity from a predefined list'),
        render: (competency_ids: number[], row: Activity) => {
          return (
            <Select
              className="Select-Field"
              showSearch
              mode={ 'multiple' }
              allowClear
              dropdownMatchSelectWidth={ false }
              maxTagCount={ 'responsive' }
              maxTagTextLength={ competency_ids?.length === 1 ? 22 : 12 }
              style={{ width: '100%' }}
              placeholder={ '-' }
              filterOption={(input: any, option: any) => {
                return !!competencies.find((record: Competence) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
              } }
              value={ competency_ids }
              onChange={ (recordIds: Array<string | number>) => {
                let ids = recordIds;

                // Select all logic
                if (recordIds.includes('all')) {
                  ids = competencies.map((competence: Competence) => competence.id);
                }

                this.setState({
                  activities: this.modifyValues(row.id, _.cloneDeep(activities), ids, 'competency_ids')
                });
              } }
            >
              { !_.isEmpty(competencies) &&
                <Select.Option key={ 'all' } value={ 'all' }>{ 'Select all' }</Select.Option>
              }
              { competencies.map( (competence: Competence) => (
                <Select.Option key={ competence.id } value={ competence.id }>{ competence.title }</Select.Option>
              )) }
            </Select>
          );
        }
      },
      {
        key: 'evidence',
        dataIndex: 'evidence',
        dependencyFeatures: ['evidence'],
        width: 100,
        ellipsis: true,
        title: this.renderColumnTitle(['Evidence'], 'Requires the user to upload a file as evidence of activity completion'),
        render: (evidence: number, row: Activity) => {
          return (
            <div className='d-f jc-c'>
              <Switch
                checkedChildren={ <CheckOutlined /> }
                unCheckedChildren={ <CloseOutlined /> }
                checked={ !!evidence }
                onChange={ (checked: boolean) => {
                  this.setState({
                    activities: this.modifyValues(row.id, _.cloneDeep(activities), Number(checked), 'evidence')
                  });
                } }
              />
            </div>
          );
        },
      },
      {
        key: 'document',
        dataIndex: 'document',
        width: 100,
        dependencyFeatures: ['document'],
        ellipsis: true,
        title: this.renderColumnTitle(['Document'], 'The user can upload a file to the row'),
        render: (document: number, row: Activity) => {
          return (
            <div className='d-f jc-c'>
              <Switch
                checkedChildren={ <CheckOutlined /> }
                unCheckedChildren={ <CloseOutlined /> }
                checked={ !!document }
                onChange={ (checked: boolean) => {
                  this.setState({
                    activities: this.modifyValues(row.id, _.cloneDeep(activities), Number(checked), 'document')
                  });
                } }
              />
            </div>
          );
        },
      },
      {
        key: 'comments',
        dataIndex: 'comments',
        dependencyFeatures: ['comments'],
        width: 110,
        ellipsis: true,
        title: this.renderColumnTitle(['Comments'], 'Allows the user to post comments against an activity'),
        render: (comments: number, row: Activity) => {
          return (
            <div className='d-f jc-c'>
              <Switch
                checkedChildren={ <CheckOutlined /> }
                unCheckedChildren={ <CloseOutlined /> }
                checked={ !!comments }
                onChange={ (checked: boolean) => {
                  this.setState({
                    activities: this.modifyValues(row.id, _.cloneDeep(activities), Number(checked), 'comments')
                  });
                } }
              />
            </div>
          );
        },
      },
      {
        key: 'required',
        dataIndex: 'required',
        width: 100,
        ellipsis: true,
        title: this.renderColumnTitle(['Required'], 'Required activities must be completed and cannot be skipped'),
        render: (required: number, row: Activity & { isNewActivity?: boolean }) => {
          return (
            <div className='d-f jc-c'>
              <Switch
                checkedChildren={ <CheckOutlined /> }
                unCheckedChildren={ <CloseOutlined /> }
                checked={ !!required }
                onChange={ (checked: boolean) => {
                  this.setState({
                    activities: this.modifyValues(row.id, _.cloneDeep(activities), Number(checked), 'required')
                  });
                } }
              />
            </div>
          );
        },
      },
      {
        key: 'actions',
        dataIndex: '',
        width: 90,
        fixed: 'right' as 'right',
        align: 'right' as 'right',
        title: () => {
          const actions: DropdownAction[] = [
            {
              node: <PlusOutlined />,
              onClick: () => {
                this.scrollToBottomIndicator = true;
                this.setState({
                  activities: _.concat(activities, getBlankState(+templateId))
                });
              }
            }
          ];

          if (activities.length > 1) {
            actions.push(
              {
                node: 'Change Order',
                onClick: () => this.setState({ showRearrangeModal: true }),
                disabled: !_.isEmpty(errors) ? ['Resolve errors before moving'] : false
              }
            );
          }

          return <Dropdown actions={ actions } />;
        },
        render: (__: any, row: Activity) => {

          const actions: DropdownAction[] = [
            {
              node: '',
              onClick: () => {}
            },
            {
              node: 'Create Child Activity',
              onClick: () => {
                const newRow = getBlankState(+templateId);
                this.setState({
                  activities: modifyNestedSetItem(row.id, { ...row, children: _.isEmpty(row?.children) ? [newRow] : _.concat(row.children, newRow) }, _.cloneDeep(activities))
                });
              }
            },
            {
              node: 'Remove',
              isDangerous: true,
              onClick: () => {
                this.setState({
                  activities: removeNestedSetItem(row.id, _.cloneDeep(activities))
                });
              }
            }
          ];

          return <Dropdown styles={{ paddingRight: -5 }} actions={ actions } />;
        }
      },
    ];

    // if a feature is not enabled in the configuration, the column associated with that feature is hidden
    const filteredColumns = this.filterColumns(columns, features);
    const scrollX = flattenSet(filteredColumns).reduce((acc: any, curr: any) => acc += curr.width || 150, 0);

    return (
      <div className='Layout-box' ref={ node => (this.component = node) }>
        <Table
          className={ 'ActivityTemplateActivity' }
          sticky
          size={ 'small' }
          columns={ filteredColumns }
          showSorterTooltip={ false }
          dataSource={ activities }
          pagination={ false }
          rowClassName={ (row: any) => {
            return !_.isEmpty(errors) && errors.includes(row?.key) ? 'error' : '';
          } }
          expandable={{
            expandedRowKeys: flattenSet(activities).map((activity: Activity) => activity.key),
            expandIcon: () => <></>,
            indentSize: 30,
          }}
          scroll={{
            x: scrollX,
            y: tableHeight
          }}
        />
        { dialogInfo?.type && this.renderDialog(dialogInfo.type) }
        { showRearrangeModal && this.renderMoveDialog(activities) }
      </div>
    );
  };

  renderFeatureSwitch = (features: Feature[], feature: Feature) => {
    return (
      <Switch
        checkedChildren={ <CheckOutlined /> }
        unCheckedChildren={ <CloseOutlined /> }
        checked={ !!feature.enabled }
        onChange={ (checked: boolean) => {
          this.setState({
            features: flattenSet(modifyNestedSetItem(feature.id, { ...feature, enabled: Number(checked) }, features))
          });
        } }
      />
    );
  };

  getNestedFeatures = (feature: Feature, features: Feature[]): any => {
    return {
      ...feature,
      key: feature.id,
      children: features
        .filter((_feature: Feature) => _feature?.parent_id === feature.id)
        .map((_feature: Feature) => this.getNestedFeatures(_feature, features))
    };
  };

  renderFeatures = (features: Feature[]): JSX.Element => {

    const nestedFeatures = features
      .filter((feature: Feature) => !feature?.parent_id)
      .map((feature: Feature) => this.getNestedFeatures(feature, features));

    return (
      <Table
        size={ 'small' }
        showHeader={ false }
        columns={ [
          {
            title: '',
            dataIndex: 'field',
            key: 'field',
            width: '30%',
            render: (__: any, feature: Feature) => {
              return (
                <div
                  className={ classNames('pX-20', {
                    'pL-50': !!feature?.parent_id
                  }) }
                >
                  <List.Item>
                    <List.Item.Meta
                      title={ feature.title }
                      description={ feature.description }
                    />
                    { feature?.type === 'FEATURE' &&
                      <div className="pL-50">
                        { this.renderFeatureSwitch(features, feature) }
                      </div>
                    }
                  </List.Item>
                </div>
              );
            },
          }
        ] }
        dataSource={ nestedFeatures }
        pagination={ false }
        expandable={{
          defaultExpandAllRows: true,
          showExpandColumn: false
        }}
      />
    );
  };

  render = () => {
    const {
      recordTitle,
      activities,
      isLoading,
      isSaving,
      originalActivities,
      originalFeatures,
      competencies,
      milestones,
      roles,
      showPreviewDialog,
      features,
    } = this.state;

    const errors = this.getErrors();
    const isModified = this.isModified();

    const actions: DropdownAction[] = [
      {
        node: 'Save',
        type: 'primary',
        onClick: this.handleSave,
        disabled: !_.isEmpty(errors) ? ['Error on form'] : !isModified ? ['No unsaved changes'] : false,
        isLoading: isSaving
      },
      {
        node: 'Cancel',
        onClick: () => this.setState({ activities: originalActivities, features: originalFeatures }),
        disabled: !isModified ? ['No unsaved changes'] : false,
      },
      {
        node: 'Show Preview',
        onClick: () => this.mounted && this.setState({ showPreviewDialog: true }),
      }
    ];

    return (
      <BlockingSpinner isLoading={ isLoading }>
        <Jumbotron
          content={ recordTitle }
          tabs={[
            {
              label: 'Activities',
              classes: !_.isEmpty(errors) ? 'text-danger' : this.isModified('activities') ? 'text-warning' : undefined,
              node: this.renderActivities(errors),
            },
            {
              label: 'Features',
              classes: this.isModified('features') ? 'text-warning' : undefined,
              node: this.renderFeatures(features),
            }
          ]}
          rightActions={ [
            {
              node: (
                <div className="d-if">
                  { (!_.isEmpty(errors) || isModified) &&
                    <Tooltip
                      placement="top"
                      title={ !_.isEmpty(errors) ? 'Please resolve all errors' : 'You have unsaved changes' }
                    >
                      <Button
                        type={ 'primary' }
                        className={ classNames('InfoBox__button InfoBox__button--info InfoBox__button--with-spacing', {
                          'InfoBox__button--warning': _.isEmpty(errors) && isModified
                        }) }
                        danger={ !_.isEmpty(errors) }
                      >
                        <Icon component={ InfoIcon } />
                      </Button>
                    </Tooltip>
                  }
                  <Dropdown
                    actions={ actions }
                  />
                </div>
              )
            }
          ] }
        />
        { showPreviewDialog && (
          <PreviewModal
            title={ `${recordTitle} Preview` }
            activities={ originalActivities }
            features={ originalFeatures }
            competencies={ competencies }
            milestones={ milestones }
            resource={ roles }
            onClose={ () => this.mounted && this.setState({ showPreviewDialog: false }) }
          />
        ) }
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(ActivityTemplateActivity), 'access_admin_templates'));