// Libs
import * as React from "react";
import _ from 'lodash';

// Components
import { Form, Modal, Select } from 'antd';

// Interfaces
import { BulkOperationElement } from 'components/basic-list/BasicList';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

const API: Api = new Api();
const { Option } = Select;

interface Props {
  runEndpoint: string;
  checkEndpoint: string;
  elements: { [key: string]: BulkOperationElement };
  entities: any[];
  skipNotifications?: boolean;
  onClose: () => void;
  onSuccess: (response: any) => void;
};

interface State {
  isApplyingOperation: boolean;
  runData: { [key: string]: number[] };
  errorResponse: any[];
};

class BulkAssignModal extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    isApplyingOperation: false,
    runData: {},
    errorResponse: [],
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleApply = async () => {
    const { runEndpoint, checkEndpoint, entities, skipNotifications, onClose, onSuccess } = this.props;
    const { runData } = this.state;

    try {
      await new Promise((resolve) => this.setState({ isApplyingOperation: true }, () => resolve(null) ));

      const response = await API.post(checkEndpoint, {
        config: { ...runData, skip_notifications: !!skipNotifications },
        entities: entities
      });

      const hasError = response.find((_error: any) => _error.status === 'error');

      if (!!hasError) {
        const runResponse = await API.post(runEndpoint, {
          config: { ...runData, skip_notifications: !!skipNotifications },
          entities: entities
        });

        Notification('success', `Bulk Operation Applied`);
        onClose();
        onSuccess(runResponse);
      }
    } catch (error) {
      console.error('Error: ', error);
      Notification('error', 'Bulk Operation Failed');
      this.setState({
        errorResponse: error.data
      });
    } finally {
      this.mounted && this.setState({
        isApplyingOperation: false
      });
    }
  };

  renderForm = () => {
    const { elements } = this.props;
    const { runData } = this.state;

    return Object.keys(elements).map((key: string) => {
      const element = elements[key];
      switch (element.type) {
        case 'select':
          let elementOptions = element.options;

          // Get dependent options
          if (element?.dependency_element) {
            const selectedDependencies = _.has(runData, element.dependency_element) ? runData[element.dependency_element] : [];

            if (!_.isEmpty(selectedDependencies)) {
              elementOptions = elementOptions.filter(option => !!option?.parent_id && selectedDependencies.includes(option?.parent_id));
            }
          }

          return (
            <Form.Item
              label={ element?.title }
              key={ element?.reference }
            >
              <Select
                maxTagCount={ 'responsive' }
                placeholder={ 'All' }
                showSearch
                autoFocus
                allowClear
                dropdownMatchSelectWidth={ false }
                filterOption={(input: string, option: any) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1;
                }}
                onChange={(recordIds: Array<number>) => {
                  this.setState({
                    runData: { ...runData, [element.reference]: recordIds }
                  });
                }}
                value={ _.has(runData, element.reference) ? runData[element.reference] : undefined }
              >
                {elementOptions.map((option: any) => (
                  <Option key={`${option.title}_${option.id}`} value={ option.id }>
                    { option.title }
                  </Option>
                ))}
              </Select>
            </Form.Item>
          );
      }
    });
  };

  validateFormElements = () => {
    const { elements } = this.props;
    const { runData } = this.state;
    let errors: { [key: string]: string } = {};

    Object.keys(elements).forEach((key: string) => {
      const element = elements[key];
      if (!_.has(runData, element.reference) || (_.isArray(runData[element.reference]) && _.isEmpty(runData[element.reference])) || !runData[element.reference]) {
        errors[element.reference] = element.reference;
      }
    });

    return !_.isEmpty(errors);
  };

  render = () => {
    const { isApplyingOperation, errorResponse } = this.state;
    const { entities, onClose } = this.props;
    return (
      <Modal
        visible
        centered
        title={ 'Add Resource' }
        maskClosable={!isApplyingOperation}
        okText={ 'Add' }
        onOk={ this.handleApply }
        onCancel={() => this.setState({
          runData: {}
        }, onClose)}
        cancelButtonProps={{
          disabled: isApplyingOperation
        }}
        okButtonProps={{
          disabled: this.validateFormElements(),
          loading: isApplyingOperation,
        }}
      >
        { !_.isEmpty(errorResponse) && (
          <div className='d-f fxd-c jc-c mT-10 mB-10'>
            <div className="fw-600 mB-10">We could not run the transition because:</div>
            { errorResponse.map((message: any, key: number) => (
              <ul className="mL-5" key={ `error_group_${key}` }>
                <li key={ `error_title_${key}` }>
                  { message.title }
                  <ul>
                    { message.errors.map((error: string, index: number) => (
                      <li className="ant-text-danger" key={ `error_message_${key}_${index}` }>{ error }</li>
                    )) }
                  </ul>
                </li>
              </ul>
            )) }
          </div>
        ) }
        <Form layout="vertical">
          { this.renderForm() }
        </Form>
      </Modal>
    );
  };
};

export default BulkAssignModal;
