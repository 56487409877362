// Libs
import * as React from 'react';
import _ from 'lodash';

// Components
import CoverModal from 'components/cover-modal';
import { Input, Button, Table, Select, Transfer } from 'antd';
import Switch from 'components/switch/Switch';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Utils
import { nestedSet, flattenSet } from 'utils/utils';

// Interfaces
import { IInsight, IFilter, IModuleJoin, IColumn } from 'components/insight/Insight.interfaces';

const { Search } = Input;
const API: Api = new Api();

interface Props {
  insight: IInsight;
  clientId: number;
  onSave(filters: IFilter[]): void;
  onClose(): void;
};

interface State {
  filters: IFilter[];
  keywordFilter: string | null;
  moduleFilter: string | null;
  shouldReduceFilter: boolean;
  selectedFilters: IFilter[];
  targetKeys: string[];
  isFetching: boolean;
};

class AddFilters extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    filters: [],
    keywordFilter: null,
    moduleFilter: null,
    shouldReduceFilter: false,
    selectedFilters: [],
    targetKeys: [],
    isFetching: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.fetchFilters();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchFilters = async () => {
    const { clientId, insight } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const filters = await API.post(`client/${clientId}/insight/builder/filters`, {
        data: insight
      });

      this.mounted && this.setState({
        filters: filters
      });
    } catch (error) {
      Notification('error', 'Failed to fetch filters', 'Failed');
    } finally {
      this.mounted && this.setState({ isFetching: false });
    }
  };

  handleSave = (filters: IFilter[], targetKeys: string[]) => {
    this.props.onSave(filters.filter((column: IFilter) => targetKeys.includes(column.id)));
  };

  filter = (
    insight: IInsight,
    dataSource: IFilter[],
    moduleFilter: string | null,
    keywordFilter: string | null,
    shouldReduceFilter: boolean
  ) => {
    return dataSource.filter((filter: IFilter) => {
      if (!!moduleFilter) {
        return filter.module_id === moduleFilter;
      }
      return true;
    })
    .filter((filter: IFilter) => {
      if (!!keywordFilter) {
        return filter.title.toLowerCase().includes(keywordFilter.toLowerCase());
      }
      return true;
    })
    .filter((filter: IFilter) => {
      if (shouldReduceFilter) {
        return insight.columns.some((column: IColumn) => filter.config.related_columns.includes(column.column));
      }
      return true;
    });
  };

  renderTransfer = (
    insight: IInsight,
    dataSource: any[],
    targetKeys: string[],
    moduleFilter: string | null,
    keywordFilter: string | null,
    shouldReduceFilter: boolean,
  ) => {

    const filteredDataSource = this.filter(insight, dataSource, moduleFilter, keywordFilter, shouldReduceFilter);
    const availableSourceItems = filteredDataSource.length - filteredDataSource.filter((_filter: IFilter) => targetKeys.includes(_filter.id)).length;

    return (
      <Transfer
        className='InsightModalTransfer h-100p'
        listStyle={{ width: '100%', height: '100%' }}
        titles={ [`Available (${availableSourceItems})`, `Selected (${targetKeys.length})`] }
        dataSource={ dataSource }
        targetKeys={ targetKeys }
        onChange={ (newTargetKeys: string[]) => {
          this.setState({
            targetKeys: newTargetKeys
          });
        } }
        render={ (filter: IFilter) => {
          return filter.title;
        } }
      >
        {
          ( {
            direction,
            onItemSelectAll,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
          } ) => {

            const rowSelection = {
              getCheckboxProps: (item: any) => ({ disabled: listDisabled || item.disabled }),
              onSelectAll(selected: any, selectedRows: IFilter[]) {

                const treeSelectedKeys = selectedRows
                  .filter((filter: IFilter) => {
                    if (!!moduleFilter && direction === 'left') {
                      return filter.module_id === moduleFilter;
                    }
                    return true;
                  })
                  .filter((filter: IFilter) => {
                    if (!!keywordFilter && direction === 'left') {
                      return filter.title.toLowerCase().includes(keywordFilter.toLowerCase());
                    }
                    return true;
                  })
                  .map((filter: IFilter) => filter.id);

                const diffKeys = selected ? _.difference(treeSelectedKeys, listSelectedKeys) : _.difference(listSelectedKeys, treeSelectedKeys);

                onItemSelectAll(diffKeys, selected);
              },
              onSelect(item: any, selected: any) {
                onItemSelect(item.key, selected);
              },
              selectedRowKeys: listSelectedKeys,
            };

            const displayType: string = direction === 'left' ? 'source' : 'target';
            const columns = direction === 'left' ? [
              {
                dataIndex: 'title',
                title: 'Title',
                ellipsis: true,
                render: (__: any, filter: IFilter) => <span className="us-n">{ filter.title }</span>
              },
              {
                dataIndex: 'module_title',
                title: 'Module',
                render: (__: any, filter: IFilter) => <span className="us-n">{ filter.module_title }</span>
              }
            ] : [
              {
                dataIndex: 'title',
                title: 'Title',
                ellipsis: true,
                render: (__: any, filter: IFilter) => <span className="us-n">{ filter.title }</span>
              },
              {
                dataIndex: 'module_title',
                title: 'Module',
                render: (__: any, filter: IFilter) => <span className="us-n">{ filter.module_title }</span>
              }
            ];

            switch (displayType) {
              case 'source':
                return (
                  <Table
                    sticky
                    size="small"
                    pagination={ false }
                    dataSource={ filteredDataSource.filter((filter: IFilter) => !targetKeys.includes(filter.id)) }
                    columns={ columns }
                    rowSelection={ rowSelection }
                    onRow={ ({ id }, idx) => ({
                      index: idx,
                      onClick: () => {
                        onItemSelect(id, !listSelectedKeys.includes(id));
                      }
                    }) }
                    scroll={{
                      x: columns.length * 200,
                      y: 350,
                    }}
                  />
                );
              case 'target':
                return (
                  <Table
                    sticky
                    size="small"
                    pagination={ false }
                    columns={ columns }
                    rowSelection={ rowSelection }
                    dataSource={ dataSource.filter((filter: IFilter) => targetKeys.includes(filter.id)) }
                    onRow={ ({ id }, idx) => ({
                      index: idx,
                      onClick: () => {
                        onItemSelect(id, !listSelectedKeys.includes(id));
                      }
                    }) }
                    scroll={{
                      x: columns.length * 200,
                      y: 350,
                    }}
                  />
                );
            }
          }
        }
      </Transfer>
    );
  };

  render = () => {
    const { insight, onClose } = this.props;
    const { filters, targetKeys, keywordFilter, moduleFilter, shouldReduceFilter, isFetching } = this.state;
    const uniqueFilteredFilters = _.uniqBy(filters, 'module_id');
    const dataSource = nestedSet(filters.filter((filter: IFilter) => !insight.filters.some((_filter: IFilter) => _filter.id === filter.id)));

    return (
      <CoverModal
        isLoading={ isFetching }
        showCloseIcon={ false }
        style={{ width: '90vw', maxWidth: 1300, height: '80vh', maxHeight: 800 }}
        middleContent={ 'Filters' }
        onClose={ onClose }
      >
        <div className='d-f fxd-c pX-50 h-100p'>
          <div className="d-f">
            <div>
              <Search
                style={{ width: 200 }}
                allowClear
                placeholder="Search"
                disabled={ _.isEmpty(filters) }
                onSearch={ (value: string) => this.setState({
                  keywordFilter: !!value ? value : null,
                  selectedFilters: [],
                }) }
              />
            </div>
            <div className='mL-10'>
              <Select
                allowClear
                style={{ width: 170 }}
                disabled={ _.isEmpty(uniqueFilteredFilters) }
                placeholder={ 'Filter by Module' }
                dropdownMatchSelectWidth={ false }
                onClear={ () => {
                  this.setState({
                    moduleFilter: null,
                    selectedFilters: [],
                  });
                } }
                onChange={ (module_id: string) => {
                  this.setState({
                    moduleFilter: module_id,
                    selectedFilters: [],
                  });
                } }
              >
                { uniqueFilteredFilters
                  .map((filter: IFilter, index: number) => (
                    <Select.Option key={ index } value={ filter.module_id }>{ filter.module_title }</Select.Option>
                  ))
                }
              </Select>
            </div>
            <Switch
              className='mL-10'
              wrapperClassName='mL-10'
              size={ 'small' }
              wrapper
              checked={ shouldReduceFilter }
              onChange={ (checked: boolean) => {
                this.setState({
                  shouldReduceFilter: checked,
                  selectedFilters: [],
                });
              }}
            >
              Reduce to Selected Columns
            </Switch>
          </div>
          <div className="d-f fxd-c fxg-1 mT-10">
            { this.renderTransfer(insight, dataSource, targetKeys, moduleFilter, keywordFilter, shouldReduceFilter) }
          </div>
          <div className='mB-40 pT-20 ta-r'>
            <Button
              onClick={ () => this.props.onClose() }
            >
              Cancel
            </Button>
            <Button
              type='primary'
              className="mL-5"
              disabled={ _.isEmpty(targetKeys) }
              onClick={ () => this.handleSave(filters, targetKeys) }
            >
              Add
            </Button>
          </div>
        </div>
      </CoverModal>
    );
  };
};

export default AddFilters;
