// Libs
import * as React from "react";
import _ from 'lodash';

// Components
import { Button, Form, Tooltip, TreeSelect, Upload } from 'antd';
import CoverModal from 'components/cover-modal';

// Icons
import { InboxOutlined } from "@ant-design/icons";

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';
import { findFirst } from 'utils/utils';

interface Props {
  clientId?: number;
  runEndpoint: string;
  checkEndpoint: string;
  entities: any[];
  onClose: () => void;
  onSuccess: (response: any) => void;
};

interface State {
  bundle?: string;
  types: string[];
  folders: any;
  fileList: any[];
  isLoading: boolean;
  isUploading: boolean;
  folder_id: number | null;
  errorResponse: any[];
};

const API: Api = new Api();

class UploadDocumentsModal extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    types: [],
    folders: null,
    fileList: [],
    isUploading: false,
    isLoading: false,
    folder_id: null,
    errorResponse: [],
  };

  componentDidMount = async () => {
    const { clientId, entities } = this.props;

    this.mounted = true;

    try {
      if (!clientId) throw new Error('Failed');

      const bundles: string[] = [];
      const types: string[] = [];

      entities.forEach((entity: any) => {
        bundles.push(entity.bundle);
        types.push(entity.type);
      });

      await new Promise((resolve) => this.setState({ isLoading: true, bundle: _.uniq(bundles).pop(), types: _.uniq(types) }, () => resolve(null)));

      const folders = await API.get(`client/${clientId}/bulk-operations/upload-document/available-folders`, {
        bundle: _.uniq(bundles).pop(),
        types: _.uniq(types)
      });

      this.mounted && this.setState({
        folders: folders
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleUpload = async (folder: any) => {
    const { fileList } = this.state;
    const { checkEndpoint, runEndpoint, entities, onClose, onSuccess } = this.props;

    try {
      await new Promise((resolve) => this.setState({ isUploading: true }, () => resolve(null) ));

      const form = new FormData();

      form.append('config[folder]', folder.id);
      form.append('entities', JSON.stringify(entities));

      fileList.forEach((file: any) => {
        form.append('config[documents][]', file.originFileObj);
      });

      const response = await API.post(checkEndpoint, form);

      const hasError = response.find((_error: any) => _error.status === 'error');
      if (!hasError) {
        const runResponse = await API.post(runEndpoint, form);

        Notification('success', `Successfully uploaded documents`);
        onClose();
        onSuccess(runResponse);
      }

      onClose();
    } catch (error) {
      console.error('Error: ', error);
      Notification('error', 'Failed to upload documents');
      this.setState({
        errorResponse: error.data
      });
    } finally {
      this.mounted && this.setState({
        isUploading: false
      });
    }
  };

  render = () => {
    const { onClose } = this.props;
    const { errorResponse, folders, fileList, isUploading, isLoading, folder_id } = this.state;
    const mappedFolders: any = [];

    if (folders) {
      Object.keys(folders).forEach(key => {
        mappedFolders.push({
          'id': folders[key].reference,
          'key': folders[key].reference,
          'value': folders[key].reference,
          'title': folders[key].title,
          'context': null,
          'selectable': true,
          'disabled': false,
          'children': folders[key].children ? folders[key].children : [],
        });
      });
    }

    return (
      <CoverModal
        style={{ minWidth: '800px', minHeight: '500px', maxHeight: '90vh' }}
        middleContent={ 'Upload' }
        isLoading={ isLoading }
        onClose={ () => !isUploading && onClose() }
      >
        { !_.isEmpty(errorResponse) && (
          <div className='d-f fxd-c jc-c mT-10 mB-10'>
            <div className="fw-600 mB-10">We could not run the transition because:</div>
            { errorResponse.map((message: any, key: number) => (
              <ul className="mL-5" key={ `error_group_${key}` }>
                <li key={ `error_title_${key}` }>
                  { message.title }
                  <ul>
                    { message.errors.map((error: string, index: number) => (
                      <li className="ant-text-danger" key={ `error_message_${key}_${index}` }>{ error }</li>
                    )) }
                  </ul>
                </li>
              </ul>
            )) }
          </div>
        ) }
        <Form
          className="pL-100 pR-100"
          name="upload"
          layout="vertical"
          autoComplete="off"
          onFinish={ (fields: any) => {
            const folder = findFirst({ children: mappedFolders }, 'children', { key: fields.folder });
            if (folder) {
              this.handleUpload(folder);
            }
          } }
        >
          <Form.Item
            name="folder"
            label="Folder"
          >
            <TreeSelect
              style={{ width: '100%' }}
              treeDefaultExpandAll
              treeData={ mappedFolders }
              onChange={ (folder_id: any) => this.setState({ folder_id: folder_id }) }
              placeholder="Please select"
            />
          </Form.Item>
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={ (e: any) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            } }
            rules={[
              {
                required: true,
                message: 'Required'
              },
            ]}
          >
            <Upload.Dragger
              multiple
              fileList={ fileList }
              beforeUpload={ () => false }
              onChange={ ({ fileList }) => this.setState({ fileList }) }
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Support for a single or bulk upload.</p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item className="ta-r">
            { _.isEmpty(fileList) || !folder_id ? (
              <Tooltip
                placement="left"
                title={ 'Please select Folder and File(s) to upload.' }
              >
                <Button type="primary" loading={ isUploading } disabled>
                  Upload
                </Button>
              </Tooltip>
            ) : (
              <Button type="primary" loading={ isUploading } disabled={ isUploading } htmlType="submit">
                Upload
              </Button>
            ) }
          </Form.Item>
        </Form>
      </CoverModal>
    );
  };
};

export default UploadDocumentsModal;
