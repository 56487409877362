// Libs
import * as React from 'react';
import { connect } from 'react-redux';

// Services
import { Api } from 'services/api';

// Interfaces
import { RecordFormEntity, AuditEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { UserPermissions } from 'types/permissions';

// Components
import AuditList from 'components/audit-list';
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';

const API: Api = new Api();

interface Props {
  id?: number,
  type: string;
  entity: string;
  client_id?: number;
  permissions?: UserPermissions;
  record?: RecordFormEntity;
  pure?: boolean;
};

interface State {
  record: RecordFormEntity | null;
  profile_id: number | null;
  audit_logs: AuditEntity[];
  isFetching: boolean;
};

class AuditView extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    record: null,
    profile_id: null,
    audit_logs: [],
    isFetching: true,
  };

  componentDidMount = async () => {
    const { type, entity, client_id, record } = this.props;
    this.mounted = true;

    if (!client_id) return;

    try {

      if (!record) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const audit_logs = await API.getAuditLog(client_id, type, record.id, entity);

      this.mounted && this.setState({
        record: record,
        audit_logs: audit_logs,
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderList = (client_id: number, permissions: any, audit_logs: AuditEntity[]) => {
    return <AuditList client_id={ client_id } permissions={ permissions } items={ audit_logs } />;
  };

  render = () => {
    const { client_id, permissions, pure } = this.props;
    const { audit_logs, isFetching } = this.state;

    if (!client_id) return <></>;

    return (
      <BlockingSpinner isLoading={ isFetching }>
        { pure ? (
            <div>
              { this.renderList(client_id, permissions, audit_logs || []) }
            </div>
          ) : (
            <Jumbotron
              title={ this.props.record?.title }
              content={ 'Audit' }
              tabs={[
                {
                  label: '',
                  node: this.renderList(client_id, permissions, audit_logs || [])
                },
              ]}
            />
          )
        }
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(AuditView);
