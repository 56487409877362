// Libs
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Table } from 'antd';
import Badge, { getBadgeType } from 'components/badge';

// Interfaces
import { DynamicField } from 'components/form/field/dynamic/Dynamic.interface';

// View
import CreateRecordView from 'views/common/CreateRecordView';

// Services
import { getFormatedDate, getUserSetting } from 'services/settings';
import { Api } from 'services/api';

// Icons
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";

const API: Api = new Api();

interface Props {
  clientId: number;
  dynamicField: DynamicField;
  isLocked: boolean;
  isPreviewing: boolean;
  onCreate(field: DynamicField): void;
};

interface State {
  showCreate: boolean;
  isCreateLoading: boolean;
};

class Actions extends Component<Props, State> {

  state: State = {
    showCreate: false,
    isCreateLoading: false,
  };

  render = () => {
    const { dynamicField, isLocked, onCreate, clientId, isPreviewing } = this.props;
    const { showCreate, isCreateLoading } = this.state;
    const canCreate = _.has(dynamicField, 'action_list.config.can_create') ? dynamicField.action_list.config.can_create : false;
    const disabled = isLocked || !canCreate || isPreviewing;

    return (
      <>
        <div className="d-f jc-sb mB-5">
          <label className="fw-500">
            Actions
          </label>
          { isCreateLoading ? (
            <LoadingOutlined className="fsz-def text-ant-default" />
            ) : (
            <PlusCircleOutlined
              className={ classNames('fsz-def text-ant-default', {
                'text-ant-disabled': disabled,
                'cur-na': disabled,
              }) }
              onClick={ () => !disabled && this.setState({ showCreate: true, isCreateLoading: true }) }
            />
          ) }
        </div>
        { _.has(dynamicField, 'action_list') && dynamicField.action_list &&
          <Table
            size={ 'small' }
            columns={ [
              {
                key: 'action',
                dataIndex: 'action',
                title: 'Action',
                render: (__: any, record: any) => {
                  if (_.has(record, 'action') && record.action) {
                    return (
                      <RouterLink className='primaryColor' to={ record?.action.path }>
                        { record?.action.title }
                      </RouterLink>
                    );
                  }
                  return '-';
                },
                sorter: false,
                ellipsis: true,
              },
              {
                key: 'assignee',
                dataIndex: 'assignee',
                title: 'Assignee',
                render: (__: any, record: any) => {
                  if (_.has(record, 'assignee') && record.assignee) {
                    return record.assignee;
                  }
                  return '-';
                },
              },
              {
                key: 'priority',
                dataIndex: 'priority',
                title: 'Priority',
                render: (__: any, record: any) => {
                  if (_.has(record, 'priority') && record.priority) {
                    return record.priority;
                  }
                  return '-';
                },
              },
              {
                key: 'completion_date',
                dataIndex: 'completion_date',
                title: 'Planned Completion',
                render: (__: any, record: any) => {
                  if (_.has(record, 'completion_date') && record.completion_date) {
                    return getFormatedDate(record.completion_date, getUserSetting('date_format'));
                  }
                  return '-';
                },
                sorter: false,
                ellipsis: true,
              },
              {
                key: 'actual_date',
                dataIndex: 'actual_date',
                title: 'Actual Completion',
                render: (__: any, record: any) => {
                  if (_.has(record, 'actual_date') && record.actual_date) {
                    return getFormatedDate(record.actual_date, getUserSetting('date_format'));
                  }
                  return '-';
                },
                sorter: false,
                ellipsis: true,
              },
              {
                key: 'stage',
                dataIndex: 'stage',
                title: 'Stage',
                render: (__: any, record: any) => {
                  if (_.has(record, 'stage') && !!record.stage) {
                    return <Badge type={ getBadgeType(record.stage.context) } text={ _.startCase(_.toLower(record.stage.title)).split('_').join(' ') } />;
                  }
                  return '-';
                },
                sorter: false,
                ellipsis: true,
              },
            ] }
            dataSource={ dynamicField.action_list.data.map((row: any, index: number) => {
              return {
                ...row,
                key: index,
              };
            }) }
            pagination={ false }
          />
        }
        { showCreate &&
          <CreateRecordView
            entity={ _.kebabCase(_.has(dynamicField, 'action_list.config.create_option.bundle') && dynamicField.action_list.config.create_option.bundle) }
            type={ _.kebabCase(_.has(dynamicField, 'action_list.config.create_option.type') && dynamicField.action_list.config.create_option.type) }
            parent_id={ _.has(dynamicField, 'action_list.config.entity_parent.id') && dynamicField.action_list.config.entity_parent.id }
            parent_bundle={ _.has(dynamicField, 'action_list.config.entity_parent.bundle') && dynamicField.action_list.config.entity_parent.bundle }
            parent_type={ _.has(dynamicField, 'action_list.config.entity_parent.type') && dynamicField.action_list.config.entity_parent.type }
            params={{
              component_id: dynamicField.id
            }}
            onReady={ () => this.setState({ isCreateLoading: false }) }
            onClose={ () => this.setState({ showCreate: false }) }
            onCreated={ async (_record: any) => {
              try {
                const actionList = await API.get(`client/${clientId}/field/dynamic/${dynamicField?.action_list?.config?.field_id}/actions`, {
                  entity_id: dynamicField?.action_list?.config?.entity_parent?.id,
                  entity_type: dynamicField?.action_list?.config?.entity_parent?.type,
                  entity_bundle: dynamicField?.action_list?.config?.entity_parent?.bundle,
                  component_id: dynamicField?.id
                });

                onCreate(_.set(_.cloneDeep(dynamicField), ['action_list', 'data'], actionList));

              } finally {
                this.setState({
                  showCreate: false,
                });
              }
            } }
          />
        }
      </>
    );
  };
};

export default Actions;
