// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { has } from 'lodash';

// Services
import { Api } from 'services/api';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Containers
import ContractContainer from 'containers/supplier/suppliers/ContractContainer';

// Views
import Suppliers from 'views/supplier/Suppliers';
import SupplierRecord from 'views/supplier/suppliers/SupplierRecord';
import ServiceSpecifications from 'views/supplier/suppliers/ServiceSpecifications';
import SupplierDocuments from 'views/supplier/suppliers/SupplierDocuments';
import SupplierResources from 'views/supplier/suppliers/SupplierResources';
import SupplierGroups from 'views/supplier/suppliers/SupplierGroups';
import SupplierAudit from 'views/supplier/suppliers/SupplierAudit';
import SupplierWorkflow from 'views/supplier/suppliers/SupplierWorkflow';
import SupplierComments from 'views/supplier/suppliers/SupplierComments';
import DashboardView from 'views/common/DashboardView';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { RecordFormEntity } from 'types/entities';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbs,
  setBreadcrumbsLoading,
} from 'store/UI/ActionCreators';

// Utils
import Console from 'utils/console';

interface Props {
  client_id: number;
  permissions: any;
  match: { params: Record<string, any> };
  location: {
    pathname: string;
  };
  routes: any[];
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  isFetching: boolean;
  record: RecordFormEntity | null;
};

const API: Api = new Api();

class SupplierContainer extends React.Component<Props> {

  mounted: boolean = false;
  state: State = {
    isFetching: false,
    record: null
  };

  componentDidMount = () => {
    this.mounted = true;
    // Get record
    this.getRecord();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (prevProps.location.pathname.includes('/contracts/') && !this.props.location.pathname.includes('/contracts/')) {
        this.getRecord(false, true);
      } else if (prevProps.match.params.record_id !== this.props.match.params.record_id) {
        this.getRecord(false, false);
      }
    }
  };

  componentWillUnmount = () => {
    this.props.setSecondarySidebarRoutes([]);
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getRecord = async (silent: boolean = false, rerenderSidebar: boolean = false) => {
    if (!this.props.match.params.record_id) {
      this.props.setSecondarySidebarRoutes([]);
    } else {
      try {

        if (this.props.match.params.record_id && this.props.client_id) {

          if (!silent) {
            this.props.setBreadcrumbsLoading(true);
            await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));
          }

          if (rerenderSidebar) {
            this.props.setSecondarySidebarRoutes([]);
          }

          const record = await API.get(`client/${this.props.client_id}/company/supplier/${this.props.match.params.record_id}`);

          this.setRecord(record, silent);
        }
      } catch (error) {
        Console.error(error);
      } finally {
        this.props.setBreadcrumbsLoading(false);
        this.setState({
          isFetching: false
        });
      }
    }
  };

  setRecord = (record: RecordFormEntity, silent?: boolean) => {
    const showLoadingState = this.state.record?.title !== record.title;

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(true);
    }

    if (has(record, 'breadcrumbs')) {
      this.props.setBreadcrumbs(record.breadcrumbs, false);
    }

    if (has(record, 'permissions')) {
      const { permissions, new_content } = record;

      this.props.setSecondarySidebarRoutes([
        { title: 'Supplier', path: `/suppliers/suppliers/${record.id}/record`, isDisabled: !hasPermission(permissions, 'company_supplier_view') },
        { title: 'Dashboards', path: `/suppliers/suppliers/${record.id}/dashboards`, isIndented: true, isDisabled: !hasPermission(permissions, 'company_supplier_dashboard_view') },
        { title: 'Service Specifications', path: `/suppliers/suppliers/${record.id}/service-specifications`, isIndented: true, isDisabled: !hasPermission(permissions, 'company_supplier_service_specification_view') },
        { title: 'Documents', path: `/suppliers/suppliers/${record.id}/documents`, isIndented: true, isDisabled: !hasPermission(permissions, 'company_supplier_document_view'), hasUpdates: !!new_content.documents },
        { title: 'Comments', path: `/suppliers/suppliers/${record.id}/comments`, isIndented: true, isDisabled: !hasPermission(permissions, 'company_supplier_comment_view'), hasUpdates: !!new_content.comments },
        { title: 'Resources', path: `/suppliers/suppliers/${record.id}/resources`, isIndented: true, isDisabled: !hasPermission(permissions, 'company_supplier_resource_view'), hasUpdates: !!new_content.resources },
        { title: 'Groups', path: `/suppliers/suppliers/${record.id}/groups`, isIndented: true, isDisabled: !hasPermission(permissions, 'company_supplier_group_view') },
        { title: 'Workflow', path: `/suppliers/suppliers/${record.id}/workflow`, isIndented: true, isDisabled: !hasPermission(permissions, 'company_supplier_workflow_view') },
        { title: 'Audit', path: `/suppliers/suppliers/${record.id}/audit`, isIndented: true, isDisabled: !hasPermission(permissions, 'company_supplier_audit_view') },
      ]);
    }

    this.setState({
      record: record,
      isFetching: false
    });

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(false);
    }
  };

  render = () => {
    const { isFetching, record } = this.state;
    const permissions = record && has(record, 'permissions') ? record.permissions : this.props.permissions;
    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Switch>

          <Redirect exact from="/suppliers/suppliers/:record_id" to="/suppliers/suppliers/:record_id/record" />

          <Route exact path="/suppliers/suppliers" render={ () => {
            return (
              <RestrictionWrapper restricted={ !hasPermission(permissions, 'company_supplier_view_list') }>
                <Suppliers />
              </RestrictionWrapper>
            );
          } } />

          { record &&
            <Route exact path="/suppliers/suppliers/:record_id/record" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'company_supplier_view') }>
                  <SupplierRecord record={ record } setRecord={ this.setRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/suppliers/suppliers/:record_id/service-specifications" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'company_supplier_service_specification_view') }>
                  <ServiceSpecifications record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/suppliers/suppliers/:record_id/documents" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'company_supplier_document_view') }>
                  <SupplierDocuments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/suppliers/suppliers/:record_id/audit" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'company_supplier_audit_view') }>
                  <SupplierAudit record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/suppliers/suppliers/:record_id/resources" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'company_supplier_resource_view') }>
                  <SupplierResources record={ record } getRecord={ this.getRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route path="/suppliers/suppliers/:record_id/groups" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'company_supplier_group_view') }>
                  <SupplierGroups record={ record } getRecord={ this.getRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/suppliers/suppliers/:record_id/workflow" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'company_supplier_workflow_view') }>
                  <SupplierWorkflow record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/suppliers/suppliers/:record_id/comments" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'company_supplier_comment_view') }>
                  <SupplierComments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route path="/suppliers/suppliers/:record_id/dashboards" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'company_supplier_dashboard_view') }>
                  <Route
                    exact
                    path="/suppliers/suppliers/:record_id/dashboards"
                    render={ () => {
                      return (
                        <DashboardView record={ record } />
                      );
                    } }
                  />
                  <Route
                    exact
                    path="/suppliers/suppliers/:record_id/dashboards/:dashboard_id"
                    render={ (props: RouteComponentProps | any) => {
                      return (
                        <DashboardView record={ record } dashboardId={ props.match?.params?.dashboard_id } />
                      );
                    } }
                  />
                </RestrictionWrapper>
              );
            } } />
          }

          {/* Supplier Contract */}

          <Route path="/suppliers/suppliers/:record_id/contracts/:contract_id" component={ ContractContainer } />

          <Route render={ () => <NotFound /> } />

        </Switch>
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    routes: store.UIState.secondarySidebarRoutes
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierContainer);
