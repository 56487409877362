// Libs
import React from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import classNames from 'classnames';

// Components
import Jumbotron from 'components/jumbotron';
import BlockingSpinner from 'components/blocking-spinner';
import DragSortingList from 'components/drag-sorting-list';
import Dropdown from 'components/dropdown';
import { RestrictionHoC } from 'components/restriction';
import { Button, Form, Input, Modal, Popconfirm, Popover, Tooltip } from 'antd';
import { TwitterPicker, ColorResult } from '@hello-pangea/color-picker';

// Icons
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';

// Services
import { Api } from 'services/api';
import { getFormatedDate } from 'services/settings';
import Notification from 'services/notification';

// Actions
import { setBreadcrumbs, setBreadcrumbsLoading } from 'store/UI/ActionCreators';

// Utils
import { arrayMoveImmutable } from 'utils/formSetup';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserPermissions } from 'types/permissions';
import { Breadcrumb } from 'store/UI/State.interface';
import { TableType } from 'components/drag-sorting-list/DragSortingList.interfaces';
import { KpiPriority } from 'components/kpi-library/KpiLibrary.interfaces';

// Styles
import './KpiLibrary.scss';

const API: Api = new Api();

interface AddDialogInfo {
  title: string;
  description: string;
  color?: string;
  display: boolean;
};

interface EditDialogInfo {
  title: string;
  description: string;
  color?: string;
  originalPriority: KpiPriority;
  display: boolean;
};

interface Props {
  client_id?: number;
  match: { params: Record<string, any> };
  permissions: UserPermissions;
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  prioritySetTitle: string;
  priorities: KpiPriority[];
  addDialogInfo: AddDialogInfo | null;
  editDialogInfo: EditDialogInfo | null;
  isFetching: boolean;
  isSaving: boolean;
  isLoading: boolean;
};

class PrioritySet extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    prioritySetTitle: '',
    priorities: [],
    addDialogInfo: null,
    editDialogInfo: null,
    isFetching: false,
    isSaving: false,
    isLoading: false,
  };

  componentDidMount = async () => {
    const { client_id } = this.props;

    const { priority_set_id } = this.props.match.params;

    this.mounted = true;

    try {

      this.props.setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));
      const response = await API.get(`client/${client_id}/kpi-library/priority-set/${priority_set_id}/priority`);

      this.props.setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Workplace Services', path: '/workplace-services' },
        { title: 'KPI Library', path: '/workplace-services/kpi-library' },
        { title: response.title, path: null }
      ], false);

      this.mounted && this.setState({
        prioritySetTitle: response.title,
        priorities: response.kpi_priorities,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  onCreate = async (priority: Partial<KpiPriority>): Promise<void> => {
    try {
      await new Promise((resolve) => this.setState({ isSaving: true }, () => resolve(null)));

      const response = await API.post(`client/${this.props.client_id}/kpi-library/priority-set/${this.props.match.params.priority_set_id}/priority`, {
        data: { ...priority, kpi_priority_set_id: this.props.match.params.priority_set_id },
      });

      this.mounted && this.setState({
        priorities: response.kpi_priorities,
      }, () => {
        Notification('success', 'The priority has been created.', 'Priority Created');
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({ isSaving: false });
    }
  };

  onDelete = async (priorityId: KpiPriority['id']): Promise<void> => {
    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const response = await API.delete(`client/${this.props.client_id}/kpi-library/priority-set/${this.props.match.params.priority_set_id}/priority/${priorityId}`);

      this.mounted && this.setState({
        priorities: response.kpi_priorities,
      }, () => {
        Notification('success', 'The priority has been deleted.', 'Priority Deleted');
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({ isLoading: false });
    }
  };

  onEdit = async (priority: KpiPriority): Promise<void> => {
    try {
      await new Promise((resolve) => this.setState({ isSaving: true }, () => resolve(null)));

      const response = await API.put(`client/${this.props.client_id}/kpi-library/priority-set/${this.props.match.params.priority_set_id}/priority/${priority.id}`, {
        data: priority,
      });

      this.mounted && this.setState({
        priorities: response.kpi_priorities,
      }, () => {
        Notification('success', 'The priority has been edited.', 'Priority Edited');
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({ isSaving: false });
    }
  };

  onReorder = async (priorities: KpiPriority[], dragIndex: number, hoverIndex: number): Promise<void> => {
    if (dragIndex !== hoverIndex) {
      try {

        await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

        const reorderedRows: Array<{ id: number; order: number }> = arrayMoveImmutable<KpiPriority>(priorities, dragIndex, hoverIndex)
          .filter((element: KpiPriority) => !!element)
          .map((row: KpiPriority, idx: number) => ({ id: row.id, order: idx }));

        const response = await API.put(`client/${this.props.client_id}/kpi-library/priority-set/${this.props.match.params.priority_set_id}/priority/reorder`, {
          data: reorderedRows,
        });

        this.mounted && this.setState({
          priorities: response.kpi_priorities,
        });

      } catch (error) {
        console.error('Error: ', error);
      } finally {
        this.mounted && this.setState({ isLoading: false });
      }
    }
  };

  renderAddDialog = (addDialogInfo: AddDialogInfo, isSaving: boolean) => {
    const errors: { [key: string]: boolean } = {};
    const modified: { [key: string]: boolean } = {};

    // if title is empty, set error, otherwise set modified state
    if (_.isEmpty(addDialogInfo.title.trim())) {
      errors.title = true;
    } else {
      modified.title = true;
    }

    // if description is empty, set error, otherwise set modified state
    if (!addDialogInfo?.description.replace(/<[^>]*>?/gm, '')) {
      errors.description = true;
    } else {
      modified.description = true;
    }

    return (
      <Modal
        visible
        centered
        closable={ !isSaving }
        maskClosable={ !isSaving }
        title={ 'Add Priority' }
        onOk={ async () => {
          await this.onCreate({ title: addDialogInfo.title, description: addDialogInfo.description, color: addDialogInfo.color });
          this.setState({ addDialogInfo: null });
        } }
        okText={ 'Add' }
        onCancel={ () => this.mounted && this.setState({
          addDialogInfo: null,
        }) }
        style={{ minWidth: 800 }}
        okButtonProps={{
          disabled: !_.isEmpty(errors) || _.isEmpty(modified),
          loading: isSaving,
        }}
        cancelButtonProps={{
          disabled: isSaving,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Title" required>
            <Input
              className={ classNames('Field', {
                'Field--has-error border-danger': !!errors.title,
                'Field--has-warning border-warning': !!modified.title && !errors.title,
              }) }
              onChange={ e => {
                this.mounted && this.setState({
                  addDialogInfo: { ...addDialogInfo, title: e.target.value }
                });
              } }
              value={ addDialogInfo?.title }
            />
          </Form.Item>
          <Form.Item label="Description" required>
            <ReactQuill
              className={ classNames('Editor bd bdrs-2', {
                'border-danger': !!errors.description,
                'border-antd': !modified.description && !errors.description,
                'border-warning': !!modified.description && !errors.description,
              }) }
              theme="snow"
              bounds={ '.Editor' }
              value={ addDialogInfo?.description }
              onChange={ _.debounce((__, ___, source: string, editor) => {
                if (source === 'user') {
                  this.mounted && this.setState({
                    addDialogInfo: { ...addDialogInfo, description: editor.getHTML() }
                  });
                }
              }, 500) }
            />
          </Form.Item>
          <Form.Item
            label="Colour"
            name="color"
          >
            <Popover
              placement={ 'bottom' }
              trigger={ 'click' }
              content={
                <TwitterPicker
                  styles={{ card: { boxShadow: 'none' } }}
                  color={ addDialogInfo?.color }
                  colors={ ['#c4d8de', '#0f9aee', '#00875a', '#efb636', '#d6494a'] }
                  width={ 350 }
                  triangle={ 'hide' }
                  onChange={ (color: ColorResult) => {
                    this.mounted && this.setState({
                      addDialogInfo: { ...addDialogInfo, color: color.hex }
                    });
                  } }
                />
              }
            >
              <Input
                addonBefore={ '#' }
                suffix={ addDialogInfo?.color ? (
                  <>
                    <CloseCircleOutlined
                      onClick={ () => {
                        this.mounted && this.setState({
                          addDialogInfo: { ...addDialogInfo, color: undefined }
                        });
                      } }
                    />
                    <span
                      style={{
                        background: addDialogInfo?.color || '#ffffff',
                        height: 20,
                        width: 20,
                        position: 'relative',
                        outline: 'none',
                        float: 'left',
                        borderRadius: 4,
                      }}
                    />
                  </>
                ) : (
                  <span />
                ) }
                value={ addDialogInfo?.color?.replace('#', '')?.toUpperCase() || undefined }
              />
            </Popover>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderEditDialog = (editDialogInfo: EditDialogInfo, isSaving: boolean): JSX.Element => {
    const errors: { [key: string]: boolean } = {};
    const modified: { [key: string]: boolean } = {};

    // if title is empty, set error
    if (_.isEmpty(editDialogInfo.title.trim())) {
      errors.title = true;
    }

    // if the title has been changed, set the modified state
    if (!_.isEqual(editDialogInfo.title, editDialogInfo.originalPriority.title)) {
      modified.title = true;
    }

    // if the color has been changed, set the modified state
    if (!_.isEqual(editDialogInfo.color, editDialogInfo.originalPriority.color)) {
      modified.color = true;
    }

    // if description is empty, set error
    if (!editDialogInfo?.description.replace(/<[^>]*>?/gm, '')) {
      errors.description = true;
    }

    // if the description has been changed, set the modified state
    if (!_.isEqual(editDialogInfo?.description, editDialogInfo.originalPriority.description)) {
      modified.description = true;
    }

    return (
      <Modal
        visible
        centered
        closable={ !isSaving }
        maskClosable={ !isSaving }
        title={ 'Edit Priority' }
        onOk={ async () => {
          await this.onEdit({ ...editDialogInfo.originalPriority, title: editDialogInfo?.title, color: editDialogInfo.color, description: editDialogInfo.description });
          this.setState({ editDialogInfo: null });
        } }
        okText={ 'Save' }
        onCancel={ () => this.mounted && this.setState({
          editDialogInfo: null,
        }) }
        style={{ minWidth: 800 }}
        okButtonProps={{
          disabled: !_.isEmpty(errors) || _.isEmpty(modified),
          loading: isSaving,
        }}
        cancelButtonProps={{
          disabled: isSaving,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Title" required>
            <Input
              className={ classNames('Field', {
                'Field--has-error border-danger': !!errors.title,
                'Field--has-warning border-warning': !!modified.title && !errors.title,
              }) }
              onChange={ e => {
                this.mounted && this.setState({
                  editDialogInfo: { ...editDialogInfo, title: e.target.value }
                });
              } }
              value={ editDialogInfo?.title }
            />
          </Form.Item>
          <Form.Item label="Description" required>
            <ReactQuill
              className={ classNames('Editor bd bdrs-2', {
                'border-danger': !!errors.description,
                'border-antd': !modified.description && !errors.description,
                'border-warning': !!modified.description && !errors.description,
              }) }
              theme="snow"
              bounds={ '.Editor' }
              value={ editDialogInfo?.description }
              onChange={ _.debounce((__, ___, source: string, editor) => {
                if (source === 'user') {
                  this.mounted && this.setState({
                    editDialogInfo: { ...editDialogInfo, description: editor.getHTML() }
                  });
                }
              }, 500) }
            />
          </Form.Item>
          <Form.Item
            label="Colour"
            name="color"
          >
            <Popover
              placement={ 'bottom' }
              trigger={ 'click' }
              content={
                <TwitterPicker
                  styles={{ card: { boxShadow: 'none' } }}
                  color={ editDialogInfo?.color }
                  colors={ ['#c4d8de', '#0f9aee', '#00875a', '#efb636', '#d6494a'] }
                  width={ 350 }
                  triangle={ 'hide' }
                  onChange={ (color: ColorResult) => {
                    this.mounted && this.setState({
                      editDialogInfo: { ...editDialogInfo, color: color.hex }
                    });
                  } }
                />
              }
            >
              <Input
                addonBefore={ '#' }
                suffix={ editDialogInfo?.color ? (
                  <>
                    <CloseCircleOutlined
                      onClick={ () => {
                        this.mounted && this.setState({
                          editDialogInfo: { ...editDialogInfo, color: undefined }
                        });
                      } }
                    />
                    <span
                      style={{
                        background: editDialogInfo?.color || '#ffffff',
                        height: 20,
                        width: 20,
                        position: 'relative',
                        outline: 'none',
                        float: 'left',
                        borderRadius: 4,
                      }}
                    />
                  </>
                ) : (
                  <span />
                ) }
                value={ editDialogInfo?.color?.replace('#', '')?.toUpperCase() || undefined }
              />
            </Popover>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderView = (items: KpiPriority[], isLoading: boolean): JSX.Element => {
    const columns = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        render: () => <MenuOutlined className="DragMenu" />,
        width: 80,
        align: 'center' as 'center',
      },
      {
        key: 'title',
        dataIndex: 'title',
        render: (title: string, row: KpiPriority) => {
          return (
            <>
              <span>{ title }</span>
              <span
                style={{
                  background: row?.color || '#ffffff',
                  height: 20,
                  width: 20,
                  position: 'relative',
                  outline: 'none',
                  float: 'right',
                  borderRadius: 4,
                }}
              />
            </>
          );
        },
        title: 'Title',
        ellipsis: true,
      },
      {
        key: 'created_at',
        dataIndex: 'created_at',
        title: 'Created',
        render: (created_at: string) => getFormatedDate(created_at),
        ellipsis: true,
        width: 300,
      },
      {
        key: 'actions',
        dataIndex: '',
        title: '',
        render: (__: any, row: KpiPriority) => {
          const canDelete: boolean = row?.can_delete ? true : false;
          const canEdit: boolean = row?.can_edit ? true : false;
          return (
            <>
              <Tooltip
                placement="top"
                title={ canEdit ? 'Edit priority' : 'This priority cannot be edited as it is used within existing metrics.' }
              >
                <Button
                  style={{
                    marginLeft: '5px',
                    padding: '4px 7px',
                    width: '32px',
                  }}
                  disabled={ !canEdit }
                  onClick={ () => this.mounted && this.setState({
                    editDialogInfo: {
                      title: row.title,
                      color: row.color,
                      description: row.description,
                      originalPriority: row,
                      display: true,
                    }
                  }) }
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
              <Popconfirm
                title={ 'Are you sure?' }
                icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                okButtonProps={{ danger: true }}
                onConfirm={ () => this.onDelete(row.id) }
                disabled={ !canDelete }
              >
                <Tooltip
                  placement="top"
                  title={ canDelete ? 'Delete priority' : 'This priority cannot be deleted as it is used within existing metrics.' }
                >
                  <Button
                    style={{
                      marginLeft: '5px',
                      padding: '4px 7px',
                      width: '32px',
                    }}
                    disabled={ !canDelete }
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </>
          );
        },
        align: 'center' as 'center',
        width: 100,
      },
    ];

    return (
      <div className='Layout-box'>
        <DragSortingList
          className={ 'PriorityTable' }
          columns={ columns  }
          items={ items }
          isParent
          pagination={ false }
          loading={{
            spinning: isLoading,
            indicator: <BlockingSpinner isLoading />,
          }}
          config={{
            type: TableType.Tab,
            references: [],
          }}
          moveRow={ (dragIndex: number, hoverIndex: number) => this.onReorder(items, dragIndex, hoverIndex) }
        />
      </div>
    );
  };

  render = (): JSX.Element => {
    const { priorities, prioritySetTitle, addDialogInfo, editDialogInfo, isFetching, isLoading, isSaving } = this.state;
    return (
      <BlockingSpinner isLoading={ isFetching } style={{ minHeight: 300 }}>
        <Jumbotron
          content={ <p className="mB-0">{ prioritySetTitle }</p> }
          tabs={[
            {
              label: 'Overview',
              node: this.renderView(_.sortBy(priorities, 'order'), isLoading),
            }
          ]}
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Create Priority',
                      onClick: () => this.mounted && this.setState({ addDialogInfo: { title: '', description: '', display: true } })
                    },
                  ] }
                />
              )
            }
          ] }
        />
        { !!addDialogInfo?.display && this.renderAddDialog(addDialogInfo, isSaving) }
        { !!editDialogInfo?.display && this.renderEditDialog(editDialogInfo, isSaving) }
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    client_id: store.ClientState.client_id,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[]) => dispatch(setBreadcrumbs(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(PrioritySet, 'can_manage_kpi_priorities'));
