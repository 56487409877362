// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Form, Steps, Table, Switch, Select, Tooltip, Checkbox } from 'antd';
import CoverModal from 'components/cover-modal';

// Interfaces
import { IDynamicFieldTemplateComponent, IDynamicFieldTemplateSet, IDynamicFieldTemplate } from 'views/admin/templates/Templates.interfaces';
import { SelectListItem, SliderRange } from 'components/form/field/dynamic-field-template/DynamicFieldTemplate.interface';

// Icons
import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as WarningIcon } from 'assets/svg/warning-triangle.svg';

// Utils
import { nestedSet, flattenSet, arrayToChunks } from 'utils/utils';
import { findFirst } from 'utils/utils';

interface ISetting {
  title: string,
  key: string
};

const settings: ISetting[] = [
  {
    title: 'Actions',
    key: 'allow_actions',
  },
  {
    title: 'Allow Multiselect',
    key: 'allow_multiselect',
  },
  {
    title: 'Can Mark Not Applicable',
    key: 'can_mark_not_applicable',
  },
  {
    title: 'Commenting',
    key: 'allow_comment',
  },
  {
    title: 'File Attachments',
    key: 'allow_attachments',
  },
  {
    title: 'Required',
    key: 'required',
  },
  {
    title: 'Assessment',
    key: 'allow_scoring',
  }
];

export interface IConfig {
  allow_actions?: boolean;
  allow_multiselect?: boolean;
  can_mark_not_applicable?: boolean;
  allow_comment?: boolean;
  allow_attachments?: boolean;
  required?: boolean;
  allow_scoring?: boolean;
  scoring?: {
    list_id: string
  }
};

interface Props {
  template: IDynamicFieldTemplate;
  setId: number | string;
  defaultAssessmentOptionId: string | null;
  assessmentOptions: any[];
  onApply: (componentIds: string[], configs: IConfig | undefined, turnOn: boolean) => void;
  onClose: () => void;
};

export default function CommentComponent({ template, setId, defaultAssessmentOptionId, assessmentOptions, onApply, onClose }: Props) {

  const [selectedQuestionKeys, setSelectedQuestionKeys] = React.useState<string[]>([]);
  const [turnOn, setTurnOn] = React.useState<boolean>(true);
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [filterType, setFilterType] = React.useState<string | null>(null);
  const [configs, setConfigs] = React.useState<IConfig>(defaultAssessmentOptionId ? {
    scoring: {
      list_id: defaultAssessmentOptionId
    }
  } : {});

  const templateSet: IDynamicFieldTemplateSet | undefined = template.sets.find((set: IDynamicFieldTemplateSet) => set.id === setId || set.key === setId);
  let components = nestedSet(templateSet?.components);
  const types = flattenSet(components).map((component: IDynamicFieldTemplateComponent) => component.type);

  if (filterType) {

    const filterByType: any = (components: IDynamicFieldTemplateComponent[], filterType: string) => {
      return components
        .filter((component: IDynamicFieldTemplateComponent) => {
          return component.type === filterType || !!findFirst({ children: component.children }, 'children', { type: filterType });
        })
        .map((component: IDynamicFieldTemplateComponent) => {
          return {
            ...component,
            children: component?.children ? filterByType(component.children, filterType) : null
          };
        });
    };

    components = filterByType(components, filterType);
  }

  const handleSelect = (key: string) => {
    let newKeys = _.cloneDeep(selectedQuestionKeys);

    if (key) {
      if (newKeys.includes(`${key}`)) {
        newKeys = newKeys.filter((_key: string) => _key !== key);
      } else {
        newKeys.push(`${key}`);
      }

      setSelectedQuestionKeys(newKeys);
    }
  };

  return (
    <CoverModal
      style={ { width: 800, height: 600 } }
      leftContent={ currentStep !== 1 ? (
        <div className="d-f ai-c h-100p primaryColor cur-p">
          <ArrowLeftOutlined
            className="mL-30"
            style={{ fontSize: 25 }}
            onClick={ () => setCurrentStep(currentStep - 1) }
          />
        </div>
      ) : undefined }
      middleContent={ (
        <div className="d-f ai-c h-100p">
          <Steps current={ currentStep - 1 }>
            <Steps.Step
              title="Selection"
              className={ classNames({
                'cur-p': currentStep !== 1
              }) }
              onClick={ () => {
                if (currentStep !== 1) {
                  setCurrentStep(1);
                }
              } }
            />
            <Steps.Step title="Settings" />
          </Steps>
        </div>
      ) }
      actions={ currentStep === 1 ?
        [
          {
            label: 'Next',
            onClick: () => setCurrentStep(2),
            isDisabled: _.isEmpty(selectedQuestionKeys),
          }
        ] : [
          {
            label: 'Close',
            onClick: () => onClose(),
          },
          {
            type: 'primary',
            label: 'Apply',
            onClick: () => onApply(selectedQuestionKeys, configs, turnOn),
            isDisabled: _.isEmpty(configs),
          }
        ]
      }
      onClose={ () => onClose() }
      smallHeader
    >
      <div className='h-100p d-f fxd-c ai-c'>
        <div className="fx-1" style={{ width: '100%' }}>
          <div className="mL-20 mR-20">
            { currentStep === 1 ? (
              <div className="d-f fxd-c">
                <div className="d-f jc-c mT-20">
                  <div className="d-f ai-c w-70p">
                    <span className="mR-20">
                      <InfoCircleOutlined className="fsz-def" />
                    </span>
                    <p>Please select the questions you want to perform the bulk change to.</p>
                  </div>
                </div>
                <div className="Layout-box mT-20">
                  <div>
                    <Select
                      size='small'
                      allowClear
                      style={{ width: 200 }}
                      className="Select-Field"
                      placeholder={ 'Filter' }
                      onClear={ () => {
                        setFilterType(null);
                      } }
                      onChange={ (value: string) => {
                        setFilterType(value);
                      } }
                    >
                      { _.uniq(types).map((value: unknown, index: number) => (
                        <Select.Option key={ index } value={ value }>{ _.capitalize(_.toLower(`${value}`)).replaceAll('_', ' ') }</Select.Option>
                      ) ) }
                    </Select>
                  </div>
                  <div className="mT-10">
                    <Table
                      size={'small'}
                      bordered
                      columns={[
                        {
                          title: 'Question',
                          render: (__: any, component: IDynamicFieldTemplateComponent) => {
                            return (
                              <div
                                className="d-f jc-sb cur-p"
                                onClick={ () => handleSelect(`${component?.key || component?.id}`) }
                              >
                                { component?.title || component?.label || '-' } <span className="default-grey fw-500">{ ` (${_.capitalize(_.toLower(`${component?.type}`)).replaceAll('_', ' ')})` }</span>
                              </div>
                            );
                          }
                        },
                      ]}
                      scroll={{
                        y: '100%',
                      }}
                      dataSource={ components }
                      pagination={ false }
                      expandable={{
                        defaultExpandAllRows: true
                      }}
                      rowSelection={{
                        hideSelectAll: true,
                        selectedRowKeys: selectedQuestionKeys,
                        columnTitle: (
                          <Tooltip key={ 'select_all' } title={ 'Select All' } placement={ 'left' }>
                            <Checkbox
                              indeterminate={ !_.isEmpty(selectedQuestionKeys) && flattenSet(components).length !== selectedQuestionKeys.length }
                              checked={ flattenSet(components).length === selectedQuestionKeys.length }
                              onChange={ () => {
                                const newKeys = flattenSet(components).length !== selectedQuestionKeys.length ? flattenSet(components).map((component: IDynamicFieldTemplateComponent) => component.key) : [];
                                setSelectedQuestionKeys(newKeys);
                              } }
                            />
                          </Tooltip>
                        ),
                        onSelect: (component: IDynamicFieldTemplateComponent) => {
                          handleSelect(`${component?.key || component?.id}`);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-f jc-c fxd-c">
                <div className="d-f jc-c mT-50">
                  <div className="d-f ai-c w-70p">
                    <span className="mR-20">
                      <InfoCircleOutlined className="fsz-def" />
                    </span>
                    <p>Please apply the relevant updates to the selected questions. Note, some settings may only affect specific field types.</p>
                  </div>
                </div>
                <div className="d-f jc-c mT-50">
                  <div className="w-60p">
                    <div>
                      { arrayToChunks(settings, 2).map((chunkSettings: any, index: number) => (
                        <div key={ index } className="d-f jc-sb">
                          { chunkSettings.map((setting: ISetting, _index: number) => (
                            <div key={ _index } className="d-if">
                              <Checkbox
                                style={{ width: 200 }}
                                onChange={ (e) => {
                                  setConfigs({
                                    ...configs,
                                    [setting.key]: e.target.checked
                                  });
                                } }
                                checked={ _.has(configs, setting.key) ? (configs as any)[setting.key] : false }
                              >
                                { setting.title }
                              </Checkbox>
                            </div>
                          ) ) }
                        </div>
                      ) ) }
                    </div>
                    <div>
                      { turnOn && !!configs?.allow_scoring &&
                        <div className="d-f mT-30 jc-c ai-c">
                          <span>Assessment:</span>
                          <span className="mL-10 w-100p">
                            <Select
                              className="w-100p"
                              showSearch
                              placeholder={ 'Please select' }
                              defaultValue={ defaultAssessmentOptionId }
                              dropdownMatchSelectWidth={ false }
                              filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1;
                              }}
                              onChange={ (id: string) => {
                                setConfigs({
                                  ...configs,
                                  scoring: {
                                    list_id: id
                                  }
                                });
                              } }
                            >
                              { assessmentOptions.map((option: SliderRange | SelectListItem) => (
                                <Select.Option key={ option.id } value={ option.id }>
                                  { option.title }
                                </Select.Option>
                              )) }
                            </Select>
                          </span>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="d-f jc-c mT-70">
                  <div className="d-f ai-c jc-c w-70p">
                    <span className="mR-10">
                      <WarningIcon
                        className={ classNames('mL-5 mT-5', {
                          'text-danger': true,
                        }) }
                        height={ 20 }
                        width={ 20 }
                      />
                    </span>
                    <p>I would like to</p>
                    <span className="mL-5 mR-5">
                      <Switch
                        checkedChildren={ 'Switch On' }
                        unCheckedChildren={ 'Switch Off' }
                        onChange={ (checked: boolean) => {
                          setTurnOn(checked);
                        } }
                        checked={ turnOn }
                      />
                    </span>
                    <p>the selected settings.</p>
                  </div>
                </div>
              </div>
            ) }
          </div>
        </div>
      </div>
    </CoverModal>
  );
};
