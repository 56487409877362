// Libs
import * as React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Views
import SliderRangeOptions from 'views/admin/content-manager/slider-range/SliderRangeOptions';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';

interface Props extends RouteComponentProps {
  permissions: any;
};

class SliderRangeContainer extends React.Component<Props> {

  render = () => {
    return (
      <Switch>

        <Route exact path="/admin/content-manager/slider-ranges/:type" render={ () => {
          return (
            <RestrictionWrapper restricted={ !hasPermission(this.props.permissions, 'access_admin_content_manager') && 'No access' }>
              <SliderRangeOptions />
            </RestrictionWrapper>
          );
        } } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(SliderRangeContainer);
