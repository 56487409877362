// Libs
import React from 'react';
import _ from 'lodash';
import { injectIntl, IntlShape } from 'react-intl';

// Components
import { Checkbox, Form, Input, Modal } from 'antd';

interface Props {
  intl: IntlShape;
  onCancel: () => void;
  onSubmit: (title: string, config: any) => Promise<void>;
  isEditing?: boolean;
  initalState: Partial<{ title: string; config: any }>;
  title: string;
};

interface State {
  newItem: Partial<{ title: string; config: any }>;
  isAdding: boolean;
};

class AddTabDialog extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    newItem: this.props.initalState,
    isAdding: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render = () => {
    const { isEditing, title, onCancel, onSubmit } = this.props;
    const { isAdding, newItem } = this.state;

    return (
      <Modal
        title={ `${isEditing ? 'Edit' : 'Add'} ${title}` }
        maskClosable={ !isAdding }
        centered
        visible
        onCancel={ () => this.mounted && this.setState({ newItem: {} }, onCancel) }
        onOk={ () => this.mounted && this.setState({ isAdding: true }, async () => {
          try {
            const { title = '', config } = newItem;
            await onSubmit(title, config);
          } catch (error) {
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({ isAdding: false, newItem: {} });
            onCancel();
          }
        }) }
        okText={ isEditing ? 'Save' : 'Create' }
        okButtonProps={{
          disabled: _.isEmpty(newItem.title?.trim()) || isAdding,
          loading: isAdding,
        }}
        cancelButtonProps={{
          disabled: isAdding,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Label" required>
            <Input
              value={ newItem.title }
              placeholder={ 'Please enter a label' }
              onChange={ (e) => this.setState({ newItem: Object.assign(newItem, { title: e.target.value }) }) }
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={ newItem.config?.open }
              onChange={ (e) => this.setState({ newItem: Object.assign(newItem, { config: { open: e.target.checked } }) }) }
            >
              Open By Default
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
};

export default injectIntl(AddTabDialog);
