// Libs
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import { Button, Popconfirm } from 'antd';
import BasicList from 'components/basic-list';
import BlockingSpinner from 'components/blocking-spinner';
import AttachMetricModal from 'components/kpi-library/AttachMetricModal';
import { hasPermission, RestrictionHoC } from 'components/restriction';
import CreateMetricModal from 'components/kpi-library/CreateMetricModal';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import Badge, { BadgeType } from 'components/badge';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';
import { getFormatedDate } from 'services/settings';

// Actions
import { setBreadcrumbs, setBreadcrumbsLoading } from 'store/UI/ActionCreators';

// Views
import MetricCopyModal from 'views/workplace-service/kpi-library/MetricCopyModal';

// Icons
import Icon, { QuestionCircleOutlined } from '@ant-design/icons';

// Utils
import history from 'utils/history';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';
import { UserPermissions } from 'types/permissions';
import { Breadcrumb } from 'store/UI/State.interface';
import { KpiMetricRow, KpiMetric, KpiMetricType, KpiPrioritySet, KpiMetricRecordType } from 'components/kpi-library/KpiLibrary.interfaces';

// Styles
import './KpiLibrary.scss';

const API: Api = new Api();

interface Props {
  record?: RecordFormEntity;
  client_id?: number;
  permissions: UserPermissions;
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  kpi_metrics: KpiMetric[];
  kpi_metric_record_types: KpiMetricRecordType[];
  kpi_metric_types: KpiMetricType[];
  kpi_priority_sets: KpiPrioritySet[];
  isFetching: boolean;
  isLoading: boolean;
  isModifying: boolean;
  showCreateDialog: boolean;
  showAttachMetricDialog: boolean;
  copyMetric: KpiMetricRow | null;
  isPublishing: boolean;
  isVersioning: boolean;
};

class KpiMetricListView extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    kpi_metrics: [],
    kpi_metric_record_types: [],
    kpi_metric_types: [],
    kpi_priority_sets: [],
    isFetching: false,
    isLoading: false,
    isModifying: false,
    showCreateDialog: false,
    showAttachMetricDialog: false,
    copyMetric: null,
    isPublishing: false,
    isVersioning: false,
  };

  componentDidMount = async () => {
    const { client_id, record } = this.props;

    this.mounted = true;

    try {
      this.props.setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({isFetching: true}, () => resolve(null)));
      const response = await API.get(`client/${client_id}/kpi-library/metric`, {
        'entity_id': record?.id,
        'entity_type': record?.type,
        'entity_bundle': record?.bundle,
      });

      this.props.setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Workplace Services', path: '/workplace-services' },
        { title: 'KPI Library', path: '/workplace-services/kpi-library' },
      ], false);

      if (record && _.has(record, 'breadcrumbs')) {
        this.props.setBreadcrumbs(record.breadcrumbs, false);
      }

      this.mounted && this.setState({
        kpi_metrics: response.kpi_metrics || [],
        kpi_metric_record_types: response.kpi_metric_record_types || [],
        kpi_metric_types: response.kpi_metric_types || [],
        kpi_priority_sets: response.kpi_priority_sets || [],
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  onCopy = async (metricId: number, title: string, callback: () => void) => {
    try {
      const { client_id } = this.props;
      const newKpiMetrics = await API.post(`client/${client_id}/kpi-library/metric/${metricId}/copy`, {
        title: title
      });

      Notification('success', 'The Metric has been copied', 'Copied');

      this.setState({
        copyMetric: null,
        kpi_metrics: !!newKpiMetrics ? newKpiMetrics : this.state.kpi_metrics
      });
    } catch (error: any) {
      console.error(error);
      Notification('error', _.has(error, 'data') && !!error.data.message ? error.data.message : 'Failed to copy Metric', 'Failed');
    } finally {
      callback();
    }
  };

  renderMetrics = (kpiMetrics: KpiMetric[]): JSX.Element => {
    const { isModifying } = this.state;
    const { permissions, record } = this.props;

    let columns: any = [
      {
        key: 'record_bundle',
        dataIndex: 'record_bundle',
        title: 'Record Bundle',
        render: (type: string) => {
          return <span>{ type || '-' }</span>;
        },
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 120,
      },
      {
        key: 'record_type',
        dataIndex: 'record_type',
        title: 'Record Type',
        render: (type: string) => {
          return <span>{ type || '-' }</span>;
        },
        sorter: true,
        filterable: true,
        ellipsis: true,
        width: 120,
      },
      {
        key: 'type',
        dataIndex: 'type',
        title: 'Type',
        render: (type: string) => {
          return <span>{ type || '-' }</span>;
        },
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 120,
      }
    ];

    if (!!record) {
      columns.push(
        {
          key: 'kpis_count',
          dataIndex: 'kpis_count',
          title: 'Record Count',
          sorter: true,
          filterable: false,
          ellipsis: true,
          width: 80,
          render: (kpisCount: any) => {
            return <span>{ kpisCount || '-' }</span>;
          },
        }
      );
    }

    columns = [...columns, ...[
      {
        key: 'version_status',
        dataIndex: 'version_status',
        title: 'Version',
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 50,
        render: (versionStatus: string) => {
          return (
            <Badge type={ versionStatus === 'PUBLISHED' ? BadgeType.Success : BadgeType.Default } text={ _.startCase(_.toLower(versionStatus)) } />
          );
        },
      },
      {
        key: 'version_number',
        dataIndex: 'version_number',
        title: 'Version Number',
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 80,
        render: (versionNumber: number) => {
          return <span>{ versionNumber || '-' }</span>;
        },
      },
      {
        key: 'updated_at',
        dataIndex: 'updated_at',
        title: 'Last Modified',
        render: (updated_at: string) => getFormatedDate(updated_at, undefined, true),
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 120,
      }
    ]];

    if (hasPermission(permissions, 'kpi_metric_copy') || hasPermission(permissions, 'kpi_metric_delete')) {
      columns.push(
        {
          key: 'actions',
          dataIndex: 'actions',
          title: '',
          render: (__: any, row: any) => {

            const _actions: DropdownAction[] = [
              {
                node: '',
                onClick: () => {}
              }
            ];

            if (hasPermission(permissions, 'kpi_metric_edit')) {
              let path = `/workplace-services/kpi-library/metric/${row.id}`;

              if (record && record.type === 'contract') {
                path = `${record.path}/kpi-library/metric/${row.id}`;
              }

              _actions.push({
                node: 'Edit',
                onClick: () => history.push(path)
              });
            }

            if (hasPermission(permissions, 'kpi_metric_copy')) {
              _actions.push({
                node: 'Duplicate',
                onClick: () => this.setState({ copyMetric: row })
              });
            }

            if (hasPermission(permissions, 'kpi_metric_delete')) {
              _actions.push({
                node: (
                  <Popconfirm
                    title={ 'Are you sure?' }
                    icon={ <QuestionCircleOutlined style={ { color: 'red' } } /> }
                    okButtonProps={ {
                      danger: true
                    } }
                    placement="topRight"
                    onConfirm={ () => this.onDelete(row.id) }
                  >
                    Delete
                  </Popconfirm>
                ),
                isDangerous: true,
                onClick: () => {}
              });
            }

            return <Dropdown actions={ _actions } />;
          },
          sorter: false,
          filterable: false,
          ellipsis: true,
          width: 30,
        }
      );
    }

    const mapData = (kpiMetrics: KpiMetric[]) => {
      return kpiMetrics.map((kpiMetric: KpiMetric, index: number) => {
        const metric: any = {
          'key': index,
          'id': kpiMetric.id,
          'type': kpiMetric?.kpi_metric_type_title,
          'record_type': _.startCase(kpiMetric?.kpi_metric_record_entity_type),
          'record_bundle': _.startCase(kpiMetric?.kpi_metric_record_entity_bundle),
          'version_status': kpiMetric.version_status,
          'version_number': kpiMetric.version,
          'updated_at': kpiMetric?.updated_at,
        };

        if (!!record) {
          metric.kpis_count = kpiMetric.kpis_count;
        }

        return metric;
      });
    };

    const rightActions = [];

    if (record) {
      rightActions.push({
        node: (
          <Button
            onClick={ () => this.setState({ showAttachMetricDialog: true }) }
            loading={ isModifying }
          >
            Attach Metric
          </Button>
        ),
      });
    }

    return <BasicList columns={ columns } items={ mapData(kpiMetrics) } rightActions={ rightActions } rawData/>;
  };

  onCreate = async (payload: any, callback?: () => void): Promise<void> => {
    const { client_id } = this.props;

    try {
      await new Promise((resolve) => this.setState({ isModifying: true }, () => resolve(null)));

      const response = await API.post(`client/${client_id}/kpi-library/metric`, {
        data: payload
      });

      this.mounted && this.setState({
        kpi_metrics: response.kpi_metrics,
        showCreateDialog: false
      }, () => {
        Notification('success', 'The metric has been created.', 'Created');
      });
    } catch(error) {
      Notification('error', 'Failed to create metric', 'Failed');
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({ isModifying: false, showCreateDialog: false }, callback);
    }
  };

  onDelete = async (metricId: KpiMetric['id'], callback?: () => void): Promise<void> => {
    const { client_id } = this.props;

    try {
      await new Promise((resolve) => this.setState({ isModifying: true }, () => resolve(null)));

      const response = await API.delete(`client/${client_id}/kpi-library/metric/${metricId}`);

      this.mounted && this.setState({
        kpi_metrics: response.kpi_metrics || [],
        kpi_metric_types: response.kpi_metric_types,
        kpi_priority_sets: response.kpi_priority_sets,
      }, () => {
        Notification('success', 'The metric has been deleted.', 'Metric Deleted');
        history.push(window.location.pathname);
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({ isModifying: false }, callback);
    }
  };

  onAttach = async (metrics: KpiMetric[]): Promise<void> => {
    const { client_id, record } = this.props;

    try {
      await new Promise((resolve) => this.setState({ isModifying: true }, () => resolve(null)));

      const response = await API.post(`client/${client_id}/kpi-library/metric/attach-metric`, {
        metrics: metrics,
        entity_id: record?.id,
        entity_type: record?.type,
        entity_bundle: record?.bundle,
      });

      this.mounted && this.setState({
        kpi_metrics: response,
        showAttachMetricDialog: false
      }, () => {
        Notification('success', 'The metrics have been attached.', 'Attached');
      });
    } catch(error) {
      Notification('error', 'Failed to attach metrics', 'Failed');
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({ isModifying: false, showAttachMetricDialog: false });
    }
  };

  render = (): JSX.Element => {
    const { client_id } = this.props;
    const { isFetching, kpi_metrics, kpi_metric_record_types, kpi_metric_types, kpi_priority_sets, copyMetric, showCreateDialog, showAttachMetricDialog } = this.state;

    return (
      <>
        <BlockingSpinner isLoading={ isFetching } style={ { minHeight: 300 } }>
          { this.renderMetrics(kpi_metrics) }
        </BlockingSpinner>
        { copyMetric &&
          <MetricCopyModal
            metric={ copyMetric }
            onClose={ () => this.setState({ copyMetric: null }) }
            onCopy={ this.onCopy }
          />
        }
        { showCreateDialog && !isFetching &&
          <CreateMetricModal
            clientId={ client_id }
            kpiMetricRecordTypes={ kpi_metric_record_types }
            kpiMetricTypes={ kpi_metric_types }
            kpiPrioritySets={ kpi_priority_sets }
            onCreate={ this.onCreate }
            onClose={ () => {
              this.setState({ showCreateDialog: false });
            } }
          />
        }
        { showAttachMetricDialog && !isFetching &&
          <AttachMetricModal
            clientId={ client_id }
            onAttach={ (metrics: KpiMetric[]) => this.onAttach(metrics) }
            onClose={ () => this.setState({ showAttachMetricDialog: false }) }
          />
        }
      </>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    client_id: store.ClientState.client_id,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[]) => dispatch(setBreadcrumbs(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(KpiMetricListView, 'can_manage_kpi_metrics'));
