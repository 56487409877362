// Libs
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import Jumbotron from "components/jumbotron";
import BlockingSpinner from 'components/blocking-spinner';
import { Modal, Select } from 'antd';
import { RestrictionHoC } from 'components/restriction';
import Dropdown from 'components/dropdown';
import BasicList from 'components/basic-list';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';
import { getFormatedDate } from 'services/settings';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";

interface Props {
  client_id: number;
  user_id: number;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  modules: any[];
  isLoading: boolean;
  showRebuildDialog: boolean;
  isRebuilding: boolean;
};

const API: Api = new Api();

class Insights extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    modules: [],
    isLoading: false,
    showRebuildDialog: false,
    isRebuilding: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Insights', path: null },
    ], false);

    this.fetchModules(client_id);
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  fetchModules = async (clientId: number) => {
    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const modules = await API.get(`client/${clientId}/admin/insights/modules`);

      this.mounted && this.setState({
        modules: modules
      });
    } catch (error) {
      Notification('error', 'Failed to fetch modules', 'Failed');
    } finally {
      this.mounted && this.setState({ isLoading: false });
    }
  };

  renderRebuildDialog = (clientId: number) => {
    return (
      <Modal
        visible
        centered
        closable={ false }
        maskClosable={ false }
        title={ 'Force Rebuild' }
        okText={ 'Run' }
        onOk={ async () => {
          try {
            await new Promise((resolve) => this.setState({ isRebuilding: true }, () => resolve(null)));

            await API.get(`client/${clientId}/admin/insights/rebuild`);
            const modules = await API.get(`client/${clientId}/admin/insights/modules`);

            this.mounted && this.setState({
              modules: modules
            });
          } catch (error: any) {
            if (error?.error?.response?.data?.message) {
              Notification('error', error.error.response.data.message, 'Failed');
            } else {
              Notification('error', 'Failed to rebuild modules', 'Failed');
            }
          } finally {
            this.mounted && this.setState({
              showRebuildDialog: false,
              isRebuilding: false,
            });
          }
        } }
        onCancel={ () => this.setState({
          showRebuildDialog: false,
        }) }
        cancelButtonProps={{
          disabled: this.state.isRebuilding
        }}
        okButtonProps={{
          loading: this.state.isRebuilding,
          danger: true,
        }}
      >
        <p>This will trigger a full rebuild of all reporting modules. This is a safe action and will not interrupt reports. The rebuild will happen behind the scenes and you will see the “Last Generated” times below update once completed.</p>
      </Modal>
    );
  };

  renderView = () => {
    const { modules } = this.state;
    return (
      <BasicList
        rawData
        columns={ [
          {
            key: 'module',
            title: 'Module',
            render: (__: any, module: any) => {
              return module?.title;
            },
            ellipsis: true,
            width: '50%',
          },
          {
            key: 'generation',
            title: 'Generation Time',
            render: (__: any, module: any) => {
              if (module?.last_build_time && module?.last_build_time !== '0.00') {
                return `${module?.last_build_time}s`;
              }
              return '-';
            },
            ellipsis: true,
          },
          {
            key: 'generated',
            title: 'Last Generated',
            render: (__: any, module: any) => {
              if (module?.generated_at && module.generated_at !== '0000-00-00 00:00:00') {
                return getFormatedDate(module?.generated_at, undefined, true);
              }
              return '-';
            },
            ellipsis: true,
          }
        ] }
        items={ modules.map((module: any) => {
          return {
            key: module.id,
            ...module
          };
        }) }
      />
    );
  };

  render = () => {
    const { client_id } = this.props;
    const { isLoading, showRebuildDialog } = this.state;
    return (
      <BlockingSpinner isLoading={isLoading}>
        <Jumbotron
          content={ 'Insights' }
          tabs={ [
            {
              label: 'Modules',
              node: this.renderView(),
            }
          ] }
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Force Rebuild',
                      onClick: () => this.setState({ showRebuildDialog: true })
                    }
                  ] }
                />
              )
            }
          ] }
        />
        { showRebuildDialog && this.renderRebuildDialog(client_id) }
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    user_id: store.UserState.user.id,
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(Insights, 'access_admin_insights'));