// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import SpaceContainer from 'containers/workspace/SpaceContainer';

// Views
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

class WorkspaceContainer extends React.Component {

  render = () => {
    return (
      <Switch>

        <Redirect exact from="/workspaces" to="/workspaces/spaces" />

        <Route exact path="/workspaces/spaces" component={ SpaceContainer } />
        <Route path="/workspaces/spaces/:record_id" component={ SpaceContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

export default WorkspaceContainer;
