export enum TableType {
  Tab = 'Tab',
  Group = 'Group',
  Element = 'Element',
};

export interface Config {
  type: TableType;
  references: Array<{ type: TableType; id: number }>;
};
