// Libs
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

// Components
import { Modal, Input, Form, Select, Checkbox, Tabs, Button, Spin, Tooltip } from 'antd';
import FieldWrapper from 'components/form/field/field-wrapper';

// Api
import Notification from 'services/notification';

// Interfaces
import { WorkflowStageContext, WorkflowStage } from 'components/workflow';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

type StageState = Partial<WorkflowStage>;

enum TabPaneType {
  Edit = "Edit",
  Delete = "Delete",
};

type TargetStage = { id: number, title: string };

interface Props {
  onCancel: () => void;
  title: string;
  request?: (data: StageState) => Promise<void>;
  checkExistsEntities?: () => Promise<{ exists: boolean, stages: { [id: string]: string } }>;
  deleteStage?: (target_stage_id?: number) => Promise<void>;
  successMessage?: string;
  errorMessage?: string;
  defaultStage?: StageState;
  showDeleteBtn?: boolean;
  disableDeleteBtn?: boolean;
};

interface State {
  stage: StageState;
  activeTab: TabPaneType;
  isLoading: boolean;
  isConnectedContent: boolean;
  isChecking: boolean;
  targetStages?: Array<TargetStage>;
  target_stage_id?: number;
};

export class WorkflowStageModal extends React.Component<Props, State> {
  state: State = {
    stage: this.props.defaultStage || {
      title: '',
      description: '',
      locked: false,
      can_version: false,
    },
    isLoading: false,
    isChecking: false,
    isConnectedContent: false,
    activeTab: TabPaneType.Edit,
    target_stage_id: undefined,
  };

  renderEditTab = () => {
    const { stage } = this.state;

    return (
      <Form layout="vertical">
        <Form.Item label="Title" required>
          <Input
            value={ stage.title }
            onChange={ (e) => {
              this.setState({
                stage: { ...stage, title: e.target.value },
              });
            } }
          />
        </Form.Item>
        <Form.Item label="Description" required>
          <TextArea
            rows={ 4 }
            value={ stage.description || '' }
            onChange={ (e) => {
              this.setState({
                stage: { ...stage, description: e.target.value },
              });
            } }
          />
        </Form.Item>
        <Form.Item label="Context" required>
          <Select
            defaultValue={ stage.context }
            onChange={ (stageContext: WorkflowStageContext) => {
              this.setState({
                stage: { ...stage, context: stageContext },
              });
            } }
          >
            { Object.entries(WorkflowStageContext).map(([title, value], i) => (
              <Option key={ value + i } value={ value }>
                { _.capitalize(title) }
              </Option>
            )) }
          </Select>
        </Form.Item>
        <Checkbox
          onChange={ (e) => {
            this.setState({
              stage: { ...stage, locked: e.target.checked },
            });
          } }
          checked={ stage.locked }
        >
          Locked
        </Checkbox>
        <Checkbox
          onChange={ (e) => {
            this.setState({
              stage: { ...stage, can_version: e.target.checked },
            });
          } }
          checked={ stage.can_version }
        >
          Can Version
        </Checkbox>
      </Form>
    );
  };

  renderDeleteTab = () => {
    const { isChecking, isConnectedContent, target_stage_id, targetStages } = this.state;

    return (
      <Spin tip="Loading..." spinning={ isChecking }>
        <p className="mT-10 mB-15">
          This will permanently delete this workflow stage and any linked transitions.
        </p>
        { isConnectedContent && !isChecking && !_.isEmpty(targetStages) && (
          <>
            <p className="mB-15">
              Please also choose the new stage you wish to remap content to. Any records using the
              deleted stage will automatically be moved to this new one.
            </p>
            <FieldWrapper
              label={ 'Target Stage' }
              required
            >
              <Select
                value={ target_stage_id }
                placeholder= { 'Please choose the new stage' }
                onChange={ (stageId: number) => this.setState({ target_stage_id: stageId }) }
              >
                { targetStages?.map(({ title, id }) => (
                  <Option key={ id } value={ id }>
                    { title }
                  </Option>
                )) }
              </Select>
            </FieldWrapper>
          </>
        ) }
        <p>Please confirm you wish to proceed.</p>
      </Spin>
    );
  };

  renderDeleteTabFooter = () => {
    const { onCancel, deleteStage } = this.props;
    const { isLoading, isChecking, isConnectedContent, target_stage_id } = this.state;

    const isDisabledDeleteBtn = () => {
      if (isChecking) {
        return true;
      }
      if (isConnectedContent && !target_stage_id) {
        return true;
      }
    };

    return (
      <div>
        <Button
          disabled={ isLoading }
          onClick={ () =>
            this.setState({
              activeTab: TabPaneType.Edit,
              isConnectedContent: false,
              target_stage_id: undefined,
            })
          }
        >
          Cancel
        </Button>
        <Button
          type="primary"
          danger
          disabled={ isDisabledDeleteBtn() }
          loading={ isLoading }
          onClick={ () =>
            this.setState({ isLoading: true }, async () => {
              try {
                await deleteStage?.(target_stage_id);
                Notification('success', '', 'Stage deleted');
              } catch (e) {
                Notification('error', '', 'Failed to delete stage');
              } finally {
                this.setState({ isLoading: false });
                onCancel();
              }
            })
          }
        >
          Delete
        </Button>
      </div>
    );
  };

  renderEditTabFooter = () => {
    const {
      successMessage,
      errorMessage,
      showDeleteBtn,
      defaultStage,
      disableDeleteBtn,
      onCancel,
      checkExistsEntities,
      request,
    } = this.props;
    const { stage, isLoading } = this.state;

    const isDisabledEditBtn = () => {
      // if the title is empty, disable the edit button
      if (!stage.title?.trim()) {
        return true;
      }

      // if no context is selected, disable the edit button
      if (!stage.context) {
        return true;
      }

      // if the data has not changed, disable the edit button
      if (_.isEqual(defaultStage, {
          ...stage,
          locked: Number(stage.locked),
          can_version: Number(stage.can_version),
        })
      ) {
        return true;
      }
    };

    return (
      <div className={ classNames({ "d-f fxd-r jc-sb": showDeleteBtn }) }>
        { showDeleteBtn && (
          <Tooltip placement="top" title={ disableDeleteBtn && 'You cannot remove the last stage in a workflow' }>
            <Button
              type="primary"
              danger
              disabled={ isLoading || disableDeleteBtn }
              onClick={ () =>
                this.setState({ activeTab: TabPaneType.Delete, isChecking: true }, async () => {
                  try {
                    const response = await checkExistsEntities?.();
                    if(response) {
                        const { exists, stages } = response;
                        const targets = Object.keys(stages)
                          .map((key) => ({ id: +key, title: stages[key] }))
                          .filter((target) => target.id !== stage.id);
                        this.setState({ isConnectedContent: exists, targetStages: targets });
                    }
                  } catch (error) {
                    console.log(error);
                  } finally {
                    this.setState({ isChecking: false });
                  }
                })
              }
            >
              Delete
            </Button>
          </Tooltip>
        ) }
        <div>
          <Button disabled={ isLoading } onClick={ onCancel }>
            Cancel
          </Button>
          <Button
            type="primary"
            loading={ isLoading }
            disabled={ isDisabledEditBtn() }
            onClick={ () =>
              this.setState({ isLoading: true }, async () => {
                try {
                  await request?.(stage);
                  successMessage && Notification('success', '', successMessage);
                } catch (e) {
                  errorMessage && Notification('error', '', errorMessage);
                } finally {
                  this.setState({ isLoading: false });
                  onCancel();
                }
              })
            }
          >
            OK
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { title, onCancel } = this.props;
    const { isLoading, activeTab } = this.state;
    const modalTitle = activeTab === TabPaneType.Delete ? 'Delete Stage' : title;

    return (
      <Modal
        visible
        centered
        title={ modalTitle }
        maskClosable={ !isLoading }
        onCancel={ onCancel }
        footer={ activeTab === TabPaneType.Edit ? this.renderEditTabFooter() : this.renderDeleteTabFooter() }
      >
        <TabPane active={ activeTab === TabPaneType.Edit } key={ TabPaneType.Edit }>
          { this.renderEditTab() }
        </TabPane>
        <TabPane active={ activeTab === TabPaneType.Delete } key={ TabPaneType.Delete }>
          { this.renderDeleteTab() }
        </TabPane>
      </Modal>
    );
  };
};
