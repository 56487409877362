// Libs
import React, { Component, BaseSyntheticEvent } from 'react';
import _ from 'lodash';

// Components
import { Button, Empty, Modal, Input, Tooltip } from 'antd';
import CommentComponent from 'components/comment';
import CoverModal from 'components/cover-modal';
import BlockingSpinner from 'components/blocking-spinner';
import OverlaySpinner from 'components/overlay-spinner';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import { Comment } from 'components/comment/Comment.interface';

// Styles
import './ActivityManagement.scss';

const { TextArea } = Input;

const API: Api = new Api();

interface Props {
  onCancel: () => void;
  onSave?: (comments: Comment[]) => void;
  endpoint: string;
  currentUserId?: number;
  canCreateComment?: boolean;
};

interface State {
  origignalComments: Comment[];
  comments: Comment[];
  activeComment: Comment | null;
  tempComment: string;
  isLoading: boolean;
  isUpdating: boolean;
  showCreateDialog: boolean;
  showReplyDialog: boolean;
  showEditDialog: boolean;
  showDeleteDialog: boolean;
};

class CommentsDialog extends Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    origignalComments: [],
    comments: [],
    activeComment: null,
    tempComment: '',
    isLoading: false,
    isUpdating: false,
    showCreateDialog: false,
    showReplyDialog: false,
    showEditDialog: false,
    showDeleteDialog: false,
  };

  componentDidMount = async () => {
    this.mounted = true;

    const { endpoint } = this.props;

    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const comments = await API.get(endpoint);

      this.mounted && this.setState({
        origignalComments: comments,
        comments: comments,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderCommentModal = (data: { title: string, okText: string, onSave: () => void, onCancel: () => void }) => {
    const { title, okText, onSave, onCancel } = data;
    const { isUpdating, tempComment } = this.state;

    return (
      <Modal
        visible
        centered
        title={ title }
        maskClosable={ !isUpdating }
        closable={ !isUpdating }
        okText={ okText }
        okButtonProps={{
          disabled: isUpdating || !tempComment,
          loading: isUpdating,
        }}
        cancelButtonProps={{
          disabled: isUpdating,
        }}
        onOk={ onSave }
        onCancel={ onCancel }
      >
        <TextArea
          autoSize={{ minRows: 4 }}
          autoFocus
          onFocus={ event => event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length) }
          onChange={ (event: BaseSyntheticEvent) => this.setState({ tempComment: event.target.value }) }
          value={ tempComment }
        />
      </Modal>
    );
  };

  renderCreateDialog = () => {
    const { endpoint} = this.props;
    const { tempComment } = this.state;

    const handleCreate = () => {
      this.setState({ isUpdating: true, showCreateDialog: false }, async () => {
        try {
          const comments = await API.post(endpoint, {
            data: JSON.stringify({ message: tempComment })
          });

          this.mounted && this.setState({
            comments: comments,
          }, () => {
            Notification('success', `Your comment has been created`, 'Comment Created');
          });

        } catch(error) {
          Notification('error', `Failed to create comment`);
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({
            tempComment: '',
            activeComment: null,
            isUpdating: false,
          });
        }
      });
    };

    const cancel = () => {
      this.setState({ tempComment: '', activeComment: null, showCreateDialog: false });
    };

    const props = {
      title: 'Create Comment',
      okText: 'Create',
      onSave: handleCreate,
      onCancel: cancel,
    };

    return this.renderCommentModal(props);
  };

  renderReplyDialog = (comment: Comment) => {
    const { endpoint} = this.props;
    const { tempComment } = this.state;

    const handleReply = () => {
      this.setState({ isUpdating: true, showReplyDialog: false }, async () => {
        try {
          const comments = await API.post(endpoint, {
            data: JSON.stringify({ parent_id: comment.id, message: tempComment })
          });

          this.mounted && this.setState({
            comments: comments,
          }, () => {
            Notification('success', `Sucessfully committed reply`);
          });

        } catch(error) {
          Notification('error', `Failed to commit reply`);
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({
            tempComment: '',
            activeComment: null,
            isUpdating: false,
          });
        }
      });
    };

    const cancel = () => {
      this.setState({ tempComment: '', activeComment: null, showReplyDialog: false });
    };

    const props = {
      title: 'Reply',
      okText: 'Reply',
      onSave: handleReply,
      onCancel: cancel,
    };

    return this.renderCommentModal(props);
  };

  renderEditDialog = (comment: Comment) => {
    const { endpoint} = this.props;
    const { tempComment } = this.state;

    const handleEdit = () => {
      this.setState({ isUpdating: true, showEditDialog: false }, async () => {
        try {
          const comments = await API.put(`${endpoint}/${comment.id}`, {
            data: JSON.stringify({ message: tempComment })
          });

          this.mounted && this.setState({
            comments: comments,
          }, () => {
            Notification('success', `Updated comment`);
          });

        } catch(error) {
          Notification('error', `Failed to edit comment`);
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({
            tempComment: '',
            activeComment: null,
            isUpdating: false,
          });
        }
      });
    };

    const cancel = () => {
      this.setState({ showEditDialog: false });
    };

    const props = {
      title: 'Edit comment',
      okText: 'Edit',
      onSave: handleEdit,
      onCancel: cancel,
    };

    return this.renderCommentModal(props);
  };

  renderDeleteDialog = (comment: Comment) => {
    const { endpoint} = this.props;
    const { isUpdating } = this.state;

    const handleDelete = () => {
      this.setState({ isUpdating: true, showDeleteDialog: false }, async () => {
        try {
          const comments = await API.delete(`${endpoint}/${comment.id}`);

          this.mounted && this.setState({
            comments: comments,
          }, () => {
            Notification('success', `Removed comment`);
          });

        } catch(error) {
          Notification('error', `Failed to remove comment`);
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({
            tempComment: '',
            activeComment: null,
            isUpdating: false,
          });
        }
      });
    };

    return (
      <Modal
        visible
        centered
        title={ 'Remove Comment' }
        maskClosable={ !isUpdating }
        closable={ !isUpdating }
        okButtonProps={{
          danger: true,
          disabled: isUpdating,
          loading: isUpdating,
        }}
        cancelButtonProps={{
          disabled: isUpdating,
        }}
        onOk={ handleDelete }
        onCancel={ () => this.setState({ showDeleteDialog: false, activeComment: null }) }
      >
        <p>Are you sure you want to remove this comment?</p>
      </Modal>
    );
  };

  renderList = (comments: Comment[]) => {
    const { canCreateComment, currentUserId } = this.props;

    const actions = [
      {
        type: 'reply',
        node: <span key="reply">Reply</span>,
        disabled: !canCreateComment,
        onClick: (comment: Comment) => this.setState({ showReplyDialog: true, activeComment: comment }),
      },
      {
        type: 'edit',
        node: <span key="edit">Edit</span>,
        disabled: true,
        onClick: (comment: Comment) => this.setState({ showEditDialog: true, activeComment: comment, tempComment: comment.message }),
      },
      {
        type: 'remove',
        node: <span key="remove">Remove</span>,
        disabled: true,
        onClick: (comment: Comment) => this.setState({ showDeleteDialog: true, activeComment: comment }),
      },
    ];

    return (
      <div className="pX-20 pY-20">
        <div className="ta-r pB-15">
          <Tooltip
            placement="topRight"
            title={ `You don't have permissions to create comments` }
            // don't show tooltip if it's possible to create a comment
            visible={ !!canCreateComment ? false : undefined }
          >
            <Button
              onClick={ () => this.setState({ showCreateDialog: true }) }
              disabled={ !canCreateComment }
            >
              Create Comment
            </Button>
          </Tooltip>
        </div>
          { _.isEmpty(comments) ? (
            <div className="d-f jc-c ai-c mH-500"><Empty description={ '' } /></div>
          ) : (
            <div className="CommentsContainer">
              <CommentComponent
                currentUserId={ currentUserId || Number.NaN }
                actions={ actions }
                onProfileClick={ () => {} }
                comments={ comments }
              />
            </div>
          ) }
      </div>
    );
  };

  render = () => {
    const { onCancel, onSave } = this.props;
    const {
      comments,
      origignalComments,
      isLoading,
      isUpdating,
      activeComment,
      showCreateDialog,
      showReplyDialog,
      showEditDialog,
      showDeleteDialog,
    } = this.state;

    return (
      <CoverModal
        style={{ minWidth: '900px', minHeight: '500px', maxHeight: '90vh' }}
        middleContent={ 'View Comments' }
        onClose={ () => {
          if (isLoading) return;

          if (!_.isEqual(comments, origignalComments)) {
            onSave?.(comments);
          }

          onCancel();
        } }
      >
        <BlockingSpinner isLoading={ isLoading } style={{ minHeight: '40vh' }}>
          { this.renderList(comments) }
          { showCreateDialog && this.renderCreateDialog() }
          { showReplyDialog && activeComment && this.renderReplyDialog(activeComment) }
          { showEditDialog && activeComment && this.renderEditDialog(activeComment) }
          { showDeleteDialog && activeComment && this.renderDeleteDialog(activeComment) }
        </BlockingSpinner>
        { isUpdating && <OverlaySpinner /> }
      </CoverModal>
    );
  };
};

export default CommentsDialog;