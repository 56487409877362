// Libs
import React, { Component } from 'react';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import BasicListView, { CreateOption } from "components/basic-list";
import { hasPermission } from 'components/restriction';

// View
import CreateRecordView from 'views/common/CreateRecordView';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import { RecordFormEntity } from 'types/entities';

const API: Api = new Api();

interface Props {
  clientId: number;
  record: RecordFormEntity;
  element: any;
};

interface State {
  data: any;
  bundle: string | null,
  type: string| null,
  showCreateModal: boolean;
  isCreating: boolean;
  isLoading: boolean;
};

class RelationField extends Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    data: null,
    bundle: null,
    type: null,
    showCreateModal: false,
    isCreating: false,
    isLoading: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.getData();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.record.updated_at !== this.props.record.updated_at) {
      this.getData();
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  getData = async () => {
    const { clientId, record, element } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const data = await API.get(`client/${clientId}/${record.bundle.replaceAll('_', '-')}/${record.type.replaceAll('_', '-')}/${ !!record.id ? `${record.id}` : '' }/relation/${element.id}`);

      this.mounted && this.setState({
        data: data
      });

    } catch (error) {
      Notification('error', 'Failed to fetch data', 'Failed');
    } finally {
      this.mounted && this.setState({ isLoading: false });
    }
  };

  render = () => {
    const { record, element } = this.props;
    const { data, showCreateModal, isCreating, isLoading } = this.state;
    const createOptions = element && _.has(element, 'data.config.create_options') ? element.data.config.create_options : [];
    const canCreate = element && _.has(element, 'data.config.can_create') ? element.data.config.can_create : false;
    const actions: DropdownAction[] = [];

    if (!_.isEmpty(createOptions) && !!canCreate) {

      createOptions.forEach((createOption: CreateOption, index: number) => {

        if (!index && createOptions.length > 1) {
          actions.push({
            node: '',
            onClick: () => {}
          });
        }

        if (hasPermission(record.permissions, `${createOption.bundle}_${_.snakeCase(createOption.type)}_create`)) {
          actions.push({
            node: `Create ${createOption.label}`,
            onClick: () => this.setState({
              type: createOption.type,
              bundle: createOption.bundle,
              showCreateModal: true,
              isCreating: true,
            }),
            isLoading: isCreating
          });
        }
      });
    }

    return (
      <FieldWrapper
        col={ element.config.column_span }
        required={ false }
      >
        <BasicListView
          exportTitle={ record.title }
          isLoading={ isLoading }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
          columns={ _.has(data, 'columns') ? data.columns : [] }
          items={ _.has(data, 'data') ? data.data : [] }
        />
        { showCreateModal && !!this.state.type && !!this.state.bundle &&
          <CreateRecordView
            type={ _.kebabCase(this.state.type) }
            entity={ _.kebabCase(this.state.bundle) }
            parent_id={ element.data.parent_id }
            parent_bundle={ element.data.parent_bundle }
            parent_type={ element.data.parent_type }
            onReady={ () => this.mounted && this.setState({ isCreating: false }) }
            onClose={ () => this.mounted && this.setState({
              type: null,
              bundle: null,
              showCreateModal: false,
            }) }
            onCreated={ () => this.mounted && this.setState({
              type: null,
              bundle: null,
              showCreateModal: false,
            }, () => this.getData() ) }
          />
        }
      </FieldWrapper>
    );
  };
};

export default RelationField;
