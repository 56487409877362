// Libs
import React, { BaseSyntheticEvent } from 'react';
import { Form, Input, Modal } from 'antd';
import _ from 'lodash';

// Interfaces
import { RecordFormEntity } from 'types/entities';
import { OnClone, OnLoading } from 'components/form/form-wrapper/FormWrapper.interface';

interface Props {
  onClose?: () => void;
  onLoading?: OnLoading;
  onCopy?: OnClone;
  record: RecordFormEntity;
  isCopying: boolean;
};

interface State {
  title: string;
};

class MaintenancePlanCopyModal extends React.Component<Props, State> {

  state: State = {
    title: `${this.props.record.title} - Copy`
  };

  render = () => {
    const { title } = this.state;
    const { isCopying, record, onLoading, onCopy, onClose } = this.props;
    return (
      <Modal
        visible
        centered
        maskClosable={ !isCopying }
        closable={ !isCopying }
        title={ 'Copy Maintenance Plan' }
        okText={ 'Copy' }
        onOk={ () => {
          if (onLoading) {
            onLoading(() => onCopy && onCopy(record.id, [], () => onClose && onClose(), title));
          }
        } }
        onCancel={ () => onClose && onClose() }
        okButtonProps={ {
          loading: isCopying,
          disabled: _.isEmpty(title)
        } }
        cancelButtonProps={ {
          disabled: isCopying
        } }
      >
        <p>This will create a new copy of the maintenance plan with all children records for Asset Category and Asset Type.</p>
        <div className="mT-20">
          <Form layout="horizontal">
            <Form.Item
              label="Title"
              required
            >
              <Input
                autoComplete="off"
                value={ title }
                onChange={ (event: BaseSyntheticEvent) => {
                  this.setState({
                    title: event.target.value
                  });
                } }
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  };
};

export default MaintenancePlanCopyModal;
