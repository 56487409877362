// Libs
import * as React from 'react';

// Components
import DocumentView from 'views/common/DocumentView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
};

class QuoteDocuments extends React.Component<Props> {
  render = () => {
    return <DocumentView type={ 'quote' } record={ this.props.record } entity={ 'record' } />;
  };
};

export default QuoteDocuments;
