// Libs
import * as React from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Views
import SelectListOptions from 'views/admin/content-manager/select-lists/SelectListOptions';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';

interface Props extends RouteComponentProps {
  permissions: any;
};

class SelectListContainer extends React.Component<Props> {

  render = () => {
    return (
      <Switch>

        <Redirect exact from="/admin/content-manager/select-lists/:type/:id" to="/admin/content-manager/select-lists/:type/:id/record" />

        <Route exact path="/admin/content-manager/select-lists/:type" render={ () => {
          return (
            <RestrictionWrapper restricted={ !hasPermission(this.props.permissions, 'access_admin_content_manager') && 'No access' }>
              <SelectListOptions />
            </RestrictionWrapper>
          );
        } } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(SelectListContainer);
