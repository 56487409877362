// Libs
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { TreeSelect, Typography } from 'antd';
import Map, { IMarker } from 'components/map';
import FilterComponent from 'components/basic-list/Filters';
import { recordDataMapping } from 'components/basic-list/BasicList';
import { Link as RouterLink } from 'react-router-dom';

// Icons
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg';
import Icon from '@ant-design/icons';

// Interfaces
import AppState from 'store/AppState.interface';

export interface ILocation {
  key: string;
  title: string;
  region: string;
  latitude: string;
  longitude: string;
};

interface Props {
  columns: any;
  items: any;
  dateRangeFilterFields?: string[];
  defaultFilters?: any;
};

interface State {
  items: any;
  tableHeight: number;
  showFilter: boolean;
};

class MapListView extends React.Component<Props, State> {

  component: any = React.createRef(); // This is used to figure out when the component is rendered

  state: State = {
    items: _.cloneDeep(recordDataMapping(this.props.items)),
    tableHeight: 0,
    showFilter: false,
  };

  componentDidMount = async () => {
    if (this.component) {
      this.heightObserver();
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.component) {
      this.heightObserver();
    }
  };

  heightObserver = () => {
    const root: number = document.getElementById('root')?.offsetHeight || 0;
    const header: number = document.getElementById('Header')?.offsetHeight || 0;
    const jumbotron: number = document.getElementById('Jumbotron')?.offsetHeight || 0;
    const tabViewBar: number = document.getElementById('TabView-bar')?.offsetHeight || 0;
    const filtersHeight: number = document.getElementById('MapListFilter')?.offsetHeight || 0;
    const tableHeight: number = root - (header + jumbotron + tabViewBar + filtersHeight + 110); // 80 is spill from misc margins and paddings that's to small to target.

    if (this.state.tableHeight !== tableHeight) {
      this.setState({
        tableHeight: tableHeight
      });
    }
  };

  renderTooltipHeader = (path: string, title: string) => {
    return (
      <RouterLink className='d-f primaryColor' to={ path }>
        { title }
      </RouterLink>
    );
  };

  renderTooltip = (item: any) => {
    return (
      <div>
        { item?.ownership?.value && <div>Ownership: <b>{ `${item?.ownership?.value}` }</b></div> }
        { item?.region?.title && <div>Region: <b>{ `${item?.region?.title}` }</b></div> }
      </div>
    );
  };

  render = () => {
    const { columns } = this.props;
    const { tableHeight, items, showFilter } = this.state;

    const markers: IMarker[] = items
      .filter((item: any) => {
        return item?.map_position?.latitude && item?.map_position?.longitude;
      })
      .map((item: any) => {
        return {
          latitude: item?.map_position?.latitude,
          longitude: item?.map_position?.longitude,
          tooltip: {
            header: !!item?.title ? this.renderTooltipHeader(item.title.value.path, item.title.value.title) : '-',
            content: this.renderTooltip(item)
          }
        };
      });

    return (
      <>
        <div className="d-if mL-10 mB-10">
          <span>Showing<b className="mL-5 mR-5">{ markers.length || 0 }</b>entries of<b className="mL-5">{ this.props.items.length || 0 }</b></span>
          <span
            className={ classNames('mL-35 link', {
              'text-ant-disabled disabled': _.isEmpty(columns) || _.isEmpty(items),
              'active': showFilter
            }) }
            onClick={ () => {
              this.setState({
                showFilter: !showFilter
              });
            } }
          >
            <Icon
              component={ FilterIcon }
            />
            <span>Filter</span>
          </span>
        </div>
        <div id="MapListFilter" ref={ node => (this.component = node) }>
          { showFilter &&
            <FilterComponent
              columns={ columns.map((column: any) => {
                return {
                  ...column,
                  key: column.id,
                };
              } ) }
              items={ recordDataMapping(this.props.items) }
              dateRangeFilterFields={ this.props.dateRangeFilterFields }
              onFilter={ (_items: any[]) => {
                this.setState({
                  items: _items
                });
              } }
            />
          }
        </div>
        <div className='Layout-box'>
          <div style={{ height: tableHeight, width: '100%' }}>
            <Map cluster markers={ markers } />
          </div>
        </div>
      </>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
  };
};

export default connect(mapStateToProps, null)(MapListView);
