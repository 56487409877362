// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import BasicList from 'components/basic-list';
import Jumbotron from 'components/jumbotron';
import { RestrictionHoC } from 'components/restriction';

// Views
import FinanceTemplateModal from 'views/admin/templates/finance/FinanceTemplateModal';

// Services
import { Api } from 'services/api';
import { getFormatedDate } from 'services/settings';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Icons
import { EditOutlined } from '@ant-design/icons';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { FinanceTemplate as IFinanceTemplate, FinanceTemplateType as IFinanceTemplateType, CategoryYear as ICategoryYear } from 'views/admin/templates/Templates.interfaces';

const API: Api = new Api();

interface Props {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  templateType: IFinanceTemplateType | null,
  templates: IFinanceTemplate[],
  newTemplate: Partial<IFinanceTemplate> | null;
  activeTemplate: IFinanceTemplate | null;
  isLoading: boolean;
  isAddingTemplate: boolean;
  isDeletingTemplate: boolean;
  isEditMode: boolean;
  showTemplateModal: boolean;
};

class FinanceTypes extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    templateType: null,
    templates: [],
    newTemplate: null,
    activeTemplate: null,
    isLoading: false,
    isAddingTemplate: false,
    isDeletingTemplate: false,
    isEditMode: false,
    showTemplateModal: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const finance_template_type_id = this.props.match.params.finance_template_type_id;

    this.mounted = true;

    try {

      this.props.setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const templates = await API.get(`client/${client_id}/admin/templates/finance/types/${finance_template_type_id}/templates`);
      const templateType = await API.get(`client/${client_id}/admin/templates/finance/types/${finance_template_type_id}`);

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Template Types', path: '/admin/templates/finance' },
        { title: `${templateType.title} Templates`, path: null },
      ], false);

      this.mounted && this.setState({
        templates: templates,
        templateType: templateType,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderTemplates = () => {
    const finance_template_type_id = this.props.match.params.finance_template_type_id;
    const { client_id } = this.props;
    const { templates, isLoading, isDeletingTemplate, activeTemplate, showTemplateModal } = this.state;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Title',
          render: (title: string, record: IFinanceTemplate) => {
            return (
              <Link className='primaryColor' to={ `/admin/templates/finance/type/${finance_template_type_id}/template/${record.id}` }>
                { title }
              </Link>
            );
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'category_years',
          dataIndex: 'category_years',
          title: 'Years',
          render: (years: ICategoryYear[]) => {
            if (_.isEmpty(years)) {
              return '-';
            }
            return years.map((categoryYear: ICategoryYear) => categoryYear.title).join(', ');
          },
          ellipsis: true,
          filterable: false,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: string) => getFormatedDate(created_at, undefined, true),
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
        {
          key: 'actions',
          dataIndex: 'actions',
          title: '',
          render: (__: any, template: IFinanceTemplate) => {
            return (
              <EditOutlined
                className="link"
                style={{ fontSize: 18 }}
                onClick={ () => {
                  this.setState({ showTemplateModal: true, activeTemplate: template });
                } }
              />
            );
          },
          width: 120,
          ellipsis: true,
          align: 'center' as 'center',
        },
      ];
    };

    const mapData = (templates: IFinanceTemplate[]) => {
      return templates.map((template: IFinanceTemplate, index: number) => {
        return {
          'key': index,
          ...template
        };
      });
    };

    return (
      <>
        <BasicList
          rawData
          isLoading={ isDeletingTemplate }
          columns={ mapColumns() }
          items={ mapData(templates) }
        />
        { showTemplateModal && (
          <FinanceTemplateModal
            client_id={ client_id }
            template={ activeTemplate || undefined }
            onSave={ async (template: IFinanceTemplate) => {
              try {

                const newTemplate = await API.put(`client/${client_id}/admin/templates/finance/types/${finance_template_type_id}/templates/${template.id}/columns`, {
                  data: template
                });

                const index = templates.findIndex((_template: IFinanceTemplate) => _template.id === template.id);

                this.mounted && this.setState({
                  templates: _.set(_.cloneDeep(templates), index, newTemplate),
                });

              } catch (error) {
                console.error('Error: ', error);
              } finally {
                this.mounted && this.setState({
                  showTemplateModal: false,
                  activeTemplate: null
                });
              }
            } }
            onClose={ () => this.setState({
              showTemplateModal: false,
              activeTemplate: null
            }) }
          />
        ) }
      </>
    );
  };

  render = () => {
    const { isLoading, templateType } = this.state;

    if (!templateType) return <></>;

    return (
      <BlockingSpinner isLoading={ isLoading }>
        <Jumbotron
          content={ `${templateType.title} Templates` }
          tabs={[
            {
              label: 'Overview',
              node: this.renderTemplates(),
            }
          ]}
        />
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(FinanceTypes), 'access_admin_content_manager'));
