// Libs
import React, { BaseSyntheticEvent } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import Stepper from 'components/stepper';
import CoverModal from 'components/cover-modal';
import { Empty, Form, Select, Popconfirm, Button, Input, Table, Tooltip, InputNumber, Tag, Switch } from 'antd';
import DragSortingList from 'components/drag-sorting-list';
import Insight from 'components/insight';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';

// Views
import AddColumns from 'views/insight/AddColumns';
import AddFormula from 'views/insight/AddFormula';
import AddFilters from 'views/insight/AddFilters';
import AddSorter from 'views/insight/AddSorter';
import AddRoles from 'views/insight/AddRoles';
import SettingsDialog, { ALLOWED_SETTINGS } from 'views/insight/SettingsDialog';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Icons
import Icon, { MenuOutlined, DeleteOutlined, QuestionCircleOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { ReactComponent as BarChartIcon } from 'assets/svg/charts/bar.svg';
import { ReactComponent as PieChartIcon } from 'assets/svg/charts/pie.svg';
import { ReactComponent as ColumnChartIcon } from 'assets/svg/charts/column.svg';
import { ReactComponent as NoneChartIcon } from 'assets/svg/charts/none.svg';

// Utils
import { arrayMoveImmutable } from 'utils/formSetup';
import { nestedSet, flattenSet, modifyNestedSetItem, removeNestedSetItem } from 'utils/utils';
import history from 'utils/history';

// Interfaces
import { IStep } from 'components/stepper/Stepper.interface';
import { TableType } from 'components/drag-sorting-list/DragSortingList.interfaces';
import { IInsight, IColumn, IPreview, ITool, IModule, IModuleJoin, IFilter, ISorter, IRole, ISettings, ColumnType, OnSaveCallback } from 'components/insight/Insight.interfaces';

// Styles
import './InsightModal.scss';

const API: Api = new Api();

export const getTags = (settings: any) => {
  const tags: { primaryTags: React.ReactNode[], otherTags: string[] } = {
    primaryTags: [],
    otherTags: [],
  };

  Object.keys(settings)
    .filter((setting: string) => {
      return ALLOWED_SETTINGS.includes(setting);
    })
    .forEach((setting: string, index: number) => {
      if (_.has(settings, [setting]) && ![null, false, 200].includes(settings[setting])) {
        switch (setting) {
          case 'group_by':
              tags.primaryTags.push(<Tag key={ index } color={ 'default' }>{ 'Grouped' }</Tag>);
            break;

          case 'aggregator':
              tags.primaryTags.push(<Tag key={ index } color={ 'default' }>{ 'Aggregator: ' }<b>{ _.startCase(_.toLower(settings[setting])) }</b></Tag>);
            break;

          default:
              tags.otherTags.push(_.startCase(_.toLower(setting)).replaceAll('_', ' '));
            break;
        }
      };
    });

  return tags;
};

export const renderTags = (settings: ISettings, onClick: () => void = () => {}) => {
  const tags = getTags(settings);
  const node: React.ReactNode[] = [];

  tags.primaryTags.forEach((tag: React.ReactNode) => {
    node.push(tag);
  });

  if (!_.isEmpty(tags.otherTags)) {
    node.push((
      <Tooltip
        key={ 'other' }
        placement="top"
        title={ tags.otherTags.map((tag: string) => tag).join('\n') }
      >
        <Tag onClick={ onClick } color={ 'default' }>{ `+ ${tags.otherTags.length}` }</Tag>
      </Tooltip>
    ));
  }

  return node;
};

export const changeOrder = (data: any, dragIndex: number, hoverIndex: number) => {
  if (dragIndex !== hoverIndex) {
    return arrayMoveImmutable(data, dragIndex, hoverIndex).filter((element) => !!element).map((row: any) => ({ ...row }));
  }
  return data;
};

interface Props {
  clientId: number;
  insightId: number | null;
  onSave(insight: IInsight, callback: OnSaveCallback): void;
  onClose(): void;
};

interface State {
  insight: IInsight;
  preview: IPreview | null;
  availableTools: ITool[];
  availableModules: IModule[];
  columnDeletionId: string | null;
  filterDeletionId: string | null;
  sorterDeletionId: string | null;
  roleDeletionId: string | null;
  showAddColumns: boolean;
  showAddFormula: boolean;
  editFormulaId: string | null;
  showAddFilters: boolean;
  showAddSorters: boolean;
  showAddRoles: boolean;
  settingDialog: { type: string, id: string } | null;
  isFetchingTools: boolean;
  isFetchingModules: boolean;
  isFetching: boolean;
  isSaving: boolean;
  isGeneratingPreview: boolean;
};

class InsightModal extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    insight: {
      title: null,
      tool_id: null,
      module_id: null,
      module_joins: [],
      limit: null,
      columns: [],
      filters: [],
      sorters: [],
      roles: [],
      chart: null,
    },
    preview: null,
    availableTools: [],
    availableModules: [],
    columnDeletionId: null,
    filterDeletionId: null,
    sorterDeletionId: null,
    roleDeletionId: null,
    showAddColumns: false,
    showAddFormula: false,
    editFormulaId: null,
    showAddFilters: false,
    showAddSorters: false,
    showAddRoles: false,
    settingDialog: null,
    isFetchingTools: false,
    isFetchingModules: false,
    isFetching: false,
    isSaving: false,
    isGeneratingPreview: false,
  };

  componentDidMount = () => {
    const { clientId, insightId } = this.props;
    this.mounted = true;

    if (!!insightId) {
      this.fetchInsight(clientId, insightId);
    } else {
      this.fetchTools(clientId);
      this.fetchModules(clientId);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchInsight = async (clientId: number, insightId: number) => {
    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const insight = await API.get(`client/${clientId}/insight/builder/report/${insightId}`);
      await this.fetchTools(clientId);
      await this.fetchModules(clientId);

      this.mounted && this.setState({
        insight: insight
      });
    } catch (error) {
      Notification('error', 'Failed to fetch insight', 'Failed');
    } finally {
      this.mounted && this.setState({ isFetching: false });
    }
  };

  fetchTools = async (clientId: number) => {
    try {
      await new Promise((resolve) => this.setState({ isFetchingTools: true }, () => resolve(null)));

      const tools = await API.get(`client/${clientId}/insight/builder/tools`);

      this.mounted && this.setState({
        availableTools: tools
      });
    } catch (error) {
      Notification('error', 'Failed to fetch tools', 'Failed');
    } finally {
      this.mounted && this.setState({ isFetchingTools: false });
    }
  };

  fetchModules = async (clientId: number) => {
    try {
      await new Promise((resolve) => this.setState({ isFetchingModules: true }, () => resolve(null)));

      const modules = await API.get(`client/${clientId}/insight/builder/modules`);

      this.mounted && this.setState({
        availableModules: modules
      });
    } catch (error) {
      Notification('error', 'Failed to fetch modules', 'Failed');
    } finally {
      this.mounted && this.setState({ isFetchingModules: false });
    }
  };

  purgeInsight = (insight: IInsight): IInsight => {
    const primaryModuleId = insight.module_id;
    const moduleJoinIds = flattenSet(insight.module_joins).map((moduleJoin: IModuleJoin) => moduleJoin.target_module_id);
    const moduleIds = [primaryModuleId].concat(moduleJoinIds);

    return {
      ...insight,
      columns: insight.columns.filter((column: IColumn) => moduleIds.includes(column.module_id)),
      filters: insight.filters.filter((filter: IFilter) => moduleIds.includes(filter.module_id)),
      sorters: insight.sorters.filter((sorter: ISorter) => moduleIds.includes(sorter.module_id)),
    };
  };

  getIconComponent = (key: string) => {
    const icons: { [key: string]: React.ReactNode } = {
      'bar': BarChartIcon,
      'pie': PieChartIcon,
      'column': ColumnChartIcon,
      'none': NoneChartIcon,
    };
    return icons[key] || false;
  };

  generatePreview = async (insight: IInsight) => {
    try {
      await new Promise((resolve) => this.setState({ isGeneratingPreview: true }, () => resolve(null)));

      const preview = await API.post(`client/${this.props.clientId}/insight/builder/report/preview`, {
        data: insight
      });

      this.mounted && this.setState({
        preview: preview
      });
    } catch (error) {
      Notification('error', 'Failed to generate insight', 'Failed');
    } finally {
      this.mounted && this.setState({ isGeneratingPreview: false });
    }
  };

  getFlattenJoins = (modules: IModule[]) => {
    return modules.reduce((acc: any[], curr: IModule) => acc.concat(...curr.joins), []);
  };

  getErrors = (insight: IInsight) => {
    const errors: { [key: string]: string } = {};

    if (!insight.title) {
      errors.title = '* Title required';
    }

    if (!insight.tool_id) {
      errors.tool = '* Tool required';
    }

    if (!insight.module_id) {
      errors.primary_module = '* At least one module is required';
    }

    if (_.isEmpty(insight.columns)) {
      errors.columns = '* At least one column is required';
    }

    if (
      (['PIE'].includes(insight?.chart?.type || '') && (!insight?.chart?.config?.key_field || !insight?.chart?.config?.value_field)) ||
      (['BAR', 'COLUMN'].includes(insight?.chart?.type || '') && (!insight?.chart?.config?.x_field || !insight?.chart?.config?.y_field))
    ) {
      errors.columns = '* Missing chart config';
    }

    return _.isEmpty(errors) ? false : errors;
  };

  renderFormulaTag = (onClick: () => void = () => {}) => {
    return <Tag onClick={ onClick } color={ 'default' }>Calculated</Tag>;
  };

  renderColorConfig = (insight: IInsight) => {
    return (
      <Select
        allowClear
        style={{ width: 250 }}
        placeholder={ 'Colour Column' }
        dropdownMatchSelectWidth={ false }
        onClear={ () => {
          this.setState({
            insight: {
              ..._.set(insight, 'chart.config.color_field', null),
              ..._.set(insight, 'chart.config.show_legend', false)
            }
          });
        } }
        onSelect={ (value: string) => {
          this.setState({
            insight: {
              ..._.set(insight, 'chart.config.color_field', value),
              ..._.set(insight, 'chart.config.show_legend', false)
            },
          });
        } }
        value={ insight.chart?.config?.color_field }
      >
        { insight.columns.map((column: IColumn, index: number) => (
          <Select.Option key={ index } value={ column.id }>{ column?.settings?.custom_title || column.title }</Select.Option>
        )) }
      </Select>
    );
  };

  renderGroupConfig = (insight: IInsight) => {
    return (
      <>
        <Select
          allowClear
          style={{ width: 250 }}
          placeholder={ 'Grouped Column' }
          dropdownMatchSelectWidth={ false }
          onClear={ () => {
            this.setState({
              insight: _.set(insight, 'chart.config.group_field', null),
            });
          } }
          onSelect={ (value: string) => {
            this.setState({
              insight: _.set(insight, 'chart.config.group_field', value),
            });
          } }
          value={ insight.chart?.config?.group_field }
        >
          { insight.columns.map((column: IColumn, index: number) => (
            <Select.Option key={ index } value={ column.id }>{ column?.settings?.custom_title || column.title }</Select.Option>
          )) }
        </Select>
        { !!insight.chart?.config?.group_field &&
          <Select
            className="mL-10"
            allowClear
            style={{ width: 200 }}
            placeholder={ 'Mode' }
            dropdownMatchSelectWidth={ false }
            onClear={ () => {
              this.setState({
                insight: _.set(insight, 'chart.config.group_mode', null),
              });
            } }
            onSelect={ (mode: string) => {
              this.setState({
                insight: _.set(insight, 'chart.config.group_mode', _.upperCase(mode))
              });
            } }
            value={ !!insight.chart?.config?.group_mode ? _.toLower(insight.chart?.config?.group_mode) : undefined }
          >
            { ['stack', 'group', 'percent'].map((mode: string) => (
              <Select.Option key={ mode } value={ mode }>{ _.upperFirst(_.toLower(mode)) }</Select.Option>
            )) }
          </Select>
        }
      </>
    );
  };

  renderLegendConfig = (insight: IInsight) => {

    if (!!insight.chart?.config?.color_field) {
      return (
        <Tooltip
          placement="top"
          title={ 'Not available for coloured charts' }
        >
          <Switch
            disabled={ !!insight.chart?.config.color_field }
          />
        </Tooltip>
      );
    }

    return (
      <Switch
        onChange={ (checked: boolean) => {
          this.setState({
            insight: _.set(insight, 'chart.config.show_legend', checked),
          });
        } }
        checked={ insight.chart?.config?.show_legend }
      />
    );
  };

  renderDefaultPagination = (insight: IInsight) => {
    return (
      <Select
        allowClear
        style={{ width: 250 }}
        placeholder={ '5' }
        dropdownMatchSelectWidth={ false }
        onClear={ () => {
          this.setState({
            insight: {
              ..._.set(insight, 'chart.config.default_pagination', null),
            }
          });
        } }
        onSelect={ (value: number) => {
          this.setState({
            insight: {
              ..._.set(insight, 'chart.config.default_pagination', value),
            },
          });
        } }
        value={ insight.chart?.config?.default_pagination }
      >
        { [5, 10, 20].map((option: number) => (
          <Select.Option key={ option } value={ option }>
            { option }
          </Select.Option>
        )) }
      </Select>
    );
  };

  renderChartConfig = (insight: IInsight) => {
    switch (_.toLower(insight.chart?.type)) {
      case 'bar':
      case 'column':
        return (
          <div>
            <span>
              <Select
                style={{ width: 250 }}
                placeholder={ 'X-axis' }
                dropdownMatchSelectWidth={ false }
                onClear={ () => {
                  this.setState({
                    insight: _.set(insight, 'chart.config.x_field', null),
                    preview: null,
                  });
                } }
                onSelect={ (value: string) => {
                  this.setState({
                    insight: _.set(insight, 'chart.config.x_field', value),
                    preview: null,
                  });
                } }
                value={ insight.chart?.config?.x_field }
              >
                { insight.columns.map((column: IColumn, index: number) => (
                  <Select.Option key={ index } value={ column.id }>{ column?.settings?.custom_title || column.title }</Select.Option>
                )) }
              </Select>
            </span>
            <span className='mL-10'>
              <Select
                style={{ width: 250 }}
                placeholder={ 'Y-axis' }
                dropdownMatchSelectWidth={ false }
                onClear={ () => {
                  this.setState({
                    insight: _.set(insight, 'chart.config.y_field', null),
                    preview: null,
                  });
                } }
                onSelect={ (value: string) => {
                  this.setState({
                    insight: _.set(insight, 'chart.config.y_field', value),
                    preview: null,
                  });
                } }
                value={ insight.chart?.config?.y_field }
              >
                { insight.columns.map((column: IColumn, index: number) => (
                  <Select.Option key={ index } value={ column.id }>{ column?.settings?.custom_title || column.title }</Select.Option>
                )) }
              </Select>
            </span>
          </div>
        );
      case 'pie':
        return (
          <div>
            <span>
              <Select
                style={{ width: 250 }}
                placeholder={ 'Key' }
                dropdownMatchSelectWidth={ false }
                onClear={ () => {
                  this.setState({
                    insight: _.set(insight, 'chart.config.key_field', null),
                    preview: null,
                  });
                } }
                onSelect={ (value: string) => {
                  this.setState({
                    insight: _.set(insight, 'chart.config.key_field', value),
                    preview: null,
                  });
                } }
                value={ insight.chart?.config?.key_field }
              >
                { insight.columns.map((column: IColumn, index: number) => (
                  <Select.Option key={ index } value={ column.id }>{ column?.settings?.custom_title || column.title }</Select.Option>
                )) }
              </Select>
            </span>
            <span className='mL-10'>
              <Select
                style={{ width: 250 }}
                placeholder={ 'Value' }
                dropdownMatchSelectWidth={ false }
                onClear={ () => {
                  this.setState({
                    insight: _.set(insight, 'chart.config.value_field', null),
                    preview: null,
                  });
                } }
                onSelect={ (value: string) => {
                  this.setState({
                    insight: _.set(insight, 'chart.config.value_field', value),
                    preview: null,
                  });
                } }
                value={ insight.chart?.config?.value_field }
              >
                { insight.columns.map((column: IColumn, index: number) => (
                  <Select.Option key={ index } value={ column.id }>{ column?.settings?.custom_title || column.title }</Select.Option>
                )) }
              </Select>
            </span>
          </div>
        );
    }
  };

  getFirstAvailablePrimaryJoin = (insight: IInsight, availableModules: IModule[]) => {

    const joins = nestedSet(_.cloneDeep(insight.module_joins));
    const joinIdsInUse = flattenSet(joins).map((__join: IModuleJoin) => __join.target_module_id);

    const currentPrimaryModuleJoins = availableModules.find((module: IModule) => module.id === insight.module_id);
    let filteredCurrentPrimaryModuleJoins: IModuleJoin[] = [];

    if (currentPrimaryModuleJoins && !_.isEmpty(currentPrimaryModuleJoins.joins)) {
      filteredCurrentPrimaryModuleJoins = currentPrimaryModuleJoins.joins
        .filter((_join: IModuleJoin) => {
          return !joinIdsInUse.includes(_join.target_module_id);
        });
    }

    return !_.isEmpty(filteredCurrentPrimaryModuleJoins) ? filteredCurrentPrimaryModuleJoins[0] : [];
  };

  renderStepOne = (
    insight: IInsight,
    availableTools: ITool[],
    availableModules: IModule[],
  ): IStep => {

    const joins = nestedSet(_.cloneDeep(insight.module_joins));
    const joinIdsInUse = flattenSet(joins).map((__join: IModuleJoin) => __join.target_module_id);
    const availableJoins = this.getFlattenJoins(availableModules);
    const availablePrimaryModules = availableModules
      .filter((module: IModule) => {
        return module.tool_id === insight.tool_id;
      })
      .filter((module: IModule) => {
        return !joinIdsInUse.includes(module.id);
      });

    const firstAvailablePrimaryJoin = this.getFirstAvailablePrimaryJoin(insight, availableModules);

    return {
      title: 'Choose reporting area',
      component: (
        <div>
          <p>This determines the key data represented by each row of your insight</p>
          <div className="mT-20">
            <div>
              <div>
                <span className="fw-600">Tool:</span><span className="text-required mL-2 fsz-md va-t">*</span>
              </div>
              <div>
                <Select
                  style={{ width: 200 }}
                  loading={ this.state.isFetchingTools }
                  placeholder={ 'Please select' }
                  dropdownMatchSelectWidth={ false }
                  value={ insight.tool_id || undefined }
                  onChange={ (tool_id: number) => {
                    this.setState({
                      insight: {
                        ...insight,
                        tool_id: tool_id,
                        module_id: null,
                        module_joins: [],
                        columns: [],
                        sorters: [],
                        filters: [],
                        roles: [],
                      },
                      preview: null,
                    });
                  } }
                >
                  { availableTools
                    .map((tool: ITool, index: number) => (
                      <Select.Option key={ index } value={ tool.id }>{ tool.title }</Select.Option>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div className="mT-20">
              <div>
                <span className="fw-600">Modules:</span><span className="text-required mL-2 fsz-md va-t">*</span>
              </div>
              <div>
                <Select
                  style={{ width: 200 }}
                  loading={ this.state.isFetchingModules }
                  disabled={ !insight.tool_id || this.state.isFetchingModules }
                  placeholder={ 'Please select' }
                  dropdownMatchSelectWidth={ false }
                  value={ insight.module_id || undefined }
                  onChange={ (module_id: string) => {
                    this.setState({
                      insight: this.purgeInsight({
                        ...insight,
                        module_id: module_id,
                        module_joins: []
                      }),
                      preview: null,
                    });
                  } }
                >
                  { availablePrimaryModules
                    .map((module: IModule, index: number) => (
                      <Select.Option key={ index } value={ module.id }>{ module.title }</Select.Option>
                    ))
                  }
                </Select>
                { !_.isEmpty(firstAvailablePrimaryJoin) &&
                  <Button
                    className="mL-5"
                    onClick={ () => {
                      this.setState({
                        insight: {
                          ...insight,
                          module_joins: _.cloneDeep(insight.module_joins).concat(firstAvailablePrimaryJoin),
                        },
                        preview: null,
                      });
                    } }
                    style={{
                      padding: '4px 7px',
                      width: '32px',
                    }}
                  >
                    <PlusOutlined />
                  </Button>
                }
              </div>
            </div>
            <div className="mT-10 mB-10 mL-20">
              { !_.isEmpty(joins) &&
                <Table
                  className="InsightModalTable InsightModalJoinsTable"
                  size={ 'small' }
                  showHeader={ false }
                  columns={ [
                    {
                      title: '',
                      dataIndex: '',
                      render: (__: any, join: IModuleJoin) => {

                        // Get the first available join
                        const firstJoinableJoin: IModuleJoin | undefined = availableJoins
                          .filter((_join: IModuleJoin) => {
                            return _join.target_module_id !== insight.module_id;
                          })
                          .filter((_join: IModuleJoin) => {
                            return !flattenSet(insight.module_joins).map((__join: IModuleJoin) => __join.target_module_id).includes(_join.target_module_id);
                          })
                          .find((_join: IModuleJoin) => {
                            return _join.source_module_id === join.target_module_id;
                          });

                        return (
                          <>
                            <Select
                              style={{ width: 200 }}
                              allowClear
                              onClear={ () => {
                                this.setState({
                                  insight: this.purgeInsight({
                                    ...insight,
                                    module_joins: removeNestedSetItem(join.id, insight.module_joins),
                                  }),
                                  preview: null,
                                });
                              } }
                              disabled={ !insight.module_id }
                              placeholder={ 'Please select' }
                              dropdownMatchSelectWidth={ false }
                              value={ join?.id || undefined }
                              onChange={ (moduleJoinId: string) => {

                                const __join: IModuleJoin | undefined = availableJoins.find((_moduleJoin: IModuleJoin) => _moduleJoin.id === moduleJoinId);
                                const exist = flattenSet(insight.module_joins).find((_moduleJoin: IModuleJoin) => _moduleJoin.id === join.id);

                                if (__join) {
                                  this.setState({
                                    insight: this.purgeInsight({
                                      ...insight,
                                      module_joins: exist ? modifyNestedSetItem(join.id, { ...__join }, _.cloneDeep(insight.module_joins)) : _.cloneDeep(insight.module_joins).concat({ ...__join }),
                                    }),
                                    preview: null,
                                  });
                                }
                              } }
                            >
                              { availableJoins
                                .filter((moduleJoin: IModuleJoin) => {
                                  return moduleJoin.target_module_id !== insight.module_id;
                                })
                                .filter((moduleJoin: IModuleJoin) => {
                                  return moduleJoin.source_module_id === join.source_module_id;
                                })
                                .filter((moduleJoin: IModuleJoin) => {
                                  const joinIdsInUse = flattenSet(insight.module_joins).map((__join: IModuleJoin) => __join.target_module_id);
                                  return moduleJoin.id === join.id || !joinIdsInUse.includes(moduleJoin.target_module_id);
                                })
                                .map((moduleJoin: IModuleJoin, index: number) => {
                                  return (
                                    <Select.Option key={ index } value={ moduleJoin?.id }>{ moduleJoin.target_module_title }</Select.Option>
                                  );
                                })
                              }
                            </Select>
                            { !!firstJoinableJoin && !_.isEmpty(firstJoinableJoin) &&
                              <Button
                                className="mL-5"
                                onClick={ () => {
                                  const modifiedJoin = { ...join, children: join?.children ? join.children.concat({ ...firstJoinableJoin }) : [{ ...firstJoinableJoin }] };

                                  this.setState({
                                    insight: {
                                      ...insight,
                                      module_joins: modifyNestedSetItem(join.key || join.id, modifiedJoin, _.cloneDeep(insight.module_joins)),
                                    },
                                    preview: null,
                                  });
                                } }
                                style={{
                                  padding: '4px 7px',
                                  width: '32px',
                                }}
                              >
                                <PlusOutlined />
                              </Button>
                            }
                          </>
                        );
                      },
                    }
                  ] }
                  expandable={{
                    expandedRowKeys: flattenSet(joins).map((join: IModuleJoin) => join.key || join.id),
                    expandIcon: () => (<></>)
                  }}
                  dataSource={ joins }
                  pagination={ false }
                />
              }
            </div>
          </div>
        </div>
      )
    };
  };

  renderStepTwo = (insight: IInsight): IStep => {
    const addColumnActions: DropdownAction[] = [
      {
        node: '',
        onClick: () => {}
      },
      {
        node: 'Add Formula',
        disabled: !insight.module_id,
        onClick: () => this.mounted && this.setState({
          showAddFormula: true,
          editFormulaId: null
        })
      }
    ];

    return {
      title: 'Select columns',
      component: (
        <div className='mB-20'>
          <p>Choose the data field that you would like to include in the insight</p>
          <div className="d-f gp-10 mT-10">
            <div className="d-f fx-1">
              <div className='d-f fx-1' style={{ minWidth: 300 }}>
                <div className='pX-10 pT-15 pB-10 bd w-100p'>
                  <div className="d-f jc-sb ai-c mB-15">
                    <div className='d-if'>
                      <span>
                        <Tooltip
                          placement="top"
                          title={ 'Choose the columns/fields you would like to show in your report' }
                        >
                          <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                        </Tooltip>
                      </span>
                      <span className="fw-600 mL-5">Columns</span>
                      <span className="text-required mL-2 fsz-md va-t">*</span>
                      <span className="mL-5">{ `(${insight.columns.length})` }</span>
                    </div>
                    <div className='d-if'>
                      <Button
                        className="mL-5"
                        disabled={ !insight.module_id }
                        onClick={ () => this.setState({ showAddColumns: true }) }
                        style={{
                          padding: '4px 7px',
                        }}
                      >
                        Add Column
                      </Button>
                      <Dropdown actions={ addColumnActions } />
                    </div>
                  </div>
                  { !_.isEmpty(insight.columns) ? (
                    <DragSortingList
                      className='w-100p bd ov-s'
                      styles={{ height: 500 }}
                      columns={ [
                        {
                          title: '',
                          dataIndex: 'sort',
                          render: (__: any, column: IColumn) => {
                            return (
                              <div className="d-f jc-sb pX-10">
                                <span className="d-f jc-c ai-c">
                                  <span>
                                    <MenuOutlined className="EntityRolesTab-Table-DragMenu" />
                                  </span>
                                  <span className='mL-20 whs-nw ov-h tov-e' style={{ width: '100%' }}>
                                    { column?.settings?.custom_title || column.title } <span className="color-grey-600">({`${column.module_title}`})</span>
                                  </span>
                                </span>
                                <span>
                                  { !_.isEmpty(column?.settings) && (
                                    <span>
                                      <span>
                                        { column?.column_type === ColumnType.Formula ? this.renderFormulaTag(() => this.setState({ showAddFormula: true, editFormulaId: column.id })) : renderTags(column.settings, () => this.setState({ settingDialog: { type: 'columns', id: column.id} })) }
                                      </span>
                                      <span className="mR-10">
                                        <SettingOutlined
                                          className="link"
                                          onClick={ () => {
                                            if (column?.column_type === ColumnType.Column) {
                                              this.setState({settingDialog: {type: 'columns', id: column.id}});
                                            } else if(column?.column_type === ColumnType.Formula) {
                                              this.setState({showAddFormula: true, editFormulaId: column.id});
                                            }
                                          } }
                                        />
                                      </span>
                                    </span>
                                  ) }
                                  <Popconfirm
                                    title={ 'Are you sure?' }
                                    icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                                    visible={ !!this.state.columnDeletionId && this.state.columnDeletionId === column.id }
                                    okButtonProps={{
                                      danger: true
                                    }}
                                    onConfirm={ () => {
                                      const columns = this.state.insight.columns.filter((_column: IColumn) => _column.id !== column.id);
                                      const chart = _.cloneDeep(this.state.insight.chart);

                                      const _insight = {
                                        ...insight,
                                        columns: columns,
                                        chart: chart,
                                      };

                                      // Clear chart configs if they rely on the deleted column
                                      if (chart) {
                                        if (
                                          _.isEmpty(columns) ||
                                          chart?.config?.x_field === this.state.columnDeletionId ||
                                          chart?.config?.y_field === this.state.columnDeletionId ||
                                          chart?.config?.key_field === this.state.columnDeletionId ||
                                          chart?.config?.value_field === this.state.columnDeletionId ||
                                          chart?.config?.group_field === this.state.columnDeletionId
                                        ) {
                                          _insight.chart = null;
                                        }
                                      }

                                      this.setState({
                                        insight: _insight,
                                        columnDeletionId: null,
                                        preview: null,
                                      });
                                    } }
                                    onCancel={ () => this.setState({ columnDeletionId: null }) }
                                  >
                                    <DeleteOutlined className="link" onClick={ () => this.setState({ columnDeletionId: column.id }) } />
                                  </Popconfirm>
                                </span>
                              </div>
                            );
                          },
                        }
                      ] }
                      items={ insight.columns }
                      isParent
                      showHeader={ false }
                      pagination={ false }
                      config={{
                        type: TableType.Tab,
                        references: [],
                      }}
                      moveRow={ (dragIndex: number, hoverIndex: number) => {
                        this.setState({
                          insight: _.set(insight, 'columns', changeOrder(insight.columns, dragIndex, hoverIndex)),
                          preview: null
                        });
                      } }
                    />
                  ) : (
                    <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                  ) }
                </div>
              </div>
            </div>
            <div className="d-f fx-1 fxd-c gp-10">
              <div className='d-f fx-1' style={{ minWidth: 300 }}>
                <div className='pX-10 pT-15 pB-10 bd w-100p'>
                  <div className="d-f jc-sb ai-c mB-15">
                    <div className='d-if'>
                      <span>
                        <Tooltip
                          placement="top"
                          title={ 'Filters allow report users to search the data set' }
                        >
                          <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                        </Tooltip>
                      </span>
                      <span className="fw-600 mL-5">Filters</span>
                      <span className="mL-5">{ `(${insight.filters.length})` }</span>
                    </div>
                    <div className='d-if'>
                      <Button
                        className="mL-5"
                        disabled={ !insight.module_id }
                        onClick={ () => this.setState({ showAddFilters: true }) }
                        style={{
                          padding: '4px 7px',
                          width: '32px',
                        }}
                      >
                        <PlusOutlined />
                      </Button>
                    </div>
                  </div>
                  { !_.isEmpty(insight.filters) ? (
                    <DragSortingList
                      className='w-100p bd ov-s'
                      styles={{ height: 200 }}
                      columns={ [
                        {
                          title: '',
                          render: (__: any, filter: IFilter) => {
                            return (
                              <div className="d-f jc-sb pX-10">
                                <span className="d-f jc-c ai-c">
                                  <span>
                                    <MenuOutlined className="EntityRolesTab-Table-DragMenu" />
                                  </span>
                                  <span className='mL-20 whs-nw ov-h tov-e' style={{ width: '100%' }}>
                                    { filter?.settings?.custom_title || filter.title } <span className="color-grey-600">({`${filter.module_title}`})</span>
                                  </span>
                                </span>
                                <span>
                                  { !_.isEmpty(filter.settings) &&
                                    <span>
                                      <span>
                                        { renderTags(filter.settings, () => this.setState({ settingDialog: { type: 'filters', id: filter.id} })) }
                                      </span>
                                      <span className="mR-10">
                                        <SettingOutlined
                                          className="link"
                                          onClick={ () => this.setState({ settingDialog: { type: 'filters', id: filter.id} }) }
                                        />
                                      </span>
                                    </span>
                                  }
                                  <Popconfirm
                                    title={ 'Are you sure?' }
                                    icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                                    visible={ this.state.filterDeletionId === filter.id }
                                    okButtonProps={{
                                      danger: true
                                    }}
                                    onConfirm={ () => {
                                      this.setState({
                                        insight: {
                                          ...insight,
                                          filters: this.state.insight.filters.filter((_filter: IFilter) => _filter.id !== filter.id)
                                        },
                                        filterDeletionId: null,
                                        preview: null,
                                      });
                                    } }
                                    onCancel={ () => this.setState({ filterDeletionId: null }) }
                                  >
                                    <DeleteOutlined className="link" onClick={ () => this.setState({ filterDeletionId: filter.id }) } />
                                  </Popconfirm>
                                </span>
                              </div>
                            );
                          },
                        },
                      ] }
                      items={ insight.filters }
                      isParent
                      showHeader={ false }
                      pagination={ false }
                      config={{
                        type: TableType.Tab,
                        references: [],
                      }}
                      moveRow={ (dragIndex: number, hoverIndex: number) => {
                        this.setState({
                          insight: _.set(insight, 'filters', changeOrder(insight.filters, dragIndex, hoverIndex)),
                          preview: null
                        });
                      } }
                    />
                  ) : (
                    <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                  ) }
                </div>
              </div>
              <div className='d-f fx-1' style={{ minWidth: 300 }}>
                <div className='pX-10 pT-15 pB-10 bd w-100p'>
                  <div className="d-f jc-sb ai-c mB-15">
                    <div className='d-if'>
                      <span>
                        <Tooltip
                          placement="top"
                          title={ 'Choose the default sorting order when the report first loads' }
                        >
                          <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                        </Tooltip>
                      </span>
                      <span className="fw-600 mL-5">Sorters</span>
                      <span className="mL-5">{ `(${insight.sorters.length})` }</span>
                    </div>
                    <div className='d-if'>
                      <Button
                        className="mL-5"
                        disabled={ !insight.module_id }
                        onClick={ () => this.setState({ showAddSorters: true }) }
                        style={{
                          padding: '4px 7px',
                          width: '32px',
                        }}
                      >
                        <PlusOutlined />
                      </Button>
                    </div>
                  </div>
                  { !_.isEmpty(insight.sorters) ? (
                    <DragSortingList
                      className='w-100p bd ov-s'
                      styles={{ height: 200 }}
                      columns={ [
                        {
                          title: '',
                          dataIndex: 'sort',
                          width: '50%',
                          render: (__: any, sorter: ISorter) => {
                            return (
                              <div className="d-f jc-sb pL-10">
                                <span className="d-f ai-cp pL-10">
                                  <span>
                                    <MenuOutlined className="EntityRolesTab-Table-DragMenu" />
                                  </span>
                                  <span className='mL-20 whs-nw ov-h tov-e' style={{ width: '100%' }}>
                                    { sorter?.settings?.custom_title || sorter.title } <span className="color-grey-600">({`${sorter.module_title}`})</span>
                                  </span>
                                </span>
                              </div>
                            );
                          },
                        },
                        {
                          title: '',
                          dataIndex: 'action',
                          align: 'right',
                          width: '20%',
                          render: (__: any, sorter: ISorter) => {
                            return (
                              <div>
                                { !_.isEmpty(sorter?.settings) &&
                                  <SettingOutlined
                                    className="link mR-10"
                                    onClick={ () => this.setState({ settingDialog: { type: 'sorters', id: sorter.id} }) }
                                  />
                                }
                                <span>
                                  <Popconfirm
                                    title={ 'Are you sure?' }
                                    icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                                    visible={ this.state.sorterDeletionId === sorter.id }
                                    okButtonProps={{
                                      danger: true
                                    }}
                                    onConfirm={ () => {
                                      this.setState({
                                        insight: {
                                          ...insight,
                                          sorters: this.state.insight.sorters.filter((_sorter: ISorter) => _sorter.id !== sorter.id)
                                        },
                                        sorterDeletionId: null,
                                        preview: null,
                                      });
                                    } }
                                    onCancel={ () => this.setState({ sorterDeletionId: null }) }
                                  >
                                    <DeleteOutlined className="link" onClick={ () => this.setState({ sorterDeletionId: sorter.id }) } />
                                  </Popconfirm>
                                </span>
                              </div>
                            );
                          },
                        }
                      ] }
                      items={ insight.sorters }
                      isParent
                      showHeader={ false }
                      pagination={ false }
                      config={{
                        type: TableType.Tab,
                        references: [],
                      }}
                      moveRow={ (dragIndex: number, hoverIndex: number) => {
                        this.setState({
                          insight: _.set(insight, 'sorters', changeOrder(insight.sorters, dragIndex, hoverIndex)),
                          preview: null
                        });
                      } }
                    />
                  ) : (
                    <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    };
  };

  renderStepThree = (insight: IInsight): IStep => {
    return {
      title: 'Additional Settings',
      component: (
        <div className='mB-30'>
          <p>Apply additional settings for the insight</p>
          <div className="mT-20">
            <div>
              <span className="fw-600">Limit:</span>
            </div>
            <div>
              <InputNumber
                style={{ width: 250 }}
                value={ insight?.limit || undefined }
                placeholder={ 'Unlimited' }
                onBlur={ (event: BaseSyntheticEvent) => {
                  this.setState({
                    insight: _.set(insight, 'limit', parseInt(event.target.value)),
                  });
                } }
              />
            </div>
          </div>
        </div>
      )
    };
  };

  renderStepFour = (insight: IInsight): IStep => {
    return {
      title: 'Chart',
      component: (
        <div className='mB-50'>
          <p>Choose your chart and settings if required</p>
          <div className="d-f mT-20">
            { ['NONE', 'COLUMN', 'BAR', 'PIE'].map((type: string) => (
              <div
                key={ type }
                className={ classNames('d-if fxd-c bdrs-10 p-10 bd mR-10 cur-p', {
                  'border-primary': type === 'NONE' && !insight?.chart?.type || type === insight?.chart?.type
                }) }
                style={{ width: 110, borderRadius: 10 }}
                onClick={ () => {
                  this.setState({
                    insight: type === 'NONE' ? _.set(insight, 'chart', null) : _.set(insight, 'chart', { type: type }),
                    preview: null
                  });
                } }
              >
                <Icon
                  style={{ fontSize: 50 }}
                  className={ classNames({
                    'color-grey-500': type === 'NONE'
                  }) }
                  component={ this.getIconComponent(_.toLower(type)) as any }
                />
                <div className="ta-c pT-5 fsz-xs">{ _.upperFirst(_.toLower(type)) }</div>
              </div>
            )) }
          </div>
          { !!insight?.chart &&
            <div className="mT-20">
              <div>
                <span className="fsz-xs fw-600">Axis:</span><span className="text-required mL-2 lh-1 fsz-md va-t">*</span>
              </div>
              <div>
                { this.renderChartConfig(insight) }
              </div>
              <div className="mT-10">
                <span className="fsz-xs fw-600">Colour:</span>
              </div>
              <div>
                { this.renderColorConfig(insight) }
              </div>
              <div className="mT-10">
                <span className="fsz-xs fw-600">Stack:</span>
              </div>
              <div>
                { this.renderGroupConfig(insight) }
              </div>
              <div className="mT-10">
                <span className="fsz-xs fw-600">Default Pagination:</span>
              </div>
              <div>
                { this.renderDefaultPagination(insight) }
              </div>
              <div className="mT-10">
                <span className="fsz-xs fw-600">Show legend:</span>
              </div>
              <div>
                { this.renderLegendConfig(insight) }
              </div>
            </div>
          }
        </div>
      )
    };
  };

  renderStepFive = (insight: IInsight): IStep => {
    const errors = this.getErrors(insight);
    return {
      title: (
        <div className="d-f jc-sb ai-c">
          <div className='d-if'>
            Preview
          </div>
          <div className='d-if'>
            { !!errors ? (
              <Tooltip
                placement="top"
                title={ Object.keys(errors).map((error: string) => errors[error]).join('\n') }
              >
                <Button disabled>Preview</Button>
              </Tooltip>
            ) : (
              <Button
                disabled={ !!errors }
                loading={ this.state.isGeneratingPreview }
                onClick={ () => this.generatePreview(insight) }
              >
                Preview
              </Button>
            ) }
          </div>
        </div>
      ),
      component: (
        <div className='mB-40'>
          <p>Generate a preview of the report</p>
          <div
            className={ classNames('fxg-1 ov-h', {
              'bd mT-20': !this.state.preview
            }) }
          >
            <Insight
              isPreview
              clientId={ this.props.clientId }
              insight={ this.state.preview }
              isLoading={ this.state.isGeneratingPreview }
            />
          </div>
        </div>
      )
    };
  };

  renderStepSix = (insight: IInsight): IStep => {
    return {
      title: (
        <div className="d-f jc-sb ai-c">
          <div className='d-if'>
            { `Role Restrictions (${insight.roles.length})` }
          </div>
          <div className='d-if'>
            <Button
              className="mL-5"
              disabled={ !insight.module_id }
              onClick={ () => this.setState({ showAddRoles: true }) }
              style={{
                padding: '4px 7px',
                width: '32px',
              }}
            >
              <PlusOutlined />
            </Button>
          </div>
        </div>
      ),
      component: (
        <div className='mB-50'>
          <p>Limit access to the report to these roles only. No roles will make the report public</p>
          <div className="mT-20">
            <div className='p-20 bd'>
              { !_.isEmpty(insight.roles) ? (
                <Table
                  className="w-100p bd ov-s"
                  size={ 'small' }
                  bordered
                  showHeader={ false }
                  columns={ [
                    {
                      title: '',
                      dataIndex: 'sort',
                      render: (__: any, role: IRole) => {
                        return (
                          <div className="d-f jc-sb pX-10">
                            <span className="d-f jc-c ai-c">
                              <span className='whs-nw ov-h tov-e' style={{ width: '100%' }}>
                                { role.title }
                              </span>
                            </span>
                            <span onClick={ (event: React.MouseEvent) => event.stopPropagation() }>
                              <Popconfirm
                                title={ 'Are you sure?' }
                                icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                                visible={ this.state.roleDeletionId === role.id }
                                okButtonProps={{
                                  danger: true
                                }}
                                onConfirm={ () => {
                                  this.setState({
                                    insight: {
                                      ...insight,
                                      roles: this.state.insight.roles.filter((_role: IRole) => _role.id !== role.id)
                                    },
                                    roleDeletionId: null,
                                    preview: null,
                                  });
                                } }
                                onCancel={ () => this.setState({ roleDeletionId: null }) }
                              >
                                <DeleteOutlined className="link" onClick={ () => this.setState({ roleDeletionId: role.id }) } />
                              </Popconfirm>
                            </span>
                          </div>
                        );
                      },
                    },
                  ] }
                  dataSource={ nestedSet(insight.roles) }
                  pagination={ false }
                />
              ) : (
                <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
              ) }
            </div>
          </div>
        </div>
      )
    };
  };

  renderStepSeven = (insight: IInsight): IStep => {
    return {
      title: 'Schedule and share',
      component: (
        <div className='mB-50'>
          <p>Choose the frequency for the report to be run and which users should receive access to the report</p>
          <div className="mT-20">
            <Form
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 4 }}
              layout="horizontal"
              initialValues={{
                report_frequency: 1,
                report_notification: [1],
              }}
            >
              <Form.Item
                label="Report frequency"
                name="report_frequency"
                rules={ [{ required: true, message: 'Required' }] }
              >
                <Select
                  placeholder={ 'Please select' }
                  dropdownMatchSelectWidth={ false }
                >
                  <Select.Option value={ 1 }>Does not repeat</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Email report notification"
                name="report_notification"
                rules={ [{ required: true, message: 'Required' }] }
              >
                <Select
                  mode={ 'multiple' }
                  placeholder={ 'Please select' }
                  dropdownMatchSelectWidth={ false }
                >
                  <Select.Option value={ 1 }>Karen Smith</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </div>
        </div>
      )
    };
  };

  getSteps = (
    insight: IInsight,
    availableTools: ITool[],
    availableModules: IModule[],
  ): IStep[] => {
    return [
      this.renderStepOne(insight, availableTools, availableModules),
      this.renderStepTwo(insight),
      this.renderStepThree(insight),
      this.renderStepFour(insight),
      this.renderStepFive(insight),
      this.renderStepSix(insight),
      // this.renderStepSeven(insight),
    ];
  };

  render = () => {
    const { clientId, onClose } = this.props;
    const {
      insight,
      showAddColumns,
      showAddFormula,
      editFormulaId,
      showAddFilters,
      showAddSorters,
      showAddRoles,
      settingDialog,
      availableTools,
      availableModules,
      isFetching,
      isFetchingTools,
    } = this.state;

    const steps: IStep[] = this.getSteps(insight, availableTools, availableModules);
    const errors = this.getErrors(insight);

    const editFormula: IColumn | undefined = editFormulaId ? insight.columns.find(_column => _column.id === editFormulaId) : undefined;

    return (
      <CoverModal
        closeOnTop
        style={{ width: '100vw', minHeight: '100vh', height: '100%' }}
        middleContent={ 'Insight' }
        onClose={ onClose }
        isLoading={ isFetching || isFetchingTools }
      >
        <>
          <div className='pX-50 pT-30'>
            <div style={{ marginLeft: 47 }}>
              <Form
                initialValues={{
                  title: insight.title
                }}
              >
                <Form.Item
                  className='fxd-c'
                  style={{ maxWidth: 300 }}
                  name="title"
                  label="Title"
                  colon={ false }
                  required
                >
                  <Input
                    autoComplete="off"
                    onChange={ (event: BaseSyntheticEvent) => {
                      this.setState({
                        insight: _.set(insight, 'title', event.target.value)
                      });
                    } }
                  />
                </Form.Item>
              </Form>
            </div>
            <div>
              <Stepper
                containerClass="w-100p"
                stepsProps={{ direction: 'vertical' }}
                steps={ steps }
              />
            </div>
            <div className="mT-50 mB-50">
              <div className="ta-r">
                <Button
                  onClick={ this.props.onClose }
                >
                  Close
                </Button>
                { !!errors ? (
                  <Tooltip
                    placement="top"
                    title={ Object.keys(errors).map((error: string) => errors[error]).join('\n') }
                  >
                    <Button type='primary' className="mL-5" disabled>Save</Button>
                  </Tooltip>
                ) : (
                  <Button
                    type='primary'
                    className="mL-5"
                    loading={ this.state.isSaving }
                    disabled={ _.isEmpty(insight.columns) }
                    onClick={ () => this.setState({
                      isSaving: true
                    }, () => {
                      this.props.onSave(insight, (insightId: IPreview['id'] | undefined) => {
                        this.setState({
                          isSaving: false
                        }, () => {
                          if (!!this.props.insightId) {
                            Notification('success', 'Insight Saved', '');
                          } else {
                            !!insightId && history.push(`/insights/${insightId}`);
                          }
                        });
                      });
                    }) }
                  >
                    { !!this.props.insightId ? 'Save' : 'Create' }
                  </Button>
                ) }
              </div>
            </div>
          </div>
          { showAddColumns &&
            <AddColumns
              insight={ insight }
              clientId={ clientId }
              onSave={ (columns: IColumn[]) => {
                this.setState({
                  showAddColumns: false,
                  insight: {
                    ...insight,
                    columns: _.cloneDeep(insight.columns).concat(columns),
                  },
                  preview: null,
                });
              } }
              onClose={ () => this.setState({ showAddColumns: false }) }
            />
          }
          { showAddFormula &&
            <AddFormula
              insight={ insight }
              clientId={ clientId }
              column={ editFormula }
              onSave={ (columns: IColumn[]) => {
                this.setState({
                  showAddFormula: false,
                  editFormulaId: null,
                  insight: {
                    ...insight,
                    columns: columns,
                  },
                  preview: null,
                });
              } }
              onClose={ () => this.setState({ showAddFormula: false, editFormulaId: null }) }
            />
          }
          { showAddFilters &&
            <AddFilters
              insight={ insight }
              clientId={ clientId }
              onSave={ (filters: IFilter[]) => {
                this.setState({
                  showAddFilters: false,
                  insight: {
                    ...insight,
                    filters: _.cloneDeep(insight.filters).concat(filters)
                  },
                  preview: null,
                });
              } }
              onClose={ () => this.setState({ showAddFilters: false }) }
            />
          }
          { showAddSorters &&
            <AddSorter
              insight={ insight }
              clientId={ clientId }
              onSave={ (sorters: ISorter[]) => {
                this.setState({
                  showAddSorters: false,
                  insight: {
                    ...insight,
                    sorters: _.cloneDeep(insight.sorters).concat(sorters)
                  },
                  preview: null,
                });
              } }
              onClose={ () => this.setState({ showAddSorters: false }) }
            />
          }
          { showAddRoles &&
            <AddRoles
              insight={ insight }
              clientId={ clientId }
              onSave={ (roles: IRole[]) => {
                this.setState({
                  showAddRoles: false,
                  insight: {
                    ...insight,
                    roles: _.cloneDeep(insight.roles).concat(roles)
                  },
                  preview: null,
                });
              } }
              onClose={ () => this.setState({ showAddRoles: false }) }
            />
          }
          { !!settingDialog &&
            <SettingsDialog
              insight={ insight }
              id={ settingDialog.id }
              type={ settingDialog.type }
              onSave={ (insight: IInsight) => {
                this.setState({
                  settingDialog: null,
                  insight: _.cloneDeep(insight),
                  preview: null,
                });
              } }
              onClose={ () => this.setState({ settingDialog: null }) }
            />
          }
        </>
      </CoverModal>
    );
  };
};

export default InsightModal;
