// Libs
import React, { useState } from 'react';
import { Collapse } from 'antd';

// Styles
import './Group.scss';

interface Props {
  title: React.ReactNode;
  hasErrors: boolean;
  hasWarnings: boolean;
  hasRequiredFields: boolean;
  isOpen: boolean;
  state?: {
    errors?: Record<string, any>;
    modified?: Record<string, any>;
  };
}

const Group: React.FC<Props> = ({
  children,
  title,
  hasErrors,
  hasWarnings,
  hasRequiredFields,
  isOpen: defaultIsOpen,
}) => {
  const [isOpen, setOpenState] = useState<boolean>(defaultIsOpen);
  const toggleGroup = (key: any) => {
    setOpenState(!isOpen);
    return isOpen ? null : key;
  };
  const { Panel } = Collapse;
  const header = (
    <span>
      { title }
      { hasRequiredFields && <span className="fsz-md text-danger mL-2">*</span> }
    </span>
  );

  return (
    <Collapse
      className={`Form-Group ${isOpen && 'Form-Group--open'} ${
        hasWarnings && 'Form-Group--has-warning'
        } ${hasErrors && 'Form-Group--has-error'}`}
      onChange={toggleGroup}
      expandIconPosition="right"
      defaultActiveKey={isOpen ? 1 : undefined}
    >
      <Panel header={header} key={1}>
        <div className="Form-Grid">{children}</div>
      </Panel>
    </Collapse>
  );
};

export default Group;
