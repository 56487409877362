// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Modal, Select } from 'antd';

// Interfaces
import { AreaUnit, FormField } from 'components/form/form-wrapper';

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
};

interface Props {
  units: AreaUnit[];
  defaultMeasurementUnit: AreaUnit | undefined;
  onChange(newUnit: AreaUnit | undefined): void;
  onClose(): void;
};

interface State {
  currentUnit: AreaUnit | undefined;
};

class ChangeMeasurementModal extends React.Component<Props, State> {

  state: State = {
    currentUnit: _.cloneDeep(this.props.defaultMeasurementUnit)
  };

  render = () => {
    const { units, defaultMeasurementUnit } = this.props;
    const { currentUnit } = this.state;
    return (
      <Modal
        centered
        visible
        closable={ false }
        title={ 'Change Area Unit' }
        onCancel={ () => this.props.onClose() }
        okText={ 'Change' }
        okButtonProps={{
          danger: true,
          disabled: defaultMeasurementUnit?.unit === currentUnit?.unit,
        }}
        onOk={ () => {
          this.props.onChange(this.state.currentUnit);
        } }
      >
        <div className="mT-10">
          <p><b>Note</b>, some conversions (such as imperial to metric) can result in numbers being approximately rounded. It's strongly recommended all numbers are checked after conversion.</p>
        </div>
        <div className="mT-30">
          <label className='d-b'><b>Area Unit</b></label>
          <Select
            className="Select-Field w-100p"
            onChange={ (__unit: string) => {
              const newUnit = units.find((_unit: AreaUnit) => _unit.unit === __unit);
              if (newUnit) {
                this.setState({
                  currentUnit: newUnit
                });
              }
            } }
            placeholder={ 'Please Select' }
            value={ currentUnit?.unit }
          >
            { units.map((_unit: AreaUnit) => {
              return (
                <Select.Option
                  key={ _unit.unit }
                  value={ _unit.unit }
                >
                  { _unit.unit }
                </Select.Option>
              );
            }) }
          </Select>
        </div>
      </Modal>
    );
  };
};

export default ChangeMeasurementModal;
