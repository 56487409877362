// Libs
import * as React from 'react';
import moment from 'moment';

// Components
import ActivityManagement from 'components/form/field/activity_management/ActivityManagement';
import CoverModal from 'components/cover-modal';

// Utilities
import { convertToActivityRecord } from 'utils/activityManagement';

// Interfaces
import { Activity, Milestone, Competence, Feature, Role } from 'views/admin/templates/Templates.interfaces';

const DATE_FORMAT = 'YYYY-MM-DD 00:00:00';

interface Props {
  title: string;
  activities: Activity[];
  features: Feature[];
  milestones: Milestone[];
  competencies: Competence[];
  resource: Role[];
  onClose(): void;
};

interface State {};

class PreviewModal extends React.Component<Props, State> {
  renderFieldPreview = () => {
    const { activities, features, competencies, milestones, resource } = this.props;

    // the project start date is set to today's date.
    const projectStartDate = moment().format(DATE_FORMAT);

    // template activities are converted to the activity type required for the activity management field
    const convertedActivities = activities.map((activity, idx) => ({
      id: idx,
      ...convertToActivityRecord(activity, projectStartDate, { yes: 1, no: 0 })
    }));

    const field: any = {
      values: {
        activities: convertedActivities,
        start_date: projectStartDate,
      },
      template: {
        features: features,
      },
      links: [],
      resource: resource,
      milestones: milestones,
      competencies: competencies,
      options: [],
      config: {},
      entity_label: 'Entity',
    };

    const DUMMY_FC = () => {};
    const DUMMY_OBJ: any = {};

    return (
      <ActivityManagement
        key={ `activity_management_preview` }
        originalValues={ convertedActivities }
        field={ field }
        isDisabled
        canEditActualCompletionDate={ false }
        clientId={ Math.floor(Math.random() * 10) + 1 } // dummy client id
        record={ DUMMY_OBJ }
        config={ DUMMY_OBJ }
        fieldErrorMessages={ DUMMY_OBJ }
        fieldModifiedMessages={ DUMMY_OBJ }
        setFieldModifiedMessage={ DUMMY_FC }
        setFieldErrorMessage={ DUMMY_FC }
        onValueChange={ DUMMY_FC }
      />
    );
  };

  render = () => {
    const { title, onClose } = this.props;
    return (
      <CoverModal
        style={{ minWidth: '800px', maxWidth: '90%', minHeight: '500px', maxHeight: '90vh' }}
        middleContent={ title }
        onClose={ onClose }
      >
        <div className='Layout-box pX-20'>
          { this.renderFieldPreview() }
        </div>
      </CoverModal>
    );
  };
};

export default PreviewModal;