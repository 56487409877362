// Libs
import React, { Component } from 'react';
import _ from "lodash";

// Components
import { Button, Empty, Form, Steps, Upload } from 'antd';
import BasicListView from 'components/basic-list';

// Interfaces
import { IValidationResponse, ValidationResponseStatus } from 'components/import/Import.interface';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Icons
import { InboxOutlined } from "@ant-design/icons";

interface Props {
  uploadEndpoint: string;
  downloadEndpoint: string;
  importEndpoint: string;
  callback?: () => void;
};

interface State {
  currentStep: number;
  error: any;
  fileList: any[];
  isUploading: boolean;
  isImporting: boolean;
  isDownloading: boolean;
  validationResponse: IValidationResponse | null;
};

const API: Api = new Api();

export class Import extends Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    fileList: [],
    error: null,
    isUploading: false,
    isImporting: false,
    isDownloading: false,
    currentStep: 1,
    validationResponse: null,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleUpload = async () => {
    const { fileList } = this.state;
    const { uploadEndpoint } = this.props;

    try {

      await new Promise((resolve) => this.setState({ isUploading: true }, () => resolve(null) ));

      const form = new FormData();
      form.append('file', fileList[0].originFileObj);

      const response = await API.post(uploadEndpoint, form);

      this.mounted && this.setState({
        validationResponse: response,
        currentStep: 2,
      });
    } catch (error: any) {
      this.mounted && this.setState({
        error: _.has(error, 'data.message') ? error.data.message : error
      }, () => {
        Notification('error', `Failed to import data`);
      });
    } finally {
      this.mounted && this.setState({
        isUploading: false,
        fileList: [],
      });
    }
  };

  handleImport = async (validationResponse: IValidationResponse) => {
    const { importEndpoint, callback } = this.props;

    try {

      await new Promise((resolve) => this.setState({ isImporting: true }, () => resolve(null) ));

      const response: IValidationResponse = await API.put(importEndpoint, {
        file_hash: validationResponse.file_hash
      });

      this.mounted && this.setState({
        validationResponse: response,
        currentStep: 3,
      }, () => {
        Notification(response.status !== 'IMPORTED' ? 'error' : 'success', response.status_message);
        if (callback) {
          callback();
        }
      });
    } catch (error: any) {
      this.mounted && this.setState({
        error: _.has(error, 'data.message') ? error.data.message : error
      }, () => {
        Notification('error', `Failed to import data`);
      });
    } finally {
      this.mounted && this.setState({
        isImporting: false,
      });
    }
  };

  handleDownload = async (validationResponse: IValidationResponse) => {
    const { downloadEndpoint } = this.props;

    try {

      await new Promise((resolve) => this.setState({ isDownloading: true }, () => resolve(null) ));

      await API.download(downloadEndpoint, `${validationResponse.file_hash}.xlsx`, {
        file_hash: validationResponse.file_hash
      });

      Notification('success', `File downloaded`);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isDownloading: false
      });
    }
  };

  renderUpload = () => {
    const { fileList, isUploading } = this.state;
    return (
      <div className="pT-50" style={{ minHeight: 200 }}>
        <Form
          className="pL-100 pR-100"
          name="upload"
          layout="vertical"
          autoComplete="off"
          onFinish={ () => {
            this.handleUpload();
          } }
        >
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={ (event: any) => {
              return event && event.fileList;
            } }
          >
            { _.isEmpty(fileList) ? (
              <Upload.Dragger
                beforeUpload={ () => false }
                maxCount={ 1 }
                multiple={ false }
                onChange={ ({ fileList }) => this.setState({ fileList }) }
                showUploadList={ false }
              >
                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                <p className="ant-upload-text">Click or drag file to this area to start</p>
              </Upload.Dragger>
            ) : (
              <Upload
                listType="picture"
                defaultFileList={ [...fileList] }
                onRemove={ () => {
                  this.setState({
                    fileList: []
                  });
                } }
              />
            ) }
          </Form.Item>
          <Form.Item className="ta-r">
            <Button
              type="primary"
              loading={ isUploading }
              disabled={ _.isEmpty(fileList) || isUploading }
              htmlType="submit"
            >
              Upload
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  renderValidate = (validationResponse: IValidationResponse) => {
    return (
      <div className='pT-50 pL-100 pR-100 pB-20'>
        <div className='d-f jc-c ai-c' style={{ minHeight: 200 }}>
          { validationResponse?.status_message && !_.isEmpty(validationResponse?.status_message) && (
            <div className="mB-10 ta-c">
              { validationResponse?.status_message.map((status_message: string, index: number) => (
                <p key={ index }>{ status_message }</p>
              )) }
            </div>
          ) }
        </div>
        <div className='d-f jc-fe mT-20'>
          <div>
            <Button
              type="default"
              onClick={ () => this.setState({
                fileList: [],
                validationResponse: null,
                currentStep: 1,
              }) }
            >
              Restart
            </Button>
          </div>
          <div className='mL-10'>
            { validationResponse.status === ValidationResponseStatus.VERIFIED &&
              <Button
                type="primary"
                loading={ this.state.isImporting }
                disabled={ this.state.isImporting }
                onClick={ () => this.handleImport(validationResponse) }
              >
                Import
              </Button>
            }
            { validationResponse.status === ValidationResponseStatus.CORRUPTION_ERROR &&
              <Button
                type="primary"
                loading={ this.state.isDownloading }
                disabled={ this.state.isDownloading }
                onClick={ () => this.handleDownload(validationResponse) }
              >
                Download
              </Button>
            }
          </div>
        </div>
      </div>
    );
  };

  renderImported = (validationResponse: IValidationResponse) => {
    return (
      <div className='pT-20 pB-20'>
        <div className='d-f jc-c ai-c fxd-c' style={{ minHeight: 250 }}>
          <div style={{ width: '100%' }}>
            <BasicListView
              columns={ validationResponse.list.columns }
              items={ validationResponse.list.data }
              emptyElement={ (
                <Empty
                  style={{ marginTop: 10, marginBottom: 10 }}
                  imageStyle={{ height: 30 }}
                  image={ Empty.PRESENTED_IMAGE_SIMPLE }
                  description={ <span className='fsz-xs'>No rows imported</span> }
                />
              ) }
            />
          </div>
        </div>
        <div className='d-f jc-fe mT-20'>
          <div>
            <Button
              type="default"
              onClick={ () => this.setState({
                fileList: [],
                validationResponse: null,
                currentStep: 1,
              }) }
            >
              Restart
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderStep = (step: number, validationResponse: IValidationResponse | null) => {
    switch (step) {
      case 1:
        return this.renderUpload();
      case 2:
        return validationResponse ? this.renderValidate(validationResponse) : <></>;
      case 3:
        return validationResponse ? this.renderImported(validationResponse) : <></>;
    }
  };

  render = () => {
    const { currentStep, validationResponse } = this.state;
    let validationStepStatus: 'wait' | 'process' | 'finish' | 'error' | undefined = undefined;
    let importedStepStatus: 'wait' | 'process' | 'finish' | 'error' | undefined = undefined;

    switch (validationResponse?.status) {
      case ValidationResponseStatus.VERIFIED:
        validationStepStatus = 'process';
        break;
      case ValidationResponseStatus.CORRUPTION_ERROR:
      case ValidationResponseStatus.GENERIC_ERROR:
        validationStepStatus = 'error';
        break;
      case ValidationResponseStatus.IMPORTED:
        importedStepStatus = 'finish';
        break;
    }

    return (
      <div className='Layout-box' style={{ minHeight: 400 }}>
        <div className="mT-30 mX-30">
          <Steps current={ currentStep - 1 }>
            <Steps.Step title="Upload" />
            <Steps.Step title="Validation" status={ validationStepStatus } />
            <Steps.Step title="Imported" status={ importedStepStatus } />
          </Steps>
        </div>
        <div>
          { this.renderStep(currentStep, validationResponse) }
        </div>
      </div>
    );
  };
}
