// Libs
import React, { memo } from 'react';
import {
  EdgeText,
  EdgeProps,
  getMarkerEnd,
  getSmoothStepPath,
  getEdgeCenter,
} from 'react-flow-renderer';

// Interfaces
import { WorkflowTransition } from 'components/workflow';

interface CustomEdgeData {
  transition: WorkflowTransition;
  onDoubleClickByEdge?: (transition: WorkflowTransition) => void;
};

interface Props extends EdgeProps {};

function RenderCustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  arrowHeadType,
  markerEndId,
}: Props) {
  const { transition, onDoubleClickByEdge } = data as CustomEdgeData;

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [X, Y] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition
  });

  return (
    <>
      <path
        id={ id }
        className="react-flow__edge-path"
        d={ edgePath }
        markerEnd={ markerEnd }
      />
      <EdgeText
        x={ X }
        y={ Y - 10 }
        label={ transition.title }
        labelStyle={{ fill: 'black' }}
        labelShowBg
        labelBgPadding={ [2, 4] }
        labelBgBorderRadius={ 2 }
        onDoubleClick={ () => onDoubleClickByEdge?.(transition) }
      />
    </>
  );
};

export const WorkflowCustomEdge = memo(RenderCustomEdge);
