// Libs
import React from 'react';
import { Steps } from "antd";

// Interfaces
import { IStepsProps, IStep } from 'components/stepper/Stepper.interface';

// Styles
import './Stepper.scss';

const { Step } = Steps;

const Stepper: React.FC<IStepsProps> = (props) => {
  const {
    steps,
    current,
    stepsProps,
    containerClass = '',
  } = props;

  return (
    <div className={ `Stepper ${containerClass}` }>
      <Steps { ...stepsProps }>
        { steps.map((step: IStep, index) => {
          const { component: Component, ...rest } = step;
          return (
            <Step
              key={ index }
              status={ current === index ? 'process' : 'wait' }
              description={ Component }
              { ...rest }
            />
          );
        }) }
      </Steps>
    </div>
  );
};

export default React.memo(Stepper);
