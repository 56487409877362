// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { has } from 'lodash';

// Services
import { Api } from 'services/api';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Views
import BudgetGroupRecord from 'views/finance/cost-centre/budget/BudgetGroupRecord';
import BudgetGroupResources from 'views/finance/cost-centre/budget/BudgetGroupResources';
import BudgetGroupComments from 'views/finance/cost-centre/budget/BudgetGroupComments';
import BudgetGroupAudit from 'views/finance/cost-centre/budget/BudgetGroupAudit';
import CostCentreBudgetItemContainer from './CostCentreBudgetItemContainer';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { RecordFormEntity } from 'types/entities';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbs,
  setBreadcrumbsLoading,
} from 'store/UI/ActionCreators';

// Utils
import Console from 'utils/console';

interface Props {
  client_id: number;
  permissions: any;
  match: { params: Record<string, any> };
  location: {
    pathname: string;
  };
  routes: any[];
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  record: RecordFormEntity | null;
  isFetching: boolean;
};

const API: Api = new Api();

class CostCentreBudgetGroupContainer extends React.Component<Props, State> {

  mounted: boolean = false;
  state: State = {
    isFetching: false,
    record: null
  };

  componentDidMount = () => {
    this.mounted = true;

    // Get record
    this.getRecord();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.params.budget_group_id !== this.props.match.params.budget_group_id) {
      this.getRecord();
    }

    if (!!prevProps.location.pathname.match(/budget-item\/[0-9]+/) !== !!this.props.location.pathname.match(/budget-item\/[0-9]+/)) {
      this.getRecord();
    }
  };

  componentWillUnmount = () => {
    this.props.setSecondarySidebarRoutes([]);
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getRecord = async (silent: boolean = false) => {
    try {

      if (this.props.match.params.budget_group_id && this.props.client_id && !this.props.location.pathname.match(/budget-item\/[0-9]+/)) {

        if (!silent) {
          await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));
          this.props.setBreadcrumbsLoading(true);
        }

        const record = await API.get(`client/${this.props.client_id}/record/budget-group/${this.props.match.params.budget_group_id}`);

        this.setRecord(record, silent);
      }
    } catch (error) {
      Console.error(error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.setState({
        isFetching: false
      });
    }
  };

  setRecord = (record: RecordFormEntity, silent?: boolean) => {
    const showLoadingState = this.state.record?.title !== record.title;

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(true);
    }

    if (has(record, 'breadcrumbs')) {
      this.props.setBreadcrumbs(record.breadcrumbs, false);
    }

    if (has(record, 'permissions')) {
      const { permissions, new_content } = record;

      this.props.setSecondarySidebarRoutes([
        { title: 'Budget Group', path: 'record', isDisabled: !hasPermission(permissions, 'record_budget_group_view') },
        { title: 'Comments', path: 'comments', isIndented: true, isDisabled: !hasPermission(permissions, 'record_budget_group_comment_view'), hasUpdates: !!new_content.comments },
        { title: 'Resources', path: 'resources', isIndented: true, isDisabled: !hasPermission(permissions, 'record_budget_group_resource_view'), hasUpdates: !!new_content.resources },
        { title: 'Audit', path: 'audit', isIndented: true, isDisabled: !hasPermission(permissions, 'record_budget_group_audit_view') },
      ]);
    }

    this.setState({
      record: record,
      isFetching: false
    });

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(false);
    }
  };

  render = () => {
    const { isFetching, record } = this.state;
    const permissions = record && has(record, 'permissions') ? record.permissions : this.props.permissions;
    return (
      <BlockingSpinner isLoading={isFetching}>
        <Switch>

          <Redirect exact from="/finance/cost-centres/:cost_centre_id/budget/:budget_id/budget-group/:budget_group_id" to="/finance/cost-centres/:cost_centre_id/budget/:budget_id/budget-group/:budget_group_id/record" />

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/budget-group/:budget_group_id/record" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_group_view') }>
                  <BudgetGroupRecord record={ record } setRecord={ this.setRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/budget-group/:budget_group_id/resources" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_group_resource_view') }>
                  <BudgetGroupResources record={ record } getRecord={ this.getRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/budget-group/:budget_group_id/comments" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_group_comment_view') }>
                  <BudgetGroupComments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/budget-group/:budget_group_id/audit" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_group_audit_view') }>
                  <BudgetGroupAudit record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          <Route path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/budget-group/:budget_group_id/budget-item/:budget_item_id" component={ CostCentreBudgetItemContainer } />

          <Route render={ () => <NotFound /> } />
        </Switch>
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    routes: store.UIState.secondarySidebarRoutes
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CostCentreBudgetGroupContainer);
