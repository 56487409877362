// Libs
import * as React from 'react';
import _ from 'lodash';

// Components
import CoverModal from 'components/cover-modal';
import { Input, Button, Table } from 'antd';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Utils
import { nestedSet, flattenSet } from 'utils/utils';

// Interfaces
import { IInsight, IRole, IModuleJoin } from 'components/insight/Insight.interfaces';

const { Search } = Input;
const API: Api = new Api();

interface Props {
  insight: IInsight;
  clientId: number;
  onSave(roles: IRole[]): void;
  onClose(): void;
};

interface State {
  roles: IRole[];
  filter: string | null;
  selectedRoles: IRole[];
  isFetching: boolean;
};

class AddRoles extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    roles: [],
    filter: null,
    selectedRoles: [],
    isFetching: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.fetchRoles();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchRoles = async () => {
    const { clientId, insight } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      let moduleIds: string[] = flattenSet(insight.module_joins).map((join: IModuleJoin) => join.target_module_id);

      if (!!insight.module_id) {
        moduleIds = moduleIds.concat([insight.module_id]);
      }

      const roles = await API.get(`client/${clientId}/insight/builder/roles`, {
        module_ids: moduleIds
      });

      this.mounted && this.setState({
        roles: roles
      });
    } catch (error) {
      Notification('error', 'Failed to fetch roles', 'Failed');
    } finally {
      this.mounted && this.setState({ isFetching: false });
    }
  };

  render = () => {
    const { insight, onClose } = this.props;
    const { roles, filter, selectedRoles, isFetching } = this.state;

    return (
      <CoverModal
        showCloseIcon={ false }
        style={{ width: '90vw', maxWidth: 1300, height: '80vh', maxHeight: 800 }}
        middleContent={ 'Add Roles' }
        onClose={ onClose }
      >
        <div className='d-f fxd-c pX-50 h-100p'>
          <div>
            <Search
              placeholder="Search"
              disabled={ _.isEmpty(roles) }
              onSearch={ (value: string) => this.setState({
                filter: !!value ? value : null,
                selectedRoles: []
              }) }
            />
          </div>
          <div className="mT-20 fxg-1 ov-h">
            <Table
              className="h-100p"
              size={ 'small' }
              loading={ isFetching }
              bordered={ false }
              onRow={(_role: IRole) => {
                return {
                  onClick: () => {
                    if (selectedRoles.some((__role: IRole) => __role.id === _role.id)) {
                      // Remove
                      this.setState({
                        selectedRoles: selectedRoles.filter((__role: IRole) => __role.id !== _role.id)
                      });
                    } else {
                      // Add
                      this.setState({
                        selectedRoles: selectedRoles.concat(_role)
                      });
                    }
                  },
                };
              }}
              rowSelection={{
                checkStrictly: false,
                selectedRowKeys: selectedRoles.map((_role: IRole) => _role.id),
                onChange: (__, selectedRoles: IRole[]) => {
                  this.setState({
                    selectedRoles: selectedRoles
                  });
                }
              }}
              scroll={{
                y: 350
              }}
              columns={ [
                {
                  title: 'Title',
                  dataIndex: 'title',
                  render: (__, role: IRole) => <span className="us-n">{ role.title }</span>
                }
              ] }
              dataSource={ nestedSet(roles
                // Don't include filters that's already in use
                .filter((_role: IRole) => {
                  return !insight.filters.some((__role: IRole) => __role.id === _role.id);
                } )
                // Filter from keyword
                .filter((_role: IRole) => {
                  if (!!filter) {
                    return _role.title.toLowerCase().includes(filter.toLowerCase());
                  }
                  return true;
                }))
              }
              pagination={ false }
            />
          </div>
          <div className='mB-20 pT-20 ta-r'>
            <Button
              onClick={ () => this.props.onClose() }
            >
              Cancel
            </Button>
            <Button
              type='primary'
              className="mL-5"
              onClick={ () => this.props.onSave(selectedRoles) }
            >
              Add
            </Button>
          </div>
        </div>
      </CoverModal>
    );
  };
};

export default AddRoles;
