import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import _ from "lodash";

// Components
import FieldWrapper from "components/form/field/field-wrapper";
import { Input } from "antd";

// Interfaces
import {
  FormField,
  FormValues,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage
} from "components/form/form-wrapper";

interface Props {
  numberFormat: any;
  field: FormField;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string
  ): void;
  onRefreshForm(field_id: string): void;
  originalState: FormValues;
  state: FormValues;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage: (id: string, message?: FormFieldInfoBoxModifiedMessage) => void;
  setFieldErrorMessage: (id: string, message?: FormFieldInfoBoxErrorMessage) => void;
  validate: (field: FormField, column: string, value: string | number) => string[];
  border?: boolean;
};

interface State {
  state: FormValues;
};

class NumberField extends Component<Props, State> {

  state: State = {
    state: this.props.state
  };

  componentDidMount = () => {
    this.validate(this.props.state);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { field } = this.props;

    if (!_.isEqual(prevProps.state, this.props.state)) {
      // Set state downwards
      this.setState({
        state: this.props.state
      });
    } else if (!_.isEqual(prevState.state, this.state.state)) {
      // Roll state upwards
      this.handleChange(this.state.state);
    }

    if (!_.isEqual(prevProps.field, this.props.field)) {
      this.validate(this.props.state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, [originalState], config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { field, originalState } = this.props;
    const columnKeys = Object.keys(field.columns);

    columnKeys.forEach((columnKey: string) => {
      this.generateModifiedState(originalState[columnKey], state[columnKey], columnKey, shouldClear);
      this.generateErrorState(state[columnKey], columnKey, shouldClear);
    });
  };

  handleChange = _.debounce((state: any) => {
    this.props.onChange(this.props.field, state.value, this.props.config, 'value');
  }, 500);

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string, shouldClear = false) => {
    const { setFieldModifiedMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { setFieldErrorMessage, validate, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const errors = validate(field, columnKey, value);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  render = () => {
    const { field, config, fieldErrorMessages, fieldModifiedMessages, border, numberFormat, isDisabled } = this.props;
    const { state } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_value`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const decimal = _.has(field, 'config.decimal') ? field.config.decimal : 0;

    if (!!isDisabled) {
      return (
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          border={ border }
          refreshOnChange={ !!field.config.refresh_on_change }
          versionChanged={ !!field.config.version_changed }
          description={ !!field.description && field.description }
        >
          <div className="ta-r">
            <NumberFormat
              { ...numberFormat }
              fixedDecimalScale={ !!decimal }
              decimalScale={ decimal }
              suffix={ _.has(field, 'config.suffix') ? ` ${field.config.suffix}` : undefined }
              displayType={ 'text' }
              value={ _.isNumber(state?.value) ? state?.value : '-' }
            />
          </div>
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ errors }
        required={ field.config.required }
        border={ border }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <NumberFormat
          { ...numberFormat }
          fixedDecimalScale={ !!decimal }
          decimalScale={ decimal }
          customInput={ Input }
          autoComplete="newpassword" // hack
          className={ classNames('Field pR-20 ta-r', {
            'Field--has-warning border-warning': isModified && _.isEmpty(errors),
          }) }
          suffix={ _.has(field, 'config.suffix') ? ` ${field.config.suffix}` : undefined }
          required={ field.config.required }
          disabled={ isDisabled || field.config.locked }
          value={ state?.value }
          onValueChange={ (value: any) => {
            this.setState({ state: value.value !== '' ? { value: Number(value.value) } : {} });
          } }
        />
      </FieldWrapper>
    );
  };
};

export default NumberField;
