// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Button, Checkbox, Form, Input, Modal, Select, Spin } from 'antd';
import BlockingSpinner from 'components/blocking-spinner';
import DragSortingList from 'components/drag-sorting-list';
import Badge, { BadgeType } from 'components/badge';

// Icons
import { DeleteOutlined, EditOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';

// Utils
import { arrayMoveImmutable } from 'utils/formSetup';

// Interfaces
import { EntityRole, EntityRoleTypes, Role, RoleType } from 'views/admin/entity/Entity.interfaces';

// Styles
import './EntityRolesTab.scss';

interface Props {
  entityRoles: EntityRole[];
  roles: Role[];
  reorderRoles: (values: Array<{ id: number, order: number }>, cb?: () => void) => Promise<void>;
  addRole: (data: { roleId: number, type: EntityRoleTypes, required: boolean }, cb?: () => void) => Promise<void>;
  editRole: (entityRoleId: number, data: { type: EntityRoleTypes, required: boolean }, cb?: () => void) => Promise<void>;
  deleteRole: (entityRoleId: number, cb?: () => void) => Promise<void>;
  isFetchingRoles: boolean;
};

interface State {
  newRole: Partial<{ id: number, entityRoleType: EntityRoleTypes, roleType: RoleType, required: boolean }> | null;
  editableRole: EntityRole | null;
  deletedRoleId: number | null;
  isAdding: boolean;
  isEditing: boolean;
  isReordering: boolean;
  isDeletingRole: boolean;
  showAddRoleDialog: boolean;
  showEditRoleDialog: boolean;
  showRemoveRoleDialog: boolean;
};

class EntityRolesTab extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    editableRole: null,
    newRole: null,
    deletedRoleId: null,
    isAdding: false,
    isEditing: false,
    isReordering: false,
    isDeletingRole: false,
    showAddRoleDialog: false,
    showEditRoleDialog: false,
    showRemoveRoleDialog: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  reorderRolesHandler = (dragIndex: number, hoverIndex: number) => {
    const { entityRoles, reorderRoles } = this.props;
    if (dragIndex !== hoverIndex) {
      const reorderedRoles = arrayMoveImmutable<EntityRole>(entityRoles, dragIndex, hoverIndex).filter(el => !!el);
      const data = reorderedRoles.map((role, index: number) => ({ id: role.id, order: index }));
      this.mounted && this.setState({ isReordering: true }, () => {
        reorderRoles(data, () => this.setState({ isReordering: false }));
      });
    }
  };

  renderRoles = () => {
    const { entityRoles } = this.props;
    const { isReordering } = this.state;

    const columns: any = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        width: 50,
        render: () => <MenuOutlined className="EntityRolesTab-Table-DragMenu" />,
      },
      {
        key: 'title',
        dataIndex: 'title',
        title: 'Role Name',
        ellipsis: true,
      },
      {
        key: 'type',
        dataIndex: 'type',
        title: 'Type',
        render: (type: EntityRoleTypes) => (
          <Badge
            type={ type === EntityRoleTypes.DIRECT ? BadgeType.Success : BadgeType.Warning }
            text={ type }
          />
        ),
        ellipsis: true,
      },
      {
        key: 'required',
        dataIndex: 'required',
        title: 'Required',
        render: (required: boolean) => (
          <Badge
            type={ required ? BadgeType.Success : BadgeType.Disabled }
            text={ required ? 'Required' : 'Not Required' }
          />
        ),
        ellipsis: true,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: (
          <Button
            style={{
              marginLeft: 5,
              padding: '4px 7px',
              width: '32px',
            }}
            onClick={ () => this.setState({ showAddRoleDialog: true }) }
          >
            <PlusOutlined />
          </Button>
        ),
        render: (__: any, role: EntityRole) => {
          return (
            <>
              <EditOutlined
                className="link"
                style={{ fontSize: 18 }}
                onClick={ () => {
                  this.setState({ showEditRoleDialog: true, editableRole: _.cloneDeep(role) });
                } }
              />
              <DeleteOutlined
                className="mL-20 link"
                style={{ fontSize: 18 }}
                onClick={ () => {
                  this.setState({ showRemoveRoleDialog: true, deletedRoleId: role.id });
                } }
              />
            </>
          );
        },
        width: 130,
        ellipsis: true,
        align: 'center',
      },
    ];
    const type: any = 'EntityRolesTab-Table'; // table unique key
    return (
      <Spin indicator={ <BlockingSpinner isLoading /> } spinning={ isReordering }>
        <DragSortingList
          columns={ columns }
          items={ entityRoles.sort((a: EntityRole, b: EntityRole) => a.order - b.order) }
          isParent
          pagination={ false }
          className={ 'EntityRolesTab-Table' }
          config={{
            type: type,
            references: [],
          }}
          moveRow={ this.reorderRolesHandler }
        />
      </Spin>
    );
  };

  renderAddRoleDialog = () => {
    const { roles, entityRoles, addRole } = this.props;
    const { newRole, isAdding } = this.state;

    const availableRoles = roles.filter((role) => {
      return role.type === newRole?.roleType && entityRoles.every((entityRole) => entityRole.role_id !== role.id);
    });

    const onCancel = () => this.mounted && this.setState({
      newRole: null,
      isAdding: false,
      showAddRoleDialog: false,
    });

    const isOkButtonDisabled = () => {
      if (isAdding || !newRole?.id || !newRole?.entityRoleType) {
        return true;
      }
      return false;
    };

    return (
      <Modal
        title={ `Add Role` }
        maskClosable={ !isAdding }
        closable={ !isAdding }
        centered
        visible
        onCancel={ onCancel }
        onOk={ () => {
          const { id, entityRoleType, required = false } = newRole || {};
          if (id && entityRoleType) {
            this.mounted && this.setState({ isAdding: true }, () => {
              addRole({ roleId: id, type: entityRoleType, required }, onCancel);
            });
          }
        } }
        okText={ 'Add' }
        okButtonProps={{
          disabled: isOkButtonDisabled(),
          loading: isAdding,
        }}
        cancelButtonProps={{
          disabled: isAdding,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Role Type" required>
            <Select
              value={ newRole?.roleType }
              placeholder={ 'Please select a role type' }
              onChange={ (roleType: RoleType) => {
                this.setState({ newRole: Object.assign({}, newRole, { roleType: roleType, id: null }) });
              } }
            >
              { Object.keys(RoleType).map((roleType) => (
                <Select.Option key={ roleType } value={ roleType }>
                  { roleType }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item label="Role" required>
            <Select
              showSearch
              disabled={ _.isEmpty(newRole?.roleType) }
              value={ newRole?.id }
              placeholder={ 'Please select a role' }
              filterOption={ (input: any, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              } }
              onChange={ (roleId: number) => {
                this.setState({ newRole: Object.assign({}, newRole, { id: roleId }) });
              } }
            >
              { availableRoles.map((role) => (
                <Select.Option key={ role.id } value={ role.id }>
                  { role.title }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item label="Entity Role Type" required>
            <Select
              value={ newRole?.entityRoleType }
              placeholder={ 'Please select an entity role type' }
              onChange={ (entityRoleType: EntityRoleTypes) => {
                this.setState({ newRole: Object.assign({}, newRole, { entityRoleType: entityRoleType }) });
              } }
            >
              { Object.keys(EntityRoleTypes).map((entityRoleType) => (
                <Select.Option key={ entityRoleType } value={ entityRoleType }>
                  { entityRoleType }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={ !!newRole?.required }
              onChange={ (e) => {
                this.setState({ newRole: Object.assign({}, newRole, { required: Number(e.target.checked) }) });
              } }
            >
              Required
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderEditRoleDialog = () => {
    const { entityRoles, editRole } = this.props;
    const { editableRole, isEditing } = this.state;
    const roleInitialState = entityRoles.find(role => role.id === editableRole?.id);

    const onCancel = () => this.mounted && this.setState({
      editableRole: null,
      isEditing: false,
      showEditRoleDialog: false,
    });

    return (
      <Modal
        title={ `Edit Role` }
        maskClosable={ !isEditing }
        closable={ !isEditing }
        centered
        visible
        onCancel={ onCancel }
        onOk={ () => {
          if (this.mounted && editableRole) {
            const { id, type, required } = editableRole;
            this.setState({ isEditing: true }, () => editRole(id, { type, required }, onCancel));
          }
        } }
        okText={ 'Save' }
        okButtonProps={{
          disabled: isEditing || _.isEqual(editableRole, roleInitialState) || _.isEmpty(editableRole?.type),
          loading: isEditing,
        }}
        cancelButtonProps={{
          disabled: isEditing,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Role Name">
            <Input
              value={ editableRole?.title }
              disabled
              readOnly
            />
          </Form.Item>
          <Form.Item label="Type" required>
            <Select
              value={ editableRole?.type }
              placeholder={ 'Please select a role type' }
              onChange={ (roleType: EntityRoleTypes) => {
                this.setState({ editableRole: Object.assign(editableRole, { type: roleType }) });
              } }
            >
              { Object.keys(EntityRoleTypes).map((roleType) => (
                <Select.Option key={ roleType } value={ roleType }>
                  { roleType }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={ !!editableRole?.required }
              onChange={ (e) => {
                this.setState({ editableRole: Object.assign(editableRole, { required: Number(e.target.checked) }) });
              } }
            >
              Required
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderRemoveRoleDialog = () => {
    const { deleteRole } = this.props;
    const { deletedRoleId, isDeletingRole } = this.state;

    const onCancel = () => this.mounted && this.setState({
      deletedRoleId: null,
      isDeletingRole: false,
      showRemoveRoleDialog: false,
    });

    return (
      <Modal
        title={ 'Remove Role' }
        closable={ !isDeletingRole }
        maskClosable={ !isDeletingRole }
        centered
        visible
        onCancel={ onCancel }
        onOk={ () => {
          if (this.mounted && deletedRoleId) {
            this.setState({ isDeletingRole: true }, () => deleteRole(deletedRoleId, onCancel));
          }
        } }
        okButtonProps={{
          danger: true,
          disabled: isDeletingRole,
          loading: isDeletingRole,
        }}
        cancelButtonProps={{
          disabled: isDeletingRole,
        }}
      >
        <p className="mB-10">
          Are you sure you want to remove this role?
        </p>
        <p>All resources who hold this role against this entity type will have their access revoked.</p>
      </Modal>
    );
  };

  render = () => {
    const { isFetchingRoles } = this.props;
    const { showAddRoleDialog, showEditRoleDialog, showRemoveRoleDialog } = this.state;

    return (
      <BlockingSpinner isLoading={ isFetchingRoles } style={{ height: '65vh' }}>
        { this.renderRoles() }
        { showAddRoleDialog && this.renderAddRoleDialog() }
        { showEditRoleDialog && this.renderEditRoleDialog() }
        { showRemoveRoleDialog && this.renderRemoveRoleDialog() }
      </BlockingSpinner>
    );
  };
};

export default EntityRolesTab;
