// Libs
import * as React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import { RestrictionHoC, hasPermission } from 'components/restriction';
import CreateRecordView from 'views/common/CreateRecordView';
import AdvancedList from "components/advanced-list";
import Dropdown from 'components/dropdown';

// Services
import Notification from 'services/notification';
import { getUserSetting } from 'services/settings';
import { Api } from 'services/api';

// Icons
import { LoadingOutlined } from '@ant-design/icons';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbsLoading,
  setBreadcrumbs,
} from 'store/UI/ActionCreators';

// Interfaces
import { UserEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { Action as DropdownAction } from 'components/dropdown';
import { UserPermissions } from 'types/permissions';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

const messages = defineMessages({
  create: {
    id: 'general.create',
    defaultMessage: 'Create',
    description: '',
  },
  title: {
    id: 'locations.column_title',
    defaultMessage: 'Title',
    description: '',
  },
  created: {
    id: 'locations.column_created',
    defaultMessage: 'Created',
    description: '',
  },
  list_view: {
    id: 'locations.list_view',
    defaultMessage: 'List View',
    description: '',
  },
  map_view: {
    id: 'locations.map_view',
    defaultMessage: 'Map View',
    description: '',
  },
});

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
};

interface State {
  record: any;
  isFetching: boolean;
  isCreateLoading: boolean;
  showCreateModal: {
    id?: number;
    bundle: string;
    type: string;
    parent_id?: number;
    parent_type?: string;
    parent_bundle?: string;
  } | null;
  exportingRow: number | null;
};

class PurchaseOrders extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    record: null,
    isFetching: false,
    isCreateLoading: false,
    showCreateModal: null,
    exportingRow: null,
  };

  componentDidMount = async () => {
    const { client_id } = this.props;

    this.mounted = true;

    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Finance', path: '/finance' },
      { title: 'Purchase Orders', path: '/finance/purchase-orders' }
    ], false);

    this.props.setSecondarySidebarRoutes([]);

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const record = await API.get(`client/${client_id}/record/purchase-order/advanced-list`, {
        filters: {},
        currency: getUserSetting('currency'),
        measurement: getUserSetting('measurement'),
      });

      this.mounted && this.setState({
        record: record
      });

    } catch (error) {
      console.error('Error: ', error);
      Notification('error', 'Failed to get data');
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getFlatten = (data: any) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({ ..._value });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  filterReport = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { client_id } = this.props;
    try {
      const record = await API.get(`client/${client_id}/record/purchase-order/advanced-list`, {
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      this.setState({
        record: record
      }, () => {
        callback(true);
      });
    } catch (error) {
      callback(false);
      Notification('error', 'Filter Failed');
    }
  };

  exportFile = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { client_id } = this.props;
    const { record } = this.state;
    try {
      await API.download(`client/${client_id}/record/purchase-order/advanced-list/export`, `pacs_export_${_.snakeCase(record.title)}_${moment().format('YYYY-MM-DD')}.xlsx`, {
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      callback(true);
    } catch (error) {
      callback(false);
      Notification('error', 'Export Failed');
    }
  };

  renderListView = (record: any) => {

    // Add action column if not attached
    if (!record.columns.some((column: any) => column.id === 'actions')) {
      record.columns.push(
        {
          id: 'actions',
          label: 'Actions',
          render: (__: any, row: any) => {

            if (this.state.exportingRow === row.key ||
              (this.state.isCreateLoading &&
                this.state.showCreateModal?.id === row?.id &&
                this.state.showCreateModal?.bundle === row?.bundle &&
                this.state.showCreateModal?.type === 'invoice'
              )
            ) {
              return <span className="d-f ai-c jc-fe mR-10" style={{ height: 35 }}><LoadingOutlined /></span>;
            }

            const actions: DropdownAction[] = [
              {
                node: '',
                onClick: () => {}
              }
            ];

            if (hasPermission(this.props.permissions, 'record_invoice_create') && row?.type === 'purchase_order') {
              actions.push({
                node: 'Create Invoice',
                disabled: !!row.disabled,
                onClick: () => this.setState({
                  isCreateLoading: true,
                  showCreateModal: {
                    id: row?.id,
                    bundle: 'record',
                    type: 'invoice',
                    parent_id: row.id,
                    parent_type: row.type,
                    parent_bundle: row.bundle,
                  }
                })
              });
            }

            actions.push({
              node: 'Export PDF',
              onClick: async () => {
                try {

                  await new Promise((resolve) => this.setState({ exportingRow: row.key }, () => resolve(null)));
                  await API.download(`client/${this.props.client_id}/${row?.bundle.replaceAll('_', '-')}/${row?.type.replaceAll('_', '-')}/${row.id}/export/pdf`, `pacs_${row?.type}_${moment().format('YYYY-MM-DD')}.pdf`);

                } catch (error) {
                  Notification('error', 'Export Failed');
                } finally {
                  this.setState({
                    exportingRow: null
                  });
                }
              }
            });

            return (
              <span className="d-f ai-c jc-c" style={{ height: 35 }}>
                <Dropdown actions={ actions } />
              </span>
            );
          },
          align: 'right',
          fixed: 'right',
          width: 75,
        }
      );
    }

    const defaultExpandedRowKeys = this.getFlatten(record?.data)
      .reduce((acc: any, curr: any) => {
        if (curr?.expanded) {
          acc.push(curr?.key);
        }
        return acc;
      }, []);

    return (
      <AdvancedList
        clientId={ this.props.client_id }
        filters={ record?.filters || [] }
        columns={ record?.columns || [] }
        items={ record?.data || [] }
        config={ record?.config }
        defaultExpandedRowKeys={ defaultExpandedRowKeys }
        onExport={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.exportFile(filters, currency, measurement, callback) }
        onFilter={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.filterReport(filters, currency, measurement, callback) }
      />
    );
  };

  render = () => {
    const { permissions, intl: { formatMessage } } = this.props;
    const { record, showCreateModal, isFetching, isCreateLoading } = this.state;

    if (isFetching || !record) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const actions: DropdownAction[] = [];

    if (hasPermission(permissions, 'record_purchase_order_create')) {
      actions.push({
        node: 'Create Purchase Order',
        onClick: () => this.mounted && this.setState({
          isCreateLoading: true,
          showCreateModal: {
            bundle: 'record',
            type: 'purchase-order',
          },
        }),
        isLoading: isCreateLoading
      });
    }

    return (
      <>
        <Jumbotron
          title={ 'Purchase Orders' }
          tabs={ [
            {
              label: formatMessage(messages.list_view),
              node: this.renderListView(record),
            }
          ] }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
        />
        { !!showCreateModal &&
          <CreateRecordView
            type={ showCreateModal?.type.replaceAll('_', '-') }
            entity={ showCreateModal?.bundle.replaceAll('_', '-') }
            parent_id={ !!showCreateModal?.parent_id ? showCreateModal?.parent_id : undefined }
            parent_type={ !!showCreateModal?.parent_type ? showCreateModal?.parent_type : undefined }
            parent_bundle={ !!showCreateModal?.parent_bundle ? showCreateModal?.parent_bundle : undefined }
            onReady={ () => this.mounted && this.setState({ isCreateLoading: false }) }
            onClose={ () => this.mounted && this.setState({ showCreateModal: null }) }
          />
        }
      </>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
    client_id: store.ClientState.client_id,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[]) => dispatch(setBreadcrumbs(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(PurchaseOrders), 'record_purchase_order_view_list'));
