// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// Components
import Jumbotron from 'components/jumbotron';
import { hasPermission } from 'components/restriction';
import CreateRecordView from 'views/common/CreateRecordView';
import AdvancedList from "components/advanced-list";
import Dropdown from 'components/dropdown';
import { CreateOption } from "components/basic-list";

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

// Icons
import { LoadingOutlined } from '@ant-design/icons';

// Interfaces
import AppState from 'store/AppState.interface';
import { Action as DropdownAction } from 'components/dropdown';
import { RecordFormEntity } from 'types/entities';

const API: Api = new Api();

interface Props {
  id?: number;
  type: string;
  entity: string;
  client_id: number;
  permissions?: any;
  record: RecordFormEntity;
  pure?: boolean;
  config?: any;
};

interface State {
  list: any;
  isFetching: boolean;
  isCreateLoading: boolean;
  showCreateModal: {
    id?: number;
    bundle: string;
    type: string;
    parent_id?: number;
    parent_type?: string;
    parent_bundle?: string;
  } | null;
  createOption: CreateOption | null;
  exportingRow: number | null;
};

class Invoices extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    list: [],
    isFetching: false,
    isCreateLoading: false,
    showCreateModal: null,
    exportingRow: null,
    createOption: null,
  };

  componentDidMount = async () => {
    const { client_id, record } = this.props;

    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const list = await API.get(`client/${client_id}/record/invoice/advanced-list`, {
        entity_id: record.id,
        entity_type: record.type,
        entity_bundle: record.bundle,
      });

      this.mounted && this.setState({
        list: list,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  getFlatten = (data: any = []) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({ ..._value });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  filterReport = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { client_id, record } = this.props;
    try {
      const list = await API.get(`client/${client_id}/record/invoice/advanced-list`, {
        entity_id: record.id,
        entity_type: record.type,
        entity_bundle: record.bundle,
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      this.setState({
        list: list
      }, () => {
        callback(true);
      });
    } catch (error) {
      callback(false);
      Notification('error', 'Filter Failed');
    }
  };

  exportFile = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { client_id, record } = this.props;
    const { list } = this.state;
    try {
      await API.download(`client/${client_id}/record/invoice/advanced-list`, `pacs_report_${_.snakeCase(list.title)}_${moment().format('YYYY-MM-DD')}.xlsx`, {
        entity_id: record.id,
        entity_type: record.type,
        entity_bundle: record.bundle,
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      callback(true);
    } catch (error) {
      callback(false);
      Notification('error', 'Export Failed');
    }
  };

  renderList = (list: any, isFetching: boolean) => {
    const { permissions } = this.props;
    const createOptions = _.has(list?.config, 'create_options') ? list?.config?.create_options : [];
    const actions: DropdownAction[] = [];

    // Add action column if not attached
    if (!_.isEmpty(list) && !list?.columns?.some((column: any) => column.id === 'actions')) {
      list.columns.push(
        {
          id: 'actions',
          label: 'Actions',
          render: (__: any, row: any) => {

            if (this.state.exportingRow === row.key ||
              (this.state.isCreateLoading &&
                this.state.showCreateModal?.id === row?.id &&
                this.state.showCreateModal?.bundle === row?.bundle &&
                this.state.showCreateModal?.type === row?.type
              )
            ) {
              return <LoadingOutlined className="fl-r fsz-def text-ant-default" />;
            }

            const _actions: DropdownAction[] = [
              {
                node: '',
                onClick: () => {}
              }
            ];

            _actions.push({
              node: 'Export',
              onClick: async () => {
                try {

                  await new Promise((resolve) => this.setState({ exportingRow: row.key }, () => resolve(null)));
                  await API.download(`client/${this.props.client_id}/${row?.bundle}/${row?.type}/${row.id}/export/pdf`, `pacs_export_${moment().format('YYYY-MM-DD')}.xlsx`);

                } catch (error) {
                  Notification('error', 'Export Failed');
                } finally {
                  this.setState({
                    exportingRow: null
                  });
                }
              }
            });

            return <Dropdown actions={ _actions } />;
          },
          align: 'right',
          fixed: 'right',
          width: 75,
        }
      );
    }

    const defaultExpandedRowKeys = this.getFlatten(list?.data)
      .reduce((acc: any, curr: any) => {
        if (curr?.expanded) {
          acc.push(curr?.key);
        }
        return acc;
      }, []);

    createOptions.forEach((createOption: CreateOption) => {
      const hasCreatePermissions = hasPermission(permissions, `${createOption.bundle}_${_.snakeCase(createOption.type)}_create`);
      const node = `Create ${createOption.label}`;

      if (hasCreatePermissions && !actions.some((action: DropdownAction) => action.node === node)) {
        actions.push({
          node: node,
          disabled: !!createOption.disabled,
          onClick: () => this.setState({
            showCreateModal: {
              bundle: createOption.bundle,
              type: createOption.type,
            },
            isCreateLoading: true,
          }),
          isLoading: this.state.isCreateLoading
        });
      }
    });

    return (
      <AdvancedList
        clientId={ this.props.client_id }
        filters={ list?.filters || [] }
        columns={ list?.columns || [] }
        rightActions={ actions }
        items={ list?.data || [] }
        config={ list?.config }
        defaultExpandedRowKeys={ defaultExpandedRowKeys }
        isFetching={ isFetching }
        onExport={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.exportFile(filters, currency, measurement, callback) }
        onFilter={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.filterReport(filters, currency, measurement, callback) }
      />
    );
  };

  render = () => {
    const { pure, record, type, entity } = this.props;
    const { list, isFetching, showCreateModal } = this.state;

    return (
      <>
        { pure ? (
            <div>
              { this.renderList(list, isFetching) }
            </div>
          ) : (
            <Jumbotron
              content={ 'Report' }
              title={ this.props.record?.title }
              tabs={[
                {
                  label: '',
                  node: this.renderList(list, isFetching),
                },
              ]}
            />
          )
        }
        { showCreateModal &&
          <CreateRecordView
            type={ showCreateModal?.type.replaceAll('_', '-') }
            entity={ showCreateModal?.bundle.replaceAll('_', '-') }
            parent_id={ record ? record?.id : undefined }
            parent_type={ type }
            parent_bundle={ entity }
            onReady={ () => this.mounted && this.setState({ isCreateLoading: false }) }
            onClose={ () => this.mounted && this.setState({ showCreateModal: null }) }
          />
        }
      </>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps)(Invoices);
