// Libs
import * as React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import { RestrictionHoC, hasPermission } from 'components/restriction';
import CreateRecordView from 'views/common/CreateRecordView';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import BasicList, { Config } from "components/basic-list";
import MapList from "components/map-list";

// Services
import { Api } from 'services/api';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbsLoading,
  setBreadcrumbs,
} from 'store/UI/ActionCreators';

// Interfaces
import { UserEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

const messages = defineMessages({
  create: {
    id: 'general.create',
    defaultMessage: 'Create',
    description: '',
  },
  title: {
    id: 'locations.column_title',
    defaultMessage: 'Title',
    description: '',
  },
  created: {
    id: 'locations.column_created',
    defaultMessage: 'Created',
    description: '',
  },
  list_view: {
    id: 'locations.list_view',
    defaultMessage: 'List View',
    description: '',
  },
  map_view: {
    id: 'locations.map_view',
    defaultMessage: 'Map View',
    description: '',
  },
});

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
};

interface State {
  columns: any[];
  items: any[];
  config: Config | null;
  isFetching: boolean;
  isCreateLoading: boolean;
  showCreateLeaseModal: boolean;
  showCreateOwnedModal: boolean;
};

class Properties extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    columns: [],
    items: [],
    config: null,
    isFetching: false,
    isCreateLoading: false,
    showCreateLeaseModal: false,
    showCreateOwnedModal: false,
  };

  componentDidMount = async () => {
    const { client_id } = this.props;

    this.mounted = true;

    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Portfolio', path: '/portfolio' },
      { title: 'Properties', path: '/portfolio/properties' }
    ], false);

    this.props.setSecondarySidebarRoutes([]);

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const record = await API.get(`client/${client_id}/properties`);
      const items = record.data.map((property: any) => {
        return {
          ...property,
          key: `${_.snakeCase(property.type)}-${property.id}`
        };
      });

      this.mounted && this.setState({
        columns: record.columns,
        items: items,
        config: record.config,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderListView = () => {
    return (
      <BasicList
        exportTitle={ 'Properties' }
        config={ this.state.config }
        columns={ this.state.columns }
        items={ this.state.items }
      />
    );
  };

  renderMapView = (columns: any, items: any) => {
    return (
      <MapList
        columns={ columns }
        items={ items }
      />
    );
  };

  render = () => {
    const { permissions, intl: { formatMessage } } = this.props;
    const { showCreateLeaseModal, showCreateOwnedModal, isFetching, isCreateLoading } = this.state;

    const tabs = [
      {
        label: formatMessage(messages.list_view),
        node: this.renderListView(),
      },
      {
        label: formatMessage(messages.map_view),
        node: this.renderMapView(this.state.columns, this.state.items),
      }
    ];

    const actions: DropdownAction[] = [];

    // First element is blank to ensure create new appears in action dropdown
    if (hasPermission(permissions, 'record_lease_create') || hasPermission(permissions, 'record_owned_create')) {
      actions.push({
        node: '',
        onClick: () => {}
      });
    }

    if (hasPermission(permissions, 'record_lease_create')) {
      actions.push({
        node: 'Create Lease',
        onClick: () => this.mounted && this.setState({ isCreateLoading: true, showCreateLeaseModal: true }),
        isLoading: isCreateLoading
      });
    }

    if (hasPermission(permissions, 'record_owned_create')) {
      actions.push({
        node: 'Create Owned',
        onClick: () => this.mounted && this.setState({ isCreateLoading: true, showCreateOwnedModal: true }),
        isLoading: isCreateLoading
      });
    }

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ 'Properties' }
          tabs={ tabs }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
        />
        { showCreateLeaseModal &&
          <CreateRecordView
            entity={ 'record' }
            type={ 'lease' }
            onReady={ () => this.mounted && this.setState({ isCreateLoading: false }) }
            onClose={ () => this.mounted && this.setState({ showCreateLeaseModal: false }) }
          />
        }
        { showCreateOwnedModal &&
          <CreateRecordView
            entity={ 'record' }
            type={ 'owned' }
            onReady={ () => this.mounted && this.setState({ isCreateLoading: false }) }
            onClose={ () => this.mounted && this.setState({ showCreateOwnedModal: false }) }
          />
        }
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
    client_id: store.ClientState.client_id,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[]) => dispatch(setBreadcrumbs(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(Properties), ['record_lease_view_list', 'record_owned_view_list']));
