import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import moment from 'moment';
import { expireSession } from 'services/auth';

export const axiosConfig = {
  baseURL: `${process.env.REACT_APP_API_URL}/v1/`,
  headers: {
    'Content-Type': 'application/json',
  }
};

export interface AuthenticationResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

export type Token = string;
class Api {
  fetchingAccessToken: boolean;
  fetchingRefreshToken: boolean;
  instance: AxiosInstance;
  private requests: any[];
  private requestUrls: string[];

  constructor() {
    this.fetchingAccessToken = false;
    this.fetchingRefreshToken = false;
    this.requests = [];
    this.requestUrls = [];
    this.instance = axios.create(axiosConfig);

    this.getTokens();
    this.initialiseInstance();
  }

  public destroyInstance = () => {
    try {
      localStorage.clear();
      this.fetchingAccessToken = false;
      this.fetchingRefreshToken = false;
      this.requests = [];
      this.requestUrls = [];
      this.instance = axios.create(axiosConfig);

      this.initialiseInstance();
      return true;
    } catch (error) {
      throw error;
    }
  };

  public hasTokens = () => {
    const { accessToken, refreshToken } = this.getTokens();
    if (accessToken && refreshToken) {
      return true;
    }

    return false;
  };

  public hasTokenExpired = (expiresAt: string) => {
    if (!expiresAt) return true;
    return moment().isAfter(moment(expiresAt));
  };

  private initialiseInstance = async () => {
    this.instance.interceptors.request.use(
      (originalConfig: AxiosRequestConfig) => {
        const config = {
          ...originalConfig,
        };

        config.headers.Accept = 'application/json';
        const { accessToken } = this.getTokens();

        if (!!accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    this.instance.interceptors.response.use(
      (response) => {
        // If the request succeeds, we don't have to do anything and just return the response
        return response;
      },
      async (error: AxiosError) => {
        try {
          const errorResponse = error.response;
          const { refreshToken } = this.getTokens();

          if (this.isTokenExpiredError(errorResponse) && error.response?.status === 401) {
            if (refreshToken) {
              return await this.refreshTokensRestartRequest(error);
            }

            return expireSession();
          } else {
            throw error;
          }
        } catch (error) {
          throw error;
        }
      },
    );
  };

  private getTokens = () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      const expiresAtTimeStamp: any = localStorage.getItem('expiresAt');
      const expiresAt = moment.unix(expiresAtTimeStamp).format();

      if (this.hasTokenExpired(expiresAt) && refreshToken && this.fetchingAccessToken === false) {
        this.getRefreshTokens();
      }

      return {
        accessToken,
        refreshToken,
        expiresAt
      };
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  public getTokenExpirationTime = () => {
    const expiresAtTimeStamp: any = localStorage.getItem('expiresAt');
    return moment.unix(expiresAtTimeStamp).format();
  };

  public setTokens = (accessToken: Token, refreshToken: Token, expiresAt: number) => {
    try {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('expiresAt', `${expiresAt}`);
    } catch (error) {
      throw error;
    }
  };

  public getRefreshTokens = async (error?: any) => {
    try {
      this.fetchingAccessToken = true;
      const tokens = this.getTokens();

      const response: AxiosResponse<AuthenticationResponse> = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/v1/oauth/token`,
        data: `grant_type=refresh_token&refresh_token=${tokens.refreshToken}&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&client_secret=${process.env.REACT_APP_AUTH_CLIENT_SECRET}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).catch(error => Promise.reject({ callee: 'refreshToken', error }));

      if (!response.data && error) {
        Promise.reject({ callee: 'refreshTokenResponse', error });
      }

      const decodedToken = jwtDecode<JwtPayload>(response.data.access_token);
      const accessToken = response.data.access_token;
      const refreshToken = response.data.refresh_token;
      const expiresAt = decodedToken.exp || moment().add(response.data.expires_in, 'seconds').valueOf();
      this.setTokens(accessToken, refreshToken, expiresAt);

      return {
        accessToken,
        refreshToken,
      };
    } catch (error) {
      this.destroyInstance();
      expireSession();
      throw(error);
    }
  };

  private refreshTokensRestartRequest = async (error: any) => {
    try {
      const { response: errorResponse } = error;
      const { refreshToken } = this.getTokens();
      if (refreshToken === '') {
        // We can't refresh, throw the error anyway
        return Promise.reject(error);
      }

      // We generate the list of requests to retry
      const retryOriginalRequest = new Promise((resolve) => {
        if (this.requestUrls.includes(errorResponse.config.url) === false) {
          this.requestUrls.push(errorResponse.config.url);
          this.addRequest((access_token: string) => {
            errorResponse.config.headers.Authorization = `Bearer ${access_token}`;
            resolve(axios(errorResponse.config));
          });
        }
      });

      if (!this.fetchingAccessToken && refreshToken) {
        const { accessToken } = await this.getRefreshTokens(error);
        this.fetchingAccessToken = false;
        if (accessToken) {
          this.onAccessTokenFetched(accessToken);
        }
      }

      return retryOriginalRequest;
    } catch (error) {
      // logout();
      return Promise.reject(error);
    }
  };

  private onAccessTokenFetched = async (accessToken: string) => {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    this.requests = this.requests.filter((callback, index) => {
      callback(accessToken);
      this.requestUrls = this.requestUrls.splice(index, 1);
      return false;
    });

    if (this.requests.length === 0) {
      this.requests = [];
      this.requestUrls = [];
    }
  };

  private isTokenExpiredError(errorResponse: any) {
    return errorResponse ? errorResponse.status === 401 : true;
  }

  private addRequest = (callback: (access_token: string) => void) => {
    this.requests.push(callback);
  };
}

export default Api;
