// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Components
import Jumbotron from "components/jumbotron";
import { Table, Select, Pagination, Input, Tooltip } from 'antd';
import { RestrictionHoC } from 'components/restriction';
import CoverModal from 'components/cover-modal';
import Badge, { BadgeType } from 'components/badge';

// Views
import EmailView from 'views/email/Email';

// Api
import { Api } from 'services/api';

// Service
import { getFormatedDate } from 'services/settings';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";

interface Props {
  client_id: number,
  intl: IntlShape;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  active_email_id: number | null;
  emails: any;
  currentPage: number;
  total: number;
  itemsPerPage: number;
  search: string | null;
  isLoading: boolean;
  showPreview: boolean;
};

const API: Api = new Api();
const { Option } = Select;
const { Search } = Input;

class Emails extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    active_email_id: null,
    emails: [],
    currentPage: 1,
    itemsPerPage: 25,
    total: 0,
    search: null,
    isLoading: false,
    showPreview: false
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const { currentPage, itemsPerPage} = this.state;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Emails', path: '/admin/emails' },
    ], false);

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));
      const emails = await API.get(`client/${client_id}/admin/emails`, {
        page: currentPage,
        items_per_page: itemsPerPage,
      });

      this.mounted && this.setState({
        total: emails.total,
        emails: emails.results,
      });
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { client_id } = this.props;
    const { currentPage, itemsPerPage, search } = this.state;

    // Fetch companies
    if (prevState.currentPage !== currentPage || prevState.itemsPerPage !== itemsPerPage || prevState.search !== search) {
      try {

        await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));
        const emails = await API.get(`client/${client_id}/admin/emails`, {
          search: search,
          page: currentPage,
          items_per_page: itemsPerPage,
        });

        this.mounted && this.setState({
          total: emails.total,
          emails: emails.results,
        });

      } catch {
        console.error('Failed to fetch companies');
      } finally {
        this.mounted && this.setState({
          isLoading: false,
        });
      }
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderPreview = () => {
    const { active_email_id, emails } = this.state;
    const email = emails.find((email: any) => email.id === active_email_id);

    if (!email) return;

    return (
      <CoverModal style={{ width: '80%', height: '90%' }} middleContent={ `Email` } onClose={ () => this.setState({ showPreview: false }) }>
        <EmailView email_id={ email.id } />
      </CoverModal>
    );
  };

  renderEmails = () => {
    const { emails, currentPage, total, itemsPerPage, isLoading } = this.state;

    const columns: any = [
      {
        key: 'user_email',
        dataIndex: 'user_email',
        title: 'Email',
        render: (user: string) => {
          return <span>{ user }</span>;
        },
        width: '30%',
        filterable: false,
        sorter: false,
        ellipsis: true,
      },
      {
        key: 'user',
        dataIndex: 'user_name',
        title: 'User',
        render: (user_name: string, email: any) => {
          return <Link className="primaryColor" to={`/admin/users/${email.user_id}`}>{ user_name }</Link>;
        },
        filterable: false,
        sorter: false,
        ellipsis: true,
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: 'Status',
        render: (status: string) => {

          let badgeType = BadgeType.Default;
          let tooltip = null;

          switch (status) {
            case 'PENDING':
              badgeType = BadgeType.Warning;
              tooltip = "The email wasn't sent";
            break;
            case 'SENT':
              badgeType = BadgeType.Success;
              tooltip = 'The email was sent';
            break;
            case 'ERROR':
              badgeType = BadgeType.Danger;
            break;
          }

          return (
            <Tooltip placement="top" title={ tooltip }>
              <div className='d-if'>
                <Badge type={ badgeType } text={ _.startCase(_.toLower(status)).split('_').join(' ') } />
              </div>
            </Tooltip>
          );
        },
        width: '20%',
        filterable: false,
        sorter: false,
        ellipsis: true,
      },
      {
        key: 'subject',
        dataIndex: 'subject',
        title: 'Subject',
        width: '40%',
        render: (subject: any, email: any) => {
          return <span className="link primaryColor" onClick={ () => this.setState({ active_email_id: email.id, showPreview: true }) }>{ subject }</span>;
        },
        sorter: false,
        ellipsis: true,
        filterable: false,
      },
      {
        key: 'created_at',
        dataIndex: 'created_at',
        title: 'Sent Date',
        render: (created_at: any) => getFormatedDate(created_at, undefined, true),
        width: '15%',
        sorter: false,
        ellipsis: true,
        filterable: false,
      }
    ];

    const mapData: any = (emails: any) => {
      return emails.map((email: any) => {
        return {
          'key': email.id,
          'id': email.id,
          'user_name': _.has(email.user, 'full_name') ? email.user.full_name : '',
          'user_email': email.user_email,
          'user_id': _.has(email.user, 'id') ? email.user.id : 0,
          'status': email.status,
          'subject': email.subject,
          'created_at': email.created_at,
        };
      });
    };

    return (
      <>
        <div className="d-f jc-sb ai-c">
          <div className="d-if" style={{ margin: '5px 0 20px 0' }}>
            <Search
              allowClear
              loading={ isLoading }
              placeholder={ 'Quick Search' }
              style={{ width: 300 }}
              onBlur={ event => {
                this.setState({
                  search: event.target.value || null,
                  currentPage: 1,
                });
              }}
              onSearch={ value => {
                this.setState({
                  search: value || null,
                  currentPage: 1,
                });
              }}
            />
          </div>
        </div>
        <div
          className="d-f jc-sb ai-c mB-10"
          style={{ userSelect: 'none' }}
        >
          <div className="d-if mL-10">
            <span>Show</span>
            <span className="mL-10 mR-10">
              <Select
                size={'small'}
                onChange={(value: number) => {
                  this.setState({
                    currentPage: 1,
                    itemsPerPage: value
                  });
                }}
                defaultValue={ itemsPerPage }
              >
                <Option value={ 25 }>25</Option>
                <Option value={ 50 }>50</Option>
                <Option value={ 100 }>100</Option>
              </Select>
            </span>
            <span>entries of <b>{ total }</b></span>
          </div>
          <div className="d-if">
            <Pagination
              showSizeChanger={ false }
              current={ currentPage }
              total={ total }
              pageSize={ itemsPerPage }
              onChange={ page => {
                this.setState({
                  currentPage: page
                });
              } }
            />
          </div>
        </div>
        <div className='Layout-box'>
          <Table
            loading={ isLoading }
            size={ 'small' }
            columns={ columns }
            dataSource={ mapData(emails) }
            pagination={ false }
          />
        </div>
      </>
    );
  };

  render = () => {
    const { showPreview } = this.state;
    return (
      <>
        <Jumbotron
          content={ 'Emails' }
          tabs={[
            {
              label: '',
              node: this.renderEmails(),
            }
          ]}
        />
        { showPreview && this.renderPreview() }
      </>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(Emails), 'access_admin_emails'));
