import { FieldConfig, FormField } from 'components/form/form-wrapper/FormWrapper.interface';

export enum Types {
  Date = 'date',
  Datetime = 'datetime',
};

export interface DateTimeFieldConfig extends FieldConfig {
  time_without_seconds?: boolean;
};

export interface Field extends FormField {
  config: DateTimeFieldConfig;
};
