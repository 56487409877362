export enum ValidationResponseStatus {
  GENERIC_ERROR = 'GENERIC_ERROR',
  CORRUPTION_ERROR = 'CORRUPTION_ERROR',
  VERIFIED = 'VERIFIED',
  IMPORTED = 'IMPORTED',
};

export enum IProcessResponseStatus {
  ERROR = 'ERROR',
  ERROR_ALREADY_IMPORTED = 'ERROR_ALREADY_IMPORTED',
  VERIFIED = 'VERIFIED',
  IMPORTED = 'IMPORTED',
};

export enum IProcessStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  COMPLETE = 'COMPLETE',
  UNKNOWN = 'UNKNOWN',
};

export interface IValidationResponse {
  title: string;
  reference: string;
  status: ValidationResponseStatus;
  status_message:string[] | null;
  list: {
    columns: any[];
    data: any[];
  };
  export_id: string;
  file_hash: string;
  error_file: string;
};

export interface IProcessResponse {
  status: IProcessResponseStatus;
  messages: string[] | null;
  error_file: string;
  filename: string;
  export_id: string;
  list: {
    columns: any[];
    data: any[];
  };
};

export interface IProcess {
  user_id: number;
  id: number;
  response: IProcessResponse | null;
  job_batch: {
      id: string;
      name: string;
      total_jobs: number;
      pending_jobs: number;
      failed_jobs: number;
      cancelled_at: number | null;
      created_at: number;
      finished_at: number | null;
  },
  status: IProcessStatus;
};