enum ClientActionTypes {
  GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS',
  GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE',
  SET_CLIENT_SUCCESS = 'SET_CLIENT_SUCCESS',
  SET_CLIENT_FAILURE = 'SET_CLIENT_FAILURE',
  GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START',
  GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED',
}

export default ClientActionTypes;