// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import CoverModal from 'components/cover-modal';
import FormWrapper from 'components/form/form-wrapper';

// Utils
import { instanceOfFormErrorResponse, instanceOfRecordFormEntity } from 'utils/guards';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';

const API: Api = new Api();

interface Props {
  type: string;
  entity: string;
  client_id?: number;
  parent_id?: number;
  label?: string | null;
  parent_bundle?: string;
  parent_type?: string;
  params?: any;
  permissions?: any;
  onClose?(): void;
  onReady?(): void;
  onCreated?(record: RecordFormEntity): void;
  history: Record<string, any>;
};

interface State {
  isFetching: boolean;
  form: any | null;
};

class CreateRecord extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    isFetching: false,
    form: null,
  };

  componentDidMount = async () => {
    const { type, parent_id, parent_bundle, parent_type, client_id, entity, params } = this.props;
    this.mounted = true;

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      let _params = {};

      // Related entity
      if (!!parent_id && !!parent_bundle && !!parent_type) {
        _params = {
          ...params,
          parent_id: parent_id,
          parent_bundle: parent_bundle.replaceAll('-', '_'),
          parent_type: parent_type.replaceAll('-', '_')
        };
      }

      const form = await API.get(`client/${client_id}/${entity}/${type}/form`, _params);

      this.mounted && this.setState({
        isFetching: false,
        form: form
      });

    } catch (error) {
      console.error(error);
      Notification('error', '', 'Unable to load record');
      this.handleClose();
    } finally {
      this.props.onReady && this.props.onReady();
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  onCreate = async (record: RecordFormEntity, callback: (response: any) => void) => {
    const { client_id, type, history, entity } = this.props;

    try {
      if (instanceOfRecordFormEntity(record)) {
        const createdRecord = await API.post(`client/${client_id}/${entity}/${type}`, {
          data: JSON.stringify(record)
        });

        if (this.props.onCreated) {
          this.props.onCreated(createdRecord);
        } else {
          if (instanceOfFormErrorResponse(createdRecord)) {
            callback(createdRecord);
          } else {
            history.push(createdRecord.path);
          }
        }

      } else {
        Promise.reject({
          callee: 'recordInterfaceGuard',
          error: new Error('Location is not an instance of RecordFormEntity')
        });
      }
    } catch (error: any) {

      // Don't know what to catch
      if (!_.has(error, 'callee')) {
        Notification('error', '', 'Unknown Error creating record');
        callback(null);
        return;
      }

      switch (error.callee) {
        case 'recordInterfaceGuard':
          Notification('error', '', 'Unknown Error create record');
          break;

        default:
          Notification('error', '', 'Unknown Error create record');
          break;
      }
    }
  };

  render = () => {
    const { type, entity, client_id, label } = this.props;
    const { isFetching, form } = this.state;

    if (isFetching || !form) return <></>;

    const record: any = {
      title: null,
      version: 1,
      form: form.form,
      form_config: form.form_config,
      type: type,
      bundle: entity
    };

    return (
      <CoverModal style={{ width: '90%', height: '90%' }} middleContent={ `Create ${ label ? label : _.startCase(type)}` } onClose={ this.handleClose }>
        { form && client_id &&
          <FormWrapper
            clientId={ client_id }
            isNew
            canView
            canEdit={{ status: true, tooltip: null }}
            canCreate
            isLockedTitle={ _.has(form, 'form_config') && !!form.form_config.is_locked_title }
            record={ record }
            onCreate={ this.onCreate }
            entity={ entity }
          />
        }
      </CoverModal>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(withRouter(CreateRecord));