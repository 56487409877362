// Libs
import * as React from "react";
import _ from 'lodash';

// Components
import { Button, Form, Select, Tooltip, TreeSelect, Upload } from 'antd';
import CoverModal from 'components/cover-modal';

// Icons
import { InboxOutlined } from "@ant-design/icons";

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';
import { nestedSet } from 'utils/utils';

interface Props {
  clientId?: number;
  runEndpoint: string;
  checkEndpoint: string;
  entities: any[];
  onClose: () => void;
  onSuccess: (response: any) => void;
};

interface State {
  opexCoa: any[];
  opexCoaIds: any[];
  suppliers: any[];
  supplier: number | null;
  suppliersAreLoading: boolean;
  folders: any;
  fileList: any[];
  isLoading: boolean;
  isUploading: boolean;
  folder_id: number | null;
  errorResponse: any[];
};

const API: Api = new Api();

class ScopeItemUploadDocumentsModal extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    opexCoa: [],
    opexCoaIds: [],
    suppliers: [],
    supplier: null,
    suppliersAreLoading: false,
    folders: null,
    fileList: [],
    isUploading: false,
    isLoading: false,
    folder_id: null,
    errorResponse: [],
  };

  componentDidMount = async () => {
    const { clientId } = this.props;

    this.mounted = true;

    try {
      if (!clientId) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const opexCoa = await API.get(`client/${clientId}/category/opex-coa`, {});

      const folders = await API.get(`client/${clientId}/bulk-operations/upload-document/available-folders`, {
        bundle: 'record',
        types: ['contract_scope']
      });

      this.mounted && this.setState({
        opexCoa: !!opexCoa?.data ? opexCoa.data : [],
        folders: folders
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleUpload = async (folder: any, opexCoa: any[], supplier: any[]) => {
    const { fileList } = this.state;
    const { checkEndpoint, runEndpoint, entities, onClose, onSuccess } = this.props;

    try {
      await new Promise((resolve) => this.setState({ isUploading: true }, () => resolve(null) ));

      const form = new FormData();

      form.append('config[folder]', folder);
      form.append('config[coaIds]', JSON.stringify(opexCoa));
      form.append('config[supplier]', JSON.stringify(supplier));
      form.append('entities', JSON.stringify(entities));

      fileList.forEach((file: any) => {
        form.append('config[documents][]', file.originFileObj);
      });

      const response = await API.post(checkEndpoint, form);

      const hasError = response.find((_error: any) => _error.status === 'error');

      if (!hasError) {
        const runResponse = await API.post(runEndpoint, form);

        Notification('success', `Successfully uploaded documents`);
        onClose();
        onSuccess(runResponse);
      }
    } catch (error: any) {
      console.error('Error: ', error);
      Notification('error', 'Failed to upload documents');
      this.setState({
        errorResponse: error?.data || []
      });
    } finally {
      this.mounted && this.setState({
        isUploading: false
      });
    }
  };

  getSuppliers = async (opexCoa: any[]) => {
    try {
      const { clientId } = this.props;

      await new Promise((resolve) => this.setState({ suppliersAreLoading: true }, () => resolve(null)));

      const suppliers = await API.get(`client/${clientId}/bulk-operations/upload-scope-document/suppliers-by-coa`, {
        coaIds: opexCoa
      });

      return suppliers;

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        suppliersAreLoading: false,
      });
    }
  };

  renderCoaSelect = (): JSX.Element => {
    const { opexCoa, opexCoaIds } = this.state;

    return (
      <TreeSelect
        allowClear
        showSearch
        multiple
        dropdownMatchSelectWidth={ false }
        placeholder={ 'All' }
        showCheckedStrategy={ TreeSelect.SHOW_ALL }
        maxTagCount={ 2 }
        value={ opexCoaIds }
        treeData={ nestedSet(opexCoa) }
        filterTreeNode={ (input: string, option: any) => {
          if (option) {
            const filteredInput = input.toLocaleLowerCase();
            const title = option.title && option.title.toLowerCase();

            if (title.includes(filteredInput)) {
              return true;
            }
          }

          return false;
        } }
        onChange={(value: any[]) => {
          this.setState({
            opexCoaIds: value
          }, async () => {
            const _suppliers = await this.getSuppliers(value);

            this.setState({
              suppliers: _suppliers,
            });
          });
        }}
      />
    );
  };

  render = () => {
    const { onClose } = this.props;
    const { fileList, isUploading, isLoading, folders, folder_id, suppliers, supplier, suppliersAreLoading, errorResponse } = this.state;
    const mappedFolders: any = [];

    if (folders) {
      Object.keys(folders).forEach(key => {
        mappedFolders.push({
          'id': folders[key].reference,
          'key': folders[key].reference,
          'value': folders[key].reference,
          'title': folders[key].title,
          'context': null,
          'selectable': true,
          'disabled': false,
          'children': folders[key].children ? folders[key].children : [],
        });
      });
    }

    return (
      <CoverModal
        style={{ minWidth: '800px', minHeight: '500px', maxHeight: '90vh' }}
        middleContent={ 'Upload' }
        isLoading={ isLoading }
        onClose={ () => !isUploading && onClose() }
      >
        { !_.isEmpty(errorResponse) && (
          <div className='d-f fxd-c jc-c mT-10 mB-10'>
            <div className="fw-600 mB-10">We could not run the transition because:</div>
            { errorResponse.map((message: any, key: number) => (
              <ul className="mL-5" key={ `error_group_${key}` }>
                <li key={ `error_title_${key}` }>
                  { message.title }
                  <ul>
                    { message.errors.map((error: string, index: number) => (
                      <li className="ant-text-danger" key={ `error_message_${key}_${index}` }>{ error }</li>
                    )) }
                  </ul>
                </li>
              </ul>
            )) }
          </div>
        ) }
        <Form
          className="pL-100 pR-100"
          name="upload"
          layout="vertical"
          autoComplete="off"
          onFinish={ (fields: any) => {
            const folder = fields.folder;
            const opexCoa = fields.opex_coa;
            const supplier = fields.supplier;

            if (folder) {
              this.handleUpload(folder, opexCoa, supplier);
            }
          } }
        >
          <Form.Item
            name="opex_coa"
            label="Opex Coa"
          >
            { this.renderCoaSelect() }
          </Form.Item>
          <Form.Item name="supplier" label={ 'Supplier' }>
            <Select
              showSearch
              mode="multiple"
              placeholder={ 'All' }
              maxTagCount={ 3 }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              onChange={(_supplier: any) => {
                this.setState({
                  supplier: _supplier,
                });
              }}
              value={ !!supplier ? supplier : undefined }
              loading={ suppliersAreLoading || false }
            >
              {suppliers && suppliers.map((_supplier: any) => (
                <Select.Option key={`add-supplier-${_supplier.id}`} value={_supplier.id}>
                  {_supplier.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="folder"
            label="Folder"
          >
            <TreeSelect
              style={{ width: '100%' }}
              treeDefaultExpandAll
              treeData={ mappedFolders }
              onChange={ (folder: any) => this.setState({ folder_id: folder }) }
              placeholder="Please select"
            />
          </Form.Item>
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={ (e: any) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            } }
            rules={[
              {
                required: true,
                message: 'Required'
              },
            ]}
          >
            <Upload.Dragger
              multiple
              fileList={ fileList }
              beforeUpload={ () => false }
              onChange={ ({ fileList }) => this.setState({ fileList }) }
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Support for a single or bulk upload.</p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item className="ta-r">
            { _.isEmpty(fileList) || !folder_id ? (
              <Tooltip
                placement="left"
                title={ 'Please select Folder and File(s) to upload.' }
              >
                <Button type="primary" loading={ isUploading } disabled>
                  Upload
                </Button>
              </Tooltip>
            ) : (
              <Button type="primary" loading={ isUploading } disabled={ isUploading } htmlType="submit">
                Upload
              </Button>
            ) }
          </Form.Item>
        </Form>
      </CoverModal>
    );
  };
};

export default ScopeItemUploadDocumentsModal;
