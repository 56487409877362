// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Types as TextTypes } from 'components/form/field/text';
import FieldWrapper from 'components/form/field/field-wrapper';
import { Input } from 'antd';

// Interfaces
import {
  FormField,
  FormValues,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
} from 'components/form/form-wrapper';

interface Props {
  field: FormField;
  originalState: FormValues;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: any): void;
  setFieldErrorMessage(id: string, message?: any): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

class LinkField extends Component<Props> {

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { originalState } = this.props;
    const columnKey = 'value';

    this.generateModifiedState(originalState[columnKey], state[columnKey], columnKey, shouldClear);
    this.generateErrorState(state[columnKey], columnKey, shouldClear);
  };

  generateModifiedState = (pastValue: string | number, newValue: string | number, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { setFieldErrorMessage, field, config, validate } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const errors = validate(field, columnKey, value);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  render = () => {
    const { field, config, border, state, fieldErrorMessages, fieldModifiedMessages, onChange, isDisabled } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_value`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const value: string | null = state.value ? `${state.value}` : '';

    if (!!isDisabled) {
      return (
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          border={ border }
          versionChanged={ !!field.config.version_changed }
          description={ !!field.description && field.description }
        >
          <span className="d-b pY-5">{ value }</span>
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ errors }
        required={ field.config.required }
        border={ border }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <Input
          key={ JSON.stringify(value) } // Force rerender if states been manipulated (needed because we're using defaultValue)
          className={ classNames(`Field`, {
            'border-warning Field--has-warning': isModified && _.isEmpty(errors),
          }) }
          onBlur={ (e: React.ChangeEvent<HTMLInputElement>) => onChange(field, e.target.value, config, 'value') }
          placeholder={ field.label }
          required={ !!field.config.required }
          defaultValue={ value }
          type={ TextTypes.Email }
          disabled={ isDisabled }
        />
      </FieldWrapper>
    );
  };
};

export default LinkField;
