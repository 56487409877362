// Libs
import React, { BaseSyntheticEvent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import CommentComponent from 'components/comment';
import { Modal, Input, Empty } from 'antd';
import { hasPermission } from 'components/restriction';
import Dropdown from 'components/dropdown';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';
import { Comment } from 'components/comment/Comment.interface';
import { UserPermissions } from 'types/permissions';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';
import ProfileCard from 'components/profile-card';

const { TextArea } = Input;
const API: Api = new Api();

interface Props {
  type: string;
  entity: string;
  client_id: number;
  user_id: number;
  permissions?: UserPermissions;
  record: RecordFormEntity;
  pure?: boolean;
};

interface State {
  record: RecordFormEntity | null;
  comments: Comment[];
  tmpValue: string;
  activeComment: Comment | null;
  profileId: number | null;
  isLoading: boolean;
  showProfileModal: boolean;
  showCreateDialog: boolean;
  showReplyDialog: boolean;
  showEditDialog: boolean;
  showDeleteDialog: boolean;
};

class CommentView extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    record: null,
    comments: [],
    tmpValue: '',
    activeComment: null,
    profileId: null,
    isLoading: false,
    showProfileModal: false,
    showCreateDialog: false,
    showReplyDialog: false,
    showEditDialog: false,
    showDeleteDialog: false,
  };

  componentDidMount = async () => {
    const { type, entity, client_id, record } = this.props;

    this.mounted = true;

    if (!client_id) return;

    try {

      if (!record) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const comments = await API.get(`client/${client_id}/${entity}/${_.kebabCase(type)}/${record.id}/comment`);

      this.mounted && this.setState({
        comments: comments,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderCreateDialog = () => {
    const { record, client_id, entity, type } = this.props;
    const { isLoading, tmpValue } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Create Comment' }
        maskClosable={ !isLoading }
        closable={ !isLoading }
        okText={ 'Create' }
        okButtonProps={{
          disabled: isLoading || !tmpValue,
          loading: isLoading,
        }}
        cancelButtonProps={{
          disabled: isLoading,
        }}
        onOk={ () => {
          this.setState({
            isLoading: true,
          }, async () => {
            try {

              if (!record) throw new Error('Failed');

              const comments = await API.post(`client/${client_id}/${entity}/${type}/${record.id}/comment`, {
                data: JSON.stringify({
                  message: tmpValue
                })
              });

              this.mounted && this.setState({
                comments: comments,
              }, () => {
                Notification('success', `Your comment has been created`, 'Comment Created');
              });

            } catch (error) {
              Notification('error', `Failed to create comment`);
              console.error('Error: ', error);
            } finally {
              this.mounted && this.setState({
                isLoading: false,
                showCreateDialog: false,
                activeComment: null,
                tmpValue: '',
              });
            }
          });
        } }
        onCancel={ () => this.setState({ showCreateDialog: false, tmpValue: '' }) }
      >
        <TextArea
          autoSize={{ minRows: 4 }}
          autoFocus
          onFocus={ event => event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length) }
          onChange={ (event: BaseSyntheticEvent) => this.setState({ tmpValue: event.target.value }) }
          value={ tmpValue }
        />
      </Modal>
    );
  };

  renderReplyDialog = (comment: Comment) => {
    const { record, client_id, entity, type } = this.props;
    const { isLoading, tmpValue } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Reply' }
        maskClosable={ !isLoading }
        closable={ !isLoading }
        okText={ 'Reply' }
        okButtonProps={{
          disabled: isLoading || !tmpValue,
          loading: isLoading,
        }}
        cancelButtonProps={{
          disabled: isLoading,
        }}
        onOk={ () => {
          this.setState({
            isLoading: true,
          }, async () => {

            try {

              if (!record) throw new Error('Failed');

              const comments = await API.post(`client/${client_id}/${entity}/${type}/${record.id}/comment`, JSON.stringify({
                data: JSON.stringify({
                  parent_id: comment.id,
                  message: tmpValue
                })
              }));

              this.mounted && this.setState({
                comments: comments,
              }, () => {
                Notification('success', `Sucessfully committed reply`);
              });

            } catch (error) {
              Notification('error', `Failed to commit reply`);
              console.error('Error: ', error);
            } finally {
              this.mounted && this.setState({
                isLoading: false,
                showReplyDialog: false,
                activeComment: null,
                tmpValue: '',
              });
            }
          });
        } }
        onCancel={ () => this.setState({ showReplyDialog: false, tmpValue: '' }) }
      >
        <TextArea
          autoSize={{ minRows: 4 }}
          autoFocus
          onFocus={ event => event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length) }
          onChange={ (event: BaseSyntheticEvent) => this.setState({ tmpValue: event.target.value }) }
        />
      </Modal>
    );
  };

  renderEditDialog = (comment: Comment) => {
    const { record, client_id, entity, type } = this.props;
    const { isLoading, tmpValue } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Edit comment' }
        maskClosable={ !isLoading }
        closable={ !isLoading }
        okText={ 'Edit' }
        okButtonProps={{
          disabled: isLoading || !tmpValue,
          loading: isLoading,
        }}
        cancelButtonProps={{
          disabled: isLoading,
        }}
        onOk={ () => {
          this.setState({
            isLoading: true,
          }, async () => {
            try {

              const comments = await API.put(`client/${client_id}/${entity}/${type}/${record.id}/comment/${comment.id}`, { data: JSON.stringify({
                message: tmpValue
              }) });

              this.mounted && this.setState({
                comments: comments,
              }, () => {
                Notification('success', `Updated comment`);
              });
            } catch (error) {
              Notification('error', `Failed to edit comment`);
              console.error('Error: ', error);
            } finally {
              this.mounted && this.setState({
                isLoading: false,
                showEditDialog: false,
                activeComment: null,
              });
            }
          });
        } }
        onCancel={ () => this.setState({ showEditDialog: false, tmpValue: '' }) }
      >
        <TextArea
          autoSize={{ minRows: 4 }}
          autoFocus
          onFocus={ event => event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length) }
          onChange={ (event: BaseSyntheticEvent) => this.setState({ tmpValue: event.target.value }) }
          value={ tmpValue }
        />
      </Modal>
    );
  };

  renderDeleteDialog = (comment: Comment) => {
    const { record, client_id, entity, type } = this.props;
    const { isLoading } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Remove Notification' }
        maskClosable={ !isLoading }
        closable={ !isLoading }
        okButtonProps={{
          danger: true,
          disabled: isLoading,
          loading: isLoading,
        }}
        cancelButtonProps={{
          disabled: isLoading,
        }}
        onOk={ () => this.mounted && this.setState({
          isLoading: true,
        }, async () => {

          try {

            if (!record) throw new Error('Failed');

            const comments = await API.delete(`client/${client_id}/${entity}/${type}/${record.id}/comment/${comment.id}`);

            this.mounted && this.setState({
              comments: comments,
            }, () => {
              Notification('success', `Removed comment`);
            });

          } catch (error) {
            Notification('error', `Failed to remove comment`);
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              showDeleteDialog: false,
              activeComment: null,
              isLoading: false,
            });
          }

        } )}
        onCancel={ () => this.setState({ showDeleteDialog: false, activeComment: null }) }
      >
        <p>Are you sure you want to remove this comment?</p>
      </Modal>
    );
  };

  renderProfileModal = (permissions: any, client_id: number, profile_user_id: number) => {
    return (
      <ProfileCard
        clientId={ client_id }
        userId={ profile_user_id }
        canMasquerade={ hasPermission(permissions, 'masquerade') }
        onMasquerade={ async () => {
          try {

            await API.put(`/client/${client_id}/user/masquerade/start`, {
              user_id: profile_user_id
            });
            window.location.reload();

          } catch (error) {
            Notification('error', '', 'Failed to start masquerading');
          }
        } }
        onClose={ () => this.setState({ showProfileModal: false, profileId: null }) }
      />
    );
  };

  renderList = (comments: any, _actions?: any) => {
    const { type, entity, record } = this.props;
    const actions: any = [
      {
        type: 'reply',
        node: <span key="reply">Reply</span>,
        disabled: !hasPermission(record.permissions, `${_.snakeCase(entity)}_${_.snakeCase(type)}_comment_create`),
        onClick: (comment: any) => this.setState({ showReplyDialog: true, activeComment: comment }),
      },
      {
        type: 'edit',
        node: <span key="edit">Edit</span>,
        disabled: true,
        onClick: (comment: any) => this.setState({ showEditDialog: true, activeComment: comment, tmpValue: comment.message }),
      },
      {
        type: 'remove',
        node: <span key="remove">Remove</span>,
        disabled: true,
        onClick: (comment: any) => this.setState({ showDeleteDialog: true, activeComment: comment }),
      },
    ];

    return (
      <>
        { _actions &&
          <div className="ta-r pB-15">
            <Dropdown actions={ _actions } />
          </div>
        }
        <div>
          { _.isEmpty(comments) ? (
            <div className="d-f jc-c ai-c mH-500"><Empty description={ '' } /></div>
          ) : (
            <CommentComponent
              currentUserId={ this.props.user_id }
              comments={ comments }
              actions={ actions }
              onProfileClick={ (profileId: number) => this.setState({ profileId: profileId, showProfileModal: true }) }
            />
          ) }
        </div>
      </>
    );
  };

  render = () => {
    const { client_id, permissions, record, entity, pure } = this.props;
    const {
      comments,
      showProfileModal,
      showCreateDialog,
      showReplyDialog,
      showEditDialog,
      showDeleteDialog,
      activeComment,
      profileId,
      isLoading,
    } = this.state;

    const type = _.snakeCase(this.props.type);
    const actions = [
      {
        node: 'Create Comment',
        onClick: () => this.setState({ showCreateDialog: true }),
        disabled: !hasPermission(record.permissions, `${entity}_${type}_comment_create`) && ["You don't have permissions to create comments"]
      }
    ];

    return (
      <BlockingSpinner isLoading={ isLoading }>
        { pure ? (
          <div>
            { this.renderList(comments || [], actions) }
          </div>
        ) : (
          <Jumbotron
            content={ 'Comments' }
            title={ this.props.record?.title }
            tabs={ [
              {
                label: '',
                node: this.renderList(comments || []),
              },
            ] }
            rightActions={ [
              {
                node: (
                  <Dropdown actions={ actions } />
                )
              }
            ] }
          />
        ) }

        { showProfileModal && !!client_id && !!profileId && this.renderProfileModal(permissions, client_id, profileId) }
        { showCreateDialog && this.renderCreateDialog() }
        { showReplyDialog && activeComment && this.renderReplyDialog(activeComment) }
        { showEditDialog && activeComment && this.renderEditDialog(activeComment) }
        { showDeleteDialog && activeComment && this.renderDeleteDialog(activeComment) }
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    client_id: store.ClientState.client_id,
    user_id: store.UserState.user.id,
  };
};

export default connect(mapStateToProps, {})(CommentView);
