// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import Comment from 'components/form/field/dynamic/common/Comment';
import Attachments from 'components/form/field/dynamic/common/Attachments';
import Actions from 'components/form/field/dynamic/common/Actions';
import Scoring from 'components/form/field/dynamic/common/Scoring';

// Interfaces
import { DynamicField } from 'components/form/field/dynamic/Dynamic.interface';

interface Props {
  clientId: number;
  dynamicField: DynamicField;
  isLocked: boolean;
  isPreviewing: boolean;
  showComment: boolean;
  showAttachments: boolean;
  showActions: boolean;
  showScoring: boolean;
  extensions: string[];
  onComment(comment: string | null): void;
  onUpload(fieldRecord: any, callback?: () => void): void;
  onRemove(field: DynamicField): void;
  onCreateAction(field: DynamicField): void;
  onScore(field: DynamicField): void;
  hideScoring: () => void;
};

export class ActionWrapper extends Component<Props> {
  render = () => {
    const {
      clientId,
      dynamicField,
      showComment,
      showAttachments,
      showActions,
      showScoring,
      isLocked,
      isPreviewing,
      extensions,
      onComment,
      onUpload,
      onRemove,
      onCreateAction,
      onScore
    } = this.props;
    return (
      <div
        className={ classNames({
          'mT-10 p-10 bd': showComment || showAttachments || showActions || showScoring,
        }) }
      >
        { showScoring &&
          <div className="bd p-10 mB-10">
            <Scoring
              dynamicField={ dynamicField }
              isLocked={ isLocked }
              isPreviewing={ isPreviewing }
              onScore={ onScore }
            />
          </div>
        }
        { showComment &&
          <div className="bd p-10 mB-10">
            <Comment
              comment={ _.has(dynamicField, 'attachments.comment') && !!dynamicField.attachments.comment ? dynamicField.attachments.comment : undefined }
              isLocked={ isLocked }
              isPreviewing={ isPreviewing }
              onChange={ (comment: string | null) => onComment(comment) }
            />
          </div>
        }
        { showAttachments &&
          <div className="bd p-10 mB-10">
            <Attachments
              clientId={ clientId }
              dynamicField={ dynamicField }
              isLocked={ isLocked }
              isPreviewing={ isPreviewing }
              extensions={ extensions }
              onUpload={ onUpload }
              onRemove={ onRemove }
            />
          </div>
        }
        { showActions &&
          <div className="bd p-10 mB-10">
            <Actions
              clientId={ clientId }
              dynamicField={ dynamicField }
              isLocked={ isLocked }
              isPreviewing={ isPreviewing }
              onCreate={ onCreateAction }
            />
          </div>
        }
      </div>
    );
  };
};

export default ActionWrapper;