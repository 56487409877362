// Libs
import React, { CSSProperties } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Table, TableProps } from 'antd';
import DraggableBodyRow from './DraggableBodyRow';

// Interfaces
import { ColumnType } from 'antd/lib/table/interface';
import { Config } from './DragSortingList.interfaces';

// Styles
import './DragSortingList.scss';

export type moveRowFunc = (
  dragIndex: number,
  hoverIndex: number,
  sourceTableConfig: Config,
  targetTableConfig: Config,
) => void;

export type moveRowInEmptyTableFunc = (dragIndex: number, sourceTableConfig: Config, targetTableConfig: Config) => void;

interface TableComponents {
  body: {
    row: (props: any) => JSX.Element;
  };
};

interface ITableProps
  extends Pick<
    TableProps<any>,
    | 'expandable'
    | 'pagination'
    | 'sticky'
    | 'bordered'
    | 'scroll'
    | 'loading'
    | 'size'
  > {};

interface Props extends ITableProps {
  columns: ColumnType<any>[];
  items: Array<any>;
  config: Config;
  isParent?: boolean;
  moveRow: moveRowFunc;
  moveRowInEmptyTable?: moveRowInEmptyTableFunc;
  className?: string;
  rowClassName?: string;
  rowKey?: string;
  styles?: CSSProperties;
  showHeader?: boolean;
  emptyText?: React.ReactElement;
  selectedRow?: number | string | null;
  sort?: boolean;
  scrollToBottom?: boolean;
  errorRows?: string[];
};

interface State {
  /*
    tableComponents property needed to save the draggable row object,
    property helps prevent the row component from being unmounted during the re-rendering of the main component.
  */
  tableComponents: TableComponents | null;
};

class DragSortingList extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    tableComponents: null,
  };

  componentDidMount = () => {
    this.mounted = true;

    this.mounted && this.setState({
      tableComponents: this.generateTableComponents(),
    });
  };

  componentDidUpdate = (prevProps: Props) => {
    if (!_.isEqual(prevProps.config, this.props.config)) {
      this.mounted && this.setState({
        tableComponents: this.generateTableComponents(),
      });
    }

    // Scroll to bottom when a new item was added
    if (this.props.scrollToBottom && (!_.isEqual(prevProps.items, this.props.items) && this.props.items.length > prevProps.items.length)) {
      this.scrollToBottom();
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  scrollToBottom = () => {
    const table = document.querySelector('.DragSortingTable div.ant-table-body') as HTMLElement;
    if (table) {
      table.scrollTop = table.scrollHeight;
    }
  };

  generateTableComponents = (): TableComponents => {
    const components = {
      body: {
        row: (props: any) => (
          <DraggableBodyRow { ...props } moveRow={ this.props.moveRow } moveRowInEmptyTable={ this.props.moveRowInEmptyTable } config={ this.props.config } style={{ cursor: 'pointer' }} />
        ),
      },
    };

    return components;
  };

  renderList = () => {
    const {
      columns,
      items,
      expandable,
      pagination,
      config,
      isParent,
      scroll,
      loading,
      sticky,
      bordered,
      size = 'small',
      rowKey = 'id',
      className = null,
      styles = {},
      showHeader = true,
      emptyText = <>Empty</>,
      selectedRow = null,
      sort = true,
      rowClassName = '',
      errorRows = [],
    } = this.props;

    const { tableComponents } = this.state;

    return (
      <Table
        showHeader={ showHeader }
        style={{ ...styles }}
        className={ classNames(className, {
          'DragSortingTable': true,
          'DragSortingList': !!isParent,
        }) }
        columns={ columns }
        dataSource={ items }
        components={ !!sort && !_.isEmpty(items) ? tableComponents || undefined : undefined }
        pagination={ pagination }
        onRow={ (__, index): any => ({ index }) }
        rowClassName={ (item: any) => {
          let classNames = rowClassName || '';

          if (selectedRow && selectedRow === item.key) {
            classNames = `${classNames} ant-table-row-selected`;
          }

          if (!_.isEmpty(errorRows) && errorRows.includes(item?.key)) {
            classNames = `${classNames} error`;
          }

          return classNames;
        } }
        expandable={ expandable }
        sticky={ sticky }
        bordered={ bordered }
        size={ size }
        scroll={ scroll }
        loading={ loading }
        rowKey={ (record) => {
          return record?.key || `${config.type}_${record[rowKey]}`;
        } }
        locale={{
          emptyText: () => emptyText
        }}
      />
    );
  };

  render = () => {
    return this.props.isParent ? <DndProvider backend={ HTML5Backend }>{ this.renderList() }</DndProvider> : this.renderList();
  };
};

export default DragSortingList;
