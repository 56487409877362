// Libs
import React, { Component } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
  FormValues,
} from 'components/form/form-wrapper';
import FieldWrapper from 'components/form/field/field-wrapper';

// Interfaces
import { KpiPriority } from './KpiPriority.interface';

// Styles
import './KpiPriority.scss';

interface Props {
  field: FormField;
  originalState: FormValues[];
  state: FormValues[];
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

const { Option } = Select;

class KpiPriorityField extends Component<Props> {

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { originalState } = this.props;

    this.generateModifiedState(originalState, state, 'kpi_priority', shouldClear);
    this.generateErrorState(state, 'kpi_priority', shouldClear);
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { setFieldErrorMessage, field, config, validate } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;
    const errors = validate(field, columnKey, value);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  render = () => {
    const { field, config, border, fieldErrorMessages, state, fieldModifiedMessages, onChange, isDisabled } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_kpi_priority`;
    const isLocked = isDisabled;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const kpiPriorities: KpiPriority[] = field.options ? field.options : [];
    const value = !_.isEmpty(state) ? state.map((value: any) => value.kpi_priority_id) : [];
    const kpiPriorityDescription: string = kpiPriorities.find((_kpiPriority: KpiPriority) => value?.includes(_kpiPriority.id))?.description ?? '-';

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        label={ 'Kpi Priority' }
        col={ config.fieldColSpan }
        required={ field.config.required }
        border={ border }
        versionChanged={ !!field.config.version_changed }
        hasErrors={ !_.isEmpty(errors) }
      >
        <div className="KpiPriority-field" key='kpi-priority-set'>
          <FieldWrapper
            id={ `${config.tabID}|${config.groupID}|${field.id}` }
            col={ 4 }
            label={ 'Level' }
            errors={ errors }
            required={ field.config.required }
            border={ border }
            description={ !!field.description && field.description }
          >
            <div className="w-100p">
              <div className="KpiPriority-Field-Select w-100p">
                <Select
                  showSearch
                  allowClear={ !field.config.required }
                  className={classNames('Select-Field', {
                    'border-warning Select-Field--has-warning': isModified && _.isEmpty(errors),
                  })}
                  onChange={ (option: any) => {
                    if (!option) {
                      onChange(field, [], config);
                    } else {
                      onChange(field, [{
                        kpi_priority_id: option
                      }], config);
                    }
                  } }
                  placeholder={ !_.isEmpty(kpiPriorities) ? 'Please Select' : 'No Priorities Available' }
                  value={ value }
                  disabled={ isLocked }
                >
                  { kpiPriorities.map((_kpiPriority: KpiPriority) => {
                    return (
                      <Option
                        key={ `${field.id}-list-kpi-priority-option-${_kpiPriority.id}` }
                        value={ _kpiPriority.id }
                      >
                        { _kpiPriority?.title }
                      </Option>
                    );
                  }) }
                </Select>
              </div>
            </div>
          </FieldWrapper>
        </div>
        <div className="KpiPriority-field" key='kpi-priority-description'>
          <FieldWrapper
            id={ `${config.tabID}|${config.groupID}|${field.id}|description` }
            col={ 8 }
            label={ 'Description' }
            required={ false }
            border={ border }
          >
            <span className="d-b pY-5" dangerouslySetInnerHTML={ { __html: kpiPriorityDescription } }></span>
          </FieldWrapper>
        </div>
      </FieldWrapper>
    );
  };
};

export default KpiPriorityField;
