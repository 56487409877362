// Store
import { Reducer } from 'redux';

import UserActions from 'store/User/Actions.type';
import UserActionTypes from './ActionTypes.enum';
import UserState from 'store/User/State.interface';
import { UserAccessType, UserType } from 'types/entities';

const initialUserState: UserState = {
  error: null,
  hasSessionExpired: false,
  isAuthenticated: false,
  isFetching: false,
  user: {
    id: 0,
    access_type: UserAccessType.User,
    first_name: '',
    last_name: '',
    full_name: '',
    last_login: null,
    email: '',
    status: '',
    phone_object: null,
    active_client: 0,
    is_masquerading: false,
    settings: [],
    clients: [],
    permissions: {},
    type: UserType.Internal,
    created_at: '',
    updated_at: '',
    company: {
      id: 1,
      title: '',
      created_at: '',
      deleted_at: '',
      path: '/',
      updated_at: '',
      config: {
        audit: true,
        comment: true,
        form: true,
        role: true,
        root_entity: true,
        version: true,
        workflow: true,
        resource_region: true,
      },
    },
    default_dashboard_id: null,
  },
};

const UserReducer: Reducer<UserState, UserActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case UserActionTypes.GET_USER_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        user: action.payload.user,
      };
    }
    case UserActionTypes.GET_USER_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case UserActionTypes.AUTHENTICATION_START: {
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        error: null,
      };
    }
    case UserActionTypes.AUTHENTICATION_FAILURE: {
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        error: action.payload.error,
      };
    }
    case UserActionTypes.AUTHENTICATION_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        isFetching: false,
        error: null,
      };
    }
    case UserActionTypes.UPDATED_USER_SETTINGS: {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case UserActionTypes.LOGOUT_SUCCESS: {
      return {
        ...initialUserState,
        hasSessionExpired: action.payload.hasSessionExpired,
      };
    }
    case UserActionTypes.SESSION_EXPIRATION: {
      return {
        ...state,
        hasSessionExpired: true,
      };
    }
    default:
      return state;
  }
};

export default UserReducer;
