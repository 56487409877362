// Libs
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// Components
import { Result, Button } from 'antd';

export interface Props extends RouteComponentProps {};

class NotFound extends React.Component<Props> {

  mounted: boolean = false;

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render = () => {
    return (
      <div className="d-f jc-c ai-c h-100p">
        <Result
          status="warning"
          title="Page is unavailable"
          extra={
            <Button type="primary" onClick={ () => this.props.history.goBack() }>
              Go Back
            </Button>
          }
        />
      </div>
    );
  };

};

export default withRouter(NotFound);