// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import PropertyAuditContainer from 'containers/ehs/audit/PropertyAuditContainer';
import SupplierAuditContainer from 'containers/ehs/audit/SupplierAuditContainer';
import ComplianceAuditContainer from 'containers/ehs/audit/ComplianceAuditContainer';
import CustomerAuditContainer from 'containers/ehs/audit/CustomerAuditContainer';

// Views
import Audits from 'views/ehs/Audits';
import NotFound from 'views/NotFound';

class AuditContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Route exact path="/ehs/audits" component={ Audits } />

        <Redirect exact from="/ehs/audits/property-audit" to="/ehs/audits" />
        <Redirect exact from="/ehs/audits/supplier-audit" to="/ehs/audits" />
        <Redirect exact from="/ehs/audits/compliance-audit" to="/ehs/audits" />
        <Redirect exact from="/ehs/audits/customer-audit" to="/ehs/audits" />

        <Route path="/ehs/audits/property-audit/:record_id" component={ PropertyAuditContainer } />

        <Route path="/ehs/audits/supplier-audit/:record_id" component={ SupplierAuditContainer } />

        <Route path="/ehs/audits/compliance-audit/:record_id" component={ ComplianceAuditContainer } />

        <Route path="/ehs/audits/customer-audit/:record_id" component={ CustomerAuditContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

export default AuditContainer;
