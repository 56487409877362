import { Moment } from 'moment';

import { APIFieldErrorMessage, FormErrorResponse } from 'components/form/form-wrapper';
import { RecordFormEntity } from 'types/entities';

export function instanceOfFormErrorResponse(
  response: FormErrorResponse | RecordFormEntity
): response is FormErrorResponse {
  return 'errors' in response;
};

export function instanceOfFormAPIError(object: any): object is APIFieldErrorMessage {
  return "errors" in object;
};

export function instanceOfRecordFormEntity(
  data: RecordFormEntity | null
): data is RecordFormEntity {
  if (data !== null) {
    return 'form' in data;
  }

  return false;
};

export function instanceOfMoment(object: any): object is Moment {
  if (object !== null) {
    return  'isUTC' in object;
  }

  return false;
}
