// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import BreakOptionContainer from 'containers/portfolio/transaction/BreakOptionContainer';
import LeaseAquisitionContainer from 'containers/portfolio/transaction/LeaseAquisitionContainer';
import LeaseDisposalContainer from 'containers/portfolio/transaction/LeaseDisposalContainer';
import LeaseRenewalContainer from 'containers/portfolio/transaction/LeaseRenewalContainer';
import OwnedAquisitionContainer from 'containers/portfolio/transaction/OwnedAquisitionContainer';
import OwnedDisposalContainer from 'containers/portfolio/transaction/OwnedDisposalContainer';
import RentReviewContainer from 'containers/portfolio/transaction/RentReviewContainer';

// Views
import Transactions from 'views/portfolio/Transactions';
import NotFound from 'views/NotFound';

class TransactionContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Route exact path="/portfolio/transactions" component={ Transactions } />

        <Redirect exact from="/portfolio/transactions/break-option" to="/portfolio/transactions" />
        <Redirect exact from="/portfolio/transactions/lease-aquisition" to="/portfolio/transactions" />
        <Redirect exact from="/portfolio/transactions/lease-disposal" to="/portfolio/transactions" />
        <Redirect exact from="/portfolio/transactions/lease-renewal" to="/portfolio/transactions" />
        <Redirect exact from="/portfolio/transactions/owned-aquisition" to="/portfolio/transactions" />
        <Redirect exact from="/portfolio/transactions/owned-disposal" to="/portfolio/transactions" />
        <Redirect exact from="/portfolio/transactions/rent-review" to="/portfolio/transactions" />

        <Route path="/portfolio/transactions/break-option/:record_id" component={ BreakOptionContainer } />
        <Route path="/portfolio/transactions/lease-aquisition/:record_id" component={ LeaseAquisitionContainer } />
        <Route path="/portfolio/transactions/lease-disposal/:record_id" component={ LeaseDisposalContainer } />
        <Route path="/portfolio/transactions/lease-renewal/:record_id" component={ LeaseRenewalContainer } />
        <Route path="/portfolio/transactions/owned-aquisition/:record_id" component={ OwnedAquisitionContainer } />
        <Route path="/portfolio/transactions/owned-disposal/:record_id" component={ OwnedDisposalContainer } />
        <Route path="/portfolio/transactions/rent-review/:record_id" component={ RentReviewContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

export default TransactionContainer;
