import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Component
import { Slider, Tooltip } from 'antd';
import FieldWrapper from 'components/form/field/field-wrapper';
import { getIconComponent } from 'views/admin/content-manager/slider-range/SliderRangeOptions';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
} from 'components/form/form-wrapper';

// Icons
import Icon from '@ant-design/icons';

// Styles
import './Slider.scss';

interface Props {
  field: FormField;
  originalState: any;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onRefreshForm(field_id: string): void;
  setFieldModifiedMessage(id: string, message?: any): void;
  setFieldErrorMessage(id: string, message?: any): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

class SliderField extends Component<Props> {

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, [originalState], config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { originalState } = this.props;
    const columnKey = 'value';

    this.generateModifiedState(parseFloat(originalState[columnKey]), parseFloat(state[columnKey]), columnKey, shouldClear);
    this.generateErrorState(parseFloat(state[columnKey]), columnKey, shouldClear);
  };

  generateModifiedState = (pastValue: string | number, newValue: string | number, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { setFieldErrorMessage, field, config, validate } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const errors = validate(field, columnKey, value);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  render = () => {
    const { field, config, border, state, fieldErrorMessages, fieldModifiedMessages, onChange, isDisabled } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_value`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);

    const marks: any = {};
    const options: any = field.options || [];
    const value: any = state.value || 0;
    const min: any = field.min || 0;
    const max: any = field.max || 100;
    const step: any = field.step || 1;

    options.forEach((option: any, index: number) => {

      const iconComponent: any = option?.icon?.file && getIconComponent(option?.icon?.file);

      const title = iconComponent ? (
        <Icon style={{ fontSize: 25 }} component={ iconComponent } />
      ) : (
        <span>
          { _.truncate(option.title, {
            'length': 70,
            'separator': /,? +/
          }) }
        </span>
      );

      let label = title;

      if (_.has(option, 'description') && !!option.description) {
        label = (
          <Tooltip key={ index } title={ option.description } placement={ 'bottom' }>
            { title }
          </Tooltip>
        );
      }
      marks[option.value] = {
        'label': label
      };
    });

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ 12 } // We always want this to take the full width
        label={ field.label }
        required={ !!field.config.required }
        description={ !!field.description && field.description }
        border={ border }
        isModified={ isModified }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
      >
        <div style={{ minHeight: 150 }}>
          <div style={{ width: '80%', margin: '0 auto', paddingTop: 50 }}>
            <Slider
              key={ JSON.stringify(value) } // Force rerender if states been manipulated (needed because we're using defaultValue)
              className={ classNames(`Slider-Field`, {
                'is-modified': isModified && _.isEmpty(errors),
              }) }
              onAfterChange={ (_value: number) => onChange(field, _value, config, 'value') }
              disabled={ isDisabled || _.isEmpty(marks) }
              marks={ marks }
              min={ min }
              max={ max }
              step={ parseFloat(step) }
              defaultValue={ parseFloat(value) }
            />
          </div>
        </div>
      </FieldWrapper>
    );
  };
};

export default SliderField;
