// Libs
import React, { useEffect, useRef } from 'react';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { TwitterPicker, ColorResult } from '@hello-pangea/color-picker';
import { Input, Popover } from 'antd';

// Icons
import { CloseCircleOutlined } from '@ant-design/icons';

// Interfaces
import {
  FormField,
  FormValues,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
} from 'components/form/form-wrapper';

interface Props {
  field: FormField;
  config: FormFieldConfig;
  originalValues: FormValues[];
  fieldModifiedMessages: Record<string, FormFieldInfoBoxModifiedMessage>;
  setFieldModifiedMessage: (id: string, message?: FormFieldInfoBoxModifiedMessage) => void;
  onChange(field: FormField, values: FormValues[] | [], config: FormFieldConfig, column?: string): void;
};

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

const validate = (
  field: FormField,
  originalValues: FormValues[],
  config: FormFieldConfig,
  columnKey: string = 'value',
  setFieldModifiedMessage: (id: string, message?: FormFieldInfoBoxModifiedMessage) => void,
) => {
  generateModifiedState(field, config, originalValues, columnKey, setFieldModifiedMessage);
};

const generateModifiedState = (
  field: FormField,
  config: FormFieldConfig,
  originalValues: FormValues[],
  columnKey: string,
  setFieldModifiedMessage: (id: string, message?: FormFieldInfoBoxModifiedMessage) => void,
) => {

  const id = field.id;
  const cardinality = config.fieldIndex || 0;
  const key = `${id}_${cardinality}_${columnKey}`;

  if (!_.isEqual(field.values, originalValues)) {
    setFieldModifiedMessage(key, {
      id: id,
      cardinality: 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
      modified: {}
    });
  } else {
    setFieldModifiedMessage(key);
  }
};

const handleChange = (
  field: FormField,
  value: string | null,
  config: FormFieldConfig,
  onChange: (field: FormField, values: FormValues[] | [], config: FormFieldConfig, column?: string) => void,
) => {
  onChange(field, value ? [{ value: value }] : [] as FormValues[], config);
};

const Color = ({
  field,
  config,
  originalValues,
  fieldModifiedMessages,
  onChange,
  setFieldModifiedMessage,
}: Props) => {

  const id = field.id;
  const cardinality = config.fieldIndex || 0;
  const key = `${id}_${cardinality}_value`;

  const prevField: FormField | undefined = usePrevious(field);
  const value: string | undefined = !_.isEmpty(field?.values) ? `${field.values[0].value}` : undefined;
  const isModified = _.has(fieldModifiedMessages, key);

  // Field update
  useEffect(() => {
    if (prevField && !_.isEqual(field, prevField)) {
      validate(field, originalValues, config, 'value', setFieldModifiedMessage);
    }
  }, [field]);

  return (
    <FieldWrapper
      id={ `${config.tabID}|${config.groupID}|${field.id}` }
      col={ config.fieldColSpan }
      description={ field.description }
      label={ field.label }
      required={ field.config.required }
      isModified={ isModified }
      border
    >
      <Popover
        placement={ 'bottom' }
        trigger={ 'click' }
        content={
          <TwitterPicker
            styles={{ card: { boxShadow: 'none' } }}
            color={ value || undefined }
            colors={ ['#c4d8de', '#0f9aee', '#00875a', '#efb636', '#d6494a'] }
            width={ 350 }
            triangle={ 'hide' }
            onChange={ (color: ColorResult) => {
              handleChange(field, color.hex, config, onChange);
            } }
          />
        }
      >
        <Input
          addonBefore={ '#' }
          suffix={ value ? (
            <>
              <CloseCircleOutlined
                onClick={ () => {
                  handleChange(field, null, config, onChange);
                } }
              />
              <span
                style={{
                  background: value || '#ffffff',
                  height: 20,
                  width: 20,
                  position: 'relative',
                  outline: 'none',
                  float: 'left',
                  borderRadius: 4,
                }}
              />
            </>
          ) : (
            <span />
          ) }
          value={ value && value.replace('#', '')?.toUpperCase() }
        />
      </Popover>
    </FieldWrapper>
  );
};

export default Color;
