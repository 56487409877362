// Libs
import * as React from 'react';
import { Dropdown, Menu, Tooltip } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';

// Styles
import './Dropdown.scss';

export interface Action {
  type?: 'primary' | 'ghost' | 'dashed';
  node: React.ReactNode;
  group?: string;
  onClick(): void;
  disabled?: boolean | string[];
  isLoading?: boolean;
  isDangerous?: boolean;
};

interface Props {
  actions: Action[],
  styles?: React.CSSProperties;
};

const DropdownComponent: React.FC<Props> = props => {

  const { actions, styles } = props;

  const findNodeIndex = (node: React.ReactNode) => actions.findIndex((action: Action) => action.node === node);

  const renderMenu = (actions: Action[]) => {

    const content: React.ReactNode[] = [];
    const plains: Action[] = [];
    const groups: Action[] = [];

    actions.forEach((action: Action) => {
      !!action.group ? groups.push(action) : plains.push(action);
    });

    if (!_.isEmpty(groups)) {
      const group = _.uniqBy(groups, 'group');
      group.forEach((_groupAction: Action, groupIndex: number) => {
        const uniqGroup = actions.filter((action: Action) => action.group === _groupAction.group);
        content.push(
          <Menu.ItemGroup key={ `menu-item-group-${_groupAction.group}-${groupIndex}` } title={ _groupAction.group }>
            { uniqGroup.map((uniqGroupAction, index: number) => menuItem(uniqGroupAction)) }
          </Menu.ItemGroup>
        );
      });
    }

    if (!_.isEmpty(plains)) {
      content.push(plains.map((_action: Action) => menuItem(_action)));
    }

    return !_.isEmpty(content) ? content : <></>;
  };

  const menuItem = (action: Action) => {
    const index = findNodeIndex(action.node);
    if (_.isArray(action.disabled) || !!action.disabled) {
      const disabledMessage = _.isArray(action.disabled) ? action.disabled.join(' ') : !!action.disabled;
      return (
        <Menu.Item key={ index } disabled danger={ !!action.isDangerous }>
          <Tooltip key={ `tooltip-item-${index}` } title={ disabledMessage } placement={ 'left' }>
            { action.node }
          </Tooltip>
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item key={ index } danger={ !!action.isDangerous }>
          { action.node }
        </Menu.Item>
      );
    }
  };

  const menu = (
    <Menu
      onClick={ (e: any) => {
        !!actions[parseInt(e.key)] && actions[parseInt(e.key)].onClick();
      }}
    >
      { actions && renderMenu(actions.filter((_: any, i: number) => i !== 0)) }
    </Menu>
  );

  if (_.isEmpty(actions)) return <></>;

  return (
    <div
      className={ classNames('Dropdown', {
        'Dropdown--hide-default-button': actions[0].node === ''
      }) }
      style={{ ...styles }}
    >
      <Dropdown.Button
        overlay={ actions.length > 1 ? menu : <></> }
        trigger={ ['click'] }
        onClick={ actions[0].onClick && actions[0].onClick }
        disabled={ actions.some((action: Action) => !!action.isLoading ) }
        buttonsRender={ ([leftButton, rightButton]: any) => {
          leftButton = React.cloneElement(leftButton, { loading: !!actions[0].isLoading, disabled: !!actions[0].disabled, type: actions[0].type });
          return [
            typeof actions[0].disabled === 'boolean' ? (
              leftButton
            ) : (
              <Tooltip title={ actions[0].disabled }>
                { leftButton }
              </Tooltip>
            ),
            actions.length > 1 ? (
              React.cloneElement(rightButton, { loading: actions.filter((_: any, i: number) => i !== 0).some((action: Action) => !!action.isLoading ) })
            ) : (
              <></>
            )
          ];
        }}
      >
        { actions[0].node }
      </Dropdown.Button>
    </div>
  );
};

export default DropdownComponent;