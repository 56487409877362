// Libs
import * as React from 'react';

// Components
import ResourceView from 'views/common/ResourceView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  getRecord(silent?: boolean): void;
};

class ScheduledTicketResource extends React.Component<Props> {
  render = () => {
    return (
      <ResourceView
        entity={ 'help-desk-ticket' }
        type={ 'scheduled-ticket' }
        record={ this.props.record }
        getRecord={ this.props.getRecord }
      />
    );
  };
};

export default ScheduledTicketResource;