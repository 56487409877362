// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Modal, Form, Select } from 'antd';

// Interfaces
import { RecordFormEntity } from 'types/entities';

// Api
import { Api } from 'services/api';

interface Props {
  clientId: number;
  record: RecordFormEntity;
  excludeUsers: string[],
  selectedRoleId: number,
  onCreate(
    user: {
      id: number,
      company: string,
      title: string
    }
  ): void;
  onClose(): void;
};

interface State {
  resource: {
    role_id: number | null;
    company_id: number | null;
    user_id: number | null;
  },
  availableRoles: any[];
  availableCompanies: any[];
  availableUsers: any[];
  isLoadingRoles: boolean;
  isLoadingCompanies: boolean;
  isLoadingUsers: boolean;
};

const API: Api = new Api();
const { Option } = Select;

class AddResourceDialog extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    resource: {
      role_id: null,
      company_id: null,
      user_id: null,
    },
    availableRoles: [],
    availableCompanies: [],
    availableUsers: [],
    isLoadingRoles: true,
    isLoadingCompanies: false,
    isLoadingUsers: false,
  };

  componentDidMount = async () => {
    const { clientId, record, selectedRoleId } = this.props;
    const { isLoadingRoles } = this.state;

    this.mounted = true;

    if (isLoadingRoles) {
      try {

        const availableRoles = await API.get(`client/${clientId}/${_.kebabCase(record.bundle)}/${_.kebabCase(record.type)}/${record.id}/available-roles`);
        const availableCompanies = await API.get(`client/${this.props.clientId}/${_.kebabCase(this.props.record.bundle)}/${_.kebabCase(this.props.record.type)}/${this.props.record.id}/resource/search/company`, {
          role_id: selectedRoleId,
        });

        let companyId = null;
        let userId = null;
        let availableUsers = [];

        if (availableCompanies.length === 1) {
          companyId = availableCompanies[0].id;

          availableUsers = await API.get(`client/${this.props.clientId}/${_.kebabCase(this.props.record.bundle)}/${_.kebabCase(this.props.record.type)}/${this.props.record.id}/resource/search/user`, {
            role_id: selectedRoleId,
            company_id: companyId,
          });

          availableUsers = availableUsers.filter((user: any) => !this.props.excludeUsers.includes(user.id));

          if (availableUsers.length === 1) {
            userId = availableUsers[0].id;
          }
        }

        this.mounted && this.setState({
          availableRoles: availableRoles,
          availableUsers: availableUsers,
          availableCompanies: availableCompanies,
          resource: {
            role_id: selectedRoleId,
            company_id: companyId,
            user_id: userId,
          }
        });

      } catch (error) {
        console.error('Error: ', error);
      } finally {
        this.mounted && this.setState({
          isLoadingRoles: false
        });
      }
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render = () => {
    return (
      <Modal
        centered
        visible
        title={ 'Add Resource' }
        closable={ false }
        onCancel={ () => this.props.onClose() }
        okText={ 'Add' }
        onOk={ () => {
          const user = this.state.availableUsers.find((user: any) => user.id === this.state.resource.user_id);
          this.props.onCreate({
            id: user.id,
            company: user.company.title,
            title: user.full_name,
          });
        } }
        okButtonProps={{
          disabled: !this.state.resource.role_id || !this.state.resource.company_id || !this.state.resource.user_id,
        }}
      >
        <Form layout="vertical">
          <Form.Item label={ 'Role' } required>
            <Select
              showSearch
              loading={ this.state.isLoadingRoles }
              disabled
              placeholder={ 'Select Role' }
              value={ this.state.resource.role_id }
            >
              { this.state.availableRoles.map((role: any) => (
                <Select.Option key={ `add-role-${role.id}` } value={ role.id }>
                  { role.title }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item label={ 'Company' } required>
            <Select
              showSearch
              placeholder={ 'Select Company' }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              loading={ this.state.isLoadingCompanies }
              disabled={ _.isEmpty(this.state.availableCompanies) }
              onChange={ async (companyId: number) => {
                try {

                  await new Promise((resolve) => this.setState({
                    resource: {
                      ...this.state.resource,
                      company_id: companyId,
                      user_id: null,
                    }
                  }, () => resolve(null)));
                  await new Promise((resolve) => this.setState({ isLoadingCompanies: true }, () => resolve(null)));

                  let availableUsers = await API.get(`client/${this.props.clientId}/${_.kebabCase(this.props.record.bundle)}/${_.kebabCase(this.props.record.type)}/${this.props.record.id}/resource/search/user`, {
                    role_id: this.state.resource.role_id,
                    company_id: companyId,
                  });

                  availableUsers = availableUsers.filter((user: any) => !this.props.excludeUsers.includes(user.id));

                  let userId = null;

                  if (availableUsers.length === 1) {
                    userId = availableUsers[0].id;
                  }

                  this.mounted && this.setState({
                    availableUsers: availableUsers,
                    resource: {
                      ...this.state.resource,
                      company_id: companyId,
                      user_id: userId,
                    }
                  });
                } catch (error) {
                  console.error('Error: ', error);
                } finally {
                  this.mounted && this.setState({
                    isLoadingCompanies: false
                  });
                }
              } }
              value={ this.state.resource.company_id }
            >
              { this.state.availableCompanies.map((company: any) => (
                <Select.Option key={`add-company-${company.id}`} value={company.id}>
                  { company.title }
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={ 'User' } required>
            <Select
              showSearch
              placeholder={ this.state.availableUsers.length === 0 ? 'No Users Found' : 'Select User' }
              filterOption={ (input: any, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              } }
              onChange={ (userId: number) => {
                this.setState({
                  resource: {
                    ...this.state.resource,
                    user_id: userId,
                  }
                });
              } }
              value={ this.state.resource.user_id }
              loading={ this.state.isLoadingUsers }
              disabled={ _.isEmpty(this.state.availableUsers) }
            >
              { this.state.availableUsers.map((user: any) => (
                <Select.Option key={`add-user-${user.id}`} value={user.id}>
                  { `${user.full_name} - ${user.email}` }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
};
export default AddResourceDialog;
