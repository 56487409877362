// Libs
import * as React from 'react';
import _ from 'lodash';

// Views
import NoAccess from 'views/NoAccess';

export const hasPermission = (permissions: any, permission: any) => {
  return _.has(permissions, permission) && !!_.get(permissions, permission);
};

export function RestrictionHoC(WrappedComponent: any, permission: string | string[]) {
  return class extends React.Component<any> {

    checkPermission = (): boolean => {
      if (Array.isArray(permission)) {
        const checks = permission.map((key: string) => hasPermission(this.props.permissions, key));
        return !!checks.find((check: boolean) => check === true);
      }

      return hasPermission(this.props.permissions, permission);
    };

    render = () => {
      if (!this.checkPermission()) {
        return <NoAccess />;
      }

      return <WrappedComponent {...this.props} />;
    };
  };
};

export const RestrictionWrapper: React.FC<{ restricted: React.ReactNode; children: React.ReactNode; }> = ({ restricted, children }) => {

  // Print Restricted node
  if (restricted !== false) {
    // restricted is a plain boolean we render the default NoAccess component
    if (typeof restricted === 'boolean') {
      return <NoAccess />;
    }

    return <>{ restricted }</>;
  }

  return <>{ children }</>;
};