// Libs
import * as React from "react";
import { defineMessages, injectIntl, IntlShape } from "react-intl";
import { connect } from "react-redux";
import _ from 'lodash';

// Components
import BlockingSpinner from "components/blocking-spinner";
import Jumbotron from "components/jumbotron";
import { hasPermission } from 'components/restriction';
import CreateRecordView from 'views/common/CreateRecordView';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import BasicList, { Config } from "components/basic-list";

// Services
import { Api } from "services/api";

// Actions
import { setIsBlocked, setBreadcrumbs } from 'store/UI/ActionCreators';

// Interfaces
import AppState from "store/AppState.interface";
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from "types/permissions";

// Styles
import "assets/styles/_layout.scss";

const API: Api = new Api();

const messages = defineMessages({
  error: {
    id: 'general.error',
    defaultMessage: 'Error',
    description: '',
  },
  create: {
    id: "general.create",
    defaultMessage: "Create",
    description: "",
  },
  title: {
    id: "locations.column_title",
    defaultMessage: "Title",
    description: "",
  },
  created: {
    id: "locations.column_created",
    defaultMessage: "Created",
    description: "",
  },
  list_view: {
    id: "locations.list_view",
    defaultMessage: "List View",
    description: "",
  },
  map_view: {
    id: "locations.map_view",
    defaultMessage: "Map View",
    description: "",
  },
});

export const BREADCRUMBS = [
  { title: 'Home', path: '/' },
  { title: 'Portfolio', path: '/portfolio' },
  { title: 'Locations', path: '/portfolio/locations' },
];

interface Props {
  client_id: number;
  permissions: UserPermissions;
  intl: IntlShape;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setIsBlocked(value: boolean): void;
};

interface State {
  columns: any;
  items: any;
  config: Config | null;
  isFetching: boolean;
  isCreateLoading: boolean;
  showCreateModal: boolean;
}

class Locations extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    columns: [],
    items: [],
    config: null,
    isFetching: false,
    isCreateLoading: false,
    showCreateModal: false,
  };

  componentDidMount = async () => {
    const { intl: { formatMessage }, client_id } = this.props;

    this.mounted = true;
    setBreadcrumbs(BREADCRUMBS, false);

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const record = await API.getLocations(client_id);

      this.mounted && this.setState({
        columns: record.columns,
        items: record.data,
        config: record.config,
      });

    } catch (error) {
      console.error(`${formatMessage(messages.error)}:`, error);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderListView = () => {
    return <BasicList config={ this.state.config } columns={ this.state.columns } items={ this.state.items } exportTitle={ 'Locations' } />;
  };

  render = () => {
    const { permissions, intl: { formatMessage } } = this.props;
    const { showCreateModal, isFetching, isCreateLoading } = this.state;

    const actions: DropdownAction[] = [];
    const tabs = [
      {
        label: formatMessage(messages.list_view),
        node: this.renderListView(),
      }
    ];

    if (hasPermission(permissions, 'record_location_create')) {
      actions.push({
        node: 'Create Location',
        onClick: () => this.mounted && this.setState({ isCreateLoading: true, showCreateModal: true }),
        isLoading: isCreateLoading
      });
    }

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ 'Locations' }
          tabs={ tabs }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
        />
        { showCreateModal &&
          <CreateRecordView
            type={ 'location' }
            entity={ 'record' }
            onReady={ () => this.mounted && this.setState({ isCreateLoading: false }) }
            onClose={ () => this.mounted && this.setState({ showCreateModal: false }) }
          />
        }
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setIsBlocked: (value: boolean) => dispatch(setIsBlocked(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Locations));