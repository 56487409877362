// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import Map from 'components/map';
import { Modal, InputNumber } from "antd";
import { MapMouseEvent } from '@vis.gl/react-google-maps';

// Interfaces
import {
  FormField,
  FormValues,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
} from 'components/form/form-wrapper';

// Styles
import './GeoLocation.scss';

interface Props {
  field: FormField;
  originalState: FormValues;
  onChange(
    field: FormField,
    values: any,
    config: FormFieldConfig,
    column?: string
  ): FormField;
  state: FormValues;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  state: any;
  showConfirmationModal: boolean;
  coordinates: { latitude: number | undefined, longitude: number | undefined } | null;
};

class GeoLocationField extends Component<Props, State> {

  state: State = {
    state: _.cloneDeep(this.props.state),
    coordinates: null,
    showConfirmationModal: false,
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!_.isEqual(prevProps.state, this.props.state)) {
      // Set state downwards
      this.setState({
        state: this.props.state
      });
    } else if (!_.isEqual(prevState.state, this.state.state)) {
      // Roll state upwards
      this.handleChange([{
        ...this.state.state
      }]);
    }

    if (!_.isEqual(prevProps.field, this.props.field)) {
      this.validate(this.props.state);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { field, originalState } = this.props;
    const columnKeys = Object.keys(field.columns);

    columnKeys.forEach((columnKey: string) => {
      this.generateModifiedState(originalState[columnKey], state[columnKey], columnKey, shouldClear);
      this.generateErrorState(state[columnKey], columnKey, shouldClear);
    });
  };

  handleChange = _.debounce((state: any) => {
    this.props.onChange(this.props.field, state, this.props.config);
  }, 500);

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(Number.parseFloat(pastValue).toPrecision(10), Number.parseFloat(newValue).toPrecision(10)) && !shouldClear) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: field.id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldErrorMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;
    const isRequired = !!field.columns[columnKey].required;

    let errors = [];

    if (isRequired && !value) {
      errors.push('Cannot be empty');
    }

    if (!_.isEmpty(errors) && !shouldClear) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  renderMap = (latitude: string, longitude: string) => {
    return (
      <div style={{ height: 400, width: '100%' }}>
        <Map
          markers={[
            {
              latitude: latitude,
              longitude: longitude,
            }
          ]}
          onClick={ (event: MapMouseEvent) => {
            this.setState({
              showConfirmationModal: true,
              coordinates: {
                latitude: event?.detail?.latLng?.lat,
                longitude: event?.detail?.latLng?.lng,
              }
            });
          }}
        />
        { this.state.showConfirmationModal && this.state.coordinates?.latitude && this.state.coordinates?.longitude && this.renderConfirm(this.state.coordinates?.latitude, this.state.coordinates?.longitude) }
      </div>
    );
  };

  renderConfirm = (latitude: number, longitude: number) => {
    return (
      <Modal
        centered
        visible
        title={ 'Confirm Update' }
        onCancel={ () => this.setState({ showConfirmationModal: false, coordinates: null }) }
        maskClosable
        onOk={ () => {
          this.setState({
            showConfirmationModal: false,
            coordinates: null,
            state: {
              latitude: latitude,
              longitude: longitude,
            }
          });
        } }
        cancelText={ 'Dismiss' }
        okText={ 'Update' }
      >
        <p>Are you sure you want to update the position?</p>
      </Modal>
    );
  };

  renderPostions = () => {
    const { field, config, border, isDisabled, fieldModifiedMessages, fieldErrorMessages } = this.props;
    const { state } = this.state;
    const columnKeys = Object.keys(field.columns);
    return (
      <div>
        { columnKeys.map((columnKey: string) => {
          const id = field.id;
          const cardinality = config.fieldIndex || 0;
          const key = `${id}_${cardinality}_${columnKey}`;
          const value: any = state[columnKey] ? state[columnKey] : 0;
          const isModified = _.has(fieldModifiedMessages, key);
          const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];

          // Longitude -180 degrees to 180 degrees
          // Latitude -90 degrees to 90 degrees
          const min: number = columnKey === 'longitude' ? -180 : -90;
          const max: number = columnKey === 'longitude' ? 180 : 90;

          return (
            <div className="Geo-location-field m-5" key={ `form-${config.tabID}-${config.groupID}-field-${columnKey}` }>
              <label style={{ fontWeight: 600, width: 100 }}>{ field.columns[columnKey].label }</label>
              <InputNumber
                className={classNames('Field', {
                  'border-warning Field--has-warning': isModified && _.isEmpty(errors),
                })}
                onChange={ (value: string | number | undefined) => {
                  this.setState({ state: _.set(_.cloneDeep(state), columnKey, value || '0') });
                } }
                placeholder={ field.columns[columnKey].label || '' }
                required={ !!field.columns[columnKey].required }
                value={ value }
                disabled={ isDisabled }
                precision={ 8 }
                min={ min }
                max={ max }
              />
            </div>
          );
        }) }
      </div>
    );
  };

  render = () => {
    const { field, config, border } = this.props;
    const { state } = this.state;
    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        label={ field.label }
        col={ 12 }
        required={ !!field.config.required }
        border={ border }
        description={ !!field.description && field.description }
      >
        <FieldWrapper
          col={ 12 }
          border
        >
          { this.renderPostions() }
        </FieldWrapper>
        <FieldWrapper
          col={ 12 }
          border
        >
          { this.renderMap(state?.latitude, state?.longitude) }
        </FieldWrapper>
      </FieldWrapper>
    );
  };
};

export default GeoLocationField;
