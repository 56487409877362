// Libs
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import Jumbotron from "components/jumbotron";
import { RestrictionHoC } from 'components/restriction';
import { Result } from 'antd';

// Views
import Settings from 'views/admin/general-settings/Settings';
import Currencies from 'views/admin/general-settings/currencies/Currencies';
import Countries from 'views/admin/general-settings/countries/Countries';
import Regions from 'views/admin/general-settings/regions/Regions';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Utils
import history from 'utils/history';

export const TABS = ['overview', 'countries', 'regions', 'currencies'];

interface Props {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  logotype: any;
  isLoadingSettings: boolean;
  isUploadingLogotype: boolean;
  isFetchingAssetMaintenancePlans: boolean;
};

class GeneralSettings extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    logotype: null,
    isLoadingSettings: false,
    isUploadingLogotype: false,
    isFetchingAssetMaintenancePlans: false,
  };

  handleTabChange = (tab: string) => {
    history.push(_.kebabCase(tab.toLowerCase()));
  };

  renderContent = (tab: string) => {
    switch (tab.toLowerCase()) {
      case 'overview':
        return <Settings />;
      case 'currencies':
        return <Currencies />;
      case 'regions':
        return <Regions />;
      case 'countries':
        return <Countries />;
      default:
        return <>Content type not found</>;
    }
  };

  render = () => {

    if (process.env.REACT_APP_ENVIRONMENT === 'training') {
      return (
        <div className="d-f jc-c ai-c mT-20 mX-30 h-100p">
          <Result title="Disabled For Training" />
        </div>
      );
    }

    const tabs = TABS.map((tab: string) => {
      return {
        key: tab,
        label: `${_.startCase(_.toLower(tab))}`,
        node: this.renderContent(tab)
      };
    });

    return (
      <Jumbotron
        content={ 'General Settings' }
        tabs={ tabs }
        defaultActiveTab={ this.props.match.params.type }
        onChange={ (tab: string) => this.handleTabChange(tab) }
      />
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client: store.ClientState,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(GeneralSettings), 'access_admin_general_settings'));