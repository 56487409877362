// Libs
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { Select, Table, Tooltip, Button, Modal, Checkbox, Tag, Popconfirm } from 'antd';
import { hasPermission } from 'components/restriction';
import OverlaySpinner from "components/overlay-spinner";
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import LinkCompanyModal from 'components/service-specification-scope/LinkCompanyModal';
import SwitchComponent from 'components/switch';
import ScopeItemUploadDocumentsModal from 'components/bulk-operation/scope-item-upload-documents/ScopeItemUploadDocumentsModal';

// Views
import RecordView from 'views/common/RecordView';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Utils
import history from 'utils/history';

// Icons
import { EllipsisOutlined, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";

// Interfaces
import { RecordFormEntity } from 'types/entities';
import { UserPermissions } from 'types/permissions';

export interface IContract {
  id: number,
  key?: number | string,
  bundle: string,
  type: string,
  supplier_type: string,
  title: string,
  supplier: string,
  scope: any,
};

const API: Api = new Api();
const { Option } = Select;

interface Props {
  record: RecordFormEntity;
  id: number;
  clientId: number;
  scopeId: number;
  operations: any;
  permissions: UserPermissions;
  setSecondarySidebarCollapsed: (value: boolean) => void;
};

interface State {
  services: any[];
  contracts: any[];
  filters: any;
  selectedOperation: any;
  selectedRowKeys: any[];
  activeService: any | null;
  activeContracts: IContract[] | null;
  tableHeight: number;
  isLoading: boolean;
  isUpdating: boolean;
  isFetching: boolean;
  isOperating: boolean;
};

class ServiceSpecificationScopeList extends React.Component<Props, State> {

  mounted: boolean = false;
  component: any = React.createRef();

  state: State = {
    services: [],
    contracts: [],
    selectedRowKeys: [],
    activeService: null,
    filters: [],
    selectedOperation: null,
    activeContracts: null,
    tableHeight: 0,
    isLoading: false,
    isUpdating: false,
    isFetching: false,
    isOperating: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.fetchScope();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps.scopeId !== this.props.scopeId && !!this.props.scopeId) {
      this.props.setSecondarySidebarCollapsed(true);
    }

    if (this.component && !this.state.isLoading && !this.state.isFetching && (!this.state.tableHeight || !_.isEqual(prevState.filters, this.state.filters))) {
      this.heightObserver();
    }
  };

  heightObserver = () => {
    const root: number = document.getElementById('root')?.offsetHeight || 0;
    const header: number = document.getElementById('Header')?.offsetHeight || 0;
    const jumbotron: number = document.getElementById('Jumbotron')?.offsetHeight || 0;
    const tabViewBar: number = document.getElementById('TabView-bar')?.offsetHeight || 0;
    const filtersHeight: number = document.getElementById('ServiceSpecificationScopeFilter')?.offsetHeight || 0;
    const tableHeight: number = root - (header + jumbotron + tabViewBar + filtersHeight + 170);

    if (this.state.tableHeight !== tableHeight) {
      this.setState({
        tableHeight: tableHeight
      });
    }
  };

  fetchScope = async () => {
    const { clientId, id } = this.props;

    try {

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));
      const scope = await API.get(`client/${clientId}/record/service-specification/${id}/scope-matrix`);

      this.setState({
        services: scope.data,
        contracts: scope.columns,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
        isUpdating: false,
        isFetching: false,
      });
    }
  };

  onRemoveContractScope = async (service: any, contract_id: number, in_scope: boolean) => {
    const { clientId, id, scopeId } = this.props;

    try {
      await new Promise((resolve) => this.setState({ isUpdating: true }, () => resolve(null)));

      const scope = await API.put(`client/${clientId}/record/service-specification/${id}/scope-matrix/change-scope-item`, {
        scope_type: service.type,
        scope_id: service.id,
        contract_id: contract_id,
        in_scope: in_scope,
      });

      this.setState({
        services: scope.data,
        contracts: scope.columns,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isUpdating: false
      }, () => {
        if (!_.isEmpty(service.contracts) && service.contracts.some((contract: IContract) => !!contract?.scope?.id && `${contract?.scope?.id}` === `${scopeId}`)) {
          history.push(`/workplace-services/service-specifications/${id}/scope`);
        }
      });
    }
  };

  onChangeServiceScope = async (scope_type: string, scope_id: number, in_scope: boolean) => {
    const { clientId, id } = this.props;

    try {
      await new Promise((resolve) => this.setState({ isUpdating: true }, () => resolve(null)));

      const scope = await API.put(`client/${clientId}/record/service-specification/${id}/scope-matrix/change-service`, {
        scope_type: scope_type,
        scope_id: scope_id,
        in_scope: in_scope,
      });

      this.setState({
        services: scope.data,
        contracts: scope.columns,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isUpdating: false
      });
    }
  };

  filterTable = (data: any[], filters: any) => {

    const collector: any = [];

    const companyFilter = !_.isEmpty(filters) && _.has(filters, 'company') ? filters['company'] : null; // 'Supplier 1'
    const inScopeFilter = !_.isEmpty(filters) && _.has(filters, 'scope') ? filters['scope'] : null; // true,false,null
    const statusFilter = !_.isEmpty(filters) && _.has(filters, 'status') ? filters['status'] : null; // 'pending','active', null

    this.getFlatten(data)
      .filter((service: any) => {

        let passedCompanyFilter = true;
        let passedScopeFilter = true;
        let passedStatusFilter = true;

        if (companyFilter !== null) {
          passedCompanyFilter = service.contracts.some((contract: IContract) => !!contract?.scope && contract.supplier === companyFilter);
        }

        if (inScopeFilter !== null) {
          passedScopeFilter = !!service?.in_scope === inScopeFilter;
        }

        if (statusFilter !== null) {
          passedStatusFilter = service.contracts.some((contract: IContract) => contract?.scope?.status?.title && _.kebabCase(contract.scope.status.title.toLowerCase()) === statusFilter);
        }

        return passedCompanyFilter && passedScopeFilter && passedStatusFilter;
      })
      .forEach((service: any, index: number) => {
        collector.push({
          ...service,
          key: index,
          children: null,
        });
      });

    return collector;
  };

  getColumns = () => {
    const { record, scopeId } = this.props;
    const { isUpdating } = this.state;

    const canEdit = hasPermission(record, 'permissions.record_contract_scope_edit');

    return [
      {
        key: 'services',
        dataIndex: 'services',
        title: 'Services',
        width: '30%',
        ellipsis: true,
        render: (__: any, row: any) => {
          return (
            <span className={ classNames({ 'fw-600': !!row?.parent}) }>
              { row?.label || '-' }
            </span>
          );
        },
      },
      {
        key: 'in_scope',
        dataIndex: 'in_scope',
        title: 'In Scope',
        width: '10%',
        render: (__: any, row: any) => {
          return (
            <SwitchComponent
              checked={ row?.in_scope }
              onChange={ checked => this.onChangeServiceScope(row.type, row.id, checked) }
              disabled={ !row?.can_descope || !canEdit || isUpdating }
            />
          );
        },
      },
      {
        key: 'delivered_by',
        dataIndex: 'delivered_by',
        title: 'Delivered by',
        render: (__: any, row: any) => {
          if (_.has(row, 'contracts') && !_.isEmpty(row.contracts)) {
            const contracts = row.contracts.filter((contract: IContract) => !!contract.scope);
            return (
              <div className="d-f fxw-w">
                { !_.isEmpty(contracts) ? (
                  contracts.map((contract: IContract, index: number) => {

                      const inScope = contract && !!contract.scope;
                      const isCurrentScopeItem = `${scopeId}` === `${contract.scope.id}`;

                      let supplierType: 'C' | 'S' | 'L' | null = null;
                      let stage = 'Out of Scope';
                      let color = '';

                      if (inScope && _.has(contract, 'scope.status.context')) {
                        switch (contract.scope.status.context.toUpperCase()) {
                          case 'TODO':
                            color = 'tag-todo';
                            stage = contract.scope.status.title;
                          break;
                          case 'PROGRESS':
                            color = 'tag-progress';
                            stage = contract.scope.status.title;
                          break;
                          case 'RESOLVED':
                            color = 'tag-resolved';
                            stage = contract.scope.status.title;
                          break;
                          case 'PROBLEM':
                            color = 'tag-problem';
                            stage = contract.scope.status.title;
                          break;
                          case 'CLOSED':
                            color = 'tag-closed';
                            stage = contract.scope.status.title;
                          break;
                        }
                      }

                      if (!!contract?.supplier_type) {
                        switch (contract.supplier_type) {
                          case 'customer':
                            supplierType = 'C';
                          break;
                          case 'supplier':
                            supplierType = 'S';
                          break;
                          case 'landlord':
                            supplierType = 'L';
                          break;
                        }
                      }

                      return (
                        <div
                          key={ index }
                          className={ classNames('d-f mY-2', {
                            'mR-5': (index + 1) !== contracts.length,
                            'u-animationPulseBlue': isCurrentScopeItem
                          }) }
                        >
                          { supplierType &&
                            <Tooltip
                              placement="top"
                              title={ _.upperFirst(contract.supplier_type) }
                            >
                              <span
                                className={ `pL-5 pR-5 ${color} op-80p` }
                                style={{ borderTopLeftRadius: 2, borderBottomLeftRadius: 2 }}
                              >
                                { supplierType }
                              </span>
                            </Tooltip>
                          }
                          <Tooltip
                            placement="top"
                            title={ stage }
                          >
                            <Tag
                              className={ classNames(`${color} m-0 cur-p`, {
                                'bdrsL-0': !!supplierType
                              }) }
                              onClick={ () => {
                                if (!isUpdating && !!inScope) {
                                  if (isCurrentScopeItem) {
                                    history.push(`/workplace-services/service-specifications/${record.id}/scope`);
                                  } else {
                                    history.push(`/workplace-services/service-specifications/${record.id}/scope/${contract.scope.id}`);
                                  }
                                }
                              } }
                            >
                              <span className={ classNames('whs-nw ov-h tov-e w-100p', { 'fw-600 td-u': isCurrentScopeItem }) }>
                                { `${contract.supplier} - ${contract.title}` }
                              </span>
                              <span onClick={ (event: React.MouseEvent) => event.stopPropagation() }>
                                <Popconfirm
                                  title={ 'Are you sure?' }
                                  icon={ <QuestionCircleOutlined className="text-danger" /> }
                                  okButtonProps={{
                                    danger: true,
                                  }}
                                  onConfirm={ () => {
                                    this.onRemoveContractScope(row, contract.id, false);
                                  } }
                                >
                                  <CloseOutlined className="mL-5" />
                                </Popconfirm>
                              </span>
                            </Tag>
                          </Tooltip>
                        </div>
                      );
                    } )
                ) :  (
                  <span>-</span>
                ) }
              </div>
            );
          }
          return '-';
        },
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        width: 80,
        align: 'center',
        render: (__: any, row: any) => {
          return (
            <Dropdown actions={ [
              {
                node: '',
                onClick: () => {}
              },
              {
                node: 'Link Contract',
                onClick: () => this.setState({
                  activeService: row
                }),
              }
              ] }
            />
          );
        },
      },
    ];
  };

  getData = (data: any) => {
    const recordDataMapping = (data: any[]) => {
      return data.map((entity: any, index: number) => {
        const appendChildrenKeys = (children: any) => {
          return children
            .map((childEntity: any) => {
              return {
                ...childEntity,
                key: `${childEntity.type}-${childEntity.id}`,
                label: childEntity.title,
                children: !_.isEmpty(childEntity.children) ? appendChildrenKeys(childEntity.children) : null,
              };
            });
        };
        return {
          ...entity,
          parent: true,
          key: `${entity.type}-${entity.id}`,
          label: entity.title,
          children: appendChildrenKeys(entity.children),
        };
      });
    };

    return recordDataMapping(data);
  };

  getFlatten = (data: any) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({
          ..._value,
          'key': `${_value.type}-${_value.id}`,
        });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };
      return check(value);
    });

    return collector;
  };

  getSelectedServices = (flattenServices: any[], selectedRowKeys: string[]) => {
    return selectedRowKeys.map((selectedRowKey: string) => {
      return flattenServices.find((_service: any) => _service.key === selectedRowKey);
    });
  };

  renderWorkflowOperationDialog = () => {
    const { id, clientId } = this.props;
    const { services, selectedOperation, isOperating, contracts, selectedRowKeys } = this.state;
    const selectedServices = this.getSelectedServices(this.getFlatten(services), selectedRowKeys);

    let description: React.ReactNode = <></>;

    if (selectedOperation) {
      switch (_.snakeCase(_.toLower(selectedOperation.title))) {
        case 'bulk_approve':
        case 'bulk_reject':
          description = (
            <p>You're about to <b>{ selectedOperation.title }</b> the selected <b>Pending Review</b> Services.</p>
          );
        break;
        case 'bulk_submit_for_approval':
          description = (
            <p>You're about to <b>{ selectedOperation.title }</b> the selected <b>Draft</b> Services.</p>
          );
        break;
      }
    }

    return (
      <Modal
        visible
        centered
        maskClosable={ !isOperating }
        closable={ !isOperating }
        title={ selectedOperation.title }
        okText={ 'Ok' }
        onOk={ async () => {
          try {
            await new Promise((resolve) => this.setState({ isOperating: true }, () => resolve(null)));

            const scope = await API.put(`client/${clientId}/record/service-specification/${id}/scope-matrix/bulk-operation/workflow_transition`, {
              data: {
                transition_id: selectedOperation.data.id,
                contracts: contracts.map((contract: any) => contract.id),
                services: selectedServices
              }
            });

            this.setState({
              services: scope.data,
              contracts: scope.columns,
            }, () => {
              Notification('success', 'Bulk Operation Successful');
            });

          } catch (error) {
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              isOperating: false,
              selectedOperation: null,
            });
          }
        } }
        onCancel={() => this.setState({
          selectedOperation: null,
        }) }
        okButtonProps={{
          loading: isOperating,
          danger: true
        }}
        cancelButtonProps={{
          disabled: isOperating
        }}
      >
        { description }
        <p className="mT-20" >Are you sure you wish to proceed?</p>
      </Modal>
    );
  };

  renderScopeOperationDialog = () => {
    const { id, clientId } = this.props;
    const { services, selectedOperation, isOperating, selectedRowKeys } = this.state;
    const selectedServices = this.getSelectedServices(this.getFlatten(services), selectedRowKeys);

    return (
      <Modal
        visible
        centered
        maskClosable={ !isOperating }
        closable={ !isOperating }
        title={ selectedOperation.title }
        okText={ 'Ok' }
        onOk={ async () => {
          try {
            await new Promise((resolve) => this.setState({ isOperating: true }, () => resolve(null)));

            const scope = await API.put(`client/${clientId}/record/service-specification/${id}/scope-matrix/bulk-operation/scope_service`, {
              data: {
                services: selectedServices
              }
            });

            this.setState({
              services: scope.data,
              contracts: scope.columns,
            }, () => {
              Notification('success', 'Bulk Operation Successful');
            });

          } catch (error) {
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              isOperating: false,
              selectedOperation: null,
            });
          }
        } }
        onCancel={() => this.setState({
          selectedOperation: null,
        }) }
        okButtonProps={{
          loading: isOperating,
          danger: true
        }}
        cancelButtonProps={{
          disabled: isOperating
        }}
      >
        <p>The following services will be put in scope.</p>
        <ul className="mT-20 ov-s" style={{ maxHeight: 200 }}>
          { selectedServices.map((service: any, index: number) => (
            <li key={ index }>{ service?.title }</li>
          )) }
        </ul>
        <p className="mT-20" >Are you sure you wish to proceed?</p>
      </Modal>
    );
  };

  getCompaniesWithLinkedContract = (data: any[]) => {
    const collector: any[] = [];

    data.forEach((service: any) => {
      service.contracts.forEach((contract: any) => {
        if (!!contract?.scope) {
          collector.push(contract);
        }
      });
    });

    return collector;
  };

  render = (): JSX.Element => {
    const { clientId, record, operations, permissions, scopeId } = this.props;
    const {
      services,
      filters,
      isLoading,
      isUpdating,
      isFetching,
      selectedRowKeys,
      isOperating,
      selectedOperation,
      activeService,
      tableHeight,
    } = this.state;

    const canBulkOperate = hasPermission(record, 'permissions.record_service_specification_bulk_operation');

    const actions: DropdownAction[] = [
      {
        node: '',
        onClick: () => {},
      }
    ];

    operations.forEach((operation: any) => {
      actions.push({
        node: operation.title,
        onClick: () => this.setState({
          selectedOperation: operation
        }),
        isLoading: isOperating,
      });
    });

    const columns: any = this.getColumns();
    let dataSource = this.getData(services);

    if (!_.isEmpty(filters)) {
      dataSource = this.filterTable(dataSource, filters);
    }

    const flattenServices = this.getFlatten(dataSource);
    const selectedServices = this.getSelectedServices(flattenServices, selectedRowKeys);
    const availableCompanies = this.getCompaniesWithLinkedContract(flattenServices);
    const uniqCompanies = _.uniqBy(availableCompanies, 'supplier');
    const bulkOperationEntities: any[] = [];

    selectedServices.forEach((_service: any) => {
      const contracts = !!_service?.contracts && _service.contracts.filter((contract: IContract) => !!contract.scope);
      if (!_.isEmpty(contracts)) {
        contracts.forEach((contract: IContract) => {
          bulkOperationEntities.push({
            id: contract.scope.id,
            type: contract.type,
            bundle: contract.bundle
          });
        });
      }
    });

    return (
      <div id="ServiceSpecificationScopeList" className="d-f">
        <div style={ !!scopeId ? { width: '40%' } : { width: '100%' }}>
          <div ref={ node => (this.component = node) }>
            <div id="ServiceSpecificationScopeListFilter">
              { !isLoading &&
                <div className="d-f ai-c mB-15">
                  <span>
                    <Select
                      allowClear
                      style={{ minWidth: 200 }}
                      placeholder="Company"
                      optionFilterProp="children"
                      onChange={ (value: any) => {
                        let newFilters = filters;
                        if (!value) {
                          delete newFilters['company'];
                        } else {
                          newFilters = Object.assign({}, filters, { 'company': value });
                        }
                        this.setState({
                          filters: newFilters
                        });
                      } }
                      value={ _.has(filters, 'company') ? filters['company'] : undefined }
                    >
                      { uniqCompanies.map((contract: any) =>
                        <Option key={ contract.supplier } value={ contract.supplier }>{ contract.supplier }</Option>
                      ) }
                    </Select>
                  </span>
                  <span className="mL-10">
                    <Select
                      allowClear
                      style={{ minWidth: 200 }}
                      placeholder="Scope"
                      optionFilterProp="children"
                      onChange={ (value: any) => {
                        let newFilters = filters;
                        if (!value) {
                          delete newFilters['scope'];
                        } else {
                          newFilters = Object.assign({}, filters, { 'scope': value === '1' });
                        }
                        this.setState({
                          filters: newFilters
                        });
                      } }
                      value={ _.has(filters, 'scope') ? !!filters['scope'] ? '1' : '0' : undefined }
                    >
                      <Option value="1">In Scope</Option>
                      <Option value="0">Out of Scope</Option>
                    </Select>
                  </span>
                  <span className="mL-10">
                    <Select
                      allowClear
                      style={{ minWidth: 200 }}
                      placeholder="Stage"
                      optionFilterProp="children"
                      onChange={ (value: any) => {
                        let newFilters = filters;
                        if (!value) {
                          delete newFilters['status'];
                        } else {
                          newFilters = Object.assign({}, filters, { 'status': value });
                        }
                        this.setState({
                          filters: newFilters
                        });
                      } }
                      value={ _.has(filters, 'status') ? filters['status'] : undefined }
                    >
                      <Option value="draft">Draft</Option>
                      <Option value="pending-review">Pending Review</Option>
                      <Option value="approved">Approved</Option>
                    </Select>
                  </span>
                  <div className="d-if mL-10">
                    { _.isEmpty(selectedRowKeys) ? (
                      <Tooltip
                        placement={ 'top' }
                        title={ 'No selected service' }
                      >
                        <Button
                          disabled
                          style={{
                            padding: '5px 7px',
                            width: '32px',
                          }}
                          onClick={ () => {} }
                        >
                          <EllipsisOutlined className="fsz-def text-ant-default" />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Dropdown actions={ actions } />
                    ) }
                  </div>
                </div>
              }
            </div>
          </div>
          <BlockingSpinner isLoading={ isLoading || isFetching } style={{ minHeight: '50vh' }}>
            { isUpdating && <OverlaySpinner /> }
            <div className='Layout-box'>
              <Table
                size={ 'small' }
                sticky
                columns={ columns }
                dataSource={ dataSource }
                pagination={ false }
                scroll={{
                  x: columns.length * 300,
                  y: tableHeight,
                }}
                expandable={{
                  defaultExpandAllRows: true
                }}
                rowSelection={ canBulkOperate && !_.isEmpty(dataSource) ? {
                  selectedRowKeys: selectedRowKeys,
                  columnTitle: (
                    <Tooltip key={ 'select_all' } title={ 'Select All' } placement={ 'left' }>
                      <Checkbox
                        indeterminate={ !_.isEmpty(selectedRowKeys) && flattenServices.length !== selectedRowKeys.length }
                        checked={ flattenServices.length === selectedRowKeys.length }
                        onChange={ () => {
                          this.setState({
                            selectedRowKeys: flattenServices.length !== selectedRowKeys.length ? flattenServices.map((flattenService: any) => flattenService.key) : []
                          });
                        } }
                      />
                    </Tooltip>
                  ),
                  checkStrictly: true,
                  onChange: selectedRowKeys => {
                    this.setState({
                      selectedRowKeys: selectedRowKeys
                    });
                  }
                } : undefined }
                rowClassName={ (row: any) => {
                  if (!_.isEmpty(row.contracts) && row.contracts.some((contract: IContract) => !!contract?.scope?.id && `${contract?.scope?.id}` === `${scopeId}`)) {
                    return 'bg-tinted-grey';
                  }

                  if (!!row?.parent) {
                    return 'bg-tinted-cyan';
                  }

                  return '';
                } }
              />
            </div>
          </BlockingSpinner>
          { selectedOperation && selectedOperation.type === 'workflow_transition' &&
            this.renderWorkflowOperationDialog()
          }
          { selectedOperation && selectedOperation.type === 'upload_document' &&
            <ScopeItemUploadDocumentsModal
              clientId = { clientId }
              runEndpoint={ `client/${clientId}/bulk-operations/upload-scope-documents/run` }
              checkEndpoint={ `client/${clientId}/bulk-operations/upload-scope-documents/check` }
              entities={ bulkOperationEntities }
              onClose={() => this.setState({
                selectedOperation: null,
              }) }
              onSuccess={ (responseData: any) => this.setState({ services: responseData }) }
            />
          }
          { selectedOperation && selectedOperation.type === 'scope_service' &&
            this.renderScopeOperationDialog()
          }
          { ((selectedOperation && selectedOperation.type === 'link_contract') || !!activeService) &&
            <LinkCompanyModal
              record={ record }
              clientId={ clientId }
              userPermissions={ permissions }
              services={ selectedServices }
              service={ activeService }
              onClose={ (scope: any | null) => {
                if (scope) {
                  this.setState({
                    selectedOperation: null,
                    services: scope.data,
                    contracts: scope.columns,
                    activeService: null,
                  });
                } else {
                  this.setState({
                    selectedOperation: null,
                    activeService: null,
                  });
                }
              } }
            />
          }
        </div>
        { scopeId &&
          <div className='w-100p'>
            { !isLoading && !isFetching &&
              <div style={{ position: 'relative', width: '100%' }}>
                <div
                  style={{ position: 'absolute', zIndex: 10, top: 28, right: -60 }}
                  className="Modal-navigation-close"
                  onClick={ () => history.push(`/workplace-services/service-specifications/${record.id}/scope`) }
                />
              </div>
            }
            <div
              className={ classNames('w-100p ov-s', {
                'mL-10 bd': !isLoading && !isFetching,
              } ) }
              style={{ marginTop: 48, height: tableHeight + 70 }}
            >
              <RecordView
                pure
                id={ scopeId }
                entity={ 'record' }
                type={ 'contract-scope' }
                floatingControls={ false }
                onSaveHandle={ () => {
                  this.fetchScope();
                } }
                onTransitionHandle={ () => {
                  this.fetchScope();
                } }
              />
            </div>
          </div>
        }
      </div>
    );
  };
};

export default ServiceSpecificationScopeList;
