// Libs
import * as React from "react";
import { connect } from "react-redux";
import parse from 'html-react-parser';

// Components
import BlockingSpinner from "components/blocking-spinner";
import { LeftOutlined } from '@ant-design/icons';
import Badge, { BadgeType } from 'components/badge';
import { Button } from 'antd';

// Services
import { Api } from "services/api";
import { getFormatedDate } from 'services/settings';
import history from 'utils/history';
import NotificationMessage from 'services/notification';

// Interfaces
import AppState from "store/AppState.interface";
import { INotification } from 'store/Notification/State.interface';
import NotificationState  from 'store/Notification/State.interface';

// Actions
import { readNotification } from 'store/Notification/Actions';

// Styles
import 'assets/styles/_notification.scss';

const API: Api = new Api();

interface Props {
  notification_id: number;
  client_id?: number;
  user_id: number;
  notificationState: NotificationState,
  readNotification(value: number): void;
};

interface State {
  notification: INotification | null;
  isFetching: boolean;
}

class Notification extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    notification: null,
    isFetching: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.fetchNotification();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.notification_id !== this.props.notification_id) {
      this.fetchNotification();
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchNotification = async () => {
    const { notification_id, client_id, user_id, readNotification } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));
      const notification = await API.get(`client/${client_id}/user/${user_id}/notification/${notification_id}`);

      this.mounted && this.setState({
        notification: notification,
      }, () => {
        readNotification(notification.id);
      });

    } catch (error) {
      NotificationMessage('error', '', 'Failed to get notification');
      console.error(`Failed to get notification: ${notification_id}`);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  render = () => {
    const { notification, isFetching } = this.state;

    if (isFetching) return (
      <div className="d-f jc-c ai-c h-100p">
        <BlockingSpinner isLoading />
      </div>
    );

    if (!notification) return <></>;

    return (
      <>
        <div className="bdB pX-30 pT-30" style={{ minHeight: '170px' }}>
          <div>
            <div className="d-f ai-c">
              <div className="Notification-content-left-controls as-fs">
                <span className="va-m">
                  <Button icon={ <LeftOutlined /> } onClick={ () => history.push(`/notifications`) }></Button>
                </span>
              </div>
              <div>
                <p className="fsz-lg lh-1">{ notification.subject }</p>
                <p className="mT-10">
                  { notification.type && <span className="mR-10"><Badge type={ notification.type === 'PORT' ? BadgeType.Disabled : BadgeType.Default } text={ notification.type.toUpperCase() } /></span> }
                  <span className="va-m">{ getFormatedDate(notification.created_at, undefined, true) }</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Notification-content-body ovY-s h-100p pB-160">
          { parse(notification.message) }
        </div>
      </>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    notificationState: store.NotificationState,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    readNotification: (notification_id: number) => dispatch(readNotification(notification_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);