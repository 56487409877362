// Libs
import React, { Component, BaseSyntheticEvent } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import ActionWrapper from 'components/form/field/dynamic/common/ActionWrapper';
import { getActionControls } from 'components/form/field/dynamic/common/ActionControls';
import { Input, Tooltip, Checkbox } from 'antd';

// Interfaces
import { DynamicField } from 'components/form/field/dynamic/Dynamic.interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface Props {
  clientId: number;
  dynamicField: DynamicField;
  originalDynamicField: DynamicField;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  isLocked: boolean;
  isPreviewing: boolean;
  extensions: string[];
  setFieldModifiedMessage(id: string, message?: any): void;
  setFieldErrorMessage(id: string, message?: any): void;
  onChange(field: DynamicField): void;
  onComment(comment: string | null): void;
  onUpload(fieldRecord: any, callback?: () => void): void;
  onRemove(field: DynamicField): void;
  onCreateAction(field: DynamicField): void;
  onScore(field: DynamicField): void;
};

interface State {
  showComment: boolean;
  showAttachments: boolean;
  showActions: boolean;
  showScoring: boolean;
};

class TextField extends Component<Props> {

  state: State = {
    showComment: !!this.props.dynamicField?.attachments?.comment,
    showAttachments: !!this.props.dynamicField?.attachments?.files.length,
    showActions: !!this.props.dynamicField?.action_list?.data.length,
    showScoring: !!this.props.dynamicField?.scoring?.value,
  };

  componentDidMount = () => {
    if (!this.props.isLocked) {
      this.validate(this.props.dynamicField);
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    const { dynamicField } = this.props;

    if (!_.isEqual(prevProps.dynamicField, dynamicField)) {
      this.validate(dynamicField);
    }
  };

  validate = (dynamicField: DynamicField) => {
    const { originalDynamicField } = this.props;

    this.generateModifiedState(originalDynamicField, dynamicField);
  };

  generateModifiedState = (pastField: DynamicField, newField: DynamicField) => {
    const { dynamicField, setFieldModifiedMessage } = this.props;

    const id = dynamicField.id;
    const ref = dynamicField.reference;
    const key = `${id}_${ref}`;

    if (!_.isEqual(pastField, newField)) {
      const message = {
        id: id,
        cardinality: 0,
        content: {
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  getValue = (dynamicField: DynamicField) => {
    return !_.isEmpty(dynamicField.values) ? dynamicField.values[0]['value'] : null;
  };

  handleChange = (value: string) => {
    if (!value) {
      this.props.onChange(_.set(_.cloneDeep(this.props.dynamicField), ['values'], []));
    } else {
      this.props.onChange(_.set(_.cloneDeep(this.props.dynamicField), ['values'], [{ value: value }]));
    }
  };

  handleFieldChange = (dynamicField: any) => {
    this.props.onChange(_.cloneDeep(dynamicField));
  };

  render = () => {
    const {
      clientId,
      dynamicField,
      fieldErrorMessages,
      fieldModifiedMessages,
      isLocked,
      isPreviewing,
      extensions,
      onComment,
      onUpload,
      onRemove,
      onCreateAction,
      onScore
    } = this.props;
    const { showComment, showAttachments, showActions, showScoring } = this.state;

    const key = `${dynamicField.id}_${dynamicField.reference}`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const value = this.getValue(dynamicField);

    let rightActions: any = [];

    if (dynamicField.config?.can_mark_not_applicable) {
      rightActions.push({
        node: (
          <Tooltip
            placement="top"
            title={ 'Select N/A if the question is not Applicable' }
          >
            <span className="mL-10"> { 'N/A' } </span>
            <Checkbox
              className="mL-5"
              onChange={ (e: CheckboxChangeEvent) => {
                let _dynamicField = _.cloneDeep(dynamicField);
                _dynamicField.not_applicable = !!e.target.checked;
                this.handleFieldChange(_dynamicField);
              }}
              checked={ !!dynamicField?.not_applicable }
            />
        </Tooltip>
        )
      });
    }

    rightActions = rightActions.concat(getActionControls(dynamicField, this.state, (state: any) => this.setState(state), isPreviewing));

    return (
      <FieldWrapper
        description={ dynamicField.description }
        label={ dynamicField.label }
        required={ dynamicField.config.required }
        versionChanged={ !!dynamicField.config.version_changed }
        rightActions={ rightActions }
        errors={ errors }
        isModified={ isModified }
        border
      >
        <>
          <Input
            key={ JSON.stringify(value) } // Force rerender if states been manipulated (needed because we're using defaultValue)
            type={ 'text' }
            autoComplete="none"
            className={ classNames('Field', {
              'border-warning Field--has-warning': isModified && _.isEmpty(errors),
            }) }
            onBlur={ (event: BaseSyntheticEvent) => this.handleChange(event.target.value) }
            placeholder={ dynamicField.label }
            defaultValue={ value || undefined }
            disabled={ isLocked }
          />
          <ActionWrapper
            clientId={ clientId }
            dynamicField={ dynamicField }
            isLocked={ isLocked }
            isPreviewing={ isPreviewing }
            showAttachments={ showAttachments }
            showComment={ showComment }
            showActions={ showActions }
            showScoring={ showScoring }
            hideScoring={ () => this.setState({ showScoring: false }) }
            extensions={ extensions }
            onComment={ onComment }
            onUpload={ onUpload }
            onRemove={ onRemove }
            onCreateAction={ onCreateAction }
            onScore={ onScore }
          />
        </>
      </FieldWrapper>
    );
  };
};

export default TextField;
