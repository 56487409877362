// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Modal, Form, Select, TreeSelect } from 'antd';
import BlockingSpinner from 'components/blocking-spinner';

// Api
import { Api } from 'services/api';

// Utils
import { findFirst } from 'utils/utils';

export const regionMap = (data: any = []) => {
  return !_.isEmpty(data) && data.map((entity: any) => {
    const appendChildrenKeys = (children: any) => {

      // Prevent nesting
      if (_.isEmpty(children)) return null;

      return children.map((childEntity: any) => {
        return {
          'key': childEntity.id,
          'id': childEntity.id,
          'value': childEntity.id,
          'title': childEntity.title,
          'children': appendChildrenKeys(childEntity.children),
        };
      });
    };

    return {
      'key': entity.id,
      'id': entity.id,
      'value': entity.id,
      'title': entity.title,
      'children': appendChildrenKeys(entity.children),
    };
  });
};

interface Props {
  clientId: number;
  resource: any;
  onCreate(
    role: string,
    region_ids: any,
    recordType: any,
    records: any,
    targets: any,
  ): void;
  onClose(): void;
};

interface State {
  role: any;
  regions: any;
  recordType: any;
  records: any;
  availableRoles: any[];
  availableRegions: any[];
  availableRecordTypes: any[];
  availableRecords: any[];
  isLoadingRoles: boolean;
  isLoadingRegions: boolean;
  isLoadingRecordTypes: boolean;
  isLoadingRecords: boolean;
  isCreating: boolean;
  isInitiatingEdit: boolean;
};

const API: Api = new Api();
const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

class ResourceSetupDialog extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    role: null,
    regions: [],
    recordType: null,
    records: [],
    availableRoles: [],
    availableRegions: [],
    availableRecordTypes: [],
    availableRecords: [],
    isLoadingRoles: true,
    isLoadingRegions: false,
    isLoadingRecordTypes: false,
    isLoadingRecords: false,
    isCreating: false,
    isInitiatingEdit: this.props.resource ? true : false,
  };

  componentDidMount = async () => {
    const { clientId, resource } = this.props;
    const { isLoadingRoles } = this.state;

    this.mounted = true;

    if (isLoadingRoles) {
      try {

        const roles = await API.get(`client/${clientId}/field/resource_setup/request_roles/roles`);

        this.mounted && this.setState({
          availableRoles: roles
        });

        resource && this.initiateEdit(resource);

      } catch (error) {
        console.error('Error: ', error);
      } finally {
        this.mounted && this.setState({
          isLoadingRoles: false
        });
      }
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { clientId, resource } = this.props;

    // Load regions
    if ((prevState.isLoadingRegions !== this.state.isLoadingRegions && this.state.isLoadingRegions && !!this.state.role)) {
      try {

        const regions = await API.get(`client/${clientId}/field/resource_setup/request_roles/regions`);

        this.mounted && this.setState({
          availableRegions: regions,
        });

      } catch (error) {
        console.error('Error: ', error);
      } finally {

        const newState: any = {
          isLoadingRegions: false
        };

        // Turn off loading spinner in edit mode
        if (this.state.isInitiatingEdit && resource && _.isEmpty(resource.targets)) {
          newState.isInitiatingEdit = false;
        }

        this.mounted && this.setState(newState);
      }
    }

    // Load record types
    if ((prevState.isLoadingRecordTypes !== this.state.isLoadingRecordTypes && this.state.isLoadingRecordTypes && !!this.state.role)) {
      try {

        const types = await API.get(`client/${clientId}/field/resource_setup/request_roles/types`, {
          role: this.state.role.id,
        });

        this.mounted && this.setState({
          availableRecordTypes: types,
        });

      } catch (error) {
        console.error('Error: ', error);
      } finally {
        this.mounted && this.setState({
          isLoadingRecordTypes: false
        });
      }
    }

    // Load records
    if ((prevState.isLoadingRecords !== this.state.isLoadingRecords && this.state.isLoadingRecords && !_.isEmpty(this.state.regions) && !!this.state.recordType)) {
      try {

        const records = await API.get(`client/${clientId}/field/resource_setup/request_roles/entities`, {
          regions: this.state.regions.map((region: any) => region.id),
          type: this.state.recordType.type,
          bundle: this.state.recordType.bundle,
        });

        this.mounted && this.setState({
          availableRecords: records,
        });

      } catch (error) {
        console.error('Error: ', error);
      } finally {
        this.mounted && this.setState({
          isLoadingRecords: false,
          isInitiatingEdit: false
        });
      }
    }
  };

  initiateEdit = (resource: any) => {
    this.setState({
      role: {
        id: resource.role_id,
        title: resource.role_title,
      },
      regions: resource.regions,
      recordType: !!resource.target_bundle ? {
        bundle: resource.target_bundle,
        type: resource.target_type,
        label: resource.target_label
      } : null,
      records: !_.isEmpty(resource.targets) ? resource.targets : [],
      isLoadingRegions: true,
      isLoadingRecordTypes: true,
      isLoadingRecords: !_.isEmpty(resource.targets),
    });
  };

  render = () => {
    const { resource, onCreate, onClose } = this.props;
    const {
      role,
      regions,
      recordType,
      records,
      availableRoles,
      availableRegions,
      availableRecordTypes,
      availableRecords,
      isLoadingRoles,
      isLoadingRegions,
      isLoadingRecordTypes,
      isLoadingRecords,
      isCreating,
      isInitiatingEdit
    } = this.state;
    return (
      <Modal
        visible
        title={ resource ? 'Edit Role' : 'Add Role' }
        centered
        maskClosable={ !isCreating }
        closable={ !isCreating }
        okButtonProps={{
          disabled: _.isEmpty(role) || _.isEmpty(regions) || isInitiatingEdit,
          loading: isCreating
        }}
        cancelButtonProps={{
          disabled: isCreating,
        }}
        onOk={ () => this.setState({
            isCreating: true,
          }, () => {
            if (!_.isEmpty(role) && !_.isEmpty(regions)) {
              const targets = records.map((record: any) => {
                const target = availableRecords.find((availableRecord: any) => availableRecord.id === record.id);
                return {
                  id: target.id,
                  title: target.title,
                };
              });
              onCreate(role, regions, recordType, records, targets);
            }
          }
        ) }
        onCancel={ () => onClose() }
      >
        <BlockingSpinner isLoading={ isInitiatingEdit } style={{ minHeight: 344 }}>
          <Form layout="vertical">
            <Form.Item label="Role" required>
              <Select
                showSearch
                disabled={ isLoadingRoles }
                loading={ isLoadingRoles }
                className={ classNames('Select-Field') }
                filterOption={(input: any, option: any) => {
                  return availableRoles.find((role: any) => role.title.toLowerCase() === option.children.toLowerCase() && role.title.toLowerCase().includes(input.toLowerCase()) );
                } }
                value={ role ? role.id : undefined }
                onChange={ (roleId: string) => {
                  const _role = availableRoles.find((role: any) => role.id === roleId);
                  this.setState({
                    role: {
                      id: _role.id,
                      title: _role.title
                    },
                    regions: [],
                    recordType: null,
                    records: [],
                    isLoadingRegions: true,
                  });
                } }
              >
                { availableRoles.map( (role: any, index: number) => (
                  <Option key={ index } value={ role.id }>{ role.title }</Option>
                )) }
              </Select>
            </Form.Item>
            <Form.Item label="Regions" required>
              <TreeSelect
                loading={ isLoadingRegions }
                disabled={ _.isEmpty(role) || isLoadingRegions }
                style={{
                  width: '100%',
                }}
                maxTagCount={ 3 }
                showCheckedStrategy={ SHOW_PARENT }
                treeCheckable
                treeData={ availableRegions ? regionMap(availableRegions) : [] }
                value={ regions.map((region: any) => region.id) || [] }
                multiple
                filterTreeNode={ (input: string, option: any) => {
                  if (option) {
                    const filteredInput = input.toLocaleLowerCase();
                    const title = option.title && option.title.toLowerCase();

                    if (title.includes(filteredInput)) {
                      return true;
                    }
                  }

                  return false;
                } }
                onChange={(regionIds: any) => {
                  this.setState({
                    regions: regionIds.map((regionId: number) => {
                      const region = findFirst({ children: availableRegions }, 'children', { id: regionId });
                      return {
                        id: region.id,
                        title: region.title,
                      };
                    }),
                    recordType: null,
                    records: [],
                    isLoadingRecordTypes: true,
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Record Type (optional)">
              <Select
                allowClear
                showSearch
                loading={ isLoadingRecordTypes }
                className={ classNames('Select-Field') }
                disabled={ _.isEmpty(role) || _.isEmpty(regions) || isLoadingRecordTypes }
                filterOption={(input: any, option: any) => {
                  return availableRecordTypes.find((recordType: any) => recordType.label.toLowerCase() === option.children.toLowerCase() && recordType.label.toLowerCase().includes(input.toLowerCase()) );
                } }
                onClear={ () => this.setState({
                  recordType: null,
                  records: []
                }) }
                value={ recordType ? `${recordType.bundle}|${recordType.type}` : undefined }
                onSelect={ (recordType: string) => {
                  const _recordType = recordType.split('|'); // lets never talk about this
                  const type = availableRecordTypes.find((availableRecordType: any) => availableRecordType.bundle === _recordType[0] && availableRecordType.type === _recordType[1]);
                  this.setState({
                    recordType: {
                      label: type.label_plural,
                      type: type.type,
                      bundle: type.bundle,
                    },
                    records: [],
                    isLoadingRecords: true,
                  });
                } }
              >
                { availableRecordTypes.map( (recordType: any, index: number) => (
                  <Option key={ index } value={ `${recordType.bundle}|${recordType.type}` }>{ recordType.label }</Option>
                )) }
              </Select>
            </Form.Item>
            <Form.Item label="Record Selection (optional)">
              <Select
                showSearch
                mode='multiple'
                loading={ isLoadingRecords }
                disabled={ _.isEmpty(role) || _.isEmpty(regions) || !recordType || isLoadingRecords || isLoadingRecordTypes }
                className={ classNames('Select-Field') }
                maxTagCount={ 3 }
                filterOption={(input: any, option: any) => {
                  return availableRecords.find((record: any) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
                } }
                value={ !_.isEmpty(records) ? records.map((record: any) => record.id) : undefined }
                onChange={ (recordIds: any) => {

                  let ids: any[] = recordIds;

                  // Select all logic
                  if (recordIds.includes('all')) {
                    ids = availableRecords.map((availableRecord: any) => availableRecord.id);
                  }

                  this.setState({
                    records: ids.map((recordId: number) => {
                      const _record = availableRecords.find((availableRecord: any) => availableRecord.id === recordId);
                      return {
                        id: _record.id,
                        type: _record.type,
                        bundle: _record.bundle,
                      };
                    })
                  });

                } }
              >
                { !_.isEmpty(availableRecords) &&
                  <Option key={ 'all' } value={ 'all' }>{ 'Select all' }</Option>
                }
                { availableRecords.map( (record: any, index: number) => (
                  <Option key={ index } value={ record.id }>{ record.title }</Option>
                )) }
              </Select>
            </Form.Item>
          </Form>
        </BlockingSpinner>
      </Modal>
    );
  };
};

export default ResourceSetupDialog;
