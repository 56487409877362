// Libs
import React from 'react';
import _ from 'lodash';
import { injectIntl, IntlShape } from 'react-intl';

// Components
import { Checkbox, Form, Input, Modal, Select, Tooltip } from 'antd';

// Icons
import { QuestionCircleOutlined } from '@ant-design/icons';

// Utils
import { orderListByKey } from 'utils/formSetup';

// Interface
import { EntityRole } from 'views/admin/entity/Entity.interfaces';

interface Props {
  intl: IntlShape;
  onCancel: () => void;
  onSubmit: (title: string, config: any) => Promise<void>;
  isEditing?: boolean;
  initalState: Partial<{ title: string; config: any }>;
  title: string;
  entityRoles: EntityRole[];
};

interface State {
  newItem: Partial<{ title: string; config: any }>;
  isAdding: boolean;
};

class AddGroupDialog extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    newItem: this.props.initalState,
    isAdding: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render = () => {
    const { isEditing, title, entityRoles, onCancel, onSubmit } = this.props;
    const { isAdding, newItem } = this.state;

    // deleted roles have been filtered out
    // display only the roles assigned to the entity
    const filteredSelectedRoles = newItem.config?.roles?.filter((roleId: number) => entityRoles.some((entityRole) => entityRole.role_id === roleId));

    return (
      <Modal
        title={ `${isEditing ? 'Edit' : 'Add'} ${title}` }
        maskClosable={ !isAdding }
        centered
        visible
        onCancel={ () => this.mounted && this.setState({ newItem: {} }, onCancel) }
        onOk={ () => this.mounted && this.setState({ isAdding: true }, async () => {
          try {
            const { title = '', config } = newItem;
            await onSubmit(title, config);
          } catch (error) {
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({ isAdding: false, newItem: {} });
            onCancel();
          }
        }) }
        okText={ isEditing ? 'Save' : 'Create' }
        okButtonProps={{
          disabled: _.isEmpty(newItem.title?.trim()) || isAdding,
          loading: isAdding,
        }}
        cancelButtonProps={{
          disabled: isAdding,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Label" required>
            <Input
              value={ newItem.title }
              placeholder={ 'Please enter a label' }
              onChange={ (e) => this.setState({ newItem: Object.assign(newItem, { title: e.target.value }) }) }
            />
          </Form.Item>
          <Form.Item
            label={
              <div>
                <label>Limit Role Visibility</label>
                <Tooltip
                  className="mL-5"
                  placement="top"
                  title="Limit visibility of this group to the selected roles. No selection means anyone can see the form group."
                >
                  <QuestionCircleOutlined className="fsz-def text-ant-default" />
                </Tooltip>
              </div>
            }
          >
            <Select
              showSearch
              allowClear
              mode={ 'multiple' }
              maxTagCount={ 'responsive' }
              dropdownMatchSelectWidth={ false }
              placeholder={ 'Please select roles' }
              style={{ width: '100%' }}
              filterOption={(input: string, option: any) => {
                return !!entityRoles?.find((record) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
              } }
              onChange={ (roleIds: number[]) => {
                this.setState({ newItem: Object.assign(newItem, { config: { ...newItem.config, roles: roleIds } }) });
              } }
              value={ filteredSelectedRoles || [] }
            >
              { orderListByKey(entityRoles || [], 'title').map((option) => (
                <Select.Option key={ option.role_id } value={ option.role_id }>
                  { option.title }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={ newItem.config?.open }
              onChange={ (e) => {
                this.setState({ newItem: Object.assign(newItem, { config: { ...newItem.config, open: e.target.checked } }) });
              } }
            >
              Open By Default
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
};

export default injectIntl(AddGroupDialog);