// Libs
import React from 'react';

// Components
import CoverModal from 'components/cover-modal';
import KpiMetricForm from 'components/kpi-library/KpiMetricForm';

// Interfaces
import { KpiMetricType, KpiPrioritySet, MetricOnCreate, KpiMetricRecordType } from 'components/kpi-library/KpiLibrary.interfaces';

interface Props {
  clientId: number | undefined;
  kpiMetricRecordTypes: KpiMetricRecordType[];
  kpiMetricTypes: KpiMetricType[];
  kpiPrioritySets: KpiPrioritySet[];
  onCreate: MetricOnCreate;
  onClose(): void;
};

class CreateMetricModal extends React.Component<Props> {

  render = (): JSX.Element => {
    const { clientId, kpiMetricRecordTypes, kpiMetricTypes, kpiPrioritySets, onCreate } = this.props;

    return (
      <CoverModal
        style={{ width: '90%', height: '90%' }}
        middleContent={ 'Create Metric' }
        onClose={ this.props.onClose }
      >
        <KpiMetricForm
          clientId={ clientId }
          kpiMetric={ null }
          kpiMetricRecordTypes={ kpiMetricRecordTypes }
          kpiMetricTypes={ kpiMetricTypes }
          kpiPrioritySets={ kpiPrioritySets }
          onCreate={ onCreate }
          canEdit
        />
      </CoverModal>
    );
  };
};

export default CreateMetricModal;
