// Libs
import { last, upperFirst, find } from 'lodash';

export const isGrandChildRoute = ((routes: any, pathname: string) => {
  return !!getGrandChildRoutes(routes, pathname).length;
});

export const getCurrentRoute = ((pathname: string) => {
  const routes = pathname.split('/').slice(1);
  return upperFirst(last(routes));
});

export const getRouteName = ((routes: any, currentRoute: string) => {
  const current = find(routes, route => {
    return route.route === currentRoute;
  });
  return current ? current.label : currentRoute;
});

// Ugly way of setting active submenu
export const getActiveSubmenu = (routes: any, pathname: string) => {
  return routes.reduce((acc: any, parent: any, index: number) => {
    if (pathname.includes(parent.route)) {
      return [index.toString()];
    }
    return acc;
  }, []);
};

// TODO: this is a quick solution, replace
export const getGrandChildRoutes = ((routes: any, pathname: string) => {
  let collector: any = [];

  const parts = pathname.split('/').filter((part: any) => {
    return part.length;
  });

  if (parts.includes('admin')) {
    collector = collector.concat([
      { label: 'General', route: '/admin/general-settings' },
      { label: 'Users', route: '/admin/users' },
      { label: 'Workflows', route: '/admin/workflows' },
      { label: 'Roles', route: '/admin/roles' },
    ]);
  }

  routes && routes.forEach((route: any) => {
    if (parts.includes(route.route)) {
      parts.splice(parts.indexOf(route.route), 1);
      if (!!parts.length && route.modules.length) {
        route.modules.forEach((module: any) => {
          if (pathname.includes(module.route)) {
            parts.splice(parts.indexOf(module.route), 1);
            if (!!parts.length && module.children.length) {
              module.children.forEach((child: any) => {
                collector.push(child);
              });
            }
          }
        });
      }
    }
  });

  return collector;
});