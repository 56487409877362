// Libs
import * as React from 'react';

// Styles
import './BlockingSpinner.scss';

interface Props {
  isLoading?: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

class BlockingSpinner extends React.Component<Props> {

  render = () => {
    const { isLoading = true, children, style } = this.props;
    return (
      <React.Fragment>
        { isLoading ?
          <div className='BlockingSpinner' style={ style }>
            <div className='BlockingSpinner-spinner' />
          </div>
          : children
        }
      </React.Fragment>
    );
  };

};

export default BlockingSpinner;