// Libs
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

// Containers
import CostCentreContainer from 'containers/finance/cost-centre/CostCentreContainer';

// Views
import CostCentres from 'views/finance/CostCentres';
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

class CostCentresContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Route exact path="/finance/cost-centres" component={ CostCentres } />
        <Route path="/finance/cost-centres/:cost_centre_id" component={ CostCentreContainer } />

        <Route render={() => <NotFound />} />

      </Switch>
    );
  };

};

export default CostCentresContainer;
