// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import Jumbotron from 'components/jumbotron';
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import DetailsTab from 'views/admin/entity/details-tab/DetailsTab';
import FieldMappingTab from 'views/admin/entity/field-mapping/FieldMappingTab';
import FormSetupTab from 'views/admin/entity/form-setup-tab/FormSetupTab';
import EntityRolesTab from 'views/admin/entity/entity-roles-tab/EntityRolesTab';
import DashboardMappingTab from 'views/admin/entity/dashboard-mapping/DashboardMappingTab';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

// Actions
import { setBreadcrumbs, setBreadcrumbsLoading } from 'store/UI/ActionCreators';

// Utils
import { orderListByKey } from 'utils/formSetup';
import { isBlank } from 'utils/utils';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { Workflow as WorkflowRecord } from 'components/workflow/Workflow.interface';
import { EntityField, Field, FormTab, Entity as IEntity, EntityRole, EntityRoleTypes, Role, Relation, FormGroup, WorkflowAssignmentCategory, WorkflowAssignment, DetailsTabErrors, DetailsTabModified } from './Entity.interfaces';
import { UserPermissions } from 'types/permissions';
import { Action as DropdownAction } from 'components/dropdown';

interface Props {
  permissions: UserPermissions;
  client_id: number;
  history: Record<string, any>;
  intl: IntlShape;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  workflows: WorkflowRecord[];
  fields: Field[];
  entityFields: EntityField[];
  formState: FormTab[];
  entityRoles: EntityRole[];
  roles: Role[];
  relations: Relation[];
  categories: WorkflowAssignmentCategory[];
  entity: IEntity | null;
  defaultEntity: IEntity | null;
  isLoading: boolean;
  isEditing: boolean;
  isSaving: boolean;
  isFetchingWorkflows: boolean;
  isFetchingFields: boolean;
  isDeletingField: boolean;
  isAddingField: boolean;
  isFetchingForm: boolean;
  isFetchingRoles: boolean;
  isFetchingCategories: boolean;
};

const API: Api = new Api();

class Entity extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    entity: null,
    defaultEntity: null,
    workflows: [],
    fields: [],
    entityFields: [],
    formState: [],
    entityRoles: [],
    roles: [],
    relations: [],
    categories: [],
    isLoading: false,
    isEditing: false,
    isSaving: false,
    isFetchingWorkflows: false,
    isFetchingFields: false,
    isDeletingField: false,
    isAddingField: false,
    isFetchingForm: false,
    isFetchingRoles: false,
    isFetchingCategories: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs, setBreadcrumbsLoading } = this.props;
    const { entity_bundle, type: entityType } = this.props.match.params;

    this.mounted = true;

    const fetchFields = () => {
      this.mounted && this.setState({ isFetchingFields: true }, async () => {
        try {
          const fields = await API.get(`client/${client_id}/admin/entity/fields`);
          const entityFields = await API.get(`client/${client_id}/admin/entity/field_map`, {
            entity_bundle: entity_bundle,
            entity_type: entityType,
          });
          this.mounted && this.setState({
            fields: fields,
            entityFields: entityFields
          });
        } catch (error) {
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({
            isFetchingFields: false
          });
        }
      });
    };

    const fetchForm = () => {
      this.mounted && this.setState({ isFetchingForm: true }, async () => {
        try {
          const form = await API.get(`client/${client_id}/admin/entity/form/${entity_bundle}/${entityType}`);
          const relations = await API.get(`client/${client_id}/admin/entity/fields/relation/${entity_bundle}/${entityType}`);
          this.setState({ formState: form, relations: relations });
        } catch (error) {
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({
            isFetchingForm: false
          });
        }
      });
    };

    const fetchRoles = () => {
      this.mounted && this.setState({ isFetchingRoles: true }, async () => {
        try {
          const entityRoles = await API.get(`client/${client_id}/admin/entity/roles/${entity_bundle}/${entityType}`);
          const roles = await API.get(`client/${client_id}/admin/roles`);
          this.setState({ entityRoles: entityRoles, roles: roles });
        } catch (error) {
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({
            isFetchingRoles: false
          });
        }
      });
    };

    const fetchCategories = () => {
      this.mounted && this.setState({ isFetchingCategories: true }, async () => {
        try {
          const categories = await API.get(`client/${client_id}/admin/entity/${entity_bundle}/${entityType}/workflow-assignment/categories`);
          this.setState({ categories: categories });
        } catch (error) {
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({
            isFetchingCategories: false
          });
        }
      });
    };

    try {
      const breadcrumbs = [
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Record Settings', path: '/admin/record-settings' },
        { title: this.generateBreadcrumbLabel(entity_bundle), path: `/admin/record-settings/${entity_bundle}` },
      ];
      setBreadcrumbs(breadcrumbs, false);
      setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));
      const entity = await API.get(`client/${client_id}/admin/entity/${entity_bundle}/${entityType}`);
      const workflows = await API.get(`client/${client_id}/admin/workflows`, { includeAll: true });
      fetchFields();
      fetchForm();
      fetchRoles();
      fetchCategories();

      this.mounted && this.setState({
        entity: entity,
        workflows: workflows,
      });

      setBreadcrumbs([...breadcrumbs, { title: entity.label, path: null }], false);

    } catch (error) {
      Notification('error', `Failed to load entity`);
      console.error('Error: ', error);
    } finally {
      setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }

  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  generateBreadcrumbLabel = (bundle: string) => {
    switch (bundle.toLowerCase()) {
      case 'record':
        return 'Records';
      case 'category':
        return 'Categories';
      case 'company':
        return 'Companies';
      case 'help_desk_ticket':
        return 'Help Desk';
      case 'transaction':
        return 'Transactions';
      case 'audit':
        return 'Audits';
      case 'action':
        return 'Actions';
      default:
        return 'Tab label not found';
    }
  };

  renderDetails = () => {
    const { client_id } = this.props;
    const {
      entity,
      defaultEntity,
      workflows,
      categories,
      isEditing,
      isSaving,
      isFetchingWorkflows,
      isFetchingCategories,
    } = this.state;

    const onChangeDefaultWorkflow = (workflowId: WorkflowRecord['id']) => {
      this.mounted && this.setState({ entity: Object.assign({}, entity, { workflow_id: workflowId }) });
    };

    const onChangeWorkflowAssignments = (workflowAssignments: WorkflowAssignment[]) => {
      this.mounted && this.setState({ entity: Object.assign({}, entity, { workflow_assignment: workflowAssignments }) });
    };

    const getModified = (): DetailsTabModified => {
      const modified: DetailsTabModified = {};

      if (!isEditing) return modified;

      if (!_.isEqual(entity?.workflow_id, defaultEntity?.workflow_id)) {
        modified.workflow_id = true;
      }

      if (!_.isEqual(entity?.label, defaultEntity?.label)) {
        modified.label = true;
      }

      if (!_.isEqual(entity?.workflow_assignment, defaultEntity?.workflow_assignment)) {
        // check if a row has been removed
        const isRemoved = _.some(defaultEntity?.workflow_assignment, (originalWorkflowAssignment) => {
          return _.every(entity?.workflow_assignment, (workflowAssignment) => workflowAssignment.id !== originalWorkflowAssignment.id);
        });

        if (isRemoved) {
          modified.workflow_assignment = Object.assign({}, modified.workflow_assignment, { removed: ['removed'] });
        }

        const schema: Array<keyof WorkflowAssignment> = ['field_id', 'category_id', 'workflow_id', 'order'];

        // check if the workflow_assignment has changed
        entity?.workflow_assignment.forEach((workflowAssignment) => {
          const oldValue = defaultEntity?.workflow_assignment.find((_oldValue: WorkflowAssignment) => _oldValue.id === workflowAssignment.id);
          // check if a new row has been added
          if (!oldValue) {
            modified.workflow_assignment = Object.assign({}, modified.workflow_assignment, { [workflowAssignment.id]: schema });
          } else if (!_.isEqual(workflowAssignment, oldValue)) {
            schema.forEach((key) => {
              if (_.has(workflowAssignment, key) && !_.isEqual(workflowAssignment[key], oldValue[key])) {
                modified.workflow_assignment = Object.assign(
                  {},
                  modified.workflow_assignment,
                  { [workflowAssignment.id]: _.concat(modified?.workflow_assignment?.[workflowAssignment.id] || [], key) }
                );
              }
            });
          }
        });
      }

      return modified;
    };

    const getErrors = (): DetailsTabErrors => {
      const errors: DetailsTabErrors = {};

      if (!entity?.workflow_id) {
        errors.workflow_id = true;
      }

      if (!entity?.label?.trim()) {
        errors.label = true;
      }

      if (!!entity?.workflow_assignment.length) {
        const keys: Array<keyof WorkflowAssignment> = ['field_id', 'category_id', 'workflow_id'];
        entity?.workflow_assignment.forEach(workflowAssignment => {
          const rowErrors =  keys.filter(key => isBlank(workflowAssignment[key]));
          if (!_.isEmpty(rowErrors)) {
            errors.workflow_assignment = Object.assign({}, errors.workflow_assignment, { [workflowAssignment.id]: rowErrors });
          }
        });
      }

      return errors;
    };

    const errors: DetailsTabErrors = getErrors();
    const modified: DetailsTabModified = getModified();
    const actions: DropdownAction[] = [];

    if (isEditing) {
      actions.push(
        {
          node: 'Save',
          type: 'primary',
          disabled: !_.isEmpty(errors) || _.isEmpty(modified),
          isLoading: isSaving,
          onClick: () => {
            this.mounted && this.setState({ isSaving: true }, async () => {
              try {
                const updatedEntity = await API.put(`client/${client_id}/admin/entity/${entity?.bundle}/${entity?.type}`, {
                  data: {
                    label: entity?.label,
                    workflow_id: entity?.workflow_id,
                    workflow_assignments: entity?.workflow_assignment.map((workflowAssignment) => ({
                      category_id: workflowAssignment.category_id,
                      order: workflowAssignment.order,
                      workflow_id: workflowAssignment.workflow_id,
                      category_type: workflowAssignment.category_type,
                      field_id: workflowAssignment.field_id,
                    })),
                  },
                });
                this.mounted && this.setState({
                  entity: updatedEntity,
                  defaultEntity: null,
                });
                Notification('success', 'Entity was updated', 'Successful');
              } catch (error) {
                Notification('error', 'Failed to update entity', 'Failed');
                console.error('Error: ', error);
              } finally {
                this.mounted && this.setState({
                  isSaving: false,
                  isEditing: false,
                });
              }
            });
          }
        },
        {
          node: 'Cancel',
          onClick: () => this.mounted && this.setState({
            isEditing: false,
            entity: defaultEntity,
            defaultEntity: null,
          })
        }
      );
    } else {
      actions.push(
        {
          node: 'Edit',
          onClick: () => this.mounted && this.setState({
            isEditing: true,
            defaultEntity: _.cloneDeep(entity),
          })
        }
      );
    }

    return (
      <DetailsTab
        entity={ entity }
        workflows={ workflows }
        categories={ categories }
        actions={ actions }
        errors={ errors }
        modified={ modified }
        disabled={ !isEditing || isSaving }
        isFetchingWorkflows={ isFetchingWorkflows }
        isFetchingCategories={ isFetchingCategories }
        onChangeDefaultWorkflow={ onChangeDefaultWorkflow }
        onChangeWorkflowAssignments={ onChangeWorkflowAssignments }
      />
    );
  };

  renderField = () => {
    const { entity_bundle, type: entityType } = this.props.match.params;
    const { client_id } = this.props;
    const { entityFields, fields, entity, formState, isFetchingFields, isAddingField, isDeletingField } = this.state;

    if (isFetchingFields) {
      return (
        <div className="d-f jc-c ai-c" style={{ height: '65vh' }}>
          <BlockingSpinner isLoading />
        </div>
      );
    }

    const formGroups = orderListByKey(formState, 'title')
      .map((tab: FormTab) => {
        return {
          label: tab.title,
          options: orderListByKey(tab.groups, 'title').map((group: FormGroup) => ({ id: group.id, label: group.title })),
        };
      })
      .filter((tab) => !!tab.options.length);

    const deleteField = async (field: EntityField) => {
      this.mounted && this.setState({ isDeletingField: true }, async () => {
        try {
          const response = await API.delete(`client/${client_id}/admin/entity/field_map`, {
            data: { entity_bundle: field.entity_bundle, entity_type: field.entity_type, field_id: field.field_id }
          });
          this.mounted && this.setState({
            entityFields: response.field_map,
            formState: response.form
          });
          Notification('success', 'Field was deleted', 'Successful');
        } catch (error) {
          Notification('error', `Failed to delete field`, 'Failed');
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({
            isDeletingField: false
          });
        }
      });
    };

    const addElement = async (fieldId: string, groupId: number): Promise<void> => {
      const cloneFormState = _.cloneDeep(formState);
      const tabIdx = cloneFormState.findIndex(tab => tab.groups.some(group => group.id === groupId));

      if (tabIdx < 0) return;

      try {
        const groups = cloneFormState[tabIdx].groups;
        const groupIdx = groups.findIndex((group) => group.id === groupId);
        const payload = {
          entity_bundle: entity_bundle,
          entity_type: entityType,
          group_id: groupId,
          type: 'field',
          config: { field: fieldId, column_span: 4 },
          dependencies: [],
          hide_on_create: false,
          order: groups[groupIdx].elements?.length || 0,
          protected: false,
        };
        const response = await API.post(`client/${client_id}/admin/entity/elements`, { data: payload });
        groups[groupIdx].elements.push(response);

        this.setState({ formState: cloneFormState });
        Notification('success', `Element was added`, 'Successful');
      } catch (error) {
        Notification('error', `Failed to add element to group`, 'Failed');
        console.error('Error: ', error);
      }
    };

    const addField = async (fieldId: string, groupId: number | undefined, config: any, cb: () => void) => {
      this.setState({ isAddingField: true }, async () => {
        try {
          const entityFields = await API.post(`client/${client_id}/admin/entity/field_map`, {
            data: {
              entity_bundle: entity?.bundle,
              entity_type: entity?.type,
              field_id: fieldId,
              config: config
            }
          });

          this.mounted && this.setState({
            entityFields: entityFields
          });
          Notification('success', 'Field was created', 'Successful');

          if (groupId) {
            await addElement(fieldId, groupId);
          }
        } catch (error) {
          Notification('error', 'Failed to create field', 'Failed');
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({ isAddingField: false }, cb);
        }
      });
    };

    const editField = async (fieldId: string, config: any, cb: () => void) => {
      this.setState({ isAddingField: true }, async () => {
        try {
          const entityFields = await API.put(`client/${client_id}/admin/entity/field_map`, {
            entity_bundle: entity?.bundle,
            entity_type: entity?.type,
            field_id: fieldId,
            config: config
          });
          this.setState({
            entityFields: entityFields
          });
          Notification('success', 'Field was updated', 'Successful');
        } catch (error) {
          Notification('error', 'Failed to update field', 'Failed');
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({ isAddingField: false }, cb);
        }
      });
    };

    return (
      <FieldMappingTab
        entity={ entity }
        entityFields={ entityFields }
        fields={ fields }
        formGroups={ formGroups }
        isAddingField={ isAddingField }
        isLoading={ isDeletingField }
        deleteField={ deleteField }
        addField={ addField }
        editField={ editField }
      />
    );
  };

  renderFormSetup = () => {
    const { client_id } = this.props;
    const { type, entity_bundle = '' } = this.props.match.params;
    const { entityFields, entity, fields, formState, relations, entityRoles, isFetchingForm } = this.state;
    const mappedFields = entityFields?.filter((entityField) => entityField.entity_type === entity?.type);

    return (
      <FormSetupTab
        clientId={ client_id }
        entityType={ type }
        entityBundle={ entity_bundle }
        mappedFields={ mappedFields }
        entityFields={ entityFields }
        fields={ fields }
        formTabs={ formState }
        relations={ relations }
        entityRoles={ entityRoles }
        isFetchingFormTabs={ isFetchingForm }
        onFormChange={ (data: FormTab[]) => this.setState({ formState: data }) }
      />
    );
  };

  renderEntityRoles = () => {
    const { client_id } = this.props;
    const { entity_bundle, type: entityType } = this.props.match.params;
    const { isFetchingRoles, entityRoles, roles } = this.state;

    const reorderRoles = async (data: Array<{ id: number, order: number }>, cb?: () => void) => {
      try {
        const updatedRoles = await API.put(`client/${client_id}/admin/entity/roles/reorder/${entity_bundle}/${entityType}`, {
          data: data,
        });
        this.mounted && this.setState({ entityRoles: updatedRoles });
        Notification('success', 'Role Moved', 'Successful');
      } catch (error) {
        Notification('error', 'Failed To Save', 'Failed');
        console.error('Error: ', error);
      } finally {
        cb?.();
      }
    };

    const addRole = async (data: { roleId: number, type: EntityRoleTypes, required: boolean }, cb?: () => void) => {
      const { roleId, type, required } = data;
      try {
        const updatedRoles = await API.post(`client/${client_id}/admin/entity/roles`, {
          data: {
            entity_bundle: entity_bundle,
            entity_type: entityType,
            role_id: roleId,
            type: type,
            required: required,
            order: entityRoles.length
          }
        });
        this.mounted && this.setState({ entityRoles: updatedRoles });
        Notification('success', 'Role was added', 'Successful');
      } catch (error) {
        Notification('error', 'Failed to add role', 'Failed');
        console.error('Error: ', error);
      } finally {
        cb?.();
      }
    };

    const editRole = async (entityRoleId: number, data: { type: EntityRoleTypes, required: boolean }, cb?: () => void) => {
      try {
        const updatedRole = await API.put(`client/${client_id}/admin/entity/roles/${entityRoleId}`, { data: data });
        const updatedRoles = entityRoles.map(role => role.id === entityRoleId ? updatedRole : role);
        this.mounted && this.setState({ entityRoles: updatedRoles });
        Notification('success', 'Role was updated', 'Successful');
      } catch (error) {
        Notification('error', 'Failed to update role', 'Failed');
        console.error('Error: ', error);
      } finally {
        cb?.();
      }
    };

    const deleteRole = async (entityRoleId: number, cb?: () => void) => {
      try {
        const updatedRoles = await API.delete(`client/${client_id}/admin/entity/roles/${entityRoleId}`, {
          data: { entity_bundle: entity_bundle, entity_type: entityType }
        });
        this.mounted && this.setState({ entityRoles: updatedRoles });
        Notification('success', 'Role was deleted', 'Successful');
      } catch (error) {
        Notification('error', 'Failed to delete role', 'Failed');
        console.error('Error: ', error);
      } finally {
        cb?.();
      }
    };

    return (
      <EntityRolesTab
        entityRoles={ entityRoles }
        roles={ roles }
        reorderRoles={ reorderRoles }
        addRole={ addRole }
        editRole={ editRole }
        deleteRole={ deleteRole }
        isFetchingRoles={ isFetchingRoles }
      />
    );
  };

  renderDashboards = (entity: IEntity) => {
    return (
      <DashboardMappingTab
        clientId={ this.props.client_id }
        entity={ entity }
      />
    );
  };

  render = () => {
    const { isLoading, entity } = this.state;

    if (isLoading || !entity) return <BlockingSpinner />;

    const tabs = [
      {
        label: 'Overview',
        node: this.renderDetails(),
      },
      {
        label: 'Fields',
        node: this.renderField(),
      },
      {
        label: 'Form Setup',
        node: this.renderFormSetup(),
      },
      {
        label: 'Roles',
        node: this.renderEntityRoles(),
      },
    ];

    if (['lease', 'owned', 'location', 'cost_centre', 'project', 'customer', 'supplier', 'contract', 'service_specification', 'space'].includes(entity?.type)) {
      tabs.push({
        label: 'Dashboards',
        node: this.renderDashboards(entity),
      });
    }

    return (
      <Jumbotron
        content={ entity?.label }
        tabs={ tabs }
      />
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    client_id: store.ClientState.client_id,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(withRouter(Entity)), 'access_admin_entity_config'));