// Libs
import React from 'react';

// Components
import CoverModal from 'components/cover-modal';
import RecordView from 'views/common/RecordView';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  scopeId: number;
  clientId: number;
  onClose(): void;
};

interface State {
  record: RecordFormEntity | null;
  isLoading: boolean;
};

const API: Api = new Api();

class ScopeModal extends React.Component<Props, State> {

  mounted: boolean = false;
  entity = 'record';
  type = 'contract-scope';

  state: State = {
    record: null,
    isLoading: false,
  };

  componentDidMount = async () => {
    const { clientId, scopeId } = this.props;
    this.mounted = true;

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const record = await API.get(`client/${clientId}/${this.entity}/${this.type}/${scopeId}`);

      this.mounted && this.setState({
        record: record
      });

    } catch (error) {
      console.error(error);
      Notification('error', '', 'Unable to load record');
    } finally {
      this.setState({
        isLoading: false
      });
    }
  };

  render = () => {
    const { record, isLoading } = this.state;

    return (
      <CoverModal
        isLoading={ isLoading }
        style={{ width: '90%', height: '90%' }}
        middleContent={ record?.display_title || record?.title }
        onClose={ this.props.onClose }
      >
        <RecordView
          pure
          id={ this.props.scopeId }
          type={ this.type }
          entity={ this.entity }
        />
      </CoverModal>
    );
  };
};

export default ScopeModal;