// Libs
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import DragSortingList from 'components/drag-sorting-list';
import { Button, Form, Input, Popconfirm, Select, Tooltip, TreeSelect } from 'antd';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';

// Icons
import { DeleteOutlined, MenuOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Utils
import { arrayMoveImmutable, orderListByKey } from 'utils/formSetup';

// Interfaces
import { Workflow } from 'components/workflow/Workflow.interface';
import { TableType } from 'components/drag-sorting-list/DragSortingList.interfaces';
import { Category, DetailsTabErrors, DetailsTabModified, Entity as IEntity, WorkflowAssignment, WorkflowAssignmentCategory } from 'views/admin/entity/Entity.interfaces';

// Styles
import './DetailsTab.scss';

const { SHOW_ALL } = TreeSelect;

const getBlankState = (order: number): any => {
  const uniqueKey: string = uuidv4();
  return {
    id: uniqueKey,
    field_id: undefined,
    category_type: undefined,
    workflow_id: undefined,
    category_id: [],
    order: order,
    isNewWorkflowAssignment: true, // The parameter is needed to recognize the new workflow assignment
  };
};

const getDragAndDropDisableConfig  = () => {
  return {
    draggable: true,
    onDragStart: (event: React.DragEvent<HTMLInputElement>) => event.preventDefault(),
  };
};

interface Props {
  entity: IEntity | null;
  workflows: Workflow[];
  categories: WorkflowAssignmentCategory[];
  actions: DropdownAction[];
  errors: DetailsTabErrors;
  modified: DetailsTabModified;
  disabled: boolean;
  isFetchingWorkflows: boolean;
  isFetchingCategories: boolean;
  onChangeDefaultWorkflow: (id: Workflow['id']) => void;
  onChangeWorkflowAssignments: (newState: WorkflowAssignment[]) => void;
};

interface State {};

interface CategoryList {
  key: number;
  value: number;
  title: string;
  children?: CategoryList[];
};

class DetailsTab extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {};

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  generateCategoriesList = (category: Category, categories: Category[]): CategoryList => {
    return {
      key: category.id,
      value: category.id,
      title: category.title,
      children: categories && orderListByKey(
        categories
          .filter((_category: Category) => category.id === _category.parent_id)
          .map((_category: Category) => this.generateCategoriesList(_category, categories)),
        'title'
      ),
    };
  };

  renderColumnTitle = (rows: string[], tooltip: string, required: boolean = false): JSX.Element => {
    return (
      <div className='d-f'>
        <div className='d-f fxd-c'>
          { rows.map((row, idx) => <span key={ `${row}_${idx}` }>{ row }</span>) }
        </div>
        { required && <span className="text-required mL-2 fsz-md va-t">*</span> }
        <Tooltip
          className="mL-5 pT-1"
          placement="top"
          title={ tooltip }
        >
          <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
        </Tooltip>
      </div>
    );
  };

  renderWorkflowAssignment = (): JSX.Element => {
    const { entity, workflows, categories, errors, modified, disabled, isFetchingWorkflows, isFetchingCategories, onChangeWorkflowAssignments } = this.props;

    const { workflow_assignment = [] } = entity || {};

    const addNewRow = () => {
      onChangeWorkflowAssignments(_.concat(_.cloneDeep(workflow_assignment), getBlankState(workflow_assignment.length)));
    };

    const onReorder = async (dragIndex: number, hoverIndex: number) => {
      if (dragIndex !== hoverIndex) {
        const rows = arrayMoveImmutable<WorkflowAssignment>(workflow_assignment, dragIndex, hoverIndex).filter((element) => !!element);
        const reorderedRows = rows.map((row, idx) => ({ ...row, order: idx }));
        onChangeWorkflowAssignments(reorderedRows);
      }
    };

    const onChangeRow = (identifier: WorkflowAssignment['id'], updatedData: Partial<WorkflowAssignment>) => {
      onChangeWorkflowAssignments(workflow_assignment.map(row => {
        if (row.id === identifier) {
          return { ...row, ...updatedData };
        }
        return row;
      }));
    };

    const deleteRow = (identifier: WorkflowAssignment['id']) => {
      onChangeWorkflowAssignments(workflow_assignment.filter(item => item.id !== identifier));
    };

    const isModified = (rowId: WorkflowAssignment['id'], fieldKey: keyof WorkflowAssignment): boolean => {
      return _.has(modified, ['workflow_assignment', rowId]) && !!modified?.workflow_assignment?.[rowId]?.includes(fieldKey);
    };

    const hasError = (rowId: WorkflowAssignment['id'], fieldKey: keyof WorkflowAssignment): boolean => {
      return _.has(errors, ['workflow_assignment', rowId]) && !!errors?.workflow_assignment?.[rowId]?.includes(fieldKey);
    };

    const columns = [
      {
        dataIndex: 'sort',
        title: this.renderColumnTitle(
          ['Sort'],
          'Allowing users to set the default sort order',
        ),
        render: () => <MenuOutlined className="DragMenu" />,
        width: 65,
        ellipsis: true,
        align: 'center' as 'center',
      },
      {
        key: 'field_id',
        dataIndex: 'field_id',
        title: this.renderColumnTitle(
          ['Category'],
          ''
        ),
        render: (fieldId: WorkflowAssignment['field_id'], row: WorkflowAssignment) => {
          const hasErrors = hasError(row.id, 'field_id');
          const _isModified = isModified(row.id, 'field_id');

          return (
            <Select
              showSearch
              allowClear
              disabled={ disabled || isFetchingCategories }
              loading={ isFetchingCategories }
              dropdownMatchSelectWidth={ false }
              style={{ width: '100%' }}
              placeholder={ '-' }
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': !!hasErrors,
                'Select-Field--has-warning border-warning': _isModified && !hasErrors,
              }) }
              filterOption={ (input: string, option: any) => {
                return !!categories.find((record) => record.field_label.toLowerCase() === option.children.toLowerCase() && record.field_label.toLowerCase().includes(input.toLowerCase()) );
              } }
              onChange={ (fieldId: WorkflowAssignment["field_id"]) => {
                onChangeRow(row.id, {
                  field_id: fieldId,
                  category_type: categories.find((c) => c.field_id === fieldId)?.category_type || fieldId,
                  category_id: [],
                });
              } }
              value={ fieldId }
            >
              { orderListByKey(categories, 'field_label').map(category => (
                <Select.Option key={ category.field_id } value={ category.field_id }>
                  { category.field_label }
                </Select.Option>
              )) }
            </Select>
          );
        },
        width: 220,
        ellipsis: true,
      },
      {
        key: 'category_id',
        dataIndex: 'category_id',
        title: this.renderColumnTitle(
          ['Option'],
          ''
        ),
        render: (optionIds: WorkflowAssignment['category_id'], row: WorkflowAssignment) => {
          const options = categories.find(category => category.field_id === row.field_id)?.categories;

          const hasErrors = hasError(row.id, 'category_id');
          const _isModified = isModified(row.id, 'category_id');

          const categoryList: CategoryList[] = options && options
            .filter((category: Category) => !category.parent_id) // Filter out ground floor first
            .map((category: Category) => this.generateCategoriesList(category, options)) || [];

          const treeData = orderListByKey(categoryList || [], 'title');

          return (
            <TreeSelect
              style={{ width: '100%' }}
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': !!hasErrors,
                'Select-Field--has-warning border-warning': _isModified && !hasErrors,
              }) }
              multiple
              showSearch
              treeCheckable
              treeDefaultExpandAll
              maxTagCount={ 'responsive' }
              maxTagTextLength={ optionIds?.length === 1 ? 22 : 12 }
              dropdownMatchSelectWidth={ false }
              loading={ isFetchingCategories }
              disabled={ disabled || _.isEmpty(options) || isFetchingCategories }
              placeholder={ '-' }
              showCheckedStrategy={ SHOW_ALL }
              treeData={ treeData }
              value={ !_.isEmpty(treeData) ? optionIds : [] }
              filterTreeNode={ (input: string, option: any) => {
                if (option) {
                  const filteredInput = input.toLocaleLowerCase();
                  const title = option.title && option.title.toLowerCase();

                  if (title.includes(filteredInput)) {
                    return true;
                  }
                }

                return false;
              } }
              onChange={ (changedValues: any[]) => {
                onChangeRow(row.id, { category_id: changedValues.map((changedValue) => !!changedValue.value ? changedValue.value : changedValue) });
              } }
            />
          );
        },
        width: 260,
        ellipsis: true,
      },
      {
        key: 'workflow_id',
        dataIndex: 'workflow_id',
        title: this.renderColumnTitle(
          ['Workflow'],
          ''
        ),
        render: (workflowId: Workflow['id'], row: WorkflowAssignment) => {
          const hasErrors = hasError(row.id, 'workflow_id');
          const _isModified = isModified(row.id, 'workflow_id');

          return (
            <Select
              showSearch
              allowClear
              disabled={ disabled || isFetchingWorkflows }
              dropdownMatchSelectWidth={ false }
              style={{ width: '100%' }}
              placeholder={ '-' }
              loading={ isFetchingWorkflows }
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': !!hasErrors,
                'Select-Field--has-warning border-warning': _isModified && !hasErrors,
              }) }
              filterOption={ (input: string, option: any) => {
                return !!workflows.find((record: Workflow) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
              } }
              onChange={ (workflow: Workflow['id']) => {
                onChangeRow(row.id, { workflow_id: workflow });
              } }
              value={ workflowId }
            >
              { orderListByKey(workflows, 'title').map((workflow: Workflow) => (
                <Select.Option key={ workflow.id } value={ workflow.id }>
                  { workflow.title }
                </Select.Option>
              )) }
            </Select>
          );
        },
        width: 200,
        ellipsis: true,
      },
      {
        key: 'actions',
        dataIndex: '',
        title: (
          <Button
            className={ 'ActionButton' }
            disabled={ disabled }
            onClick={ addNewRow }
          >
            <PlusOutlined />
          </Button>
        ),
        render: (row: WorkflowAssignment) => {
          return (
            <Popconfirm
              title={ 'Are you sure?' }
              icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
              okButtonProps={{
                danger: true,
                disabled: disabled,
              }}
              placement="topRight"
              onConfirm={ () => deleteRow(row.id) }
            >
              <DeleteOutlined
                className="link"
                style={{ fontSize: 18 }}
              />
            </Popconfirm>
          );
        },
        width: 80,
        ellipsis: true,
        fixed: 'right' as 'right',
        align: 'center' as 'center',
      },
    ];

    const scrollX = columns.reduce((acc, curr) => acc += curr.width, 0);

    const sortedAssignments = workflow_assignment.sort((a: WorkflowAssignment, b: WorkflowAssignment) => a.order - b.order);

    // disabled drag and drop if the form is disabled
    const dragAndDropDisableConfig = disabled ? getDragAndDropDisableConfig() : {};

    return (
      <div { ...dragAndDropDisableConfig }>
        <DragSortingList
          className={ 'WorkflowAssignmentTable' }
          columns={ columns }
          items={ sortedAssignments }
          isParent
          bordered
          sticky
          pagination={ false }
          scroll={{
            x: scrollX,
            y: workflow_assignment.length > 8 ? 400 : undefined,
          }}
          loading={{
            spinning: false,
            indicator: <BlockingSpinner isLoading />,
          }}
          config={{
            type: TableType.Tab,
            references: [],
          }}
          moveRow={ onReorder }
        />
      </div>
    );
  };

  renderForm = (): JSX.Element => {
    const { workflows, entity, errors, modified, disabled, isFetchingWorkflows, onChangeDefaultWorkflow } = this.props;
    const workflowAssignmentEntities = ['record', 'ticket', 'company', 'transaction', 'audit', 'action'];

    return (
      <Form layout="vertical">
        <Form.Item label={ 'Label' } required>
          <Input
            // read-only field
            disabled
            value={ entity?.label }
            className={ classNames({
              'border-danger': !!errors.label,
            }) }
          />
        </Form.Item>
        <Form.Item label={ 'Default Workflow' } required>
          <Select
            showSearch
            className={ classNames('Select-Field', {
              'Select-Field--has-error': !!errors.workflow_id,
              'Select-Field--has-warning border-warning': !!modified.workflow_id && !errors.workflow_id,
            }) }
            disabled={ disabled || isFetchingWorkflows }
            loading={ isFetchingWorkflows }
            filterOption={ (input: string, option: any) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            } }
            onSelect={ onChangeDefaultWorkflow }
            value={ entity?.workflow_id }
          >
            { orderListByKey(workflows, 'title').map((workflow: Workflow) => (
              <Select.Option key={ workflow.id } value={ workflow.id }>
                { workflow.title }
              </Select.Option>
            )) }
          </Select>
        </Form.Item>
        { workflowAssignmentEntities.includes(entity?.bundle || '') &&
          <Form.Item label={ 'Workflow Assignment' }>
            { this.renderWorkflowAssignment() }
          </Form.Item>
        }
      </Form>
    );
  };

  render = () => {
    const { actions } = this.props;

    return (
      <div className="Layout-box pB-20">
        <div className="d-f jc-fe">
          <Dropdown actions={ actions } />
        </div>
        { this.renderForm() }
      </div>
    );
  };
};

export default DetailsTab;