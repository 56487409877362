// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import BudgetRequestContainer from 'containers/project/transaction/ProjectBudgetRequestContainer';
import DelayRequestContainer from 'containers/project/transaction/ProjectDelayRequestContainer';

// Views
import Transactions from 'views/project/Transactions';
import NotFound from 'views/NotFound';

class TransactionContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Route exact path="/projects/transactions" component={ Transactions } />

        <Redirect exact from="/projects/transactions/project-budget-request" to="/projects/transactions" />

        <Route path="/projects/transactions/project-budget-request/:record_id" component={ BudgetRequestContainer } />
        <Route path="/projects/transactions/project-delay-request/:record_id" component={ DelayRequestContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

export default TransactionContainer;
