// Libs
import * as React from 'react';
import { connect } from 'react-redux';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import BasicList, { Config } from "components/basic-list";

// Services
import { Api } from 'services/api';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

interface Props {
  record: RecordFormEntity;
  client_id?: number,
};

interface State {
  columns: any[];
  items: any[];
  config: Config | null;
  isFetching: boolean;
};

class ServiceSpecifications extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    columns: [],
    items: [],
    config: null,
    isFetching: false,
  };

  componentDidMount = async () => {
    const { client_id, record } = this.props;

    this.mounted = true;

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const response = await API.get(`client/${client_id}/company/supplier/${record.id}/service-specifications`);
      const columns = response.columns;
      const items = response.data;

      this.mounted && this.setState({
        columns: columns,
        items: items,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderListView = () => {
    return <BasicList config={ this.state.config } columns={ this.state.columns } items={ this.state.items } />;
  };

  render = () => {
    const tabs = [
      {
        label: 'List View',
        node: this.renderListView(),
      }
    ];

    return (
      <BlockingSpinner isLoading={ this.state.isFetching }>
        <Jumbotron
          content={ 'Service Specifications' }
          title={ this.props.record?.title }
          tabs={ tabs }
        />
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
  };
};

export default connect(mapStateToProps, null)(ServiceSpecifications);
