// Libs
import * as React from 'react';

// Components
import DocumentView from 'views/common/DocumentView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
};

class OwnedDocuments extends React.Component<Props> {
  render = () => {
    return <DocumentView record={ this.props.record } entity={ 'record' } type={ 'owned' } />;
  };
};

export default OwnedDocuments;
