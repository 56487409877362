// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { has } from 'lodash';

// Services
import { Api } from 'services/api';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Views
import SupplierAuditRecord from 'views/ehs/supplier-audit/SupplierAuditRecord';
import SupplierAuditDocuments from 'views/ehs/supplier-audit/SupplierAuditDocuments';
import SupplierAuditAudit from 'views/ehs/supplier-audit/SupplierAuditAudit';
import SupplierAuditResources from 'views/ehs/supplier-audit/SupplierAuditResources';
import SupplierAuditWorkflow from 'views/ehs/supplier-audit/SupplierAuditWorkflow';
import SupplierAuditComments from 'views/ehs/supplier-audit/SupplierAuditComments';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { RecordFormEntity } from 'types/entities';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbs,
  setBreadcrumbsLoading,
} from 'store/UI/ActionCreators';

// Utils
import Console from 'utils/console';

interface Props {
  client_id: number;
  permissions: any;
  match: { params: Record<string, any> };
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  record: RecordFormEntity | null;
  isFetching: boolean;
};

const API: Api = new Api();

class SupplierAuditContainer extends React.Component<Props, State> {

  mounted: boolean = false;
  state: State = {
    record: null,
    isFetching: false,
  };

  componentDidMount = () => {
    this.mounted = true;

    // Get record
    this.getRecord();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.params.record_id !== this.props.match.params.record_id) {
      this.getRecord();
    }
  };

  componentWillUnmount = () => {
    this.props.setSecondarySidebarRoutes([]);
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getRecord = async (silent: boolean = false) => {
    try {

      if (!silent) {
        // Set default breadcrumbs
        this.props.setBreadcrumbs([
          { title: 'Home', path: '/' },
          { title: 'EH&S', path: '/ehs' },
          { title: 'Audits', path: '/ehs/audits' },
          { title: 'Supplier Audits', path: '/ehs/audits/supplier-audits' },
        ], false);

        // Set default routes
        this.props.setSecondarySidebarRoutes([]);
      }

      if (this.props.match.params.record_id && this.props.client_id) {

        if (!silent) {
          await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));
          this.props.setBreadcrumbsLoading(true);
        }

        const record = await API.get(`client/${this.props.client_id}/audit/supplier-audit/${this.props.match.params.record_id}`);

        this.setRecord(record, silent);
      }
    } catch (error) {
      Console.error(error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.setState({
        isFetching: false
      });
    }
  };

  setRecord = (record: RecordFormEntity, silent?: boolean) => {
    const showLoadingState = this.state.record?.title !== record.title;

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(true);
    }

    if (has(record, 'breadcrumbs')) {
      this.props.setBreadcrumbs(record.breadcrumbs, false);
    }

    if (has(record, 'permissions')) {
      const { permissions, new_content } = record;

      this.props.setSecondarySidebarRoutes([
        { title: 'Supplier Audit', path: 'record', isDisabled: !hasPermission(permissions, 'audit_supplier_audit_view') },
        { title: 'Documents', path: 'documents', isIndented: true, isDisabled: !hasPermission(permissions, 'audit_supplier_audit_document_view'), hasUpdates: !!new_content.documents },
        { title: 'Comments', path: 'comments', isIndented: true, isDisabled: !hasPermission(permissions, 'audit_supplier_audit_comment_view'), hasUpdates: !!new_content.comments },
        { title: 'Resources', path: 'resources', isIndented: true, isDisabled: !hasPermission(permissions, 'audit_supplier_audit_resource_view'), hasUpdates: !!new_content.resources },
        { title: 'Workflow', path: 'workflow', isIndented: true, isDisabled: !hasPermission(permissions, 'audit_supplier_audit_workflow_view') },
        { title: 'Audit', path: 'audit', isIndented: true, isDisabled: !hasPermission(permissions, 'audit_supplier_audit_audit_view') },
      ]);
    }

    this.setState({
      record: record,
      isFetching: false
    });

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(false);
    }
  };

  render = () => {
    const { isFetching, record } = this.state;
    const permissions = record && has(record, 'permissions') ? record.permissions : this.props.permissions;

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Switch>

          <Redirect exact from="/ehs/audits/supplier-audit/:record_id" to="/ehs/audits/supplier-audit/:record_id/record" />

          { record &&
            <Route exact path="/ehs/audits/supplier-audit/:record_id/record" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'audit_supplier_audit_view') }>
                  <SupplierAuditRecord record={ record } getRecord={ this.getRecord } setRecord={ this.setRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/ehs/audits/supplier-audit/:record_id/documents" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'audit_supplier_audit_document_view') }>
                  <SupplierAuditDocuments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/ehs/audits/supplier-audit/:record_id/audit" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'audit_supplier_audit_audit_view') }>
                  <SupplierAuditAudit record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/ehs/audits/supplier-audit/:record_id/resources" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'audit_supplier_audit_resource_view') }>
                  <SupplierAuditResources record={ record } getRecord={ this.getRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/ehs/audits/supplier-audit/:record_id/workflow" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'audit_supplier_audit_workflow_view') }>
                  <SupplierAuditWorkflow record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/ehs/audits/supplier-audit/:record_id/comments" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'audit_supplier_audit_comment_view') }>
                  <SupplierAuditComments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          <Route render={ () => <NotFound /> } />

        </Switch>
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierAuditContainer);
