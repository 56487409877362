// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import PreviewModal from 'components/preview-modal';
import ActionWrapper from 'components/form/field/dynamic/common/ActionWrapper';
import { getActionControls } from 'components/form/field/dynamic/common/ActionControls';
import Upload from 'components/upload';
import { Tooltip, Checkbox } from 'antd';

// Interfaces
import { DynamicField } from 'components/form/field/dynamic/Dynamic.interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

// Styles
import 'components/form/field/file/File.scss';

interface Props {
  clientId: number;
  dynamicField: DynamicField;
  originalDynamicField: DynamicField;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  isLocked: boolean;
  isPreviewing: boolean;
  extensions: string[];
  setFieldModifiedMessage(id: string, message?: any): void;
  setFieldErrorMessage(id: string, message?: any): void;
  onChange(field: DynamicField): void;
  onComment(comment: string | null): void;
  onUpload(fieldRecord: any, callback?: () => void): void;
  onRemove(field: DynamicField): void;
  onCreateAction(field: DynamicField): void;
  onScore(field: DynamicField): void;
};

interface State {
  preview_id: number | null;
  isDownloading: boolean;
  isModified: boolean;
  showComment: boolean;
  showAttachments: boolean;
  showActions: boolean;
  showScoring: boolean;
};

class FileField extends Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    preview_id: null,
    isDownloading: false,
    isModified: false,
    showComment: !!this.props.dynamicField?.attachments?.comment,
    showAttachments: !!this.props.dynamicField?.attachments?.files.length,
    showActions: !!this.props.dynamicField?.action_list?.data.length,
    showScoring: !!this.props.dynamicField?.scoring?.value,
  };

  componentDidMount = () => {
    this.mounted = true;
    if (!this.props.isLocked) {
      this.validate(this.props.dynamicField);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  componentDidUpdate = (prevProps: Props) => {
    const { dynamicField } = this.props;

    if (!_.isEqual(prevProps.dynamicField, dynamicField)) {
      this.validate(dynamicField);
    }
  };

  validate = (dynamicField: DynamicField) => {
    const { originalDynamicField } = this.props;

    this.generateModifiedState(originalDynamicField, dynamicField);
  };

  generateModifiedState = (pastField: DynamicField, newField: DynamicField) => {
    const { dynamicField, setFieldModifiedMessage } = this.props;

    const id = dynamicField.id;
    const ref = dynamicField.reference;
    const key = `${id}_${ref}`;

    if (!_.isEqual(pastField, newField)) {
      const message = {
        id: id,
        cardinality: 0,
        content: {
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  handleChange = (fileRecord: any) => {
    const dynamicField = _.cloneDeep(this.props.dynamicField);
    dynamicField.values = dynamicField.values.concat([fileRecord]);
    this.props.onChange(dynamicField);
  };

  handleFieldChange = (dynamicField: any) => {
    this.props.onChange(_.cloneDeep(dynamicField));
  };

  handleRemove = (file: any) => {
    const dynamicField = _.cloneDeep(this.props.dynamicField);
    dynamicField.values = dynamicField.values.filter((_file: any) => _file.file_id !== file.uid);
    this.props.onChange(dynamicField);
  };

  renderPreview = (file_id: number) => {
    const { dynamicField, clientId } = this.props;
    const files: any[] = _.has(dynamicField, 'values') ? dynamicField.values : [];
    const fileRecord: any = files.find((record: any) => record.file_id === file_id);
    return (
      <PreviewModal
        clientId={ clientId }
        fileRecord={ fileRecord }
        files={ files }
        onClose={ () => {
          this.setState({
            preview_id: null
          });
        }}
      />
    );
  };

  render = () => {
    const { dynamicField, fieldErrorMessages, fieldModifiedMessages, clientId, isLocked, isPreviewing, extensions, onComment, onUpload, onRemove, onCreateAction, onScore } = this.props;
    const { preview_id, showComment, showAttachments, showActions, showScoring } = this.state;

    const key = `${dynamicField.id}_${dynamicField.reference}`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const allowedExtensions = _.has(dynamicField, 'config.allowed_extensions') && !_.isEmpty(dynamicField.config.allowed_extensions) && dynamicField.config.allowed_extensions.map(extension => '.' + extension).join(', ');
    const cardinality = _.has(dynamicField, 'config.cardinality') ? dynamicField.config.cardinality : 0;
    const isDisabled = (cardinality === 0 ? false : dynamicField?.values.length >= cardinality) || isLocked;
    const maxFileSize = ((dynamicField?.config?.max_file_size || 0) / 1024 / 1024) || 50;

    let rightActions: any = [];

    if (dynamicField.config?.can_mark_not_applicable) {
      rightActions.push({
        node: (
          <Tooltip
            placement="top"
            title={ 'Select N/A if the question is not Applicable' }
          >
            <span className="mL-10"> { 'N/A' } </span>
            <Checkbox
              className="mL-5"
              onChange={ (e: CheckboxChangeEvent) => {
                let _dynamicField = _.cloneDeep(dynamicField);
                _dynamicField.not_applicable = !!e.target.checked;
                this.handleFieldChange(_dynamicField);
              }}
              checked={ !!dynamicField?.not_applicable }
            />
          </Tooltip>
        )
      });
    }

    rightActions = rightActions.concat(getActionControls(dynamicField, this.state, (state: any) => this.setState(state), isPreviewing));

    return (
      <FieldWrapper
        description={ dynamicField.description }
        label={ dynamicField.label }
        required={ dynamicField.config.required }
        versionChanged={ !!dynamicField.config.version_changed }
        rightActions={ rightActions }
        errors={ errors }
        isModified={ isModified }
        border
      >
        { !!dynamicField?.not_applicable ? (
            <div> Not Applicable </div>
          ) : (
            <div>
              <Upload
                clientId={ clientId }
                classes={ 'File-Field' }
                uploadEndpoint={ `client/${clientId}/field/dynamic/upload` }
                allowedExtensions={ allowedExtensions }
                onRemove={ (file: any) => this.handleRemove(file) }
                onPreview={ (file: any) => this.setState({
                  preview_id: file.uid
                }) }
                multiple={ cardinality !== 1 }
                isDisabled={ isDisabled }
                showAllowedTypes
                files={ dynamicField?.values }
                onUpload={ (fileRecord: any) => {
                  this.handleChange(fileRecord);
                }}
                maxFileSize={ maxFileSize }
              />
              { preview_id && this.renderPreview(preview_id) }
              <ActionWrapper
                clientId={ clientId }
                dynamicField={ dynamicField }
                isLocked={ isLocked }
                isPreviewing={ isPreviewing }
                showAttachments={ showAttachments }
                showComment={ showComment }
                showActions={ showActions }
                showScoring={ showScoring }
                hideScoring={ () => this.setState({ showScoring: false }) }
                extensions={ extensions }
                onComment={ onComment }
                onUpload={ onUpload }
                onRemove={ onRemove }
                onCreateAction={ onCreateAction }
                onScore={ onScore }
              />
            </div>
          )
        }
      </FieldWrapper>
    );
  };

};

export default FileField;
