// Libs
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Components
import Jumbotron from "components/jumbotron";
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import BasicListView from 'components/basic-list';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Services
import { Api } from 'services/api';

// Actions
import { setBreadcrumbs, setBreadcrumbsLoading } from "store/UI/ActionCreators";

interface Props {
  client_id: number;
  user_id: number;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  fileList: any[];
  tool: string | null;
  error: any;
  records: any;
  isLoading: boolean;
  isUploading: boolean;
};

const API: Api = new Api();

class ExportImportListView extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    fileList: [],
    tool: null,
    error: null,
    records: [],
    isLoading: false,
    isUploading: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Export - Import', path: null },
    ], false);

    try {
      this.props.setBreadcrumbsLoading(true);
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const records = await API.get(`client/${client_id}/admin/export-import`);

      this.mounted && this.setState({
        records: records,
      });
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderList = () => {
    const { records } = this.state;
    return (
      <BasicListView
        rawData
        columns={ [
          {
            key: 'title',
            dataIndex: 'title',
            title: 'Title',
            filterable: false,
            render: (__: any, record: any) => {
              return <Link to={ `/admin/export-import/${record.reference}` } style={ { 'color': '#2196f3' } }>{ record?.title || '-' }</Link>;
            },
            sorter: true,
            ellipsis: true,
          },
        ] }
        items={ records.map((record: { title: string, reference: string, export: string }) => {
          return {
            ...record,
            key: record.reference,
          };
        }) }
      />
    );
  };

  render = () => {
    const { isLoading } = this.state;
    return (
      <BlockingSpinner isLoading={ isLoading }>
        <Jumbotron
          content={ 'Import - Export' }
          tabs={ [
            {
              label: '',
              node: this.renderList(),
            },
          ] }
        />
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    user_id: store.UserState.user.id,
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(ExportImportListView, 'access_admin_import'));