// Store
import { Reducer } from 'redux';

import ClientActions from './Actions.type';
import ClientActionTypes from './ActionTypes.enum';
import ClientState from './State.interface';

const initialClientState: ClientState = {
  client_id: 0,
  name: '',
  routes: [],
  settings: [],
};

const ClientReducer: Reducer<ClientState, ClientActions> = (
  state = initialClientState,
  action
) => {
  switch (action.type) {
    case ClientActionTypes.GET_CLIENT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        client_id: action.payload.client_id,
        name: action.payload.name,
        routes: action.payload.routes,
        settings: action.payload.settings,
      };
    }
    case ClientActionTypes.GET_CLIENT_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case ClientActionTypes.SET_CLIENT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        client_id: action.payload.client_id,
        name: action.payload.name,
        routes: action.payload.routes,
        settings: action.payload.settings,
      };
    }
    case ClientActionTypes.SET_CLIENT_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};

export default ClientReducer;
