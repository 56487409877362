// Libs
import * as React from 'react';
import moment from 'moment';
import _ from 'lodash';

// Components
import CostCalendar, { IStateFilter } from 'components/cost-calendar';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

const API: Api = new Api();

interface Props {
  client_id: number | undefined;
  id: number;
  type: string;
  bundle: string;
};

interface State {
  config: null,
  columns: any[],
  data: any[],
  filters: any[],
  isLoading: boolean;
};

class CostCalendarView extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    config: null,
    columns: [],
    data: [],
    filters: [],
    isLoading: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getData();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  exportFile = async (filters: IStateFilter, callback: (successful: boolean) => void) => {
    const { client_id, bundle, type, id } = this.props;
    try {
      await API.download(`client/${client_id}/${bundle}/${type}/${id}/calendar/opex/export`, `pacs_cost_table_${moment().format('YYYY-MM-DD')}.xlsx`, {
        filters: filters,
      });
      callback(true);
    } catch (error) {
      callback(false);
      Notification('error', 'Export Failed');
    }
  };

  getData = async (filters?: IStateFilter) => {
    const { client_id, id, type, bundle} = this.props;
    try {

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const list = await API.get(`client/${client_id}/${bundle}/${type}/${id}/calendar/opex`, {
        filters: filters || []
      });

      this.mounted && this.setState({
        config: list?.config || null,
        columns: list?.columns || [],
        data: list?.data || [],
        filters: list?.filters || [],
      });
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  render = () => {
    return (
      <CostCalendar
        isLoading={ this.state.isLoading }
        config={ this.state.config }
        columns={ this.state.columns }
        data={ this.state.data }
        filters={ this.state.filters }
        onFilter={ (filters: IStateFilter) => {
          this.getData(filters);
        } }
        onExport={ (filters: IStateFilter, callback: (successful: boolean) => void) => {
          this.exportFile(filters, callback);
        } }
      />
    );
  };
};

export default CostCalendarView;
