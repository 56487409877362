// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import AssetsContainer from 'containers/workplace-service/AssetsContainer';
import ServiceSpecificationContainer from 'containers/workplace-service/ServiceSpecificationContainer';
import HelpDeskContainer from 'containers/workplace-service/HelpDeskContainer';
import MaintenancePlansContainer from 'containers/workplace-service/MaintenancePlansContainer';
import KpiLibraryContainer from 'containers/workplace-service/KpiLibraryContainer';

// Views
import NotFound from 'views/NotFound';

class WorkplaceServiceContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Redirect exact from="/workplace-services" to="/workplace-services/service-specifications" />

        <Route exact path="/workplace-services/service-specifications" component={ ServiceSpecificationContainer } />
        <Route path="/workplace-services/service-specifications/:record_id" component={ ServiceSpecificationContainer } />

        <Route exact path="/workplace-services/assets" component={ AssetsContainer } />
        <Route path="/workplace-services/assets/:asset_register_id" component={ AssetsContainer } />

        <Route path="/workplace-services/help-desk-tickets" component={ HelpDeskContainer } />

        <Route exact path="/workplace-services/maintenance-plan" component={ MaintenancePlansContainer } />
        <Route path="/workplace-services/maintenance-plan/:maintenance_plan_id" component={ MaintenancePlansContainer } />

        <Route exact path="/workplace-services/kpi-library" component={ KpiLibraryContainer } />
        <Route path="/workplace-services/kpi-library/metric" component={ KpiLibraryContainer } />
        <Route path="/workplace-services/kpi-library/priority-set/:priority_set_id" component={ KpiLibraryContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

export default WorkplaceServiceContainer;
