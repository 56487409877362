// Libs
import React from 'react';

// Components
import CoverModal from 'components/cover-modal';
import { Input, Form, Button } from 'antd';

// Interfaces
import { UserEntity } from 'types/entities';
import ClientState from 'store/Client/State.interface';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

const API: Api = new Api();

interface Props {
  client: ClientState;
  user: UserEntity;
  onClose(): void;
};

interface State {
  isCreating: boolean;
};

class HelpTicketModal extends React.Component<Props, State> {

  formRef: any = React.createRef();

  state: State = {
    isCreating: false,
  };

  onSubmit = async (values: any) => {
    const { client, user, onClose } = this.props;

    try {

      await new Promise((resolve) => this.setState({ isCreating: true }, () => resolve(null)));

      await API.post(`client/${client.client_id}/user/${user.id}/help-ticket`, {
        data: {
          'name': user.first_name,
          'email': user.email,
          'client': client.name,
          'subject': values.subject,
          'description': values.description,
          'currentUrl': window.location.href,
          'userAgent': navigator.userAgent,
        }
      });

      Notification('success', `A ticket was created.`, 'Ticket Created');

    } catch (error) {
      console.error(error);
      Notification('error', `Failed to create a ticket.`);
    } finally {
      this.setState({
        isCreating: false
      }, () => {
        onClose();
      });
    }
  };

  render = () => {
    return (
      <CoverModal style={{ width: 900, height: 700 }} middleContent={ 'Request Help' } onClose={ this.props.onClose }>
        <div className="m-30">
          <div>
            <p className="mT-10">If you’re having trouble using the PACS platform, please submit a help ticket to us using the form below.</p>
            <p className="mT-10">We endeavor to respond within 48 hours to issues being submitted to us here.</p>
            <p className="mT-10">Please note, the following data will automatically be appended to your support ticket. By making a submission, you agree for the data listed below to be shared with our internal Support team.</p>
            <ul className="mT-10">
              <li>First Name</li>
              <li>Email</li>
              <li>Current Page URL</li>
              <li>Browser Name and Version Number</li>
            </ul>
          </div>
          <div className="mT-30">
            <Form
              ref={ this.formRef }
              layout="vertical"
              onFinish={ (values: any) => this.onSubmit(values) }
            >
              <Form.Item
                label="Issue Subject"
                name="subject"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Issue Description"
                name="description"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input.TextArea
                  autoComplete="none"
                  autoSize={{ minRows: 4 }}
                />
              </Form.Item>
              <Form.Item className="ta-r">
                <Button type="primary" htmlType="submit" loading={ this.state.isCreating }>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </CoverModal>
    );
  };
};

export default HelpTicketModal;
