// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Views
import Templates from 'views/admin/templates/Templates';
import NotFound from 'views/NotFound';

// Containers
import ActivityContainer from 'containers/admin/template/ActivityContainer';
import FinanceContainer from 'containers/admin/template/FinanceContainer';
import ClassificationContainer from 'containers/admin/template/ClassificationContainer';
import DynamicFieldContainer from 'containers/admin/template/DynamicFieldContainer';

class TemplateContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Redirect exact from="/admin/templates" to="/admin/templates/activity" />

        <Route exact path="/admin/templates/:template_type" component={ Templates } />

        <Route path="/admin/templates/activity/:type_id" component={ ActivityContainer } />
        <Route path="/admin/templates/activity/:type_id/activities/:id" component={ ActivityContainer } />

        <Route path="/admin/templates/finance" component={ FinanceContainer } />

        <Route path="/admin/templates/classifications" component={ ClassificationContainer } />

        <Route path="/admin/templates/dynamic-field" component={ DynamicFieldContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

export default TemplateContainer;
