// Libs
import React, { Component } from 'react';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import TemplateTable from 'components/template-modal/TemplateTable';

// Interfaces
import { RecordFormEntity } from 'types/entities';
import { ITemplate } from 'components/template-modal/TemplateModal.interface';

interface Props {
  record: RecordFormEntity;
  field: FormField;
  clientId: number;
  originalValues: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  numberFormat: any;
  onChange(
    state: any
  ): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  deleteConfirmId: string | null;
  errors: any[];
};

class FinanceTemplate extends Component<Props, State> {

  state: State = {
    deleteConfirmId: null,
    errors: [],
  };

  componentDidUpdate = (prevProps: Props) => {
    if (!_.isEqual(prevProps.field, this.props.field)) {
      this.validate(this.props.field, this.props.originalValues, this.state.errors);
    }
  };

  validate = (field: FormField, originalValues: any, errors: any) => {
    const key = `${this.props.field.id}_${this.props.config.fieldIndex || 0}_finance_template`;

    this.props.setFieldErrorMessage(key, _.isEmpty(errors) ? undefined : {
      id: this.props.field.id,
      cardinality: this.props.config.fieldIndex || 0,
      group: this.props.config.groupID,
      tab: this.props.config.tabID,
      order: this.props.config.elementIndex,
      content: {
        label: this.props.field.label,
        content: []
      },
      errors: errors
    });

    this.props.setFieldModifiedMessage(key, _.isEqual(field.values, originalValues) ? undefined : {
      id: this.props.field.id,
      cardinality: this.props.config.fieldIndex || 0,
      group: this.props.config.groupID,
      tab: this.props.config.tabID,
      order: this.props.config.elementIndex,
      content: {
        label: this.props.field.label,
        content: []
      },
      modified: { '': true }
    });
  };

  handleChange = (values: any[], row: any) => {

    let newValues = _.cloneDeep(values);

    const rowIndex = newValues.findIndex((value: any) => (!!value.id && value.id === row.id) || value.key === row.key);
    const isNew = rowIndex === -1;

    if (isNew) {
      newValues = newValues.concat([row]);
    } else {
      newValues = _.set(newValues, rowIndex, row);
    }

    this.props.onChange(newValues);
  };

  modifyRow = (key: any, row: any, newValue: any) => {
    return {
      ..._.set(row, `values.${key}`, newValue)
    };
  };

  render = () => {
    const { field, config, border, fieldModifiedMessages, fieldErrorMessages } = this.props;
    const key = `${field.id}_${config.fieldIndex || 0}_finance_template`;
    const isModified = _.has(fieldModifiedMessages, key);
    const hasErrors = _.has(fieldErrorMessages, key);
    const template: ITemplate = field.template;

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ 12 }
        label={ field.label }
        errors={ hasErrors ? ['Please resolve errors'] : undefined }
        hideErrorInfo
        required={ field.config.required }
        border={ border }
        description={ !!field.description && field.description }
        isModified={ !hasErrors && isModified }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
      >
        <TemplateTable
          numberFormat={ this.props.numberFormat }
          template={ template }
          record={ this.props.record }
          templates={ [template] }
          values={ field.values }
          errors={ this.state.errors }
          height={ 500 }
          setValues={ (values: any) => {
            this.props.onChange(values);
          } }
          setErrors={ (errors: any) => {
            this.setState({
              errors: errors
            });
          } }
        />
      </FieldWrapper>
    );
  };
};

export default FinanceTemplate;
