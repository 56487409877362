// Libs
import * as React from 'react';
import { createPortal } from 'react-dom';

// Styles
import './OverlaySpinner.scss';

interface Props {
};

const portal: any = document.getElementById('portal');

export default class OverlaySpinner extends React.Component<Props> {

  modal: any;

  constructor(props: Props) {
    super(props);
    this.modal = document.createElement('div');
  };

  componentDidMount() {
    portal.appendChild(this.modal);
  };

  componentWillUnmount = () => {
    portal.removeChild(this.modal);
  };

  render = () => {
    return createPortal((
      <div className='OverlaySpinner'>
        <div className='OverlaySpinner-wrapper'>
          <div className='OverlaySpinner-spinner' />
        </div>
      </div>
    ), portal);
  };
};