// Libs
import * as React from 'react';

// Components
import KpisView from 'views/common/KpisView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
};

class ReactiveTicketKpiLibrary extends React.Component<Props> {
  render = () => {
    return <KpisView type={ 'reactive_ticket' } entity={ 'help_desk_ticket' } record={ this.props.record } />;
  };
};

export default ReactiveTicketKpiLibrary;
