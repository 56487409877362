// Libs
import React from 'react';
import { Divider } from 'antd';

// Styles
import './Divider.scss';

// Interfaces
import { FormElement } from 'components/form/form-wrapper';

interface Props {
  orientation: FormElement['config']['orientation'];
  text: FormElement['config']['text'];
};

const DividerComponent: React.FC<Props> = ({
  orientation,
  text,
}) => {
  return (
    <Divider className="Divider" orientation={ orientation || 'left' }>{ text || '' }</Divider>
  );
};

export default DividerComponent;
