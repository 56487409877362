// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import PlannedContainer from 'containers/workplace-service/help-desk/PlannedContainer';
import StatutoryContainer from 'containers/workplace-service/help-desk/StatutoryContainer';
import ReactiveContainer from 'containers/workplace-service/help-desk/ReactiveContainer';
import RemedialContainer from 'containers/workplace-service/help-desk/RemedialContainer';
import ScheduledContainer from 'containers/workplace-service/help-desk/ScheduledContainer';

// Views
import HelpdeskTickets from 'views/workplace-service/HelpdeskTickets';
import NotFound from 'views/NotFound';

class HelpDeskContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Route exact path="/workplace-services/help-desk-tickets" component={ HelpdeskTickets } />

        <Redirect exact from="/workplace-services/help-desk-tickets/planned-ticket" to="/workplace-services/helpdesk-tickets" />
        <Redirect exact from="/workplace-services/help-desk-tickets/statutory-ticket" to="/workplace-services/helpdesk-tickets" />
        <Redirect exact from="/workplace-services/help-desk-tickets/reactive-ticket" to="/workplace-services/helpdesk-tickets" />
        <Redirect exact from="/workplace-services/help-desk-tickets/remedial-ticket" to="/workplace-services/helpdesk-tickets" />
        <Redirect exact from="/workplace-services/help-desk-tickets/scheduled-ticket" to="/workplace-services/helpdesk-tickets" />

        <Route path="/workplace-services/help-desk-tickets/planned-ticket/:record_id" component={ PlannedContainer } />

        <Route path="/workplace-services/help-desk-tickets/statutory-ticket/:record_id" component={ StatutoryContainer } />

        <Route path="/workplace-services/help-desk-tickets/reactive-ticket/:record_id" component={ ReactiveContainer } />

        <Route path="/workplace-services/help-desk-tickets/remedial-ticket/:record_id" component={ RemedialContainer } />

        <Route path="/workplace-services/help-desk-tickets/scheduled-ticket/:record_id" component={ ScheduledContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

export default HelpDeskContainer;
