// Libs
import * as React from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

// Components
import {
  DatePicker,
  Select,
  Table,
  Pagination,
  Tooltip,
  Dropdown,
  Button,
  Menu,
  Modal,
  Input,
  Typography,
  Form,
  TreeSelect,
  Tree,
} from 'antd';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import Badge, { BadgeType } from 'components/badge';
import CoverModal from 'components/cover-modal';

// Icons
import { ReactComponent as CrownIcon } from 'assets/svg/crown.svg';
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg';
import { InfoCircleOutlined } from '@ant-design/icons';

// Services
import Notification from 'services/notification';

// Utils
import Console from 'utils/console';
import history from 'utils/history';

// Interfaces
import { RoleEntity, RecordFormEntity } from 'types/entities';

import {
  Alignment,
  Column,
  Resource,
  ResourceListing,
  ResourceRecord,
  ResourceRecordListing,
  ResourceTypeValues,
  ResourceStatus,
  Type,
  User,
  ResourceStatusKeys,
  ResourceType,
  NestedColumn,
  ResourceRequest,
} from './ResourcesTable.interface';

// Messages
import messages from './ResourcesTable.messages';

// Styles
import './ResourcesTable.scss';

export const regionMap = (data: any = []) => {
  return !_.isEmpty(data) && data.map((entity: any) => {
    const appendChildrenKeys = (children: any) => {

      // Prevent nesting
      if (_.isEmpty(children)) return null;

      return children.map((childEntity: any) => {
        return {
          'key': childEntity.id,
          ...childEntity,
          'id': childEntity.id,
          'value': childEntity.id,
          'title': childEntity.title,
          'children': appendChildrenKeys(childEntity.children),
        };
      });
    };

    return {
      'key': entity.id,
      ...entity,
      'id': entity.id,
      'value': entity.id,
      'title': entity.title,
      'children': appendChildrenKeys(entity.children),
    };
  });
};

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Link } = Typography;
const { SHOW_PARENT } = TreeSelect;

export interface Props extends RouteComponentProps {
  record: RecordFormEntity;
  resources: ResourceRecord[];
  roles: RoleEntity[];
  regions: any[];
  title: string;
  intl: IntlShape;
  canCreate?: boolean;
  canDelete?: boolean;
  canEdit?: boolean;
  canModerate?: boolean;
  isRejectCommentRequired?: boolean;
  onProfileClick(profileId: number): void;
  addResource(resource: ResourceRequest): Promise<any>;
  modifyResource(resource: ResourceRequest, resourceId: number): Promise<any>;
  removeResource(resourceId: number): Promise<any>;
  getCompanies(roleId: number): Promise<any>;
  getUsers(roleId: number, companyId?: number): Promise<any>;
  getResources(): Promise<ResourceRecord[]>;
  approveResource(resourceId: number): Promise<any>;
  makePrimaryResource(resourceId: number): Promise<any>;
  rejectResource(resourceId: number, comment: string): Promise<any>;
};

export interface State {
  columns: Column[];
  nestedColumns: NestedColumn[];
  currentPage: number;
  filters: any;
  nestedFilters: any;
  resources: ResourceRecord[];
  formattedResources: ResourceRecordListing[];
  resourcesPerPage: number;
  showFilter: boolean;
  showSort: boolean;
  sorter: any;
  tooltip: any;
  expandedRowKeys: string[];
  activeResourceRow: Resource | null,
  showAddResourceModal: boolean;
  showReplaceResourceModal: boolean;
  showRemoveResourceModal: boolean;
  showChangeResourceModal: boolean;
  showPrimaryResourceModal: boolean;
  showRejectResourceModal: boolean;
  showRegionsModal: boolean;
  addModal: {
    isLoading?: boolean;
    role?: number;
    company?: number;
    companies?: any[];
    regions?: any[];
    companiesAreLoading?: boolean;
    users?: any[];
    userId?: number;
    user?: User;
    usersAreLoading?: boolean;
  };
  replaceModal: {
    isLoading?: boolean;
    recordId?: number;
    roleTitle?: string;
    oldUserId?: number;
    newUserId?: number;
    companies?: any[];
    regions?: any[];
    companiesAreLoading?: boolean;
    companyId?: number;
    users?: User[];
    user?: User;
    oldUser?: User;
    usersAreLoading?: boolean;
  };
  removeModal: {
    isLoading?: boolean;
    roleId?: number;
    roleTitle?: string;
    resourceId?: number;
    userId?: number;
    user?: User;
  };
  changeResourceModal: {
    isLoading?: boolean;
    oldRoleId?: number;
    newRoleId?: number;
    resourceId?: number;
    regions?: any[];
    user?: User;
  };
  changePrimaryResourceModal: {
    isLoading?: boolean;
    resourceId?: number;
    recordName?: string;
    user?: User;
  };
  rejectResourceModal: {
    comment?: string;
    isCommentRequired?: boolean;
    isLoading?: boolean;
    resourceId?: number;
    recordName?: string;
    user?: User;
  };
};

class ResourcesTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { getCompanies, intl: { formatMessage } } = props;

    this.state = {
      columns: [
        {
          title: formatMessage(messages.columnsRole),
          dataIndex: 'title',
          key: 'title',
          type: Type.Select,
          render: (value: string, row: ResourceRecord) => {
            const rowStatus = this.getParentRowStatus(row);

            return (
              <span className='d-f'>
                <span>
                  {value} {row.required ? <span className='fsz-md text-danger'>*</span> : null} ({row.resources.length})
                </span>
                {(rowStatus.pending > 0 || rowStatus.rejected > 0) && (
                  <span className='d-b mL-10'>
                    {rowStatus.pending > 0 && (
                      <Badge className="mR-10" type={ BadgeType.Warning } text={ `${rowStatus.pending} ${formatMessage(messages.pendingStatusLabel)}` } />
                    )}
                    {rowStatus.rejected > 0 && (
                      <Badge type={ BadgeType.Danger } text={ `${rowStatus.rejected} ${formatMessage(messages.rejectedStatusLabel)}` } />
                    )}
                  </span>
                )}
              </span>
            );
          },
        },
        {
          title: '',
          dataIndex: '',
          key: 'misc',
          align: Alignment.Right,
          render: (_: string, row: ResourceRecord) => {

            if (this.props.canCreate && this.isRoleAvailable(row)) {
              return (
                <Button
                  className='Resources-Table-Row-Button'
                  onClick={async (e: React.MouseEvent) => {

                    e.stopPropagation();
                    this.toggleAddModal();
                    this.openAddModal(e, row.id);

                    const companies = await getCompanies(row.id);

                    const newAddModalData = {
                      ...this.state.addModal,
                      companiesAreLoading: false,
                      companies,
                    };

                    if (!!companies && companies.length === 1) {
                      newAddModalData.company = companies[0].id;
                    }

                    this.setState({
                      addModal: newAddModalData,
                    });
                  }}
                >
                  +
                </Button>
              );
            }

            return <></>;
          },
        },
      ],
      nestedColumns: [
        {
          id: 'type',
          title: formatMessage(messages.columnsInnerType),
          key: 'type',
          dataIndex: 'type',
          type: Type.Select,
          items: Object.values(ResourceTypeValues),
          width: 150,
        },
        {
          id: 'company',
          title: formatMessage(messages.columnsInnerCompany),
          key: 'company',
          dataIndex: 'company',
          type: Type.Select,
          items: this.getCompanyFilterValues(props.resources),
          width: 150,
        },
        {
          id: 'status',
          title: formatMessage(messages.columnsInnerStatus),
          key: 'status',
          dataIndex: 'status',
          type: Type.Select,
          items: Object.values(ResourceStatusKeys),
          itemLabels: Object.values(ResourceStatus),
          width: 150,
        },
      ],
      currentPage: 1,
      filters: {},
      nestedFilters: {},
      resources: props.resources,
      formattedResources: this.formatResources(props.resources),
      resourcesPerPage: 25,
      showFilter: false,
      showSort: false,
      sorter: null,
      tooltip: null,
      expandedRowKeys: [],
      activeResourceRow: null,
      showAddResourceModal: false,
      showReplaceResourceModal: false,
      showRemoveResourceModal: false,
      showChangeResourceModal: false,
      showPrimaryResourceModal: false,
      showRejectResourceModal: false,
      showRegionsModal: false,
      addModal: {},
      replaceModal: {},
      removeModal: {},
      changeResourceModal: {},
      changePrimaryResourceModal: {},
      rejectResourceModal: {},
    };
  };

  componentDidMount = () => {
    this.setState({
      expandedRowKeys: this.getData().paginatedData.map((resource: ResourceRecordListing) => resource?.key)
    });
  };

  getResourceTypeLabel = (resourceTypeEnum: string): string => {
    switch (resourceTypeEnum) {
      case ResourceType.Direct:
        return ResourceTypeValues.Direct;

      case ResourceType.Inherited:
        return ResourceTypeValues.Inherited;

      default:
        return ResourceTypeValues.Direct;
    }
  };

  isRoleAvailable = (row: ResourceRecord) => {
    const { roles } = this.props;
    const role = roles.filter((role: RoleEntity) => role.id === row.id);

    return role.length >= 1;
  };

  navigateToEditUser = (userId: number) => {
    this.props.history.push(`/admin/users/${userId}`);
  };

  getCompanyFilterValues = (data: ResourceRecord[]) => {
    const companies: string[] = [];
    const companyIds: number[] = [];

    data && data.forEach((item: ResourceRecord) => {
      item && item.resources.forEach((resource: Resource) => {
        if (_.has(resource, 'user.company.id') && companyIds.includes(resource.user.company.id) === false) {
          companies.push(resource.user.company.title);
          companyIds.push(resource.user.company.id);
        }
      });
    });

    return companies;
  };

  formatResources = (data: ResourceRecord[]): ResourceRecordListing[] => {
    return data.map((item: ResourceRecord, index: number): ResourceRecordListing => {
        const updatedResources = item.resources.map((resource: Resource, _index: number): ResourceListing => ({
            ...resource,
            key: `${item?.id}-${resource?.user_id}-${_index}`,
          }),
        );

        return {
          ...item,
          resources: updatedResources,
          key: `role-${item.id}-${index}` || 'no-key',
        };
      },
    );
  };

  openAddModal = (event?: React.MouseEvent, roleId?: any) => {
    const addModal = roleId ? { role: roleId, companiesAreLoading: true } : {};
    this.setState((state: State) => ({ addModal }));
  };

  toggleAddModal = () => {
    const { showAddResourceModal } = this.state;
    this.setState({ showAddResourceModal: !showAddResourceModal });
  };

  toggleRemoveModal = () => {
    const { showRemoveResourceModal } = this.state;
    this.setState({ showRemoveResourceModal: !showRemoveResourceModal });
  };

  toggleReplaceModal = () => {
    const { showReplaceResourceModal } = this.state;
    this.setState({ showReplaceResourceModal: !showReplaceResourceModal });
  };

  togglePrimaryResourceModal = () => {
    const { showPrimaryResourceModal } = this.state;
    this.setState({ showPrimaryResourceModal: !showPrimaryResourceModal });
  };

  toggleChangeResourceModal = () => {
    const { showChangeResourceModal } = this.state;
    this.setState({ showChangeResourceModal: !showChangeResourceModal });
  };

  toggleRejectResourceModal = () => {
    const { showRejectResourceModal } = this.state;
    this.setState({ showRejectResourceModal: !showRejectResourceModal });
  };

  isAddResourceButtonDisabled = (): boolean => {
    if (this.state.addModal.userId && this.state.addModal.role) {
      return false;
    }

    if (this.state.addModal.user) {
      return false;
    }

    return true;
  };

  isReplaceResourceButtonDisabled = (): boolean => {
    if (this.state.replaceModal.newUserId) {
      return false;
    }

    return true;
  };

  approveResource = async (resourceId: number, record: ResourceRecord, resource: Resource) => {
    const {
      approveResource,
      getResources
    } = this.props;

    try {
      await approveResource(resourceId);
      const resources = await getResources();
      const formattedResources = this.formatResources(resources);
      this.setState({ resources, formattedResources });
      Notification('success', `Resource "${record.title}, ${resource.user.full_name}" is now approved`, 'Resource approved');
    } catch (error: any) {
      Console.info(error);

      switch (error.callee) {
        case 'approveEntityResource':
          Notification('error', error.message, 'Error approving resource');
          break;

        case 'getEntityResources':
          Notification('error', error.message, 'Error refreshing resources');
          break;

        default:
          Notification('error', error.message, 'Error approving resource');
          Console.info('Unknown error');
          break;
      }
    }
  };

  addResource = async () => {
    const { addResource, getResources, intl: { formatMessage }, roles } = this.props;
    const { addModal } = this.state;
    const { user, regions } = addModal;
    const role: RoleEntity | undefined = roles && roles.find((element) => element.id === addModal.role);

    if (!user || !role) return;

    try {

      this.setState((state: State) => ({ addModal: { ...state.addModal, isLoading: true } }));

      const resource = await addResource({ role_id: role.id, user_id: user.id, regions: regions || [] });
      const resources = await getResources();
      const formattedResources = this.formatResources(resources);
      const formattedResource = formattedResources.find((_formattedResource) => _formattedResource.id === resource.role_id);

      this.setState({
        resources: resources,
        formattedResources: formattedResources,
        expandedRowKeys: formattedResource?.key ? [...this.state.expandedRowKeys, formattedResource.key] : this.state.expandedRowKeys,
        showAddResourceModal: false,
      }, () => {
        Notification('success', `Resource "${role.title}, ${user.full_name}" has been added`, 'Resource added');
      });

    } catch (error: any) {
      Console.error(error);

      switch (error.callee) {
        case 'postEntityResource':
          Notification('error', error.message, formatMessage(messages.errorsAddingResource));
          break;

        case 'getEntityResources':
          Notification('error', error.message, 'Error refreshing resources');
          break;

        default:
          Notification('error', error.message, formatMessage(messages.errorsAddingResource));
          Console.info('Unknown error');
          break;
      }
    } finally {
      this.setState((state: State) => ({ addModal: { ...state.addModal, isLoading: false } }));
    }
  };

  replaceRole = async () => {
    const { getResources, intl: { formatMessage }, modifyResource } = this.props;
    const { replaceModal } = this.state;
    const { users, regions } = replaceModal;

    const user = users && users.find(user => user.id === replaceModal.newUserId);

    if (!user || !regions) return;

    try {
      this.setState((state: State) => ({ replaceModal: { ...state.replaceModal, isLoading: true } }));
      await Promise.all(
        this.state.formattedResources.map(async (record: ResourceRecord) => {

          if (record.id === this.state.replaceModal.recordId) {
            const index = record.resources.findIndex(
              (element) => element.user.id === this.state.replaceModal.oldUserId,
            );

            await modifyResource(
              { user_id: user.id, role_id: record.id, regions: regions},
              record.resources[index].id,
            );
          }

          return record;
        }),
      );

      const resources = await getResources();
      const formattedResources = this.formatResources(resources);

      this.setState({
        resources: resources,
        formattedResources: formattedResources,
        showReplaceResourceModal: false,
      });

    } catch (error: any) {
      Console.error(error);

      switch (error.callee) {
        case 'putEntityResource':
          Notification('error', error.message, formatMessage(messages.errorsReplacingResource));
          break;

        case 'getEntityResource':
          Notification('error', error.message, 'Error updating resource list');
          break;

        default:
          Notification('error', error.message, 'Unknown Error');
          break;
      }
    } finally {
      this.setState((state: State) => ({ replaceModal: { ...state.replaceModal, isLoading: false } }));
    }
  };

  changeResource = async () => {
    const {
      addResource,
      getResources,
      modifyResource,
      removeResource,
      roles
    } = this.props;

    const {
      changeResourceModal,
      formattedResources
    } = this.state;

    try {
      this.setState((state: State) => ({ changeResourceModal: { ...state.changeResourceModal, isLoading: true } }));
      let newEntry = false;

      await Promise.all(formattedResources.map(async (record: ResourceRecord) => {
        if (changeResourceModal.user) {
          if (record.id === changeResourceModal.oldRoleId) {
            if (record.resources.length > 1) {
              const index = record.resources.findIndex(
                (element) => element.id === changeResourceModal.resourceId,
              );

              const oldResourceId = record.resources[index].id;
              await removeResource(oldResourceId).catch((error) =>
                Promise.reject({ callee: error.callee, index, error })
              );
            }
          }

          if (record.id === changeResourceModal.newRoleId && changeResourceModal.resourceId) {
            await modifyResource(
              {
                user_id: changeResourceModal.user?.id,
                role_id: changeResourceModal.newRoleId,
                regions: [],
              },
              changeResourceModal.resourceId
            );
          }
        }
      }));

      if (newEntry) {
        await roles.forEach(async (role: RoleEntity, index) => {
          if (role.id === changeResourceModal.newRoleId && changeResourceModal.user) {
            await addResource({
              user_id: changeResourceModal.user?.id,
              role_id: changeResourceModal.newRoleId,
              regions: [],
            }).catch((error) => Promise.reject({ callee: 'addNewResource', index, error }));
          }
        });
      }

      const resources = await getResources();
      const newFormattedResources = this.formatResources(resources);
      this.setState({ resources, formattedResources: newFormattedResources });
      this.toggleChangeResourceModal();
      Notification('success', `Resource "${changeResourceModal.user?.full_name}" has been changed`, 'Resource changed');
    } catch (error: any) {
      Console.error(error);
      this.setState((state: State) => ({ changeResourceModal: { ...state.changeResourceModal, isLoading: false } }));

      switch (error.callee) {
        case 'postEntityResource':
          Notification('error', error.message, 'Error changing role');
          break;

        case 'addNewResource':
          Notification('error', error.message, 'Error changing role');
          break;

        case 'deleteEntityResource':
          Notification('error', error.message, 'Error changing role');
          break;
      }
    }
  };

  handleRemoveRole = async () => {
    const {
      getResources,
      intl: { formatMessage },
      removeResource
    } = this.props;

    const {
      removeModal
    } = this.state;

    try {
      this.setState((state: State) => ({ removeModal: { ...state.removeModal, isLoading: true } }));

      if (removeModal.resourceId) {
        await removeResource(removeModal.resourceId);
      }

      const resources = await getResources();
      const newResources = this.formatResources(resources);

      this.setState({
        resources,
        formattedResources: newResources
      });

      Notification('success', `Resource "${removeModal.roleTitle}, ${removeModal.user?.full_name}" has been removed`, 'Resource removed');
    } catch (error: any) {
      this.setState((state: State) => ({ removeModal: { ...state.removeModal, isLoading: false } }));

      switch (error.callee) {
        case 'deleteEntityResource':
          Notification('error', error.message, formatMessage(messages.errorsRemovingResource));
          break;

        case 'getEntityResource':
          Notification('error', error.message, 'Error updating resource list');
          break;

        default:
          Notification('error', error.message, 'Unknown Error');
          break;
      }
    } finally {
      this.setState({
        showRemoveResourceModal: false
      });
    }
  };

  makeResourcePrimary = async () => {
    const { intl: { formatMessage }, makePrimaryResource, getResources } = this.props;
    const { changePrimaryResourceModal } = this.state;
    const resourceId = changePrimaryResourceModal.resourceId;

    if (!resourceId) return;

    try {

      await new Promise((resolve) => this.setState({ changePrimaryResourceModal: { ...changePrimaryResourceModal, isLoading: true } }, () => resolve(null)));
      await makePrimaryResource(resourceId);
      const resources = await getResources();

      this.setState({
        resources: resources,
        formattedResources: this.formatResources(resources),
        showPrimaryResourceModal: false,
      });

    } catch (error: any) {
      Console.error(error);

      switch (error.callee) {
        case 'makeEntityResourcePrimary':
          Notification('error', error.message, formatMessage(messages.errorsReplacingResource));
          break;

        case 'getEntityResource':
          Notification('error', error.message, 'Error updating resource list');
          break;

        default:
          Notification('error', error.message, 'Unknown Error');
          break;
      }
    } finally {
      this.setState(() => ({
        changePrimaryResourceModal: { ...changePrimaryResourceModal, isLoading: false },
      }));
    }
  };

  rejectResource = async () => {
    const {
      getResources,
      rejectResource,
    } = this.props;

    try {
      this.setState((state: State) => ({
        rejectResourceModal: { ...state.rejectResourceModal, isLoading: true },
      }));
      const resourceId = this.state.rejectResourceModal?.resourceId;
      const comment = this.state.rejectResourceModal?.comment || '';

      if (resourceId) {
        await rejectResource(resourceId, comment);
        const resources = await getResources();
        const formattedResources = this.formatResources(resources);
        this.setState({ resources, formattedResources });
        this.toggleRejectResourceModal();
      }
    } catch (error: any) {
      Console.error(error);
      this.setState((state: State) => ({
        rejectResourceModal: { ...state.rejectResourceModal, isLoading: false },
      }));

      switch (error.callee) {
        case 'makeEntityResourcePrimary':
          Notification('error', error.message, 'Error rejecting resource');
          break;

        case 'getEntityResource':
          Notification('error', error.message, 'Error updating resource list');
          break;

        default:
          Notification('error', error.message, 'Unknown Error');
          break;
      }
    }
  };

  handleSort = (pagination: any, filters: any, sorter: any, extra: any) => {
    this.setState({
      sorter: sorter,
    });
  };

  paginateResources = (items: any[], currentPage = 1, resourcesPerPage = 25) => {
    return _.drop(items, (currentPage - 1) * resourcesPerPage).slice(0, resourcesPerPage);
  };

  sortData = (items: any[], sorter: any) => {
    return _.orderBy(items, sorter.field, sorter.order === 'descend' ? 'desc' : 'asc');
  };

  filter = (items: any[], filters: any) => {
    const {
      columns
    } = this.state;

    return items.filter((item: any) => {
      return Object.keys(filters).every((key) => {
        if (!filters[key].length) return false;

        const column = columns.find((column: any) => column.dataIndex === key);

        switch (column?.type) {
          case Type.Range:
            return moment(item[key]).isBetween(filters[key][0], filters[key][1]);

          case Type.Select:
            return filters[key].includes(item[key]);
        }

        return false;
      });
    });
  };

  nestedFilter = (items: ResourceRecordListing[], filters: Record<string, string>): ResourceRecordListing[] => {
    const { nestedColumns } = this.state;
    const filteredItems: ResourceRecordListing[] = [];

    items.forEach((item: ResourceRecordListing) => {
      const resources = item.resources.filter((resource: ResourceListing) => {
        return Object.keys(filters).every((key) => {
          if (!filters[key].length) return false;
          const column = nestedColumns.find((column: NestedColumn) => column.dataIndex === key);
          const rawValue = resource[key as keyof ResourceListing];

          if (key === 'company' && _.has(resource, 'user.company.title')) {
            return filters[key].includes(resource.user.company.title);
          } else {
            switch (column?.type) {
              case Type.Range:
                if (typeof rawValue === 'string') {
                  return moment(rawValue).isBetween(filters[key][0], filters[key][1]);
                }

                break;
              case Type.Select:
                const rawFilterValue = filters[key];

                if (typeof rawFilterValue === 'string' && typeof rawValue === 'string') {
                  const filterValue = rawFilterValue.toLowerCase();
                  const value = rawValue.toLowerCase();
                  return filterValue === value;
                }

                return rawFilterValue === rawValue;
            }
          }

          return false;
        });
      });

      if (resources.length > 0) {
        filteredItems.push({
          ...item,
          resources,
        });
      }
    });

    return filteredItems;
  };


  getParentRowStatus = (record: ResourceRecord) => {
    const pending = record.resources.filter((element: Resource) => element.status === ResourceStatusKeys.Pending).length;
    const rejected = record.resources.filter((element: Resource) => element.status === ResourceStatusKeys.Rejected).length;

    return { pending, rejected };
  };

  isRejectCommentRequired = () => {
    return false;
  };

  handleRejectCommentChange = (comment: string) => {
    this.setState((state: State) => ({
      rejectResourceModal: Object.assign(state.rejectResourceModal, { comment: comment })
    }));
  };

  renderFilterSelect = (column: Column, items: any) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { filters, tooltip } = this.state;

    const uniqItems = _.uniqBy(items, column.key)
      .filter((item: any) => {
        return !!item[column.key];
      })
      .map((item: any) => {
        return item[column.key];
      });

    // No items found
    if (_.isEmpty(uniqItems)) return;

    return (
      <Tooltip
        key={column.key}
        visible={tooltip === column.key}
        title={`${formatMessage(messages.filter_by)} ${column.title}`}
      >
        <Select
          allowClear
          style={{ width: 200, margin: 5 }}
          placeholder={column.title}
          onMouseEnter={() => {
            filters[column.key] &&
              this.setState({
                tooltip: column.key,
              });
          }}
          onMouseLeave={() => {
            tooltip &&
              this.setState({
                tooltip: null,
              });
          }}
          onChange={(value: any) => {
            let newFilters = filters;
            if (!value || !value.length) {
              delete newFilters[column.key];
            } else {
              newFilters = Object.assign({}, filters, { [column.key]: value });
            }
            this.setState({
              filters: newFilters,
              currentPage: 1,
            });
          }}
          value={filters[column.key] ? filters[column.key] : []}
        >
          {uniqItems.map((uniqItem: any) => (
            <Option key={`filter-${column.key}-${uniqItem}`} value={uniqItem}>
              {uniqItem}
            </Option>
          ))}
        </Select>
      </Tooltip>
    );
  };

  renderFilterRange = (column: Column) => {
    const { filters } = this.state;

    return (
      <div className='m-5'>
        <RangePicker
          showTime
          onChange={(dates: any, dateStrings: any) => {
            let newFilters = filters;

            // Check if dateStrings contains empty values e.i ['', '']
            if (_.some(dateStrings, (value) => !value.length)) {
              delete newFilters[column.key];
            } else {
              newFilters = Object.assign({}, filters, {
                [column.key]: dateStrings,
              });
            }

            this.setState({
              filters: newFilters,
            });
          }}
        />
      </div>
    );
  };

  renderNestedSelectFilters = (column: any) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { nestedFilters, tooltip } = this.state;
    const filterItems: Array<{ label: string, value: string }> = [];

    if (Array.isArray(column.items)) {
      column.items.forEach((item: string, index: number) => {
        if (!!item) {
          if (column.itemLabels && column.itemLabels[index]) {
            filterItems.push({label: column.itemLabels[index], value: item});
          } else {
            filterItems.push({label: item, value: item});
          }
        }
      });
    }

    return (
      <Tooltip
        key={column.id}
        visible={tooltip === column.id}
        title={`${formatMessage(messages.filter_by)} ${column.title}`}
      >
        <Select
          allowClear
          style={{ width: 200, margin: 5 }}
          placeholder={column.title}
          onMouseEnter={() => {
            nestedFilters[column.id] &&
              this.setState({
                tooltip: column.id,
              });
          }}
          onMouseLeave={() => {
            tooltip &&
              this.setState({
                tooltip: null,
              });
          }}
          onChange={(value: any) => {
            let newFilters = nestedFilters;
            if (!value || !value.length) {
              delete newFilters[column.id];
            } else {
              newFilters = Object.assign({}, nestedFilters, { [column.id]: value });
            }
            this.setState({
              nestedFilters: newFilters,
            });
          }}
          value={nestedFilters[column.id] ? nestedFilters[column.id] : []}
        >
          {filterItems.map((item: { label: string, value: string }) => {
            return (
              <Option key={`filter-${column.key}-${item.value}`} value={item.value}>
                {item.label}
              </Option>
            );
          })}
        </Select>
      </Tooltip>
    );
  };

  flattenNestedStructure = (items: any) => {
    const flattenedItems: any[] = [];
    items.forEach((item: any) => {
      flattenedItems.push(...item.resources);
    });

    return flattenedItems;
  };

  renderCustomFilters = (items: any) => {
    return (
      <>
        {this.state.nestedColumns.map((column: any) => {
          return <div key={column.id}>{this.renderNestedSelectFilters(column)}</div>;
        })}
      </>
    );
  };

  renderFilters = (columns: any, items: any) => {
    return (
      <div className='d-f mB-10'>
        {columns.map((column: any) => {
          return <div key={column.key}>{this.renderFilter(column, items)}</div>;
        })}
        {this.renderCustomFilters(items)}
      </div>
    );
  };

  renderFilter = (column: Column, items: ResourceRecord) => {
    switch (column.type) {
      case Type.Range:
        return this.renderFilterRange(column);

      case Type.Select:
        return this.renderFilterSelect(column, items);
    }
  };

  renderApproveResourceOption = (record: ResourceRecord, resource: Resource) => {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <Menu.Item
        key={`approve-resource-${resource.role_id}-${record.reference}-${record.id}`}
        onClick={() => this.approveResource(resource.id, record, resource)}
      >
        {formatMessage(messages.menuApproveResource)}
      </Menu.Item>
    );
  };

  renderRejectResourceOption = (record: ResourceRecord, resource: Resource) => {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <Menu.Item
        key={`reject-resource-${resource.role_id}-${record.id}`}
        onClick={async () => {
          const rejectResourceModal = {
            comment: '',
            isCommentRequired: false,
            resourceId: resource.id,
            recordName: record.title,
            user: resource.user,
          };

          this.setState({ rejectResourceModal });
          this.toggleRejectResourceModal();
        }}
      >
        {formatMessage(messages.menuRejectResource)}
      </Menu.Item>
    );
  };

  renderMakeEntityResourcePrimaryOption = (record: ResourceRecord, resource: Resource) => {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <Menu.Item
        key={`make-primary-resource-${resource.role_id}-${record.id}`}
        onClick={async () => {
          const changePrimaryResourceModal = {
            resourceId: resource.id,
            recordName: record.title,
            user: resource.user,
          };

          this.setState({ changePrimaryResourceModal });
          this.togglePrimaryResourceModal();
        }}
      >
        {formatMessage(messages.menuReplacePrimaryResource)}
      </Menu.Item>
    );
  };

  renderReplaceOption = (record: ResourceRecord, resource: Resource) => {
    const {
      canEdit,
      getCompanies,
      intl: { formatMessage },
    } = this.props;

    if (!!canEdit) {
      return (
        <Menu.Item
          key={`replace-resource-${resource.role_id}-${record.id}`}
          onClick={async () => {
            const replaceModal = {
              recordId: record.id,
              roleTitle: record.title,
              oldUserId: resource.user.id,
              oldUser: resource.user,
              companiesAreLoading: true,
            };

            this.setState({ replaceModal });
            this.toggleReplaceModal();
            const companies = await getCompanies(record.id);
            this.setState({ replaceModal: { ...replaceModal, companiesAreLoading: false, companies } });
          }}
        >
          {formatMessage(messages.menuReplaceResource)}
        </Menu.Item>
      );
    }

    return <></>;
  };

  renderRemoveOption = (record: ResourceRecord, resource: Resource) => {
    const { canDelete, intl: { formatMessage } } = this.props;

    if (!!canDelete) {
      return (
        <Menu.Item
          danger
          key={`remove-resource-${resource.role_id}-${record.id}`}
          onClick={() => {
            this.setState({
              showRemoveResourceModal: true,
              removeModal: {
                roleId: record.id,
                roleTitle: record.title,
                resourceId: resource.id,
                userId: resource.user.id,
                user: resource.user,
              },
            });
          }}
        >
          { formatMessage(messages.menuRemoveResource) }
        </Menu.Item>
      );
    }

    return <></>;
  };

  renderResourceOptions = (record: ResourceRecord, resource: Resource) => {
    const { canEdit, canModerate, intl: { formatMessage } } = this.props;
    const directResources = record.resources.filter((_resource: any) => _resource.type === ResourceType.Direct);
    const canRemoveResource = !record.required || (!_.isEmpty(directResources) && directResources.length > 1);

    const options = [];

    if (resource.type === ResourceType.Direct) {

      if (!resource.primary && !!canEdit) {
        options.push(this.renderMakeEntityResourcePrimaryOption(record, resource));
      }

      if (resource.status === ResourceStatusKeys.Pending && !!canModerate) {
        options.push(this.renderApproveResourceOption(record, resource));
        options.push(this.renderRejectResourceOption(record, resource));
      }

      if (canRemoveResource) {
        options.push(this.renderRemoveOption(record, resource));
      }

      return options;
    }

    if (!resource.primary && record.resources.length > 1 && !!canEdit) {
      options.push(this.renderMakeEntityResourcePrimaryOption(record, resource));
    }

    if (resource.status === ResourceStatusKeys.Pending && !!canEdit) {
      options.push(this.renderRejectResourceOption(record, resource));
    }

    return options;
  };

  renderDetailedRow = (resourceRecord: ResourceRecord) => {
    const { record, onProfileClick, canDelete, canEdit, canModerate, intl: { formatMessage } } = this.props;
    const showRegions = record.config.resource_region || false;

    if (_.isEmpty(resourceRecord.resources)) return <></>;

    const renderTableCell = (value: React.ReactNode) => {
      return (
        <span className='cell-line-height'>{ value }</span>
      );
    };

    let columns = [
      {
        title: '',
        dataIndex: 'primary',
        key: 'primary',
        width: 40,
        render: (_: any, resource: Resource) => {

          if (!!resource.primary) {
            return <div className="d-f jc-c"><CrownIcon /></div>;
          }

          return <span className="Resources-Table-Primary-Spacer" />;
        },
      },
      {
        title: '',
        dataIndex: 'versioned',
        key: 'versioned',
        width: 20,
        render: (_: any, resource: Resource) => {

          if (!!resource.version_changed) {
            return (
              <Tooltip
                placement="top"
                title={ 'This resource has been modified since the previous version' }
              >
                <InfoCircleOutlined className="fsz-def text-warning" />
              </Tooltip>
            );
          }

          return <></>;
        },
      },
      {
        title: formatMessage(messages.columnsInnerType),
        dataIndex: 'type',
        key: 'type',
        render: (_: any, resource: Resource) => renderTableCell(this.getResourceTypeLabel(resource.type)),
        width: 100,
      },
      {
        title: 'Record',
        key: 'record',
        dataIndex: 'record',
        render: (_: any, resource: Resource) => {
          if (!!resource.entity_preview.path) {
            return (
              <Link onClick={ () => history.push(resource.entity_preview.path) }>
                { resource.entity_preview.title }
              </Link>
            );
          }

          return resource.entity_preview.title || '';
        },
        width: 150,
      },
      {
        title: 'User',
        key: 'user',
        dataIndex: 'user',
        render: (__: any, resource: Resource) => {

          if (!_.isNull(resource.user.id)) {
            return (
              <Link onClick={ () => onProfileClick(resource.user.id) }>
                { resource.user.full_name }
              </Link>
            );
          }

          return <> { resource.user.full_name } </>;
        },
        width: 150,
      },
      {
        title: formatMessage(messages.columnsInnerCompany),
        key: 'company',
        dataIndex: 'company',
        render: (__: any, resource: Resource) => _.has(resource, 'user.company.title') ? renderTableCell(resource.user.company.title) : <>-</>,
        width: 150,
      },
      {
        title: 'Regions',
        key: 'regions',
        dataIndex: 'regions',
        render: (__: any, resource: Resource) => {

          if (!_.isEmpty(resource.regions)) {
            return (
              <Link onClick={ () => this.setState({ showRegionsModal: true, activeResourceRow: resource }) }>
                Regions
              </Link>
            );
          }

          return <>-</>;

        },
        width: 150,
      },
      {
        title: formatMessage(messages.columnsInnerStatus),
        dataIndex: 'status',
        key: 'status',
        width: 75,
        render: (_: any, resource: Resource) => {
          switch (resource.status.toUpperCase()) {
            case ResourceStatusKeys.Approved:
              return renderTableCell(
                <Badge type={ BadgeType.Success } text={ formatMessage(messages.approvedStatusLabel) } />
              );

            case ResourceStatusKeys.Pending:
              return renderTableCell(
                <Badge type={ BadgeType.Warning } text={ formatMessage(messages.pendingStatusLabel) } />
              );

            case ResourceStatusKeys.Rejected:
              return renderTableCell(
                <Badge type={ BadgeType.Danger } text={ formatMessage(messages.rejectedStatusLabel) } />
              );

            case ResourceStatusKeys.Requested:
              return renderTableCell(
                <Badge type={ BadgeType.Default } tooltip='A user has been requested' text={ formatMessage(messages.requestedStatusLabel) } />
              );

            default:
              return '';
          }
        },
      },
      {
        title: '',
        key: 'actions',
        width: 100,
        align: Alignment.Right,
        render: (__: any, resource: Resource) => {

          if (!canDelete && !canEdit && !canModerate) return <></>;
          if (resource.type !== ResourceType.Direct) return <></>;

          // Also hide if moderating is enabled, but resource is already active
          if((!canDelete && !canEdit) && !!canModerate && resource.status !== ResourceStatusKeys.Pending) return <></>;

          const options: any = this.renderResourceOptions(resourceRecord, resource);

          if (!_.isEmpty(options)) {
            return (
              <Dropdown overlay={ <Menu>{ options.map((option: any) => option) }</Menu> } trigger={ ['click'] }>
                <Button className='Resources-Table-Row-Button'>...</Button>
              </Dropdown>
            );
          }

          return (
            <Button disabled className='Resources-Table-Row-Button'>...</Button>
          );
        },
      },
    ];

    if (!showRegions) {
      columns = columns.filter((column) => column.key !== 'region');
    }

    return <Table size={ 'small' } columns={ columns } dataSource={ resourceRecord.resources } pagination={ false } />;
  };

  renderReplaceResourceModal = () => {
    const { canEdit, intl: { formatMessage }, getUsers, title } = this.props;
    const { replaceModal } = this.state;
    return (
      <Modal
        centered
        closable={false}
        visible
        onCancel={this.toggleReplaceModal}
        okText={formatMessage(messages.replaceLabel)}
        onOk={this.replaceRole}
        okButtonProps={{
          disabled: this.isReplaceResourceButtonDisabled() || !canEdit,
        }}
        confirmLoading={replaceModal.isLoading}
      >
        <div>
          <h3>
            {formatMessage(messages.replaceResourceTitle, {
              role: replaceModal.roleTitle ?? '',
              entity: title,
            })}
          </h3>
          <p>{formatMessage(messages.replaceResourceWarningMessage)}</p>
          <div className='d-f jc-sb mB-10 Resources-Table-Multi-Field-Container'>
            <label className='d-f fxd-c w-100p'>
              {formatMessage(messages.fromLabel)}
              <Select disabled value={replaceModal.oldUserId}>
                <Select.Option
                  key={`replace-user-from-${replaceModal?.oldUserId ?? 0}`}
                  value={replaceModal?.oldUserId ?? 0}
                  disabled
                >
                  {replaceModal?.oldUser?.full_name}
                </Select.Option>
              </Select>
            </label>
          </div>
          <div>
            <span>{formatMessage(messages.toLabel)}</span>
            <div className='d-f jc-sb fxd-c Resources-Table-Multi-Field-Container'>
              <label className='d-f fxd-c w-100p'>
                {formatMessage(messages.companyLabel)}
                <Select
                  value={replaceModal.companyId}
                  onChange={async (value: any) => {
                    if (replaceModal.recordId && value) {
                      this.setState((state: State) => ({
                        replaceModal: { ...state.replaceModal, usersAreLoading: true },
                      }));
                      const users = await getUsers(replaceModal.recordId, value);
                      this.setState((state: State) => ({
                        replaceModal: { ...state.replaceModal, usersAreLoading: false, users, company: value },
                      }));
                    }
                  }}
                  disabled={!replaceModal?.companies || replaceModal?.companies?.length === 0}
                  loading={replaceModal?.companiesAreLoading}
                >
                  {replaceModal?.companies?.map((company: any) => (
                    <Select.Option key={`replace-company-${company.id}`} value={company.id}>
                      {company.title}
                    </Select.Option>
                  ))}
                </Select>
              </label>
              <label className='d-f fxd-c w-100p'>
                {formatMessage(messages.userLabel)}
                <Select
                  value={replaceModal.newUserId}
                  onChange={(value: any) =>
                    this.setState((state: State) => ({ replaceModal: { ...state.replaceModal, newUserId: value } }))
                  }
                  disabled={!replaceModal?.users || replaceModal?.users?.length === 0}
                  loading={replaceModal?.usersAreLoading}
                >
                  {replaceModal?.users?.map((user: User) => {
                    if (user.id !== replaceModal.oldUserId) {
                      return (
                        <Select.Option key={`replace-user-from-${user.id}`} value={user.id}>
                          {user.full_name} - {user.email}
                        </Select.Option>
                      );
                    }
                  })}
                </Select>
              </label>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  renderRemoveResourceModal = () => {
    const { intl: { formatMessage }, roles } = this.props;
    const { removeModal } = this.state;

    const name = removeModal.user?.full_name || 'this user';
    const role = roles.find((role: RoleEntity) => role.id === removeModal.roleId);

    return (
      <Modal
        title={ 'Remove Resource' }
        closable={ false }
        maskClosable={ !removeModal.isLoading }
        centered
        visible
        onCancel={ () => this.setState({ showRemoveResourceModal: false }) }
        okText={ formatMessage(messages.removeLabel) }
        onOk={ this.handleRemoveRole }
        okButtonProps={{
          danger: true
        }}
        confirmLoading={ removeModal.isLoading }
      >
        <p>Are you sure you want to remove <b>{ name }</b> from <b>{ role ? role.title : 'this role' }</b>?</p>
      </Modal>
    );
  };

  renderChangeResourceModal = () => {
    const { intl: { formatMessage }, record, roles, regions } = this.props;
    const { changeResourceModal } = this.state;
    const showRegions = record.config.resource_region || false;
    return (
      <Modal
        centered
        closable={false}
        visible
        title={ formatMessage(messages.changeResourceTitle, { resource: changeResourceModal.user?.full_name }) }
        onCancel={ this.toggleChangeResourceModal }
        okText={ formatMessage(messages.updateLabel) }
        onOk={ this.changeResource }
        okButtonProps={{
          disabled: !changeResourceModal.newRoleId || _.isEmpty(changeResourceModal.regions),
        }}
        confirmLoading={changeResourceModal.isLoading}
      >
        <Form layout="vertical">
          <Form.Item label={ formatMessage(messages.userLabel) } required>
            <Select disabled value={changeResourceModal.user?.id}>
              { changeResourceModal.user && (
                <Select.Option
                  key={`remove-user-${changeResourceModal.user.id}`}
                  value={changeResourceModal.user.id}
                  disabled
                >
                  {changeResourceModal.user?.full_name}
                </Select.Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item label={ formatMessage(messages.roleLabel) } required>
            <Select
                value={ changeResourceModal.newRoleId }
                onChange={(value: any) =>
                  this.setState((state: State) => ({
                    changeResourceModal: { ...state.changeResourceModal, newRoleId: value },
                  }))
                }
              >
                {roles.map((role: any) => (
                  <Select.Option key={`remove-role-${role.id}`} value={role.id}>
                    {role.title}
                  </Select.Option>
                ))}
              </Select>
          </Form.Item>
          { showRegions &&
            <Form.Item label={ 'Regions' } required>
              <TreeSelect
                style={{
                  width: '100%',
                }}
                maxTagCount={ 3 }
                showCheckedStrategy={ SHOW_PARENT }
                treeCheckable
                treeData={ regions ? regionMap(regions) : [] }
                value={ changeResourceModal.regions || [] }
                onChange={(_regions: any) => {
                  this.setState({
                    changeResourceModal: {
                      ...changeResourceModal,
                      regions: _regions
                    }
                  });
                }}
              />
            </Form.Item>
          }
        </Form>
      </Modal>
    );
  };

  renderPrimaryResourceModal = () => {
    const { intl: { formatMessage } } = this.props;
    const { changePrimaryResourceModal } = this.state;

    return (
      <Modal
        title={ 'Update Primary Resource' }
        visible
        centered
        closable={ false }
        okText={ formatMessage(messages.updateLabel) }
        onCancel={ () => this.togglePrimaryResourceModal() }
        onOk={ () => this.makeResourcePrimary() }
        okButtonProps={{
          disabled: changePrimaryResourceModal.isLoading,
          loading: changePrimaryResourceModal.isLoading,
        }}
        cancelButtonProps={{
          disabled: changePrimaryResourceModal.isLoading,
        }}
      >
        <p>Are you sure you want to set <b>{ changePrimaryResourceModal.user?.full_name || 'this user' }</b> to primary resource?</p>
      </Modal>
    );
  };

  renderRejectResourceModal = () => {
    const { canEdit, intl: { formatMessage } } = this.props;
    const { rejectResourceModal } = this.state;
    return (
      <Modal
        centered
        closable={false}
        visible
        onCancel={this.toggleRejectResourceModal}
        okText={formatMessage(messages.rejectLabel)}
        onOk={this.rejectResource}
        okButtonProps={{
          disabled: this.isRejectCommentRequired() || !canEdit,
        }}
        confirmLoading={rejectResourceModal.isLoading}
      >
        <div>
          <h3>
            {formatMessage(messages.rejectResourceTitle, {
              user: rejectResourceModal.user?.full_name,
              role: rejectResourceModal.recordName,
            })}
          </h3>
          <label>
            {formatMessage(messages.rejectResourceCommentMessage)}
            <TextArea
              autoSize={{ minRows: 4 }}
              onChange={ (e: React.ChangeEvent<HTMLTextAreaElement>) => this.handleRejectCommentChange(e.target.value) }
              value={ rejectResourceModal.comment }
            />
          </label>
        </div>
      </Modal>
    );
  };

  renderAddResourceModal = () => {
    const { intl: { formatMessage }, record, getCompanies, getUsers, roles, regions } = this.props;
    const { addModal } = this.state;
    const showRegions = record.config.resource_region || false;
    return (
      <Modal
        centered
        visible
        title={ formatMessage(messages.addResourceTitle) }
        closable={ false }
        onCancel={ () => this.setState({ showAddResourceModal: false }) }
        okText={ formatMessage(messages.addLabel) }
        onOk={ this.addResource }
        okButtonProps={{
          disabled: !addModal.user || !addModal.role || (showRegions && _.isEmpty(addModal.regions)),
        }}
        cancelButtonProps={{
          disabled: addModal.isLoading
        }}
        confirmLoading={ addModal.isLoading }
      >
        <Form layout="vertical">
          <Form.Item label={ formatMessage(messages.roleLabel) } required>
            <Select
              showSearch
              placeholder={ 'Select Role' }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              onChange={(role: any) => {
                this.setState({
                  addModal: { companiesAreLoading: true }
                }, async () => {
                  const companies = await getCompanies(role);

                  // default if only one company
                  let companyId = null;
                  let users = null;
                  let user = null;

                  if (companies && companies.length === 1) {
                    companyId = companies[0].id;

                    // default if only one user
                    users = await getUsers(role, companyId);
                    if (users && users.length === 1) {
                      user = users[0];
                    }
                  }

                  this.setState({
                    addModal: {
                      companiesAreLoading: false,
                      companies: companies,
                      role: role,
                      company: companyId,
                      users: users,
                      user: user,
                    },
                  });
                });
              }}
              value={ addModal.role }
            >
              {roles && roles.map((role: any) => (
                <Select.Option key={`add-role-${role.id}`} value={role.id}>
                  {role.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={ formatMessage(messages.companyLabel) } required>
            <Select
              showSearch
              placeholder={ 'Select Company' }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              onChange={(companyId: number) => {
                this.setState({
                  addModal: {
                    usersAreLoading: true,
                    companies: addModal.companies,
                    role: addModal.role,
                  }
                }, async () => {
                  if (!!addModal.role && companyId) {

                    const users = await getUsers(addModal.role, companyId);
                    let user = null;

                    if (users && users.length === 1) {
                      user = users[0];
                    }

                    this.setState({
                      addModal: {
                        usersAreLoading: false,
                        companies: addModal.companies,
                        role: addModal.role,
                        company: companyId,
                        users: users,
                        user: user
                      }
                    });
                  }
                });

              } }
              value={ addModal.company }
              loading={ addModal?.companiesAreLoading || false }
              disabled={ !addModal.companies || _.isEmpty(addModal.companies)}
            >
              { addModal.companies && addModal.companies.map((company: any) => (
                <Select.Option key={`add-company-${company.id}`} value={company.id}>
                  { company.title }
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={ formatMessage(messages.userLabel) } required>
            <Select
              showSearch
              placeholder={ !addModal?.users || addModal?.users?.length === 0 ? 'No Users Found' : 'Select User' }
              filterOption={ (input: any, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              } }
              onChange={(user_id: number) => {
                const user = addModal.users && addModal.users.find(user => user.id === user_id);
                if (!!user) {
                  this.setState({
                    addModal: {
                      usersAreLoading: false,
                      companies: addModal.companies,
                      role: addModal.role,
                      company: addModal.company,
                      users: addModal.users,
                      user: user,
                    }
                  });
                }
              }}
              value={ addModal.user?.id || undefined }
              loading={ addModal?.usersAreLoading }
              disabled={ !addModal?.users || addModal?.users?.length === 0 }
            >
              {(addModal?.users && Array.isArray(addModal?.users)) && addModal?.users?.map((user: User) => (
                <Select.Option key={`add-user-${user.id}`} value={user.id}>
                  { `${user.full_name} - ${user.email}` }
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          { showRegions &&
            <Form.Item label={ 'Region' } required>
              <TreeSelect
                style={{
                  width: '100%',
                }}
                maxTagCount={ 3 }
                showCheckedStrategy={ SHOW_PARENT }
                treeCheckable
                treeData={ regions ? regionMap(regions) : [] }
                disabled={ !addModal.user }
                value={ addModal.regions || [] }
                onChange={(_regions: any) => {
                  this.setState({
                    addModal: {
                      ...addModal,
                      regions: _regions
                    }
                  });
                }}
              />
            </Form.Item>
          }
        </Form>
      </Modal>
    );
  };

  renderRegionModal = (resourceRow: Resource) => {
    const { regions } = this.props;
    const { resources } = this.state;
    const role = resources.find(role => role.id === resourceRow.role_id);
    const resource = role && role.resources && role.resources.find((resource: any) => resource.id === resourceRow.id);
    const activeRegions = resource && resource.regions && resource.regions.map((region: any) => region.id);

    return (
      <CoverModal
        closeOnTop
        style={{ minWidth: 500, minHeight: 600, maxHeight: '70vh' }}
        middleContent={ 'Regions' }
        onClose={ () => this.setState({ showRegionsModal: false }) }
      >
        <Tree
          className="Resources-Table-Tree m-20 bg-grey-100"
          disabled
          checkable
          selectable={ false }
          defaultExpandedKeys={ activeRegions || [] }
          defaultCheckedKeys={ activeRegions || [] }
          treeData={ regions ? regionMap(regions) : [] }
        />
      </CoverModal>
    );
  };

  getData = () => {
    const {
      currentPage,
      resourcesPerPage,
      sorter,
      filters,
      nestedFilters,
      formattedResources,
    } = this.state;

    let filteredResources = formattedResources;

    // Filter
    if (!_.isEmpty(filters)) {
      filteredResources = this.filter(filteredResources, filters);
    }

    // Nested filters
    if (!_.isEmpty(nestedFilters)) {
      filteredResources = this.nestedFilter(filteredResources, nestedFilters);
    }

    // Sort data
    if (sorter) {
      filteredResources = this.sortData(filteredResources, sorter);
    }

    // Paginate items
    const paginatedData = this.paginateResources(filteredResources || [], currentPage, resourcesPerPage);

    return { filteredResources, paginatedData };
  };

  render = () => {
    const { intl: { formatMessage } } = this.props;
    const {
      currentPage,
      resourcesPerPage,
      showFilter,
      filters,
      columns,
      formattedResources,
      showAddResourceModal,
      showChangeResourceModal,
      showPrimaryResourceModal,
      showRejectResourceModal,
      showRemoveResourceModal,
      showReplaceResourceModal,
      showRegionsModal,
      activeResourceRow,
    } = this.state;

    const { filteredResources, paginatedData } = this.getData();

    return (
      <>
        <div className='d-f jc-sb ai-c mB-10' style={{ userSelect: 'none' }}>
          <div className='d-if mL-10'>
            <span>{formatMessage(messages.show)}</span>
            <span className='mL-10 mR-10'>
              <Select
                size={'small'}
                onChange={(value: number) => {
                  this.setState({
                    currentPage: 1,
                    resourcesPerPage: value,
                  });
                }}
                defaultValue={resourcesPerPage}
              >
                <Option value={ 25 }>25</Option>
                <Option value={ 50 }>50</Option>
                <Option value={ 100 }>100</Option>
              </Select>
            </span>
            <span>
              {formatMessage(messages.entries_of)} <b>{filteredResources.length}</b>
            </span>
            <span
              className={classNames('link mL-35', {
                active: showFilter || !_.isEmpty(filters),
              })}
              onClick={() => {
                this.setState({
                  showSort: false,
                  showFilter: !showFilter,
                });
              }}
            >
              <Icon component={FilterIcon} />
              <span> {formatMessage(messages.filter)}</span>
            </span>
          </div>
          <div className='d-if'>
            <div className='mR-10'>
              <Pagination
                showSizeChanger={ false }
                current={ currentPage }
                total={ filteredResources.length }
                pageSize={ resourcesPerPage }
                onChange={ (page) => {
                  this.setState({
                    currentPage: page,
                  });
                } }
              />
            </div>
            { this.props.canCreate &&
              <Button
                onClick={ () => {
                  this.toggleAddModal();
                  this.openAddModal();
                } }
              >
                <span className='pR-10 Resources-Table-Add-Button-Icon'>+</span>
                <span>{ formatMessage(messages.addResourcesButtonLabel) }</span>
              </Button>
            }
          </div>
        </div>
        <div>{ showFilter && this.renderFilters(columns, formattedResources) }</div>
        <div className='Layout-box'>
          <Table
            size={ 'small' }
            className="Resources-Table cur-p"
            columns={ columns }
            dataSource={ paginatedData }
            onChange={ this.handleSort }
            pagination={ false }
            expandable={ {
              expandedRowKeys: this.state.expandedRowKeys,
              expandRowByClick: true,
              onExpand: (expanded: boolean, row: any) => {
                if (expanded) {
                  this.setState({ expandedRowKeys: [...this.state.expandedRowKeys, row.key] });
                } else {
                  this.setState({ expandedRowKeys: !_.isEmpty(this.state.expandedRowKeys) ? this.state.expandedRowKeys.filter((rowKey) => rowKey !== row.key) : this.state.expandedRowKeys });
                }
              },
              rowExpandable: (record: ResourceRecord) => !_.isEmpty(record.resources),
              expandedRowRender: (record: ResourceRecord) => this.renderDetailedRow(record)
            } }
          />
        </div>

        { showReplaceResourceModal && this.renderReplaceResourceModal() }
        { showRemoveResourceModal && this.renderRemoveResourceModal() }
        { showChangeResourceModal && this.renderChangeResourceModal() }
        { showPrimaryResourceModal && this.renderPrimaryResourceModal() }
        { showRejectResourceModal && this.renderRejectResourceModal() }
        { showAddResourceModal && this.renderAddResourceModal() }
        { showRegionsModal && activeResourceRow && this.renderRegionModal(activeResourceRow) }
      </>
    );
  };
}

export default injectIntl(withRouter(ResourcesTable));
