// Libs
import * as React from 'react';
import _ from 'lodash';

// Components
import Icon from '@ant-design/icons';
import { Comment as AntCommentComponent, Tooltip, Button, Typography } from 'antd';

// Icons
import { ReactComponent as AvatarIcon } from 'assets/svg/avatar.svg';

// Interfaces
import { Comment } from './Comment.interface';

// Services
import { getFormatedDate } from 'services/settings';

// Styles
import './Comment.scss';

const { Text, Link } = Typography;

interface Props {
  currentUserId: number,
  comments: Comment[],
  actions: React.ReactNode[],
  onProfileClick(userId: number): void;
};

function renderComment(currentUserId: number, comments: Comment[], actions: React.ReactNode[], onProfileClick: (userId: number) => void, depth: number = 0) {
  return comments.map((comment: Comment) => {

    const filteredActions = actions.filter((action: any) => {

      // Overrides for admin roles
      if ((action.type === 'edit' && !!comment.can_edit) || (action.type === 'remove' && !!comment.can_remove)) {
        return true;
      }

      // Remove action if disabled
      if (_.has(action, 'disabled') && !!action.disabled) {
        return false;
      }

      return action.type === 'reply' || (((action.type === 'edit' || action.type === 'remove') && comment.user.id === currentUserId));
    });

    return (
      <AntCommentComponent
        key={ comment.id }
        actions={ filteredActions.map((action: any) => {

          let content = <Button className="pL-0 pR-5" onClick={ () => action.onClick(comment) } type="link">{ action.node }</Button>;
          let dot = null;

          // Disable reply
          if (action.type === 'reply' && depth > 1) {
            content = (
              <Tooltip placement={ 'top' } title={ 'You cannot reply to this comment' }>
                <Text className="mR-5" style={{ fontSize: 14 }} type="secondary" disabled>{ action.node }</Text>
              </Tooltip>
            );
          }

          // Add a dot seperator
          if (filteredActions.indexOf(action) !== filteredActions.length - 1) {
            dot = <span>&#8226;</span>;
          }

          return <>{content} { dot }</>;
        }) }
        author={
          <Link onClick={ () => onProfileClick(comment.user.id) }>{ comment.user.full_name }</Link>
        }
        avatar={
          <Icon style={{ fontSize: '25px' }} component={ AvatarIcon } />
        }
        content={
          <p>{ comment.message }</p>
        }
        datetime={ getFormatedDate(comment.created_at, undefined, true) }
      >
        { !_.isEmpty(comment.children) && renderComment(currentUserId, comment.children, actions, onProfileClick, depth+1) }
      </AntCommentComponent>
    );
  });
};

export default function CommentComponent({currentUserId, comments, actions, onProfileClick }: Props) {
  return (
    <div className="Comment">
      { renderComment(currentUserId, comments, actions, onProfileClick) }
    </div>
  );
};