// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Components
import Jumbotron from 'components/jumbotron';
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import BasicListView from 'components/basic-list';
import NumberFormat from 'react-number-format';

// Services
import { Api } from 'services/api';
import { getNumberFormatProps, getUserSetting } from 'services/settings';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { Entity } from './Entity.interfaces';
import { UserPermissions } from 'types/permissions';

// Utils
import history from 'utils/history';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

export const ENTITY_BUNDLES = ['record', 'category', 'company', 'help_desk_ticket', 'transaction', 'audit', 'action'];

interface Props {
  client_id: number;
  intl: IntlShape;
  permissions: UserPermissions;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  entities: Entity[];
  isLoading: boolean;
};

class Entities extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    entities: [],
    isLoading: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.handleFetch();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.match.params.entity_bundle !== prevProps.match.params.entity_bundle) {
      this.handleFetch();
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  handleFetch = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const entity_bundle = this.props.match.params.entity_bundle;

    try {

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Record Settings', path: '/admin/record-settings' },
        { title: this.generateTabLabel(entity_bundle), path: null },
      ], false);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const bundle = _.snakeCase(entity_bundle);
      const entities = await API.get(`client/${client_id}/admin/entity/bundle/${bundle}`);

      this.mounted && this.setState({
        entities: entities
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  handleTabChange = (tab: string) => {
    history.push(_.kebabCase(tab.toLowerCase()));
  };

  generateTabLabel = (bundle: string) => {
    switch (_.snakeCase(bundle.toLowerCase())) {
      case 'record':
        return 'Records';
      case 'category':
        return 'Categories';
      case 'company':
        return 'Companies';
      case 'help_desk_ticket':
        return 'Help Desk';
      case 'transaction':
        return 'Transactions';
      case 'audit':
        return 'Audits';
      case 'action':
        return 'Actions';
      default:
        return 'Tab label not found';
    }
  };

  renderEntities = (bundle: string) => {
    const { entities, isLoading } = this.state;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const columns = [
      {
        key: 'label',
        dataIndex: 'label',
        title: 'Label',
        render: (label: string, record: Entity) => <Link to={ `/admin/record-settings/${bundle}/${record.type}` } className="primaryColor">{ label }</Link>,
        sorter: true,
        ellipsis: true,
        filterable: true,
      },
      {
        key: 'count',
        dataIndex: 'count',
        title: 'Number of Records',
        sorter: true,
        ellipsis: true,
        filterable: false,
        width: 200,
        render: (count: number) => {
          return (
            <div className="ta-r">
              <NumberFormat
                { ...getNumberFormatProps(getUserSetting('number_format'), 0) }
                value={ count }
                displayType={ 'text' }
              />
            </div>
          );
        },
      }
    ];

    const items = entities.map((entity: Entity, index: number) => {
      return {
        'key': index,
        'label': entity.label,
        'type': entity.type,
        'bundle': entity.bundle,
        'count': entity.count,
      };
    });

    return (
      <BasicListView
        rawData
        columns={ columns }
        items={ items }
      />
    );
  };

  render = () => {
    const tabs = ENTITY_BUNDLES.map((bundle: string) => {
      return {
        key: bundle,
        label: this.generateTabLabel(bundle),
        node: this.renderEntities(bundle)
      };
    });

    return (
      <Jumbotron
        content={ 'Record Settings' }
        tabs={ tabs }
        defaultActiveTab={ this.props.match.params.entity_bundle }
        onChange={ (tab: string) => this.handleTabChange(tab) }
      />
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(Entities), 'access_admin_entity_config'));