// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Button, Row, Col, Tooltip, Popover } from 'antd';
import Dropdown from 'components/dropdown';

// Interfaces
import { Action as DropdownAction } from 'components/dropdown';
import { FormConfig } from 'types/entities';

// Icons
import Icon from '@ant-design/icons';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';
import { UpOutlined, DownOutlined, CloseOutlined, LockOutlined } from '@ant-design/icons';

// Styles
import './ExternalControls.scss';

enum ButtonTypes {
  Danger = 'danger',
  Primary = 'primary',
  Warning = 'warning',
};

interface Props {
  fieldErrorMessages: any;
  hasErrors: boolean;
  isModified: boolean;
  isNew: boolean;
  scrollToField(tabId: number, groupId: number, fieldId: string): void;
  actions: DropdownAction[];
  floatingControls?: boolean | undefined;
  formConfig?: FormConfig;
};

const getButtonType = (fieldErrorMessages: any[], hasErrors: boolean, isModified: boolean): ButtonTypes => {
  if (hasErrors && !_.isEmpty(fieldErrorMessages)) {
    return ButtonTypes.Danger;
  } else if (isModified) {
    return ButtonTypes.Warning;
  }

  return ButtonTypes.Primary;
};

const ExternalControls: React.FC<Props> = ({
  hasErrors,
  isModified,
  isNew,
  actions,
  fieldErrorMessages,
  scrollToField,
  floatingControls = true,
  formConfig,
}) => {

  const [activeScrollIndex, setActiveScrollIndex] = React.useState<number | null>(null);
  const buttonType = getButtonType(fieldErrorMessages, hasErrors, isModified);
  const showInfoBoxButton = shouldShowInfoButton();
  const fieldErrorKeys = Object.keys(fieldErrorMessages);

  React.useEffect(() => {
    if (_.isEmpty(fieldErrorMessages) && activeScrollIndex !== null) {
      setActiveScrollIndex(null);
    }
  }, [fieldErrorMessages]);

  function shouldShowInfoButton() {
    if (!isNew) {
      return !!hasErrors || !!isModified;
    }

    return false;
  };

  const scrollToIndex = (newIndex: number) => {
    const keys = Object.keys(fieldErrorMessages);
    let jumpIndex = newIndex;

    if (newIndex > (keys.length - 1)) { // Restart loop
      jumpIndex = 0;
    } else if (newIndex < 0) { // Jump to top
      jumpIndex = keys.length - 1;
    }

    const errorField = fieldErrorMessages[keys[jumpIndex]];
    scrollToField(errorField.tab, errorField.group, errorField.id);
    setActiveScrollIndex(jumpIndex);
  };

  const controls: React.ReactNode[] = [];

  if (!formConfig?.can_edit?.status) {
    controls.push(
      <Tooltip
        className='mR-5'
        placement="right"
        title={ formConfig?.can_edit?.tooltip || 'You do not have permission to change this record' }
      >
        <Button
          style={{
            padding: '4px 7px',
            width: '32px',
          }}
          disabled
        >
          <LockOutlined />
        </Button>
      </Tooltip>
    );
  }

  if (showInfoBoxButton) {
    controls.push(
      <Tooltip
        placement="top"
        title={ hasErrors ? 'Please resolve all errors' : 'You have unsaved changes' }
      >
        <Popover
          visible={ buttonType === ButtonTypes.Danger && activeScrollIndex !== null }
          placement='leftTop'
          content={ () => {

            const key = activeScrollIndex !== null && fieldErrorKeys[activeScrollIndex];
            const activeErrorField = key && fieldErrorMessages[key];

            return (
              <div
                className="d-f jc-sb ai-c"
                style={{ width: 300, height: 30 }}
              >
                <div className="d-f text-danger">
                  { activeErrorField ? `${activeErrorField.content.label} Field` : '' }
                </div>
                <div className="d-f ai-c">
                  <div style={{ marginRight: 15 }}>
                    { activeScrollIndex !== null && `${activeScrollIndex + 1}/${fieldErrorKeys.length}` }
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <div style={{ height: 30, borderRight: '1px solid black' }} className='border-antd' />
                  </div>
                  <div>
                    <span style={{ marginRight: 15 }}>
                      <UpOutlined
                        className="fsz-xs link cur-p"
                        onClick={ () => {
                          scrollToIndex((activeScrollIndex || 0) - 1);
                        } }
                      />
                    </span>
                    <span style={{ marginRight: 15 }}>
                      <DownOutlined
                        className="fsz-xs link cur-p"
                        onClick={ () => {
                          scrollToIndex((activeScrollIndex || 0) + 1);
                        } }
                      />
                    </span>
                    <span>
                      <CloseOutlined
                        className="fsz-xs link cur-p"
                        onClick={ () => {
                          setActiveScrollIndex(null);
                        } }
                      />
                    </span>
                  </div>
                </div>
              </div>
            );
          } }
          trigger="click"
        >
          <Button
            type={ 'primary' }
            className={ classNames('ExternalControls__button ExternalControls__button--info ExternalControls__button--with-spacing', {
              'ExternalControls__button--warning': buttonType === ButtonTypes.Warning,
              'cur-p': buttonType === ButtonTypes.Danger
            }) }
            danger={ buttonType === ButtonTypes.Danger }
            onClick={ () => {
              if (buttonType === ButtonTypes.Danger) {
                if (activeScrollIndex === null) {
                  scrollToIndex(0);
                }
                setActiveScrollIndex(activeScrollIndex !== null ? null : 0);
              }
            } }
          >
            <Icon component={InfoIcon} />
          </Button>
        </Popover>
      </Tooltip>
    );
  }

  if (!_.isEmpty(actions)) {
    controls.push(
      <Dropdown actions={ actions } />
    );
  }

  return (
    <Row className="ExternalControls" style={ floatingControls ? { position: 'fixed', right: 30, zIndex: 10 } : {}}>
      <Col flex={ 1 } />
      <Col>
        <div className="d-if ai-c">
          { controls.map((control: React.ReactNode, index: number) => (
            <span key={ index }>{ control }</span>
          ) ) }
        </div>
      </Col>
    </Row>
  );
};

export default ExternalControls;
