enum UserActionTypes {
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_FAILURE = 'GET_USER_FAILURE',
  AUTHENTICATION_START = 'AUTHENTICATION_START',
  AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS',
  AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',
  SESSION_EXPIRATION = 'SESSION_EXPIRATION',
  UPDATED_USER_SETTINGS = 'UPDATED_USER_SETTINGS',
};

export default UserActionTypes;