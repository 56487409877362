// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import AdvancedList from "components/advanced-list";
import Jumbotron from 'components/jumbotron';
import Badge, { BadgeType } from 'components/badge';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

// Actions
import { setBreadcrumbs, setBreadcrumbsLoading } from 'store/UI/ActionCreators';

// Interfaces
import { UserEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  match: { params: Record<string, any> };
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  report: any;
  isFetching: boolean;
};

class Reports extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    report: null,
    isFetching: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.getReport();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.params.report_id !== this.props.match.params.report_id) {
      this.getReport();
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getReport = async () => {

    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Insights', path: '/insights' }
    ], false);

    try {

      if (this.props.match.params.report_id && this.props.client_id) {

        await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

        this.props.setBreadcrumbsLoading(true);

        const report = await API.get(`client/${this.props.client_id}/report/${this.props.match.params.report_id}`);

        this.mounted && this.setState({
          report: report
        }, () => {
          this.props.setBreadcrumbs([
            { title: 'Home', path: '/' },
            { title: 'Insights', path: '/insights' },
            { title: report.title, path: null },
          ], false);
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.setState({
        isFetching: false
      });
    }
  };

  filterReport = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    try {
      const report = await API.get(`client/${this.props.client_id}/report/${this.props.match.params.report_id}`, {
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      this.setState({
        report: report
      }, () => {
        callback(true);
      });
    } catch (error) {
      callback(false);
      Notification('error', 'Filter Failed');
    }
  };

  exportFile = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { report } = this.state;
    try {
      await API.download(`client/${this.props.client_id}/report/${this.props.match.params.report_id}/export`, `pacs_report_${_.snakeCase(report.title)}_${moment().format('YYYY-MM-DD')}.xlsx`, {
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      callback(true);
    } catch (error) {
      callback(false);
      Notification('error', 'Export Failed');
    }
  };

  renderListView = (report: any) => {
    return (
      <AdvancedList
        clientId={ this.props.client_id }
        filters={ report.filters }
        columns={ report.columns }
        items={ report.data }
        config={ report.config }
        onExport={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.exportFile(filters, currency, measurement, callback) }
        onFilter={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.filterReport(filters, currency, measurement, callback) }
      />
    );
  };

  render = () => {
    const { report, isFetching } = this.state;

    if (isFetching || report === null) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    return (
      <Jumbotron
        content={
          <>
            <p className="fsz-lg fw-600 mB-0">
              { report.title || 'Report' } <Badge className="mL-5" type={ BadgeType.Disabled } text={ 'Report' } />
            </p>
            <p className="mT-5 mB-0" style={{ fontSize: 12 }}>
              { report.description || '' }
            </p>
          </>
        }
        tabs={ [
          {
            label: 'Details',
            node: this.renderListView(report),
          },
          {
            label: 'Chart',
            node: <></>,
            disabled: true,
          }
        ] }
      />
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    user: store.UserState.user,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
