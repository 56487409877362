// Libs
import * as React from 'react';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';

// Services
import { validEmail } from 'utils/utils';
import history from 'utils/history';
import { Api } from "services/api";
import Notification from 'services/notification';

const API: Api = new Api();

interface Props {
}

interface State {
  isLoading: boolean;
};

class RequestToken extends React.Component<Props, State> {

  mounted: boolean = false;

  state = {
    isLoading: false,
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleRequestToken = (values: any) => {
    this.setState(
      {
        isLoading: true,
      }, () => {

        const email = values.email;

        API.requestPasswordToken(email)
          .then((response: any) => {
            Notification('success', `We've sent an email with instructions for the password reset to ${email}. Please check your email now and don't forget to check the spam folder.`, 'Request successful');
            history.push('/login');
          })
          .catch((err: any) => {
            Notification('error', 'Something went wrong', 'Failed');
            console.error(err);
          })
          .finally(() => this.mounted && this.setState({
            isLoading: false
          }));

      }
    );
  };

  render = () => {
    return (
      <>
        <div className="mB-40">
          <h1 className="ta-c">Request password change</h1>
          <p className="ta-c">Enter the email used to create your account. We'll send you an email with instructions.</p>
        </div>
        <Form
          className="auth-form"
          layout="vertical"
          onFinish={ this.handleRequestToken }
        >
          <Form.Item
            name="email"
            rules={[
              {
                validator: (_, value) => validEmail(value) ? Promise.resolve() : Promise.reject('Not a valid email address')
              }
            ]}
          >
            <Input placeholder="e.g. user@email.com" />
          </Form.Item>
          <Form.Item>
            <div className="ta-c">
              <Button
                block
                type="primary"
                className="login-form-button"
                htmlType="submit"
                disabled={this.state.isLoading}
                loading={this.state.isLoading}
              >
                Send Password Reset Link
              </Button>
            </div>
          </Form.Item>
          <Form.Item>
            <div className="ta-c">
              <span>Already got an account?</span> <Link to="/login">Login here</Link>
            </div>
          </Form.Item>
        </Form>
      </>
    );
  };

};

export default RequestToken;