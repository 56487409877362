export interface AssetMaintenanceElement {
  id: number;
  title: string;
  parent_id: number;
  path: string;
  order: number;
  created_at: Date;
  updated_at: Date;
  config: { [key: string]: boolean };
  status: string;
  bundle: string;
  type: Type;
};

export interface AssetType extends AssetMaintenanceElement {
  type: Type.AssetType;
  service_category: ServiceCategory;
  asset_type_id: string;
};

export interface AssetCategory extends AssetMaintenanceElement {
  type: Type.AssetCategory;
  children: AssetType[];
};

export enum Type {
  AssetCategory = 'asset_category',
  AssetType = 'asset_type',
};

export interface ServiceCategory {
  target_id: number;
  target_type: string;
  target_bundle: string;
  target_title: string;
  target_path: string;
  target_reference: string;
};