// Libs
import * as React from 'react';

// Icons
import Icon, { FlagOutlined } from '@ant-design/icons';
import { ReactComponent as AdIcon } from 'assets/svg/flags/ad.svg';
import { ReactComponent as AeIcon } from 'assets/svg/flags/ae.svg';
import { ReactComponent as AfIcon } from 'assets/svg/flags/af.svg';
import { ReactComponent as AgIcon } from 'assets/svg/flags/ag.svg';
import { ReactComponent as AiIcon } from 'assets/svg/flags/ai.svg';
import { ReactComponent as AlIcon } from 'assets/svg/flags/al.svg';
import { ReactComponent as AmIcon } from 'assets/svg/flags/am.svg';
import { ReactComponent as AoIcon } from 'assets/svg/flags/ao.svg';
import { ReactComponent as AqIcon } from 'assets/svg/flags/aq.svg';
import { ReactComponent as ArIcon } from 'assets/svg/flags/ar.svg';
import { ReactComponent as AsIcon } from 'assets/svg/flags/as.svg';
import { ReactComponent as AtIcon } from 'assets/svg/flags/at.svg';
import { ReactComponent as AuIcon } from 'assets/svg/flags/au.svg';
import { ReactComponent as AwIcon } from 'assets/svg/flags/aw.svg';
import { ReactComponent as AxIcon } from 'assets/svg/flags/ax.svg';
import { ReactComponent as AzIcon } from 'assets/svg/flags/az.svg';
import { ReactComponent as BaIcon } from 'assets/svg/flags/ba.svg';
import { ReactComponent as BbIcon } from 'assets/svg/flags/bb.svg';
import { ReactComponent as BdIcon } from 'assets/svg/flags/bd.svg';
import { ReactComponent as BeIcon } from 'assets/svg/flags/be.svg';
import { ReactComponent as BfIcon } from 'assets/svg/flags/bf.svg';
import { ReactComponent as BgIcon } from 'assets/svg/flags/bg.svg';
import { ReactComponent as BhIcon } from 'assets/svg/flags/bh.svg';
import { ReactComponent as BiIcon } from 'assets/svg/flags/bi.svg';
import { ReactComponent as BjIcon } from 'assets/svg/flags/bj.svg';
import { ReactComponent as BlIcon } from 'assets/svg/flags/bl.svg';
import { ReactComponent as BmIcon } from 'assets/svg/flags/bm.svg';
import { ReactComponent as BnIcon } from 'assets/svg/flags/bn.svg';
import { ReactComponent as BoIcon } from 'assets/svg/flags/bo.svg';
import { ReactComponent as BqIcon } from 'assets/svg/flags/bq.svg';
import { ReactComponent as BrIcon } from 'assets/svg/flags/br.svg';
import { ReactComponent as BsIcon } from 'assets/svg/flags/bs.svg';
import { ReactComponent as BtIcon } from 'assets/svg/flags/bt.svg';
import { ReactComponent as BvIcon } from 'assets/svg/flags/bv.svg';
import { ReactComponent as BwIcon } from 'assets/svg/flags/bw.svg';
import { ReactComponent as ByIcon } from 'assets/svg/flags/by.svg';
import { ReactComponent as BzIcon } from 'assets/svg/flags/bz.svg';
import { ReactComponent as CaIcon } from 'assets/svg/flags/ca.svg';
import { ReactComponent as CcIcon } from 'assets/svg/flags/cc.svg';
import { ReactComponent as CdIcon } from 'assets/svg/flags/cd.svg';
import { ReactComponent as CfIcon } from 'assets/svg/flags/cf.svg';
import { ReactComponent as CgIcon } from 'assets/svg/flags/cg.svg';
import { ReactComponent as ChIcon } from 'assets/svg/flags/ch.svg';
import { ReactComponent as CiIcon } from 'assets/svg/flags/ci.svg';
import { ReactComponent as CkIcon } from 'assets/svg/flags/ck.svg';
import { ReactComponent as ClIcon } from 'assets/svg/flags/cl.svg';
import { ReactComponent as CmIcon } from 'assets/svg/flags/cm.svg';
import { ReactComponent as CnIcon } from 'assets/svg/flags/cn.svg';
import { ReactComponent as CoIcon } from 'assets/svg/flags/co.svg';
import { ReactComponent as CrIcon } from 'assets/svg/flags/cr.svg';
import { ReactComponent as CuIcon } from 'assets/svg/flags/cu.svg';
import { ReactComponent as CvIcon } from 'assets/svg/flags/cv.svg';
import { ReactComponent as CwIcon } from 'assets/svg/flags/cw.svg';
import { ReactComponent as CxIcon } from 'assets/svg/flags/cx.svg';
import { ReactComponent as CyIcon } from 'assets/svg/flags/cy.svg';
import { ReactComponent as CzIcon } from 'assets/svg/flags/cz.svg';
import { ReactComponent as DeIcon } from 'assets/svg/flags/de.svg';
import { ReactComponent as DjIcon } from 'assets/svg/flags/dj.svg';
import { ReactComponent as DkIcon } from 'assets/svg/flags/dk.svg';
import { ReactComponent as DmIcon } from 'assets/svg/flags/dm.svg';
import { ReactComponent as DoIcon } from 'assets/svg/flags/do.svg';
import { ReactComponent as DzIcon } from 'assets/svg/flags/dz.svg';
import { ReactComponent as EcIcon } from 'assets/svg/flags/ec.svg';
import { ReactComponent as EeIcon } from 'assets/svg/flags/ee.svg';
import { ReactComponent as EgIcon } from 'assets/svg/flags/eg.svg';
import { ReactComponent as EhIcon } from 'assets/svg/flags/eh.svg';
import { ReactComponent as ErIcon } from 'assets/svg/flags/er.svg';
import { ReactComponent as EsIcon } from 'assets/svg/flags/es.svg';
import { ReactComponent as EsCtIcon } from 'assets/svg/flags/es-ct.svg';
import { ReactComponent as EsGaIcon } from 'assets/svg/flags/es-ga.svg';
import { ReactComponent as EtIcon } from 'assets/svg/flags/et.svg';
import { ReactComponent as EuIcon } from 'assets/svg/flags/eu.svg';
import { ReactComponent as FiIcon } from 'assets/svg/flags/fi.svg';
import { ReactComponent as FjIcon } from 'assets/svg/flags/fj.svg';
import { ReactComponent as FkIcon } from 'assets/svg/flags/fk.svg';
import { ReactComponent as FmIcon } from 'assets/svg/flags/fm.svg';
import { ReactComponent as FoIcon } from 'assets/svg/flags/fo.svg';
import { ReactComponent as FrIcon } from 'assets/svg/flags/fr.svg';
import { ReactComponent as GaIcon } from 'assets/svg/flags/ga.svg';
import { ReactComponent as GbIcon } from 'assets/svg/flags/gb.svg';
import { ReactComponent as GbEngIcon } from 'assets/svg/flags/gb-eng.svg';
import { ReactComponent as GbNirIcon } from 'assets/svg/flags/gb-nir.svg';
import { ReactComponent as GbSctIcon } from 'assets/svg/flags/gb-sct.svg';
import { ReactComponent as GbWlsIcon } from 'assets/svg/flags/gb-wls.svg';
import { ReactComponent as GdIcon } from 'assets/svg/flags/gd.svg';
import { ReactComponent as GeIcon } from 'assets/svg/flags/ge.svg';
import { ReactComponent as GfIcon } from 'assets/svg/flags/gf.svg';
import { ReactComponent as GgIcon } from 'assets/svg/flags/gg.svg';
import { ReactComponent as GhIcon } from 'assets/svg/flags/gh.svg';
import { ReactComponent as GiIcon } from 'assets/svg/flags/gi.svg';
import { ReactComponent as GlIcon } from 'assets/svg/flags/gl.svg';
import { ReactComponent as GmIcon } from 'assets/svg/flags/gm.svg';
import { ReactComponent as GnIcon } from 'assets/svg/flags/gn.svg';
import { ReactComponent as GpIcon } from 'assets/svg/flags/gp.svg';
import { ReactComponent as GqIcon } from 'assets/svg/flags/gq.svg';
import { ReactComponent as GrIcon } from 'assets/svg/flags/gr.svg';
import { ReactComponent as GsIcon } from 'assets/svg/flags/gs.svg';
import { ReactComponent as GtIcon } from 'assets/svg/flags/gt.svg';
import { ReactComponent as GuIcon } from 'assets/svg/flags/gu.svg';
import { ReactComponent as GwIcon } from 'assets/svg/flags/gw.svg';
import { ReactComponent as GyIcon } from 'assets/svg/flags/gy.svg';
import { ReactComponent as HkIcon } from 'assets/svg/flags/hk.svg';
import { ReactComponent as HmIcon } from 'assets/svg/flags/hm.svg';
import { ReactComponent as HnIcon } from 'assets/svg/flags/hn.svg';
import { ReactComponent as HrIcon } from 'assets/svg/flags/hr.svg';
import { ReactComponent as HtIcon } from 'assets/svg/flags/ht.svg';
import { ReactComponent as HuIcon } from 'assets/svg/flags/hu.svg';
import { ReactComponent as IdIcon } from 'assets/svg/flags/id.svg';
import { ReactComponent as IeIcon } from 'assets/svg/flags/ie.svg';
import { ReactComponent as IlIcon } from 'assets/svg/flags/il.svg';
import { ReactComponent as ImIcon } from 'assets/svg/flags/im.svg';
import { ReactComponent as InIcon } from 'assets/svg/flags/in.svg';
import { ReactComponent as IoIcon } from 'assets/svg/flags/io.svg';
import { ReactComponent as IqIcon } from 'assets/svg/flags/iq.svg';
import { ReactComponent as IrIcon } from 'assets/svg/flags/ir.svg';
import { ReactComponent as IsIcon } from 'assets/svg/flags/is.svg';
import { ReactComponent as ItIcon } from 'assets/svg/flags/it.svg';
import { ReactComponent as JeIcon } from 'assets/svg/flags/je.svg';
import { ReactComponent as JmIcon } from 'assets/svg/flags/jm.svg';
import { ReactComponent as JoIcon } from 'assets/svg/flags/jo.svg';
import { ReactComponent as JpIcon } from 'assets/svg/flags/jp.svg';
import { ReactComponent as KeIcon } from 'assets/svg/flags/ke.svg';
import { ReactComponent as KgIcon } from 'assets/svg/flags/kg.svg';
import { ReactComponent as KhIcon } from 'assets/svg/flags/kh.svg';
import { ReactComponent as KiIcon } from 'assets/svg/flags/ki.svg';
import { ReactComponent as KmIcon } from 'assets/svg/flags/km.svg';
import { ReactComponent as KnIcon } from 'assets/svg/flags/kn.svg';
import { ReactComponent as KpIcon } from 'assets/svg/flags/kp.svg';
import { ReactComponent as KrIcon } from 'assets/svg/flags/kr.svg';
import { ReactComponent as KwIcon } from 'assets/svg/flags/kw.svg';
import { ReactComponent as KyIcon } from 'assets/svg/flags/ky.svg';
import { ReactComponent as KzIcon } from 'assets/svg/flags/kz.svg';
import { ReactComponent as LaIcon } from 'assets/svg/flags/la.svg';
import { ReactComponent as LbIcon } from 'assets/svg/flags/lb.svg';
import { ReactComponent as LcIcon } from 'assets/svg/flags/lc.svg';
import { ReactComponent as LiIcon } from 'assets/svg/flags/li.svg';
import { ReactComponent as LkIcon } from 'assets/svg/flags/lk.svg';
import { ReactComponent as LrIcon } from 'assets/svg/flags/lr.svg';
import { ReactComponent as LsIcon } from 'assets/svg/flags/ls.svg';
import { ReactComponent as LtIcon } from 'assets/svg/flags/lt.svg';
import { ReactComponent as LuIcon } from 'assets/svg/flags/lu.svg';
import { ReactComponent as LvIcon } from 'assets/svg/flags/lv.svg';
import { ReactComponent as LyIcon } from 'assets/svg/flags/ly.svg';
import { ReactComponent as MaIcon } from 'assets/svg/flags/ma.svg';
import { ReactComponent as McIcon } from 'assets/svg/flags/mc.svg';
import { ReactComponent as MdIcon } from 'assets/svg/flags/md.svg';
import { ReactComponent as MeIcon } from 'assets/svg/flags/me.svg';
import { ReactComponent as MfIcon } from 'assets/svg/flags/mf.svg';
import { ReactComponent as MgIcon } from 'assets/svg/flags/mg.svg';
import { ReactComponent as MhIcon } from 'assets/svg/flags/mh.svg';
import { ReactComponent as MkIcon } from 'assets/svg/flags/mk.svg';
import { ReactComponent as MlIcon } from 'assets/svg/flags/ml.svg';
import { ReactComponent as MmIcon } from 'assets/svg/flags/mm.svg';
import { ReactComponent as MnIcon } from 'assets/svg/flags/mn.svg';
import { ReactComponent as MoIcon } from 'assets/svg/flags/mo.svg';
import { ReactComponent as MpIcon } from 'assets/svg/flags/mp.svg';
import { ReactComponent as MqIcon } from 'assets/svg/flags/mq.svg';
import { ReactComponent as MrIcon } from 'assets/svg/flags/mr.svg';
import { ReactComponent as MsIcon } from 'assets/svg/flags/ms.svg';
import { ReactComponent as MtIcon } from 'assets/svg/flags/mt.svg';
import { ReactComponent as MuIcon } from 'assets/svg/flags/mu.svg';
import { ReactComponent as MvIcon } from 'assets/svg/flags/mv.svg';
import { ReactComponent as MwIcon } from 'assets/svg/flags/mw.svg';
import { ReactComponent as MxIcon } from 'assets/svg/flags/mx.svg';
import { ReactComponent as MyIcon } from 'assets/svg/flags/my.svg';
import { ReactComponent as MzIcon } from 'assets/svg/flags/mz.svg';
import { ReactComponent as NaIcon } from 'assets/svg/flags/na.svg';
import { ReactComponent as NcIcon } from 'assets/svg/flags/nc.svg';
import { ReactComponent as NeIcon } from 'assets/svg/flags/ne.svg';
import { ReactComponent as NfIcon } from 'assets/svg/flags/nf.svg';
import { ReactComponent as NgIcon } from 'assets/svg/flags/ng.svg';
import { ReactComponent as NiIcon } from 'assets/svg/flags/ni.svg';
import { ReactComponent as NlIcon } from 'assets/svg/flags/nl.svg';
import { ReactComponent as NoIcon } from 'assets/svg/flags/no.svg';
import { ReactComponent as NpIcon } from 'assets/svg/flags/np.svg';
import { ReactComponent as NrIcon } from 'assets/svg/flags/nr.svg';
import { ReactComponent as NuIcon } from 'assets/svg/flags/nu.svg';
import { ReactComponent as NzIcon } from 'assets/svg/flags/nz.svg';
import { ReactComponent as OmIcon } from 'assets/svg/flags/om.svg';
import { ReactComponent as PaIcon } from 'assets/svg/flags/pa.svg';
import { ReactComponent as PeIcon } from 'assets/svg/flags/pe.svg';
import { ReactComponent as PfIcon } from 'assets/svg/flags/pf.svg';
import { ReactComponent as PgIcon } from 'assets/svg/flags/pg.svg';
import { ReactComponent as PhIcon } from 'assets/svg/flags/ph.svg';
import { ReactComponent as PkIcon } from 'assets/svg/flags/pk.svg';
import { ReactComponent as PlIcon } from 'assets/svg/flags/pl.svg';
import { ReactComponent as PmIcon } from 'assets/svg/flags/pm.svg';
import { ReactComponent as PnIcon } from 'assets/svg/flags/pn.svg';
import { ReactComponent as PrIcon } from 'assets/svg/flags/pr.svg';
import { ReactComponent as PsIcon } from 'assets/svg/flags/ps.svg';
import { ReactComponent as PtIcon } from 'assets/svg/flags/pt.svg';
import { ReactComponent as PwIcon } from 'assets/svg/flags/pw.svg';
import { ReactComponent as PyIcon } from 'assets/svg/flags/py.svg';
import { ReactComponent as QaIcon } from 'assets/svg/flags/qa.svg';
import { ReactComponent as ReIcon } from 'assets/svg/flags/re.svg';
import { ReactComponent as RoIcon } from 'assets/svg/flags/ro.svg';
import { ReactComponent as RsIcon } from 'assets/svg/flags/rs.svg';
import { ReactComponent as RuIcon } from 'assets/svg/flags/ru.svg';
import { ReactComponent as RwIcon } from 'assets/svg/flags/rw.svg';
import { ReactComponent as SaIcon } from 'assets/svg/flags/sa.svg';
import { ReactComponent as SbIcon } from 'assets/svg/flags/sb.svg';
import { ReactComponent as ScIcon } from 'assets/svg/flags/sc.svg';
import { ReactComponent as SdIcon } from 'assets/svg/flags/sd.svg';
import { ReactComponent as SeIcon } from 'assets/svg/flags/se.svg';
import { ReactComponent as SgIcon } from 'assets/svg/flags/sg.svg';
import { ReactComponent as ShIcon } from 'assets/svg/flags/sh.svg';
import { ReactComponent as SiIcon } from 'assets/svg/flags/si.svg';
import { ReactComponent as SjIcon } from 'assets/svg/flags/sj.svg';
import { ReactComponent as SkIcon } from 'assets/svg/flags/sk.svg';
import { ReactComponent as SlIcon } from 'assets/svg/flags/sl.svg';
import { ReactComponent as SmIcon } from 'assets/svg/flags/sm.svg';
import { ReactComponent as SnIcon } from 'assets/svg/flags/sn.svg';
import { ReactComponent as SoIcon } from 'assets/svg/flags/so.svg';
import { ReactComponent as SrIcon } from 'assets/svg/flags/sr.svg';
import { ReactComponent as SsIcon } from 'assets/svg/flags/ss.svg';
import { ReactComponent as StIcon } from 'assets/svg/flags/st.svg';
import { ReactComponent as SvIcon } from 'assets/svg/flags/sv.svg';
import { ReactComponent as SxIcon } from 'assets/svg/flags/sx.svg';
import { ReactComponent as SyIcon } from 'assets/svg/flags/sy.svg';
import { ReactComponent as SzIcon } from 'assets/svg/flags/sz.svg';
import { ReactComponent as TcIcon } from 'assets/svg/flags/tc.svg';
import { ReactComponent as TdIcon } from 'assets/svg/flags/td.svg';
import { ReactComponent as TfIcon } from 'assets/svg/flags/tf.svg';
import { ReactComponent as TgIcon } from 'assets/svg/flags/tg.svg';
import { ReactComponent as ThIcon } from 'assets/svg/flags/th.svg';
import { ReactComponent as TjIcon } from 'assets/svg/flags/tj.svg';
import { ReactComponent as TkIcon } from 'assets/svg/flags/tk.svg';
import { ReactComponent as TlIcon } from 'assets/svg/flags/tl.svg';
import { ReactComponent as TmIcon } from 'assets/svg/flags/tm.svg';
import { ReactComponent as TnIcon } from 'assets/svg/flags/tn.svg';
import { ReactComponent as ToIcon } from 'assets/svg/flags/to.svg';
import { ReactComponent as TrIcon } from 'assets/svg/flags/tr.svg';
import { ReactComponent as TtIcon } from 'assets/svg/flags/tt.svg';
import { ReactComponent as TvIcon } from 'assets/svg/flags/tv.svg';
import { ReactComponent as TwIcon } from 'assets/svg/flags/tw.svg';
import { ReactComponent as TzIcon } from 'assets/svg/flags/tz.svg';
import { ReactComponent as UaIcon } from 'assets/svg/flags/ua.svg';
import { ReactComponent as UgIcon } from 'assets/svg/flags/ug.svg';
import { ReactComponent as UmIcon } from 'assets/svg/flags/um.svg';
import { ReactComponent as UsIcon } from 'assets/svg/flags/us.svg';
import { ReactComponent as UyIcon } from 'assets/svg/flags/uy.svg';
import { ReactComponent as UzIcon } from 'assets/svg/flags/uz.svg';
import { ReactComponent as VaIcon } from 'assets/svg/flags/va.svg';
import { ReactComponent as VcIcon } from 'assets/svg/flags/vc.svg';
import { ReactComponent as VeIcon } from 'assets/svg/flags/ve.svg';
import { ReactComponent as VgIcon } from 'assets/svg/flags/vg.svg';
import { ReactComponent as ViIcon } from 'assets/svg/flags/vi.svg';
import { ReactComponent as VnIcon } from 'assets/svg/flags/vn.svg';
import { ReactComponent as VuIcon } from 'assets/svg/flags/vu.svg';
import { ReactComponent as WfIcon } from 'assets/svg/flags/wf.svg';
import { ReactComponent as WsIcon } from 'assets/svg/flags/ws.svg';
import { ReactComponent as YeIcon } from 'assets/svg/flags/ye.svg';
import { ReactComponent as YtIcon } from 'assets/svg/flags/yt.svg';
import { ReactComponent as ZaIcon } from 'assets/svg/flags/za.svg';
import { ReactComponent as ZmIcon } from 'assets/svg/flags/zm.svg';
import { ReactComponent as ZwIcon } from 'assets/svg/flags/zw.svg';
import { ReactComponent as UnIcon } from 'assets/svg/flags/un.svg';
import { ReactComponent as XkIcon, } from 'assets/svg/flags/xk.svg';

interface Props {
  code: string;
  fontSize?: number;
};

const getIconComponent = (code: string) => {
  const icons: any = {
    'ad': AdIcon,
    'ae': AeIcon,
    'af': AfIcon,
    'ag': AgIcon,
    'ai': AiIcon,
    'al': AlIcon,
    'am': AmIcon,
    'ao': AoIcon,
    'aq': AqIcon,
    'ar': ArIcon,
    'as': AsIcon,
    'at': AtIcon,
    'au': AuIcon,
    'aw': AwIcon,
    'ax': AxIcon,
    'az': AzIcon,
    'ba': BaIcon,
    'bb': BbIcon,
    'bd': BdIcon,
    'be': BeIcon,
    'bf': BfIcon,
    'bg': BgIcon,
    'bh': BhIcon,
    'bi': BiIcon,
    'bj': BjIcon,
    'bl': BlIcon,
    'bm': BmIcon,
    'bn': BnIcon,
    'bo': BoIcon,
    'bq': BqIcon,
    'br': BrIcon,
    'bs': BsIcon,
    'bt': BtIcon,
    'bv': BvIcon,
    'bw': BwIcon,
    'by': ByIcon,
    'bz': BzIcon,
    'ca': CaIcon,
    'cc': CcIcon,
    'cd': CdIcon,
    'cf': CfIcon,
    'cg': CgIcon,
    'ch': ChIcon,
    'ci': CiIcon,
    'ck': CkIcon,
    'cl': ClIcon,
    'cm': CmIcon,
    'cn': CnIcon,
    'co': CoIcon,
    'cr': CrIcon,
    'cu': CuIcon,
    'cv': CvIcon,
    'cw': CwIcon,
    'cx': CxIcon,
    'cy': CyIcon,
    'cz': CzIcon,
    'de': DeIcon,
    'dj': DjIcon,
    'dk': DkIcon,
    'dm': DmIcon,
    'do': DoIcon,
    'dz': DzIcon,
    'ec': EcIcon,
    'ee': EeIcon,
    'eg': EgIcon,
    'eh': EhIcon,
    'er': ErIcon,
    'es': EsIcon,
    'es-ct': EsCtIcon,
    'es-ga': EsGaIcon,
    'et': EtIcon,
    'eu': EuIcon,
    'fi': FiIcon,
    'fj': FjIcon,
    'fk': FkIcon,
    'fm': FmIcon,
    'fo': FoIcon,
    'fr': FrIcon,
    'ga': GaIcon,
    'gb': GbIcon,
    'gb-eng': GbEngIcon,
    'gb-nir': GbNirIcon,
    'gb-sct': GbSctIcon,
    'gb-wls': GbWlsIcon,
    'gd': GdIcon,
    'ge': GeIcon,
    'gf': GfIcon,
    'gg': GgIcon,
    'gh': GhIcon,
    'gi': GiIcon,
    'gl': GlIcon,
    'gm': GmIcon,
    'gn': GnIcon,
    'gp': GpIcon,
    'gq': GqIcon,
    'gr': GrIcon,
    'gs': GsIcon,
    'gt': GtIcon,
    'gu': GuIcon,
    'gw': GwIcon,
    'gy': GyIcon,
    'hk': HkIcon,
    'hm': HmIcon,
    'hn': HnIcon,
    'hr': HrIcon,
    'ht': HtIcon,
    'hu': HuIcon,
    'id': IdIcon,
    'ie': IeIcon,
    'il': IlIcon,
    'im': ImIcon,
    'in': InIcon,
    'io': IoIcon,
    'iq': IqIcon,
    'ir': IrIcon,
    'is': IsIcon,
    'it': ItIcon,
    'je': JeIcon,
    'jm': JmIcon,
    'jo': JoIcon,
    'jp': JpIcon,
    'ke': KeIcon,
    'kg': KgIcon,
    'kh': KhIcon,
    'ki': KiIcon,
    'km': KmIcon,
    'kn': KnIcon,
    'kp': KpIcon,
    'kr': KrIcon,
    'kw': KwIcon,
    'ky': KyIcon,
    'kz': KzIcon,
    'la': LaIcon,
    'lb': LbIcon,
    'lc': LcIcon,
    'li': LiIcon,
    'lk': LkIcon,
    'lr': LrIcon,
    'ls': LsIcon,
    'lt': LtIcon,
    'lu': LuIcon,
    'lv': LvIcon,
    'ly': LyIcon,
    'ma': MaIcon,
    'mc': McIcon,
    'md': MdIcon,
    'me': MeIcon,
    'mf': MfIcon,
    'mg': MgIcon,
    'mh': MhIcon,
    'mk': MkIcon,
    'ml': MlIcon,
    'mm': MmIcon,
    'mn': MnIcon,
    'mo': MoIcon,
    'mp': MpIcon,
    'mq': MqIcon,
    'mr': MrIcon,
    'ms': MsIcon,
    'mt': MtIcon,
    'mu': MuIcon,
    'mv': MvIcon,
    'mw': MwIcon,
    'mx': MxIcon,
    'my': MyIcon,
    'mz': MzIcon,
    'na': NaIcon,
    'nc': NcIcon,
    'ne': NeIcon,
    'nf': NfIcon,
    'ng': NgIcon,
    'ni': NiIcon,
    'nl': NlIcon,
    'no': NoIcon,
    'np': NpIcon,
    'nr': NrIcon,
    'nu': NuIcon,
    'nz': NzIcon,
    'om': OmIcon,
    'pa': PaIcon,
    'pe': PeIcon,
    'pf': PfIcon,
    'pg': PgIcon,
    'ph': PhIcon,
    'pk': PkIcon,
    'pl': PlIcon,
    'pm': PmIcon,
    'pn': PnIcon,
    'pr': PrIcon,
    'ps': PsIcon,
    'pt': PtIcon,
    'pw': PwIcon,
    'py': PyIcon,
    'qa': QaIcon,
    're': ReIcon,
    'ro': RoIcon,
    'rs': RsIcon,
    'ru': RuIcon,
    'rw': RwIcon,
    'sa': SaIcon,
    'sb': SbIcon,
    'sc': ScIcon,
    'sd': SdIcon,
    'se': SeIcon,
    'sg': SgIcon,
    'sh': ShIcon,
    'si': SiIcon,
    'sj': SjIcon,
    'sk': SkIcon,
    'sl': SlIcon,
    'sm': SmIcon,
    'sn': SnIcon,
    'so': SoIcon,
    'sr': SrIcon,
    'ss': SsIcon,
    'st': StIcon,
    'sv': SvIcon,
    'sx': SxIcon,
    'sy': SyIcon,
    'sz': SzIcon,
    'tc': TcIcon,
    'td': TdIcon,
    'tf': TfIcon,
    'tg': TgIcon,
    'th': ThIcon,
    'tj': TjIcon,
    'tk': TkIcon,
    'tl': TlIcon,
    'tm': TmIcon,
    'tn': TnIcon,
    'to': ToIcon,
    'tr': TrIcon,
    'tt': TtIcon,
    'tv': TvIcon,
    'tw': TwIcon,
    'tz': TzIcon,
    'ua': UaIcon,
    'ug': UgIcon,
    'um': UmIcon,
    'us': UsIcon,
    'uy': UyIcon,
    'uz': UzIcon,
    'va': VaIcon,
    'vc': VcIcon,
    've': VeIcon,
    'vg': VgIcon,
    'vi': ViIcon,
    'vn': VnIcon,
    'vu': VuIcon,
    'wf': WfIcon,
    'ws': WsIcon,
    'ye': YeIcon,
    'yt': YtIcon,
    'za': ZaIcon,
    'zm': ZmIcon,
    'zw': ZwIcon,
    'un': UnIcon,
    'xk': XkIcon,
  };
  return icons[code] || FlagOutlined;
};

export default function FlagComponent({ code, fontSize = 20 }: Props) {
  return (
    <Icon
      style={{ fontSize: fontSize, verticalAlign: 'middle' }}
      component={ getIconComponent(code) }
    />
  );
};