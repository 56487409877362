// Libs
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';

// Interfaces
import { FormFieldConfig } from 'components/form/form-wrapper';

interface Props {
  field: any;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  border?: boolean;
};

class DynamicFieldComponentRelationship extends Component<Props> {
  render = () => {
    return (
      <FieldWrapper
        col={ this.props.config.fieldColSpan }
        description={ this.props.field.description }
        label={ this.props.field.label }
        required={ this.props.field.config.required }
        border
      >
        { _.has(this.props.state, 'component.label') && (
          <p className="fsz-sm lh-1 mB-5 whs-nw ov-h tov-e">{ this.props.state?.component?.label }</p>
        ) }
        { _.has(this.props.state, 'target_path') ? (
          <Link to={ this.props.state.target_path }>{ this.props.state.target_title }</Link>
        ) : (
          <span>-</span>
        ) }
      </FieldWrapper>
    );
  };
};

export default DynamicFieldComponentRelationship;