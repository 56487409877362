// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { DatePicker, Timeline as AntTimeline, Button, Tooltip } from 'antd';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
} from 'components/form/form-wrapper';

// Icons
import { ClockCircleOutlined } from '@ant-design/icons';

// Services
import { getUserSetting } from 'services/settings';

// Utils
import { dateTimeDifference } from 'utils/utils';

// Styles
import './Timeline.scss';

interface Props {
  numberFormat: any;
  field: FormField;
  originalStateArray: any;
  stateArray: any;
  config: FormFieldConfig;
  border?: boolean;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: any): void;
  setFieldErrorMessage(id: string, message?: any): void;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  onFieldChange(
    field: FormField
  ): void;
  onRefreshForm(field_id: string): void;
};

interface Rule {
  before?: string;
  after?: string;
};

interface Step {
  id: number;
  title: string;
  reference: string;
  config: {
    required: boolean;
    dependencies?: string[];
    rules?: Rule;
  };
};

class Timeline extends Component<Props> {

  componentDidMount = () => {
    this.validate(this.props.stateArray);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, stateArray } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(stateArray);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  componentWillUnmount = () => {
    const { field, originalStateArray, config, onChange } = this.props;

    // Revert state
    onChange(field, originalStateArray, config);

    // Remove validations for this field
    this.validate(originalStateArray, true);
  };

  validate = (stateArray: any, shouldClear = false) => {
    const { originalStateArray } = this.props;

    this.generateModifiedState(originalStateArray, stateArray, shouldClear);
    // this.generateErrorState(stateArray, shouldClear); // turned off for now
  };

  generateModifiedState = (originalStateArray: any, stateArray: any, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_timeline`;

    if (!_.isEqual(originalStateArray, stateArray) && !shouldClear) {

      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  getErrors = (field: FormField, state: any) => {
    return field.template.steps.filter((step: Step) => {
      return step.config.required && !field?.values.find((stepValue: any) => stepValue?.timeline_template_step_id === step.id)?.value;
    }) || [];
  };

  generateErrorState = (stateArray: any, shouldClear = false) => {
    const { setFieldErrorMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_timeline`;

    const errors = this.getErrors(field, stateArray);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  getDependencyValue = (field: FormField, dependencyRef: string): any => {

    const dependencyStep = field?.template?.steps.find((step: Step) => step.reference === dependencyRef);

    if (dependencyStep) {
      return field?.values.find((stepValue: any) => stepValue?.timeline_template_step_id === dependencyStep.id)?.value;
    }

    return null;
  };

  passDependencyCheck = (field: FormField, step: Step): boolean => {

    const dependencies: string[] = !_.isNil(step.config?.dependencies) ? step.config.dependencies : [];

    if (_.isEmpty(dependencies)) {
      return true;
    }

    if (!_.isEmpty(field?.values) && dependencies.every((dependency: string) => !!this.getDependencyValue(field, dependency))) {
      return true;
    }

    return false;
  };

  passClearDependencyCheck = (field: FormField, step: Step): boolean => {

    const dependantSteps: Step[] = field?.template?.steps.filter((_step: Step) => _step.config?.dependencies?.includes(step.reference));

    if (_.isEmpty(dependantSteps)) {
      return true;
    }

    if (!_.isEmpty(field?.values) && dependantSteps.every((dependantSteps: Step) => !this.getDependencyValue(field, dependantSteps.reference))) {
      return true;
    }

    return false;
  };

  getBetweenDate = (field: FormField, currentStep: Step): string | boolean => {

    const currentStepIndex = field?.template?.steps.findIndex((step: Step) => step.id === currentStep.id);
    const currentStepValueDate = field?.values.find((stepValue: any) => stepValue.timeline_template_step_id === currentStep?.id);

    const prevStep: Step | undefined = field?.template?.steps[currentStepIndex - 1];
    const prevStepDate = field?.values.find((stepValue: any) => stepValue.timeline_template_step_id === prevStep?.id);

    if (currentStepValueDate?.value && prevStepDate?.value) {

      const from = moment(prevStepDate?.value);
      const to = moment(currentStepValueDate?.value);

      const difference = dateTimeDifference(from, to);

      const yearText = `${difference.years} ${difference.years === 1 ? 'year, ' : 'years, '}`;
      const monthText = `${difference.months} ${difference.months === 1 ? 'month, ' : 'months, '}`;
      const dayText = `${difference.days} ${difference.days === 1 ? 'day' : 'days'}`;

      return `${yearText}${monthText}${dayText}`;
    }

    return false;
  };

  getStepValueByReference = (field: FormField, stepReference: string | undefined): any => {

    if (!stepReference) {
      return null;
    }

    const step: Step = field?.template?.steps.find((_step: Step) => _step.reference === stepReference);

    if (step) {
      return field?.values.find((stepValue: any) => stepValue?.timeline_template_step_id === step.id)?.value;
    }

    return null;
  };

  hasError = (step: Step) => {
    const { field, config, fieldErrorMessages } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_timeline`;

    if (_.has(fieldErrorMessages, key) &&  !_.isEmpty(fieldErrorMessages[key].errors)) {
      return !!fieldErrorMessages[key].errors.find((_step: Step) => _step.id === step.id);
    }

    return false;
  };

  handleChange = (step: Step, value: moment.Moment | null) => {
    const { field, stateArray, config, onChange } = this.props;

    let newValues = _.cloneDeep(field.values);

    if (!value) {
      _.remove(newValues, (_value) => _value.timeline_template_step_id === step.id);
    }

    if (value) {
      const index = field?.values?.findIndex((value: any) => value.timeline_template_step_id === step.id);

      newValues = index !== -1 ?_.set(newValues, index, {
        timeline_template_step_id: step.id,
        value: value.format('YYYY-MM-DD HH:mm:ss')
      }) : _.cloneDeep(stateArray).concat([{
        timeline_template_step_id: step.id,
        value: value.format('YYYY-MM-DD HH:mm:ss')
      }]);
    }

    onChange(field, newValues, config);
  };

  render = () => {
    const { field, config, border } = this.props;
    return (
      <FieldWrapper
        col={ config.fieldColSpan }
        label={ field.label }
        required={ field.config.required }
        border={ border }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <div className="w-100p mT-30">
          <AntTimeline>
            { field.template?.steps?.map((step: Step, index: number) => {

              const isDisabledTimelineItem = !this.passDependencyCheck(field, step);
              const allowValueClear = this.passClearDependencyCheck(field, step);
              const betweenTime = this.getBetweenDate(field, step);
              const stepValue = _.isArray(field?.values) ? field?.values?.find((stepValue: any) => stepValue.timeline_template_step_id === step.id) : undefined;
              const hasError = this.hasError(step);
              const disabledDateBefore = this.getStepValueByReference(field, step.config?.rules?.before);
              const disabledDateAfter = this.getStepValueByReference(field, step.config?.rules?.after);

              return (
                <AntTimeline.Item
                  dot={ betweenTime ? (
                    <Tooltip
                      placement="top"
                      title={ (
                        <div className='d-f fxd-c'>
                          <span>Time since: <span className="fw-600">{field.template?.steps[index-1]?.title}</span></span>
                          <span>{ betweenTime }</span>
                        </div>
                      ) }
                    >
                      <ClockCircleOutlined style={{ fontSize: '16px' }} />
                    </Tooltip>
                  ) : undefined }
                  key={ index }
                  color={ isDisabledTimelineItem ? 'gray' : betweenTime ? 'blue' : 'blue' }
                  className={ classNames({
                    'pB-0': field.template.steps.length === (index + 1)
                  }) }
                >
                  <div>
                    <span>{ step.title }</span>
                    { step.config.required && <span className="text-required mL-2 fsz-md lh-1 va-t">*</span> }
                  </div>
                  <div className='d-f'>
                    <DatePicker
                      className={ classNames('Field w-100p', {
                        'Field--has-error border-danger': hasError
                      }) }
                      showTime
                      allowClear={ allowValueClear }
                      showNow={ false }
                      key={ index }
                      disabled={ isDisabledTimelineItem }
                      disabledDate={ date => {
                        if (disabledDateBefore && disabledDateAfter) {
                          return date.isAfter(disabledDateAfter) && date.isBefore(disabledDateBefore);
                        } else if (disabledDateBefore) {
                          return date.isBefore(disabledDateBefore);
                        } else if (disabledDateAfter) {
                          return date.isAfter(disabledDateAfter);
                        }
                        return false;
                      } }
                      placeholder={ 'Please Select' }
                      format={ `${getUserSetting('date_format')} HH:mm` }
                      value={ stepValue?.value ? moment(stepValue?.value) : undefined }
                      onChange={ (date: moment.Moment | null) => {
                        this.handleChange(step, date);
                      } }
                    />
                    <Button
                      className="mL-10"
                      disabled={ isDisabledTimelineItem }
                      onClick={ () => {
                        this.handleChange(step, moment());
                      } }
                    >
                      Set Now
                    </Button>
                  </div>
                </AntTimeline.Item>
              );
            } ) }
          </AntTimeline>
        </div>
      </FieldWrapper>
    );
  };
};

export default Timeline;
