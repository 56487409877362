// Libs
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

// Components
import { Input, Pagination, Select, Table, Tooltip, TreeSelect, Button, DatePicker } from 'antd';
import Badge, { getBadgeType } from 'components/badge';
import BlockingSpinner from 'components/blocking-spinner';
import CoverModal from 'components/cover-modal';

// Services
import { Api } from 'services/api';
import { getFormatedDate, getUserSetting } from 'services/settings';

// Utilities
import { nestedSet, flattenSet } from 'utils/utils';

// Icons
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg';
import Icon from '@ant-design/icons';

const API: Api = new Api();
const { Search } = Input;
const { Option } = Select;
const { SHOW_ALL } = TreeSelect;
const { RangePicker } = DatePicker;

const messages = defineMessages({
  error: {
    id: 'general.error',
    defaultMessage: 'Error',
    description: '',
  },
  filter_by: {
    id: 'basic_list_view.filter_by',
    defaultMessage: 'Filter by',
    description: '',
  },
  show: {
    id: 'basic_list_view.show',
    defaultMessage: 'Show',
    description: '',
  },
  entries_of: {
    id: 'basic_list_view.entries_of',
    defaultMessage: 'Entries of',
    description: '',
  },
  quick_filter: {
    id: 'basic_list_view.quick_search',
    defaultMessage: 'Quick Search',
    description: '',
  },
  filter: {
    id: 'basic_list_view.filter',
    defaultMessage: 'Filter',
    description: '',
  },
});

enum Mode {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Annual = 'annual',
};

interface FilterItem {
  id: number;
  parent_id?: number;
  title: string;
};
interface Filter {
  [key: string]: Array<FilterItem>;
};
interface ListColumns {
  key: string | number;
  dataIndex: string | number;
  id: string;
  type: string;
  options: any;
  label: string;
  width: number;
  align: string;
  sorter: boolean;
  filterable: boolean;
  ellipsis: boolean;
  fixed: string | boolean;
  sublistColumns?: any;
  render: (field: ListDataValues, row: any) => React.ReactNode;
};

interface ListDataValues {
  type: string;
  value: any;
  title?: string;
  old?: string;
  new?: string;
  total?: number;
  prefix?: string | null;
  suffix?: string | null;
  format?: string | null;
  text?: string | number;
  color?: string;
  code?: string;
  unit?: string;
  path?: string;
  filename?: string;
};

interface MonthlyData {
  id: number;
  title: string;
  key_date: string;
  stage_reference: number;
};

interface CalendarColumn {
  label: string,
  dataIndex: string
};

interface Props {
  intl: IntlShape;
  client_id?: number;
  origin?: any;
  bundle?: string;
  type?: string;
  customEndpoint: string;
  defaultExpandedRowKeys?: string[];
  defaultSortOrder?: { field: string, order: 'ascend' | 'descend' };
};

interface State {
  mode: Mode;
  generalFilters: Filter;
  requiredFilters: Filter;
  columns: any;
  calendarColumns: CalendarColumn[];
  summaryColumns: any;
  selectedFilters: any;
  prevSelectedFilters: any;
  data: any;
  selectedClassifications: number[];
  currentPage: number;
  itemsPerPage: number;
  dialog: any | null;
  quickFilter: string | null;
  tooltip: string | null;
  showFilter: boolean;
  isFetching: boolean;
  isLoading: boolean;
  expandedRowKeys: string[];
  sorter: any;
  datePickerOpen: boolean;
  datePickerMode: string | null;
  datePickerPreset: string | null;
  prevDatePickerPreset: string | null;
};

const getQuarterByMonth = (month: number) => {
  if (month <= 3) {
    return 1;
  } else if (month <= 6) {
    return 2;
  } else if (month <= 9) {
    return 3;
  }
  return 4;
};

class CalendarView extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    mode: Mode.Annual,
    generalFilters: {},
    requiredFilters: {},
    columns: {},
    calendarColumns: [],
    summaryColumns: {},
    selectedFilters: {},
    prevSelectedFilters: null,
    data: [],
    selectedClassifications: [],
    currentPage: 1,
    itemsPerPage: 25,
    dialog: null,
    quickFilter: null,
    tooltip: null,
    showFilter: false,
    isFetching: true,
    isLoading: false,
    expandedRowKeys: this.props?.defaultExpandedRowKeys ? this.props.defaultExpandedRowKeys : [],
    sorter: !_.isEmpty(this.props.defaultSortOrder) ? this.props.defaultSortOrder : null,
    datePickerOpen: false,
    datePickerMode: null,
    datePickerPreset: null,
    prevDatePickerPreset: null,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getCalendarData();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  getFlatten = (data: any) => {
    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({ ..._value });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  getCalendarData = async () => {
    const { client_id, customEndpoint, intl: { formatMessage }, origin } = this.props;
    const { selectedFilters, mode, datePickerPreset } = this.state;

    try {
        await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

        const payload = await API.get(`client/${client_id}/${customEndpoint}/calendar`, {
          filters: selectedFilters,
          origin: origin
        });

        const convertedData =  this.convertData(payload.data, mode);
        let generalFilters: any = [];
        let requiredFilters: any = [];
        let newSelectedFilters: any = _.cloneDeep(selectedFilters);
        let defaultFilterPreset: string | null = null;

        if (!_.isEmpty(payload) && !_.isEmpty(payload.filters)) {
          Object.keys(payload.filters).forEach((key: any) => {
            const filter = payload.filters[key];
            if (!!filter.required) {
              requiredFilters = [...requiredFilters, filter];
            } else {
              generalFilters = [...generalFilters, filter];
            }

            // Add default filter option to the selected list
            if (_.has(filter, 'options') && !_.isEmpty(filter['options']) && (!_.has(selectedFilters, key) || _.isEmpty(selectedFilters[key]))) {
              filter['options'].map((option: any) => {
                if (!!option?.default) {
                  newSelectedFilters = { ...newSelectedFilters, [key]: { value: option.id } };
                }
              });
            }

            // Set default preset and dates
            if (!datePickerPreset && filter.type === 'calendar' && _.has(newSelectedFilters, key)) {
              const calendarFilterOption = filter.options.find((option: any) => option.id === newSelectedFilters[key]?.value);
              defaultFilterPreset = calendarFilterOption?.default_preset ? calendarFilterOption.default_preset : null;

              const { startDate, endDate } = this.getDefaultModeDates(defaultFilterPreset);
              newSelectedFilters = { ...newSelectedFilters, [key]: { ...newSelectedFilters[key], startDate: startDate, endDate: endDate } };
            }
          });
        }

        let _columns: any = [];

        // Bespoke Columns
        if (!_.isEmpty(payload.columns)) {
          _columns = this.recordColumnMapping(payload.columns, convertedData);
        }

        const modalColumns = _.isArray(payload?.summaryColumns) ? payload.summaryColumns.map((column: ListColumns) => {
          return {
            key: column?.key || column?.id,
            dataIndex: column?.dataIndex || column?.key || column?.id,
            type: column.type,
            title: _.has(column, 'label') ? column.label : '',
            options: _.has(column, 'options') ? column.options : [],
            width: !!column?.width ? column.width : 150,
            fixed: _.has(column, 'fixed') && !!column?.fixed ? column?.fixed : false,
            filterable: _.has(column, 'filterable') ? column.filterable : false,
            sorter: _.has(column, 'sorter') ? column.sorter : false,
            ellipsis: _.has(column, 'ellipsis') ? column.ellipsis : false,
            render: (field: ListDataValues) => {

              if (_.isEmpty(field) || !_.has(field, 'type')) return <>-</>;

              switch (field.type) {
                case 'text':
                case 'string':

                  if (_.has(field, 'value')) {
                    const characterLimit = 200;
                    if (field.value.length >= characterLimit) {
                      return <span title={ field.value }>{ _.truncate(field.value, { 'length': characterLimit, 'separator': /,? +/ }) }</span>;
                    } else {
                      return <span title={ field.value } >{ field.value }</span>;
                    }
                  }

                  return <span>{ '-' }</span>;

                case 'route':
                  if (!_.has(field, 'value.title')) return <>-</>;
                  if (!_.has(field, 'value.path') || !field.value.path) return <>{ field.value.title }</>;

                  return (
                    <RouterLink className='d-f primaryColor' to={ field.value.path }>
                      { field.value.title }
                    </RouterLink>
                  );
                case 'datetime':
                  if (!field.value) {
                    return '-';
                  }
                  if (_.has(field, 'format') && field.format === 'date') {
                    return <div>{ getFormatedDate(field.value, undefined, false) }</div>;
                  }
                  return <div>{ getFormatedDate(field.value, undefined, true) }</div>;
                case 'workflow_stage':
                    return <Badge type={ getBadgeType(field.value.context) } text={ _.startCase(_.toLower(field.value.title)).split('_').join(' ') } />;
                case 'integer':
                  return (
                    <div className="ta-r">
                      <span>{ field.prefix ? `${field.prefix} ` : '' }{ field.value }{ field.suffix ? ` ${field.suffix}` : '' }</span>
                    </div>
                  );
              }
            }
          };
        }) : [];

        const calendarColumns: CalendarColumn[] = this.getCalendarColumns(mode, _.has(newSelectedFilters, 'calendar_mode') ? newSelectedFilters['calendar_mode'] : null);

        // Calendar Columns
        _columns.push(
          ...calendarColumns.map((item: CalendarColumn, index: number) => {
            const isCurrentDateColumn: boolean = this.isCurrentDateColumn(item, 'header');

            return ({
              key: item.dataIndex,
              dataIndex: item.dataIndex,
              title: item.label,
              width: 200,
              onHeaderCell: (column: any) => {
                return { className: isCurrentDateColumn ? 'bg-tinted-cyan' : '' };
              },
              onCell: (record: any, rowIndex: any) => {
                return { className: isCurrentDateColumn ? 'bg-tinted-cyan' : '' };
              },
              render: (cell: { key: string, monthly_data: MonthlyData[] } | undefined, row: any) => {
                if (!cell) return <span> - </span>;

                return (
                  <div
                    className="primaryColor cur-p"
                    onClick={ () => {
                      this.setState({
                        dialog: {
                          modalTitle: (_.has(row, 'title') ? `${row.title.value.title} - ` : ' ') + (_.has(calendarColumns, cell.key) ? calendarColumns[cell.key as any] : ''),
                          dataSource: cell.monthly_data,
                          columns: modalColumns,
                          display: true
                        }
                      });
                    } }
                  >
                    { cell.monthly_data.length }
                  </div>
                );
              },
            });
          })
        );

        this.mounted && this.setState({
          generalFilters: generalFilters,
          requiredFilters: requiredFilters,
          selectedFilters: newSelectedFilters,
          calendarColumns: _columns,
          data: convertedData,
          datePickerPreset: defaultFilterPreset || datePickerPreset,
        });
    } catch (error) {
      console.error(`${formatMessage(messages.error)}:`, error);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  quickFilter = (items: any[], value: string) => {
    return items.filter((item: any) => {
      return Object.keys(item).some((columnKey: string) => {
        if (_.has(item, `${columnKey}.type`) && ['string', 'route'].includes(item[columnKey]['type'])) {
          switch (item[columnKey]['type']) {
            case 'string':
              return !!item[columnKey]['value'] && item[columnKey]['value'].toLowerCase().includes(value.toLowerCase());
            case 'route':
              return _.has(item, `${columnKey}.value.title`) && item[columnKey]['value']['title'].toLowerCase().includes(value.toLowerCase());
            default:
              return false;
          }
        }
        return false;
      });
    });
  };

  handleSort = (pagination: any, filters: any, sorter: any, extra: any) => {
    this.setState({
      sorter: sorter
    });
  };

  paginageItems = (items: any, currentPage = 1, itemsPerPage = 25): any => {
    return _.drop(items, (currentPage - 1) * itemsPerPage).slice(0, itemsPerPage);
  };

  sortData = (items: any[], sorter: any) => {
    const field = sorter.field;
    const order = sorter.order === 'descend' ? 'desc' : 'asc';
    const metaitem = items.find((item: any) => !!item[field]);
    const metafield = metaitem?.[field];

    if (metafield && typeof metafield === 'object') {
      switch (metafield.type) {
        case 'number':
        case 'area':
        case 'currency':
          return _.orderBy(items, item => _.has(item, `${field}.value`) ? parseFloat(item[field]['value']) : -1, order);
        case 'string':
        case 'integer':
          return _.orderBy(items, `${field}.value`, order);
        case 'route':
        case 'workflow_stage':
          return _.orderBy(items, `${field}.value.title`, order);
        case 'nested':
          return _.orderBy(items, `${field}.title`, order);
        case 'badge':
          return _.orderBy(items, `${field}.text`, order);
        case 'datetime':
          return _.orderBy(items, (item: any) => {
            if (_.has(item, `${field}.value`)) {
              return moment(item[field]['value'], 'YYYY-MM-DD HH:mm:ss').valueOf();
            } else {
              return '-';
            }
          }, order);
      }
    }

    return _.orderBy(items, field, order);
  };

  convertData = (items: any, mode: Mode) => {
    const getGroupedMonthlyData = (monthly_data: any) => {
      return monthly_data && monthly_data.reduce((acc: any, data: any) => {
        let key: any = '';

        if ( !!data && !!data.key_date && !!data.key_date.value &&  moment(data.key_date.value).isValid()) {
          switch (mode) {
            case Mode.Annual:
            case Mode.Monthly:
              key  = moment(data.key_date.value).format('YYYY-MM');
              break;

            case Mode.Quarterly:
              key = `Q${getQuarterByMonth(Number(moment(data.key_date.value).format('M')))}-${moment(data.key_date.value).format('YYYY')}`;
              break;

            case Mode.Weekly:
              key  = moment(data.key_date.value).format('WW-YY');
              break;

            case Mode.Daily:
              key = moment(data.key_date.value).format('YYYY-MM-DD');
              break;
          }
        }

        const monthly_data: MonthlyData[] = [...acc[key]?.monthly_data || [], data];
        return { ...acc, [key]: { key: key, monthly_data: monthly_data } };
      }, {} as { [key: string]: { key: string, monthly_data: MonthlyData[] } });
    };

    items.forEach((value: any) => {
      const formatMonthlyData = (_value: any) => {
        const month_data = getGroupedMonthlyData(_value.monthly_data || []);
        Object.values(month_data).forEach((month: any) => {
          _value[month.key] = month;
        });

        if (!!_value?.children && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            formatMonthlyData(__value);
          });
        }
      };

      return formatMonthlyData(value);
    });

    return items;
  };

  renderDialog = (dialogInfo: any): JSX.Element => {
    return (
      <CoverModal
        middleContent={ `${dialogInfo.modalTitle}` }
        onClose={ () => this.setState({ dialog: null }) }
        cover
      >
        <Table
          className='p-20'
          sticky
          bordered
          pagination={ false }
          size={ 'small' }
          rowKey={ record => record.id }
          columns={ dialogInfo.columns }
          dataSource={ dialogInfo.dataSource }
          onChange={ this.handleSort }
          scroll={ {
            x: flattenSet(dialogInfo.columns).reduce((acc: any, curr: any) => acc += curr?.width || 150, 0)
          } }
        />
      </CoverModal>
    );
  };

  renderSelectFilter = (filter: any) => {
    const { intl: { formatMessage } } = this.props;
    const { selectedFilters, tooltip } = this.state;

    return (
      <Tooltip key={ `${filter.label}-${filter.id}` } visible={ tooltip === filter.id } title={ `${formatMessage(messages.filter_by)} ${filter.label}` }>
        <Select
          mode='multiple'
          maxTagCount={ 'responsive' }
          style={{ width: 200, margin: 5 }}
          placeholder={ filter.label }
          showSearch
          autoFocus
          allowClear
          dropdownMatchSelectWidth={ false }
          className={ classNames({
            'Select-Field--has-error border-danger': !!filter.required && !selectedFilters[filter.id],
          }) }
          filterOption={ (input: string, option: any) => {
            return !!filter?.find((record: FilterItem) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
          } }
          onMouseEnter={ () => {
            this.mounted && this.setState({
              tooltip: filter.id,
            });
          } }
          onMouseLeave={ () => {
            tooltip && this.mounted && this.setState({
              tooltip: null,
            });
          }}
          onChange={ (recordIds: Array<number | string>) => {
            this.mounted && this.setState({
              selectedFilters: _.isEmpty(recordIds)
                ? _.omit(selectedFilters, filter.id)
                : { ...selectedFilters, [filter.id]: recordIds },
            });
          } }
          value={ selectedFilters[filter.id] }
        >
          { filter.options.map((option: any) => (
            <Option key={ `${option.title}_${option.id}` } value={ option.id }>
              { option.title }
            </Option>
          )) }
        </Select>
      </Tooltip>
    );
  };

  renderNestedFilter = (filter: any) => {
    const { intl: { formatMessage } } = this.props;
    const { selectedFilters, tooltip } = this.state;

    return (
      <Tooltip key={ `${filter.label}-${filter.id}` } visible={ tooltip === filter.id } title={ `${formatMessage(messages.filter_by)} ${filter.label}` }>
        <TreeSelect
          style={{ width: 200, margin: 5 }}
          multiple
          showSearch
          treeCheckable
          treeDefaultExpandAll
          autoFocus
          allowClear
          dropdownMatchSelectWidth={ false }
          maxTagCount={ 'responsive' }
          placeholder={ filter.label }
          showCheckedStrategy={ SHOW_ALL }
          treeData={ nestedSet(filter.options) }
          value={ selectedFilters[filter.id] }
          onMouseEnter={ () => {
            this.mounted && this.setState({
              tooltip: filter.id,
            });
          } }
          onMouseLeave={ () => {
            tooltip && this.mounted && this.setState({
              tooltip: null,
            });
          } }
          filterTreeNode={ (input: string, option: any) => {
            if (option) {
              const filteredInput = input.toLocaleLowerCase();
              const title = option.title && option.title.toLowerCase();

              if (title.includes(filteredInput)) {
                return true;
              }
            }

            return false;
          } }
          onChange={ (recordIds: Array<number | string>) => {
            this.mounted && this.setState({
              selectedFilters: _.isEmpty(recordIds)
                ? _.omit(selectedFilters, filter.id)
                : { ...selectedFilters, [filter.id]: recordIds },
            });
          } }
        />
      </Tooltip>
    );
  };

  renderFilterDateRangePicker = (filter: any) => {
    let options = _.has(filter, 'options') ? filter.options : [];
    const preselected = options.filter((option: any) =>  _.has(option, 'preselect') && !!option.preselect);

    const defaultValue = !_.isEmpty(preselected) ? preselected[0].id : 0;

    const changeDate = (value: moment.Moment | null, datePickerKey: 'startDate' | 'endDate') => {
      let newFilters = _.cloneDeep(this.state.selectedFilters);
      if (!value) {
        if (Object.keys(newFilters[filter.id]).length > 1) {
          delete newFilters[filter.id]?.[datePickerKey];
        } else {
          delete newFilters[filter.id];
        }
      } else {
        newFilters = Object.assign({}, this.state.selectedFilters, { [filter.id]: { ...this.state.selectedFilters[filter.id], [datePickerKey]: value, ['preset']: 0 } });
      }
      this.setState({
        selectedFilters: newFilters,
      });
    };

    return (
      <Tooltip
        key={ `${filter.label}-${filter.id}` }
        title={ `${filter.label}` }
        className={ 'm-5' }
      >
        <Select
          placeholder={ filter.label }
          dropdownMatchSelectWidth={ false }
          defaultValue={ defaultValue }
          value={ this.state.selectedFilters?.[filter.id]?.preset }
          onChange={ (value) => {
            const now = moment();
            const startDate = value === 'all' ? null : now;
            const addDays = value === 'all' ? 0 : value;
            const endDate = value === 'all' ? null : moment().add(addDays, 'days');

            let newFilters = _.cloneDeep(this.state.selectedFilters);
            newFilters = Object.assign({}, this.state.selectedFilters, { [filter.id]: { ...this.state.selectedFilters[filter.id], ['startDate']: startDate, ['endDate']: endDate, ['preset']: value } });
            this.setState({
              selectedFilters: newFilters,
            });
          } }
          // @ts-ignore
          autoComplete="none"
        >
          { options.map((option: any) => (
            <Option key={ option.id } value={ option.id }>
              { option.title }
            </Option>
          ))}
        </Select>
        <DatePicker
          placeholder={ 'Select start date' }
          format={ getUserSetting('date_format') }
          value={ this.state.selectedFilters?.[filter.id]?.startDate }
          disabledDate={ date => this.state.selectedFilters?.[filter.id]?.endDate && date.isAfter(this.state.selectedFilters?.[filter.id]?.endDate) }
          onChange={ (value) => changeDate(value, 'startDate') }
        />
        <DatePicker
          placeholder={ 'Select end date' }
          format={ getUserSetting('date_format') }
          value={ this.state.selectedFilters?.[filter.id]?.endDate }
          disabledDate={ date => this.state.selectedFilters?.[filter.id]?.startDate && date.isBefore(this.state.selectedFilters?.[filter.id]?.startDate) }
          onChange={ (value) => changeDate(value, 'endDate') }
        />
      </Tooltip>
    );
  };

  renderFilters = (filters: any): JSX.Element => {
    return (
      <>
        { filters
          .map((filter: any, index: number) => {
            switch (filter.type) {
              case 'multiselect':
                return this.renderSelectFilter(filter);
              case 'nested':
                return this.renderNestedFilter(filter);
              case 'calendar':
                return this.renderCalendarMode(filter);
              case 'daterange':
                return this.renderFilterDateRangePicker(filter);
              default:
                return <React.Fragment key={ index }></React.Fragment>;
            }
          })
        }
      </>
    );
  };

  renderDatePickerFooter = (filter: any, mode: string, isRangePicker: boolean = false) => {
    const { prevDatePickerPreset, datePickerPreset, prevSelectedFilters, selectedFilters } = this.state;
    const calendarFilterOption = filter.options.find((option: any) => option.id === selectedFilters[filter.id]?.value);
    const presets = calendarFilterOption?.presets ? calendarFilterOption?.presets : {};

    return (
      <>
        <Select
          allowClear
          style={{ width: isRangePicker ? '250px' : '100%' }}
          placeholder={ 'Calendar Type' }
          onClear={ () => {
            this.setState({ datePickerPreset: null });
          } }
          onChange={ (value: string) => {

            const { startDate, endDate } = this.getDefaultModeDates(value);

            const newFilters = Object.assign({}, this.state.selectedFilters, { [filter.id]: { ...this.state.selectedFilters[filter.id], startDate: startDate, endDate: endDate } });
            this.setState({
              selectedFilters: newFilters,
              datePickerPreset: value
            });
          } }
          value={ !!datePickerPreset ? datePickerPreset : 'custom_date' }
        >
          { Object.keys(presets).map((key: any, _index: number) => (
            <Select.Option key={ `${key}_${_index}` } value={ key }>{ presets[key] }</Select.Option>
          )) }
        </Select>
        <Button
          style={{ width: isRangePicker ? 'auto' : '48%', marginRight: isRangePicker ? '0' : '4%', marginLeft: isRangePicker ? '10px' : '0' }}
          type='primary'
          onClick={ () => this.setState({ datePickerOpen: false }) }
        >
          OK
        </Button>
        <Button
          style={{ width: isRangePicker ? 'auto' : '48%', marginLeft: isRangePicker ? '10px' : '0' }}
          onClick={ () => {
            this.setState({
              datePickerPreset: prevDatePickerPreset,
              prevDatePickerPreset: null,
              selectedFilters: prevSelectedFilters,
              prevSelectedFilters: null,
              datePickerOpen: false
            });
          } }
        >
          Close
        </Button>
      </>
    );
  };

  renderCalendarModeDatePicker = (filter: any, mode: string): JSX.Element => {
    const { datePickerOpen, selectedFilters } = this.state;
    const modeOption = filter.options.find((option: any) => option.id === mode);
    const pickerMode = modeOption?.picker_mode;
    const startDate = _.has(selectedFilters, filter.id) ? selectedFilters[filter.id]?.startDate : undefined;
    const endDate = _.has(selectedFilters, filter.id) ? selectedFilters[filter.id]?.endDate : undefined;
    const isPickerMode = this.isPickerMode();
    let format: any = undefined;

    // Format
    switch (pickerMode) {
      case 'date':
        format = getUserSetting('date_format');
        break;
      case 'week':
        format = '[w/c] WW - DD/MM/YY';
        break;
      case 'month':
        format = getUserSetting('date_format').replaceAll('/DD', '').replaceAll('/DD/', '').replaceAll('DD/', '');
        break;
      case 'year':
        format = 'YYYY';
        break;
    }

    if (isPickerMode) {
      const rangeValue = {
        from: startDate ? moment(startDate) : null,
        to: endDate ? moment(endDate) : null
      };

      return (
        <RangePicker
          open={ datePickerOpen }
          onOpenChange={ (open: boolean) => this.setState({
            datePickerOpen: true,
            prevSelectedFilters: _.cloneDeep(this.state.selectedFilters),
            prevDatePickerPreset: _.cloneDeep(this.state.datePickerPreset)
          }) }
          allowEmpty={ [true, true] }
          style={{ width: pickerMode === 'week' ? 300 : 250 }}
          placeholder={ ['All', 'All'] }
          picker={ pickerMode }
          format={ format }
          value={ _.has(selectedFilters, 'calendar_mode') ? [rangeValue.from, rangeValue.to] : undefined }
          renderExtraFooter={ (mode: string) => this.renderDatePickerFooter(filter, mode, true) }
          onCalendarChange={ (dates: [moment.Moment | null, moment.Moment | null] | null) => {
            let startDate: string | null = null;
            let endDate: string | null = null;

            if (!!dates) {
              switch (pickerMode) {
                case 'date':
                  startDate = moment(dates[0]).isValid() ? moment(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
                  endDate = moment(dates[1]).isValid() ? moment(dates[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
                  break;
                case 'week':
                  startDate = moment(dates[0]).isValid() ? moment(dates[0]).startOf('week').format('YYYY-MM-DD HH:mm:ss') : null;
                  endDate = moment(dates[1]).isValid() ? moment(dates[1]).endOf('week').format('YYYY-MM-DD HH:mm:ss') : null;
                  break;
                case 'month':
                  startDate = moment(dates[0]).isValid() ? moment(dates[0]).startOf('month').format('YYYY-MM-DD HH:mm:ss') : null;
                  endDate = moment(dates[1]).isValid() ? moment(dates[1]).endOf('month').format('YYYY-MM-DD HH:mm:ss') : null;
                  break;
                case 'quarter':
                  startDate = moment(dates[0]).isValid() ? moment(dates[0]).startOf('quarter').format('YYYY-MM-DD HH:mm:ss') : null;
                  endDate = moment(dates[1]).isValid() ? moment(dates[1]).endOf('quarter').format('YYYY-MM-DD HH:mm:ss') : null;
                  break;
                case 'year':
                  startDate = moment(dates[0]).isValid() ? moment(dates[0]).startOf('year').format('YYYY-MM-DD HH:mm:ss') : null;
                  endDate = moment(dates[1]).isValid() ? moment(dates[1]).endOf('year').format('YYYY-MM-DD HH:mm:ss') : null;
                  break;
              }
            }

            const newFilters = Object.assign({}, this.state.selectedFilters, {
              [filter.id]: {
                ...this.state.selectedFilters[filter.id],
                startDate: startDate,
                endDate: endDate
              }
            });
            this.setState({
              selectedFilters: newFilters
            });
          } }
        />
      );
    }

    return (
      <DatePicker
        open={ datePickerOpen }
        onOpenChange={ (open: boolean) => this.setState({
          datePickerOpen: true,
          prevSelectedFilters: _.cloneDeep(this.state.selectedFilters),
          prevDatePickerPreset: _.cloneDeep(this.state.datePickerPreset)
        }) }
        style={{ width: 250 }}
        placeholder={ 'All' }
        picker={ pickerMode }
        format={ format }
        value={ !!startDate ? moment(startDate) : undefined }
        renderExtraFooter={ (mode: string) => this.renderDatePickerFooter(filter, mode, false) }
        onChange={ (date: moment.Moment | null) => {
          let startDate: string | null = null;
          let endDate: string | null = null;

          if (!!date) {
            switch (pickerMode) {
              case 'date':
                startDate = moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
                endDate = moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
                break;
              case 'week':
                startDate = moment(date).startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
                endDate = moment(date).endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
                break;
              case 'month':
                startDate = moment(date).startOf('month').format('YYYY-MM-DD HH:mm:ss');
                endDate = moment(date).endOf('month').format('YYYY-MM-DD HH:mm:ss');
                break;
              case 'quarter':
                startDate = moment(date).startOf('quarter').format('YYYY-MM-DD HH:mm:ss');
                endDate = moment(date).endOf('quarter').format('YYYY-MM-DD HH:mm:ss');
                break;
              case 'year':
                startDate = moment(date).startOf('year').format('YYYY-MM-DD HH:mm:ss');
                endDate = moment(date).endOf('year').format('YYYY-MM-DD HH:mm:ss');
                break;
            }
          }

          const newFilters = Object.assign({}, this.state.selectedFilters, {
            [filter.id]: {
              ...this.state.selectedFilters[filter.id],
              startDate: startDate,
              endDate: endDate
            }
          });
          this.setState({
            selectedFilters: newFilters
          });
        } }
      />
    );
  };

  renderCalendarMode = (filter: any): JSX.Element => {
    const { selectedFilters, mode } = this.state;
    const modes = filter?.options ? filter.options : [];

    return (
      <div key={ `${filter.label}-${filter.id}` }>
        <Tooltip title={ 'Change Mode' }>
          <Select
            style={{ width: 200, margin: 5 }}
            placeholder={ 'Mode' }
            autoFocus
            value={ mode }
            onChange={ (value: Mode) => {
              const calendarFilterOption = filter.options.find((option: any) => option.id === value);
              const defaultPreset = calendarFilterOption?.default_preset ? calendarFilterOption?.default_preset : null;
              const { startDate, endDate } = this.getDefaultModeDates(defaultPreset);

              this.mounted && this.setState({
                mode: value,
                prevDatePickerPreset: null,
                datePickerPreset: defaultPreset,
                selectedFilters: _.isEmpty(value)
                  ? _.omit(selectedFilters, filter.id)
                  : { ...selectedFilters, [filter.id]: { value: value, startDate: startDate, endDate: endDate } }
              });
            } }
          >
            { modes.map((_mode: any) => (
              <Select.Option key={ `${_mode.id}_${_mode.title}` } value={ _mode.id }>{ _mode.title }</Select.Option>
            )) }
          </Select>
        </Tooltip>
        { this.renderCalendarModeDatePicker(filter, mode) }
      </div>
    );
  };

  renderCalendarControlPanel = (filteredData: any): JSX.Element => {
    const { intl: { formatMessage } } = this.props;
    const { data, itemsPerPage, currentPage, showFilter, generalFilters, requiredFilters } = this.state;

    return (
      <div id="CalendarControlPanel">
        <div className="d-f jc-sb ai-c mB-20 mT-5">
          <div className="d-if">
            <Search
              disabled={ _.isEmpty(data) }
              placeholder={ formatMessage(messages.quick_filter) }
              style={{ width: 300 }}
              onBlur={ event => {
                this.mounted && this.setState({
                  quickFilter: event.target.value || null,
                  currentPage: 1,
                });
              }}
              onSearch={ value => {
                this.mounted && this.setState({
                  quickFilter: value || null,
                  currentPage: 1,
                });
              }}
            />
          </div>
        </div>
        <div className="d-f jc-sb ai-c mB-10" style={{ userSelect: 'none' }}>
          <div className="d-if mL-10">
            <span>{ formatMessage(messages.show) }</span>
            <span className="mL-10 mR-10">
              <Select
                disabled={ _.isEmpty(data) }
                size={ 'small' }
                onChange={ (value: number) => {
                  this.mounted && this.setState({
                    currentPage: 1,
                    itemsPerPage: value
                  });
                } }
                defaultValue={ itemsPerPage }
              >
                <Option value={ 25 }>25</Option>
                <Option value={ 50 }>50</Option>
                <Option value={ 100 }>100</Option>
              </Select>
            </span>
            <span>{ formatMessage(messages.entries_of) } <b>{ filteredData.length || 0 }</b></span>
            <span
              className={ classNames('mL-35 link', {
                'active': showFilter && !_.isEmpty(generalFilters)
              }) }
              onClick={ () => {
                this.mounted && this.setState({
                  showFilter: !showFilter
                });
              } }
            >
              <Icon component={ FilterIcon } />
              <span>{ formatMessage(messages.filter) }</span>
            </span>
          </div>
          <div className="d-if">
            <Pagination
              disabled={ _.isEmpty(filteredData) }
              showSizeChanger={ false }
              current={ currentPage }
              total={ filteredData.length }
              pageSize={ itemsPerPage }
              onChange={ page => {
                this.setState({
                  currentPage: page
                });
              } }
            />
          </div>
        </div>
        { showFilter && !_.isEmpty(generalFilters) &&
          <div className="d-f fxw-w mB-10">
            { this.renderFilters(generalFilters) }
          </div>
        }
        <div className="d-f fxw-w mB-10">
          { !_.isEmpty(requiredFilters) &&
            <>
              { this.renderFilters(requiredFilters) }
            </>
          }
          { ((showFilter && !_.isEmpty(generalFilters)) || !_.isEmpty(requiredFilters)) &&
            <Button
              type='primary'
              className="m-5"
              onClick={ () => this.setState({ datePickerOpen: false }, () => this.getCalendarData()) }
              disabled={ this.state.isLoading }
            >
              { 'Apply Filters' }
            </Button>
          }
        </div>
      </div>
    );
  };

  renderColumnTitle = (column: ListColumns, data: any[], index: number) => {
    let label: React.ReactNode = column.label;

    if (!index) {
      label = (
        <>
          <span>{ column.label }</span>
          { !_.isEmpty(data) &&
            <Tooltip
              placement="top"
              title={ _.isEmpty(this.state.expandedRowKeys) ? 'Expand all' : 'Collapse all' }
            >
              <button
                type="button"
                style={{
                  marginTop: '2.5005px',
                  marginRight: '8px',
                  backgroundColor: '#fff',
                }}
                className={ classNames('ant-table-row-expand-icon', {
                  'ant-table-row-expand-icon-collapsed': _.isEmpty(this.state.expandedRowKeys),
                  'ant-table-row-expand-icon-expanded': !_.isEmpty(this.state.expandedRowKeys),
                }) }
                onClick={ () => {
                  if (_.isEmpty(this.state.expandedRowKeys)) {
                    this.setState({ expandedRowKeys: this.getFlatten(data).map((_data: any) => _data?.key) });
                  } else {
                    this.setState({ expandedRowKeys: [] });
                  }
                } }
              />
            </Tooltip>
          }
        </>
      );
    }

    return label;
  };

  recordColumnMapping = (columns: ListColumns[] = [], data: any[]) => {
    return columns && columns.map((column: ListColumns, index: number) => {
      const alignment = (column: ListColumns) => {
        if (_.has(column, 'align')) {
          return column.align;
        }
        return ['number', 'integer', 'currency', 'area', 'sublist'].includes(column.type) ? 'right' : 'left';
      };

      return {
        key: column?.key || column?.id,
        dataIndex: column?.dataIndex || column?.key || column?.id,
        title: this.renderColumnTitle(column, data, index),
        options: _.has(column, 'options') ? column.options : [],
        type: column.type,
        fixed: _.has(column, 'fixed') && !!column?.fixed ? column?.fixed : false,
        align: alignment(column),
        width: _.has(column, 'width') ? column.width : 200,
        filterable: _.has(column, 'filterable') ? column.filterable : false,
        sorter: _.has(column, 'sorter') ? column.sorter : false,
        ellipsis: _.has(column, 'ellipsis') ? column.ellipsis : false,
        render: (field: ListDataValues) => {

          if (_.isEmpty(field) || !_.has(field, 'type')) return <>-</>;

          switch (field.type) {
            case 'text':
            case 'string':
            case 'nested':
              return <span>{ _.has(field, 'value') && !!field.value ? field.value : '-' }</span>;

            case 'route':
              if (!_.has(field, 'value.title')) return <>-</>;
              if (!_.has(field, 'value.path') || !field.value.path) return <>{ field.value.title }</>;

              return (
                <RouterLink className='d-f primaryColor' to={ field.value.path }>
                  { field.value.title }
                </RouterLink>
              );

            case 'sublist':
              if (!field.value || _.isEmpty(field.value)) {
                return (
                  <div className="ta-r">-</div>
                );
              } else {
                const modalColumns = column.sublistColumns && column.sublistColumns.map((column: ListColumns, index: number) => {
                  return {
                    key: column?.key || column?.id,
                    dataIndex: column?.dataIndex || column?.key || column?.id,
                    title: _.has(column, 'label') ? column.label : '',
                    options: _.has(column, 'options') ? column.options : [],
                    type: column.type,
                    fixed: _.has(column, 'fixed') && !!column?.fixed ? column?.fixed : false,
                    width: _.has(column, 'width') ? column.width : 200,
                    filterable: _.has(column, 'filterable') ? column.filterable : false,
                    sorter: _.has(column, 'sorter') ? column.sorter : false,
                    ellipsis: _.has(column, 'ellipsis') ? column.ellipsis : false,
                    render: (field: ListDataValues, row: any) => {
                      if (_.isEmpty(field) || !_.has(field, 'type')) return <>-</>;

                      switch (field.type) {
                        case 'text':
                        case 'string':
                          return <span>{ _.has(field, 'value') && !!field.value ? field.value : '-' }</span>;

                        case 'route':
                          if (!_.has(field, 'value.title')) return <>-</>;
                          if (!_.has(field, 'value.path') || !field.value.path) return <>{ field.value.title }</>;

                          return (
                            <RouterLink className='d-f primaryColor' to={ field.value.path }>
                              { field.value.title }
                            </RouterLink>
                          );
                        case 'datetime':
                          if (!field.value) {
                            return '-';
                          }
                          if (_.has(field, 'format') && field?.format === 'date') {
                            return <div>{ getFormatedDate(field.value, undefined, false) }</div>;
                          }
                          return <div>{ getFormatedDate(field.value, undefined, true) }</div>;
                      }
                    }
                  };
                });

                return (
                  <div
                    className="primaryColor cur-p"
                    onClick={ () => {
                      this.setState({
                        dialog: {
                          modalTitle: column.label,
                          dataSource: field.value,
                          columns: modalColumns,
                          display: true
                        }
                      });
                    } }
                  >
                    { field.value.length === 1 ? field.value[0].title : field.value.length }
                  </div>
                );
              }
          }
        }
      };
    });
  };

  getAnnualColumns = (filter: any): CalendarColumn[] => {
    const { startDate, endDate } = filter;
    const months: CalendarColumn[] = [];

    if (startDate && endDate) {
      const date = moment(startDate).startOf('month');
      const yearFormat = getUserSetting('date_format').replaceAll('/DD', '').replaceAll('/DD/', '').replaceAll('DD/', '');

      while (date < moment(endDate).endOf('month')) {
        const column = date.format(yearFormat);
        months.push({ label: column, dataIndex: date.format('YYYY-MM') });
        date.add(1, 'month');
      }
    }

    return months;
  };

  getQuarterlyColumns = (filter: any): CalendarColumn[] => {
    const { startDate, endDate } = filter;
    const quarters: CalendarColumn[] = [];

    if (startDate && endDate) {
      const date = moment(startDate).startOf('year');

      while (date < moment(endDate).endOf('year')) {
        ['Q1', 'Q2', 'Q3', 'Q4'].map((_quarter: string) => {
          quarters.push({ label: `${_quarter} ${date.format('YYYY')}`, dataIndex: `${_quarter}-${date.format('YYYY')}` });
        });
        date.add(1, 'year');
      }
    }

    return quarters;
  };

  getWeeklyColumns = (filter: any): CalendarColumn[] => {
    const { startDate, endDate } = filter;
    const weeks: CalendarColumn[] = [];

    if (startDate && endDate) {
      const date = moment(startDate);
      const dateFormat = getUserSetting('date_format');

      while (date < moment(endDate)) {
        weeks.push({ label: date.format(`[w/c] WW - ${dateFormat}`), dataIndex: date.format('WW-YY') });
        date.add(1, 'weeks');
      }
    }

    return weeks;
  };

  getDailyColumns = (filter: any): CalendarColumn[] => {
    const { startDate, endDate } = filter;
    const days: CalendarColumn[] = [];

    if (startDate && endDate) {
      const date = moment(startDate);
      const dateFormat = getUserSetting('date_format');

      while (date < moment(endDate)) {
        const column = date.format(dateFormat);
        days.push({ label: column, dataIndex: date.format('YYYY-MM-DD') });
        date.add(1, 'days');
      }
    }

    return days;
  };

  getCalendarColumns = (mode: any, filter: any): CalendarColumn[] => {
    switch (mode) {
      case Mode.Annual:
      case Mode.Monthly:
        return this.getAnnualColumns(filter);

      case Mode.Quarterly:
        return this.getQuarterlyColumns(filter);

      case Mode.Weekly:
        return this.getWeeklyColumns(filter);

      case Mode.Daily:
        return this.getDailyColumns(filter);
    };

    return [];
  };

  getDefaultModeDates = (value: string | null) => {
    let startDate = null;
    let endDate = null;

    switch (value) {
      case 'next_7_days':
        startDate = moment();
        endDate = moment().add(7, 'days');
        break;
      case 'next_31_days':
        startDate = moment();
        endDate = moment().add(31, 'days');
        break;
      case 'next_4_weeks':
        startDate = moment();
        endDate = moment().add(4, 'weeks');
        break;
      case 'next_3_months':
        startDate = moment();
        endDate = moment().add(2, 'months');
        break;
      case 'next_12_months':
        startDate = moment().startOf('year');
        endDate = moment().startOf('year').add(12, 'months');
        break;
      case 'next_1_year':
        startDate = moment().startOf('year');
        endDate = moment().startOf('year').add(11, 'months');
        break;
      case 'next_3_years':
        startDate = moment();
        endDate = moment().endOf('year').add(2, 'years');
        break;
    }

    return { startDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : null, endDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : null };
  };

  isPickerMode = (): boolean => {
    const { datePickerPreset } = this.state;

    if (['next_7_days', 'next_31_days', 'next_4_weeks', 'next_3_months', 'next_12_months', 'next_3_years', 'custom_date'].includes(!!datePickerPreset ? datePickerPreset : 'custom_date')) {
      return true;
    }

    return false;
  };

  isCurrentDateColumn = (column: any, item: string): boolean => {
    const { mode } = this.state;

    switch (mode) {
      case Mode.Annual:
      case Mode.Monthly:
        const currentYear = moment().format('YYYY-MM');
        if (column.dataIndex === currentYear) {
          return true;
        }
        break;

      case Mode.Quarterly:
        const currentQuarter = `Q${getQuarterByMonth(Number(moment().format('M')))}-${moment().format('YYYY')}`;
        if (column.dataIndex === currentQuarter) {
          return true;
        }
        break;

      case Mode.Weekly:
        const currentWeek = moment().format('WW-YY');
        if (column.dataIndex === currentWeek) {
          return true;
        }
        break;

      case Mode.Daily:
        const currentDay = moment().format('YYYY-MM-DD');
        if (column.dataIndex === currentDay) {
          return true;
        }
        break;
    };

    return false;
  };

  render = (): JSX.Element => {
    const { isLoading, currentPage, itemsPerPage, expandedRowKeys, calendarColumns, data, quickFilter, isFetching, dialog } = this.state;

    let _data = [ ...data ];

    // Quickfilter
    if (quickFilter) {
      _data = this.quickFilter(_data, quickFilter);
    }

    return (
      <div>
        <div>
          { this.renderCalendarControlPanel(_data) }
        </div>
        <BlockingSpinner isLoading={ isFetching } style={{ minHeight: '50vh' }}>
          <div className='Layout-box'>
            <Table
              sticky
              bordered
              pagination={ false }
              size={ 'small' }
              scroll={ {
                x: calendarColumns.reduce((acc: any, curr: any) => acc += curr.width, 0)
              } }
              loading={ {
                spinning: isLoading,
                indicator: <BlockingSpinner isLoading />,
              } }
              rowKey={ record => record.key }
              columns={ calendarColumns }
              dataSource={ this.paginageItems(_data, currentPage, itemsPerPage) }
              expandable={ {
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded: boolean, row: any) => {
                  if (expanded) {
                    this.setState({
                      expandedRowKeys: [ ...expandedRowKeys, row.key ]
                    });
                  } else {
                    this.setState({
                      expandedRowKeys: !_.isEmpty(expandedRowKeys) ? expandedRowKeys.filter((rowKey) => rowKey !== row.key) : expandedRowKeys
                    });
                  }
                },
              } }
            />
          </div>
          { !!dialog?.display && this.renderDialog(dialog) }
        </BlockingSpinner>
      </div>
    );
  };
};

export default (injectIntl(CalendarView));