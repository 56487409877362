// Libs
import classNames from 'classnames';
import * as React from 'react';
import { Tabs } from 'antd';
import _ from 'lodash';

// Interfaces
import { Tab } from './TabView.interface';

// Styles
import './TabView.scss';

const { TabPane } = Tabs;

interface Props {
  activeTab?: string;
  defaultActiveTab?: string;
  tabs?: Tab[];
  onChange?: (tab: string) => void;
  destroyInactiveTabPane?: boolean;
  transparent?: boolean;
  barStyle?: React.CSSProperties;
  paneStyle?: React.CSSProperties;
};

interface State {
  activeKey: string
};

export class TabView extends React.Component<Props, State> {

  state: State = {
    activeKey: this.props.activeTab ? _.snakeCase(this.props.activeTab).toLowerCase() : this.props.defaultActiveTab ? this.props.defaultActiveTab : this.props.tabs && !_.isEmpty(this.props.tabs) ? (this.props.tabs[0].key || _.snakeCase(this.props.tabs[0].label).toLowerCase()) : ''
  };

  componentDidUpdate = (prevProps: Props) => {
    if (!!this.props.activeTab && prevProps.activeTab !== this.props.activeTab) {
      this.setState({
        activeKey: this.props.activeTab
      });
    }
  };

  // Used in FormWrapper
  forwardRefChangeTab = (tab: string, callback: any) => {
    this.setState({
      activeKey: tab
    }, () => {
      // Hack to wait for render
      setTimeout(() => callback(), 300);
    });
  };

  renderTabBar = (props: any, DefaultTabBar: any) => {
    return (
      <div
        id="TabView-bar"
        className={ classNames('TabView-bar', {
          'TabView-bar--transparent': !!this.props.transparent,
        }) }
        style={ this.props.barStyle }
      >
        <DefaultTabBar {...props} />
      </div>
    );
  };

  renderTab = (tab: Tab) => {
    const { classes = '', label, required } = tab;

    if (required) {
      return (
        <span className={ classes }>
          { label }
          <span className="text-required mL-2">*</span>
        </span>
      );
    }

    return <span className={classes}>{label}</span>;
  };

  render = () => {
    const { tabs, onChange, destroyInactiveTabPane } = this.props;
    const { activeKey } = this.state;
    return (
      <>
        { tabs && (
          <Tabs
            activeKey={ _.snakeCase(activeKey).toLowerCase() }
            renderTabBar={ this.renderTabBar }
            tabBarStyle={{ margin: 0 }}
            animated={ false }
            destroyInactiveTabPane={ !!destroyInactiveTabPane }
            onChange={ tabKey => {
              // No need to trigger a change if already on the correct tab
              if (activeKey === tabKey) return;

              this.setState({
                activeKey: tabKey
              }, () => {
                onChange && onChange(tabKey);
              });
            } }
          >
            { tabs.map((tab) => (
              <TabPane
                forceRender={ tab?.preRender || false }
                key={ _.snakeCase(tab.key).toLowerCase() || _.snakeCase(tab.label).toLowerCase() }
                className="TabView-pane"
                tab={ this.renderTab(tab) }
                disabled={ tab.disabled || false }
                style={ this.props.paneStyle }
              >
                { tab.node }
              </TabPane>
            ) ) }
          </Tabs>
        ) }
      </>
    );
  };
};