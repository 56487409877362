// Libs
import React, { Component } from 'react';
import _ from 'lodash';

// Components
import { Empty } from 'antd';
import FileField from 'components/form/field/dynamic/fields/FileField';
import NumberField from 'components/form/field/dynamic/fields/NumberField';
import TextAreaField from 'components/form/field/dynamic/fields/TextAreaField';
import TextField from 'components/form/field/dynamic/fields/TextField';
import SelectField from 'components/form/field/dynamic/fields/SelectField';
import SliderField from 'components/form/field/dynamic/fields/SliderField';
import ShiftField from 'components/form/field/dynamic/fields/ShiftField';
import OpeningHoursField from 'components/form/field/dynamic/fields/OpeningHourField';
import DateTimeField from 'components/form/field/dynamic/fields/DateTimeField';

// Interfaces
import { DynamicField } from 'components/form/field/dynamic/Dynamic.interface';
import { RecordFormEntity } from 'types/entities';
import { FormField, FormFieldInfoBoxModifiedMessage, FormFieldInfoBoxErrorMessage } from "components/form/form-wrapper";

interface Props {
  clientId: number;
  originalState?: any;
  record?: RecordFormEntity;
  field?: FormField;
  dynamicField: DynamicField;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  isDisabled?: boolean;
  isPreviewing?: boolean;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  onChange(field: DynamicField): void;
  onComment(dynamicField: DynamicField, comment: string | null): void;
  onUpload(dynamicFieldId: number, fieldRecord: any, callback?: () => void): void;
  onRemove(field: DynamicField, file: any): void;
  onCreateAction(field: DynamicField): void;
  onScore(field: DynamicField): void;
};

class Scoring extends Component<Props> {

  getFlatten = (data: any[] = []) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({ ..._value });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  appendKey = (data: any = []) => {
    return data || [].map((entity: any) => {
      const appendChildrenKeys = (children: any) => {

        // Prevent nesting
        if (_.isEmpty(children)) return null;

        return children.map((childEntity: any) => {
          return {
            ...childEntity,
            'key': childEntity.id,
            'children': appendChildrenKeys(childEntity.children),
          };
        });
      };

      return {
        ...entity,
        'key': entity.id,
        'children': appendChildrenKeys(entity.children),
      };
    });
  };

  render = () => {
    const {
      clientId,
      field,
      record,
      fieldErrorMessages,
      fieldModifiedMessages,
      isDisabled,
      isPreviewing,
      originalState,
      dynamicField,
      setFieldModifiedMessage,
      setFieldErrorMessage,
      onChange,
      onComment,
      onUpload,
      onRemove,
      onCreateAction,
      onScore
    } = this.props;

    const originalDynamicField = this.getFlatten(this.appendKey(originalState)).find((_field: DynamicField) => _field.id === dynamicField.id);
    const extensions: string[] = field?.extensions ? field.extensions : [];
    const isLocked = !!isDisabled;

    // Hack to be able to compare strings and floats for scoring
    if (_.has(originalDynamicField, 'scoring.value') && originalDynamicField.scoring.value !== null) {
      originalDynamicField.scoring.value = parseFloat(`${originalDynamicField.scoring.value}`);
    }

    if (_.has(dynamicField, 'scoring.value') && dynamicField.scoring.value !== null) {
      dynamicField.scoring.value = parseFloat(`${dynamicField.scoring.value}`);
    }

    switch (dynamicField.type) {
      case 'file':
        return (
          <FileField
            key={ dynamicField.id }
            clientId={ clientId }
            dynamicField={ dynamicField }
            originalDynamicField={ originalDynamicField }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            isLocked={ isLocked || !!isPreviewing }
            isPreviewing={ !!isPreviewing }
            extensions={ extensions }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ (dynamicField: DynamicField) => onChange(dynamicField) }
            onComment={ (comment: string | null) => onComment(dynamicField, comment) }
            onUpload={ (fileRecord: any, callback?: () => void) => onUpload(dynamicField.id, fileRecord, callback) }
            onRemove={ (file: any) => onRemove(dynamicField, file) }
            onCreateAction={ (dynamicField: DynamicField) => onCreateAction(dynamicField) }
            onScore={ (dynamicField: DynamicField) => onScore(dynamicField) }
          />
        );
      case 'number':
        return (
          <NumberField
            key={ dynamicField.id }
            clientId={ clientId }
            dynamicField={ dynamicField }
            originalDynamicField={ originalDynamicField }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            isLocked={ isLocked }
            isPreviewing={ !!isPreviewing }
            extensions={ extensions }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ (dynamicField: DynamicField) => onChange(dynamicField) }
            onComment={ (comment: string | null) => onComment(dynamicField, comment) }
            onUpload={ (fileRecord: any, callback?: () => void) => onUpload(dynamicField.id, fileRecord, callback) }
            onRemove={ (file: any) => onRemove(dynamicField, file) }
            onCreateAction={ (dynamicField: DynamicField) => onCreateAction(dynamicField) }
            onScore={ (dynamicField: DynamicField) => onScore(dynamicField) }
          />
        );
      case 'opening_hour':
        return (
          <OpeningHoursField
            key={ dynamicField.id }
            clientId={ clientId }
            dynamicField={ dynamicField }
            originalDynamicField={ originalDynamicField }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            isLocked={ isLocked }
            isPreviewing={ !!isPreviewing }
            extensions={ extensions }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ (dynamicField: DynamicField) => onChange(dynamicField) }
            onComment={ (comment: string | null) => onComment(dynamicField, comment) }
            onUpload={ (fileRecord: any, callback?: () => void) => onUpload(dynamicField.id, fileRecord, callback) }
            onRemove={ (file: any) => onRemove(dynamicField, file) }
            onCreateAction={ (dynamicField: DynamicField) => onCreateAction(dynamicField) }
            onScore={ (dynamicField: DynamicField) => onScore(dynamicField) }
          />
        );
      case 'select':
        return (
          <SelectField
            key={ dynamicField.id }
            clientId={ clientId }
            record={ record }
            dynamicField={ dynamicField }
            originalDynamicField={ originalDynamicField }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            isLocked={ isLocked }
            isPreviewing={ !!isPreviewing }
            extensions={ extensions }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ (dynamicField: DynamicField) => onChange(dynamicField) }
            onComment={ (comment: string | null) => onComment(dynamicField, comment) }
            onUpload={ (fileRecord: any, callback?: () => void) => onUpload(dynamicField.id, fileRecord, callback) }
            onRemove={ (file: any) => onRemove(dynamicField, file) }
            onCreateAction={ (dynamicField: DynamicField) => onCreateAction(dynamicField) }
            onScore={ (dynamicField: DynamicField) => onScore(dynamicField) }
          />
        );
      case 'slider':
        return (
          <SliderField
            key={ dynamicField.id }
            clientId={ clientId }
            dynamicField={ dynamicField }
            originalDynamicField={ originalDynamicField }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            isLocked={ isLocked }
            isPreviewing={ !!isPreviewing }
            extensions={ extensions }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ (dynamicField: DynamicField) => onChange(dynamicField) }
            onComment={ (comment: string | null) => onComment(dynamicField, comment) }
            onUpload={ (fileRecord: any, callback?: () => void) => onUpload(dynamicField.id, fileRecord, callback) }
            onRemove={ (file: any) => onRemove(dynamicField, file) }
            onCreateAction={ (dynamicField: DynamicField) => onCreateAction(dynamicField) }
            onScore={ (dynamicField: DynamicField) => onScore(dynamicField) }
          />
        );
      case 'shift':
        return (
          <ShiftField
            key={ dynamicField.id }
            clientId={ clientId }
            dynamicField={ dynamicField }
            originalDynamicField={ originalDynamicField }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            isLocked={ isLocked }
            isPreviewing={ !!isPreviewing }
            extensions={ extensions }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ (dynamicField: DynamicField) => onChange(dynamicField) }
            onComment={ (comment: string | null) => onComment(dynamicField, comment) }
            onUpload={ (fileRecord: any, callback?: () => void) => onUpload(dynamicField.id, fileRecord, callback) }
            onRemove={ (file: any) => onRemove(dynamicField, file) }
            onCreateAction={ (dynamicField: DynamicField) => onCreateAction(dynamicField) }
            onScore={ (dynamicField: DynamicField) => onScore(dynamicField) }
          />
        );
      case 'textarea':
        return (
          <TextAreaField
            key={ dynamicField.id }
            clientId={ clientId }
            dynamicField={ dynamicField }
            originalDynamicField={ originalDynamicField }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            isLocked={ isLocked }
            isPreviewing={ !!isPreviewing }
            extensions={ extensions }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ (dynamicField: DynamicField) => onChange(dynamicField) }
            onComment={ (comment: string | null) => onComment(dynamicField, comment) }
            onUpload={ (fileRecord: any, callback?: () => void) => onUpload(dynamicField.id, fileRecord, callback) }
            onRemove={ (file: any) => onRemove(dynamicField, file) }
            onCreateAction={ (dynamicField: DynamicField) => onCreateAction(dynamicField) }
            onScore={ (dynamicField: DynamicField) => onScore(dynamicField) }
          />
        );
      case 'text':
        return (
          <TextField
            key={ dynamicField.id }
            clientId={ clientId }
            dynamicField={ dynamicField }
            originalDynamicField={ originalDynamicField }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            isLocked={ isLocked }
            isPreviewing={ !!isPreviewing }
            extensions={ extensions }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ (dynamicField: DynamicField) => onChange(dynamicField) }
            onComment={ (comment: string | null) => onComment(dynamicField, comment) }
            onUpload={ (fileRecord: any, callback?: () => void) => onUpload(dynamicField.id, fileRecord, callback) }
            onRemove={ (file: any) => onRemove(dynamicField, file) }
            onCreateAction={ (dynamicField: DynamicField) => onCreateAction(dynamicField) }
            onScore={ (dynamicField: DynamicField) => onScore(dynamicField) }
          />
        );
      case 'datetime':
        return (
          <DateTimeField
            key={ dynamicField.id }
            clientId={ clientId }
            dynamicField={ dynamicField }
            originalDynamicField={ originalDynamicField }
            fieldErrorMessages={ fieldErrorMessages }
            fieldModifiedMessages={ fieldModifiedMessages }
            isLocked={ isLocked }
            isPreviewing={ !!isPreviewing }
            extensions={ extensions }
            setFieldModifiedMessage={ setFieldModifiedMessage }
            setFieldErrorMessage={ setFieldErrorMessage }
            onChange={ (dynamicField: DynamicField) => onChange(dynamicField) }
            onComment={ (comment: string | null) => onComment(dynamicField, comment) }
            onUpload={ (fileRecord: any, callback?: () => void) => onUpload(dynamicField.id, fileRecord, callback) }
            onRemove={ (file: any) => onRemove(dynamicField, file) }
            onCreateAction={ (dynamicField: DynamicField) => onCreateAction(dynamicField) }
            onScore={ (dynamicField: DynamicField) => onScore(dynamicField) }
          />
        );
      default:
        return <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />;
    }
  };
};

export default Scoring;