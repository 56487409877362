// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { IDashboard } from 'components/dashboard/Dashboard.interface';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import Dropdown from 'components/dropdown';
import DashboardComponent from 'components/dashboard';
import BasicList from "components/basic-list";

// Services
import { Api } from 'services/api';
import history from 'utils/history';

// Actions
import {
  setBreadcrumbs,
  setBreadcrumbsLoading,
} from 'store/UI/ActionCreators';

const API: Api = new Api();

interface Props {
  client_id: number;
  record: RecordFormEntity;
  dashboardId?: string;
  permissions: any;
  isPrimarySidebarCollapsed: boolean;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  dashboards: IDashboard[];
  dashboard: IDashboard | null;
  isFetching: boolean;
};

class DashboardView extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    dashboards: [],
    dashboard: null,
    isFetching: false,
  };

  componentDidMount = async () => {
    const { record, client_id, dashboardId } = this.props;

    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      if (dashboardId) {
        const dashboard = await API.get(`client/${client_id}/${_.kebabCase(record.bundle)}/${_.kebabCase(record.type)}/${record.id}/dashboard/${dashboardId}`);
        this.setState({
          dashboard: dashboard
        });
      } else {
        const dashboards = await API.get(`client/${client_id}/${_.kebabCase(record.bundle)}/${_.kebabCase(record.type)}/${record.id}/dashboard`);
        // Redirect if only one dashboard
        if (!_.isEmpty(dashboards) && dashboards.length === 1) {
          history.push(`dashboards/${dashboards[0].id}`);
        } else {
          this.setState({
            dashboards: dashboards
          });
        }
      }

    } catch (error) {
      console.error('Error: ', error);
    } finally {
        this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderDashboard = (dashboard: IDashboard) => {
    return (
      <DashboardComponent
        record={ this.props.record }
        clientId={ this.props.client_id }
        dashboard={ dashboard }
        isPrimarySidebarCollapsed={ this.props.isPrimarySidebarCollapsed }
        readOnly
      />
    );
  };

  renderDashboards = (dashboards: IDashboard[]) => {
    return (
      <BasicList
        rawData
        isLoading={ this.state.isFetching }
        columns={ [
          {
            key: 'title',
            dataIndex: 'title',
            title: 'Title',
            filterable: true,
            render: (__: any, dashboard: IDashboard) => {
              return (
                <Link className='primaryColor' to={ `dashboards/${dashboard.id}` }>
                  { dashboard.title }
                </Link>
              );
            },
          }
        ] }
        items={ dashboards.map((dashboard: any, index: number) => {
          return {
            'key': index,
            ...dashboard,
          };
        } ) }
      />
    );
  };

  render = () => {
    const { dashboardId } = this.props;
    const { dashboards, dashboard, isFetching } = this.state;

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ this.props.record?.title }
          content={ dashboardId && !!dashboard ? dashboard.title : 'Dashboards' }
          tabs={ [
            {
              label: '',
              node: dashboardId && !!dashboard ? this.renderDashboard(dashboard) : this.renderDashboards(dashboards),
            },
          ] }
          rightActions={ [
            {
              node: (
                <Dropdown actions={ [] } />
              )
            }
          ] }
        />
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    isPrimarySidebarCollapsed: store.UIState.isPrimarySidebarCollapsed,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
