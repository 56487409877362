// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Modal, Form, Select, Input, Tabs, Table, Popconfirm, Button, Tooltip, Checkbox } from 'antd';
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import BadgeComponent, { BadgeType } from 'components/badge/Badge';
import BlockingSpinner from 'components/blocking-spinner';
import FieldWrapper from 'components/form/field/field-wrapper';

// Service
import Notification from 'services/notification';
import { Api } from 'services/api';

// Interfaces
import {
  ConditionType,
  Entity,
  NotificationTemplate,
  NotificationTrigger,
  NotificationCondition,
  RecipientType,
  Tool,
  DeliveryMethodType,
} from './Notifications.interfaces';
import { Workflow } from 'components/workflow/Workflow.interface';
import { RoleEntity, UserEntity } from 'types/entities';

// Utils
import { transformFieldTitle } from 'utils/workflow';
import { orderListByKey } from 'utils/formSetup';

// Condition Types
import { conditionTypes } from './NotificationTemplate';

// Styles
import './Notifications.scss';

enum TabPaneType {
  Basic = 'basic',
  Conditions = 'conditions',
  Condition_details = 'condition_details',
  Recipients = 'recipients',
  Recipient_details = 'recipient_details',
};

interface Props {
  clientId: number;
  onCreate(payload: {
    template: Partial<NotificationTemplate>;
    conditions: NotificationCondition[];
    recipients: Array<{ type: RecipientType; target_id: number }>;
    deliveryMethods: Array<keyof typeof DeliveryMethodType>;
  }): Promise<void>;
  onClose(): void;
};

interface State {
  template: Partial<NotificationTemplate>;
  isFetchingTemplate: boolean;
  activeTab: TabPaneType;
  notificationTriggers: NotificationTrigger[];
  deliveryMethods: Array<keyof typeof DeliveryMethodType>;
  tools: Tool[];
  entities: Entity[];
  workflows: Workflow[];
  roles: RoleEntity[];
  users: UserEntity[];
  recipients: Array<{ id: number; type: RecipientType; target_id: number }>;
  conditions: NotificationCondition[];
  newCondition: Partial<NotificationCondition>;
  newRecipient: Partial<{ id: number; type: RecipientType; target_id: number }>;
  isLoading: boolean;
  isConditionsInfoLoading: boolean;
  isRecipientsinfoLoading: boolean;
  isEditingCondition: boolean;
  isEditingRecipient: boolean;
};

const { TabPane } = Tabs;
const { TextArea } = Input;

const API: Api = new Api();

class CreateTemplateDialog extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    template: {},
    conditions: [],
    recipients: [],
    newCondition: {},
    newRecipient: {},
    deliveryMethods: [],
    notificationTriggers: [],
    tools: [],
    entities: [],
    workflows: [],
    roles: [],
    users: [],
    activeTab: TabPaneType.Basic,
    isFetchingTemplate: false,
    isLoading: false,
    isConditionsInfoLoading: false,
    isRecipientsinfoLoading: false,
    isEditingCondition: false,
    isEditingRecipient: false,
  };

  componentDidMount = async () => {
    const { clientId } = this.props;

    this.mounted = true;

    const loadConditionTabInfo = () => {
      this.mounted && this.setState({ isConditionsInfoLoading: true }, async () => {
        try {
          const entities = await API.get(`client/${clientId}/admin/notifications/template/entities`);
          const workflows = await API.get(`client/${clientId}/admin/workflows`);
          this.setState({ entities: entities, workflows: workflows });
        } catch (error) {
          console.error('Error: ', error);
        } finally {
          this.setState({ isConditionsInfoLoading: false });
        }
      });
    };

    const loadRecipientTabInfo = () => {
      this.mounted && this.setState({ isRecipientsinfoLoading: true }, async () => {
        try {
          const roles = await API.get(`client/${clientId}/admin/roles`);
          const users = await API.get(`client/${clientId}/admin/users`);
          this.setState({ roles: roles, users: users });
        } catch (error) {
          console.error('Error: ', error);
        } finally {
          this.setState({ isRecipientsinfoLoading: false });
        }
      });
    };

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));
      const triggers = await API.get(`client/${clientId}/admin/notifications/trigger`);
      const tools = await API.get(`client/${clientId}/admin/entity/tools`);
      loadConditionTabInfo();
      loadRecipientTabInfo();

      this.mounted && this.setState({
        notificationTriggers: triggers,
        tools: tools,
      });
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderBasicTab = () => {
    const { notificationTriggers, tools, deliveryMethods, template } = this.state;

    return (
      <Form layout="vertical">
        <Form.Item label="Title" required>
          <Input
            value={ template.title }
            onChange={ (e) => this.setState({ template: Object.assign(template, { title: e.target.value }) }) }
          />
        </Form.Item>
        <Form.Item label="Subject" required>
          <Input
            value={ template.subject }
            onChange={ (e) => this.setState({ template: Object.assign(template, { subject: e.target.value }) }) }
          />
        </Form.Item>
        <Form.Item label="Message" required>
          <TextArea
            value={ template.message }
            rows={ 4 }
            onChange={ (e) => this.setState({ template: Object.assign(template, { message: e.target.value }) }) }
          />
        </Form.Item>
        <Form.Item
          label={
            <div>
              <label>Trigger</label>
              <Tooltip
                className="mL-5"
                placement="top"
                title="The action performed that will trigger the notification to be sent"
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </div>
          }
          required
        >
          <Select
            showSearch
            value={ template?.notification_trigger_id }
            filterOption={ (input: any, option: any) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            } }
            onChange={ (triggerId: number) => {
              this.setState({ template: Object.assign(template, { notification_trigger_id: triggerId }) });
            } }
          >
            { orderListByKey(notificationTriggers, 'title').map((trigger: NotificationTrigger) => (
              <Select.Option key={ trigger.id } value={ trigger.id }>
                { trigger.title }
              </Select.Option>
            )) }
          </Select>
        </Form.Item>
        <Form.Item label="Tool Selection">
          <Select
            showSearch
            allowClear
            filterOption={ (input: any, option: any) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            } }
            onChange={ (toolId: number) => {
              this.setState({ template: Object.assign(template, { tool_id: toolId }) });
            } }
            value={ template?.tool_id || undefined }
          >
            { tools.map((tool: Tool) => (
              <Select.Option key={ tool.id } value={ tool.id }>
                { tool.label }
              </Select.Option>
            )) }
          </Select>
        </Form.Item>
        <Form.Item label="Delivery Method" required>
          { (Object.keys(DeliveryMethodType ) as Array<keyof typeof DeliveryMethodType>).map((method) => (
            <Checkbox
              key={ method }
              onChange={ (e) => {
                let cloneDeliveryMethods = _.cloneDeep(deliveryMethods);
                const shouldAddMethod = !deliveryMethods.find((element) => element === method);
                if (e.target.checked && shouldAddMethod) {
                  cloneDeliveryMethods.push(method);
                } else if(!e.target.checked) {
                  cloneDeliveryMethods = cloneDeliveryMethods.filter((element) => element !== method);
                }
                this.setState({ deliveryMethods: cloneDeliveryMethods });
              } }
              checked={ !!deliveryMethods.find((element) => element === method) }
            >
              { transformFieldTitle(method) }
            </Checkbox>
          )) }
        </Form.Item>
      </Form>
    );
  };

  renderConditionsTab = () => {
    const { conditions, isConditionsInfoLoading, entities, workflows } = this.state;

    const getBadges = (type: ConditionType, config: any): string[] => {
      if (type === ConditionType.ENTITY_TYPE && config.type && config.bundle) {
        const entityLabel = entities.find((e) => e.type === config.type)?.label;
        if (entityLabel) {
          return [entityLabel];
        }
      }
      if (type === ConditionType.WORKFLOW_STAGE && config.workflow_id && config.reference) {
        const workflow = workflows.find((w) => w.id === config.workflow_id);
        const stage = workflow?.stages.find((s) => s.reference === config.reference);
        if (workflow && stage) {
          return [workflow.title, stage.title];
        }
      }
      if (type === ConditionType.WORKFLOW_TRANSITION && config.workflow_id && config.reference) {
        const workflow = workflows.find((w) => w.id === config.workflow_id);
        const transition = _.flatMap(
          workflow?.stages,
          (stage) => stage.transitions
        ).find((s) => s.reference === config.reference);
        if (workflow && transition) {
          return [workflow.title, transition.title];
        }
      }
      return [];
    };

    const getConditionTypeTitle = (type: string) => {
      return conditionTypes.find(condition => condition.type === type)?.title || transformFieldTitle(type);
    };

    const columns: any = [
      {
        dataIndex: 'type',
        key: 'type',
        title: 'Type',
        render: (type: string) => {
          return <div>{ getConditionTypeTitle(type) }</div>;
        },
        ellipsis: true,
      },
      {
        dataIndex: 'config',
        key: 'config',
        title: 'Additional Config',
        render: (__: any, record: NotificationCondition) => (
          <div className="d-f fxw-w">
            { getBadges(record.type, record.config).map((item: string, i) => {
              return (
                <BadgeComponent
                  key={ item + i }
                  className={ 'mR-5 mB-5' }
                  type={ i === 0 ? BadgeType.Warning : BadgeType.Success }
                  text={ item }
                />
              );
            }) }
          </div>
        ),
        ellipsis: true,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: () => (
          <Button onClick={ () => this.setState({ activeTab: TabPaneType.Condition_details }) }>
            Create New
          </Button>
        ),
        render: (__: any, record: NotificationCondition) => {
          return (
            <>
              <EditOutlined
                className="link"
                style={{ fontSize: 18 }}
                onClick={ () => {
                  this.setState({
                    isEditingCondition: true,
                    newCondition: _.cloneDeep(record),
                    activeTab: TabPaneType.Condition_details,
                  });
                } }
              />
              <Popconfirm
                title={ 'Are you sure?' }
                icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                okButtonProps={ { danger: true } }
                placement="topRight"
                onConfirm={ () => {
                  this.setState({ conditions: conditions.filter((c) => c.id !== record.id) });
                } }
              >
                <DeleteOutlined className="mL-20 link" style={{ fontSize: 18 }} />
              </Popconfirm>
            </>
          );
        },
        width: 120,
        ellipsis: true,
        align: 'center',
      },
    ];
    return (
      <BlockingSpinner isLoading={ isConditionsInfoLoading }>
        <Table
          size={ 'small' }
          rowKey={ 'id' }
          pagination={ false }
          columns={ columns }
          dataSource={ conditions }
        />
      </BlockingSpinner>
    );
  };

  renderConditionDetailsTab = () => {
    const { newCondition, entities, workflows } = this.state;

    const groupedEntities: Array<{ label: string; data: Entity[] }> = _.uniqBy(
      entities,
      'bundle',
    ).map((entity) => ({
      label: entity.bundle,
      data: entities.filter((e) => e.bundle === entity.bundle),
    }));
    const stages = workflows.find((w) => w.id === newCondition.config?.workflow_id)?.stages;
    const transitions = _.flatMap(stages, stage => stage.transitions);
    // filtered workflows with transitions
    const workflowsWithTransitions = workflows.filter((workflow) => {
      return workflow.stages.some((stage) => !_.isEmpty(stage.transitions));
    });

    return (
      <Form layout="vertical">
        <FieldWrapper
          style={{ minHeight: 50, marginTop: 15 }}
          description="What type of condition should this be? Additional options may be presented below based on your selection."
          label="Type"
          required
        >
          <Select
            placeholder={ 'Please select the Type of condition' }
            value={ newCondition.type }
            onChange={ (conditionType: ConditionType) => {
              this.setState({ newCondition: Object.assign(newCondition, { type: conditionType, config: {} }) });
            } }
          >
            { conditionTypes.map((condition) => {
              return (
                <Select.Option key={ condition.type } value={ condition.type }>
                  <Tooltip title={ condition.tooltip } placement="right">
                    <div className="Notifications-Option">
                      <div className="Notifications-OptionText">
                        { condition.title }
                      </div>
                    </div>
                  </Tooltip>
                </Select.Option>
              );
            }) }
          </Select>
        </FieldWrapper>
        { newCondition.type === ConditionType.ENTITY_TYPE && (
          <FieldWrapper
            style={{ minHeight: 50 }}
            label="Record"
            description="Only send the notification when the record matches this type."
            required
          >
            <Select
              showSearch
              placeholder={ 'Please select the record type' }
              value={ newCondition.config?.type }
              filterOption={ (input: any, optionOrGroup: any) => {
                if (_.isArray(optionOrGroup.options)) {
                  return false;
                }
                return optionOrGroup.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              } }
              onChange={ (type: string) => {
                const bundle = entities.find((e) => e.type === type)?.bundle;
                if (bundle) {
                  this.setState({ newCondition: Object.assign(newCondition, { config: { bundle, type } }) });
                }
              } }
            >
              { groupedEntities.map((item, index) => {
                return (
                  <Select.OptGroup key={ item.label + index } label={ item.label }>
                    { item.data.map((entity, i) => {
                      return (
                        <Select.Option value={ entity.type } key={ entity.type + i }>
                          { entity.label }
                        </Select.Option>
                      );
                    }) }
                  </Select.OptGroup>
                );
              }) }
            </Select>
          </FieldWrapper>
        ) }
        { newCondition.type === ConditionType.WORKFLOW_STAGE && (
          <>
            <FieldWrapper
              style={{ minHeight: 50 }}
              label="Workflow"
              description="Only send the notification when the record uses this workflow."
              required
            >
              <Select
                placeholder={ 'Please select the workflow that contains the stage which, when reached, should trigger the notification' }
                value={ newCondition.config?.workflow_id }
                filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                onChange={ (workflowId: number) => {
                  this.setState({ newCondition: Object.assign(newCondition, { config: { workflow_id: workflowId } }) });
                } }
              >
                { workflows.map((workflow) => {
                  return (
                    <Select.Option value={ workflow.id } key={ workflow.id }>
                      { workflow.title }
                    </Select.Option>
                  );
                }) }
              </Select>
            </FieldWrapper>
            <FieldWrapper
              style={{ minHeight: 50 }}
              label="Stage"
              description="Only send the notification when the record is on this workflow stage."
              required
            >
              <Select
                placeholder={ 'Please select the stage which when reached will trigger the notification' }
                value={ newCondition.config?.reference }
                disabled={ !stages }
                onChange={ (reference: string) => {
                  this.setState({ newCondition: Object.assign(newCondition, { config: { ...newCondition.config, reference: reference } }) });
                } }
              >
                { stages?.map((stage) => {
                  return (
                    <Select.Option value={ stage.reference } key={ stage.id }>
                      { stage.title }
                    </Select.Option>
                  );
                }) }
              </Select>
            </FieldWrapper>
          </>
        ) }
        { newCondition.type === ConditionType.WORKFLOW_TRANSITION && (
          <>
            <FieldWrapper
              style={{ minHeight: 50 }}
              label="Workflow"
              description="Only send the notification when the record uses this workflow."
              required
            >
              <Select
                placeholder={ 'Please select the workflow that contains the stage which, when reached, should trigger the notification' }
                value={ newCondition.config?.workflow_id }
                filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                onChange={ (workflowId: number) => {
                  this.setState({ newCondition: Object.assign(newCondition, { config: { workflow_id: workflowId } }) });
                } }
              >
                { workflowsWithTransitions.map((workflow) => {
                  return (
                    <Select.Option value={ workflow.id } key={ workflow.id }>
                      { workflow.title }
                    </Select.Option>
                  );
                }) }
              </Select>
            </FieldWrapper>
            <FieldWrapper
              style={{ minHeight: 50 }}
              label="Transition"
              description="Only send the notification when the record uses this transition."
              required
            >
              <Select
                placeholder={ 'Please select the transition' }
                value={ newCondition.config?.reference }
                disabled={ _.isEmpty(transitions) }
                onChange={ (reference: string) => {
                  this.setState({ newCondition: Object.assign(newCondition, { config: { ...newCondition.config, reference: reference } }) });
                } }
              >
                { transitions?.map((transition) => (
                  <Select.Option value={ transition.reference } key={ transition.id }>
                    { transition.title }
                  </Select.Option>
                )) }
              </Select>
            </FieldWrapper>
          </>
        ) }
      </Form>
    );
  };

  renderConditionDetailsTabFooter = () => {
    const { isLoading, conditions, newCondition, isEditingCondition } = this.state;

    const isOkButtonDisabled = () => {
      const { type, config } = newCondition;
      // if the condition type is not selected, disable the create button
      if (!type) {
        return true;
      }

      // if the condition type is ENTITY_TYPE and no additional configuration is selected, disable the create button
      if (type === ConditionType.ENTITY_TYPE && _.isEmpty(config)) {
        return true;
      }

      // if the condition type is WORKFLOW_STAGE and no additional configuration is selected, disable the create button
      if (type === ConditionType.WORKFLOW_STAGE && _.isEmpty(config?.reference)) {
        return true;
      }

      // if the condition type is WORKFLOW_TRANSITION and no additional configuration is selected, disable the create button
      if (type === ConditionType.WORKFLOW_TRANSITION && _.isEmpty(config?.reference)) {
        return true;
      }
    };

    const onSubmitHandler = () => {
      const condition = { id: new Date().getTime(), ...newCondition } as NotificationCondition;;
      const updatedConditions = isEditingCondition
        ? conditions.map((c) => (c.id === condition.id ? condition : c))
        : [...conditions, condition];
      this.setState({
        conditions: updatedConditions,
        activeTab: TabPaneType.Conditions,
        newCondition: {},
        isEditingCondition: false,
      });
    };

    return (
      <div>
        <Button
          disabled={ isLoading }
          onClick={ () => {
            this.setState({
              activeTab: TabPaneType.Conditions,
              newCondition: {},
              isEditingCondition: false,
            });
          } }
        >
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={ isOkButtonDisabled() }
          loading={ isLoading }
          onClick={ onSubmitHandler }
        >
          { isEditingCondition ? 'Save' : 'Add' }
        </Button>
      </div>
    );
  };

  renderRecipientsTab = () => {
    const { recipients, roles, users, isRecipientsinfoLoading } = this.state;

    const getTarget = (type: RecipientType, id: number) => {
      if (type === RecipientType.ROLE) {
        return roles.find((r) => r.id === id)?.title;
      }
      if (type === RecipientType.USER) {
        return users.find((u) => u.id === id)?.full_name;
      }
    };

    const columns: any = [
      {
        dataIndex: 'type',
        key: 'type',
        title: 'Type',
        ellipsis: true,
      },
      {
        dataIndex: 'target_id',
        key: 'target_id',
        title: 'Target',
        render: (__: any, record: { type: RecipientType; target_id: number }) => (
          <div>{ getTarget(record.type, record.target_id) }</div>
        ),
        ellipsis: true,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: () => (
          <Button onClick={ () => this.setState({ activeTab: TabPaneType.Recipient_details }) }>
            Create New
          </Button>
        ),
        render: (__: any, record: { type: RecipientType; target_id: number, id: number }) => {
          return (
            <>
              <EditOutlined
                className="link"
                style={{ fontSize: 18 }}
                onClick={ () => {
                  this.setState({
                    isEditingRecipient: true,
                    newRecipient: _.cloneDeep(record),
                    activeTab: TabPaneType.Recipient_details,
                  });
                } }
              />
              <Popconfirm
                title={ 'Are you sure?' }
                icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                okButtonProps={ { danger: true } }
                placement="topRight"
                onConfirm={ () => {
                  this.setState({ recipients: recipients.filter((c) => c.id !== record.id) });
                } }
              >
                <DeleteOutlined className="mL-20 link" style={{ fontSize: 18 }} />
              </Popconfirm>
            </>
          );
        },
        width: 120,
        ellipsis: true,
        align: 'center',
      },
    ];

    return (
      <BlockingSpinner isLoading={ isRecipientsinfoLoading }>
        <Table
          size={ 'small' }
          rowKey={ 'id' }
          pagination={ false }
          columns={ columns }
          dataSource={ recipients }
        />
      </BlockingSpinner>
    );
  };

  renderRecipientDetailsTab = () => {
    const { roles, users, newRecipient } = this.state;

    // Return recipients or roles depending on the selected recipient type
    const getRecipientsByType: () => Array<{ id: number, label: string }> = () => {
      // if recipient type is ROLE, return recipients
      if (newRecipient.type === RecipientType.ROLE) {
        return roles.map((r) => ({ id: r.id, label: r.title }));
      }

      // if recipient type is USER, return users
      if (newRecipient.type === RecipientType.USER) {
        return users.map((u) => ({ id: u.id, label: u.full_name }));
      }

      return [];
    };

    return (
      <Form layout="vertical">
        <FieldWrapper
          style={{ minHeight: 50, marginTop: 15 }}
          label="Recipient Type"
          description="What type of recipient should this be? Additional options may be presented below based on your selection."
          required
        >
          <Select
            placeholder={ 'Please select the Type of recipient' }
            value={ newRecipient.type }
            onChange={ (recipientType: RecipientType) => {
              this.setState({
                newRecipient: Object.assign(newRecipient, {
                  type: recipientType,
                  target_id: recipientType === RecipientType.ALL_ROLES ? null : undefined,
                }),
              });
            } }
          >
            { (Object.keys(RecipientType) as Array<keyof typeof RecipientType>).map((key) => {
              return (
                <Select.Option key={ key } value={ key }>
                  { transformFieldTitle(key) }
                </Select.Option>
              );
            }) }
          </Select>
        </FieldWrapper>
        { !!newRecipient.type && newRecipient.type !== RecipientType.ALL_ROLES && (
          <FieldWrapper
            style={{ minHeight: 50 }}
            label="Target"
            description={
              newRecipient.type === RecipientType.ROLE
                ? 'All users holding this role against the record will be notified.'
                : 'The targetted user will be notified.'
            }
            required
          >
            <Select
              showSearch
              disabled={ !newRecipient.type }
              placeholder={ 'Please select the target' }
              onChange={ (targetId: number) => {
                this.setState({ newRecipient: Object.assign(newRecipient, { target_id: targetId }) });
              } }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              value={ newRecipient.target_id }
            >
              { orderListByKey(getRecipientsByType(), 'label').map((item: { label: string, id: number }, i: number) => {
                return (
                  <Select.Option key={ `${item.id}_${i}` } value={ item.id }>
                    { item.label }
                  </Select.Option>
                );
              }) }
            </Select>
          </FieldWrapper>
        ) }
      </Form>
    );
  };

  renderRecipientDetailsTabFooter = () => {
    const { isLoading, recipients, newRecipient, isEditingRecipient } = this.state;

    const onSubmitHandler = () => {
      const recipient = { id: new Date().getTime(), ...newRecipient };
      const updatedRecipients: any = isEditingRecipient
        ? recipients.map((r) => (r.id === recipient.id ? recipient : r))
        : [...recipients, recipient];
      this.setState({
        recipients: updatedRecipients,
        activeTab: TabPaneType.Recipients,
        newRecipient: {},
        isEditingRecipient: false,
      });
    };

    return (
      <div>
        <Button
          disabled={ isLoading }
          onClick={ () => {
            this.setState({
              activeTab: TabPaneType.Recipients,
              newRecipient: {},
              isEditingRecipient: false,
            });
          } }
        >
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={ !newRecipient.type || (!newRecipient.target_id && newRecipient.type !== RecipientType.ALL_ROLES) }
          loading={ isLoading}
          onClick={ onSubmitHandler }
        >
          { isEditingRecipient ? 'Save' : 'Add' }
        </Button>
      </div>
    );
  };

  render = () => {
    const { onClose, onCreate } = this.props;
    const { template, conditions, recipients, deliveryMethods, isFetchingTemplate, isLoading, activeTab } = this.state;

    const isOkButtonDisabled = () => {
      if (!template.title?.trim()) {
        return true;
      }

      if (!template.subject?.trim()) {
        return true;
      }

      if (!template.message?.trim()) {
        return true;
      }

      if (!template.notification_trigger_id) {
        return true;
      }

      return false;
    };

    const displayFooter = () => {
      if (activeTab === TabPaneType.Condition_details) {
        return this.renderConditionDetailsTabFooter();
      }

      if (activeTab === TabPaneType.Recipient_details) {
        return this.renderRecipientDetailsTabFooter();
      }

      // return undefined to display the default footer
      return undefined;
    };

    const displayTitle = () => {
      if (activeTab === TabPaneType.Condition_details) {
        return 'Add Condition';
      }

      if (activeTab === TabPaneType.Recipient_details) {
        return 'Add Recipient';
      }

      return 'Create Notification Template';
    };

    const isHideTabBar = activeTab === TabPaneType.Recipient_details || activeTab === TabPaneType.Condition_details;

    return (
      <Modal
        visible
        centered
        title={ displayTitle() }
        maskClosable={ !isFetchingTemplate }
        okText={ 'Create' }
        bodyStyle={ { paddingTop: 5 } }
        style={ { minWidth: 800 } }
        onOk={ () => this.mounted && this.setState({ isFetchingTemplate: true },
          async () => {
            try {
              await onCreate({
                template: !template.tool_id ? { ...template, tool_id: null } : template,
                conditions,
                recipients,
                deliveryMethods
              });
              Notification('success', '', 'Notification template created');
            } catch (error) {
              Notification('error', 'Failed to create notification template', 'Failed');
            } finally {
              this.mounted && this.setState({
                isFetchingTemplate: false,
              });
            }
          })
        }
        onCancel={ onClose }
        cancelButtonProps={{
          disabled: isFetchingTemplate
        }}
        okButtonProps={{
          disabled: isOkButtonDisabled(),
          loading: isFetchingTemplate,
        }}
        footer={ displayFooter() }
      >
        <BlockingSpinner isLoading={ isLoading }>
          <Tabs
            activeKey={ activeTab }
            onTabClick={ (key) => this.setState({ activeTab }) }
            renderTabBar={ isHideTabBar ? () => <></> : undefined }
          >
            <TabPane tab="Basic" key={ TabPaneType.Basic }>
              { this.renderBasicTab() }
            </TabPane>
            <TabPane tab="Conditions" key={ TabPaneType.Conditions }>
              { this.renderConditionsTab() }
            </TabPane>
            <TabPane tab="Recipients" key={ TabPaneType.Recipients }>
              { this.renderRecipientsTab() }
            </TabPane>
            <TabPane key={ TabPaneType.Condition_details } disabled>
              { this.renderConditionDetailsTab() }
            </TabPane>
            <TabPane key={ TabPaneType.Recipient_details } disabled>
              { this.renderRecipientDetailsTab() }
            </TabPane>
          </Tabs>
        </BlockingSpinner>
      </Modal>
    );
  };
};

export default CreateTemplateDialog;
