// Libs
import * as React from 'react';

// Components
import WorkflowView from 'views/common/WorkflowView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
};

class ContractPricingWorkflow extends React.Component<Props> {
  render = () => {
    return <WorkflowView type={ 'contract-pricing' } entity={ 'record' } record={ this.props.record } />;
  };
};

export default ContractPricingWorkflow;
