// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

// Components
import Jumbotron from "components/jumbotron";
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import BasicListView from "components/basic-list";
import Dropdown from 'components/dropdown';
import { Modal, Form, InputNumber, Select, Popconfirm, DatePicker, Result } from 'antd';
import Badge, { BadgeType } from 'components/badge';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Icons
import { EditOutlined, DeleteOutlined, ArrowRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Services
import { Api } from 'services/api';
import { getFormatedDate, getUserSetting } from 'services/settings';
import Notification from 'services/notification';
import { getClientSetting } from 'services/settings';

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  rates: Rate[] | null;
  currencies: Currency[] | null;
  activeRateId: number | null;
  deleteConfirmId: number | null;
  showCreateDialog: boolean;
  isEditing: boolean;
  isCreating: boolean;
  isDeleting: boolean;
  isLoading: boolean;
};

interface Rate {
  id: number,
  source_currency: string,
  target_currency: string,
  rate: number,
  effective_date: string,
};

interface Currency {
  id: number;
  title: string;
  code: string;
  symbol: string;
};

const API: Api = new Api();
const { Option } = Select;

class Currency extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;
  formRef: any = React.createRef();

  state: State = {
    rates: null,
    currencies: null,
    activeRateId: null,
    deleteConfirmId: null,
    showCreateDialog: false,
    isEditing: false,
    isCreating: false,
    isDeleting: false,
    isLoading: false,
  };

  componentDidMount = async () => {
    this.mounted = true;

    try {
      const { client_id, setBreadcrumbs } = this.props;
      const currency = this.props.match.params.currency;

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));
      this.props.setBreadcrumbsLoading(true);

      const rates = await API.get(`client/${client_id}/admin/currencies/${_.upperCase(currency)}/rates`);
      const currencies = await API.get(`client/${client_id}/admin/currencies`);

      this.mounted && this.setState({
        currencies: currencies,
        rates: rates,
      });

      const currentCurrency = currencies.find((_currency: Currency) => _currency.code === _.toUpper(currency));

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'General Settings', path: '/admin/general-settings' },
        { title: 'Currencies', path: '/admin/general-settings/currencies' },
        { title: currentCurrency ? `${currentCurrency.title} (${currentCurrency.code})` : currency, path: null },
      ], false);

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }

  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderEditDialog = (activeRateId: number, rates: Rate[], currencies: Currency[]) => {
    const { client_id } = this.props;
    const { isLoading } = this.state;
    const currency = this.props.match.params.currency;

    const rate = rates.find((rate: Rate) => rate.id === activeRateId);
    const sourceCurrency = currencies.find((currency: Currency) => currency.code === rate?.source_currency);
    const targetCurrency = currencies.find((currency: Currency) => currency.code === rate?.target_currency);

    if (!rate) return <></>;

    return (
      <Modal
        visible
        centered
        title={ 'Edit Rate' }
        onOk={ () => {
          this.formRef
            .current
            .validateFields()
            .then( async (values: any) => {
              try {

                await new Promise((resolve) => this.setState({ isEditing: true }, () => resolve(null)));
                const rates = await API.put(`client/${client_id}/admin/currencies/${_.upperCase(currency)}/rates/${rate.id}`, {
                  data: {
                    'rate': values.rate,
                    'effective_date': values.effective_date.format('YYYY-MM-DD'),
                  }
                });

                this.mounted && this.setState({
                  activeRateId: null,
                  rates: rates,
                }, () => {
                  Notification('success', 'The currency rate has been changed', 'Currency Rate Changed');
                });

              } catch (error: any) {
                if (_.has(error, 'data')) {
                  Notification('error', error?.data, '');
                }
              } finally {
                this.mounted && this.setState({
                  isEditing: false
                });
              }
            })
            .catch((info: any) => {
              console.error('Invalid state');
            });
        } }
        okText={ 'Ok' }
        onCancel={ () => this.mounted && this.setState({
          activeRateId: null,
        }) }
        okButtonProps={{
          disabled: isLoading,
          loading: isLoading,
        }}
      >
        <Form
          ref={ this.formRef }
          layout="vertical"
          initialValues={{
            'source_currency': sourceCurrency?.id,
            'target_currency': targetCurrency?.id,
            'effective_date': moment(rate?.effective_date),
            'rate': rate?.rate,
          }}
        >
          <Form.Item
            label="Source Currency"
            name="source_currency"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select
              disabled
              className={ classNames('Select-Field') }
            >
              { currencies.map( (currency: any, index: number) => (
                <Option key={ index } value={ currency.id }>{ `${currency.title} (${currency.code})` }</Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item
            label="Target Currency"
            name="target_currency"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select
              disabled
              className={ classNames('Select-Field') }
            >
              { currencies.map( (currency: any, index: number) => (
                <Option key={ index } value={ currency.id }>{ `${currency.title} (${currency.code})` }</Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item
            label="Effective Date"
            name="effective_date"
            rules={[{ required: true, message: 'Required' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              allowClear={ false }
              format={ getUserSetting('date_format') }
            />
          </Form.Item>
          <Form.Item
            label="Rate"
            name="rate"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              step="0.0100000000"
              stringMode
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderCreateDialog = (currencies: Currency[]) => {
    const { client_id } = this.props;
    const { isCreating } = this.state;
    const currency = this.props.match.params.currency;
    const sourceCurrency = currencies.find((_currency: Currency) => _currency.code === _.upperCase(currency));
    const clientCurrency = getClientSetting('currency');

    return (
      <Modal
        visible
        centered
        title={ 'Create Rate' }
        onOk={ () => {
          this.formRef
            .current
            .validateFields()
            .then( async (values: any) => {
              try {

                await new Promise((resolve) => this.setState({ isCreating: true }, () => resolve(null)));
                const rates = await API.post(`client/${client_id}/admin/currencies/${_.upperCase(currency)}/rates`, {
                  data: {
                    'source_currency': currencies.find((currency: Currency) => currency.id === values.source_currency)?.code,
                    'target_currency': currencies.find((currency: Currency) => currency.id === values.target_currency)?.code,
                    'effective_date': values.effective_date.format('YYYY-MM-DD'),
                    'rate': values.rate,
                  }
                });

                this.mounted && this.setState({
                  showCreateDialog: false,
                  rates: rates,
                }, () => {
                  Notification('success', 'The currency rate has been created', 'Currency Rate Created');
                });

              } catch (error: any) {
                if (_.has(error, 'data')) {
                  Notification('error', error?.data, '');
                }
              } finally {
                this.mounted && this.setState({
                  isCreating: false
                });
              }
            })
            .catch((info: any) => {
              console.error('Invalid state');
            });
        } }
        okText={ 'Create' }
        onCancel={ () => this.mounted && this.setState({
          showCreateDialog: false,
        }) }
        okButtonProps={{
          disabled: isCreating,
          loading: isCreating,
        }}
      >
        <Form
          ref={ this.formRef }
          layout="vertical"
          initialValues={{
            'source_currency': sourceCurrency?.id,
            'target_currency': currencies.find((currency: any) => currency.code === clientCurrency)?.id,
            'effective_date': moment(),
            'rate': '1.0000000000',
          }}
        >
          <Form.Item
            label="Source Currency"
            name="source_currency"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select
              disabled
              className={ classNames('Select-Field') }
            >
              { currencies.map((currency: any, index: number) => (
                <Option key={ index } value={ currency.id }>{ `${currency.title} (${currency.code})` }</Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item
            label="Target Currency"
            name="target_currency"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select
              disabled
              className={ classNames('Select-Field') }
              placeholder={ 'Please select' }
            >
              { currencies.filter((currency: any) => currency.code !== _.upperCase(currency)).map( (currency: any, index: number) => (
                <Option key={ index } value={ currency.id }>{ `${currency.title} (${currency.code})` }</Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item
            label="Effective Date"
            name="effective_date"
            rules={[{ required: true, message: 'Required' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              allowClear={ false }
              format={ getUserSetting('date_format') }
            />
          </Form.Item>
          <Form.Item
            label="Rate"
            name="rate"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              step="0.0100000000"
              stringMode
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderView = (rates: Rate[] | null, directRate: boolean) => {
    const { client_id } = this.props;
    const { activeRateId, currencies, showCreateDialog } = this.state;
    const currency = this.props.match.params.currency;

    if (directRate) {
      return (
        <div className="d-f jc-c ai-c mT-20 mX-30 h-100p">
          <Result title="You're not able to edit your default currency" />
        </div>
      );
    }

    return (
      <div className='Layout-box'>
        <BasicListView
          rawData
          columns={ [
            {
              key: 'source_currency',
              dataIndex: 'source_currency',
              title: 'Source Currency',
              filterable: false,
              sorter: false,
              width: 200,
            },
            {
              key: 'arrow',
              dataIndex: 'arrow',
              title: '',
              filterable: false,
              sorter: false,
              width: 100,
              render: () => {
                return <ArrowRightOutlined />;
              },
            },
            {
              key: 'target_currency',
              dataIndex: 'target_currency',
              title: 'Target Currency',
              filterable: true,
              sorter: false,
              width: 200,
            },
            {
              key: 'status',
              dataIndex: 'status',
              title: 'Status',
              width: 100,
              render: (__: any, record: any) => {
                let badgeType = BadgeType.Default;
                switch (_.upperCase(record.status)) {
                  case 'FUTURE':
                    badgeType = BadgeType.Default;
                  break;
                  case 'ACTIVE':
                    badgeType = BadgeType.Success;
                  break;
                  case 'HISTORIC':
                    badgeType = BadgeType.Disabled;
                  break;
                }
                return <Badge type={ badgeType } text={ _.upperFirst(record.status) } />;
              },
              sorter: true,
              ellipsis: true,
              filterable: false,
            },
            {
              key: 'rate',
              dataIndex: 'rate',
              title: `Exchange Rate`,
              width: 250,
              filterable: false,
              sorter: false,
            },
            {
              key: 'effective_date',
              dataIndex: 'effective_date',
              title: 'Effective Date',
              filterable: false,
              sorter: false,
              render: (__: any, record: any) => {
                return getFormatedDate(record.effective_date);
              },
            },
            {
              key: 'actions',
              dataIndex: 'actions',
              title: '',
              fixed: 'right',
              render: (__: any, record: any) => {
                return (
                  <>
                    <EditOutlined
                      className="link"
                      style={{ fontSize: 18 }}
                      onClick={ () => this.setState({
                        activeRateId: record.id
                      })}
                    />
                    { _.upperCase(record.status) === 'ACTIVE' ? (
                      <DeleteOutlined
                        className="mL-20 text-ant-disabled disabled"
                        disabled
                        style={{ fontSize: 18 }}
                      />
                    ) : (
                      <Popconfirm
                        title={ 'Are you sure?' }
                        icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                        visible={ this.state.deleteConfirmId === record.id }
                        okButtonProps={{
                          danger: true
                        }}
                        onConfirm={ async () => {
                          try {
                            await new Promise((resolve) => this.setState({ isDeleting: true }, () => resolve(null)));

                            const _rates = await API.delete(`client/${client_id}/admin/currencies/${_.upperCase(currency)}/rates/${record.id}`);

                            this.mounted && this.setState({
                              rates: _rates
                            }, () => {
                              Notification('success', 'The rate has been deleted.', 'Rate Deleted');
                            });

                          } catch (error) {
                            console.error('Error: ', error);
                          } finally {
                            this.mounted && this.setState({
                              isDeleting: false,
                              deleteConfirmId: null
                            });
                          }
                        }}
                        onCancel={ () => this.setState({ deleteConfirmId: null }) }
                      >
                        <DeleteOutlined
                          className="mL-20 link"
                          disabled={ _.upperCase(record.status) === 'ACTIVE' }
                          style={{ fontSize: 18 }}
                          onClick={ () => {
                            this.setState({
                              deleteConfirmId: record.id
                            });
                          } }
                        />
                      </Popconfirm>
                    ) }
                  </>
                );
              },
              width: 100,
              sorter: false,
              ellipsis: true,
              filterable: false,
              align: 'center'
            },
          ] }
          items={ rates && rates.map((rate: Rate, index: number) => {
            return {
              'key': index,
              ...rate
            };
          }) }
        />
        { activeRateId && currencies && rates && this.renderEditDialog(activeRateId, rates, currencies) }
        { showCreateDialog && currencies && this.renderCreateDialog(currencies) }
      </div>
    );
  };

  render = () => {
    const { isLoading, rates, currencies } = this.state;
    const currentCurrency = currencies && currencies.find((currency: Currency) => currency.code === _.toUpper(this.props.match.params.currency));
    const clientCurrency = getClientSetting('currency');
    const defaultRate =  currentCurrency && clientCurrency === currentCurrency.code;

    return (
      <BlockingSpinner isLoading={ isLoading }>
        <Jumbotron
          content={ <p className="mB-0">{ currentCurrency ? `${currentCurrency.title} (${currentCurrency.code})` : '-' }</p> }
          tabs={[
            {
              label: 'Overview',
              node: this.renderView(rates, defaultRate || false),
            }
          ]}
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Create Rate',
                      disabled: defaultRate ? [`You can't create a direct rate`] : false,
                      onClick: () => this.setState({ showCreateDialog: true })
                    }
                  ] }
                />
              )
            }
          ] }
        />
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(withRouter(Currency)), 'access_admin_content_manager'));