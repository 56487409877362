// Libs
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

// Containers
import MasterCoaContainer from 'containers/finance/master-coa/MasterCoaContainer';

// Views
import MasterCoas from 'views/finance/MasterCoas';
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

class MasterCoasContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Route exact path="/finance/master-coa" component={ MasterCoas } />
        <Route path="/finance/master-coa/:coa_type/:coa_id" component={ MasterCoaContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

export default MasterCoasContainer;
