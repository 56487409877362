// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import { WorkflowHistory, WorkflowHistoryContext, WorkflowMesh, WorkflowStage } from 'components/workflow';
import Timeline from 'components/timeline';
import { Badge, Empty } from 'antd';

// Icons
import { ArrowRightOutlined } from '@ant-design/icons';

// Services
import { getFormatedDate } from 'services/settings';
import { Api } from 'services/api';

// Interfaces
import { UserEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';
import { TimelineItem } from 'components/timeline/Timeline.interface';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

interface Props {
  id?: number;
  type: string;
  client_id?: number;
  permissions?: any;
  user?: UserEntity;
  entity: string;
  record?: RecordFormEntity;
};

interface State {
  record: RecordFormEntity | null;
  isFetching: boolean;
  meshHeight: number | null;
};

class WorkflowView extends React.Component<Props, State> {

  mounted: boolean = false;
  component: any = React.createRef(); // This is used to figure out when the component is rendered

  state: State = {
    record: null,
    isFetching: false,
    meshHeight: null,
  };

  componentDidMount = async () => {
    const { type, entity, client_id, record } = this.props;
    this.mounted = true;

    if (!client_id) return;

    if (this.component) {
      this.heightObserver();
    }

    try {

      if (!record) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const freshRecord = await API.get(`client/${client_id}/${entity}/${type}/${record.id}`);

      this.mounted && this.setState({
        record: freshRecord
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (this.component && !this.state.isFetching) {
      this.heightObserver();
    }
  };

  heightObserver = () => {
    const root: number = document.getElementById('root')?.offsetHeight || 0;
    const header: number = document.getElementById('Header')?.offsetHeight || 0;
    const jumbotron: number = document.getElementById('Jumbotron')?.offsetHeight || 0;
    const tabViewBar: number = document.getElementById('TabView-bar')?.offsetHeight || 0;
    const meshHeight: number = root - (header + jumbotron + tabViewBar + 80); // an additional value of 80 are paddings
    const rendered = root && header && jumbotron && tabViewBar; // check that all required components have been rendered

    if (rendered && this.state.meshHeight !== meshHeight) {
      this.setState({
        meshHeight: meshHeight
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderOverview = () => {
    const { record, meshHeight } = this.state;

    if (!record) return <></>;

    return (
      <div className='Layout-box' ref={ node => (this.component = node) }>
        { meshHeight &&
          <div style={{ height: meshHeight }}>
            <WorkflowMesh stages={ record.workflow.stages } />
          </div>
        }
      </div>
    );
  };

  renderHistory = () => {
    const { record } = this.state;

    if (!record) return <></>;

    const getColorClass = ((context: WorkflowHistoryContext) => {
      switch (context) {
        case WorkflowHistoryContext.Problem:
          return 'Timeline--warning';
        case WorkflowHistoryContext.Resolved:
          return 'Timeline--success';
        default:
          return '';
      }
    });

    if (_.isEmpty(record.workflow_history)) {
      return <div className="mH-500 d-f jc-c ai-c"><Empty /></div>;
    }

    const items: TimelineItem[] = record.workflow_history.map((item: WorkflowHistory) => {
      const colorClass = getColorClass(item.context);
      return {
        colorClass: colorClass,
        node: (
          <>
            <div className="bdB pB-10">
              <span className="fw-500">{ getFormatedDate(item.created_at, undefined, true) }</span> | <span className="fw-500">{ item.transitioned_by }</span>
            </div>
            <div className="mT-20 mB-10">
              <span className="p-2 td-lt">{ item.before }</span><ArrowRightOutlined className="mL-10 mR-10" /><span className="p-2">{ item.after }</span>
            </div>
            { item.comment && (
              <div className="mT-20 mB-10">
                <span>{ item.comment.message }</span>
              </div>
            ) }
          </>
        ),
      };
    });

    return (
      <Timeline timeline={ items } />
    );
  };

  render = () => {
    const { isFetching } = this.state;
    const { record } = this.state;
    const currentWorkflow = record && record.workflow.stages.find((stage: WorkflowStage) => !!stage.current);
    const tabs = [
      {
        label: 'Overview',
        node: this.renderOverview(),
      },
      {
        label: 'History',
        node: this.renderHistory(),
      }
    ];

    return (
      <BlockingSpinner isLoading={ isFetching }>
        { record &&
          <Jumbotron
            title={ record.title }
            content={
              <>
                <p className="mB-0">{ record.workflow.title } { !!currentWorkflow && <Badge count={ currentWorkflow.title } style={{ backgroundColor: '#37c936', fontSize: 10 }} /> }</p>
                <p className="mB-0" style={{ fontSize: 12 }}>{ record.workflow.description }</p>
              </>
            }
            tabs={ tabs }
          />
        }
      </BlockingSpinner>
    );
  };

};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
  };
};

export default connect(mapStateToProps, null)(WorkflowView);