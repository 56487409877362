// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import Jumbotron from 'components/jumbotron';
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import { Mode, WorkflowMesh, WorkflowStageModal, WorkflowTransitionModal } from 'components/workflow';
import Dropdown from 'components/dropdown';

// Styles
import 'assets/styles/_layout.scss';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import {
  Workflow as WorkflowRecord,
  WorkflowCondition,
  WorkflowConditionType,
  WorkflowStage,
  WorkflowTransition,
  WorkflowTransitionHandle,
} from 'components/workflow/Workflow.interface';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbs, setBreadcrumbsLoading } from 'store/UI/ActionCreators';

// Api
import { Api } from 'services/api';
import Notification from 'services/notification';

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

type NewTransition = {
  source_stage_id: string;
  target_stage_id: string;
  source_handle: WorkflowTransitionHandle;
  target_handle: WorkflowTransitionHandle;
};

interface State {
  isLoading: boolean;
  showCreateDialog: boolean;
  workflow: WorkflowRecord | null;
  activeStage: WorkflowStage | null;
  activeTransition: WorkflowTransition | null;
  newTransitionState: NewTransition | null;
  meshHeight: number | null;
};

// Initialize the API service
const API: Api = new Api();

class Workflow extends React.Component<RouteComponentProps<{}> & Props, State> {
  mounted: boolean = false;
  component: any = React.createRef(); // Reference to the component to calculate height

  state: State = {
    isLoading: false,
    showCreateDialog: false,
    workflow: null,
    activeStage: null,
    activeTransition: null,
    newTransitionState: null,
    meshHeight: null,
  };

  // Lifecycle method: Runs after the component mounts
  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const workflow_id = this.props.match.params.workflow_id;

    this.mounted = true;

    this.props.setBreadcrumbsLoading(true);

    // Observe component height for layout adjustments
    if (this.component) {
      this.heightObserver();
    }

    try {
      // Set loading state before making API request
      await new Promise((resolve) => this.setState({ isLoading: true }, () => {
        this.props.setBreadcrumbsLoading(true);
        resolve(null);
      }));

      // Fetch workflow details from API
      const workflow = await API.get(`client/${client_id}/admin/workflows/${workflow_id}`);

      // Set breadcrumbs for the page
      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Workflows', path: '/admin/workflows' },
        { title: `${workflow.title}`, path: `/admin/workflows/${workflow.id}` },
      ], false);

      // Update state with the fetched workflow
      this.mounted && this.setState({
        workflow: workflow
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      // Remove loading state
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  // Lifecycle method: Runs after the component updates
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (this.component && !this.state.isLoading) {
      this.heightObserver();
    }
  };

  // Method to calculate and set mesh height for proper layout
  heightObserver = () => {
    const root: number = document.getElementById('root')?.offsetHeight || 0;
    const header: number = document.getElementById('Header')?.offsetHeight || 0;
    const jumbotron: number = document.getElementById('Jumbotron')?.offsetHeight || 0;
    const tabViewBar: number = document.getElementById('TabView-bar')?.offsetHeight || 0;
    const meshHeight: number = root - (header + jumbotron + tabViewBar + 80); // Calculate remaining height for mesh
    const rendered = root && header && jumbotron && tabViewBar; // Ensure all components are rendered

    if (rendered && this.state.meshHeight !== meshHeight) {
      this.setState({
        meshHeight: meshHeight
      });
    }
  };

  // Lifecycle method: Runs before the component is unmounted
  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  // Method to update a specific stage by its ID
  updateStage(stageId: number, body: Partial<WorkflowStage>) {
    if (this.state.workflow?.stages) {
      const stageIdx = this.state.workflow?.stages.findIndex((stage) => stage.id === stageId);
      const copyStages = [...this.state.workflow.stages];
      copyStages[stageIdx] = { ...copyStages[stageIdx], ...body };
      this.mounted && this.setState({
        workflow: Object.assign(this.state.workflow, { stages: copyStages }),
      });
    }
  };

  // Render the workflow mesh (visual representation of workflow stages and transitions)
  renderWorkflow = (workflow: WorkflowRecord) => {
    const { user: { active_client } } = this.props;
    const { meshHeight } = this.state;

    return (
      <div className='Layout-box' ref={ node => (this.component = node) }>
        { meshHeight &&
          <div style={{ height: meshHeight }}>
            <WorkflowMesh
              stages={ workflow.stages }
              openNodeDetails={ (stage: WorkflowStage) => {
                this.setState({
                  activeStage: stage,
                });
              } }
              openTransitionDetails={ (transition: WorkflowTransition) => {
                this.setState({
                  activeTransition: transition,
                });
              } }
              updateStage={ async(node) => {
                // state of the previous stage
                const prevStage = workflow.stages.find((el: any) => el.id === +node.id) as WorkflowStage;;
                const _position: any = { position: node.position };
                //Firstly we update the positions of the node (stage).
                this.updateStage(+node.id, _position);
                try {
                  // Update the stage position in the backend
                  await API.put(`client/${active_client}/admin/workflows/${workflow.id}/stages/${node.id}`,
                    {
                      data: { ...prevStage, position: node.position }
                    },
                  );
                  Notification('success', '', 'Stage moved');
                } catch (error) {
                  // On error, revert the stage to its previous position
                  this.updateStage(+node.id, prevStage);
                  Notification('error', '', 'Failed to move stage');
                  console.error('Error: ', error);
                }
              } }
              createTransition={ (data) => {
                this.setState({
                  newTransitionState: data,
                });
              } }
            />
          </div>
        }
      </div>
    );
  };

  // Render the modal dialog for editing a workflow stage
  renderEditStageDialog = (activeStage: WorkflowStage) => {
    const { user: { active_client } } = this.props;
    const { workflow } = this.state;
    const workflow_id = this.props.match.params.workflow_id;
    return (
      <WorkflowStageModal
        request={ async (workflowStage: Partial<WorkflowStage>) => {
          // Update stage details in the backend
          const response = await API.put(`client/${active_client}/admin/workflows/${workflow_id}/stages/${workflowStage.id}`,
            {
              data: workflowStage
            },
          );
          this.updateStage(activeStage.id, response);
        } }
        checkExistsEntities={ async () => {
          return await API.get(`client/${active_client}/admin/workflows/${workflow_id}/stages/${activeStage.id}/entities`);
        } }
        deleteStage={ async (target_stage_id: number | undefined) => {
          // If a target stage ID is provided, move records to this stage before deletion
          const requestBody = target_stage_id && { data: { target_stage_id } };
          const updatedStages = await API.delete(`client/${active_client}/admin/workflows/${workflow_id}/stages/${activeStage.id}`, requestBody);
          this.setState({
            workflow: Object.assign(workflow, { stages: updatedStages }),
          });
        } }
        defaultStage={ _.cloneDeep(activeStage) }
        onCancel={ () =>
          this.setState({
            activeStage: null,
          })
        }
        showDeleteBtn
        disableDeleteBtn={ !workflow?.stages || workflow.stages.length < 2 }
        title={ 'Edit Stage' }
        successMessage={ 'Stage edited' }
        errorMessage={ 'Failed to edit stage' }
      />
    );
  };

  // Render the modal dialog for adding a new workflow stage
  renderAddStageDialog = () => {
    const { workflow } = this.state;
    const { user: { active_client } } = this.props;
    const workflow_id = this.props.match.params.workflow_id;
    return (
      <WorkflowStageModal
        request={ async (data: Partial<WorkflowStage>) => {
          // Create a new stage in the backend
          const response = await API.post(`client/${active_client}/admin/workflows/${workflow_id}/stages`,
            {
              data: { ...data, start: false }
            },
          );
          // Update the state with the new stage
          this.setState({
            workflow: Object.assign(workflow, {
              stages: [...(workflow?.stages || []), response[0]],
            }),
          });
        } }
        onCancel={ () => this.setState({ showCreateDialog: false }) }
        title={ 'Create Stage' }
        successMessage={ 'Stage created' }
        errorMessage={ 'Failed to create stage' }
      />
    );
  };

  // Render the modal dialog for editing a workflow transition
  renderEditTransitionDialog = (activeTransition: WorkflowTransition) => {
    const { user: { active_client } } = this.props;
    const workflow_id = this.props.match.params.workflow_id;

    // Update the conditions of a transition
    const updateConditions = (conditions: WorkflowCondition[]) => {
      const stage = this.state.workflow?.stages.find(
        (stage) => stage.id === activeTransition.source_stage_id,
      );
      if (stage) {
        const copyTransitions = stage.transitions;
        const transitionIdx = copyTransitions.findIndex((tr) => tr.id === activeTransition.id);
        copyTransitions[transitionIdx].conditions = conditions;
        this.updateStage(stage.id, { transitions: copyTransitions });
      }
    };

    return (
      <WorkflowTransitionModal
        request={ async (transition: Partial<WorkflowTransition>) => {
          // Prepare the payload for the API request
          const payload = _.pick(transition, [
            'title',
            'description',
            'trigger',
            'source_handle',
            'target_handle',
            'requires_comment',
            'requires_comment_description',
            'bulk_operation_disabled'
          ]);
          // Update the transition in the backend
          const response = await API.put(`client/${active_client}/admin/workflows/${workflow_id}/transitions/${activeTransition.id}`,
            {
              data: payload
            },
          );
          // Update the state with the modified transition
          if (this.state.workflow?.stages) {
            const copyStages = [...this.state.workflow.stages];
            const stageIdx = copyStages.findIndex((stage) =>
              stage.transitions.find((t) => t.id === activeTransition.id),
            );
            const stageTransitions = copyStages[stageIdx].transitions;
            const updatedTransitionIdx = stageTransitions.findIndex((t) => t.id === transition.id);
            stageTransitions[updatedTransitionIdx] = response[0];
            this.mounted && this.setState({
              workflow: Object.assign(this.state.workflow, { stages: copyStages }),
            });
          }
        } }
        deleteTransition={ async () => {
          // Delete the transition in the backend
          await API.delete(`client/${active_client}/admin/workflows/${workflow_id}/transitions/${activeTransition.id}`);
          // Remove the deleted transition from the state
          const stage = this.state.workflow?.stages.find(
            (stage) => stage.id === activeTransition.source_stage_id,
          );
          if (stage) {
            const updatedTransitions = stage.transitions.filter((tr) => tr.id !== activeTransition.id);
            this.updateStage(stage.id, { transitions: updatedTransitions });
          }
        } }
        getRoles={ async () => {
          return await API.get(`client/${active_client}/admin/roles`);
        } }
        getFields={ async () => {
          return await API.get(`client/${active_client}/admin/workflows/${workflow_id}/transitions/conditions/fields`);
        } }
        editCondition={ async (conditionId: number, payload: { type: WorkflowConditionType, config: any }) => {
          const response = await API.put(`client/${active_client}/admin/workflows/${workflow_id}/transitions/conditions/${conditionId}`,
            {
              data: payload
            },
          );
          updateConditions(response);
          return response;
        } }
        createCondition={ async (payload: { type: WorkflowConditionType, config: any }) => {
          const response = await API.post(`client/${active_client}/admin/workflows/${workflow_id}/transitions/${activeTransition.id}/conditions`,
            {
              data: payload
            },
          );
          updateConditions(response);
          return response;
        } }
        deleteCondition={ async (conditionId: number) => {
          const response = await API.delete(`client/${active_client}/admin/workflows/${workflow_id}/transitions/conditions/${conditionId}`);
          updateConditions(response);
          return response;
        } }
        defaultTransition={ _.cloneDeep(activeTransition) }
        onCancel={ () => this.setState({ activeTransition: null }) }
        mode={ Mode.Edit }
      />
    );
  };

  // Render the modal dialog for adding a new workflow transition
  renderAddTransitionDialog = (newTransition: NewTransition) => {
    const { user: { active_client } } = this.props;
    const workflow_id = this.props.match.params.workflow_id;
    return (
      <WorkflowTransitionModal
        request={ async (data: Partial<WorkflowTransition>) => {
          const { conditions = [], ...values } = data;
          // Create a new transition in the backend
          const response = await API.post(`client/${active_client}/admin/workflows/${workflow_id}/transitions/`,
            { data: { transition: { ...values, ...newTransition }, conditions } }
          );
          const createdTransition = response[0];
          // Update the state with the new transition
          if (this.state.workflow?.stages && createdTransition) {
            const copyStages = [...this.state.workflow.stages];
            const stageIdx = copyStages.findIndex(
              (stage) => stage.id === createdTransition.source_stage_id,
            );
            copyStages[stageIdx].transitions.push(createdTransition);
            this.mounted && this.setState({
              workflow: Object.assign(this.state.workflow, { stages: copyStages }),
            });
          }
        } }
        getRoles={ async () => {
          return await API.get(`client/${active_client}/admin/roles`);
        } }
        getFields={ async () => {
          return await API.get(`client/${active_client}/admin/workflows/${workflow_id}/transitions/conditions/fields`);
        } }
        onCancel={ () => this.setState({ newTransitionState: null }) }
        mode={ Mode.Create }
      />
    );
  };

  // Main render method
  render = () => {
    const {
      workflow,
      isLoading,
      showCreateDialog,
      activeStage,
      activeTransition,
      newTransitionState,
    } = this.state;
    return (
      <BlockingSpinner isLoading={ isLoading }>
        { workflow && (
          <Jumbotron
            content={
              <>
                <p className="mB-0">{ workflow.title }</p>
                <p className="mB-0" style={{ fontSize: 12 }}>
                  { workflow.description }
                </p>
              </>
            }
            tabs={ [
              {
                label: 'Overview',
                node: this.renderWorkflow(workflow),
              },
            ] }
            rightActions={ [
              {
                node: (
                  <Dropdown
                    actions={ [
                      {
                        node: 'Create Stage',
                        onClick: () => this.setState({ showCreateDialog: true }),
                      },
                    ] }
                  />
                )
              }
            ] }
          />
        )}
        { showCreateDialog && this.renderAddStageDialog() }
        { activeStage && this.renderEditStageDialog(activeStage) }
        { activeTransition && this.renderEditTransitionDialog(activeTransition) }
        { newTransitionState && this.renderAddTransitionDialog(newTransitionState) }
      </BlockingSpinner>
    );
  };

};

// Map state to props
const mapStateToProps = (store: AppState) => {
  return {
    user: store.UserState.user,
    permissions: store.UserState.user.permissions,
    client_id: store.ClientState.client_id,
  };
};

// Map dispatch functions to props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

// Export the component with additional functionality (routing, state management, etc.)
export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(withRouter(Workflow)), 'access_admin_workflows'));
