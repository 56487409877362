// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { DatePicker } from 'antd';

// Services
import { getUserSetting } from 'services/settings';

// Utils
import { fieldIsModified } from 'utils/utils';

// Interfaces
import {
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
} from "components/form/form-wrapper";
import { Types, Field } from './DateTime.interface';

interface Props {
  columnKey: string;
  field: Field;
  onChange(
    field: Field,
    value: any,
    config: FormFieldConfig,
    column?: string
  ): void;
  onRefreshForm(field_id: string): void;
  originalState: any;
  state: any;
  type?: Types;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: Field, column: string, value: string | number): string[];
  border?: boolean;
};

class DateTimeField extends Component<Props> {

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, [originalState], config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { field, originalState } = this.props;
    const columnKeys = Object.keys(field.columns);

    columnKeys.forEach((columnKey: string) => {
      this.generateModifiedState(originalState[columnKey], state[columnKey], columnKey, shouldClear);
      this.generateErrorState(state[columnKey], columnKey, shouldClear);
    });
  };

  getFormat = (mode: string | undefined, userSettingFormat: string, withoutSeconds: boolean) => {
    switch (mode) {
      case 'time':
        return withoutSeconds ? 'HH:mm' : 'HH:mm:ss';
      case 'date':
        return userSettingFormat;
      case 'month':
        return 'MM';
      case 'year':
        return 'YYYY';
      default:
        return withoutSeconds ? `${userSettingFormat} HH:mm` : `${userSettingFormat} HH:mm:ss`;
    }
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (fieldIsModified(pastValue, newValue) && !shouldClear) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: field.id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { field, config, validate, setFieldErrorMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const errors = validate(field, columnKey, value);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: field.id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  render = () => {
    const {
      field,
      state,
      columnKey,
      config,
      border,
      isDisabled,
      fieldErrorMessages,
      fieldModifiedMessages,
      onChange,
    } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_value`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);

    const parsedState = state[columnKey] && state[columnKey] !== '' && moment(state[columnKey]).isValid() ? moment(state[columnKey]) : null;
    const dateFormat = getUserSetting('date_format');

    const mode: any = field.config.mode || undefined;
    const format = this.getFormat(mode, dateFormat, !!field.config.time_without_seconds);

    if (isDisabled) {
      return (
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          border={ border }
          description={ !!field.description && field.description }
          refreshOnChange={ !!field.config.refresh_on_change }
          versionChanged={ !!field.config.version_changed }
        >
          <span className="d-b pY-5">{ !!parsedState ? parsedState.format(format) : '' }</span>
        </FieldWrapper>
      );
    }

    const pickerConfig = {
      picker: mode,
      showTime: !!field.config.time_without_seconds ? { format: 'HH:mm' } : !mode
    };

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ errors }
        required={ !!field.config.required }
        border={ border }
        description={ !!field.description && field.description }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
      >
        <DatePicker
          disabled={ isDisabled }
          allowClear={ !field.config.required }
          className={ classNames('Field', {
            'Field--has-warning border-warning': isModified && _.isEmpty(errors),
          }) }
          onChange={ (date: moment.Moment | null) => {
            if (!!date) {
              onChange(field, date.format('YYYY-MM-DD HH:mm:ss'), config, columnKey);
            } else {
              onChange(field, null, config);
            }
          } }
          placeholder={ field.label }
          value={ parsedState }
          format={ format }
          { ...pickerConfig }
        />
      </FieldWrapper>
    );
  };

};

export default DateTimeField;
