// Libs
import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Select, Button, Popconfirm, Input, Tooltip } from 'antd';
import DragSortingList from 'components/drag-sorting-list';
import CreateClauseDropdown from './CreateClauseDropdown';

// Icons
import { PlusOutlined, DeleteOutlined, QuestionCircleOutlined, MenuOutlined } from '@ant-design/icons';

// Utilities
import { isBlank } from 'utils/utils';
import { arrayMoveImmutable } from 'utils/formSetup';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
  FormValues,
} from 'components/form/form-wrapper';

// Styles
import './Clause.scss';

const { Option } = Select;

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right',
};

enum Enabled {
  No = 0,
  Yes = 1,
};

interface Clause {
  clause_id: number;
  clause_name: string;
  comment: string;
  enabled?: number;
  key: string;
  order: number;
  target_id: number;
  target_bundle: string;
  target_type: string;
};

const getBlankState: (order: number) => Partial<Clause> = (order: number = 0) => {
  const uniqueKey = uuidv4();
  return {
    id: uniqueKey,
    key: `clause_${uniqueKey}`,
    clause_name: '',
    comment: '',
    target_bundle: 'category',
    target_type: 'lease_clause',
    order: order,
    enabled: undefined,
    clause_id: undefined,
    target_id: undefined,
  };
};

interface Props {
  originalValues: any;
  field: FormField;
  clientId: number;
  onValueChange(state: any): void;
  onFieldChange(field: FormField): void;
  config: FormFieldConfig;
  isDisabled?: boolean;
  isNew: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  setLoading: (value: boolean) => void;
};

interface State {};

class ClauseField extends React.Component<Props, State> {

  state: State = {};

  componentDidMount = () => {
    const { isNew, field } = this.props;

    const clauseIds = field.values.map((clause) => clause.target_id);
    const filteredReuiredClauses = field.required_clauses?.filter((clause) => !clauseIds.includes(clause.id));

    if (filteredReuiredClauses?.length) {
      const newClauses = filteredReuiredClauses?.map((clause: any, index: number) => ({
        ...getBlankState(clauseIds.length + index),
        target_id: clause.id,
        enabled: isNew ? undefined : Enabled.No,
      })) || [];
      this.handleChange([...field.values, ...newClauses]);
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, originalValues } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(field.values, originalValues);
    }
  };

  getErrors = (values: any) => {
    const errors: any = {};

    // Check for empty fields
    values.forEach((value: any) => {
      const schema = {
        target_id: (_value: any) => !isBlank(_value),
        enabled: (_value: any) => !isBlank(_value),
      };

      const _validate = (_value: any, schema: any) =>
        Object.keys(schema)
          .filter((key) => !schema[key](_value[key]))
          .map((key) => key);

      if (!_.isEmpty(_validate(value, schema))) {
        errors[value.key] = _validate(value, schema);
      }
    });

    return errors;
  };

  getModified = (values: any, originalValues: any) => {
    const modified: any = {};

    if (!_.isEqual(values, originalValues)) {
      const fieldKeys = ['target_id', 'comment', 'enabled', 'order'];

      const newStatekeys: string[] = values.map((value: any) => value.key);
      if (originalValues.some((_value: any) => !newStatekeys.includes(_value.key))) {
        modified['removed'] = null;
      }

      values.forEach((newValue: any) => {
        if (originalValues.some((_value: any) => _value.key === newValue.key)) {
          const oldValue = originalValues.find((_oldValue: any) => _oldValue.key === newValue.key);
          if (!_.isEqual(newValue, oldValue)) {
            Object.keys(newValue)
              .filter((key: string) => fieldKeys.includes(key))
              .forEach((key: string) => {
                if (!oldValue) {
                  modified[newValue.key] = fieldKeys;
                } else if (newValue[key] !== oldValue[key]) {
                  if (!!modified[newValue.key] && !modified[newValue.key].includes(key)) {
                    modified[newValue.key] = modified[newValue.key].concat(key);
                  } else {
                    modified[newValue.key] = [key];
                  }
                }
              });
          }
        } else {
          modified[newValue.key] = fieldKeys;
        }
      });
    }

    return modified;
  };

  validate = (values: any, originalValues: any) => {
    const { field, config, setFieldErrorMessage, setFieldModifiedMessage } = this.props;
    const errors = this.getErrors(values);
    const modified = this.getModified(values, originalValues);

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const errorMessage = _.isEmpty(errors) ? undefined : { ...generalMessageInfo, errors: errors };
    const modifiedMessage = _.isEmpty(modified) ? undefined : { ...generalMessageInfo, modified: modified };
    setFieldErrorMessage(`${field.id}`, errorMessage);
    setFieldModifiedMessage(`${field.id}`, modifiedMessage);
  };

  hasError = (fieldErrorMessages: any, fieldKey: string | number, fieldType: string) => {
    return (
      _.has(fieldErrorMessages, ['lease_clause', 'errors', fieldKey]) &&
      fieldErrorMessages['lease_clause']['errors'][fieldKey].includes(fieldType)
    );
  };

  isModified = (fieldModifiedMessages: any, fieldKey: string | number, fieldType: string) => {
    return (
      _.has(fieldModifiedMessages, ['lease_clause', 'modified', fieldKey]) &&
      fieldModifiedMessages['lease_clause']['modified'][fieldKey].includes(fieldType)
    );
  };

  handleChange = (values: any) => {
    this.props.onValueChange(values);
  };

  modifyValues = (identifier: string, values: any, newValue: any, key: any) => {
    return values.map((value: any) => {
      if (value.key === identifier) {
        value = _.set(value, [key], newValue);
      }

      return {
        ...value,
      };
    });
  };

  delete = (identifier: string, values: any) => {
    return values.filter((value: any) => value.key !== identifier);
  };

  moveRowHandler = (dragIndex: number, hoverIndex: number, sourceTableConfig: any, targetTableConfig: any) => {
    const { isDisabled } = this.props;
    if (dragIndex !== hoverIndex && _.isEqual(sourceTableConfig, targetTableConfig) && !isDisabled) {
      const result: FormValues[] = arrayMoveImmutable(this.props.field.values, dragIndex, hoverIndex).filter((el: any) => !!el);
      this.handleChange(result.map((value, index: number) => ({ ...value, order: index })));
    }
  };

  renderTable = (field: any) => {
    const { isDisabled, fieldModifiedMessages, fieldErrorMessages, config, clientId, onFieldChange, setLoading } = this.props;

    const isDisabledClause = (clauseId: number) => {
      const targetIds: number[] = field.values.map((value: any) => value.target_id);
      return targetIds.includes(clauseId);
    };

    const isRequiredClause = (clauseId: number) => {
      if (field.required_clauses?.length) {
        return !!field.required_clauses.find((clause: any) => clause.id === clauseId);
      }
    };

    // temporary flag to hide the function of adding a new clause
    const hideAddNewClause = true;

    const columns = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        width: 30,
        render: () => <MenuOutlined className="ClauseField-DragMenu" />,
      },
      {
        key: 'target_id',
        title: 'Clause',
        width: 300,
        render: (row: Clause) => {
          const hasErrors = this.hasError(fieldErrorMessages, row.key, 'target_id');
          const isModified = this.isModified(fieldModifiedMessages, row.key, 'target_id');
          return (
            <Select
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': !!hasErrors,
                'Select-Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              disabled={ isDisabled || isRequiredClause(row.target_id) }
              dropdownMatchSelectWidth={ false }
              value={ row.target_id }
              onChange={ (targetId: number) => {
                this.handleChange(this.modifyValues(row.key, _.cloneDeep(field.values), targetId, 'target_id'));
              } }
              // the function to add a new clause has been temporarily hidden
              dropdownRender={ hideAddNewClause ? undefined : (menu) => (
                <>
                  { menu }
                  <CreateClauseDropdown
                    clientId={ clientId }
                    setLoading={ setLoading }
                    onUpdate={ (clauses, targetId) => {
                      const values = this.modifyValues(row.key, _.cloneDeep(field.values), targetId, 'target_id');
                      onFieldChange({ ...field, values, clauses: clauses });
                    } }
                  />
                </>
              ) }
            >
              { !!field.clauses &&
                field.clauses.map((clause: any) => (
                  <Option key={ clause.id } value={ clause.id } disabled={ isDisabledClause(clause.id) }>
                    { !_.isEmpty(clause.tooltip?.trim()) ? (
                      <Tooltip title={ clause.tooltip } placement="right">
                        <div className="ClauseField-Option">
                          <div className="ClauseField-OptionText">
                            { clause.title }
                          </div>
                        </div>
                      </Tooltip>) : clause.title
                    }
                  </Option>
                )) }
            </Select>
          );
        },
      },
      {
        key: 'enabled',
        title: 'Included',
        width: 180,
        render: (row: Clause) => {
          const hasErrors = this.hasError(fieldErrorMessages, row.key, 'enabled');
          const isModified = this.isModified(fieldModifiedMessages, row.key, 'enabled');
          return (
            <Select
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': !!hasErrors,
                'Select-Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              disabled={ isDisabled }
              value={ row.enabled }
              onChange={ (enabled: number) => {
                this.handleChange(this.modifyValues(row.key, _.cloneDeep(field.values), enabled, 'enabled'));
              } }
            >
              <Option key={ Enabled.Yes } value={ Enabled.Yes }>
                { Enabled[1] }
              </Option>
              <Option key={ Enabled.No } value={ Enabled.No }>
                { Enabled[0] }
              </Option>
            </Select>
          );
        },
      },
      {
        key: 'comment',
        title: 'Comment',
        render: (row: Clause) => {
          const isModified = this.isModified(fieldModifiedMessages, row.key, 'comment');
          return (
            <Input
              className={ classNames('ClauseField', {
                'Field--has-warning border-warning': isModified,
              }) }
              disabled={ isDisabled }
              onBlur={ (e: React.ChangeEvent<HTMLInputElement>) => {
                this.handleChange(this.modifyValues(row.key, _.cloneDeep(field.values), e.target.value, 'comment'));
              } }
              placeholder={ 'Comment' }
              defaultValue={ row.comment }
            />
          );
        },
      },
      {
        key: 'actions',
        title: (
          <Button
            disabled={ isDisabled }
            className={ 'ClauseField-Button mL-5' }
            onClick={ () => this.handleChange(_.concat(_.cloneDeep(field.values), getBlankState(field.values?.length))) }
          >
            <PlusOutlined />
          </Button>
        ),
        dataIndex: '',
        align: Alignment.Right,
        width: '50px',
        render: (row: Clause) => {
          const disabled = isDisabled || isRequiredClause(row.target_id);
          const button = (
            <Button className="ClauseField-Button" disabled={ disabled }>
              <DeleteOutlined />
            </Button>
          );
          return disabled ? button : (
            <Popconfirm
              title={ 'Are you sure?' }
              icon={ <QuestionCircleOutlined className="text-danger" /> }
              okButtonProps={{
                danger: true,
              }}
              onConfirm={ () => {
                this.handleChange(this.delete(row.key, _.cloneDeep(field.values)));
              } }
            >
              { button }
            </Popconfirm>
          );
        },
      },
    ];

    const type: any = `Key_${config.elementIndex}`;
    return (
      <DragSortingList
        className={ 'ClauseField' }
        columns={ columns }
        items={ field.values || [] }
        pagination={ false }
        isParent
        moveRow={ this.moveRowHandler }
        config={ {
          type: type, // table unique key
          references: [],
        } }
      />
    );
  };

  render = () => {
    const { field, config } = this.props;

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` } border col={ config.fieldColSpan } label={ field.label } versionChanged={ !!field.config.version_changed }>
        { this.renderTable(field) }
      </FieldWrapper>
    );
  };
}

export default ClauseField;
