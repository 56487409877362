// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { has } from 'lodash';

// Services
import { Api } from 'services/api';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Containers
import CostCentreBudgetGroupContainer from './CostCentreBudgetGroupContainer';

// Views
import BudgetRecord from 'views/finance/cost-centre/budget/BudgetRecord';
import BudgetDocuments from 'views/finance/cost-centre/budget/BudgetDocuments';
import BudgetResources from 'views/finance/cost-centre/budget/BudgetResources';
import BudgetWorkflow from 'views/finance/cost-centre/budget/BudgetWorkflow';
import BudgetComments from 'views/finance/cost-centre/budget/BudgetComments';
import BudgetVersions from 'views/finance/cost-centre/budget/BudgetVersions';
import BudgetAudit from 'views/finance/cost-centre/budget/BudgetAudit';
import BudgetKpiMetrics from 'views/finance/cost-centre/budget/BudgetKpiMetrics';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { RecordFormEntity } from 'types/entities';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbs,
  setBreadcrumbsLoading,
} from 'store/UI/ActionCreators';

// Utils
import Console from 'utils/console';

interface Props {
  client_id: number;
  permissions: any;
  match: { params: Record<string, any> };
  location: {
    pathname: string;
  };
  routes: any[];
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  record: RecordFormEntity | null;
  isFetching: boolean;
}

const API: Api = new Api();

class CostCentreBudgetContainer extends React.Component<Props, State> {

  mounted: boolean = false;
  state: State = {
    record: null,
    isFetching: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.getRecord();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.params.budget_id !== this.props.match.params.budget_id) {
      this.getRecord();
    }

    if (!!prevProps.location.pathname.match(/budget-group\/[0-9]+/) !== !!this.props.location.pathname.match(/budget-group\/[0-9]+/)) {
      this.getRecord();
    }
  };

  componentWillUnmount = () => {
    this.props.setSecondarySidebarRoutes([]);
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getRecord = async (silent: boolean = false) => {
    try {

      if (this.props.match.params.budget_id && this.props.client_id && !this.props.location.pathname.match(/budget-group\/[0-9]+/)) {

        if (!silent) {
          await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));
          this.props.setBreadcrumbsLoading(true);
        }

        const record = await API.get(`client/${this.props.client_id}/record/budget/${this.props.match.params.budget_id}`);

        this.setRecord(record, silent);
      }
    } catch (error) {
      Console.error(error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.setState({
        isFetching: false
      });
    }
  };

  setRecord = (record: RecordFormEntity, silent?: boolean) => {
    const showLoadingState = this.state.record?.title !== record.title;

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(true);
    }

    if (has(record, 'breadcrumbs')) {
      this.props.setBreadcrumbs(record.breadcrumbs, false);
    }

    if (has(record, 'permissions')) {
      const { permissions, new_content } = record;

      this.props.setSecondarySidebarRoutes([
        { title: 'Budget', path: 'record', isDisabled: !hasPermission(permissions, 'record_budget_view') },
        { title: 'Documents', path: 'documents', isIndented: true, isDisabled: !hasPermission(permissions, 'record_budget_document_view'), hasUpdates: !!new_content.documents },
        { title: 'Comments', path: 'comments', isIndented: true, isDisabled: !hasPermission(permissions, 'record_budget_comment_view'), hasUpdates: !!new_content.comments },
        { title: 'Resources', path: 'resources', isIndented: true, isDisabled: !hasPermission(permissions, 'record_budget_resource_view'), hasUpdates: !!new_content.resources },
        { title: 'Workflow', path: 'workflow', isIndented: true, isDisabled: !hasPermission(permissions, 'record_budget_workflow_view') },
        { title: 'Versions', path: 'versions', isIndented: true, isDisabled: !hasPermission(permissions, 'record_budget_version_view') },
        { title: 'Audit', path: 'audit', isIndented: true, isDisabled: !hasPermission(permissions, 'record_budget_audit_view') },
        // { title: 'KPI Metrics', path: 'kpi-metrics', isIndented: true, isDisabled: !hasPermission(permissions, 'record_budget_kpi_metric_view') },
      ]);
    }

    this.setState({
      record: record,
      isFetching: false
    });

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(false);
    }
  };

  render = () => {
    const { isFetching, record } = this.state;
    const permissions = record && has(record, 'permissions') ? record.permissions : this.props.permissions;
    return (
      <BlockingSpinner isLoading={isFetching}>
        <Switch>

          <Redirect exact from="/finance/cost-centres/:cost_centre_id/budget/:budget_id" to="/finance/cost-centres/:cost_centre_id/budget/:budget_id/record" />

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/record" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_view') }>
                  <BudgetRecord record={ record } getRecord={ this.getRecord } setRecord={ this.setRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/documents" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_document_view') }>
                  <BudgetDocuments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/resources" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_resource_view') }>
                  <BudgetResources record={ record } getRecord={ this.getRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/workflow" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_workflow_view') }>
                  <BudgetWorkflow record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/comments" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_comment_view') }>
                  <BudgetComments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/versions" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(record.permissions, 'record_budget_version_view') }>
                  <BudgetVersions record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/audit" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_audit_view') }>
                  <BudgetAudit record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/kpi-metrics" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_budget_kpi_metric_view') }>
                  <BudgetKpiMetrics record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          <Route path="/finance/cost-centres/:cost_centre_id/budget/:budget_id/budget-group/:budget_group_id" component={ CostCentreBudgetGroupContainer } />

          <Route render={ () => <NotFound /> } />
        </Switch>
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    routes: store.UIState.secondarySidebarRoutes
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CostCentreBudgetContainer);
