// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Components
import { Modal, Input, Form, Popconfirm } from 'antd';
import Jumbotron from "components/jumbotron";
import BasicList from 'components/basic-list';
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import Dropdown from 'components/dropdown';

// Icons
import { EditOutlined, TeamOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Utils
import { nestedSet } from 'utils/utils';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";

const { TextArea } = Input;

export interface IGroup {
  id: number;
  title: string;
  description: string;
  users: IGroupUser[];
  entity_map: string[];
};

export interface IGroupUser {
  id: number;
  full_name: string;
  email: string;
  type: 'INHERITED' | 'DIRECT';
  entity: {
    id: number;
    title: string;
    path: string;
  };
  company: {
    id: number;
    title: string;
    path: string;
  };
};

interface Props {
  intl: IntlShape;
  user: UserEntity;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  groups: any[];
  isLoading: boolean;
  activeGroupId: number | null;
  showAddDialog: boolean;
  isFetchingGroup: boolean;
  deleteConfirmId: number | null;
};

const API: Api = new Api();

class Groups extends React.Component<Props, State> {

  mounted: boolean = false;
  createFormRef: any = React.createRef();
  editFormRef: any = React.createRef();

  state: State = {
    groups: [],
    activeGroupId: null,
    isLoading: false,
    showAddDialog: false,
    isFetchingGroup: false,
    deleteConfirmId: null,
  };

  componentDidMount = async () => {
    const { user: { active_client }, setBreadcrumbs } = this.props;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Groups', path: '/admin/groups' },
    ], false);

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const groups = await API.get(`client/${active_client}/admin/groups`);

      this.mounted && this.setState({
        groups: groups
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderGroups = () => {
    const { user: { active_client } } = this.props;
    const { groups } = this.state;

    return (
      <BasicList
        rawData
        columns={ [
          {
            key: 'title',
            dataIndex: 'title',
            title: 'Title',
            render: (title: any, record: any) => {
              return (
                <>
                  <TeamOutlined className="mR-10" style={{ fontSize: 18 }} />
                  <Link to={`/admin/groups/${record.id}`} style={{ 'color': '#2196f3' }}>{ title }</Link>
                </>
              );
            },
            filterable: true,
            sorter: true,
            ellipsis: true,
          },
          {
            key: 'description',
            dataIndex: 'description',
            title: 'Description',
            sorter: false,
            ellipsis: true,
            filterable: false,
          },
          {
            key: 'users',
            dataIndex: 'users',
            title: 'Users',
            render: (__: any, record: any) => {
              if (_.has(record, 'users')) {
                return <span>{ `${record.users.length}` }</span>;
              }
              return <span>0</span>;
            },
            width: 100,
            sorter: false,
            ellipsis: true,
            filterable: false,
          },
          {
            key: 'actions',
            dataIndex: 'actions',
            title: '',
            render: (__: any, group: any) => {
              return (
                <>
                  <EditOutlined
                    className="link"
                    style={{ fontSize: 18 }}
                    onClick={ () => this.setState({
                      activeGroupId: group.id
                    })}
                  />
                  <Popconfirm
                    title={ 'Are you sure?' }
                    icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                    visible={ this.state.deleteConfirmId === group.id }
                    okButtonProps={{
                      danger: true
                    }}
                    onConfirm={ async () => {
                      try {
                        await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

                        const groups = await API.delete(`client/${active_client}/admin/groups/${this.state.deleteConfirmId}`);

                        this.mounted && this.setState({
                          groups: groups
                        }, () => {
                          Notification('success', 'The group has been deleted.', 'Group Deleted');
                        });
                      } catch (error) {
                        console.error('Error: ', error);
                      } finally {
                        this.mounted && this.setState({
                          isLoading: false
                        });
                      }
                    }}
                    onCancel={ () => this.setState({ deleteConfirmId: null }) }
                  >
                    <DeleteOutlined
                      className="mL-20 link"
                      style={{ fontSize: 18 }}
                      onClick={ () => {
                        this.setState({
                          deleteConfirmId: group.id
                        });
                      } }
                  />
                  </Popconfirm>
                </>
              );
            },
            width: 100,
            sorter: false,
            ellipsis: true,
            filterable: false,
            align: 'center'
          },
        ] }
        items={ nestedSet(groups) }
      />
    );
  };

  renderAddDialog = () => {
    const { user: { active_client } } = this.props;
    const { isFetchingGroup } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Create Group' }
        maskClosable={ !isFetchingGroup }
        okText={ 'Create' }
        onOk={ () => {
          this.createFormRef
            .current
            .validateFields()
            .then( async (values: any) => {
              try {

                await new Promise((resolve) => this.setState({ isFetchingGroup: true }, () => resolve(null)));

                const groups = await API.post(`client/${active_client}/admin/groups`, {
                  data: {
                    title: values.title,
                    description: values.description || null,
                  }
                });

                this.mounted && this.setState({
                  groups: groups,
                  showAddDialog: false,
                }, () => {
                  Notification('success', 'The group has been created.', 'Group Created');
                });

              } catch (error) {
                Notification('error', '', 'Failed');
              } finally {
                this.mounted && this.setState({
                  isFetchingGroup: false
                });
              }
            })
            .catch((info: any) => {
              console.error('Invalid state');
            });
        } }
        onCancel={() => this.setState({
          showAddDialog: false,
        }) }
        cancelButtonProps={{
          disabled: isFetchingGroup
        }}
        okButtonProps={{
          disabled: false,
          loading: isFetchingGroup,
        }}
      >
        <Form
          ref={ this.createFormRef }
          layout="vertical"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderEditDialog = (activeGroupId: number) => {
    const { user: { active_client } } = this.props;
    const { groups, isFetchingGroup } = this.state;
    const group = groups.find((group: any) => group.id === activeGroupId);
    return (
      <Modal
        visible
        centered
        title={ 'Edit Group' }
        maskClosable={ !isFetchingGroup }
        okText={ 'Edit' }
        onOk={ () => {
          this.editFormRef
            .current
            .validateFields()
            .then( async (values: any) => {
              try {

                await new Promise((resolve) => this.setState({ isFetchingGroup: true }, () => resolve(null)));

                const groups = await API.put(`client/${active_client}/admin/groups/${activeGroupId}`, {
                  data: {
                    title: values.title,
                    description: values.description || null,
                  }
                });

                this.mounted && this.setState({
                  groups: groups,
                  activeGroupId: null,
                }, () => {
                  Notification('success', 'Your changes have been saved.', 'Group Updated');
                });

              } catch (error) {
                Notification('error', '', 'Failed');
              } finally {
                this.mounted && this.setState({
                  isFetchingGroup: false
                });
              }
            })
            .catch((info: any) => {
              console.error('Invalid state');
            });
      } }
        onCancel={() => this.setState({
          activeGroupId: null,
        }) }
        cancelButtonProps={{
          disabled: isFetchingGroup
        }}
        okButtonProps={{
          disabled: false,
          loading: isFetchingGroup,
        }}
      >
        <Form
          ref={ this.editFormRef }
          layout="vertical"
          initialValues={{
            "title": group.title,
            "description": group.description,
          }}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  render = () => {
    const { isLoading, showAddDialog, activeGroupId } = this.state;
    return (
      <BlockingSpinner isLoading={isLoading}>
        <Jumbotron
          content={ 'Groups' }
          tabs={ [
            {
              label: '',
              node: this.renderGroups(),
            }
          ] }
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Create Group',
                      onClick: () => this.setState({ showAddDialog: true })
                    }
                  ] }
                />
              )
            }
          ] }
        />
        { activeGroupId && this.renderEditDialog(activeGroupId) }
        { showAddDialog &&  this.renderAddDialog() }
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    user: store.UserState.user,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(Groups), 'access_admin_groups'));
