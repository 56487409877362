// Libs
import React, { Component, BaseSyntheticEvent } from 'react';
import _ from 'lodash';

// Components
import { Input } from 'antd';

const { TextArea } = Input;

interface Props {
  comment: string | undefined,
  isLocked: boolean;
  isPreviewing: boolean;
  onChange: (comment: string | null) => void;
};

class Comment extends Component<Props> {

  handleChange = _.debounce((comment: string) => {
    this.props.onChange(!!comment ? comment : null);
  }, 500);

  render = () => {
    return (
      <>
        <label className="fw-500">
          Comment
        </label>
        <TextArea
          autoSize={{ minRows: 1 }}
          disabled={ this.props.isLocked }
          defaultValue={ this.props.comment }
          onChange={ (event: BaseSyntheticEvent) => this.handleChange(event.target.value) }
        />
      </>
    );
  };
};

export default Comment;
