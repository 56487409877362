// Libs
import * as React from 'react';

// Views
import KpiLibrary from 'views/workplace-service/KpiLibrary';

// Services
import { Api } from 'services/api';

// Interfaces
import { RecordFormEntity } from 'types/entities';

const API: Api = new Api();

interface Props {
  client_id: number;
  record: RecordFormEntity;
};

class ContractKpiLibrary extends React.Component<Props> {
  render = () => {
    const { record } = this.props;

    return (
      <>
        <KpiLibrary
          record={ record }
          breadcrumbs={ [{ title: 'Home', path: '/' }, { title: 'Suppliers', path: '/suppliers' }, { title: 'Supplier List', path: '/suppliers/suppliers' }] }
        />
      </>
    );
  };
};

export default ContractKpiLibrary;
