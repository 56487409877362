import * as React from 'react';
import { notification } from 'antd';

/**
 * Trigger ant notification
 */
export default function (type: 'success' | 'info' | 'warning' | 'error', description: any, message: string = 'Notification') {

  if (description instanceof Array) {
    description = React.createElement(
      'ul',
      { style: { paddingLeft: 0 } },
      description.map(function (item, index) {
        return React.createElement('li', { key: index }, item);
      })
    );
  }

  return notification[type]({
    message: message,
    description: description,
  });

}
