// Libs
import * as React from 'react';

// Components
import AuditView from 'views/common/AuditView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
};

class LeaseAquisitionAudit extends React.Component<Props> {
  render = () => {
    return <AuditView record={ this.props.record } entity={ 'transaction' } type={ 'lease-aquisition' } />;
  };
};

export default LeaseAquisitionAudit;
