// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import BasicList, { Config } from "components/basic-list";
import Jumbotron from 'components/jumbotron';
import { hasPermission } from 'components/restriction';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import { Result } from 'antd';

// Views
import CreateUserDialog from 'views/admin/users/CreateUserDialog';

// Services
import { Api } from 'services/api';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

interface Props {
  client_id: number;
  user_id: number;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  columns: any[];
  items: any[];
  config: Config | null;
  isFetching: boolean;
  isCreateLoading: boolean;
  showCreateDialog: boolean;
};

class UserRequests extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    columns: [],
    items: [],
    config: null,
    isFetching: false,
    isCreateLoading: false,
    showCreateDialog: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'User Requests', path: '/user-requests' },
    ], false);

    this.fetchUserRequests(client_id);
  };

  fetchUserRequests = async (client_id: number) => {
    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const record = await API.get(`client/${client_id}/record/user-request`);

      this.mounted && this.setState({
        columns: record.columns,
        items: record.data,
        config: record.config,
      });

    } catch (error) {
      console.error('Failed to fetch');
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderCreateDialog =  () => {
    const { client_id, user_id } = this.props;
    return (
      <CreateUserDialog
        clientId={ client_id }
        userId={ user_id }
        onClose={ (refetch: boolean) => {
          this.mounted && this.setState({
            showCreateDialog: false,
            isCreateLoading: false
          }, () => {
            // Only refetch if we've finished the creation
            refetch && this.fetchUserRequests(client_id);
          });
        } }
      />
    );
  };

  renderListView = () => {
    return <BasicList config={ this.state.config } columns={ this.state.columns } items={ this.state.items } />;
  };

  render = () => {
    const { permissions  }= this.props;
    const { showCreateDialog, isFetching, isCreateLoading } = this.state;

    if (process.env.REACT_APP_ENVIRONMENT === 'training') {
      return (
        <div className="d-f jc-c ai-c mT-20 mX-30 h-100p">
          <Result title="Disabled For Training" />
        </div>
      );
    }

    const tabs = [
      {
        label: 'List View',
        node: this.renderListView(),
      }
    ];

    const actions: DropdownAction[] = [];

    if (hasPermission(permissions, 'record_user_request_create')) {
      actions.push({
        node: 'Create User Request',
        onClick: () => this.mounted && this.setState({ isCreateLoading: true, showCreateDialog: true }),
        isLoading: isCreateLoading
      });
    }

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ 'User Requests' }
          tabs={ tabs }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
        />
        { showCreateDialog && this.renderCreateDialog() }
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    user_id: store.UserState.user.id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRequests);
