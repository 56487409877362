// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import ProjectsContainer from 'containers/project/ProjectsContainer';
import TransactionContainer from 'containers/project/TransactionContainer';

// Views
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

class ProjectContainer extends React.Component {

  render = () => {
    return (
      <Switch>
        <Redirect exact from="/projects" to="/projects/projects" />

        <Route exact path="/projects/projects" component={ ProjectsContainer } />
        <Route path="/projects/projects/:record_id" component={ ProjectsContainer } />

        <Route path="/projects/transactions" component={ TransactionContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

export default ProjectContainer;
