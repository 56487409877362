// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import Jumbotron from "components/jumbotron";
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import BasicListView, { List } from "components/basic-list";
import { Modal, Form, Input, Popconfirm, Tooltip, InputNumber } from 'antd';
import Dropdown from 'components/dropdown';

// Icons
import Icon, { EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ReactComponent as Smiley01 } from 'assets/svg/smiley-01.svg';
import { ReactComponent as Smiley02 } from 'assets/svg/smiley-02.svg';
import { ReactComponent as Smiley03 } from 'assets/svg/smiley-03.svg';
import { ReactComponent as Smiley04 } from 'assets/svg/smiley-04.svg';
import { ReactComponent as Smiley05 } from 'assets/svg/smiley-05.svg';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Api
import { Api } from 'services/api';

// Services
import Notification from 'services/notification';
import { getFormatedDate } from 'services/settings';

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  record: List | null;
  isLoading: boolean;
  isFetching: boolean;
  showCreateModal: boolean;
  isCreating: boolean;
  activeOptionId: number | null;
  deleteConfirmId: number | null;
  placeholderIconId: any;
};

const API: Api = new Api();
const { TextArea } = Input;

export const getIconComponent = (key: string) => {
  const icons: { [key: string]: React.ReactNode } = {
    'smiley_01.svg': Smiley01,
    'smiley_02.svg': Smiley02,
    'smiley_03.svg': Smiley03,
    'smiley_04.svg': Smiley04,
    'smiley_05.svg': Smiley05,
  };
  return icons[key] || false;
};

class SliderRange extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;
  formRef: any = React.createRef();

  state: State = {
    record: null,
    isLoading: false,
    isFetching: false,
    showCreateModal: false,
    isCreating: false,
    activeOptionId: null,
    deleteConfirmId: null,
    placeholderIconId: null,
  };

  componentDidMount = async () => {
    this.mounted = true;

    try {
      const { client_id, setBreadcrumbs, setBreadcrumbsLoading } = this.props;
      const type = this.props.match.params.type;

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Content Management', path: '/admin/content-manager' },
      ], false);

      setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const list = await API.get(`client/${client_id}/admin/content-manager/slider-ranges/${type}`);

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Content Management', path: '/admin/content-manager' },
        { title: 'Slider Range', path: '/admin/content-manager/slider-ranges' },
        { title: _.startCase(_.toLower(type)).split('_').join(' '), path: null },
      ], false);

      this.mounted && this.setState({
        record: list,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isFetching: false
      });
    }

  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderAddDialog = (record: any) => {
    const { isLoading, placeholderIconId } = this.state;
    const { client_id } = this.props;
    const type = this.props.match.params.type;
    return (
      <Modal
        visible
        centered
        title={ 'Add Option' }
        onOk={ () => this.mounted && this.setState({
            isCreating: false
          }, () => {
            this.formRef
              .current
              .validateFields()
              .then( async (values: any) => {
                try {

                  await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

                  const record = await API.post(`client/${client_id}/admin/content-manager/slider-ranges/option/${type}`, {
                    data: {
                      title: values.title,
                      description: values.description,
                      value: values.value,
                      icon_id: placeholderIconId
                    }
                  });

                  this.mounted && this.setState({
                    showCreateModal: false,
                    placeholderIconId: null,
                    record: record,
                  }, () => {
                    Notification('success', 'The option has been added to the list.', 'Option Added');
                  });

                } catch (error) {
                  Notification('error', '', 'Failed');
                } finally {
                  this.mounted && this.setState({
                    isLoading: false,
                    placeholderIconId: null,
                  });
                }
              })
              .catch((info: any) => {
                console.error('Invalid state');
              });
          }
        )}
        okText={ 'Save' }
        onCancel={() => this.mounted && this.setState({
          showCreateModal: false,
          placeholderIconId: null,
        })}
        okButtonProps={{
          loading: isLoading,
          disabled: isLoading
        }}
      >
        <Form
          ref={ this.formRef }
          layout="vertical"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Required' }]}
          >
            <TextArea autoSize={{ minRows: 4 }} />
          </Form.Item>
          <Form.Item
            label="Value"
            name="value"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          { !_.isEmpty(record?.config?.icons) &&
            <Form.Item
              label="Icon"
              name="icon"
            >
              <div>
                { record?.config?.icons
                  .filter((icon: { id: number, title: string, file: string}) => {
                    return !!getIconComponent(icon.file);
                  })
                  .map((icon: { id: number, title: string, file: string}, index: number) => {
                    const iconComponent: any = getIconComponent(icon.file);
                    return (
                      <span
                        key={ index }
                        className={ classNames('d-if cur-p p-5 bd', {
                          'border-antd': placeholderIconId === icon.id,
                          'border-transparent': placeholderIconId !== icon.id,
                        }) }
                        onClick={ () => this.setState({
                          placeholderIconId: icon.id !== placeholderIconId ? icon.id : null
                        }) }
                      >
                        <Icon style={{ fontSize: 25 }} component={ iconComponent } />
                      </span>
                    );
                  } )
                }
              </div>
            </Form.Item>
          }
        </Form>
      </Modal>
    );
  };

  renderEditDialog = (record: any, option_id: number) => {
    const { client_id } = this.props;
    const { isLoading, placeholderIconId } = this.state;
    const type = this.props.match.params.type;
    const option = record?.data.find((option: any) => option.id === option_id);
    return (
      <Modal
        visible
        centered
        title={ 'Edit Option' }
        onOk={ () => this.mounted && this.setState({
            isCreating: false
          }, () => {
            this.formRef
              .current
              .validateFields()
              .then( async (values: any) => {
                try {

                  await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

                  const record = await API.put(`client/${client_id}/admin/content-manager/slider-ranges/option/${type}`, {
                    option_id: option_id,
                    data: {
                      title: values.title,
                      description: values.description,
                      value: values.value,
                      icon_id: placeholderIconId,
                    }
                  });

                  this.mounted && this.setState({
                    activeOptionId: null,
                    placeholderIconId: null,
                    record: record,
                  }, () => {
                    Notification('success', 'The option has been changed', 'Option Changed');
                  });

                } catch (error) {
                  Notification('error', '', 'Failed');
                } finally {
                  this.mounted && this.setState({
                    isLoading: false,
                    activeOptionId: null,
                    placeholderIconId: null,
                  });
                }
              })
              .catch((info: any) => {
                console.error('Invalid state');
              });
          }
        )}
        okText={ 'Save' }
        onCancel={() => this.mounted && this.setState({
          activeOptionId: null,
          placeholderIconId: null,
        })}
        okButtonProps={{
          disabled: isLoading,
          loading: isLoading,
        }}
      >
        <Form
          ref={ this.formRef }
          layout="vertical"
          initialValues={{
            "title": option.title,
            "description": option.description,
            "value": option.value,
          }}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Required' }]}
          >
            <TextArea autoSize={{ minRows: 4 }} />
          </Form.Item>
          <Form.Item
            label="Value"
            name="value"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          { !_.isEmpty(record?.config?.icons) &&
            <Form.Item
              label="Icon"
              name="icon"
            >
              <div>
                { record?.config?.icons
                  .filter((icon: { id: number, title: string, file: string}) => {
                    return !!getIconComponent(icon.file);
                  })
                  .map((icon: { id: number, title: string, file: string}, index: number) => {
                    const iconComponent: any = getIconComponent(icon.file);
                    return (
                      <span
                        key={ index }
                        className={ classNames('d-if cur-p p-5 bd', {
                          'border-antd': placeholderIconId === icon.id,
                          'border-transparent': placeholderIconId !== icon.id,
                        }) }
                        onClick={ () => this.setState({
                          placeholderIconId: icon.id !== placeholderIconId ? icon.id : null
                        } ) }
                      >
                        <Icon style={{ fontSize: 25 }} component={ iconComponent } />
                      </span>
                    );
                  } )
                }
              </div>
            </Form.Item>
          }
        </Form>
      </Modal>
    );
  };

  renderView = (columns: any, items: any) => {
    const { showCreateModal, activeOptionId, record } = this.state;
    return (
      <div className='Layout-box'>
        <BasicListView
          rawData
          columns={ columns || [] }
          items={ items || [] }
        />
        { showCreateModal && this.renderAddDialog(record) }
        { activeOptionId && this.renderEditDialog(record, activeOptionId) }
      </div>
    );
  };

  render = () => {
    const { client_id } = this.props;
    const { record, isFetching } = this.state;
    const type = this.props.match.params.type;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Title',
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'description',
          dataIndex: 'description',
          title: 'Description',
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'value',
          dataIndex: 'value',
          title: 'Value',
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'icon',
          dataIndex: 'icon',
          title: 'Icon',
          filterable: false,
          sorter: false,
          ellipsis: false,
          render: (__: any, record: any) => {
            const iconComponent: any = getIconComponent(record?.icon?.file);
            if (iconComponent) {
              return <Icon style={{ fontSize: 25 }} component={ iconComponent } />;
            }
            return '-';
          },
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: any) => getFormatedDate(created_at),
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
        {
          key: 'actions',
          dataIndex: 'actions',
          title: '',
          render: (__: any, option: any) => {
            if (option.isProtected) {
              return (
                <>
                  <Tooltip placement={ 'top' } title={ 'Protected' }>
                    <EditOutlined
                      disabled
                      className="cur-na text-ant-disabled"
                      style={{ fontSize: 18 }}
                    />
                  </Tooltip>
                  <Tooltip placement={ 'top' } title={ 'Protected' }>
                    <DeleteOutlined
                      disabled
                      className="mL-20 cur-na text-ant-disabled"
                      style={{ fontSize: 18 }}
                    />
                  </Tooltip>
                </>
              );
            }
            return (
              <>
                <EditOutlined
                  className="link"
                  style={{ fontSize: 18 }}
                  onClick={ () => this.setState({
                    activeOptionId: option.id,
                    placeholderIconId: option?.icon_id,
                  })}
                />
                <Popconfirm
                  title={ 'Are you sure?' }
                  icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                  visible={ this.state.deleteConfirmId === option.id }
                  okButtonProps={{
                    danger: true
                  }}
                  onConfirm={ async () => {
                    try {
                      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

                      const record = await API.delete(`client/${client_id}/admin/content-manager/slider-ranges/option/${type}`, {
                        option_id: this.state.deleteConfirmId
                      });

                      this.mounted && this.setState({
                        record: record
                      }, () => {
                        Notification('success', 'The option has been deleted.', 'Option Deleted');
                      });

                    } catch (error) {
                      console.error('Error: ', error);
                    } finally {
                      this.mounted && this.setState({
                        isLoading: false,
                        deleteConfirmId: null
                      });
                    }
                  }}
                  onCancel={ () => this.setState({ deleteConfirmId: null }) }
                >
                  <DeleteOutlined
                    disabled={ option.isProtected }
                    className="mL-20 link"
                    style={{ fontSize: 18 }}
                    onClick={ () => {
                      this.setState({
                        deleteConfirmId: option.id
                      });
                    } }
                  />
                </Popconfirm>
              </>
            );
          },
          width: 100,
          sorter: false,
          ellipsis: true,
          filterable: false,
          align: 'center'
        },
      ];
    };

    const mapData = (data: any = []) => {
      return data.map((option: any, index: number) => {
        return {
          ...option,
          'key': index,
          'isProtected': !!option.protected,
        };
      });
    };

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          content={ <p className="mB-0">{ !!type ? _.startCase(_.toLower(type)).split('_').join(' ') : '' }</p> }
          tabs={ [
            {
              label: 'Options',
              node: this.renderView(mapColumns(), mapData(record?.data || [])),
            }
          ] }
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Create Option',
                      onClick: () => this.setState({ showCreateModal: true })
                    }
                  ] }
                />
              )
            }
          ] }
        />
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(withRouter(SliderRange)), 'access_admin_content_manager'));