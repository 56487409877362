// Libs
import * as React from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Views
import Category from 'views/admin/content-manager/categories/Category';
import CategoryRecord from 'views/admin/content-manager/categories/CategoryRecord';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';

interface Props extends RouteComponentProps {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
};

class CategoryContainer extends React.Component<Props> {

  render = () => {
    const { match, permissions } = this.props;
    const type = match.params.type;

    return (
      <Switch>

        <Redirect exact from="/admin/content-manager/categories/:type/:id" to="/admin/content-manager/categories/:type/:id/record" />

        <Route exact path="/admin/content-manager/categories/:type" render={ () => {
          return (
            <RestrictionWrapper restricted={ !hasPermission(permissions, 'access_admin') && 'No access' }>
              <Category />
            </RestrictionWrapper>
          );
        } } />

        <Route exact path="/admin/content-manager/categories/:type/:id/record" render={(props: any) => {
          return (
            <RestrictionWrapper restricted={ !hasPermission(permissions, 'access_admin_content_manager') && 'No access' }>
              <CategoryRecord id={ Number(props.match.params.id) } type={ _.kebabCase(type) } />
            </RestrictionWrapper>
          );
        }}/>

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(CategoryContainer);
