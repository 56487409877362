// Libs
import React from 'react';
import moment from 'moment';
import _ from 'lodash';

// Components
import { Button, DatePicker, Modal } from "antd";
import FieldWrapper from 'components/form/field/field-wrapper';

// Services
import { getUserSetting } from 'services/settings';
import { Api } from 'services/api';
import Notification from 'services/notification';

const API: Api = new Api();

interface Props {
  onSave(items: any): void;
  onClose(): void;
  items: any;
  itemId: number;
  clientId: number;
  type: string;
  bundle: string;
  recordId: number;
};

interface State {
  items: any;
  isEditing: boolean;
};

class SpecificationDateEditModal extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    items: _.cloneDeep(this.props.items),
    isEditing: false,
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleChange = (item: any, itemId: number, dateString: moment.Moment | null, dateField: string) => {
    const date = !!dateString ? dateString.format('YYYY-MM-DD 00:00:00') : null;
    const items = _.cloneDeep(this.state.items);

    this.setState({
      items: items.map((item: any) => {
        if (item.id === itemId) {
          item.values = _.set(item.values, [dateField], { type: 'datetime', value: date, format: 'date' });
        }

        return {
          ...item,
        };
      })
    });
  };

  render = () => {
    const { items } = this.state;
    const { itemId, clientId, type, bundle, recordId } = this.props;
    const originalItems = this.props.items;

    const originalCurrentItem = originalItems.find((_item: any) => _item.id === itemId);
    const currentItem = items.find((_item: any) => _item.id === itemId);

    const footer = (
      <div className='d-f jc-fe ai-fe'>
        <div>
          <Button
            key={ 'cancel' }
            onClick={ () => this.props.onClose() }
          >
            Cancel
          </Button>
          <Button
            key={ 'ok' }
            disabled={ _.isEqual(items, this.props.items) }
            onClick={ () => {
              this.mounted && this.setState({
                isEditing: true,
              }, async () => {
                try {
                  await API.put(`client/${clientId}/${bundle}/${type}/${recordId}/company-summary/update-contract`, {
                    companySummaryRecord: currentItem
                  });

                  this.mounted && this.setState({
                    isEditing: false
                  }, () => {
                    this.props.onSave(this.state.items);
                  });
                } catch (error) {
                  Notification('error', 'Failed to update Company');
                } finally {
                  this.mounted && this.setState({
                    isEditing: false
                  });
                }
              });
            } }
          >
            Ok
          </Button>
        </div>
      </div>
    );

    const contractStartDate = _.has(currentItem.values, 'contract_start_date') ? currentItem.values.contract_start_date.value : null;
    const contractEndDate = _.has(currentItem.values, 'contract_end_date') ? currentItem.values.contract_end_date.value : null;

    const originalContractStartDate = _.has(originalCurrentItem.values, 'contract_start_date') ? originalCurrentItem.values.contract_start_date.value : null;
    const originalContractEndDate = _.has(originalCurrentItem.values, 'contract_end_date') ? originalCurrentItem.values.contract_end_date.value : null;

    return (
      <Modal
        centered
        closable={ false }
        visible
        title={ 'Service Specification Contract Dates' }
        footer={ footer }
        style={{ minWidth: 500 }}
      >
        <div className="Form-Grid">
          <FieldWrapper
            label={ 'Commencement Date' }
            isModified={ !_.isEqual(contractStartDate, originalContractStartDate) }
            col={ 6 }
            border
          >
            <DatePicker
              format={ getUserSetting('date_format') }
              className={ 'Field' }
              defaultValue={ moment(contractStartDate).isValid() ? moment(contractStartDate) : undefined }
              onChange={ (dateString: any) => {
                this.handleChange(currentItem, itemId, dateString, 'contract_start_date');
              } }
            />
          </FieldWrapper>

          <FieldWrapper
            label={ 'Expiry Date' }
            isModified={ !_.isEqual(contractEndDate, originalContractEndDate) }
            col={ 6 }
            border
          >
            <DatePicker
              format={ getUserSetting('date_format') }
              className={ 'Field' }
              defaultValue={ moment(contractEndDate).isValid() ? moment(contractEndDate) : undefined }
              onChange={ (dateString: any) => {
                this.handleChange(currentItem, itemId, dateString, 'contract_end_date');
              } }
            />
          </FieldWrapper>
        </div>
      </Modal>
    );
  };
}

export default SpecificationDateEditModal;