// Libs
import * as React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Views
import FinanceTemplate from 'views/admin/templates/finance/FinanceTemplate';
import FinanceTypes from 'views/admin/templates/finance/FinanceTypes';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';

interface Props extends RouteComponentProps {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
};

class FinanceContainer extends React.Component<Props> {

  render = () => {
    const { permissions } = this.props;

    return (
      <Switch>

        <Route exact path="/admin/templates/finance/type/:finance_template_type_id" render={ () => {
          return (
            <RestrictionWrapper restricted={ !hasPermission(permissions, 'access_admin') && 'No access' }>
              <FinanceTypes />
            </RestrictionWrapper>
          );
        } } />

        <Route exact path="/admin/templates/finance/type/:finance_template_type_id/template/:finance_template_id" render={ () => {
          return (
            <RestrictionWrapper restricted={ !hasPermission(permissions, 'access_admin') && 'No access' }>
              <FinanceTemplate />
            </RestrictionWrapper>
          );
        } } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(FinanceContainer);
