// Libs
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Button, Popover, Input } from 'antd';

// Icons
import { ClockCircleOutlined } from '@ant-design/icons';

// Utils
import { pad } from 'utils/utils';

// Styles
import './DayTimePicker.scss';

interface Props {
  allowBackdrop: boolean;
  defaultDays: number | null;
  defaultHours: number | null;
  defaultMinutes: number | null;
  hasError: boolean;
  isModified: boolean;
  showClockIcon: boolean;
  onTimeChange: (days: number | undefined, hours: number | undefined, minutes: number | undefined, callback: () => void) => void;
};

interface State {
  showPopover: boolean;
  selectedText: string;
  displayText: string;
  days: OptionValue | null;
  hours: OptionValue | null;
  minutes: OptionValue | null;
};

interface OptionValue {
  value: number;
  index: number;
  label: string;
};

const TWENTY_FOUR_LIST: OptionValue[] = _.range(24).map((_value: number) => {
  return {
    label: pad(_value),
    value: _value,
    index: _value,
  };
});

const SIXTY_LIST: OptionValue[] = _.range(60).map((_value: number) => {
  return {
    label: pad(_value),
    value: _value,
    index: _value,
  };
});

export class DayTimePicker extends React.Component<Props, State> {

  inputRef: any = React.createRef();
  daysRef: any = React.createRef();
  hoursRef: any = React.createRef();
  minutesRef: any = React.createRef();

  state: State = {
    showPopover: false,
    selectedText: '',
    displayText: '',
    days: null,
    hours: null,
    minutes: null,
  };

  componentDidMount() {
    if (!!this.props.defaultDays) {
      this.setState({
        days: !!this.props.defaultDays ? { label: String(this.props.defaultDays), value: this.props.defaultDays, index: this.props.defaultDays } : null
      });
    }

    let defaultHours = null;
    if (!!this.props.defaultHours) {
      defaultHours = TWENTY_FOUR_LIST.find((_hour: OptionValue) => _hour.value === this.props.defaultHours);
      if (!!defaultHours) {
        this.setState({
          hours: defaultHours
        });
      }
    }

    let defaultMinutes = null;
    if (!!this.props.defaultMinutes) {
      defaultMinutes = SIXTY_LIST.find((_hour: OptionValue) => _hour.value === this.props.defaultMinutes);
      if (!!defaultMinutes) {
        this.setState({
          minutes: defaultMinutes
        });
      }
    }

    if (!!this.props.defaultDays && !!defaultHours && !!defaultMinutes) {
      const displayValue = this.formatValue(this.props.defaultDays, defaultHours?.value, defaultMinutes?.value);
      this.setState({ displayText: displayValue });
    }
  };

  handleSaveClick = () => {
    this.applyChanges();
  };

  handleChange = (__: React.MouseEvent, key: string, option: OptionValue) => {
    const hoursWrapper = this.hoursRef.current;
    const minutesWrapper = this.minutesRef.current;
    const daysWrapper = this.daysRef.current;
    const hours = hoursWrapper.children;
    const minutes = minutesWrapper.children;
    const days = daysWrapper.children;

    const optionIndex = option.index;

    switch (key) {
      case 'hour':
        this.setState({
          hours: TWENTY_FOUR_LIST[optionIndex]
        }, () => {
          hours[optionIndex].focus();
        });
        break;

      case 'minute':
        this.setState({
          minutes: SIXTY_LIST[optionIndex]
        }, () => {
          minutes[optionIndex].focus();
        });
        break;

      case 'day':
        this.setState({
          days: option
        }, () => {
          days[optionIndex].focus();
        });
        break;
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ displayText: event.target.value });
  };

  handlePopoverVisibility = (visible: boolean) => {
    this.setState({ showPopover: visible });
  };

  formatValue = (days: number | undefined, hours: number | undefined, minutes: number | undefined) => {
    return `${!!days ? days : 'd'}d ${!!hours ? hours : 'h' }h ${!!minutes ? minutes : 'm' }m`;
  };

  applyChanges = () => {
    const { onTimeChange } = this.props;
    const { days, hours, minutes } = this.state;

    onTimeChange(days?.value, hours?.value, minutes?.value, () => {
      const displayValue = this.formatValue(days?.value, hours?.value, minutes?.value);
      this.setState({
        displayText: displayValue,
        showPopover: false,
      });
    });
  };

  renderPopover = () => {
    const { showClockIcon, isModified, hasError } = this.props;
    const { showPopover, displayText, days, hours, minutes } = this.state;

    return (
      <Popover
        visible={ showPopover }
        onVisibleChange={ this.handlePopoverVisibility }
        content={ () => {
          return (
            <div className="DayTimePicker-main-wrapper">
              <div className="DayTimePicker-dropdown-wrapper DayTimePicker-dropdown-active">
                <div className="DayTimePicker-options-container pB-2">
                  <div className="DayTimePicker-title mR-5">Days</div>
                  <div className="DayTimePicker-title mR-5">Hours</div>
                  <div className="DayTimePicker-title">Minutes</div>
                </div>
                <div className="DayTimePicker-options-container">
                  <div className="DayTimePicker-option-wrapper">
                    <div className="DayTimePicker-options" ref={ this.daysRef }>
                      { _.range(366).map((day: any, index: number) => (
                        <div
                          className={ classNames('DayTimePicker-option', { 'DayTimePicker-option-selected': days?.value === day }) }
                          key={ `days-${index}` }
                          onClick={ (event: React.MouseEvent) => this.handleChange(event, 'day', { value: day, label: day, index: index }) }
                        >
                          { day }
                        </div>
                      )) }
                    </div>
                  </div>
                  <div className="DayTimePicker-option-wrapper">
                    <div className="DayTimePicker-options" ref={ this.hoursRef }>
                      { TWENTY_FOUR_LIST.map((option: OptionValue) => (
                        <div
                          className={ classNames('DayTimePicker-option', { 'DayTimePicker-option-selected': hours?.value && hours.value === option.index }) }
                          key={ `hours-${option.value}` }
                          onClick={(event: React.MouseEvent) => this.handleChange(event, 'hour', option)}>{ option.label }</div>
                      )) }
                    </div>
                  </div>
                  <div className="DayTimePicker-option-wrapper">
                    <div className="DayTimePicker-options" ref={ this.minutesRef }>
                      { SIXTY_LIST.map((option: OptionValue) => (
                        <div
                          className={ classNames('DayTimePicker-option', { 'DayTimePicker-option-selected': minutes?.value && minutes.value === option.index }) }
                          key={ `minutes-${option.value}` }
                          onClick={ (event: React.MouseEvent) => this.handleChange(event, 'minute', option) }>{ option.label }</div>
                      )) }
                    </div>
                  </div>
                </div>
                <div className="DayTimePicker-bottom-bar-wrapper">
                  <div className="DayTimePicker-bottom-bar"></div>
                </div>
                <div className="DayTimePicker-actions-container">
                  <Button onClick={ this.handleSaveClick }>{ 'OK' }</Button>
                </div>
              </div>
            </div>
          );
        } }
        title="Time"
        trigger="click"
      >
        <Input
          readOnly
          value={ displayText }
          ref={ this.inputRef }
          onChange={ this.handleInputChange }
          placeholder={ 'd h m' }
          suffix={ showClockIcon ? <ClockCircleOutlined/> : null }
          className={ classNames('Field', {
            'Field--has-error border-danger': hasError,
            'Field--has-warning border-warning': isModified && !hasError,
          }) }
        />
      </Popover>
    );
  };

  render = (): JSX.Element => {
    return (
      <div className="DayTimePicker-outer-wrapper">
        { this.renderPopover() }
      </div>
    );
  };
};

export default DayTimePicker;
