// Libs
import * as React from 'react';

// Icons
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

// Components
import CoverModal from 'components/cover-modal';
import { Button, Steps } from 'antd';

interface Props {
  clientName: string;
  onClose(): void;
};

interface State {
  step: number;
};

const { Step } = Steps;
const stepsContent = (clientName: string) => [
  {
    content: (
      <div>
        <h1 className="ta-c">Welcome to PACS</h1>
        <p className="mT-50">PACS is a complete Integrated Workplace Management Solution (IWMS) designed to measure performance, monitor compliance, and drive improvements across the entire real estate cycle.</p>
        <p className="mT-10">We’ve worked hard to make PACS user friendly, and { clientName } has already chosen some system wide settings for you but you may wish to customise some of these to suit your personal preference.</p>
        <p className="mT-10">This is the place to do it, you can come back to this page at any time by clicking on the icon by your user name on the top right of the screen and selecting <b>Settings</b>.</p>
      </div>
    ),
  }
];

class FirstLoginModal extends React.Component<Props, State> {

  state: State = {
    step: 0,
  };

  render = () => {
    const { onClose, clientName } = this.props;
    const { step } = this.state;
    const steps = stepsContent(clientName);
    return (
      <CoverModal
        middleContent={ (
          <div className="mT-30">
            { steps.length > 1 &&
              <Steps current={ step }>
                { steps.map((_step, index ) => <Step key={ `step-${index}` } /> ) }
              </Steps>
            }
          </div>
        ) }
        style={{ maxWidth: 800, width: '80%' }}
        onClose={ onClose }
      >
        <div className="d-f fxd-c h-100p ta-l pT-10 pL-50 pR-50 pB-50">
          <div className="fxg-1">
            { steps[step].content }
          </div>
          <div className="d-f jc-sb mT-100">
            <div>
              { step !== 0 &&
                <>
                  <Button
                    type="primary"
                    className="mR-1"
                    icon={ <CaretLeftOutlined /> }
                    onClick={ () => {
                      this.setState({
                        step: step - 1
                      });
                    } }
                  />
                  <Button
                    type="primary"
                    onClick={ () => {
                      this.setState({
                        step: step - 1
                      });
                    } }
                  >
                    Back
                  </Button>
                </>
              }
            </div>
            <div>
              { step === steps.length - 1 ? (
                <Button
                  type="primary"
                  onClick={ onClose }
                >
                  Ok, I'm ready
                </Button>
              ) : !!steps[step+1] ? (
                <>
                  <Button
                    type="primary"
                    onClick={ () => {
                      this.setState({
                        step: step + 1
                      });
                    } }
                  >
                    Next
                  </Button>
                  <Button
                    type="primary"
                    className="mL-1"
                    icon={ <CaretRightOutlined /> }
                    onClick={ () => {
                      this.setState({
                        step: step + 1
                      });
                    } }
                  />
                </>
              ) : (
                <></>
              ) }
            </div>
          </div>
        </div>
      </CoverModal>
    );
  };
};

export default FirstLoginModal;
