// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import _ from 'lodash';

// Components
import Jumbotron from "components/jumbotron";
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import { Table, Slider } from 'antd';
import Dropdown from 'components/dropdown';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  consequences: any;
  probabilities: any;
  boundaries: any;
  originalBoundaries: any;
  isFetching: boolean;
  isSaving: boolean;
};

const API: Api = new Api();

class ProbabilityConsequenceMatrix extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    consequences: null,
    probabilities: null,
    boundaries: null,
    originalBoundaries: null,
    isFetching: true,
    isSaving: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Content Management', path: '/admin/content-manager' },
      { title: 'Configurations', path: '/admin/content-manager/configurations' },
      { title: 'Probability / Consequence Matrix', path: null },
    ], false);

    try {

      const config = await API.get(`client/${client_id}/admin/content-manager/configurations/probability-consequence-matrix`);
      const boundaries = _.has(config, 'boundaries.green') ? {
        green: {
          lower: parseFloat(config.boundaries.green.lower),
          upper: parseFloat(config.boundaries.green.upper),
        },
        amber: {
          lower: parseFloat(config.boundaries.amber.lower),
          upper: parseFloat(config.boundaries.amber.upper),
        },
        red: {
          lower: parseFloat(config.boundaries.red.lower),
          upper: parseFloat(config.boundaries.red.upper),
        },
      } : null;

      this.mounted && this.setState({
        consequences: config.consequence,
        probabilities: config.probability,
        boundaries: boundaries,
        originalBoundaries: boundaries,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  saveBoundaries = async (state: State) => {
    const { client_id } = this.props;
    try {

      await new Promise((resolve) => this.setState({ isSaving: true }, () => resolve(null)));
      await API.put(`client/${client_id}/admin/content-manager/configurations/probability-consequence-matrix`, {
        data: {
          boundaries: state.boundaries
        }
      });

      Notification('success', 'The boundaries were saved', 'Boundaries Saved');

    } catch (error) {
      Notification('error', '', 'Failed');
    } finally {
      this.mounted && this.setState({
        isSaving: false
      });
    }
  };

  inRange = (value: number, min: number, max: number): boolean => {
    return value >= min && value <= max;
  };

  getRagColor = (boundaries: any, value: number): string => {

    // Red
    if (this.inRange(value, boundaries.red.lower, boundaries.red.upper)) {
      return '#d6494a';
    }

    // Amber
    if (this.inRange(value, boundaries.amber.lower, boundaries.amber.upper)) {
      return '#efb636';
    }

    // Green
    if (this.inRange(value, boundaries.green.lower, boundaries.green.upper)) {
      return '#00875a';
    }

    return '#fff';
  };

  getProbabilityTitle = (probabilities: any, value: number) => {
    const probabilityOption = probabilities && _.has(probabilities, 'options') && probabilities.options.find((option: any) => option.value === value);

    if (probabilityOption) {
      return probabilityOption.title;
    }

    return value;
  };

  getConsequenceTitle = (consequences: any, value: number) => {
    const consequenceOption = consequences && _.has(consequences, 'options') && consequences.options.find((option: any) => option.value === value);

    if (consequenceOption) {
      return consequenceOption.title;
    }

    return value;
  };

  calculateRangeProcentage = (upper: any, lower: any, max: any) => {
    return ((upper - (lower - 1)) / max * 100);
  };

  handleChange = (step: any, values: any) => {
    this.setState({
      boundaries: {
        green: { ...this.state.boundaries.green, upper: parseFloat(values[0]) - step },
        amber: { ...this.state.boundaries.amber, lower: parseFloat(values[0]), upper: parseFloat(values[1]) },
        red: { ...this.state.boundaries.red, lower: parseFloat(values[1]) + step },
      }
    });
  };

  renderBoundarySummary = (lower: any, upper: any) => {
    if (lower === upper) {
      return `${lower}`;
    }

    return `${lower} - ${upper}`;
  };

  renderView = () => {
    const { consequences, probabilities, boundaries } = this.state;

    if (!consequences || !probabilities) return <></>;

    if (!boundaries) {
      return <>No boundaries found</>;
    }

    const consequenceRange = (consequences.max - consequences.min) + 2;
    const probabilityRange = (probabilities.max - probabilities.min) + 1;
    const step = 1.0;

    const columns: any = [
      {
        'title': <div>{ 'Consequence of Failure' }</div>,
        'children': [...Array(consequenceRange)].map((__: any, index: number) => {
          return {
            key: index,
            dataIndex: index,
            title: !index ? '' : (
              <div className="ta-c">{ this.getConsequenceTitle(consequences, index) }</div>
            ),
            render: (__: any, record: any) => {

              if (!index) {
                return <div className="default-grey fw-500 ta-l pL-10">{ this.getProbabilityTitle(probabilities, record.value) }</div>;
              }

              return (
                <div className="ta-c">{ record.value * (index || 1) }</div>
              );
            },
            onCell: (record: any) => {
              return { className: 'p-0 bd', style: { height: 50, backgroundColor: this.getRagColor(boundaries, record.value * index) } };
            },
            onHeaderCell: () => {
              return { className: 'p-0', style: { height: 50 } };
            },
            width: 100,
            filterable: false,
            sorter: false,
            ellipsis: false,
          };
        })
      }
    ];

    const data: any = [...Array(probabilityRange)].map((__: any, index: number) => {
      return {
        'key': index,
        'value': index + 1,
      };
    });

    return (
      <div className='Layout-box'>
        <div>
          <p>Please use the slider below to set the Red, Amber and Green boundaries.</p>
          <ul>
            <li><b>Green:</b> Low Risk</li>
            <li><b>Amber:</b> Medium Risk</li>
            <li><b>Red:</b> High Risk</li>
          </ul>
        </div>
        <div className="mT-50">
          <Slider
            range
            min={ consequences.min + 1 }
            max={ (consequences.max * probabilities.max) - 1 }
            step={ step }
            defaultValue={ [this.state.boundaries.amber.lower, this.state.boundaries.amber.upper] }
            onAfterChange={ (values: any[]) => this.handleChange(step, values) }
          />
        </div>
        <div className="mT-50 d-f">
          <div className="d-f ai-c jc-c" style={{ height: 50, background: '#00875a', width: `${this.calculateRangeProcentage(this.state.boundaries.green.upper, this.state.boundaries.green.lower, this.state.boundaries.red.upper)}%` }}>
            <span>{ this.renderBoundarySummary(this.state.boundaries.green.lower, this.state.boundaries.green.upper) }</span>
          </div>
          <div className="d-f ai-c jc-c"style={{ height: 50, background: '#efb636', width: `${this.calculateRangeProcentage(this.state.boundaries.amber.upper, this.state.boundaries.amber.lower, this.state.boundaries.red.upper)}%` }}>
            <span>{ this.renderBoundarySummary(this.state.boundaries.amber.lower, this.state.boundaries.amber.upper) }</span>
          </div>
          <div className="d-f ai-c jc-c" style={{ height: 50, background: '#d6494a', width: `${this.calculateRangeProcentage(this.state.boundaries.red.upper, this.state.boundaries.red.lower, this.state.boundaries.red.upper)}%` }}>
            <span>{ this.renderBoundarySummary(this.state.boundaries.red.lower, this.state.boundaries.red.upper) }</span>
          </div>
        </div>
        <div className="mT-50 mB-50 d-f">
          <div style={{ writingMode: 'vertical-rl', transform: 'rotate(-180deg)', textAlign: 'center', width: 50 }}>
            <span className="default-grey fw-500">Probability of Failure</span>
          </div>
          <div className="fx-1">
            <Table
              size={ 'small' }
              columns={ columns }
              dataSource={ data }
              pagination={ false }
            />
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    const { boundaries, originalBoundaries, isFetching, isSaving } = this.state;
    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          content={ <p className="mB-0">{ 'Asset Probability/Consequences risk' }</p> }
          tabs={ [
            {
              label: '',
              node: this.renderView(),
            }
          ] }
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Save',
                      isLoading: isSaving,
                      disabled: _.isEqual(boundaries, originalBoundaries),
                      onClick: () => this.saveBoundaries(this.state)
                    }
                  ] }
                />
              )
            }
          ] }
        />
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(ProbabilityConsequenceMatrix), 'access_admin_content_manager'));