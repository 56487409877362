// Libs
import * as React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Views
import ClassificationTemplate from 'views/admin/templates/classifications/ClassificationTemplate';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';

interface Props extends RouteComponentProps {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
};

class ClassificationContainer extends React.Component<Props> {

  render = () => {
    const { permissions } = this.props;

    return (
      <Switch>

        <Route exact path="/admin/templates/classifications/type/:classification_type_id" render={ () => {
          return (
            <RestrictionWrapper restricted={ !hasPermission(permissions, 'access_admin') && 'No access' }>
              <ClassificationTemplate />
            </RestrictionWrapper>
          );
        } } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(ClassificationContainer);
