// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import Badge, { BadgeType } from 'components/badge';
import { Select, TreeSelect } from 'antd';

// Interfaces
import { OpexCoa, CommercialModel, Modified, Field, FieldValues } from './CoaSelection.interfaces';
import {
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
  FormValues,
} from 'components/form/form-wrapper';

// Utils
import { orderListByKey } from 'utils/formSetup';
import { nestedSet, flattenSet } from 'utils/utils';

interface Props {
  config: FormFieldConfig;
  field: Field;
  originalValues: FormValues[];
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: Field,
    values: Record<string, string | number>[] | [],
    config: FormFieldConfig,
    column?: string,
  ): void;
  onRefreshForm(field_id: string): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  border?: boolean;
  isDisabled?: boolean;
};

class CoaSelection extends React.Component<Props> {

  componentDidMount = () => {
    this.validate(this.props.field.values as FieldValues, this.props.originalValues as FieldValues);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (!_.isEqual(prevProps.field, this.props.field)) {
      this.validate(this.props.field.values as FieldValues, this.props.originalValues as FieldValues);

      if (!!this.props.field.config.refresh_on_change) {
        this.props.onRefreshForm(this.props.field.id);
      }
    }
  };

  validate = (values: FieldValues, originalValues: FieldValues): void => {
    const { field, config, setFieldModifiedMessage, setFieldErrorMessage } = this.props;

    const fieldKey = this.getFieldKey();
    const modified = this.getModified(values, originalValues);
    const errors = this.getErrors(values);

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const errorMessage = _.isEmpty(errors) ? undefined : { ...generalMessageInfo, errors: errors };
    const modifiedMessage = _.isEmpty(modified) ? undefined : { ...generalMessageInfo, modified: modified };

    setFieldErrorMessage(fieldKey, errorMessage);
    setFieldModifiedMessage(fieldKey, modifiedMessage);
  };

  getErrors = (values: FieldValues): Modified => {
    const errors: Modified = {};

    // No need to check for errors on this field.

    return errors;
  };

  getModified = (values: FieldValues, originalValues: FieldValues): Modified => {

    const modified: Modified = {};

    if (!_.isEqual(values, originalValues)) {

      // Maintenance Plan
      if (!_.isEqual(values[0]?.opex_coa_id, originalValues[0]?.opex_coa_id)) {
        modified.opexCoa = true;
      }

      // Asset Category
      if (!_.isEqual(values[0]?.commercial_model_id, originalValues[0]?.commercial_model_id)) {
        modified.commercialModel = true;
      }
    }

    return modified;
  };

  getFieldKey = (): string => {
    return `${this.props.field.id}_${this.props.config.fieldIndex || 0}_${this.props.field.type}`;
  };

  hasError = (fieldErrorMessages: any, fieldKey: string, key: keyof Modified): boolean => {
    return (
      _.has(fieldErrorMessages, [fieldKey, 'errors', key]) &&
      fieldErrorMessages[fieldKey]['errors'][key]
    );
  };

  isModified = (fieldModifiedMessages: any, fieldKey: string, key: keyof Modified): boolean => {
    return (
      _.has(fieldModifiedMessages, [fieldKey, 'modified', key]) &&
      fieldModifiedMessages[fieldKey]['modified'][key]
    );
  };

  renderCoaSelect = (values: any): JSX.Element => {
    const { field, config, onChange, fieldModifiedMessages } = this.props;

    const fieldKey = this.getFieldKey();
    const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, 'opexCoa');

    return (
      <TreeSelect
        className={ classNames('Select-Field', {
          'Select-Field--has-warning border-warning': isModified,
        }) }
        allowClear
        showSearch
        dropdownMatchSelectWidth={ false }
        placeholder={ 'Please Select' }
        showCheckedStrategy={ TreeSelect.SHOW_PARENT }
        maxTagCount={ 2 }
        multiple={ false }
        treeData={ nestedSet(field.opex_coas) }
        value={ values?.opex_coa_id || undefined }
        filterTreeNode={ (input: string, option: any) => {
          if (option) {
            const filteredInput = input.toLocaleLowerCase();
            const title = option.title && option.title.toLowerCase();

            if (title.includes(filteredInput)) {
              return true;
            }
          }

          return false;
        } }
        onChange={ (opex_coa_id: string | number) => {
          const newValues: any = {};
          const selectedCoa = flattenSet(field.opex_coas).find((opexCoa: OpexCoa) => opexCoa.id === opex_coa_id);

          if (opex_coa_id) {
            newValues.opex_coa_id = opex_coa_id;

            // Auto select first coa
            if (selectedCoa && !_.isEmpty(selectedCoa.commercial_model_map)) {
              newValues.commercial_model_id = selectedCoa.commercial_model_map[0];
            }
          }

          onChange(field, !_.isEmpty(newValues) ? [newValues] : [], config);
        } }
      />
    );
  };

  renderCommercialModelSelect = (values: any): JSX.Element => {
    const { field, config, onChange, fieldModifiedMessages, fieldErrorMessages, isDisabled } = this.props;

    const fieldKey = this.getFieldKey();
    const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, 'commercialModel');
    const selectedCoaId = values?.opex_coa_id;

    const commercialModels = orderListByKey(field?.commercial_models || [], 'title')
      .filter((commercialModel: CommercialModel) => {
        const selectedCoa = flattenSet(field.opex_coas).find((opexCoa: OpexCoa) => opexCoa.id === selectedCoaId);
        return selectedCoa?.commercial_model_map.includes(commercialModel.id);
      });

    return (
      <Select
        className={ classNames('Select-Field', {
          'Select-Field--has-warning border-warning': isModified,
        }) }
        showSearch
        allowClear
        disabled={ !!isDisabled || !selectedCoaId }
        dropdownMatchSelectWidth={ false }
        placeholder={ 'Please Select' }
        value={ values?.commercial_model_id || undefined }
        filterOption={(input: string, option: any) => {
          return !!field.commercial_models?.find((commercial_model: CommercialModel) => commercial_model.title.toLowerCase() === option.children.toLowerCase() && commercial_model.title.toLowerCase().includes(input.toLowerCase()) );
        } }
        onChange={ (commercial_model_id: number) => {
          const newValue = _.cloneDeep(values);

          if (commercial_model_id) {
            newValue.commercial_model_id = commercial_model_id;
          } else {
            delete newValue.commercial_model_id;
          }

          onChange(field, [newValue], config);
        } }
      >
        { commercialModels.map((commercialModel: CommercialModel) => (
          <Select.Option key={ commercialModel.id } value={ commercialModel.id }>{ commercialModel.title }</Select.Option>
        )) }
      </Select>
    );
  };

  render = () => {
    const { field, border, fieldErrorMessages } = this.props;

    const fieldKey = this.getFieldKey();
    const errors = _.has(fieldErrorMessages, fieldKey) ? ['Cannot be empty'] : [];
    const isRequired = field.config.required;
    const selectedCommercialModel = field?.commercial_models?.find((commercialModel: CommercialModel) => commercialModel.id === field.values[0]?.commercial_model_id);

    return (
      <FieldWrapper
        col={ 12 }
        label={ field.label }
        description={ field.description }
        required={ isRequired }
        border={ border }
        errors={ errors }
        hideErrorInfo
      >
        <div className="Form-Grid">
          <FieldWrapper
            col={ 6 }
            label={ <p className="fsz-xs fw-500">Service</p> }
            description={ `Please select a 'Opex COA' using the dropdown list.` }
            errors={ [] }
            required={ isRequired }
            border
          >
            { this.renderCoaSelect((field.values as FieldValues)[0]) }
          </FieldWrapper>
          <FieldWrapper
            col={ 6 }
            label={ <p className="fsz-xs fw-500">Commercial Model</p> }
            description={ `Please select a 'Commercial Model' using the dropdown list.` }
            errors={ [] }
            required={ isRequired }
            border
          >
            { this.renderCommercialModelSelect((field.values as FieldValues)[0]) }
          </FieldWrapper>
          <FieldWrapper
            col={ 12 }
            label={ <p className="fsz-xs fw-500">Additional Information</p> }
            border
          >
            <div className="Form-Grid">
              <FieldWrapper
                col={ 4 }
                label={ <p className="fsz-xs fw-400">Quote Required</p> }
                border
              >
                { selectedCommercialModel?.quote_required || '-' }
              </FieldWrapper>
              <FieldWrapper
                col={ 4 }
                label={ <p className="fsz-xs fw-400">Work Chargeable</p> }
                border
              >
                { selectedCommercialModel?.work_chargeable || '-' }
              </FieldWrapper>
            </div>
          </FieldWrapper>
        </div>
      </FieldWrapper>
    );
  };
}

export default CoaSelection;
