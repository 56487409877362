import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';

interface Params {
  rangeStart: moment.Moment;
  daysToAdd: number;
  weekdays?: number[];
  exclusions?: string[];
  inclusions?: string[];
};

export function calculateWorkdays(params: Params): moment.Moment {
  const { rangeStart, daysToAdd, weekdays = [1, 2, 3, 4, 5], exclusions, inclusions } = params;
  return calculateDate(rangeStart, daysToAdd, parseWeekdays(weekdays), exclusions, inclusions);
};

function calculateDate(
  rangeStart: moment.Moment,
  daysToAdd: number,
  weekdays: number[],
  exclusions?: string[],
  inclusions?: string[],
) {
  let daysLeft = daysToAdd;
  const resultDate = rangeStart.clone();
  const str_exclusions = parseSet(exclusions);
  const str_inclusions = parseSet(inclusions);

  if (daysLeft >= 0) {
    /* if positive value - add days */
    while (daysLeft > 0) {
      resultDate.add(1, 'day');
      const included = str_inclusions.length !== 0 && str_inclusions.indexOf(resultDate.format(DATE_FORMAT)) >= 0;
      if (
        included ||
        (weekdays.indexOf(resultDate.weekday()) >= 0 &&
          (str_exclusions.length === 0 || str_exclusions.indexOf(resultDate.format(DATE_FORMAT)) < 0))
      ) {
        daysLeft--;
      }
    }
  } else {
    /* if negative value - subtract days */
    while (daysLeft < 0) {
      resultDate.subtract(1, 'day');
      const included = str_inclusions.length !== 0 && str_inclusions.indexOf(resultDate.format(DATE_FORMAT)) >= 0;
      if (
        included ||
        (weekdays.indexOf(resultDate.weekday()) >= 0 &&
          (str_exclusions.length === 0 || str_exclusions.indexOf(resultDate.format(DATE_FORMAT)) < 0))
      ) {
        daysLeft++;
      }
    }
  }

  return resultDate;
};

function parseSet(set?: string[]) {
  if (!set) return [];
  return set.map((item) => moment(item).format(DATE_FORMAT));
};

const parseWeekdays = function (weekdays: number[]) {
  const validWeekdays = [];
  if (!weekdays) {
    throw new Error('weekdays must be defined');
  }
  if (weekdays.length > 7) {
    throw new Error('Weekdays array exceeding week length of 7 days');
  }
  for (let i = 0; i < weekdays.length; i++) {
    let weekday = weekdays[i];
    if (!isNaN(weekday)) {
      if (weekday < 0 || weekday > 6) throw new Error('The weekday is out of 0 to 6 range');
    } else {
      weekday = moment().day(weekday).weekday();
    }
    if (validWeekdays.indexOf(weekday) >= 0) {
      throw new Error('Weekdays set contains duplicate weekday');
    }
    validWeekdays.push(weekday);
  }
  return validWeekdays;
};