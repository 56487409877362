// Libs
import * as React from "react";
import _ from 'lodash';

// Components
import { Form, Modal, Select } from 'antd';

// Interfaces
import { AvailableResourceRecord, User } from 'components/resources-table';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

interface Props {
  clientId?: number;
  runEndpoint: string;
  checkEndpoint: string;
  entities: any[];
  skipNotifications?: boolean;
  onClose: () => void;
  onSuccess: (response: any) => void;
};

interface State {
  roleId: number | null;
  companyId: number | null;
  roles: AvailableResourceRecord[];
  users?: User[];
  companies?: any[];
  userId: number | null;
  isLoading: boolean;
  companiesAreLoading: boolean;
  usersAreLoading: boolean;
  isApplyingOperation: boolean;
  errorResponse: any[];
};

const API: Api = new Api();

class RemoveResourceModal extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    roleId: null,
    companyId: null,
    userId: null,
    roles: [],
    users: [],
    companies: [],
    isLoading: false,
    companiesAreLoading: false,
    usersAreLoading: false,
    isApplyingOperation: false,
    errorResponse: [],
  };

  componentDidMount = async () => {
    const { clientId, entities } = this.props;

    this.mounted = true;

    try {
      if (!clientId) throw new Error('Failed');

      const bundles: string[] = [];
      const types: string[] = [];

      entities.forEach((entity: any) => {
        bundles.push(entity.bundle);
        types.push(entity.type);
      });

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const roles = await API.get(`client/${clientId}/resource/search/role`, {
        bundle: _.uniq(bundles).pop(),
        types: _.uniq(types)
      });

      this.mounted && this.setState({
        roles: roles
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  getCompanies = async (roleId: number) => {
    try {
      const { clientId, entities } = this.props;

      await new Promise((resolve) => this.setState({ companiesAreLoading: true }, () => resolve(null)));

      const companies = await API.post(`client/${clientId}/bulk-operations/remove-resource/companies`, {
        entities: entities,
        role_id: roleId,
      });

      return companies;

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        companiesAreLoading: false,
      });
    }
  };

  getUsers = async (roleId: number, companyId: number) => {
    try {
      const { clientId, entities } = this.props;

      await new Promise((resolve) => this.setState({ usersAreLoading: true }, () => resolve(null)));

      const users = await API.post(`client/${clientId}/bulk-operations/remove-resource/users`, {
        entities: entities,
        companies: [companyId],
        role_id: roleId,
      });

      return users;

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        usersAreLoading: false,
      });
    }
  };

  handleAdd = async () => {
    const { checkEndpoint, runEndpoint, entities, skipNotifications, onClose, onSuccess } = this.props;
    const { roleId, userId } = this.state;

    try {
      await new Promise((resolve) => this.setState({ isApplyingOperation: true }, () => resolve(null) ));

      const response = await API.post(checkEndpoint, {
        config: { role_id: roleId, user_id: userId, skip_notifications: !!skipNotifications },
        entities: entities
      });

      const hasError = response.find((_error: any) => _error.status === 'error');

      if (!hasError) {
        const runResponse = await API.post(runEndpoint, {
          config: { role_id: roleId, user_id: userId, skip_notifications: !!skipNotifications },
          entities: entities
        });

        Notification('success', `Resource has been removed`);
        onClose();
        onSuccess(runResponse);
      }
    } catch (error: any) {
      console.error('Error: ', error);
      Notification('error', 'Remove Resource Failed');
      this.setState({
        errorResponse: error.data
      });
    } finally {
      this.mounted && this.setState({
        isApplyingOperation: false
      });
    }
  };

  render = () => {
    const { onClose } = this.props;
    const { roleId, roles, companyId, companies, userId, users, isLoading, companiesAreLoading, usersAreLoading, isApplyingOperation, errorResponse } = this.state;

    return (
      <Modal
        centered
        visible
        title={ 'Remove Resources' }
        closable={ false }
        onCancel={ () => onClose() }
        okText={ 'Remove' }
        onOk={ this.handleAdd }
        okButtonProps={{
          disabled: !roleId || !userId,
          loading: isApplyingOperation
        }}
        cancelButtonProps={{
          disabled: isLoading || isApplyingOperation
        }}
        confirmLoading={ isLoading }
      >
        { !_.isEmpty(errorResponse) && (
          <div className='d-f fxd-c jc-c mT-10 mB-10'>
            <div className="fw-600 mB-10">We could not run the transition because:</div>
            { errorResponse.map((message: any, key: number) => (
              <ul className="mL-5" key={ `error_group_${key}` }>
                <li key={ `error_title_${key}` }>
                  { message.title }
                  <ul>
                    { message.errors.map((error: string, index: number) => (
                      <li className="ant-text-danger" key={ `error_message_${key}_${index}` }>{ error }</li>
                    )) }
                  </ul>
                </li>
              </ul>
            )) }
          </div>
        ) }
        <Form layout="vertical">
          <Form.Item label={ 'Role' } required>
            <Select
              showSearch
              placeholder={ 'Select Role' }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              onChange={(role: any) => {
                this.setState({
                  companiesAreLoading: true,
                  roleId: role,
                }, async () => {
                  const _companies = await this.getCompanies(role);

                  // default if only one company
                  let _companyId = null;
                  let _users = null;
                  let _userId = null;

                  if (_companies && _companies.length === 1) {
                    _companyId = _companies[0].id;

                    // default if only one user
                    _users = await this.getUsers(role, _companyId);
                    if (_users && _users.length === 1) {
                      _userId = _users[0];
                    }
                  }

                  this.setState({
                    companiesAreLoading: false,
                    companies: _companies,
                    userId: _userId
                  });
                });
              }}
              value={ roleId }
            >
              {roles && roles.map((role: any) => (
                <Select.Option key={`add-role-${role.id}`} value={role.id}>
                  {role.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={ 'Company' } required>
            <Select
              showSearch
              placeholder={ 'Select Company' }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              onChange={(_company: number) => {
                this.setState({
                  usersAreLoading: true,
                  companyId: _company,
                }, async () => {
                  if (!!roleId && _company) {
                    const users = await this.getUsers(roleId, _company);
                    let _userid = null;

                    if (users && users.length === 1) {
                      _userid = users[0].id;
                    }

                    this.setState({
                      usersAreLoading: false,
                      users: users,
                      userId: _userid
                    });
                  }
                });

              } }
              value={ companyId }
              loading={ companiesAreLoading || false }
              disabled={ !companies || _.isEmpty(companies)}
            >
              { companies && companies.map((company: any) => (
                <Select.Option key={`add-company-${company.id}`} value={company.id}>
                  { company.title }
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={ 'User' } required>
            <Select
              showSearch
              placeholder={ !users || users.length === 0 ? 'No Users Found' : 'Select User' }
              filterOption={ (input: any, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              } }
              onChange={(_userId: number) => {
                const user = users && users.find(user => user.id === _userId);
                if (!!user) {
                  this.setState({
                    usersAreLoading: false,
                    userId: user.id,
                  });
                }
              }}
              value={ userId || undefined }
              loading={ usersAreLoading }
              disabled={ _.isEmpty(users) }
            >
              {(users && Array.isArray(users)) && users?.map((user: User) => (
                <Select.Option key={`add-user-${user.id}`} value={user.id}>
                  { `${user.full_name} - ${user.email}` }
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
};

export default RemoveResourceModal;
