// Libs
import * as React from 'react';
import _ from 'lodash';

// Styles
import './EnvironmentBanner.scss';

interface Props {
  environment?: string;
};

export default function EnvironmentBanner({ environment }: Props) {
  return (
    <div className={ `EnvironmentBanner EnvironmentBanner--${_.toLower(environment)}` }>{ _.startCase(_.toLower(environment)) }</div>
  );
};
