// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import BasicList from 'components/basic-list';
import Jumbotron from 'components/jumbotron';
import { RestrictionHoC } from 'components/restriction';

// Services
import { Api } from 'services/api';
import { getFormatedDate } from 'services/settings';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Utils
import history from 'utils/history';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { ActivityTemplateType, FinanceTemplateType, ClassificationTemplateType, DynamicFieldTemplateType } from './Templates.interfaces';

const API: Api = new Api();

export const TEMPLATE_TYPES = [
  {
    route: 'activity',
    label: 'Activity'
  },
  {
    route: 'finance',
    label: 'Finance'
  },
  {
    route: 'classifications',
    label: 'Classifications'
  },
  {
    route: 'dynamic-field',
    label: 'Question Sets'
  },
];

interface Props {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  record: Array<any>,
  isLoading: boolean;
};

class Templates extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    record: [],
    isLoading: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.handleFetch();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.match.params.template_type !== prevProps.match.params.template_type) {
      this.handleFetch();
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  handleFetch = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const template_type = this.props.match.params.template_type;

    const endpoints: { [key: string]: string } = {
      'activity': `client/${client_id}/admin/activities/types`,
      'finance': `client/${client_id}/admin/templates/finance/types`,
      'classifications': `client/${client_id}/admin/activities/classifications/types`,
      'dynamic-field': `client/${client_id}/admin/templates/dynamic-field/types`,
    };

    if(!endpoints[template_type]) return;

    try {

      this.props.setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const record = await API.get(endpoints[template_type]);

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Template Types', path: '/admin/templates' },
      ], false);

      this.mounted && this.setState({
        record: record,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  handleTabChange = (tab: string) => {
    this.mounted && this.setState({ record: [] });
    history.push(_.kebabCase(tab.toLowerCase()));
  };

  renderContent = (type: string) => {
    switch (type.toLowerCase()) {
      case 'activity':
        return this.renderActivityTemplates();
      case 'finance':
        return this.renderFinanceTemplateTypes();
      case 'classifications':
        return this.renderClassificationTypes();
      case 'dynamic-field':
        return this.renderDynamicFieldTypes();
      default:
        return <>Content type not found</>;
    }
  };

  renderActivityTemplates = () => {
    const { record, isLoading } = this.state;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Type',
          render: (title: string, record: ActivityTemplateType) => {
            return (
              <Link className='primaryColor' to={ `/admin/templates/activity/${record.id}` }>
                { title }
              </Link>
            );
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: string) => getFormatedDate(created_at, undefined, true),
          sorter: true,
          ellipsis: true,
          filterable: false,
        }
      ];
    };

    const mapData = (activities: ActivityTemplateType[]) => {
      return activities.map((activity: ActivityTemplateType, index: number) => {
        return {
          'key': index,
          'id': activity.id,
          'title': activity.title,
          'created_at': activity.created_at,
        };
      });
    };

    return (
      <BasicList
        rawData
        columns={ mapColumns() }
        items={ mapData(record) }
      />
    );
  };

  renderFinanceTemplateTypes = () => {
    const { record, isLoading } = this.state;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Type',
          render: (title: string, record: FinanceTemplateType) => {
            return (
              <Link className='primaryColor' to={ `/admin/templates/finance/type/${record.id}` }>
                { title }
              </Link>
            );
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: string) => getFormatedDate(created_at, undefined, true),
          sorter: true,
          ellipsis: true,
          filterable: false,
        }
      ];
    };

    const mapData = (financeTemplateTypes: FinanceTemplateType[]) => {
      return financeTemplateTypes.map((financeTemplateType: FinanceTemplateType, index: number) => {
        return {
          'key': index,
          'id': financeTemplateType.id,
          'title': financeTemplateType.title,
          'created_at': financeTemplateType.created_at,
        };
      });
    };

    return (
      <BasicList
        rawData
        columns={ mapColumns() }
        items={ mapData(record) }
      />
    );
  };

  renderClassificationTypes = () => {
    const { record, isLoading } = this.state;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Type',
          render: (title: string, record: ClassificationTemplateType) => {
            return (
              <Link className='primaryColor' to={ `/admin/templates/classifications/type/${record.id}` }>
                { title }
              </Link>
            );
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: string) => getFormatedDate(created_at, undefined, true),
          sorter: true,
          ellipsis: true,
          filterable: false,
        }
      ];
    };

    const mapData = (classificationTemplateTypes: ClassificationTemplateType[]) => {
      return classificationTemplateTypes.map((classificationTemplateType: ClassificationTemplateType, index: number) => {
        return {
          'key': index,
          'id': classificationTemplateType.id,
          'title': classificationTemplateType.title,
          'created_at': classificationTemplateType.created_at,
        };
      });
    };

    return (
      <BasicList
        rawData
        columns={ mapColumns() }
        items={ mapData(record) }
      />
    );
  };

  renderDynamicFieldTypes = () => {
    const { record, isLoading } = this.state;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Type',
          render: (title: string, record: DynamicFieldTemplateType) => {
            return (
              <Link className='primaryColor' to={ `/admin/templates/dynamic-field/type/${record.id}` }>
                { title }
              </Link>
            );
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: string) => getFormatedDate(created_at, undefined, true),
          sorter: true,
          ellipsis: true,
          filterable: false,
        }
      ];
    };

    const mapData = (dynamicFieldTemplateType: DynamicFieldTemplateType[]) => {
      return dynamicFieldTemplateType.map((dynamicFieldTemplateType: DynamicFieldTemplateType, index: number) => {
        return {
          'key': index,
          'id': dynamicFieldTemplateType.id,
          'title': dynamicFieldTemplateType.title,
          'created_at': dynamicFieldTemplateType.created_at,
        };
      });
    };

    return (
      <BasicList
        rawData
        columns={ mapColumns() }
        items={ mapData(record) }
      />
    );
  };

  render = () => {
    const tabs = TEMPLATE_TYPES.map((type: { route: string, label: string}) => {
      return {
        key: type.route,
        label: type.label,
        node: this.renderContent(type.route)
      };
    });

    return (
      <Jumbotron
        content={ 'Template Types' }
        tabs={ tabs }
        defaultActiveTab={ this.props.match.params.template_type }
        onChange={ (tab: string) => this.handleTabChange(tab) }
      />
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(Templates), 'access_admin_templates'));
