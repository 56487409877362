// Libs
import {
  applyMiddleware,
  combineReducers,
  createStore,
  Store
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import API from 'utils/api';

// Interfacers
import AppState from 'store/AppState.interface';

// Reducers
import ClientReducer from './Client/Reducer';
import UIReducer from './UI/Reducer';
import UserReducer from './User/Reducer';
import NotificationReducer from './Notification/Reducer';

const rootReducer = () => combineReducers<AppState>({
  ClientState: ClientReducer,
  UIState: UIReducer,
  UserState: UserReducer,
  NotificationState: NotificationReducer,
});

function configureStore(): Store<AppState, any> {
  return createStore(
    rootReducer(),
    composeWithDevTools(
      applyMiddleware(thunk)
    )
  );
}

export const api = new API();
const store = configureStore();

export default store;
