// Libs
import React, { Component } from 'react';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Table, Tooltip, Menu, Dropdown as AntDropdown, Modal, Typography, Button } from 'antd';
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import ResourceSetupDialog from './ResourceSetupDialog';

// Interfaces
import { RecordFormEntity } from 'types/entities';

// Icons
import { PlusOutlined, InfoCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';

const { Link } = Typography;

interface State {
  showRoleSelectionModal: boolean;
  showDeleteDialog: boolean;
  showRecordList: boolean;
  activeRowId: number | null;
};

interface Props {
  record: RecordFormEntity;
  field: FormField;
  clientId: number;
  originalState: any;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  onRefreshForm(field_id: string): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

class ResourceSetup extends Component<Props, State> {

  state: State = {
    showRoleSelectionModal: false,
    showDeleteDialog: false,
    showRecordList: false,
    activeRowId: null,
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  validate = (state: any) => {
    const { originalState } = this.props;
    this.generateModifiedState(originalState, state, 'role_region');
    this.generateErrorState(state, 'role_region');
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string) => {
    const { setFieldModifiedMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue)) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (values: any[], columnKey: string) => {
    const { setFieldErrorMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    let errors: any = [];

    if (!!field.config.required && _.isEmpty(values)) {
      errors.push('Cannot be empty');
    }

    if (!_.isEmpty(errors)) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  handleChange = (role: any, regions: any, recordType: any, records: any, targets: any, activeRowId: number | null) => {
    const { field, config, onChange } = this.props;
    const values: any = _.cloneDeep(field.values);
    const newValues: any = {
      role_id: role.id,
      region_ids: regions.map((region: any) => region.id),
      regions: regions,
      role_title: role.title,
      targets: targets,
      target_label: '',
    };

    if (recordType && !_.isEmpty(records)) {
      newValues.target_bundle = recordType.bundle;
      newValues.target_type = recordType.type;
      newValues.target_label = recordType.label;
      newValues.target_ids = records.map((record: any) => record.id);
    }

    if (activeRowId === null) {
      // Add
      values.push(newValues);
    } else {
      // Edit
      values[activeRowId] = newValues;
    }

    onChange(field, values, config);
  };

  renderDeleteDialog = (activeRowId: number) => {
    const { field, config, onChange } = this.props;
    return (
      <Modal
        centered
        visible
        title={ 'Remove Role' }
        okButtonProps={{
          danger: true,
        }}
        onOk={ () => {
          this.setState({
            showDeleteDialog: false
          }, () => {
            onChange(field, field.values.filter((__, index: number) => index !== activeRowId), config);
          });
        } }
        onCancel={() => this.setState({ showDeleteDialog: false, activeRowId: null })}
      >
        <p>Are you sure you want to remove this role?</p>
      </Modal>
    );
  };

  renderRecordList = (activeRowId: number) => {
    const { state } = this.props;
    const value = state[activeRowId];
    const columns = [
      {
        key: 'title',
        dataIndex: 'title',
        title: 'Name',
        render: (title: string) => {
          return (
            <span>{ title }</span>
          );
        },
        sorter: true,
        ellipsis: true,
      }
    ];
    const data = value.targets.map((target: any, index: number) => {
      return {
        key: index,
        title: target.title,
      };
    });
    return (
      <Modal
        centered
        visible
        title={ 'Records' }
        onCancel={ () => this.setState({ activeRowId: null, showRecordList: false }) }
        cancelText={ 'Close' }
        style={{ maxHeight: '80vh', minWidth: 700 }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Table
          className="ov-a"
          style={{ height: 400 }}
          size={ 'small' }
          columns={ columns }
          dataSource={ data }
          pagination={ false }
          expandable={{
            defaultExpandAllRows: true
          }}
        />
      </Modal>
    );
  };

  render = () => {
    const { field, config, clientId, border, state, isDisabled, fieldModifiedMessages, fieldErrorMessages } = this.props;
    const { activeRowId, showRoleSelectionModal, showDeleteDialog, showRecordList } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_role_region`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);

    const data = state && state.map((value: any, index: number) => {
      return {
        key: index,
        role: value.role_title,
        regions: value.regions,
        type: value.target_label,
        records: value.targets,
      };
    });

    const columns: any = [
      {
        dataIndex: 'role',
        key: 'role',
        title: 'Role',
        width: 200,
        render: (title: string) => {
          return (
            <span>{title}</span>
          );
        }
      },
      {
        dataIndex: 'regions',
        key: 'regions',
        title: 'Regions',
        render: (regions: any) => {
          return (
            <>
              { regions.map((region: any, index: number) => (
                <span key={ index }>
                  { regions.length === index + 1 ?
                    (
                      <span>{ region.title }</span>
                      ) : (
                      <span><span>{ region.title }</span><ArrowRightOutlined className="mL-10 mR-10" style={{ fontSize: 10 }} /></span>
                    )
                  }
                </span>
              ) ) }
            </>
          );
        }
      },
      {
        dataIndex: 'type',
        key: 'type',
        title: 'Type',
        render: (type: any) => {
          if (!type) {
            return <span>-</span>;
          }
          return (
            <span>{ type }</span>
          );
        }
      },
      {
        dataIndex: 'records',
        key: 'records',
        title: 'Records',
        render: (records: any, option: any, index: number) => {
          if (records.length < 2) {
            return <span>{ !records.length ? '-' : records[0].title }</span>;
          }
          return (
            <Link onClick={ () => this.setState({ showDeleteDialog: false, showRecordList: true, activeRowId: index }) }>
              <div>
                { records.length }
              </div>
            </Link>
          );
        }
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        render: (__: any, option: any, index: number) => {
          return (
            <>
              <AntDropdown.Button overlay={
                <Menu>
                  <Menu.Item
                    key="edit"
                    onClick={ () => {
                      this.setState({ activeRowId: index, showRoleSelectionModal: true });
                    } }
                  >
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    key="delete"
                    danger
                    onClick={ () => {
                      this.setState({ activeRowId: index, showRecordList: false, showDeleteDialog: true });
                    } }
                  >
                    Remove
                  </Menu.Item>
                </Menu>
              } trigger={['click']}></AntDropdown.Button>
            </>
          );
        },
        width: 100,
        sorter: false,
        ellipsis: true,
        filterable: false,
        align: 'center'
      },
    ];

    return (
      <>
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ 12 }
          errors={ errors }
          label={ field.label }
          hideErrorInfo
          required={ field.config.required }
          border={ border }
          description={ !!field.description && field.description }
          isModified={ _.isEmpty(errors) && isModified }
          refreshOnChange={ !!field.config.refresh_on_change }
          versionChanged={ !!field.config.version_changed }
          rightActions={[
            {
              node: (
                <>
                  { !isDisabled &&
                    <Button
                      className="mL-5 mB-10"
                      style={{
                        marginLeft: 5,
                        padding: '4px 7px',
                        width: '32px',
                      }}
                      onClick={ () => this.setState({ showRoleSelectionModal: true }) }
                    >
                      <PlusOutlined />
                    </Button>
                  }
                  { !_.isEmpty(errors) &&
                    <Tooltip
                      overlayClassName="text-white Field-Error"
                      placement="topRight"
                      title={ errors.join(', ') }
                    >
                      <InfoCircleOutlined className="Field-Error-Icon" />
                    </Tooltip>
                  }
                </>
              )
            }
          ]}
        >
          <div className="w-100p">
            <Table
              size={ 'small' }
              pagination={ false }
              columns={ columns }
              dataSource={ data || [] }
            />
            { showRoleSelectionModal &&
              <ResourceSetupDialog
                clientId={ clientId }
                onClose={ () => this.setState({ showRoleSelectionModal: false, activeRowId: null }) }
                resource={ activeRowId !== null ? state[activeRowId] : null }
                onCreate={ (role: any, regions: any, recordType: any, records: any, targets: any) => {
                  this.handleChange(role, regions, recordType, records, targets, activeRowId);
                  this.setState({
                    showRoleSelectionModal: false,
                    activeRowId: null
                  });
                } }
              />
            }
          </div>
        </FieldWrapper>
        { showDeleteDialog && activeRowId !== null && this.renderDeleteDialog(activeRowId) }
        { showRecordList && activeRowId !== null && this.renderRecordList(activeRowId) }
      </>
    );
  };
};

export default ResourceSetup;
