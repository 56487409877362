// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Badge, Tooltip } from 'antd';

// Interfaces
import { DynamicField } from 'components/form/field/dynamic/Dynamic.interface';

// Icons
import { CommentOutlined, FileOutlined, ExclamationCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

export function getActionControls(
  dynamicField: DynamicField,
  state: any,
  setState: (state: any) => void,
  isPreviewing: boolean,
) {

  const list: any = [];
  const comment = _.has(dynamicField, 'attachments.comment') ? dynamicField.attachments.comment : '';
  const attachments = _.has(dynamicField, 'attachments.files') ? dynamicField.attachments.files.length : 0;
  const actions: number = !_.isEmpty(dynamicField?.action_list?.data) ? dynamicField.action_list?.data.length : 0;
  const score: number | string | null = _.has(dynamicField, 'scoring.value') ? dynamicField.scoring.value : 0;

  if (dynamicField?.config?.allow_scoring) {

    const scoreColor = dynamicField?.scoring?.list?.options?.find((option: any) => option.id === dynamicField?.scoring?.value)?.color || '#b9c2d0';

    list.push({
      node: (
        <Badge
          style={{ backgroundColor: scoreColor }}
          count={ !!score ? 1 : 0 }
          size='small'
          showZero
        >
          <Tooltip
            placement="top"
            title={ 'Assessment' }
          >
            <CheckCircleOutlined
              disabled={ isPreviewing }
              className={ classNames('link mL-10', {
                'active': !isPreviewing && state.showScoring,
                'text-ant-disabled': isPreviewing,
                'cur-na': isPreviewing,
              }) }
              style={{ fontSize: '1.1rem' }}
              onClick={ () => !isPreviewing && setState(Object.assign({}, state, {
                showScoring: !state.showScoring
              })) }
            />
          </Tooltip>
        </Badge>
      ),
    });
  }

  if (dynamicField?.config?.allow_comment) {
    list.push({
      node: (
        <Badge
          style={{ backgroundColor: !!comment ? '#52c41a' : '#b9c2d0' }}
          count={ !!comment ? 1 : 0 }
          size='small'
          showZero
        >
          <Tooltip
            placement="top"
            title={ 'Comment' }
          >
            <CommentOutlined
              disabled={ isPreviewing }
              className={ classNames('link fsz-md mL-10', {
                'active': !isPreviewing && state.showComment,
                'text-ant-disabled': isPreviewing,
                'cur-na': isPreviewing,
              }) }
              onClick={ () => !isPreviewing && setState(Object.assign({}, state, {
                showComment: !state.showComment,
              })) }
            />
          </Tooltip>
        </Badge>
      ),
    });
  }

  if (dynamicField?.config?.allow_attachments) {
    list.push({
      node: (
        <Badge
          style={{ backgroundColor: attachments > 0 ? '#52c41a' : '#b9c2d0' }}
          count={ +attachments }
          size='small'
          showZero
        >
          <Tooltip
            placement="top"
            title={ 'Attachments' }
          >
            <FileOutlined
              disabled={ isPreviewing }
              className={ classNames('link mL-10', {
                'active': !isPreviewing && state.showAttachments,
                'text-ant-disabled': isPreviewing,
                'cur-na': isPreviewing,
              }) }
              style={{ fontSize: '1.1rem' }}
              onClick={ () => !isPreviewing && setState(Object.assign({}, state, {
                showAttachments: !state.showAttachments,
              })) }
            />
          </Tooltip>
        </Badge>
      ),
    });
  }

  if (dynamicField?.config?.allow_actions) {
    list.push({
      node: (
        <Badge
          style={{ backgroundColor: actions > 0 ? '#52c41a' : '#b9c2d0' }}
          count={ actions }
          size='small'
          showZero
        >
          <Tooltip
            placement="top"
            title={ 'Actions' }
          >
            <ExclamationCircleOutlined
              disabled={ isPreviewing }
              className={ classNames('link mL-10', {
                'active': !isPreviewing && state.showActions,
                'text-ant-disabled': isPreviewing,
                'cur-na': isPreviewing,
              }) }
              style={{ fontSize: '1.1rem' }}
              onClick={ () => !isPreviewing && setState(Object.assign({}, state, {
                showActions: !state.showActions,
              })) }
            />
          </Tooltip>
        </Badge>
      ),
    });
  }

  return list;
};