// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import Jumbotron from "components/jumbotron";
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import BasicListView, { List } from "components/basic-list";
import Dropdown from 'components/dropdown';
import RearrangeModal from 'components/rearrange-modal';
import CreateRecordView from 'views/common/CreateRecordView';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Api
import { Api } from 'services/api';

// Services
import Notification from 'services/notification';

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  categoryRecord: List | null;
  isLoading: boolean;
  isUpdating: boolean;
  showRearrangeModal: boolean;
  showCreateModal: boolean;
  isCreateLoading: boolean;
};

const API: Api = new Api();

class CategoryComponent extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    categoryRecord: null,
    isLoading: false,
    isUpdating: false,
    showRearrangeModal: false,
    showCreateModal: false,
    isCreateLoading: false,
  };

  componentDidMount = async () => {
    this.mounted = true;

    try {
      const { client_id, setBreadcrumbs, setBreadcrumbsLoading } = this.props;
      const type = this.props.match.params.type;

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Content Management', path: '/admin/content-manager' },
      ], false);

      setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const category = await API.get(`client/${client_id}/admin/content-manager/categories/${_.kebabCase(type)}`);

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Content Management', path: '/admin/content-manager' },
        { title: 'Categories', path: '/admin/content-manager' },
        { title: _.startCase(_.toLower(type)).split('_').join(' '), path: null },
      ], false);

      this.mounted && this.setState({
        categoryRecord: category,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }

  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderRearrangeModal = (categories: any) => {
    const { client_id } = this.props;
    const { categoryRecord, isUpdating } = this.state;
    const type = this.props.match.params.type;
    const nestable = !!categoryRecord && _.has(categoryRecord, 'config.nestable') && !!categoryRecord.config.nestable;

    return (
      <RearrangeModal
        treeData={ categories }
        isNestable={ nestable }
        isLoading={ isUpdating }
        onOk={ async (treeData: any) => {

          const getFlattenedCategories = (parent_id: number, categories: any) => {
            const _categories = Array.isArray(categories) ? categories : [categories];
            return _categories.reduce((acc, category, index) => {
              acc.push({
                id: category.id,
                order: index + 1,
                parent_id: parent_id,
              });
              if (category.children) {
                acc = acc.concat(getFlattenedCategories(category.id, category.children));
                delete category.children;
              }
              return acc;
            }, []);
          };

          const manipulatedTreeData = getFlattenedCategories(0, treeData);

          try {
            if (!_.isEmpty(manipulatedTreeData)) {

              await new Promise((resolve) => this.setState({ isUpdating: true }, () => resolve(null)));
              await API.put(`client/${client_id}/admin/content-manager/categories/${_.kebabCase(type)}`, { data: manipulatedTreeData });
              const record = await API.get(`client/${client_id}/admin/content-manager/categories/${_.kebabCase(type)}`);

              this.mounted && this.setState({
                categoryRecord: record,
              });

            } else {
              throw new Error();
            }
          } catch (error) {
            Notification('error', '', 'Failed to update record');
          } finally {
            this.mounted && this.setState({
              isUpdating: false,
              showRearrangeModal: false,
            }, () => {
              Notification('success', '', 'Changed Order');
            });
          }
        } }
        onClose={ () => this.setState({ showRearrangeModal: false }) }
      />
    );
  };

  renderView = (columns: any, categories: any) => {
    const { showRearrangeModal, showCreateModal} = this.state;
    const type = this.props.match.params.type;
    return (
      <div className='Layout-box'>
        <BasicListView
          defaultExpandAllRows
          columns={ columns || [] }
          items={ categories || [] }
        />
        { showRearrangeModal && this.renderRearrangeModal(categories) }
        { showCreateModal &&
          <CreateRecordView
            type={ _.kebabCase(type) }
            entity={ 'admin/content-manager/categories' }
            onReady={ () => this.mounted && this.setState({ isCreateLoading: false }) }
            onClose={ () => this.mounted && this.setState({ showCreateModal: false }) }
          />
        }
      </div>
    );
  };

  render = () => {
    const { categoryRecord, isLoading } = this.state;
    const type = this.props.match.params.type;

    return (
      <BlockingSpinner isLoading={ isLoading }>
        <Jumbotron
          content={ <p className="mB-0">{ !!type ? _.startCase(_.toLower(type)).split('_').join(' ') : '' }</p> }
          tabs={ [
            {
              label: 'Overview',
              node: this.renderView(categoryRecord?.columns, categoryRecord?.data),
            }
          ] }
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Create',
                      disabled: _.has(categoryRecord, 'config.can_create') && !!categoryRecord?.config.can_create ? false : [`You don't have permissions to do this`],
                      onClick: () => this.setState({ showCreateModal: true })
                    },
                    {
                      node: 'Change Order',
                      disabled: _.isEmpty(categoryRecord?.data) ? [''] : false,
                      onClick: () => this.setState({
                        showRearrangeModal: true
                      })
                    }
                  ] }
                />
              )
            }
          ] }
        />
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(withRouter(CategoryComponent)), 'access_admin_content_manager'));