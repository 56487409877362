// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';

// Components
import Jumbotron from 'components/jumbotron';
import BlockingSpinner from 'components/blocking-spinner';
import { Select, Table, Typography, Tooltip, Button, TreeSelect } from 'antd';
import { hasPermission } from 'components/restriction';
import SwitchComponent from 'components/switch';
import { regionMap } from 'components/resources-table';
import OverlaySpinner from "components/overlay-spinner";

// Views
import ScopeModal from 'views/common/ScopeModal';

// Services
import { Api } from 'services/api';
import history from 'utils/history';

// Icons
import Icon, { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg';

const API: Api = new Api();
const { Link } = Typography;
const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

interface Props extends RouteComponentProps {
  record: RecordFormEntity;
  contract_id: number;
  client_id: number;
  match: any;
  location: any;
};

interface State {
  services: any[];
  locations: any[];
  availableLocations: any[];
  availableRegions: any[];
  selectedLocations: any[];
  selectedRegions: any[];
  tmpSelectedLocations: any[];
  filters: any;
  isFetchingRegions: boolean;
  isFetchingScope: boolean;
  isFetchingLocations: boolean;
  isUpdatingScope: boolean;
  tableHeight: number;
  showFilter: boolean;
  loadButtonClass: string;
};

class ContractScope extends React.Component<Props, State> {

  mounted: boolean = false;
  component: any = React.createRef(); // This is used to figure out when the component is rendered

  state: State = {
    services: [],
    locations: [],
    availableLocations: [],
    availableRegions: [],
    selectedLocations: [],
    selectedRegions: [],
    tmpSelectedLocations: [],
    filters: [],
    isFetchingRegions: false,
    isFetchingLocations: false,
    isFetchingScope: false,
    isUpdatingScope: false,
    tableHeight: 0,
    showFilter: false,
    loadButtonClass: '',
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.setState({
      isFetchingScope: true,
    }, () => {
      this.getRegions(true);
    });
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    if (
      this.component &&
      !this.state.isFetchingRegions &&
      !this.state.isFetchingLocations &&
      !this.state.isFetchingScope &&
      (!this.state.tableHeight || !_.isEqual(prevState.filters, this.state.filters) || prevState.showFilter !== this.state.showFilter)
    ) {
      this.heightObserver();
    }

    if (!_.isEqual(prevState.tmpSelectedLocations, this.state.tmpSelectedLocations)) {
      this.setState({
        loadButtonClass: 'u-animationPulse'
      }, () => {
        setTimeout(() => {
          this.setState({
            loadButtonClass: ''
          });
        }, 1000);
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  heightObserver = () => {
    const root: number = document.getElementById('root')?.offsetHeight || 0;
    const header: number = document.getElementById('Header')?.offsetHeight || 0;
    const jumbotron: number = document.getElementById('Jumbotron')?.offsetHeight || 0;
    const tabViewBar: number = document.getElementById('TabView-bar')?.offsetHeight || 0;
    const filtersHeight: number = document.getElementById('BasicListFilter')?.offsetHeight || 0;
    const tableHeight: number = root - (header + jumbotron + tabViewBar + filtersHeight + 140);

    if (this.state.tableHeight !== tableHeight) {
      this.setState({
        tableHeight: tableHeight
      });
    }
  };

  getRegions = async (initial = false) => {
    const { client_id, contract_id } = this.props;

    try {

      await new Promise((resolve) => this.setState({ isFetchingRegions: true }, () => resolve(null)));
      const regions = await API.get(`client/${client_id}/record/contract/${contract_id}/scope-matrix/available-regions`);

      this.setState({
        availableRegions: regions,
        selectedRegions: initial && !_.isEmpty(regions) ? [regions[0].id] : []
      }, () => {
        this.getLocations(initial);
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetchingRegions: false,
      });
    }
  };

  getLocations = async (initial = false) => {
    const { client_id, contract_id, location } = this.props;
    const { selectedRegions, selectedLocations, tmpSelectedLocations } = this.state;

    try {

      await new Promise((resolve) => this.setState({ isFetchingLocations: true }, () => resolve(null)));

      const availableLocations = await API.get(`client/${client_id}/record/contract/${contract_id}/scope-matrix/available-columns`, {
        regions: selectedRegions
      });

      let _tmpSelectedLocations = initial && !_.isEmpty(availableLocations) ? availableLocations.slice(0, 5).map((location: any) => location.id) : tmpSelectedLocations;
      let _selectedLocations = initial && !_.isEmpty(availableLocations) ? availableLocations.slice(0, 5).map((location: any) => location.id) : selectedLocations;

      if (initial && !!location?.state?.selectedServiceSpecification) {
        _tmpSelectedLocations = [location?.state?.selectedServiceSpecification];
        _selectedLocations = [location?.state?.selectedServiceSpecification];
      }

      const newState = {
        availableLocations: availableLocations,
        tmpSelectedLocations: _tmpSelectedLocations,
        selectedLocations: _selectedLocations,
      };

      this.setState(newState, () => {
        if (initial) {
          this.getScope(_selectedLocations);
        }
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetchingLocations: false,
      });
    }
  };

  getScope = async (selectedLocations: any[]) => {
    const { client_id, contract_id } = this.props;

    try {

      const scope = await API.get(`client/${client_id}/record/contract/${contract_id}/scope-matrix`, {
        columns: selectedLocations
      });

      this.setState({
        services: scope?.data || [],
        locations: scope?.columns || [],
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetchingScope: false,
        isUpdatingScope: false,
      });
    }
  };

  onChangeScope = async (scope_type: string, scope_id: number, specification_id: number, in_scope: boolean) => {
    const { client_id, contract_id } = this.props;
    const { selectedLocations } = this.state;

    try {
      await new Promise((resolve) => this.setState({ isUpdatingScope: true }, () => resolve(null)));

      const scope = await API.put(`client/${client_id}/record/contract/${contract_id}/scope-matrix/change-scope-item`, {
        columns: selectedLocations,
        scope_type: scope_type,
        scope_id: scope_id,
        specification_id: specification_id,
        in_scope: in_scope,
      });

      this.setState({
        services: scope.data,
        locations: scope.columns,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isUpdatingScope: false
      });
    }
  };

  onChangeCommercialModel = async (coa_type: string, coa_id: number, commercial_model_ids: number[]) => {
    const { client_id, contract_id } = this.props;
    const { selectedLocations } = this.state;

    try {
      await new Promise((resolve) => this.setState({ isUpdatingScope: true }, () => resolve(null)));

      const scope = await API.put(`client/${client_id}/record/contract/${contract_id}/scope-matrix/change-commercial-model`, {
        columns: selectedLocations,
        coa_type: coa_type,
        coa_id: coa_id,
        commercial_model_ids: commercial_model_ids,
      });

      this.setState({
        services: scope.data,
        locations: scope.columns,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isUpdatingScope: false
      });
    }
  };

  filterTable = (data: any[], filters: any) => {
    const collector: any = [];

    const inScopeFilter = !_.isEmpty(filters) && _.has(filters, 'scope') ? filters['scope'] : null; // boolean, null
    const statusFilter = !_.isEmpty(filters) && _.has(filters, 'status') ? filters['status'] : null; // string, null

    data.forEach((value: any) => {

      const check = (_value: any) => {

        let passedScopeFilter = true;
        let passedStatusFilter = true;

        if (inScopeFilter !== null) {
          passedScopeFilter = _value.specifications.some((specification: any) => {
            return _.has(specification, 'scope') && !!specification.scope === inScopeFilter;
          });
        }

        if (statusFilter !== null) {
          passedStatusFilter = _value.specifications.some((specification: any) => {
            return _.has(specification, 'scope.status.title') && _.kebabCase(specification.scope.status.title.toLowerCase()) === statusFilter;
          });
        }

        if (passedScopeFilter && passedStatusFilter) {
          collector.push({
            ..._value,
            'key': `${_value.bundle}-${_value.type}-${_value.id}-${_value.label}`,
            'label': _value.title,
            'children': null,
          });
        }

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  getColumns = (locations: any) => {
    const { record } = this.props;
    const { isUpdatingScope } = this.state;

    const canEdit = hasPermission(record, 'permissions.record_contract_scope_edit');
    const canView = hasPermission(record, 'permissions.record_contract_scope_view');
    const canEditCommercialModel = hasPermission(record, 'permissions.record_contract_commercial_model_edit');

    let columns: any = [
      {
        key: 'label',
        dataIndex: 'label',
        title: 'Service',
        fixed: 'left',
        width: 300,
        ellipsis: true,
      },
      {
        key: 'commercial_model',
        dataIndex: 'commercial_model',
        title: 'Commercial Model',
        fixed: 'left',
        width: 150,
        ellipsis: true,
        render: (__: any, row: any) => {

          if (row.type === 'opex_coa' && !_.isEmpty(row.commercial_models)) {
            return (
              <Select
                mode='multiple'
                disabled={ !canEditCommercialModel || isUpdatingScope }
                style={{ width: 150 }}
                optionFilterProp="children"
                onChange={ (commercial_model_ids: number[]) => this.onChangeCommercialModel(row.type, row.id, commercial_model_ids) }
                placeholder={ 'No Selection' }
                value={ row.commercial_model_ids || undefined }
              >
                { row.commercial_models.map((commercial_model: any) =>
                  <Option key={ commercial_model.id } value={ commercial_model.id }>{ commercial_model.title }</Option>
                ) }
              </Select>
            );
          }

          return <></>;
        }
      },
    ];

    locations.forEach((location: any) => {
      columns.push(
        {
          key: location.id,
          title: location.title,
          dataIndex: location.id,
          width: 200,
          render: (__: any, row: any) => {

            const specification = _.has(row, 'specifications') && row.specifications.find((_location: any) => location.id === _location.id);
            const inScope = specification && !!specification.scope;
            let switchColor = '';
            let stage = 'Out of Scope';

            if (inScope && _.has(specification, 'scope.status.context')) {
              switch (specification.scope.status.context.toUpperCase()) {
                case 'TODO':
                  switchColor = 'badge-todo';
                  stage = specification.scope.status.title;
                break;
                case 'PROGRESS':
                  switchColor = 'badge-progress';
                  stage = specification.scope.status.title;
                break;
                case 'RESOLVED':
                  switchColor = 'badge-resolved';
                  stage = specification.scope.status.title;
                break;
                case 'PROBLEM':
                  switchColor = 'badge-problem';
                  stage = specification.scope.status.title;
                break;
                case 'CLOSED':
                  switchColor = 'badge-closed';
                  stage = specification.scope.status.title;
                break;
              }
            }

            return (
              <div className='d-f'>
                <Tooltip placement={ 'top' } title={ stage }>
                  <SwitchComponent
                    className={ `${switchColor}` }
                    checked={ inScope }
                    onChange={ checked => this.onChangeScope(row.type, row.id, specification.id, checked) }
                    disabled={ !specification || !canEdit || isUpdatingScope }
                  />
                </Tooltip>
                { inScope &&
                  <Link
                    onClick={ () => !isUpdatingScope && history.push(`scope/${specification.scope.id}`) }
                    disabled={ isUpdatingScope }
                  >
                    { canEdit &&  <EditOutlined className="mL-10 va-m fsz-md"/> }
                    { !canEdit && canView && <EyeOutlined className="mL-10 va-m fsz-md"/> }
                  </Link>
                }
              </div>
            );
          }
        }
      );
    });

    return columns;
  };

  getData = (data: any) => {
    const recordDataMapping = (data: any[]) => {
      return data.map((entity: any) => {
        const appendChildrenKeys = (children: any) => {

          if (_.isEmpty(children)) return null;

          return children
            .map((childEntity: any) => {
              return {
                ...childEntity,
                'key': `${childEntity.bundle}-${childEntity.type}-${childEntity.id}`,
                'label': childEntity.title,
                'children': appendChildrenKeys(childEntity.children),
              };
            });
        };

        return {
          ...entity,
          'key': `${entity.bundle}-${entity.type}-${entity.id}`,
          'label': entity.title,
          'children': appendChildrenKeys(entity.children),
        };
      });
    };

    return recordDataMapping(data);
  };

  renderOpexTable = () => {
    const { client_id } = this.props;
    const {
      services,
      locations,
      filters,
      tableHeight,
      selectedLocations,
      tmpSelectedLocations,
      availableLocations,
      showFilter,
      isFetchingRegions,
      isFetchingLocations,
      isFetchingScope,
      isUpdatingScope,
      availableRegions,
      selectedRegions,
      loadButtonClass,
    } = this.state;

    const isLoading = isFetchingRegions || isFetchingLocations || isUpdatingScope || isFetchingScope;
    const columns = this.getColumns(locations);
    let dataSource = this.getData(services);

    if (!_.isEmpty(filters)) {
      dataSource = this.filterTable(dataSource, filters);
    }

    return (
      <>
        <div ref={ node => (this.component = node) }>
          <div id="ContractScopeFilter">
            { !isFetchingRegions &&
              <>
                <div className="mB-15 mT-10 d-f">
                  <span>
                    <TreeSelect
                      loading={ isFetchingRegions }
                      disabled={ isFetchingRegions || isFetchingScope || isUpdatingScope }
                      style={{
                        minWidth: 300,
                      }}
                      placeholder="Regions"
                      maxTagCount={ 3 }
                      showCheckedStrategy={ SHOW_PARENT }
                      treeCheckable
                      treeData={ availableRegions ? regionMap(availableRegions) : [] }
                      value={ selectedRegions }
                      multiple
                      filterTreeNode={ (input: string, option: any) => {
                        if (option) {
                          const filteredInput = input.toLocaleLowerCase();
                          const title = option.title && option.title.toLowerCase();

                          if (title.includes(filteredInput)) {
                            return true;
                          }
                        }

                        return false;
                      } }
                      onChange={(regionIds: any) => {
                        this.setState({
                          selectedRegions: regionIds,
                          tmpSelectedLocations: []
                        }, () => {
                          this.getLocations();
                        });
                      }}
                    />
                  </span>
                  <span className="mL-10 fxg-1">
                    <Select
                      mode="multiple"
                      showSearch
                      disabled={ isFetchingLocations || isFetchingRegions || isFetchingScope || isUpdatingScope }
                      loading={ isFetchingLocations }
                      style={{ width: '100%', minWidth: 400 }}
                      placeholder="Locations"
                      maxTagCount={ 3 }
                      onChange={(_locations: any[]) => {
                        this.setState({
                          tmpSelectedLocations: _locations
                        });
                      }}
                      value={ tmpSelectedLocations }
                      filterOption={(input: any, option: any) => {
                        return availableLocations.find((location: any) => {
                          return location.id === option.value && location.title.toLowerCase().includes(input.toLowerCase());
                        });
                      } }
                    >
                      { availableLocations.map((location: any) =>
                        <Option key={ location.id } value={ location.id }>{ location.title }</Option>
                      ) }
                    </Select>
                  </span>
                  <span className="mL-10">
                    <Button
                      className={ loadButtonClass || '' }
                      disabled={ _.isEmpty(tmpSelectedLocations) || _.isEqual(tmpSelectedLocations, selectedLocations) }
                      onClick={ () => {
                        this.setState({
                          selectedLocations: tmpSelectedLocations,
                          isFetchingScope: true
                        }, () => {
                          this.getScope(selectedLocations);
                        });
                      } }
                      loading={ isFetchingScope }
                    >
                      Load Columns
                    </Button>
                  </span>
                </div>
                <div
                  className="d-f ai-c mB-15"
                  style={{ userSelect: 'none' }}
                >
                  <div className="d-if mL-10">
                    <span>
                      <span>Showing </span>
                      <b>{ selectedLocations.length }</b>
                      <span> { selectedLocations.length === 1 ? 'location' : ' locations' }</span>
                      <span> out of </span>
                      <b>{ availableLocations.length }</b>
                    </span>
                    <span className="mL-20">
                      <div
                        className={ classNames('d-f ai-c link', {
                          'text-ant-disabled disabled': isLoading,
                          'active': showFilter,
                        }) }
                        onClick={ () => {
                          this.setState({
                            showFilter: !showFilter
                          });
                        } }
                      >
                      <Icon
                        component={ FilterIcon }
                      />
                      <span>Filter</span>
                    </div>
                    </span>
                  </div>
                </div>
                { showFilter &&
                  <div className="mB-15">
                    <span>
                      <Select
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Scope"
                        optionFilterProp="children"
                        onChange={ (value: any) => {
                          let newFilters = filters;
                          if (!value) {
                            delete newFilters['scope'];
                          } else {
                            newFilters = Object.assign({}, filters, { 'scope': value === '1' });
                          }
                          this.setState({
                            filters: newFilters
                          });
                        } }
                        value={ _.has(filters, 'scope') ? !!filters['scope'] ? '1' : '0' : undefined }
                      >
                        <Option value="1">In Scope</Option>
                        <Option value="0">Out of Scope</Option>
                      </Select>
                    </span>
                    <span className="mL-10">
                      <Select
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Stage"
                        optionFilterProp="children"
                        onChange={ (value: any) => {
                          let newFilters = filters;
                          if (!value) {
                            delete newFilters['status'];
                          } else {
                            newFilters = Object.assign({}, filters, { 'status': value });
                          }
                          this.setState({
                            filters: newFilters
                          });
                        } }
                        value={ _.has(filters, 'status') ? filters['status'] : undefined }
                      >
                        <Option value="draft">Draft</Option>
                        <Option value="pending-review">Pending Review</Option>
                        <Option value="approved">Approved</Option>
                      </Select>
                    </span>
                  </div>
                }
              </>
            }
          </div>
        </div>
        <BlockingSpinner isLoading={ isFetchingScope } style={{ minHeight: '50vh' }}>
          { isUpdatingScope && <OverlaySpinner /> }
          <div className='Layout-box'>
            <Table
              size={ 'small' }
              sticky
              columns={ columns }
              dataSource={ dataSource }
              pagination={ false }
              scroll={{
                x: columns.length * 300,
                y: tableHeight,
              }}
              expandable={{
                defaultExpandAllRows: true
              }}
            />
            { !!this.props.match?.params.scope_id &&
              <ScopeModal
                scopeId={ this.props.match.params.scope_id }
                clientId={ client_id }
                onClose={ () =>
                  this.setState({
                    isUpdatingScope: true
                  }, () => {
                    // Removes last element
                    history.push(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')));
                    this.getScope(selectedLocations);
                  })
                }
              />
            }
          </div>
        </BlockingSpinner>
      </>
    );
  };

  render = () => {
    const { record } = this.props;
    const tabs = [
      {
        label: 'Opex',
        node: this.renderOpexTable(),
      }
    ];
    return (
      <>
        { record &&
          <Jumbotron
            title={ this.props.record?.title }
            content={ 'Contract Scope' }
            tabs={ tabs }
          />
        }
      </>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
  };
};

export default connect(mapStateToProps)(withRouter(ContractScope));
