// Libs
import * as React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import Timeline from 'components/timeline';
import Badge, { BadgeType } from 'components/badge';
import { Link } from 'react-router-dom';

// Interfaces
import AppState from 'store/AppState.interface';
import { EntityVersion, RecordFormEntity } from 'types/entities';
import { TimelineItem } from 'components/timeline/Timeline.interface';

// Services
import { Api } from 'services/api';
import { getFormatedDate } from 'services/settings';

const API: Api = new Api();

interface Props {
  id?: number;
  type: string;
  entity: string;
  client_id?: number;
  permissions?: any;
  intl: IntlShape;
  record?: RecordFormEntity;
  pure?: boolean;
};

interface State {
  record: RecordFormEntity | null;
  isFetching: boolean;
  versions: EntityVersion[];
};

const messages = defineMessages({
  title: {
    id: 'versions.title',
    defaultMessage: 'Versions',
    description: '',
  },
});

class VersionView extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    isFetching: false,
    record: null,
    versions: [],
  };

  componentDidMount = async () => {
    const { type, client_id, record, entity } = this.props;

    this.mounted = true;

    if (!client_id) return;

    try {
      if (!record) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const versions = await API.getEntityVersions(client_id, type, record.id, entity);

      this.mounted && this.setState({
        versions: versions,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderList = (versions: EntityVersion[]) => {
    const items: TimelineItem[] = versions.map((item: EntityVersion) => {
      const isCurrent = !!item.is_current;
      return {
        colorClass: isCurrent ? 'Timeline--primary' : 'Timeline--default',
        node: (
          <>
            <div className="bdB pB-10">
              <span className="fw-500">{ getFormatedDate(item.updated_at, undefined, true) }</span>
              <span className="mL-10"><Badge type={ isCurrent ? BadgeType.Disabled : BadgeType.Default } text={ `Version ${item.version || 1}` } /></span>
            </div>
            <div className="mT-10">
              <span className="p-2"><Link to={ `${item.path}` }>{item.title}</Link></span>
            </div>
          </>
        ),
      };
    });

    return (
      <Timeline timeline={ items } />
    );
  };

  render = () => {
    const { intl: { formatMessage }, pure } = this.props;
    const { isFetching, versions } = this.state;

    return (
      <BlockingSpinner isLoading={ isFetching }>
        { pure ? (
            <div>
              { this.renderList(versions || []) }
            </div>
          ) : (
            <Jumbotron
              content={ formatMessage(messages.title) }
              title={ this.props.record?.title }
              tabs={[
                {
                  label: '',
                  node: this.renderList(versions || []),
                },
              ]}
            />
          )
        }
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    client_id: store.ClientState.client_id,
  };
};

export default connect(mapStateToProps, null)(injectIntl(VersionView));
