// Libs
import * as React from 'react';
import _ from 'lodash';

// Components
import { Form, Input, Modal, Select } from 'antd';
import BlockingSpinner from 'components/blocking-spinner';

// Services
import { Api } from 'services/api';

// Interfaces
import { FinanceTemplate as IFinanceTemplate, CategoryYear as ICategoryYear  } from 'views/admin/templates/Templates.interfaces';

const API: Api = new Api();

interface Props {
  client_id: number;
  template?: IFinanceTemplate;
  onSave(template: IFinanceTemplate): void;
  onClose(): void;
};

interface State {
  availableYears: string[] | null;
  template: IFinanceTemplate | null;
  isLoading: boolean;
};

class FinanceTemplateModal extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    template: this.props.template || null,
    availableYears: null,
    isLoading: false,
  };

  componentDidMount = async () => {
    const { client_id } = this.props;

    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const availableYears = await API.get(`client/${client_id}/admin/templates/finance/available_template_years`);

      this.mounted && this.setState({
        availableYears: availableYears,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render = () => {
    const { onSave, onClose } = this.props;
    const { isLoading, availableYears, template } = this.state;

    if (!template) return <></>;

    return (
      <Modal
        title={ 'Template' }
        maskClosable={ !isLoading }
        closable={ !isLoading }
        centered
        visible
        onCancel={ () => onClose() }
        onOk={ () => this.setState({
          isLoading: true
        }, () => template && onSave(template)) }
        okText={ 'Save' }
        okButtonProps={{
          disabled: isLoading || !template?.title || _.isEmpty(availableYears),
          loading: isLoading,
        }}
        cancelButtonProps={{
          disabled: isLoading,
        }}
      >
        <BlockingSpinner isLoading={ _.isEmpty(availableYears) }>
          <Form layout="vertical">
            <Form.Item label="Label" required>
              <Input
                value={ template?.title }
                onChange={ (e) => {
                  this.setState({
                    template: Object.assign(template, { title: e.target.value })
                  });
                } }
              />
            </Form.Item>
            <Form.Item label="Year(s)">
              <Select
                mode='multiple'
                placeholder={ 'Please select year(s)' }
                value={ template?.category_years.map((categoryYear: ICategoryYear) => categoryYear.id) }
                onChange={ (years: number[]) => {
                  if (availableYears && !_.isEmpty(availableYears)) {
                    this.setState({
                      template: Object.assign(template, { category_years: years.map((yearId: number) => {
                        return availableYears.find((availableYear: any) => availableYear.id === yearId);
                      }) })
                    });
                  }
                } }
              >
                { availableYears && availableYears.map((year: any) => (
                  <Select.Option key={ year.id } value={ year.id }>
                    { year.title }
                  </Select.Option>
                )) }
              </Select>
            </Form.Item>
          </Form>
        </BlockingSpinner>
      </Modal>
    );
  };
}

export default FinanceTemplateModal;
