// Libs
import React, { memo } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  Controls,
  Background,
  Node as StageFlowType,
  Connection,
  Edge,
} from 'react-flow-renderer';

// Components
import { WorkflowCustomNode, WorkflowCustomEdge } from 'components/workflow';

// Utils
import { convertToFlowElements, getHandleId } from 'utils/workflow';

// Interfaces
import {
  WorkflowStage,
  WorkflowTransition,
  WorkflowTransitionHandle,
  CustomFlowElementType,
} from './Workflow.interface';

// Styles
import './WorkflowMesh.scss';

interface Props {
  stages: WorkflowStage[];
  updateStage?: (elements: StageFlowType) => void;
  openNodeDetails?: (data: WorkflowStage) => void;
  openTransitionDetails?: (data: WorkflowTransition) => void;
  createTransition?: (data: {
    source_stage_id: string;
    target_stage_id: string;
    source_handle: WorkflowTransitionHandle;
    target_handle: WorkflowTransitionHandle;
  }) => void;
};

function RenderFlowMesh({
  stages,
  openNodeDetails,
  updateStage,
  openTransitionDetails,
  createTransition,
}: Props) {
  const onConnect = (params: Connection | Edge) => {
    const { source: source_stage_id, target: target_stage_id, sourceHandle, targetHandle } = params;
    if (source_stage_id && target_stage_id && sourceHandle && targetHandle) {
      createTransition?.({
        source_stage_id,
        target_stage_id,
        source_handle: getHandleId(sourceHandle),
        target_handle: getHandleId(targetHandle),
      });
    }
  };

  const onLoad = (reactFlowInstance: any) => {
    reactFlowInstance.fitView();
  };

  return (
    <div className="WorkflowMesh">
      <ReactFlowProvider>
        <ReactFlow
          elements={ convertToFlowElements(stages, {
            onDoubleClickByNode: openNodeDetails,
            onDoubleClickByEdge: openTransitionDetails,
          }) }
          snapGrid={ [20, 20] }
          defaultZoom={ 1.3 }
          zoomOnScroll={ false }
          onLoad={ onLoad }
          onConnect={ onConnect }
          onNodeDragStop={ (e, node: StageFlowType) => updateStage?.(node) }
          nodeTypes={{ [CustomFlowElementType.Node]: WorkflowCustomNode }}
          edgeTypes={{ [CustomFlowElementType.Edge]: WorkflowCustomEdge }}
        >
          <Controls showInteractive={ false } />
          <Background color="#aaa" gap={ 16 } />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
};

export const WorkflowMesh = memo(RenderFlowMesh);
