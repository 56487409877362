// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Modal, Form, Select } from 'antd';

// Api
import { Api } from 'services/api';

interface Props {
  clientId: number;
  onCreate(bundle: string, recordType: string, type: string): void;
  onClose(): void;
};

interface State {
  bundle: string | null;
  recordType: string | null;
  type: string | null;
  availableBundles: any[];
  availableRecordTypes: any[];
  isCreating: boolean;
  isLoadingBundles: boolean;
  isLoadingRecordTypes: boolean;
  isLoadingRecords: boolean;
};

const API: Api = new Api();
const { Option } = Select;

class CreateCoverageDialog extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    bundle: null,
    recordType: null,
    type: null,
    availableBundles: [],
    availableRecordTypes: [],
    isCreating: false,
    isLoadingBundles: false,
    isLoadingRecordTypes: false,
    isLoadingRecords: false,
  };

  componentDidMount = async () => {
    const { clientId } = this.props;
    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isLoadingBundles: true }, () => resolve(null) ));
      const bundles = await API.get(`client/${clientId}/available_bundles`);

      this.mounted && this.setState({
        availableBundles: bundles,
        bundle: !_.isEmpty(bundles) ? bundles[0]['bundle'] : null
      });

    } catch (error) {
        console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoadingBundles: false
      });
    }

  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { clientId } = this.props;

    // Load record types
    if (prevState.bundle !== this.state.bundle) {
      try {

        await new Promise((resolve) => this.setState({ isLoadingRecordTypes: true }, () => resolve(null) ));
        const recordTypes = await API.get(`client/${clientId}/available_types`, {
          bundle: this.state.bundle
        });

        this.mounted && this.setState({
          availableRecordTypes: recordTypes,
          recordType: !_.isEmpty(recordTypes) ? recordTypes[0]['type'] : null
        });

      } catch (error) {
          console.error('Error: ', error);
      } finally {
        this.mounted && this.setState({
          isLoadingRecordTypes: false
        });
      }
    }
  };

  render = () => {
    const { onCreate, onClose } = this.props;
    const {
      bundle,
      recordType,
      type,
      availableBundles,
      availableRecordTypes,
      isLoadingBundles,
      isLoadingRecordTypes,
      isCreating,
    } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Create Coverage' }
        maskClosable={ !isCreating }
        closable={ !isCreating }
        okButtonProps={{
          disabled: !bundle || !recordType || !type,
          loading: isCreating
        }}
        cancelButtonProps={{
          disabled: isCreating,
        }}
        onOk={ () => this.setState({
            isCreating: true,
          }, () => {
            if (!!bundle && !!recordType && !!type) {
              onCreate(bundle, recordType, type);
            }
          }
        ) }
        onCancel={ () => onClose() }
      >
        <Form layout="vertical">
          <Form.Item label="Bundle" required>
            <Select
              className={ classNames('Select-Field') }
              value={ bundle }
              loading={ isLoadingBundles }
              disabled={ isLoadingBundles }
              onChange={ (bundle: string) => this.setState({
                bundle: bundle,
                isLoadingRecordTypes: true
              }) }
            >
              { availableBundles.map( (bundle: { bundle: string }) => (
                <Option key={ bundle.bundle } value={ bundle.bundle }>{ _.startCase(_.toLower(bundle.bundle)).split('_').join(' ') }</Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item label="Record Type" required>
            <Select
              className={ classNames('Select-Field') }
              disabled={ !bundle || isLoadingRecordTypes }
              loading={ isLoadingRecordTypes }
              value={ recordType }
              onChange={ (type: string) => this.setState({
                recordType: type,
                type: null,
                isLoadingRecords: true
              }) }
            >
              { availableRecordTypes.map( (record_type: any) => (
                <Option key={ record_type.type } value={ record_type.type }>{ record_type.label }</Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item label="Type" required>
            <Select
              className={ classNames('Select-Field') }
              disabled={ !bundle || !recordType }
              value={ type }
              onChange={ (type: string) => this.setState({
                type: type
              }) }
            >
              <Option key={ 'inherit' } value={ 'INHERIT' }>{ 'Inherit' }</Option>
              <Option key={ 'direct' } value={ 'DIRECT' }>{ 'Direct' }</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
};

export default CreateCoverageDialog;
