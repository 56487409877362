// Libs
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Select } from 'antd';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
  FormValues,
} from 'components/form/form-wrapper';

const FIELD_TYPE = 'activity_template';
const COLUMN_KEY = 'activity_template_id';

interface Template {
  id: number;
  type_id: number;
  title: string;
  created_at: string;
  updated_at: string;
  activities: any[];
  fixed: boolean;
};

interface Props {
  config: FormFieldConfig;
  field: FormField;
  originalState: FormValues[];
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    values: Record<string, string | number>[] | [],
    config: FormFieldConfig,
    column?: string,
  ): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  isDisabled?: boolean;
  border?: boolean;
};

interface State {};

class ActivityTemplateSelect extends React.Component<Props, State> {
  state: State = {};

  componentDidMount = () => {
    const { field } = this.props;
    this.validate(field.values);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(field.values);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (values: any, shouldClear = false) => {
    const { field, config, originalState, setFieldModifiedMessage, setFieldErrorMessage } = this.props;
    const key = `${field.id}_${config.fieldIndex || 0}_${FIELD_TYPE}`;
    const isRequired = !!field.columns[COLUMN_KEY]?.required;

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const isModified: boolean = !shouldClear && !_.isEqual(values, originalState);
    const hasError: boolean = !shouldClear && isRequired && _.isEmpty(values);

    const errorMessage = hasError ? { ...generalMessageInfo, errors: [`Can't be empty`] } : undefined;
    const modifiedMessage = isModified ? { ...generalMessageInfo, modified: {} } : undefined;

    setFieldErrorMessage(key, errorMessage);
    setFieldModifiedMessage(key, modifiedMessage);
  };

  render = () => {
    const { field, config, fieldModifiedMessages, fieldErrorMessages, onChange, isDisabled, border } = this.props;

    const key = `${field.id}_${config.fieldIndex || 0}_${FIELD_TYPE}`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const values = field && field.values ? field.values.map(value => value.activity_template_id) : [];
    const canSelectMultiple = field.config.cardinality !== 1;

    const sortedTemplates = _.sortBy(field.templates, 'title'); // Sort templates alphabetically

    if (!!isDisabled) {
      if (!_.isEmpty(field.templates)) {
        const label = field.values.map((selectedTemplateId: any) => {
          const option = sortedTemplates.find((template: Template) => template.id === Number(selectedTemplateId.activity_template_id));
          return option ? option.title : '';
        });

        return (
          <FieldWrapper
            id={ `${config.tabID}|${config.groupID}|${field.id}` }
            col={ config.fieldColSpan }
            label={ field.label }
            required={ field.config.required }
            border={ border }
            description={ !!field.description && field.description }
            refreshOnChange={ !!field.config.refresh_on_change }
            versionChanged={ !!field.config.version_changed }
          >
            <span className="d-b pY-5">{ !_.isEmpty(label) ? label : '-' }</span>
          </FieldWrapper>
        );
      }

      return <>-</>;
    }

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        errors={ errors }
        col={ config.fieldColSpan }
        label={ field.label }
        description={ !!field.description && field.description }
        required={ field.config.required }
        border={ border }
      >
        <Select
          showSearch
          allowClear={ !field.config.required }
          mode={ canSelectMultiple ? 'multiple' : undefined }
          disabled={ isDisabled }
          placeholder={ field.label }
          className={ classNames('Select-Field', {
            'Select-Field--has-warning border-warning': isModified && _.isEmpty(errors),
          }) }
          value={ values }
          onClear={ () => onChange(field, [], config) }
          onChange={ (changedValues: any) => { // Updated to handle multi-select
            const newValues: Array<{ id: number }> = changedValues.map((id: number) => {
              return {
                activity_template_id: id
              };
            });

            onChange(field, newValues, config);
          } }
          filterOption={ (input: any, option: any) => {
            return !!sortedTemplates?.find((template: Template) => template.title.toLowerCase() === option.children.toLowerCase() && template.title.toLowerCase().includes(input.toLowerCase()) );
          } }
        >
          { sortedTemplates?.map((template: Template) => (
            <Select.Option value={ template.id } key={ template.id }>
              { template.title }
            </Select.Option>
          )) }
        </Select>
      </FieldWrapper>
    );
  };
}

export default ActivityTemplateSelect;
