// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import Jumbotron from 'components/jumbotron';
import Dropdown from 'components/dropdown';
import BlockingSpinner from 'components/blocking-spinner/BlockingSpinner';
import DashboardComponent from 'components/dashboard';
import { Button } from 'antd';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { IDashboard } from 'components/dashboard/Dashboard.interface';
import { IWidget } from 'components/widget/Widget.interface';

// Icons
import Icon from '@ant-design/icons';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Actions
import { setBreadcrumbs, setBreadcrumbsLoading } from 'store/UI/ActionCreators';

const API: Api = new Api();

interface Props {
  client_id: number;
  isPrimarySidebarCollapsed: boolean;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
};

export interface State {
  originalDashboard: IDashboard | null;
  dashboard: IDashboard | null;
  loadingWidgetId: number | null;
  availableReports: any[];
  showDialog: boolean;
  isFetching: boolean;
  isSaving: boolean;
  isModified: boolean;
};

class Dashboard extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    originalDashboard: null,
    dashboard: null,
    loadingWidgetId: null,
    availableReports: [],
    showDialog: false,
    isFetching: false,
    isSaving: false,
    isModified: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.fetchAvailableReports(this.props.client_id);
    this.fetchDashboard(this.props.client_id, this.props.match.params.dashboard_id);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!_.isEqual(prevState.dashboard, this.state.dashboard)) {
      this.setState({
        isModified: !_.isEqual(this.state.originalDashboard, this.state.dashboard)
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  fetchDashboard = async (clientId: number, dashboardId: number, silent: boolean = false) => {
    try {

      if (!silent) {
        await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));
        this.props.setBreadcrumbsLoading(true);
      }

      const dashboard = await API.get(`client/${clientId}/insight/dashboard/${dashboardId}`);

      this.props.setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Dashboards', path: '/dashboards' },
        { title: dashboard.title, path: null }
      ], false);

      const newDashboard = _.set(_.cloneDeep(dashboard), 'widgets', dashboard.widgets.map((widget: IWidget) => {
        return {
          ...widget,
          key: widget?.key || widget.id
        };
      }));

      this.mounted && this.setState({
        originalDashboard: _.cloneDeep(newDashboard),
        dashboard: _.cloneDeep(newDashboard),
        isModified: false,
      });

    } catch (error) {
      Notification('error', 'Failed to fetch dashboard', 'Failed');
    } finally {
      if (!silent) {
        this.mounted && this.setState({
          isFetching: false
        }, () => {
          this.props.setBreadcrumbsLoading(false);
        });
      }
    }
  };

  fetchAvailableReports = async (clientId: number) => {
    try {

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));
      const availableReports = await API.get(`client/${clientId}/insight/dashboard/widget/report`);

      this.mounted && this.setState({
        availableReports: availableReports
      });
    } catch (error) {
      Notification('error', 'Failed to fetch available reports', 'Failed');
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  saveDashboard = async (clientId: number, dashboard: IDashboard) => {
    try {

      await new Promise((resolve) => this.setState({ isSaving: true }, () => resolve(null)));
      await API.put(`client/${clientId}/insight/dashboard/${dashboard.id}`, {
        data: dashboard
      });
      this.fetchDashboard(clientId, dashboard.id, true);

      Notification('success', `Dashboard saved`);
    } catch (error) {
      Notification('error', 'Failed to save dashboard', 'Failed');
    } finally {
      this.mounted && this.setState({
        isSaving: false
      });
    }
  };

  renderDashboard = (dashboard: IDashboard) => {
    return (
      <DashboardComponent
        clientId={ this.props.client_id }
        dashboard={ _.cloneDeep(dashboard) }
        isPrimarySidebarCollapsed={ this.props.isPrimarySidebarCollapsed }
        state={ this.state }
        onLayoutChange={ (_dashboard: IDashboard) => {
          if (!_.isEqual(dashboard, _dashboard)) {
            this.setState({
              dashboard: _dashboard
            });
          }
        } }
        onChangeState={ (state: State) => {
          this.setState(state);
        } }
      />
    );
  };

  render = () => {
    const { client_id } = this.props;
    const { isModified, dashboard, isFetching, isSaving } = this.state;

    if (isFetching) {
      return (
        <div className="d-f jc-c ai-c mH-450">
          <BlockingSpinner isLoading />
        </div>
      );
    };

    if (!dashboard) {
      return (
        <div>No dashboard found</div>
      );
    }

    return (
      <Jumbotron
        title={ dashboard.title }
        content={ <div className="mT-20"></div> }
        rightActions={ [
          {
            node: (
              <div className="d-f">
                { isModified &&
                  <Button
                    type={ 'primary' }
                    className="ExternalControls__button ExternalControls__button--info ExternalControls__button--with-spacing ExternalControls__button--warning"
                  >
                    <Icon component={ InfoIcon } />
                  </Button>
                }
                <Dropdown
                  actions={ [
                    {
                      node: 'Save',
                      isLoading: isSaving,
                      disabled: !isModified,
                      onClick: () => this.saveDashboard(client_id, dashboard)
                    },
                    {
                      node: 'Add Widget',
                      onClick: () => this.setState({ showDialog: true })
                    },
                  ] }
                />
              </div>
            )
          }
        ] }
        tabs={ [
          {
            label: '',
            node: this.renderDashboard(dashboard),
          }
        ] }
      />
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    isPrimarySidebarCollapsed: store.UIState.isPrimarySidebarCollapsed,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);