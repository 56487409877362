// Libs
import React, { useState } from 'react';
import { Tree } from 'antd';
import _ from 'lodash';

// Components
import CoverModal from 'components/cover-modal';

// Utils
import { flattenSet } from 'utils/utils';

// Styles
import './RearrangeModal.scss';

const onDrop = (info: any, treeData: any, nestable: boolean) => {
  const dropKey = info.node.key;
  const dragKey = info.dragNode.key;
  const dropPos = info.node.pos.split('-');
  const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
  const data = [...treeData];
  let dragObj: any;

  const loop = (data: any, key: any, callback: any) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        loop(data[i].children, key, callback);
      }
    }
  };

  // Find dragObject
  loop(data, dragKey, (item: any, index: any, arr: any) => {
    arr.splice(index, 1);
    dragObj = item;
  });

  if (!info.dropToGap && nestable) {
    // Drop on the content
    loop(data, dropKey, (item: any) => {
      item.children = item.children || [];
      return item.children.unshift(dragObj);
    });
  } else if (
    (info.node.props.children || []).length > 0 && // Has children
    info.node.props.expanded && // Is expanded
    dropPosition === 1 // On the bottom gap
  ) {
    loop(data, dropKey, (item: any) => {
      item.children = item.children || [];
      item.children.unshift(dragObj);
    });
  } else {
    let ar: any;
    let i: any;
    loop(data, dropKey, (_: any, index: any, arr: any) => {
      ar = arr;
      i = index;
    });
    if (dropPosition === -1) {
      ar.splice(i, 0, dragObj);
    } else {
      ar.splice(i + 1, 0, dragObj);
    }
  }

  return data;
};

interface Props {
  treeData: any[];
  isNestable: boolean;
  isLoading?: boolean;
  onOk: (treeData: any[]) => void;
  onClose: () => void;
};

export default function RearrangeModal(props: Props) {
  const [tree, setTree] = useState(props.treeData);

  const replaceTitle = (category: any) => {
    return {
      key: category?.key || category.id,
      title: category?.title || category?.label,
      ...category,
      children: !_.isEmpty(category.children) ? category.children.map((child: any) => replaceTitle(child)) : null,
    };
  };

  // Since title is used in the table with the whole nested objects, we'll need to override this
  const manipulatedTreeData = tree.map((category: any) => replaceTitle(category));
  const flattenTree = flattenSet(manipulatedTreeData);

  return (
    <CoverModal
      style={{ width: '60vw', height: '90vh' }}
      middleContent={ 'Change Order' }
      isLoading={ props.isLoading }
      onClose={ () => props.onClose() }
      actions={ [
        {
          label: 'Change',
          onClick: () => props.onOk(tree),
          isDisabled: _.isEqual(props.treeData, tree),
        }
      ] }
    >
      <div className="bg-white">
        <div style={{ padding: '20px 20px 10px 20px' }}>
          <Tree
            className="RearrangeModal draggable-tree"
            draggable
            blockNode
            selectable={ false }
            defaultExpandedKeys={ flattenTree.map((branch: any) => branch?.key) }
            showLine={{ showLeafIcon: false }}
            onDrop={ (info: any) => setTree(onDrop(info, manipulatedTreeData, props.isNestable)) }
            treeData={ manipulatedTreeData }
          />
        </div>
      </div>
    </CoverModal>
  );
};
