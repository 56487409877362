// Libs
import * as React from 'react';

// Components
import ResourceView from 'views/common/ResourceView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  getRecord(silent?: boolean): void;
};

class SupplierResources extends React.Component<Props> {
  render = () => {
    return <ResourceView record={ this.props.record } type={ 'supplier' } entity={ 'company' } getRecord={ this.props.getRecord } />;
  };
};

export default SupplierResources;
