// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import CustomerContainer from 'containers/customer/CustomerContainer';
import CompanyContainer from 'containers/customer/CompanyContainer';

// Views
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

class CustomersContainer extends React.Component {

  render = () => {
    return (
      <Switch>

        <Redirect exact from="/customers" to="/customers/customers" />

        <Route exact path="/customers/customers" component={ CustomerContainer } />
        <Route path="/customers/customers/:record_id" component={ CustomerContainer } />

        <Route exact path="/customers/companies" component={ CompanyContainer } />
        <Route path="/customers/companies/:record_id" component={ CompanyContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

export default CustomersContainer;
