// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Button, Modal, Popconfirm, Select, Tooltip } from 'antd';
import BasicListView from 'components/basic-list';

// Icons
import { PlusOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Utils
import { nestedSet } from 'utils/utils';

// Interfaces
import { Entity as IEntity } from 'views/admin/entity/Entity.interfaces';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

const API: Api = new Api();

interface IDashboard {
  dashboard_id: number;
  entity_bundle: string;
  entity_type: string;
  dashboard: {
    id: number;
    title: string;
    user_id: number;
  }
};

interface Props {
  clientId: number;
  entity: IEntity;
};

interface State {
  dashboards: IDashboard[];
  isLoading: boolean;
  showAddDialog: boolean;
  showEditDialog: boolean;
  isFetchingAvailableDashboards: boolean;
  isLinkingDashboard: boolean;
  currentDeleteId: number | null;
  selectedDashboardId: number | null;
  availableDashboards: {
    id: number;
    title: string;
    user_id: number;
  }[];
};

class DashboardMappingTab extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    dashboards: [],
    isLoading: false,
    showAddDialog: false,
    showEditDialog: false,
    isFetchingAvailableDashboards: false,
    isLinkingDashboard: false,
    currentDeleteId: null,
    selectedDashboardId: null,
    availableDashboards: [],
  };

  componentDidMount = () => {
    this.mounted = true;
    this.fetchDashboards(this.props.clientId, this.props.entity);
    this.fetchAvailableDashboards(this.props.clientId, this.props.entity);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevState.showAddDialog !== this.state.showAddDialog && !!this.state.showAddDialog) {
      this.fetchAvailableDashboards(this.props.clientId, this.props.entity);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchDashboards = async (clientId: number, entity: IEntity) => {
    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));
      const dashboards = await API.get(`client/${clientId}/admin/entity/${entity.bundle}/${entity.type}/dashboard`);

      this.mounted && this.setState({
        dashboards: dashboards
      });
    } catch (error) {
      Notification('error', 'Failed to fetch dashboards', 'Failed');
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  fetchAvailableDashboards = async (clientId: number, entity: IEntity) => {
    try {
      await new Promise((resolve) => this.setState({ isFetchingAvailableDashboards: true }, () => resolve(null) ));
      const availableDashboards = await API.get(`client/${clientId}/admin/entity/${entity.bundle}/${entity.type}/dashboard/available`);

      this.mounted && this.setState({
        availableDashboards: availableDashboards
      });
    } catch (error) {
      Notification('error', 'Failed to fetch dashboards', 'Failed');
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetchingAvailableDashboards: false
      });
    }
  };

  renderAddDialog = () => {
    return (
      <Modal
        title={ 'Link Dashboard' }
        closable={ false }
        maskClosable={ false }
        centered
        visible
        onCancel={ () => this.setState({
          selectedDashboardId: null,
          showAddDialog: false,
        }) }
        onOk={ async () => {
          try {
            await new Promise((resolve) => this.setState({ isLinkingDashboard: true }, () => resolve(null) ));
            await API.post(`client/${this.props.clientId}/admin/entity/${this.props.entity.bundle}/${this.props.entity.type}/dashboard`, {
              dashboard_id: this.state.selectedDashboardId
            });
            this.fetchDashboards(this.props.clientId, this.props.entity);
            this.fetchAvailableDashboards(this.props.clientId, this.props.entity);

          } catch (error) {
            Notification('error', 'Failed to fetch dashboards', 'Failed');
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              selectedDashboardId: null,
              isLinkingDashboard: false,
              showAddDialog: false,
            });
          }
        } }
        okText= { 'Link' }
        okButtonProps={{
          disabled: this.state.isFetchingAvailableDashboards || !this.state.selectedDashboardId,
          loading: this.state.isLinkingDashboard,
        }}
        cancelButtonProps={{
          disabled: this.state.isFetchingAvailableDashboards,
        }}
      >
        <Select
          showSearch
          loading={ this.state.isFetchingAvailableDashboards }
          style={{ width: '100%' }}
          placeholder={ 'Please Select' }
          filterOption={ (input: string, option: any) => {
            return !!this.state.availableDashboards.find((dashboard: { id: number; title: string; user_id: number; }) => dashboard.title.toLowerCase() === option.children.toLowerCase() && dashboard.title.toLowerCase().includes(input.toLowerCase()));
          } }
          onChange={(dashboardId: number) => {
            this.setState({
              selectedDashboardId: dashboardId
            });
          } }
        >
          { this.state.availableDashboards.map((dashboard: { id: number; title: string; user_id: number; }) => (
            <Select.Option
              key={ dashboard.id }
              value={ dashboard.id }
            >
              { dashboard.title }
            </Select.Option>
          ) ) }
        </Select>
      </Modal>
    );
  };

  render = () => {
    return (
      <>
        <BasicListView
          rawData
          columns={ [
            {
              title: 'Title',
              sorter: true,
              ellipsis: true,
              render: (__: any, dashboard: IDashboard) => (
                <span>{ dashboard?.dashboard?.title }</span>
              )
            },
            {
              key: 'actions',
              dataIndex: 'actions',
              title: _.isEmpty(this.state.availableDashboards) ? (
                (
                  <Tooltip
                    title={ 'No available dashboards' }
                  >
                    <Button
                      disabled={ _.isEmpty(this.state.availableDashboards) }
                      style={{
                        marginLeft: 5,
                        padding: '4px 7px',
                        width: '32px',
                      }}
                    >
                      <PlusOutlined />
                    </Button>
                  </Tooltip>
                )
              ) : (
                <Button
                  style={{
                    marginLeft: 5,
                    padding: '4px 7px',
                    width: '32px',
                  }}
                  onClick={ () => this.setState({ showAddDialog: true }) }
                >
                  <PlusOutlined />
                </Button>
              ),
              render: (__: any, dashboard: IDashboard) => {
                return (
                  <Popconfirm
                    title={ 'Are you sure?' }
                    icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                    okButtonProps={{
                      danger: true
                    }}
                    placement="topRight"
                    onConfirm={ async () => {
                      try {
                        await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));
                        await API.delete(`client/${this.props.clientId}/admin/entity/${this.props.entity.bundle}/${this.props.entity.type}/dashboard`, {
                          dashboard_id: dashboard.dashboard_id
                        });
                        await this.fetchDashboards(this.props.clientId, this.props.entity);
                        this.fetchAvailableDashboards(this.props.clientId, this.props.entity);

                      } catch (error) {
                        Notification('error', 'Failed to fetch dashboards', 'Failed');
                        console.error('Error: ', error);
                      } finally {
                        this.mounted && this.setState({
                          isLoading: false
                        });
                      }
                    } }
                  >
                    <DeleteOutlined
                      className="link"
                      style={{ fontSize: 18 }}
                    />
                  </Popconfirm>
                );
              },
              width: 80,
              sorter: false,
              ellipsis: true,
              filterable: false,
              align: 'center',
            },
          ] }
          items={ nestedSet(this.state.dashboards) }
          isLoading={ this.state.isLoading }
        />
        { this.state.showAddDialog && this.renderAddDialog() }
      </>
    );
  };
};

export default DashboardMappingTab;