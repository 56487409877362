// Libs
import * as React from 'react';

// Components
import ResourceView from 'views/common/ResourceView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  getRecord(silent?: boolean): void;
};

class ContractResources extends React.Component<Props> {
  render = () => {
    return <ResourceView type={ 'contract' } entity={ 'record' } record={ this.props.record } getRecord={ this.props.getRecord } />;
  };
};

export default ContractResources;
