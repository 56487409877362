// Libs
import React, { BaseSyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';

// Components
import Jumbotron from 'components/jumbotron';
import BasicList from 'components/basic-list';
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import { EditOutlined } from '@ant-design/icons';
import { Form, Input, Modal } from 'antd';
import Dropdown from 'components/dropdown';

// Api
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { Workflow as WorkflowRecord } from 'components/workflow/Workflow.interface';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';

// Styles
import 'assets/styles/_layout.scss';

const { TextArea } = Input;

interface PlaceholderWorkflow {
  id?: number;
  key?: number;
  title?: string;
  description?: string;
};

interface Props {
  client_id: number;
  intl: IntlShape;
  user: UserEntity;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  workflows: WorkflowRecord[];
  createWorkflowPlaceholder: PlaceholderWorkflow;
  editWorkflowPlaceholder: PlaceholderWorkflow;
  showCreateDialog: boolean;
  showEditDialog: boolean;
  isFetchingWorkflow: boolean;
  isCreatingWorkflow: boolean;
  isLoading: boolean;
};

const API: Api = new Api();

class Workflows extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    workflows: [],
    createWorkflowPlaceholder: {},
    editWorkflowPlaceholder: {},
    showCreateDialog: false,
    showEditDialog: false,
    isCreatingWorkflow: false,
    isFetchingWorkflow: false,
    isLoading: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Workflows', path: '/admin/workflows' },
    ], false);

    try {

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const workflows = await API.get(`client/${client_id}/admin/workflows`);

      this.mounted && this.setState({
        workflows: workflows
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }

  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderCreateDialog = () => {
    const { user: { active_client } } = this.props;
    const { createWorkflowPlaceholder, isCreatingWorkflow } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Create Workflow' }
        maskClosable={ !isCreatingWorkflow }
        okText={ 'Create' }
        onOk={() => this.mounted && this.setState({
            isCreatingWorkflow: true,
          }, async () => {
            try {
              const workflows = await API.post(`client/${active_client}/admin/workflows`, {
                data: createWorkflowPlaceholder
              });

              this.mounted && this.setState({
                workflows: workflows,
                showCreateDialog: false,
                createWorkflowPlaceholder: {}
              }, () => {
                Notification('success', '', 'Workflow created');
              });
            } catch (error) {
              Notification('error', '', error.data);
            } finally {
              this.mounted && this.setState({
                isCreatingWorkflow: false
              });
            }
          }
        ) }
        onCancel={() => this.setState({
          showCreateDialog: false,
          createWorkflowPlaceholder: {}
        }) }
        cancelButtonProps={{
          disabled: isCreatingWorkflow
        }}
        okButtonProps={{
          disabled: !createWorkflowPlaceholder?.title || !createWorkflowPlaceholder.description,
          loading: isCreatingWorkflow,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Title" required>
            <Input
              value={ createWorkflowPlaceholder?.title }
              onChange={ (event: BaseSyntheticEvent) => {
                this.setState({
                  createWorkflowPlaceholder: Object.assign(createWorkflowPlaceholder, {
                    title: event.target.value,
                  }),
                });
              } }
            />
          </Form.Item>
          <Form.Item label="Description" required>
            <TextArea
              rows={ 4 }
              value={ createWorkflowPlaceholder?.description }
              onChange={ (event: BaseSyntheticEvent) => {
                this.setState({
                  createWorkflowPlaceholder: Object.assign(createWorkflowPlaceholder, {
                    description: event.target.value,
                  }),
                });
              } }
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderEditDialog = () => {
    const { user: { active_client } } = this.props;
    const { isFetchingWorkflow, editWorkflowPlaceholder } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Edit Workflow' }
        maskClosable={ !isFetchingWorkflow }
        okText={ 'Edit' }
        onOk={ () =>
          this.mounted &&
          this.setState(
            {
              isFetchingWorkflow: true,
            },
            async () => {
              try {
                const workflows = await API.put(`client/${active_client}/admin/workflows/${editWorkflowPlaceholder?.id}`,
                  {
                    data: editWorkflowPlaceholder
                  },
                );
                this.mounted && this.setState({
                  workflows: workflows,
                }, () => {
                  Notification('success', '', 'Workflow edited');
                });
              } catch (error) {
                Notification('error', '', 'Failed to edit workflow');
              } finally {
                this.mounted && this.setState({
                  isFetchingWorkflow: false,
                  editWorkflowPlaceholder: {},
                  showEditDialog: false,
                });
              }
            },
          )
        }
        onCancel={ () =>
          this.setState({
            editWorkflowPlaceholder: {},
            showEditDialog: false,
          })
        }
        cancelButtonProps={{
          disabled: isFetchingWorkflow,
        }}
        okButtonProps={{
          disabled: !editWorkflowPlaceholder?.title || !editWorkflowPlaceholder.description,
          loading: isFetchingWorkflow,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Title" required>
            <Input
              value={ editWorkflowPlaceholder?.title }
              onChange={ (event: BaseSyntheticEvent) => {
                this.setState({
                  editWorkflowPlaceholder: Object.assign(editWorkflowPlaceholder, {
                    title: event.target.value,
                  }),
                });
              } }
            />
          </Form.Item>
          <Form.Item label="Description" required>
            <TextArea
              rows={ 4 }
              value={ editWorkflowPlaceholder?.description }
              onChange={ (event: BaseSyntheticEvent) => {
                this.setState({
                  editWorkflowPlaceholder: Object.assign(editWorkflowPlaceholder, {
                    description: event.target.value,
                  }),
                });
              } }
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderWorkflows = () => {
    const { workflows } = this.state;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Title',
          render: (title: any, workflow: PlaceholderWorkflow) => {
            return (
              <Link to={ `/admin/workflows/${workflow.id}` } className="primaryColor">
                { title }
              </Link>
            );
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'action',
          dataIndex: 'action',
          render: (_: any, workflow: PlaceholderWorkflow) => (
            <EditOutlined
              className="link"
              style={{ fontSize: 18 }}
              onClick={ () =>
                this.setState({
                  showEditDialog: true,
                  editWorkflowPlaceholder: workflow,
                })
              }
            />
          ),
          width: 80,
          align: 'center',
        },
      ];
    };

    const mapData = (workflows: WorkflowRecord[]) => {
      return workflows.map((workflow: WorkflowRecord) => {
        return {
          key: workflow.id,
          id: workflow.id,
          title: workflow.title,
          description: workflow.description,
        };
      });
    };

    return (
      <BasicList
        rawData
        columns={ mapColumns() }
        items={ mapData(workflows) }
      />
    );
  };

  render = () => {
    const { isLoading, showCreateDialog, showEditDialog } = this.state;
    return (
      <BlockingSpinner isLoading={ isLoading }>
        <Jumbotron
          content={ 'Workflows' }
          tabs={ [
            {
              label: '',
              node: this.renderWorkflows(),
            },
          ] }
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Create Workflow',
                      onClick: () => this.setState({ showCreateDialog: true })
                    }
                  ] }
                />
              )
            }
          ] }
        />
        { showCreateDialog &&  this.renderCreateDialog() }
        { showEditDialog && this.renderEditDialog() }
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    user: store.UserState.user,
    permissions: store.UserState.user.permissions,
    client_id: store.ClientState.client_id,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(Workflows), 'access_admin_workflows'));