// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';

// Components
import Jumbotron from "components/jumbotron";
import BlockingSpinner from 'components/blocking-spinner';
import BasicList from 'components/basic-list';
import { Modal, Form, Select, Popconfirm, Button } from 'antd';
import { RestrictionHoC } from 'components/restriction';

// Icons
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Views
import CoverageTab from 'views/admin/groups/CoverageTab';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Styles
import 'assets/styles/_layout.scss';

interface Props {
  user: UserEntity;
  permissions: UserPermissions;
  history: Record<string, any>;
  intl: IntlShape;
  match: { params: Record<string, any> };
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  record: any;
  availableUsers: any[] | null;
  deleteConfirmId: number | null;
  showAddDialog: boolean;
  isFetchingUsers: boolean;
  isLoading: boolean;
};

const API: Api = new Api();
const { Option } = Select;

class Group extends React.Component<Props, State> {

  mounted: boolean = false;
  createFormRef: any = React.createRef();

  state: State = {
    record: null,
    availableUsers: [],
    deleteConfirmId: null,
    showAddDialog: false,
    isFetchingUsers: false,
    isLoading: false,
  };

  componentDidMount = async () => {
    const { user: { active_client }, setBreadcrumbs } = this.props;
    const group_id = this.props.match.params.id;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Groups', path: '/admin/groups' },
    ], false);

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));

      const group = await API.get(`client/${active_client}/admin/groups/${group_id}`);

      this.mounted && this.setState({
        record: group,
      }, () => {
        setBreadcrumbs([
          { title: 'Home', path: '/' },
          { title: 'Admin', path: '/admin' },
          { title: 'Groups', path: '/admin/groups' },
          { title: group.title, path: null },
        ], false);
      });

    } catch {
      console.error('Failed');
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { user: { active_client } } = this.props;
    const { record } = this.state;

    if ((prevState.isFetchingUsers !== this.state.isFetchingUsers) && this.state.isFetchingUsers) {
      try {
        const availableUsers = await API.get(`client/${active_client}/admin/groups/${record.id}/available_group_users`);

        this.mounted && this.setState({
          availableUsers: availableUsers,
        });

      } catch {
        console.error('Failed');
      } finally {
        this.mounted && this.setState({
          isFetchingUsers: false
        });
      }
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderAddDialog = () => {
    const { user: { active_client } } = this.props;
    const { availableUsers, isFetchingUsers, record } = this.state;
    return (
      <Modal
        visible
        title={ 'Add User(s)' }
        onOk={ () => this.mounted && this.setState({
            isFetchingUsers: false
          }, () => {
            this.createFormRef
              .current
              .validateFields()
              .then( async (values: any) => {
                try {

                  await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

                  const group = await API.post(`client/${active_client}/admin/groups/${record.id}/user`, {
                    data: {
                      users: values.users
                    }
                  });

                  this.mounted && this.setState({
                    showAddDialog: false,
                    record: group,
                  }, () => {
                    Notification('success', 'The user has been added to the group.', 'User Added');
                  });

                } catch (error) {
                  Notification('error', '', 'Failed');
                } finally {
                  this.mounted && this.setState({
                    isLoading: false
                  });
                }
              })
              .catch((info: any) => {
                console.error('Invalid state');
              });
          }
        )}
        okText={ 'Add' }
        onCancel={() => this.mounted && this.setState({
          showAddDialog: false,
          isFetchingUsers: false,
        })}
        okButtonProps={{
          disabled: false
        }}
      >
        <Form
          ref={ this.createFormRef }
          layout="vertical"
        >
          <Form.Item
            label="Users"
            name="users"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select
              mode="multiple"
              showSearch
              style={{ width: '100%' }}
              loading={ isFetchingUsers }
              disabled={ isFetchingUsers }
              placeholder={ 'Select User(s)' }
              filterOption={ (input: string, option: any) => {
                return option?.children.some((child: any) => {
                  return child.toLowerCase().includes(input.toLowerCase());
                });
              } }
            >
              { availableUsers && availableUsers.map((user: any) => (
                <Option key={ user.id } value={ user.id }>{ user.first_name } { user.last_name } ({ user.email })</Option>
              )) }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderUsers = () => {
    const { user: { active_client } } = this.props;
    const { record } = this.state;
    const columns = [
      {
        key: 'first_name',
        dataIndex: 'first_name',
        title: 'First Name',
        filterable: true,
        sorter: true,
        ellipsis: true,
      },
      {
        key: 'last_name',
        dataIndex: 'last_name',
        title: 'Last name',
        filterable: true,
        sorter: true,
        ellipsis: true,
      },
      {
        key: 'email',
        dataIndex: 'email',
        title: 'Email',
        sorter: true,
        ellipsis: true,
        filterable: true,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        render: (_: any, user: any) => {
          return (
            <Popconfirm
              title={ 'Are you sure?' }
              icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
              visible={ this.state.deleteConfirmId === user.id }
              okButtonProps={{
                danger: true
              }}
              onConfirm={ async () => {
                try {
                  await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

                  const newrecord = await API.delete(`client/${active_client}/admin/groups/${record.id}/user/${this.state.deleteConfirmId}`);

                  this.mounted && this.setState({
                    record: newrecord
                  }, () => {
                    Notification('success', 'The user has been removed from the group.', 'User Removed');
                  });

                } catch (error) {
                  Notification('error', '', 'Failed');
                } finally {
                  this.mounted && this.setState({
                    isLoading: false
                  });
                }
              }}
              onCancel={ () => this.setState({ deleteConfirmId: null }) }
            >
              <DeleteOutlined
                className="mL-20 link"
                style={{ fontSize: 18 }}
                onClick={ () => {
                  this.setState({
                    deleteConfirmId: user.id
                  });
                } }
            />
            </Popconfirm>
          );
        },
        sorter: false,
        ellipsis: true,
        filterable: false,
        align: 'right'
      },
    ];

    const items = record && record.users && record.users.map((user: any, index: number) => {
      return {
        'key': index,
        'id': user.id,
        'first_name': user.first_name,
        'last_name': user.last_name,
        'email': user.email,
      };
    });

    const actions = [
      {
        node: (
          <Button onClick={ () => this.setState({ showAddDialog: true, isFetchingUsers: true }) }>
            Add User
          </Button>
        ),
      }
    ];

    return (
      <div className="Layout-box pB-20">
        <BasicList
          rawData
          columns={ columns }
          items={ items || [] }
          rightActions={ actions }
        />
      </div>
    );
  };

  render = () => {
    const { user: { active_client } } = this.props;
    const { record, isLoading, showAddDialog } = this.state;

    if (!isLoading && !record) return <p>No group found</p>;

    return (
      <BlockingSpinner isLoading={ isLoading }>
        { record &&
          <Jumbotron
            content={
              <>
                <p className="mB-0">
                  { record.title }
                </p>
                <p className="mB-0" style={{ fontSize: 12 }}>
                  { record.description }
                </p>
              </>
            }
            tabs={[
              {
                label: 'Users',
                node: this.renderUsers(),
              },
              {
                label: 'Coverage',
                node: <CoverageTab record={ record } clientId={ active_client } />,
              }
            ]}
          />
        }
        { showAddDialog && this.renderAddDialog() }
      </BlockingSpinner>
    );

  };

};

const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(Group), 'access_admin_groups'));
