export interface NotificationTemplate {
  id: number;
  notification_trigger_id: number;
  tool_id: number | null;
  tool_label?: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  message: string;
  subject: string;
  title: string;
  disabled: boolean;
  conditions: NotificationCondition[];
  recipients: NotificationRecipient[];
  delivery_methods: DeliveryMethod[];
};

export enum DeliveryMethodType {
  EMAIL = 'EMAIL',
  IN_TOOL = 'IN_TOOL',
};

export interface DeliveryMethod {
  id: number;
  method: DeliveryMethodType;
};

export interface NotificationCondition {
  id: number;
  type: ConditionType;
  config: any;
};

export interface NotificationRecipient {
  id: number;
  notification_template_id: number;
  type: RecipientType;
  target_id: number;
};

export interface NotificationTrigger {
  id: number;
  reference: string;
  title: string;
  description: string;
};

export interface Entity {
  bundle: string;
  class: string;
  config: any;
  label: string;
  type: string;
};

export interface Tool {
  id: number;
  label: string;
  route: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

export enum ConditionType {
  ENTITY_TYPE = 'ENTITY_TYPE',
  WORKFLOW_STAGE = 'WORKFLOW_STAGE',
  WORKFLOW_TRANSITION = 'WORKFLOW_TRANSITION',
  FIELD_VALUE = 'FIELD_VALUE',
};

export enum RecipientType {
  ROLE = 'ROLE',
  USER = 'USER',
  ALL_ROLES = 'ALL_ROLES',
};
