// Libs
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// Utilities
import { calculateWorkdays } from 'utils/calculateWorkdays';

// Interfaces
import { Activity as TemplateActivity } from 'views/admin/templates/Templates.interfaces';
import { ActivityRecord, OptionId } from 'components/form/field/activity_management/ActivityManagement.interfaces';

export enum DaysOfWeek {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
};

const DATE_FORMAT = 'YYYY-MM-DD 00:00:00';

// maximum number of days to continue activity
const DEFAULT_MAX_DURATION = 365 * 30;

export const moveStartDateToMonday = (startDate: moment.Moment): moment.Moment => {
  const date = startDate.clone();
  const dayOfWeek = date.day();
  const isWeekend = dayOfWeek === DaysOfWeek.SUNDAY || dayOfWeek === DaysOfWeek.SATURDAY;
  // if the start date falls on a weekend, the date is moved to Monday
  return isWeekend ? date.add({ days: dayOfWeek === DaysOfWeek.SUNDAY ? 1 : 2 }) : date;
};

export const convertToActivityRecord = (templateActivity: TemplateActivity, startProjectDate: string, options: { yes?: OptionId, no?: OptionId }, maxDuration: number = DEFAULT_MAX_DURATION): Partial<ActivityRecord> => {
  const { id, reference, title, description, role_ids, competency_ids, duration, commencement_offset, required, evidence, comments } = templateActivity;

  // if duration from template is greater than MAX_DURATION set MAX_DURATION
  const plannedDuration = duration > maxDuration ? maxDuration : duration;

  const uniqueKey: string = uuidv4();

  // start date with offset
  const startDate = moment(startProjectDate).add({ days: commencement_offset });
  // activity start date is moved to Monday if the day falls on a weekend
  const plannedStartDate = moveStartDateToMonday(startDate);

  const plannedCompletionDate = calculateWorkdays({ rangeStart: plannedStartDate, daysToAdd: plannedDuration });

  return {
    key: uniqueKey,
    activity_id: reference,
    activity_title: title,
    comment_count: 0,
    evidence_count: 0,
    activity_description: description,
    activity_resource: role_ids,
    activity_competency: competency_ids,
    activity_required: !!required ? true : false,
    activity_evidence: !!evidence ? true : false,
    activity_comment: !!comments ? true : false,
    activity_planned_start_date: plannedStartDate.format(DATE_FORMAT),
    activity_planned_duration: plannedDuration,
    activity_planned_completion_date: plannedCompletionDate.format(DATE_FORMAT),
    activity_actual_completion_date: null,
  };
};