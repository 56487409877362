// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import FieldWrapper from 'components/form/field/field-wrapper';
import { Checkbox, Radio, Select, Modal, Form, Input } from "antd";

// Interfaces
import { Option as ListOption } from './List.interface';
import { RadioChangeEvent } from 'antd/lib/radio';
import { RecordFormEntity } from 'types/entities';
import {
  ListType,
  FormFieldConfig,
  FormField,
  FormValues,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Icons
import { PlusCircleOutlined } from '@ant-design/icons';

// Styles
import './List.scss';

enum Mode {
  Multiple = 'multiple',
  Tags = 'tags',
};

const { Option } = Select;
const API: Api = new Api();

interface Props {
  clientId: number;
  record: RecordFormEntity;
  field: FormField;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string
  ): void;
  onFieldChange(
    field: FormField
  ): void;
  onRefreshForm(field_id: string): void;
  originalState: any;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  showCreateModal: boolean;
  isCreateLoading: boolean;
};

class ListField extends Component<Props, State> {

  formRef: any = React.createRef();

  state: State = {
    showCreateModal: false,
    isCreateLoading: false,
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { field, originalState } = this.props;

    const columnKeys = Object.keys(field.columns);

    columnKeys.forEach((columnKey: string) => {
      const _originalState = this.canSelectMultiple() ? originalState.map((_originalState: any) => _originalState[columnKey]) : originalState[0];
      const _state = this.canSelectMultiple() ? state.map((_state: any) => _state[columnKey]) : state[0];

      this.generateModifiedState(_originalState, _state, columnKey, shouldClear);
      this.generateErrorState(_state, columnKey, shouldClear);
    });
  };

  generateModifiedState = (rawPastValues: any, rawNewValues: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${field.id}_${cardinality}_${columnKey}`;

    if (Array.isArray(rawPastValues) && Array.isArray(rawNewValues)) {
      const pastValues: number[] = rawPastValues.map((rawPastValue: any) => rawPastValue.option_id).sort((a: number, b: number) => a - b);
      const newValues: number[] = rawNewValues.map((rawNewValue: any) => rawNewValue.option_id).sort((a: number, b: number) => a - b);

      if (!_.isEqual(pastValues, newValues) && !shouldClear) {
        const message: FormFieldInfoBoxModifiedMessage = {
          id: id,
          cardinality: cardinality,
          group: config.groupID,
          tab: config.tabID,
          order: config.elementIndex,
          content: {
            label: field.label,
            content: [],
          },
          modified: {}
        };

        setFieldModifiedMessage(key, message);
      } else {
        setFieldModifiedMessage(key);
      }
    } else {
      const strippedPastValue = (!_.isEmpty(rawPastValues) && Array.isArray(rawPastValues)) ? rawPastValues[0].option_id : rawPastValues;

      if (!_.isEqual(strippedPastValue, rawNewValues) && !shouldClear) {
        const message: FormFieldInfoBoxModifiedMessage = {
          id: id,
          cardinality: 0,
          group: config.groupID,
          tab: config.tabID,
          order: config.elementIndex,
          content: {
            label: field.label,
            content: [],
          },
          modified: {}
        };

        setFieldModifiedMessage(key, message);
      } else {
        setFieldModifiedMessage(key);
      }
    }
  };

  generateErrorState = (values: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldErrorMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${field.id}_${cardinality}_${columnKey}`;
    const errors: string[] = [];

    if (field.config.required && _.isEmpty(values)) {
      errors.push('Cannot be empty');
    }

    if (!_.isEmpty(errors) && !shouldClear) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  canSelectMultiple = () => {
    return this.props.field.config.cardinality !== 1;
  };

  getOptionLabel = (options: ListOption[] = [], values: any[]): string => {

    if (!_.isEmpty(options)) {
      const valueLabels = values.map((rawValue: any) => {
        const value = Number(rawValue);
        const optionObj = options.find(option => option.id === value);
        return optionObj ? optionObj.option : '';
      });
      const renderedValues = valueLabels.join(', ');

      return renderedValues;
    }

    return '';
  };

  renderCreateOptionModal = () => {
    const { clientId, record, field, onFieldChange } = this.props;
    const { isCreateLoading } = this.state;
    return (
      <Modal
        centered
        visible
        closable={ false }
        title={ 'Add Option' }
        onCancel={ () => this.setState({ showCreateModal: false }) }
        okText={ 'Ok' }
        okButtonProps={{
          loading: isCreateLoading
        }}
        onOk={ async () => {
          this.formRef
            .current
            .validateFields()
            .then( async (values: any) => {
              try {

                await new Promise((resolve) => this.setState({ isCreateLoading: true }, () => resolve(null)));

                const options = await API.post(`client/${clientId}/field/select/${field.id}/create_option`, {
                  entity_id: record.id,
                  entity_type: record.type,
                  entity_bundle: record.bundle,
                  option: values.option
                });

                const option = options.find((option: any) => option.option === values.option);

                onFieldChange({
                  ...field,
                  options: options,
                  values: [{
                    option_id: option.id,
                    option_value: option.option
                  }]
                });

                Notification('success', 'Option Created', 'Created');

              } catch (error: any) {
                Notification('error', error.data, 'Failed');
              } finally {
                this.setState({
                  showCreateModal: false,
                  isCreateLoading: false,
                });
              }
            })
            .catch((info: any) => {
              console.error('Invalid state');
            });
          }
        }
        style={{ minWidth: 500 }}
      >
        <Form
          ref={ this.formRef }
          layout="vertical"
        >
          <Form.Item
            label="New option"
            name="option"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderCheckbox = (columnKey: string, value: any) => {
    const { field, config, border, fieldErrorMessages, fieldModifiedMessages, isDisabled, onChange } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const options = field.options || [];

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        key={ `${field.id}-list-${columnKey}` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ errors }
        required={ field.config.required }
        border={ border }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <Checkbox.Group
          className={ classNames('Field', {
            'Field--has-warning border-warning': isModified && _.isEmpty(errors),
          }) }
          onChange={ (checkedValue: CheckboxValueType[]) => onChange(field, checkedValue, config, columnKey) }
          defaultValue={ value }
          disabled={ isDisabled }
        >
          { options.map((option: ListOption, index: number) => {
            return (
              <Checkbox
                key={ `${field.id}-list-${columnKey}-option-${index}` }
                value={ option.id }
                disabled={ !!option.disabled }
              >
                { option.option }
              </Checkbox>
            );
          }) }
        </Checkbox.Group>
      </FieldWrapper>
    );
  };

  renderRadio = (columnKey: string, value: any) => {
    const { field, config, border, fieldErrorMessages, fieldModifiedMessages, isDisabled, onChange } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const options = field.options || [];

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        key={ `${field.id}-list-${columnKey}` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ errors }
        required={ field.config.required }
        border={ border }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <Radio.Group
          onChange={(e: RadioChangeEvent) => onChange(field, e.target.value, config, columnKey) }
          defaultValue={ value }
          disabled={ isDisabled }
        >
          { options.map((option: ListOption, index: number) => {
            return (
              <Radio
                key={ `${field.id}-list-${columnKey}-option-${index}` }
                value={ option.id }
                disabled={ !!option.disabled }
                className={ classNames('Radio-Field', {
                  'Radio-Field--has-warning border-warning': isModified && _.isEmpty(errors),
                }) }
              >
                { option.option }
              </Radio>
            );
          }) }
        </Radio.Group>
      </FieldWrapper>
    );
  };

  renderSelect = (columnKey: string, value: any, isMultiSelect: boolean) => {
    const { field, config, border, fieldErrorMessages, fieldModifiedMessages, isDisabled, onChange } = this.props;
    const { isCreateLoading } = this.state;
    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const canInlineCreate = _.has(field, 'config.can_inline_create') && field.config.can_inline_create;
    const options = field.options || [];

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        key={ `${field.id}-list-${columnKey}-${ isMultiSelect ? 'multiple' : 'single' }` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ errors }
        required={ !!field.config.required }
        border={ border }
        description={ !!field.description && field.description }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
        rightActions={ canInlineCreate ? [
          {
            node: (
              <PlusCircleOutlined className="fsz-def text-ant-default" onClick={ () => this.setState({ showCreateModal: true }) } />
            ),
            isDisabled: false,
            isLoading: isCreateLoading,
          }
        ] : [] }
      >
        <Select
          mode={ isMultiSelect ? 'multiple' : undefined }
          allowClear={ !field.config.required }
          showSearch={ !!options && options.length > 5 }
          className={ classNames('Select-Field', {
            'Select-Field--has-warning border-warning': isModified && _.isEmpty(errors),
          }) }
          onChange={ (__, option: any) => {
            if (isMultiSelect) {
              onChange(field, option.map((_option: any) => {
                return {
                  option_id: _option.value,
                  option_value: _option.label,
                };
              }), config);
            } else {
              if (!option) {
                onChange(field, [], config);
              } else {
                onChange(field, [{
                  option_id: option.value,
                  option_value: option.label,
                }], config);
              }
            }
          } }
          onClear={ () => onChange(field, null, config) }
          placeholder={ field.label }
          value={ value }
          disabled={ isDisabled }
          filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
        >
          { options.map((option: ListOption) => {
            return (
              <Option
                key={ `${field.id}-list-${columnKey}-option-${option.id}` }
                value={ option.id }
                disabled={ !!option.disabled }
                label={ option.option }
              >
                { option.option }
              </Option>
            );
          }) }
        </Select>
      </FieldWrapper>
    );
  };

  render = () => {
    const { field, state, config, border, isDisabled } = this.props;
    const { showCreateModal } = this.state;
    const columnKeys = Object.keys(field.columns) || [];

    return (
      <>
        { columnKeys.map((columnKey: string) => {

          const value: Array<string | number> | null = state.map((item: FormValues) => item[columnKey]);
          const fieldConfig = field.config.format;

          if (!!isDisabled) {
            const selectedOption = this.getOptionLabel(field.options, value || []);
            return (
              <FieldWrapper
                id={ `${config.tabID}|${config.groupID}|${field.id}` }
                col={ config.fieldColSpan }
                label={ field.label }
                required={ field.config.required }
                key={ `${field.id}-list-${columnKey}` }
                border={ border }
                description={ !!field.description && field.description }
                refreshOnChange={ !!field.config.refresh_on_change }
                versionChanged={ !!field.config.version_changed }
              >
                <span className="d-b pY-5">{ selectedOption }</span>
              </FieldWrapper>
            );
          }

          switch (fieldConfig) {
            case ListType.Checkbox:
              return this.renderCheckbox(columnKey, value);
            case ListType.Radio:
              return this.renderRadio(columnKey, value);
            case ListType.Select:
            case ListType.Multi_select:
              return this.renderSelect(columnKey, value, this.canSelectMultiple());
            default:
              return null;
          }
        })}
        { showCreateModal && this.renderCreateOptionModal() }
      </>
    );
  };
};

export default ListField;
