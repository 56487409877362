// Libs
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Components
import { Table, Tooltip } from 'antd';
import FieldWrapper from 'components/form/field/field-wrapper';
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import SpaceSelectionModal from './SpaceSelectionModal';

// Icons
import { EditOutlined, ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  field: FormField;
  clientId: number;
  originalState: any;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  onRefreshForm(field_id: string): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  showSpaceSelectionModal: boolean;
};

class SpaceRelationshipField extends Component<Props, State> {

  state: State = {
    showSpaceSelectionModal: false,
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  validate = (state: any) => {
    const { originalState } = this.props;
    this.generateModifiedState(originalState, state, 'space_relationship');
    this.generateErrorState(state, 'space_relationship');
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string) => {
    const { setFieldModifiedMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue)) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (values: any[], columnKey: string) => {
    const { setFieldErrorMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    let errors: any = [];

    if (!!field.config.required && _.isEmpty(values)) {
      errors.push('Cannot be empty');
    }

    if (!_.isEmpty(errors)) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  render = () => {
    const { record, field, config, clientId, border, state, isDisabled, onChange, fieldModifiedMessages, fieldErrorMessages } = this.props;
    const { showSpaceSelectionModal } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_space_relationship`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const spaces = state && state.map((value: any, index: number) => {
      return {
        key: index,
        ...value,
      };
    });
    const columns = [
      {
        title: <span>Property</span>,
        width: 180,
        render: (row: any) => {
          return (
            <Link className='primaryColor' to={ `${row.target_path}` }>
              { row.target_title }
            </Link>
          );
        }
      },
      {
        title: <span>Location</span>,
        width: 180,
        render: (row: any) => {
          if (!_.has(row, 'location') || !row.location) return <>-</>;
          return (
            <Link className='primaryColor' to={ `${row.location.path}` }>
              { row.location.title }
            </Link>
          );
        }
      },
      {
        title: <span>Regions</span>,
        width: 180,
        render: (row: any) => {
          if (!_.has(row, 'region_trees')) return <>-</>;
          return (
            <>
              { row.region_trees.map((regions: any, index: number) => (
                <div key={ index }>
                  { regions.map((region: any, _index: number) => (
                    <span key={ _index }>
                      { regions.length === _index + 1 ?
                        (
                          <span>{ region.title }</span>
                          ) : (
                          <span><span>{ region.title }</span><ArrowRightOutlined className="mL-10 mR-10" style={{ fontSize: 10 }} /></span>
                        )
                      }
                    </span>
                  ) ) }
                </div>
              ) ) }
            </>
          );
        }
      },
      {
        title: <span>Space</span>,
        width: 180,
        render: (row: any) => {
          if (!_.has(row, 'space_tree') || !row.space_tree) return <>-</>;
          return (
            <>
              { row.space_tree.map((space: any, index: number) => (
                  <span key={ index }>
                    { row.space_tree.length === index + 1 ?
                      (
                        <span>{ space.title }</span>
                      ) : (
                        <span><span>{ space.title }</span><ArrowRightOutlined className="mL-10 mR-10" style={{ fontSize: 10 }} /></span>
                      )
                    }
                  </span>
              ) ) }
            </>
          );
        }
      }
    ];

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ 12 }
        label={ field.label }
        errors={ errors }
        hideErrorInfo
        required={ field.config.required }
        border={ border }
        description={ !!field.description && field.description }
        isModified={ _.isEmpty(errors) && isModified }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
        rightActions={[
          {
            node: (
              <>
                { !isDisabled &&
                  <EditOutlined
                    className="link fsz-md"
                    style={{ padding: '5px 10px'}}
                    onClick={ () => this.setState({ showSpaceSelectionModal: true }) }
                  />
                }
                { !_.isEmpty(errors) &&
                  <Tooltip
                    overlayClassName="text-white Field-Error"
                    placement="topRight"
                    title={ errors.join(', ') }
                  >
                    <InfoCircleOutlined className="Field-Error-Icon" />
                  </Tooltip>
                }
              </>
            )
          }
        ]}
      >
        <Table
          size={ 'small' }
          columns={ columns }
          dataSource={ spaces || [] }
          pagination={ false }
        />
        { showSpaceSelectionModal &&
          <SpaceSelectionModal
            record={ record }
            field={ field }
            clientId={ clientId }
            onSave={ (_field: FormField) => {
              this.setState({ showSpaceSelectionModal: false }, () => {
                onChange(field, _field.values, config);
              });
            } }
            onClose={ () => this.setState({ showSpaceSelectionModal: false }) }
          />
        }
      </FieldWrapper>
    );
  };
};

export default SpaceRelationshipField;
