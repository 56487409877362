// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { has } from 'lodash';

// Services
import { Api } from 'services/api';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Containers
import CostCentreBudgetContainer from 'containers/finance/cost-centre/CostCentreBudgetContainer';

// Views
import CostCentreRecord from 'views/finance/cost-centre/CostCentreRecord';
import CostCentreDocuments from 'views/finance/cost-centre/CostCentreDocuments';
import CostCentreResources from 'views/finance/cost-centre/CostCentreResources';
import CostCentreComments from 'views/finance/cost-centre/CostCentreComments';
import CostCentreAudit from 'views/finance/cost-centre/CostCentreAudit';
import DashboardView from 'views/common/DashboardView';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { RecordFormEntity } from 'types/entities';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbs,
  setBreadcrumbsLoading,
} from 'store/UI/ActionCreators';

// Utils
import Console from 'utils/console';

interface Props {
  client_id: number;
  permissions: any;
  match: { params: Record<string, any> };
  location: {
    pathname: string;
  };
  routes: any[];
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  isFetching: boolean;
  record: RecordFormEntity | null;
};

const API: Api = new Api();

class CostCentreContainer extends React.Component<Props, State> {

  mounted: boolean = false;
  state: State = {
    isFetching: false,
    record: null
  };

  componentDidMount = () => {
    this.mounted = true;
    this.getRecord();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.params.cost_centre_id !== this.props.match.params.cost_centre_id) {
      this.getRecord();
    }

    if (!!prevProps.location.pathname.match(/budget\/[0-9]+/) !== !!this.props.location.pathname.match(/budget\/[0-9]+/)) {
      this.getRecord();
    }
  };

  componentWillUnmount = () => {
    this.props.setSecondarySidebarRoutes([]);
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getRecord = async (silent: boolean = false) => {
    try {

      // Set default routes
      this.props.setSecondarySidebarRoutes([]);

      if (this.props.match.params.cost_centre_id && this.props.client_id && !this.props.location.pathname.match(/budget\/[0-9]+/)) {

        if (!silent) {
          await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));
          this.props.setBreadcrumbsLoading(true);
        }

        const record = await API.get(`client/${this.props.client_id}/record/cost-centre/${this.props.match.params.cost_centre_id}`);

        this.setRecord(record, silent);
      }
    } catch (error) {
      Console.error(error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.setState({
        isFetching: false
      });
    }
  };

  setRecord = (record: RecordFormEntity, silent?: boolean) => {
    const showLoadingState = this.state.record?.title !== record.title;

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(true);
    }

    if (has(record, 'breadcrumbs')) {
      this.props.setBreadcrumbs(record.breadcrumbs, false);
    }

    if (has(record, 'permissions')) {
      const { permissions, new_content } = record;

      this.props.setSecondarySidebarRoutes([
        { title: 'Cost Centre', path: `/finance/cost-centres/${record.id}/record`, isDisabled: !hasPermission(permissions, 'record_cost_centre_view') },
        { title: 'Dashboards', path: `/finance/cost-centres/${record.id}/dashboards`, isIndented: true, isDisabled: !hasPermission(permissions, 'record_cost_centre_dashboard_view') },
        { title: 'Documents', path: `/finance/cost-centres/${record.id}/documents`, isIndented: true, isDisabled: !hasPermission(permissions, 'record_cost_centre_document_view'), hasUpdates: !!new_content.documents },
        { title: 'Comments', path: `/finance/cost-centres/${record.id}/comments`, isIndented: true, isDisabled: !hasPermission(permissions, 'record_cost_centre_comment_view'), hasUpdates: !!new_content.comments },
        { title: 'Resources', path: `/finance/cost-centres/${record.id}/resources`, isIndented: true, isDisabled: !hasPermission(permissions, 'record_cost_centre_resource_view'), hasUpdates: !!new_content.resources },
        { title: 'Audit', path: `/finance/cost-centres/${record.id}/audit`, isIndented: true, isDisabled: !hasPermission(permissions, 'record_cost_centre_audit_view') },
      ]);
    }

    this.setState({
      record: record,
      isFetching: false
    });

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(false);
    }
  };

  render = () => {
    const { isFetching, record } = this.state;
    const permissions = record && has(record, 'permissions') ? record.permissions : this.props.permissions;
    return (
      <BlockingSpinner isLoading={isFetching}>
        <Switch>

          <Redirect exact from="/finance/cost-centres/:cost_centre_id" to="/finance/cost-centres/:cost_centre_id/record" />

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/record" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_cost_centre_view') }>
                  <CostCentreRecord record={ record } setRecord={ this.setRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/resources" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_cost_centre_resource_view') }>
                  <CostCentreResources record={ record } getRecord={ this.getRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/documents" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_cost_centre_document_view') }>
                  <CostCentreDocuments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/comments" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_cost_centre_comment_view') }>
                  <CostCentreComments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/finance/cost-centres/:cost_centre_id/audit" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_cost_centre_audit_view') }>
                  <CostCentreAudit record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route path="/finance/cost-centres/:cost_centre_id/dashboards" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_cost_centre_dashboard_view') }>
                  <Route
                    exact
                    path="/finance/cost-centres/:cost_centre_id/dashboards"
                    render={ () => {
                      return (
                        <DashboardView record={ record } />
                      );
                    } }
                  />
                  <Route
                    exact
                    path="/finance/cost-centres/:cost_centre_id/dashboards/:dashboard_id"
                    render={ (props: RouteComponentProps | any) => {
                      return (
                        <DashboardView record={ record } dashboardId={ props.match?.params?.dashboard_id } />
                      );
                    } }
                  />
                </RestrictionWrapper>
              );
            } } />
          }

          <Route path="/finance/cost-centres/:cost_centre_id/budget/:budget_id" component={ CostCentreBudgetContainer } />

          <Route render={ () => <NotFound /> } />

        </Switch>
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    routes: store.UIState.secondarySidebarRoutes
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CostCentreContainer);
