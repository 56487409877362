// Libs
import React, { BaseSyntheticEvent } from 'react';
import _ from 'lodash';

// Components
import { Button, Input, Modal, Select, Popconfirm, Tooltip } from 'antd';
import CoverModal from 'components/cover-modal';
import DragSortingList from 'components/drag-sorting-list';
import { renderTags, changeOrder } from 'views/insight/InsightModal';

// Icons
import { MenuOutlined, DeleteOutlined, QuestionCircleOutlined, PlusCircleOutlined, SettingOutlined } from '@ant-design/icons';

// Interfaces
import { IFilter } from 'components/insight/Insight.interfaces';
import { IDashboard } from 'components/dashboard/Dashboard.interface';
import { TableType } from 'components/drag-sorting-list/DragSortingList.interfaces';

interface Props {
  dashboard: IDashboard;
  availableFilters: IFilter[];
  onSave(dashboard: IDashboard): void;
  onClose(): void;
};

interface State {
  dashboard: IDashboard;
  deletionId: string | null;
  settingsDialog: {
    isNew: boolean;
    filterId: string | null;
    custom_title: string | null;
    tooltip: string | null;
  } | null;
};

class AddFilterDialog extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    dashboard: this.props.dashboard,
    deletionId: null,
    settingsDialog: null,
  };

  renderList = (dashboard: IDashboard) => {
    return (
      <DragSortingList
        className='w-100p bd ov-s'
        styles={{ height: 200, width: 500 }}
        columns={ [
          {
            title: '',
            render: (__: any, filter: IFilter) => {
              return (
                <div className="d-f jc-sb pX-10">
                  <span className="d-f jc-c ai-c">
                    <span>
                      <MenuOutlined className="EntityRolesTab-Table-DragMenu" />
                    </span>
                    <span className='mL-20 whs-nw ov-h tov-e' style={{ width: '100%' }}>
                      { filter?.settings?.custom_title || filter.title } <span className="color-grey-600">({`${filter.module_title}`})</span>
                    </span>
                  </span>
                  <span>
                    { !_.isEmpty(filter.settings) &&
                      <span>
                        <span>
                          { renderTags(filter.settings, () => this.setState({
                            settingsDialog: {
                              isNew: false,
                              filterId: filter.id,
                              custom_title: filter?.settings?.custom_title || null,
                              tooltip: filter?.settings?.tooltip || null,
                            }
                          })) }
                        </span>
                        <span className="mR-10">
                          <SettingOutlined
                            className="link"
                            onClick={ () => {
                              this.setState({
                                settingsDialog: {
                                  isNew: false,
                                  filterId: filter.id,
                                  custom_title: filter?.settings?.custom_title || null,
                                  tooltip: filter?.settings?.tooltip || null,
                                }
                              });
                            } }
                          />
                        </span>
                      </span>
                    }
                    <Popconfirm
                      title={ 'Are you sure?' }
                      icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                      okButtonProps={{
                        danger: true
                      }}
                      onConfirm={ () => {
                        this.setState({
                          dashboard: _.set(dashboard, 'filters', dashboard.filters.filter((_filter: IFilter) => _filter.id !== filter.id))
                        });
                      } }
                      onCancel={ () => this.setState({ deletionId: null }) }
                    >
                      <DeleteOutlined className="link" />
                    </Popconfirm>
                  </span>
                </div>
              );
            },
          },
        ] }
        items={ dashboard.filters }
        isParent
        showHeader={ false }
        pagination={ false }
        config={{
          type: TableType.Tab,
          references: [],
        }}
        emptyText={ (
          <div className='d-f ai-c'>
            <span style={{ flex: 1 }}>No Filters</span>
          </div>
        ) }
        moveRow={ (dragIndex: number, hoverIndex: number) => {
          this.setState({
            dashboard: _.set(dashboard, 'filters', changeOrder(dashboard.filters, dragIndex, hoverIndex)),
          });
        } }
      />
    );
  };

  renderSettingsDialog = (
    dashboard: IDashboard,
    settingsDialog: {
      isNew: boolean;
      filterId: string | null;
      custom_title: string | null;
      tooltip: string | null;
    }
  ) => {
    const { availableFilters } = this.props;
    const uniqueFilters = availableFilters
      .filter((filter: IFilter) => {
        const currentFilterIds = dashboard.filters
          .filter((_filter: IFilter) => {
            // Exclude selected filter
            if (!settingsDialog.isNew && settingsDialog.filterId === _filter.id) {
              return false;
            }
            return true;
          })
          .map((_filter: IFilter) => _filter.id);

        return !currentFilterIds.includes(filter.id);
      });

    const uniqueGroups = _.uniqBy(uniqueFilters.map((filter: IFilter) => {
      return {
        module_id: filter.module_id,
        module_title: filter.module_title,
      };
    }), 'module_id');

    const filterOptions: any = uniqueGroups.map((group: { module_id: string, module_title: string }) => {
      return {
        label: group.module_title,
        options: uniqueFilters
          .filter((_uniqueFilter: IFilter) => _uniqueFilter.module_id === group.module_id)
          .map((_uniqueFilter: IFilter) => {
            return {
              label: _.startCase(_.toLower(_uniqueFilter.title)).replaceAll('_', ' '),
              value: _uniqueFilter.id,
            };
          })
      };
    });

    return (
      <Modal
        centered
        visible
        title={ settingsDialog.isNew ? 'Add Filter' : 'Edit Filter' }
        okButtonProps={{
          disabled: !settingsDialog.filterId
        }}
        onCancel={ () => this.setState({ settingsDialog: null }) }
        onOk={ () => {

          if (settingsDialog?.isNew) {
            const filter = availableFilters.find((_filter: IFilter) => _filter.id === settingsDialog.filterId);

            if (filter) {
              this.setState({
                dashboard: _.set(dashboard, 'filters', dashboard.filters.concat({
                  ...filter,
                  settings: {
                    ...filter.settings,
                    custom_title: settingsDialog.custom_title || null,
                    tooltip: settingsDialog.tooltip || null,
                  }
                })),
                settingsDialog: null
              });
            }
          } else {
            this.setState({
              dashboard: _.set(dashboard, 'filters', dashboard.filters.map((filter: IFilter) => {
                if (filter.id === settingsDialog.filterId) {
                  return {
                    ...filter,
                    settings: {
                      ...filter.settings,
                      custom_title: settingsDialog.custom_title || null,
                      tooltip: settingsDialog.tooltip || null,
                    }
                  };
                }
                return filter;
              })),
              settingsDialog: null
            });
          }
        } }
        cancelText={ 'Close' }
        okText={ !!settingsDialog?.isNew ? 'Add' : 'Edit' }
        style={{ minHeight: 500, minWidth: 200 }}
      >
        <div>
          <label>Filter</label>
          <Select
            showSearch
            placeholder={ '-' }
            filterOption={(input: any, option: any) => {
              if (option?.children) {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
              } else if(option.label) {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
              }
              return false;
            } }
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={ false }
            value={ settingsDialog?.filterId }
            options={ filterOptions }
            onChange={ (filter_id: string) => {
              this.setState({
                settingsDialog: {
                  ...settingsDialog,
                  filterId: filter_id,
                  custom_title: null,
                  tooltip: null,
                }
              });
            } }
          />
        </div>
        <div className="mT-10">
          <label>Custom Title</label>
          <Input
            style={{ width: '100%' }}
            placeholder={ '-' }
            disabled={ !settingsDialog?.filterId }
            value={ settingsDialog?.custom_title || undefined }
            onChange={ (event: BaseSyntheticEvent | null) => {
              this.setState({
                settingsDialog: _.set(settingsDialog, 'custom_title', event?.target.value)
              });
            } }
          />
        </div>
        <div className="mT-10">
          <label>Tooltip</label>
          <Input
            style={{ width: '100%' }}
            placeholder={ '-' }
            disabled={ !settingsDialog?.filterId }
            value={ settingsDialog?.tooltip || undefined }
            onChange={ (event: BaseSyntheticEvent | null) => {
              this.setState({
                settingsDialog: _.set(settingsDialog, 'tooltip', event?.target.value)
              });
            } }
          />
        </div>
      </Modal>
    );
  };

  render = () => {
    const { availableFilters, onSave, onClose } = this.props;
    const { dashboard, settingsDialog } = this.state;
    const uniqueFilters = availableFilters.filter((filter: IFilter) => !dashboard.filters.map((_filter: IFilter) => _filter.id).includes(filter.id));

    return (
      <CoverModal
        showCloseIcon={ false }
        style={{ width: 700, maxHeight: 700 }}
        middleContent={ (
          <span>
            <span>Manage Filters</span>
            <span className="mL-10">
              { _.isEmpty(uniqueFilters) ? (
                <Tooltip
                  title={ 'No available filters' }
                >
                  <PlusCircleOutlined
                    className="fsz-lg text-ant-disabled"
                  />
                </Tooltip>
              ) : (
                <PlusCircleOutlined
                  className="fsz-lg text-ant-blue cur-p"
                  onClick={ () => this.setState({
                    settingsDialog: {
                      isNew: true,
                      filterId: uniqueFilters[0].id,
                      custom_title: uniqueFilters[0]?.settings?.custom_title || null,
                      tooltip: uniqueFilters[0]?.settings?.tooltip || null,
                    }
                  }) }
                />
              ) }
            </span>
          </span>
        ) }
        onClose={ onClose }
      >
        <div className="pX-20 pB-20 w-100p d-f fxd-c h-100p">
          <div className='d-f fxd-c fxg-1 jc-c'>
            { this.renderList(dashboard) }
          </div>
          <div>
            <div className='mB-20 mT-50 ta-r'>
              <Button
                onClick={ () => this.props.onClose() }
              >
                Cancel
              </Button>
              <Button
                type='primary'
                className="mL-5"
                disabled={ !!settingsDialog }
                onClick={ () => onSave(dashboard) }
              >
                Ok
              </Button>
            </div>
          </div>
          { settingsDialog && this.renderSettingsDialog(dashboard, settingsDialog) }
        </div>
      </CoverModal>
    );
  };
};

export default AddFilterDialog;
