// Libs
import * as React from 'react';
import _ from 'lodash';

// Components
import { APIProvider, Map, Marker, MapMouseEvent } from '@vis.gl/react-google-maps';
import { ClusterMarker, ILocation } from 'components/map/ClusterMarker';

export interface IMarker {
  tooltip?: {
    header: React.ReactNode;
    content: React.ReactNode;
  };
  latitude: string;
  longitude: string;
};

interface Props {
  markers: IMarker[];
  cluster?: boolean;
  onClick?: (event: MapMouseEvent) => void;
};

const londonCoordinates = { latitude: '51.51', longitude: '-0.12' };
const apiKey = 'AIzaSyCp2gUVWE_JWfESJWRPd9JsCn1y-qEQE90';

export default function MapComponent({ markers, cluster = false, onClick = () => {} }: Props) {

  const getCoordinates = (marker: IMarker) => {
    return {
      lat: parseFloat(marker?.latitude),
      lng: parseFloat(marker?.longitude),
    };
  };

  const renderMarkers = (markers: IMarker[]) => {

    if (cluster) {
      const locations: ILocation[] = markers
        .filter((marker: IMarker) => !!marker?.latitude && marker?.longitude)
        .map((marker: IMarker, index: number) => {
          return {
            key: `${index}`,
            position: getCoordinates(marker),
            tooltip: marker?.tooltip
          };
      });

      return (
        <ClusterMarker
          locations={ locations }
        />
      );
    }

    return markers
      .filter((marker: IMarker) => !!marker?.latitude && marker?.longitude)
      .map((marker: IMarker, index: number) => {
        return (
          <Marker
            key={ index }
            position={ getCoordinates(marker) }
          />
        );
      } );
  };

  const defaultCenter = !_.isEmpty(markers) && (markers[0]?.latitude && markers[0]?.longitude) ? getCoordinates(markers[0]) : getCoordinates(londonCoordinates);
  const defaultZoom = cluster && markers.length > 2 ? 3 : 8;

  return (
    <APIProvider apiKey={ apiKey }>
      <Map
        mapId={ '4d6a74a217f4e45d' }
        scrollwheel={ false }
        defaultZoom={ defaultZoom }
        defaultCenter={ defaultCenter }
        disableDefaultUI
        zoomControl
        gestureHandling={ 'greedy' }
        onClick={ (event: MapMouseEvent) => {
          onClick && onClick(event);
        } }
      >
        { renderMarkers(markers) }
      </Map>
    </APIProvider>
  );
};