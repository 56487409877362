// Libs
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// Components
import { Result, Button } from 'antd';

export interface Props extends RouteComponentProps {};

class NoAccess extends React.Component<Props> {
  render = () => {
    return (
      <div className="d-f jc-c ai-c h-100p">
        <Result
          status="warning"
          title="You don't have permission to view this page."
          extra={
            <Button type="primary" onClick={ () => this.props.history.goBack() }>
              Go Back
            </Button>
          }
        />
      </div>
    );
  };
};

export default withRouter(NoAccess);