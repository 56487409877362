// Libs
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { Select, Table, Typography, Tooltip, Button, Modal, Checkbox } from 'antd';
import { hasPermission } from 'components/restriction';
import SwitchComponent from 'components/switch';
import OverlaySpinner from "components/overlay-spinner";
import Dropdown, { Action as DropdownAction } from 'components/dropdown';

// Views
import ScopeModal from 'views/common/ScopeModal';

// Services
import { Api } from 'services/api';
import history from 'utils/history';
import Notification from 'services/notification';

// Icons
import Icon, { EditOutlined, EyeOutlined, EllipsisOutlined } from "@ant-design/icons";
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg';

// Utils

// Interfaces
import { RecordFormEntity } from 'types/entities';

const API: Api = new Api();
const { Link } = Typography;
const { Option } = Select;

// Styles

interface Props {
  record: RecordFormEntity;
  id: number;
  clientId: number;
  scopeId: number;
  operations: any;
};

interface State {
  services: any[];
  contracts: any[];
  availableContracts: any[];
  selectedContracts: any[];
  tmpSelectedContracts: any[];
  filters: any;
  selectedOperation: any;
  selectedRowKeys: any[];
  isLoading: boolean;
  isUpdating: boolean;
  isFetching: boolean;
  tableHeight: number;
  showFilter: boolean;
  isOperating: boolean;
};

class ServiceSpecificationScopeMatrix extends React.Component<Props, State> {

  mounted: boolean = false;
  component: any = React.createRef();

  state: State = {
    services: [],
    contracts: [],
    availableContracts: [],
    selectedContracts: [],
    tmpSelectedContracts: [],
    selectedRowKeys: [],
    filters: [],
    selectedOperation: null,
    isLoading: false,
    isUpdating: false,
    isFetching: false,
    tableHeight: 0,
    showFilter: false,
    isOperating: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.fetchContracts();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (this.component && !this.state.isLoading && !this.state.isFetching && (!this.state.tableHeight || !_.isEqual(prevState.filters, this.state.filters) || prevState.showFilter !== this.state.showFilter)) {
      this.heightObserver();
    }

    if (!_.isEqual(prevState.selectedContracts, this.state.selectedContracts)) {
      this.setState({
        isFetching: true
      }, () => {
        this.fetchScope();
      });
    }

    if (!_.isEqual(prevProps.scopeId, this.props.scopeId)) {
      this.fetchScope();
    }
  };

  heightObserver = () => {
    const root: number = document.getElementById('root')?.offsetHeight || 0;
    const header: number = document.getElementById('Header')?.offsetHeight || 0;
    const jumbotron: number = document.getElementById('Jumbotron')?.offsetHeight || 0;
    const tabViewBar: number = document.getElementById('TabView-bar')?.offsetHeight || 0;
    const filtersHeight: number = document.getElementById('ServiceSpecificationScopeFilter')?.offsetHeight || 0;
    const tableHeight: number = root - (header + jumbotron + tabViewBar + filtersHeight + 170);

    if (this.state.tableHeight !== tableHeight) {
      this.setState({
        tableHeight: tableHeight
      });
    }
  };

  fetchContracts = async () => {
    const { clientId, id } = this.props;
    try {
      // Set loading
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      // Fetch
      const availableContracts = await API.get(`client/${clientId}/record/service-specification/${id}/scope-matrix/available-columns`);

      this.setState({
        availableContracts: availableContracts,
        selectedContracts: !_.isEmpty(availableContracts) ? availableContracts.slice(0, 5).map((contract: any) => contract.id) : [],
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  fetchScope = async () => {
    const { clientId, id } = this.props;
    const { selectedContracts } = this.state;

    try {

      const scope = await API.get(`client/${clientId}/record/service-specification/${id}/scope-matrix`, {
        columns: selectedContracts
      });

      this.setState({
        services: scope.data,
        contracts: scope.columns,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
        isUpdating: false,
        isFetching: false,
      });
    }
  };

  onChangeScope = async (scope_type: string, scope_id: number, contract_id: number, in_scope: boolean) => {
    const { clientId, id } = this.props;
    const { selectedContracts } = this.state;

    try {
      await new Promise((resolve) => this.setState({ isUpdating: true }, () => resolve(null)));

      const scope = await API.put(`client/${clientId}/record/service-specification/${id}/scope-matrix/change-scope-item`, {
        columns: selectedContracts,
        scope_type: scope_type,
        scope_id: scope_id,
        contract_id: contract_id,
        in_scope: in_scope,
      });

      this.setState({
        services: scope.data,
        contracts: scope.columns,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isUpdating: false
      });
    }
  };

  filterTable = (data: any[], filters: any) => {
    const collector: any = [];

    const inScopeFilter = !_.isEmpty(filters) && _.has(filters, 'scope') ? filters['scope'] : null; // true,false,null
    const statusFilter = !_.isEmpty(filters) && _.has(filters, 'status') ? filters['status'] : null; // 'pending','active', null

    data.forEach((value: any) => {

      const check = (_value: any) => {

        let passedScopeFilter = true;
        let passedStatusFilter = true;

        if (inScopeFilter !== null) {
          passedScopeFilter = _value.contracts.some((contracts: any) => {
            return _.has(contracts, 'scope') && !!contracts.scope === inScopeFilter;
          });
        }

        if (statusFilter !== null) {
          passedStatusFilter = _value.contracts.some((contracts: any) => {
            return _.has(contracts, 'scope.status.title') && _.kebabCase(contracts.scope.status.title.toLowerCase()) === statusFilter;
          });
        }

        if (passedScopeFilter && passedStatusFilter) {
          collector.push({
            ..._value,
            'key': `${_value.type}-${_value.id}`,
            'label': _value.title,
            'children': null,
          });
        }

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  getColumns = (contracts: any) => {
    const { record } = this.props;
    const { isUpdating } = this.state;

    const canEdit = hasPermission(record, 'permissions.record_contract_scope_edit');
    const canView = hasPermission(record, 'permissions.record_contract_scope_view');

    let columns: any = [
      {
        key: 'label',
        dataIndex: 'label',
        title: 'Service',
        fixed: 'left',
        width: 300,
        ellipsis: true,
      }
    ];

    contracts.forEach((location: any) => {
      columns.push(
        {
          key: location.id,
          title: location.title,
          dataIndex: location.id,
          width: 200,
          render: (__: any, row: any) => {

            const contract = _.has(row, 'contracts') && row.contracts.find((_contract: any) => location.id === _contract.id);
            const inScope = contract && !!contract.scope;
            let switchColor = '';
            let stage = 'Out of Scope';

            if (inScope && _.has(contract, 'scope.status.context')) {
              switch (contract.scope.status.context.toUpperCase()) {
                case 'TODO':
                  switchColor = 'Switch-Todo';
                  stage = contract.scope.status.title;
                break;
                case 'PROGRESS':
                  switchColor = 'Switch-Progress';
                  stage = contract.scope.status.title;
                break;
                case 'RESOLVED':
                  switchColor = 'Switch-Resolved';
                  stage = contract.scope.status.title;
                break;
                case 'PROBLEM':
                  switchColor = 'Switch-Problem';
                  stage = contract.scope.status.title;
                break;
                case 'CLOSED':
                  switchColor = 'Switch-Closed';
                  stage = contract.scope.status.title;
                break;
              }
            }

            return (
              <div className='d-f'>
                <Tooltip placement={ 'top' } title={ stage }>
                  <SwitchComponent
                    className={ `${switchColor}` }
                    checked={ inScope }
                    onChange={ checked => this.onChangeScope(row.type, row.id, contract.id, checked) }
                    disabled={ !contract || !canEdit || isUpdating }
                  />
                </Tooltip>
                { inScope &&
                  <Link
                    onClick={ () => !isUpdating && history.push(`scope/${contract.scope.id }`) }
                    disabled={ isUpdating }
                  >
                    { canEdit &&  <EditOutlined className="mL-10 va-m fsz-md"/> }
                    { !canEdit && canView && <EyeOutlined className="mL-10 va-m fsz-md"/> }
                  </Link>
                }
              </div>
            );
          }
        }
      );
    });

    return columns;
  };

  getData = (data: any) => {
    const recordDataMapping = (data: any[]) => {
      return data.map((entity: any) => {
        const appendChildrenKeys = (children: any) => {
          return children
            .map((childEntity: any) => {
              return {
                ...childEntity,
                'key': `${childEntity.type}-${childEntity.id}`,
                'label': childEntity.title,
                'children': !_.isEmpty(childEntity.children) ? appendChildrenKeys(childEntity.children) : null,
              };
            });
        };
        return {
          ...entity,
          'key': `${entity.type}-${entity.id}`,
          'label': entity.title,
          'children': appendChildrenKeys(entity.children),
        };
      });
    };

    return recordDataMapping(data);
  };

  getFlatten = (data: any) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({
          ..._value,
          'key': `${_value.type}-${_value.id}`,
        });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };
      return check(value);
    });

    return collector;
  };

  renderOperationDialog = () => {
    const { id, clientId } = this.props;
    const { services, selectedOperation, isOperating, contracts, selectedRowKeys } = this.state;
    let description: React.ReactNode = <></>;

    const flattenServices = this.getFlatten(services);
    const selectedServices = selectedRowKeys.map((selectedRowKey: string) => {
      const service = flattenServices.find((_service: any) => _service.key === selectedRowKey);
      return {
        id: service.id,
        type: service.type,
        bundle: service.bundle
      };
    });

    if (selectedOperation) {
      switch (_.snakeCase(_.toLower(selectedOperation.title))) {
        case 'bulk_approve':
        case 'bulk_reject':
          description = (
            <p>You're about to <b>{ selectedOperation.title }</b> the selected <b>Pending Review</b> Services.</p>
          );
        break;
        case 'bulk_submit_for_approval':
          description = (
            <p>You're about to <b>{ selectedOperation.title }</b> the selected <b>Draft</b> Services.</p>
          );
        break;
      }
    }

    return (
      <Modal
        visible
        centered
        maskClosable={ !isOperating }
        closable={ !isOperating }
        title={ selectedOperation.title }
        okText={ 'Ok' }
        onOk={ async () => {
          try {
            await new Promise((resolve) => this.setState({ isOperating: true }, () => resolve(null)));

            const scope = await API.put(`client/${clientId}/record/service-specification/${id}/scope-matrix/bulk-operation/workflow_transition`, {
              data: {
                transition_id: selectedOperation.data.id,
                contracts: contracts.map((contract: any) => contract.id),
                services: selectedServices
              }
            });

            this.setState({
              services: scope.data,
              contracts: scope.columns,
            }, () => {
              Notification('success', 'Bulk Operation Successful');
            });

          } catch (error) {
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              isOperating: false,
              selectedOperation: null,
            });
          }
        } }
        onCancel={() => this.setState({
          selectedOperation: null,
        }) }
        okButtonProps={{
          loading: isOperating,
          danger: true
        }}
        cancelButtonProps={{
          disabled: isOperating
        }}
      >
        { description }
        <p className="mT-20" >Are you sure you wish to proceed?</p>
      </Modal>
    );
  };

  render = (): JSX.Element => {
    const { clientId, record, operations, scopeId } = this.props;
    const {
      services,
      contracts,
      filters,
      tableHeight,
      selectedContracts,
      tmpSelectedContracts,
      availableContracts,
      showFilter,
      isLoading,
      isUpdating,
      isFetching,
      selectedRowKeys,
      isOperating,
    } = this.state;

    const canBulkOperate = hasPermission(record, 'permissions.record_service_specification_bulk_operation');

    const actions: DropdownAction[] = [
      {
        node: '',
        onClick: () => {},
      }
    ];

    operations.forEach((operation: any) => {
      actions.push({
        node: operation.title,
        onClick: () => this.setState({
          selectedOperation: operation
        }),
        isLoading: isOperating,
        disabled: _.isEmpty(selectedRowKeys) && ['No services selected']
      });
    });

    const columns = this.getColumns(contracts);
    let dataSource = this.getData(services);

    if (!_.isEmpty(filters)) {
      dataSource = this.filterTable(dataSource, filters);
    }

    const flattenServices = this.getFlatten(dataSource);

    return (
      <div className="d-f">
        <div style={ !!scopeId ? { width: '40%' } : { width: '100%' }}>
          <div ref={ node => (this.component = node) }>
            <div id="ServiceSpecificationScopeFilter">
              { !isLoading &&
                <>
                  <div className="mB-15 mT-10 d-f">
                    <span className="fxg-1">
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: '100%', minWidth: 400 }}
                        placeholder="Supplier/Contract"
                        maxTagCount={ 3 }
                        onChange={(_contracts: any[]) => {
                          this.setState({
                            tmpSelectedContracts: _contracts,
                          });
                        }}
                        defaultValue={ selectedContracts }
                        filterOption={(input: any, option: any) => {
                          return availableContracts.find((contract: any) => {
                            return contract.id === option.value && contract.title.toLowerCase().includes(input.toLowerCase());
                          });
                        } }
                      >
                        { availableContracts.map((contract: any) =>
                          <Option key={ contract.id } value={ contract.id }>{ contract.title }</Option>
                        ) }
                      </Select>
                    </span>
                    <span className="mL-5">
                      <Button
                        disabled={ _.isEmpty(tmpSelectedContracts) || _.isEqual(tmpSelectedContracts, selectedContracts) }
                        onClick={ () => {
                          this.setState({
                            selectedContracts: tmpSelectedContracts
                          });
                        } }
                        loading={ isUpdating || isFetching }
                      >
                        Load Columns
                      </Button>
                    </span>
                    <span className="mL-5">
                      { _.isEmpty(selectedRowKeys) ? (
                        <Tooltip
                          placement={ 'left' }
                          title={ 'No selected service' }
                        >
                          <Button
                            disabled
                            style={{
                              padding: '5px 7px',
                              width: '32px',
                            }}
                            onClick={ () => {} }
                          >
                            <EllipsisOutlined className="fsz-def text-ant-default" />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Dropdown actions={ actions } />
                      ) }
                    </span>
                  </div>
                  <div
                    className="d-f ai-c mB-15"
                    style={{ userSelect: 'none' }}
                  >
                    <div className="d-if mL-10">
                      <span>
                        <span>Showing </span>
                        <b>{ selectedContracts.length }</b>
                        <span> Contracts out of </span>
                        <b>{ availableContracts.length }</b>
                      </span>
                      <span className="mL-35">
                        <div
                          className={ classNames('d-f ai-c link', {
                            'text-ant-disabled disabled': isUpdating || isFetching,
                            'active': showFilter,
                          }) }
                          onClick={ () => {
                            this.setState({
                              showFilter: !showFilter
                            });
                          } }
                        >
                        <Icon
                          component={ FilterIcon }
                        />
                        <span>Filter</span>
                      </div>
                      </span>
                    </div>
                  </div>
                  { showFilter &&
                    <div className="mB-15">
                      <span>
                        <Select
                          allowClear
                          style={{ width: 200 }}
                          placeholder="Scope"
                          optionFilterProp="children"
                          onChange={ (value: any) => {
                            let newFilters = filters;
                            if (!value) {
                              delete newFilters['scope'];
                            } else {
                              newFilters = Object.assign({}, filters, { 'scope': value === '1' });
                            }
                            this.setState({
                              filters: newFilters
                            });
                          } }
                          value={ _.has(filters, 'scope') ? !!filters['scope'] ? '1' : '0' : undefined }
                        >
                          <Option value="1">In Scope</Option>
                          <Option value="0">Out of Scope</Option>
                        </Select>
                      </span>
                      <span className="mL-10">
                        <Select
                          allowClear
                          style={{ width: 200 }}
                          placeholder="Stage"
                          optionFilterProp="children"
                          onChange={ (value: any) => {
                            let newFilters = filters;
                            if (!value) {
                              delete newFilters['status'];
                            } else {
                              newFilters = Object.assign({}, filters, { 'status': value });
                            }
                            this.setState({
                              filters: newFilters
                            });
                          } }
                          value={ _.has(filters, 'status') ? filters['status'] : undefined }
                        >
                          <Option value="draft">Draft</Option>
                          <Option value="pending-review">Pending Review</Option>
                          <Option value="approved">Approved</Option>
                        </Select>
                      </span>
                    </div>
                  }
                </>
              }
            </div>
          </div>
          <BlockingSpinner isLoading={ isLoading || isFetching } style={{ minHeight: '50vh' }}>
            { isUpdating && <OverlaySpinner /> }
            <div className='Layout-box'>
              <Table
                size={ 'small' }
                sticky
                columns={ columns }
                dataSource={ dataSource }
                pagination={ false }
                scroll={{
                  x: columns.length * 300,
                  y: tableHeight,
                }}
                expandable={{
                  defaultExpandAllRows: true
                }}
                rowSelection={ canBulkOperate && !_.isEmpty(dataSource) ? {
                  selectedRowKeys: selectedRowKeys,
                  columnTitle: (
                    <Tooltip key={ 'select_all' } title={ 'Select All' } placement={ 'left' }>
                      <Checkbox
                        indeterminate={ !_.isEmpty(selectedRowKeys) && flattenServices.length !== selectedRowKeys.length }
                        checked={ flattenServices.length === selectedRowKeys.length }
                        onChange={ () => {
                          this.setState({
                            selectedRowKeys: flattenServices.length !== selectedRowKeys.length ? flattenServices.map((flattenService: any) => flattenService.key) : []
                          });
                        } }
                      />
                    </Tooltip>
                  ),
                  checkStrictly: true,
                  onChange: selectedRowKeys => {
                    this.setState({
                      selectedRowKeys: selectedRowKeys
                    });
                  }
                } : undefined }
              />
            </div>
          </BlockingSpinner>
        </div>
        { scopeId &&
          <ScopeModal
            scopeId={ scopeId }
            clientId={ clientId }
            onClose={ () =>
              this.setState({
                isUpdating: true
              }, () => {
                history.push(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')));
              })
            }
          />
        }
      </div>
    );
  };
};

export default ServiceSpecificationScopeMatrix;
