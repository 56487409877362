// Libs
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Compoents
import { Menu, Dropdown } from 'antd';
import BlockingSpinner from 'components/blocking-spinner/BlockingSpinner';
import Chart from 'components/insight/Chart';
import ChartTable from 'components/insight/ChartTable';

// Icons
import { MoreOutlined, ReloadOutlined, FullscreenOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Utils
import history from 'utils/history';

// Styles
import './Widget.scss';

const API: Api = new Api();

const Widget = React.forwardRef(({style, className, key, children, ...restOfProps}: any, ref: any) => {

  const [insight, setInsight]: any = useState(null);
  const [isLoading, setIsLoading]: any = useState(true);

  useEffect(() => {
    fetchInsight(restOfProps.widget.config.report_id, restOfProps.filters);
  }, [restOfProps.widget.config.report_id, restOfProps.filters]);

  const fetchInsight = async (report_id: number, filters: any) => {
    try {

      let insight = null;

      setIsLoading(true);
      if (restOfProps?.record) {
        insight = await API.post(`client/${restOfProps.clientId}/${_.kebabCase(restOfProps.record.bundle)}/${_.kebabCase(restOfProps.record.type)}/${restOfProps.record.id}/dashboard/${restOfProps.dashboard.id}/report/${report_id}`, {
          filters: filters
        });
      } else {
        insight = await API.post(`client/${restOfProps.clientId}/insight/report/${report_id}`, {
          filters: filters
        });
      }
      setInsight(insight);

    } catch (error) {
      Notification('error', 'Failed to fetch widget', 'Failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ ...style }} className={["Widget-container", className].join(' ')} key={ key } { ..._.omit(restOfProps, 'onEdit', 'onDelete', 'onDuplicate', 'clientId', 'onToggleFullscreen', 'isFullScreenInsightLoading') } ref={ ref }>
      <div
        className={ classNames('Widget-header d-f jc-sb ai-c', {
          'cur-g': !restOfProps.readOnly
        } ) }
        onClick={ (event: React.BaseSyntheticEvent) => event.stopPropagation() }
      >
        <div className="d-if">
          <span>
            { restOfProps.widget.title }
          </span>
          { !restOfProps.readOnly &&
            <span className='Widget-hidden-action mL-5'>
              <EditOutlined onClick={ () => restOfProps.onEdit(restOfProps.widget.id) } />
            </span>
          }
        </div>
        <div className="d-if">
          <span className='Widget-hidden-action mR-5'>
            <ReloadOutlined onClick={ () => fetchInsight(restOfProps.widget.config.report_id, restOfProps.filters) }/>
          </span>
          <span className='Widget-hidden-action mR-5'>
            { restOfProps.isFullScreenInsightLoading ? (
              <LoadingOutlined className="fsz-def text-ant-default" />
            ) : (
              <FullscreenOutlined onClick={ () => restOfProps.onToggleFullscreen(restOfProps.widget.id) }/>
            ) }
          </span>
          { !restOfProps.readOnly &&
            <span>
              <Dropdown
                placement={ 'bottomRight' }
                overlay={ () => (
                  <Menu>
                    <Menu.Item key={ 'edit-report' } onClick={ () => history.push(`/insights/${restOfProps.widget.config.report_id}/edit`) }>
                      { 'Edit Report' }
                    </Menu.Item>
                    <Menu.Item key={ 'edit-widget' } onClick={ () => restOfProps.onEdit(restOfProps.widget.id) }>
                      { 'Edit Widget' }
                    </Menu.Item>
                    <Menu.Item key={ 'duplice' } onClick={ () => restOfProps.onDuplicate(restOfProps.widget.id) }>
                      { 'Duplicate' }
                    </Menu.Item>
                    <Menu.Item key={ 'delete' } onClick={ () => restOfProps.onDelete(restOfProps.widget.id) }>
                      { 'Delete' }
                    </Menu.Item>
                  </Menu>
                ) }
                trigger={ ['click'] }
              >
                <MoreOutlined className="fsz-def text-ant-default" />
              </Dropdown>
            </span>
          }
        </div>
      </div>
      <div id={ `widget_key_${restOfProps.widget?.key}` } className="Widget-body">
        { restOfProps?.widget?.config?.report_mode === 'chart' ? (
          <div className="Widget-chart">
            { isLoading ? (
              <BlockingSpinner isLoading />
            ) : (
              <Chart
                mode={ 'WIDGET' }
                insight={ insight }
              />
            ) }
          </div>
        ) : (
          <div>
            { restOfProps?.widget?.config?.report_mode === 'table' &&
              <ChartTable
                mode={ 'WIDGET' }
                insight={ insight }
                widget={ restOfProps.widget }
                isLoading={ isLoading }
              />
            }
          </div>
        ) }
      </div>
      { children }
    </div>
  );
});

export default Widget;
