// Libs
import React from 'react';

import moment from 'moment';
import { TimePicker, Input, Switch } from 'antd';

// Icons
import { SwapRightOutlined, ClockCircleOutlined } from '@ant-design/icons';

// Styles
import './TimeRangePicker.scss';

interface Props {
  values: string[],
  isDisabled: boolean,
  onChangeTime: (values: string[]) => void;
};

export default function TimeRangePickerComponent({ values, isDisabled, onChangeTime }: Props) {
  const isAllDay = values[0] === '00:00:00' && values[1] === '24:00:00';
  return (
    <div className='d-if ai-c' style={{ maxWidth: 300 }}>
      <div className='ant-input-group-addon' style={{ width: 150, padding: '4px 11px 4px', height: 32 }}>
        <span className="mR-5">{ 'All Day' }</span>
        <Switch
          size={ 'small' }
          disabled={ isDisabled }
          checked={ isAllDay }
          onChange={ (checked: boolean) => checked ? onChangeTime(['00:00:00', '24:00:00']) : onChangeTime(['00:00:00', '00:00:00']) }
        />
      </div>
      <div className='TimeRangePicker ant-picker ant-picker-range'>
        { isDisabled || isAllDay ? (
          <>
            <Input
              size='small'
              disabled
              bordered={ false }
              value={ moment(values[0], 'HH:mm').format('HH:mm') }
            />
            <SwapRightOutlined className='text-ant-disabled' style={{ fontSize: 16 }} />
            <Input
              size='small'
              disabled
              bordered={ false }
              suffix={ <ClockCircleOutlined /> }
              value={ values[1] === '24:00:00' ? '24:00' : moment(values[1], 'HH:mm').format('HH:mm') }
            />
          </>
        ) : (
          <>
            <span>
              <TimePicker
                format={ 'HH:mm' }
                allowClear={ false }
                bordered={ false }
                disabled={ isDisabled }
                value={ moment(values[0], 'HH:mm') }
                suffixIcon={ <></> }
                size='small'
                showNow={ false }
                popupClassName={ 'TimeRangePicker-Popup' }
                onChange={ (value: any) => {
                  values[0] = value.format('HH:mm:ss');
                  onChangeTime(values);
                } }
                onSelect={ (value: moment.Moment) => {
                  values[0] = value.format('HH:mm:ss');
                  onChangeTime(values);
                } }
              />
            </span>
            <span>
              <SwapRightOutlined className='text-ant-disabled' style={{ fontSize: 16 }} />
            </span>
            <span>
              <TimePicker
                format={ 'HH:mm' }
                allowClear={ false }
                bordered={ false }
                disabled={ isDisabled }
                value={ moment(values[1], 'HH:mm') }
                size='small'
                showNow={ false }
                popupClassName={ 'TimeRangePicker-Popup' }
                onChange={ (value: any) => {
                  values[1] = value.format('HH:mm:ss');
                  onChangeTime(values);
                } }
                onSelect={ (value: moment.Moment) => {
                  values[1] = value.format('HH:mm:ss');
                  onChangeTime(values);
                } }
              />
            </span>
          </>
        ) }
      </div>
    </div>
  );
};
