// Libs
import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import { Form, Input, Button } from 'antd';
import BlockingSpinner from 'components/blocking-spinner';

// Services
import { Api } from "services/api";
import Notification from 'services/notification';
import { validatePasswordStrength } from 'utils/utils';

const API: Api = new Api();

interface Props {
  history: Record<string, any>;
  location: {
    hash: string;
    pathname: string;
    search: string;
    state: Record<string, any>;
  };
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
};

interface State {
  error: string | null;
  isLoading: boolean;
  isResetting: boolean;
};

class ResetPassword extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    isLoading: false,
    isResetting: false,
    error: null,
  };

  componentDidMount = async () => {
    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));
      await API.get('validate_token_expiry', {
        email: this.props.match.params.email,
        token: this.props.match.params.token,
      });

    } catch (err: any) {
      this.setState({
        error: _.has(err, 'error.response.data') ? err.error.response.data : 'Something went wrong'
      });
    } finally {
      this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleResetPassword = (values: any) => {
    const {
      history,
      match,
    } = this.props;

    const email = match.params.email;
    const token = match.params.token;
    const { password } = values;

    this.setState(
      {
        isResetting: true,
      }, () => {

        API.updatePasswordWithToken(token, email, password)
          .then((response: any) => {
            Notification('success', `Your password has been changed.`, 'Request successful');
            history.push('/login');
          })
          .catch((err: any) => {
            Notification('error', 'Something went wrong', 'Failed');
            console.error(err);
          })
          .finally(() => this.mounted && this.setState({
            isResetting: false
          }));

      }
    );
  };

  render = () => {
    const { error, isLoading } = this.state;
    return (
      <>
        <div className="ta-c mB-40">
          <h1 className="ta-c">Reset password</h1>
        </div>
        <BlockingSpinner isLoading={ isLoading }>
          { error ? (
            <>
              <div className="ta-c">
                <p>{ error }</p>
                <p className="mT-20">
                  <span>Please </span> <a href="/request-token">try again</a>
                </p>
              </div>
            </>
          ) : (
            <Form
              layout="vertical"
              onFinish={ this.handleResetPassword }
            >
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  () => ({
                    validator(__, password) {
                      const errors = validatePasswordStrength(password);
                      if (_.isEmpty(errors)) {
                        return Promise.resolve();
                      }

                      return Promise.reject(errors);
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords do not match!');
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <div className="ta-c">
                  <Button
                    block
                    type="primary"
                    className="login-form-button"
                    htmlType="submit"
                    disabled={ this.state.isResetting }
                    loading={ this.state.isResetting }
                  >
                    Change Password
                  </Button>
                </div>
              </Form.Item>
              <Form.Item>
                <div className="ta-c">
                  <span>Already got an account?</span> <a href="/login">Login here</a>
                </div>
              </Form.Item>
            </Form>
          ) }
        </BlockingSpinner>
      </>
    );
  };
};

export default withRouter(ResetPassword);