// Libs
import React, { Component } from 'react';
import _ from 'lodash';

// Components
import Upload from 'components/upload';
import PreviewModal from 'components/preview-modal';

// Interfaces
import { DynamicField } from 'components/form/field/dynamic/Dynamic.interface';

interface Props {
  clientId: number;
  dynamicField: DynamicField;
  extensions: string[];
  isLocked: boolean;
  isPreviewing: boolean;
  onUpload(file: any, callback?: () => void): void;
  onRemove(file: any): void;
};

interface State {
  preview_id: number | null;
  isDownloading: boolean;
};

class Attachments extends Component<Props, State> {

  state: State = {
    preview_id: null,
    isDownloading: false,
  };

  renderPreview = (file_id: number) => {
    const { dynamicField, clientId } = this.props;
    const files: any[] = _.has(dynamicField, 'attachments.files') ? dynamicField.attachments.files : [];
    const fileRecord: any = files.find((file: any) => file.file_id === file_id);
    return (
      <PreviewModal
        clientId={ clientId }
        fileRecord={ fileRecord }
        files={ files }
        onClose={ () => {
          this.setState({
            preview_id: null
          });
        }}
      />
    );
  };

  render = () => {
    const { clientId, dynamicField, isLocked, extensions, onUpload, onRemove } = this.props;
    const { preview_id } = this.state;
    const allowedExtensions = !_.isEmpty(extensions) ? extensions.map(extension => '.' + extension).join(', ') : false;
    const maxFileSize = ((dynamicField?.config?.max_file_size || 0) / 1024 / 1024) || 50;

    return (
      <div>
        <div className="fw-500">
          Attachments
        </div>
        <Upload
          clientId={ clientId }
          classes={ 'File-Field' }
          uploadEndpoint={ `client/${clientId}/field/dynamic/upload` }
          allowedExtensions={ allowedExtensions }
          onRemove={ (file: any) => onRemove(file) }
          onPreview={ (file: any) => this.setState({
            preview_id: file.uid
          }) }
          multiple
          isDisabled={ isLocked }
          files={ _.has(dynamicField, 'attachments.files') ? dynamicField.attachments.files : [] }
          onUpload={ (fileRecord: any, callback?: () => void) => {
            onUpload(fileRecord, callback);
          } }
          maxFileSize={ maxFileSize }
        />
        { preview_id && this.renderPreview(preview_id) }
      </div>
    );
  };
};

export default Attachments;
