// Libs
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

// Containers
import AssetContainer from 'containers/workplace-service/asset/AssetContainer';

// Views
import Assets from 'views/workplace-service/Assets';
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

class AssetsContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Route exact path="/workplace-services/assets" component={ Assets } />

        <Route path="/workplace-services/assets/:asset_id" component={ AssetContainer } />

        <Route render={() => <NotFound />} />

      </Switch>
    );
  };
};

export default AssetsContainer;
