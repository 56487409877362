// Libs
import React, { BaseSyntheticEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

// Components
import { Modal, Tabs, Table, Select, Input, Tooltip, Typography, DatePicker, Button, TreeSelect } from 'antd';
import { regionMap } from 'components/resources-table';
import BlockingSpinner from 'components/blocking-spinner/BlockingSpinner';

// Icons
import { ArrowRightOutlined } from '@ant-design/icons';

// Interfaces
import { FormField } from 'components/form/form-wrapper';
import { RecordFormEntity } from 'types/entities';

// Services
import { Api } from 'services/api';
import { getUserSetting } from 'services/settings';
import { nestedSet } from 'utils/utils';

interface Props {
  record: RecordFormEntity;
  field: any;
  clientId: number;
  onSave(field: FormField): void;
  onClose(): void;
};

interface State {
  activeTab: string;
  field: any;
  activeRowKey: string | null,
  locationFilter: string | null,
  typeFilter: string | null,
  ownershipFilter: string | null,
  propertyTypeFilter: any[],
  search: string | null,
  selectedRegions: any,
  selectedServiceTypes: any,
  selectedServiceSpecifications: any[],
  availableRegions: any[],
  availableServiceTypes: any[],
  availableServiceSpecifications: any[],
  isFetchingRegions: boolean,
  isFetchingServiceTypes: boolean,
  isFetchingServiceSpecification: boolean,
};

const API: Api = new Api();
const { TabPane } = Tabs;
const { Option } = Select;
const { Search } = Input;
const { Link } = Typography;

const getSelectedRegions = (field: any) => {
  const selectedRegions: any[] = [];

  field.values.forEach((serviceSpecification: any) => {
    serviceSpecification.properties.forEach((property: any) => {
      property.selected_regions.forEach((region_id: number) => {
        selectedRegions.push(region_id);
      });
    });
  });

  return _.union(selectedRegions);
};

const getSelectedServiceTypes = (field: any) => {
  const selectedServiceTypes: any[] = [];

  field.values
    .filter((serviceSpecification: any) => _.has(serviceSpecification, 'specification_type') && !!serviceSpecification.specification_type)
    .forEach((serviceSpecification: any) => {
        selectedServiceTypes.push(serviceSpecification.specification_type.id);
    });

  return _.union(selectedServiceTypes);
};

const getSelectedServiceSpecifications = (field: any) => {
  return field.values.map((serviceSpecification: any) => `${serviceSpecification.target_bundle}-${serviceSpecification.target_type}-${serviceSpecification.target_id}`);
};

class SpecificationSelectionModal extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    activeTab: 'regions',
    field: _.cloneDeep(this.props.field),
    activeRowKey: null,
    locationFilter: null,
    typeFilter: null,
    ownershipFilter: null,
    propertyTypeFilter: [],
    search: null,
    selectedRegions: getSelectedRegions(this.props.field),
    selectedServiceTypes: getSelectedServiceTypes(this.props.field),
    selectedServiceSpecifications: getSelectedServiceSpecifications(this.props.field),
    availableRegions: [],
    availableServiceTypes: [],
    availableServiceSpecifications: [],
    isFetchingRegions: false,
    isFetchingServiceTypes: false,
    isFetchingServiceSpecification: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getRegions();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { activeTab, selectedRegions, selectedServiceTypes, selectedServiceSpecifications, availableServiceSpecifications } = this.state;

    if (prevState.activeTab !== activeTab) {
      switch (activeTab) {
        case 'regions':
          this.getRegions();
        break;
        case 'serviceTypes':
          this.getServiceTypes(selectedRegions);
        break;
        case 'serviceSpecifications':
          this.getServiceSpecifications(selectedRegions, selectedServiceTypes);
        break;
      }
    }

    if (prevState.selectedServiceSpecifications !== selectedServiceSpecifications) {
      this.updateFieldValues(selectedServiceSpecifications, availableServiceSpecifications);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleChange = (field: any) => {
    this.setState({
      field: field
    });
  };

  updateFieldValues = (selectedServiceSpecifications: any, availableServiceSpecifications: any) => {
    const serviceSpecifications = selectedServiceSpecifications
      .map((selectedServiceSpecification: any) => {
        return availableServiceSpecifications.find((availableServiceSpecification: any) => `${availableServiceSpecification.target_bundle}-${availableServiceSpecification.target_type}-${availableServiceSpecification.target_id}` === selectedServiceSpecification);
      })
      .filter((serviceSpecification: any) => !!serviceSpecification);
    this.setState({
      field: _.set(this.state.field, ['values'], serviceSpecifications),
    });
  };

  canSelectMultiple = () => {
    return this.props.field.config.cardinality !== 1;
  };

  locationFilter = (serviceSpecifications: any[], value: any) => {
    return serviceSpecifications
      .filter((serviceSpecification: any) => {
        if (_.has(serviceSpecification, 'properties')) {
          return serviceSpecification.properties.some((property: any) => {
            return _.has(property, 'location.title') && property.location.title === value;
          });
        }
        return false;
      });
  };

  typeFilter = (serviceSpecifications: any[], value: any) => {
    return serviceSpecifications.filter((serviceSpecification: any) => _.has(serviceSpecification, 'specification_type') && !!serviceSpecification.specification_type && serviceSpecification.specification_type.title.toLowerCase().includes(value.toLocaleLowerCase()));
  };

  ownershipFilter = (serviceSpecifications: any[], value: any) => {
    return serviceSpecifications.filter((serviceSpecification: any) => _.has(serviceSpecification, 'properties') && !!serviceSpecification.properties && serviceSpecification.properties.some((property: any) => property.type === value));
  };

  propertyTypeFilter = (serviceSpecifications: any[], value: any) => {
    return serviceSpecifications.filter((serviceSpecification: any) => _.has(serviceSpecification, 'properties') && !!serviceSpecification.properties && serviceSpecification.properties.some((property: any) => !!property.property_type && property.property_type.find((type: any) => value.includes(type.target_id))));
  };

  search = (serviceSpecifications: any[], value: any) => {
    return serviceSpecifications.filter((serviceSpecification: any) => serviceSpecification.title.toLowerCase().includes(value.toLocaleLowerCase()));
  };

  getRegions = async () => {
    try {
      await new Promise((resolve) => this.setState({ isFetchingRegions: true }, () => resolve(null)));

      const availableRegions = await API.get(`client/${this.props.clientId}/field/specification_relationship/parent_service_specification/regions`);

      this.mounted && this.setState({
        availableRegions: availableRegions
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetchingRegions: false,
      });
    }
  };

  getServiceTypes = async (selectedRegions: any[]) => {
    try {
      await new Promise((resolve) => this.setState({ isFetchingServiceTypes: true }, () => resolve(null)));

      const availableServiceTypes = await API.get(`client/${this.props.clientId}/field/specification_relationship/parent_service_specification/types`, {
        regions: selectedRegions
      });

      this.mounted && this.setState({
        availableServiceTypes: availableServiceTypes
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetchingServiceTypes: false,
      });
    }
  };

  getServiceSpecifications = async (selectedRegions: any[], selectedServiceTypes: any[]) => {
    const { record } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isFetchingServiceSpecification: true }, () => resolve(null)));

      const availableServiceSpecifications = await API.get(`client/${this.props.clientId}/field/specification_relationship/parent_service_specification/specifications`, {
        entity_id: record.id,
        entity_type: record.type,
        entity_bundle: record.bundle,
        regions: selectedRegions,
        types: selectedServiceTypes
      });

      // The start and end date can be changed, so make sure those changes won't get lost.
      const manipulatedAvailableServiceSpecifications = availableServiceSpecifications
        .map((serviceSpecification: any) => {
          const value = this.state.field.values.find((value: any) => `${value.target_bundle}-${value.target_type}-${value.target_id}` === `${serviceSpecification.target_bundle}-${serviceSpecification.target_type}-${serviceSpecification.target_id}`);
          if (value) {
            return {
              ...serviceSpecification,
              start_date: value.start_date,
              end_date: value.end_date,
            };
          }
          return serviceSpecification;
        });

      this.mounted && this.setState({
        availableServiceSpecifications: manipulatedAvailableServiceSpecifications
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetchingServiceSpecification: false,
      });
    }
  };

  renderDetailModal = (activeRowKey: string, serviceSpecifications: any) => {

    const serviceSpecification = serviceSpecifications.find((serviceSpecification: any) => serviceSpecification.key === activeRowKey);
    let properties = [];

    if (serviceSpecification && _.has(serviceSpecification, 'properties')) {
      properties = serviceSpecification.properties.map((property: any) => {
        return {
          'key': `${property.bundle}-${property.type}-${property.id}`,
          ...property,
        };
      });
    }

    return (
      <Modal
        centered
        visible
        title={ 'Properties' }
        onCancel={ () => this.setState({ activeRowKey: null }) }
        cancelText={ 'Close' }
        style={{ minWidth: 1000 }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Table
          className="ov-a"
          style={{ height: 450 }}
          size={ 'small' }
          columns={ [
            {
              key: 'title',
              dataIndex: 'title',
              title: 'Property',
              width: 300,
              render: (__: any, property: any) => {
                return (
                  <RouterLink className='primaryColor' to={ property.path }>
                    { property.title }
                  </RouterLink>
                );
              },
              sorter: false,
              ellipsis: true,
            },
            {
              title: <span>Ownership</span>,
              width: 140,
              render: (__: any, property: any) => {
                if (!_.has(property, 'ownership') || _.isEmpty(property.ownership)) return <>-</>;
                return (
                  <span>
                    { property.ownership }
                  </span>
                );
              }
            },
            {
              title: <span>Location</span>,
              width: 180,
              render: (__: any, property: any) => {
                if (!_.has(property, 'location') || _.isEmpty(property.location)) return <>-</>;
                return (
                  <RouterLink className='primaryColor' to={ `${property.location.path}` }>
                    { property.location.title }
                  </RouterLink>
                );
              }
            },
            {
              title: <span>Region</span>,
              render: (__: any, property: any) => {
                if (!_.has(property, 'region_trees') || _.isEmpty(property.region_trees)) return <>-</>;
                return (
                  property.region_trees.map((regions: any, index: number) => (
                    <div key={ index }>
                      { regions.map((region: any, _index: number) => (
                        <span key={ _index }>
                          { regions.length === _index + 1 ?
                            (
                              <span>{ region.title }</span>
                            ) : (
                              <span><span>{ region.title }</span><ArrowRightOutlined className="mL-10 mR-10" style={{ fontSize: 10 }} /></span>
                            )
                          }
                        </span>
                      ) ) }
                    </div>
                  ) )
                );
              }
            }
          ] }
          dataSource={ properties }
          pagination={ false }
          expandable={{
            defaultExpandAllRows: true
          }}
        />
      </Modal>
    );
  };

  renderRegionTab = () => {
    const { availableRegions, isFetchingRegions, selectedRegions } = this.state;

    if (isFetchingRegions || _.isEmpty(availableRegions)) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    return (
      <Table
        className="ov-s"
        size={ 'small' }
        style={{ height: 450 }}
        bordered
        showHeader={ false }
        columns={ [
          {
            render: (region: any) => <span>{ region.title }</span>
          }
        ] }
        dataSource={ !_.isEmpty(availableRegions) ? regionMap(availableRegions) : [] }
        pagination={ false }
        expandable={{
          defaultExpandAllRows: true
        }}
        rowSelection={{
          checkStrictly: false,
          selectedRowKeys: selectedRegions,
          onChange: (selectedRowKeys: any, _selectedRegions: any) => {
            this.setState({ selectedRegions: selectedRowKeys });
          }
        }}
      />
    );
  };

  renderServiceTypeTab = () => {
    const { availableServiceTypes, isFetchingServiceTypes, selectedServiceTypes } = this.state;

    if (isFetchingServiceTypes || _.isEmpty(availableServiceTypes)) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const serviceTypes = availableServiceTypes.map((serviceType: any) => {
      return {
        key: serviceType.id,
        title: serviceType.title,
        ...serviceType
      };
    });

    return (
      <Table
        className="ov-s"
        size={ 'small' }
        style={{ height: 450 }}
        bordered
        columns={ [
          {
            title: 'Specification Type',
            render: (serviceType: any) => <span>{ serviceType.title }</span>
          }
        ] }
        dataSource={ !_.isEmpty(serviceTypes) ? serviceTypes : [] }
        pagination={ false }
        rowSelection={{
          hideSelectAll: true,
          selectedRowKeys: selectedServiceTypes,
          onSelect: (serviceType: any) => {
            if (selectedServiceTypes.includes(serviceType.key)) {
              this.setState({ selectedServiceTypes: selectedServiceTypes.filter((_serviceType: any) => _serviceType !== serviceType.key) });
            } else {
              this.setState({ selectedServiceTypes: selectedServiceTypes.concat([serviceType.key]) });
            }
          }
        }}
      />
    );
  };

  renderServiceSpecificationTab = () => {
    const {
      availableServiceSpecifications,
      isFetchingServiceSpecification,
      selectedServiceSpecifications,
      locationFilter,
      typeFilter,
      ownershipFilter,
      propertyTypeFilter,
      search,
      activeRowKey,
    } = this.state;

    const { field } = this.props;

    if (isFetchingServiceSpecification) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    let serviceSpecifications = availableServiceSpecifications.map((serviceSpecification: any) => {
      return {
        key: `${serviceSpecification.target_bundle}-${serviceSpecification.target_type}-${serviceSpecification.target_id}`,
        title: serviceSpecification.target_title,
        ...serviceSpecification
      };
    });

    const locations = (__: any = availableServiceSpecifications) => {
      const collector: any[] = [];

      if (!_.isEmpty(availableServiceSpecifications)) {
        availableServiceSpecifications.forEach((availableServiceSpecification: any) => {
          if (_.has(availableServiceSpecification, 'properties')) {
            availableServiceSpecification.properties.forEach((property: any) => {
              if (_.has(property, 'location') && !!property.location) {
                collector.push(property.location);
              }
            });
          }
        });
      }

      return _.uniqBy(collector, 'id');
    };

    const types: any[] = availableServiceSpecifications
      .filter((serviceSpecification: any) => _.has(serviceSpecification, 'specification_type') && !!serviceSpecification.specification_type)
      .map((serviceSpecification: any) => serviceSpecification.specification_type)
      .reduce((acc, cur) => [
        ...acc.filter((type: any) => type.id !== cur.id), cur
      ], []);

    if (!!locationFilter) {
      serviceSpecifications = this.locationFilter(serviceSpecifications, locationFilter);
    }

    if (!!typeFilter) {
      serviceSpecifications = this.typeFilter(serviceSpecifications, typeFilter);
    }

    if (!!ownershipFilter) {
      serviceSpecifications = this.ownershipFilter(serviceSpecifications, ownershipFilter);
    }

    if (!_.isEmpty(propertyTypeFilter)) {
      serviceSpecifications = this.propertyTypeFilter(serviceSpecifications, propertyTypeFilter);
    }

    if (!!search) {
      serviceSpecifications = this.search(serviceSpecifications, search);
    }

    return (
      <>
        <div className="d-f">
          <Search
            className="d-if"
            disabled={ !search && _.isEmpty(serviceSpecifications) }
            style={{ width: 350 }}
            placeholder="Search For Service Specification"
            onSearch={ (value: string) => this.setState({ search: value }) }
            onBlur={ (event: BaseSyntheticEvent) => this.setState({ search: event.target.value || null }) }
          />
          <Select
            className="d-if mL-10"
            style={{ width: 250 }}
            allowClear
            showSearch
            onSelect={ (title: string) => {
              this.setState({ locationFilter: title });
            } }
            onClear={ () => {
              this.setState({ locationFilter: null });
            } }
            placeholder={ 'Filter by Location' }
            disabled={ _.isEmpty(locations()) }
            filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
            // @ts-ignore
            autoComplete="none"
          >
            { locations().map((location: any) => {
              return (
                <Option
                  key={ location.id }
                  value={ location.title }
                >
                  { location.title }
                </Option>
              );
            }) }
          </Select>
          <Select
            className="d-if mL-10"
            style={{ width: 250 }}
            allowClear
            showSearch
            onSelect={ (title: string) => {
              this.setState({ typeFilter: title });
            } }
            onClear={ () => {
              this.setState({ typeFilter: null });
            } }
            placeholder={ 'Filter by Type' }
            disabled={ _.isEmpty(types) }
            filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
            // @ts-ignore
            autoComplete="none"
          >
            { types.map((type: any) => {
              return (
                <Option
                  key={ type.id }
                  value={ type.title }
                >
                  { type.title }
                </Option>
              );
            }) }
          </Select>
          <Select
            className="d-if mL-10"
            style={{ width: 250 }}
            allowClear
            showSearch
            onSelect={ (ownership: string) => {
              this.setState({ ownershipFilter: ownership });
            } }
            onClear={ () => {
              this.setState({ ownershipFilter: null });
            } }
            placeholder={ 'Filter by Ownership' }
            filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
            // @ts-ignore
            autoComplete="none"
          >
            { ['owned', 'lease'].map((ownership: any) => {
              return (
                <Option
                  key={ ownership }
                  value={ ownership }
                >
                  { _.startCase(ownership) }
                </Option>
              );
            }) }
          </Select>
          <TreeSelect
            className="mL-10"
            style={{ width: 250 }}
            allowClear
            showSearch
            multiple
            dropdownMatchSelectWidth={ false }
            placeholder={ 'Filter By Property Type' }
            showCheckedStrategy={ TreeSelect.SHOW_ALL }
            maxTagCount={ 2 }
            value={ propertyTypeFilter }
            treeData={ nestedSet(field.property_types) }
            filterTreeNode={ (input: string, option: any) => {
              if (option) {
                const filteredInput = input.toLocaleLowerCase();
                const title = option.title && option.title.toLowerCase();

                if (title.includes(filteredInput)) {
                  return true;
                }
              }

              return false;
            } }
            onChange={(value: any[]) => {
              this.setState({ propertyTypeFilter: value });
            }}
          />
        </div>
        <Table
          className="ov-s mT-10"
          size={ 'small' }
          style={{ height: 408 }}
          bordered
          columns={ [
            {
              title: 'Service Specification',
              width: 300,
              render: (serviceSpecification: any) => <span>{ serviceSpecification.title }</span>,
            },
            {
              title: <span>Properties</span>,
              width: 180,
              render: (row: any) => {
                const properties = _.has(row, 'properties') ? row.properties : [];
                if (_.isEmpty(properties)) {
                  return (
                    <span>-</span>
                  );
                } else if (properties.length === 1) {
                  return (
                    <RouterLink className='primaryColor' to={ properties[0]['path'] }>
                      { properties[0].title }
                    </RouterLink>
                  );
                } else {
                  return (
                    <Link onClick={ () => this.setState({ activeRowKey: row.key }) }>
                      <span>
                        { properties.length }
                      </span>
                    </Link>
                  );
                }
              }
            },
            {
              title: 'Ownership',
              width: 180,
              render: (row: any) => {
                const properties = _.has(row, 'properties') ? row.properties : [];
                const ownership: any[] = row.properties
                  .filter((property: any) => _.has(property, 'ownership') && !!property.ownership)
                  .map((property: any) => property.ownership)
                  .reduce((acc: any, cur: any) => [
                    ...acc.filter((ownership: any) => ownership !== cur), cur
                  ], []);

                if (_.isEmpty(properties) || _.isEmpty(ownership)) {
                  return (
                    <span>-</span>
                  );
                } else if (ownership.length === 1) {
                  return (
                    <span>
                      { ownership }
                    </span>
                  );
                } else {
                  return (
                    <>
                      <Link onClick={ () => this.setState({ activeRowKey: row.key }) }>
                        { ownership.length }
                      </Link>
                    </>
                  );
                }
              }
            },
            {
              title: 'Location',
              width: 180,
              render: (row: any) => {
                const properties = _.has(row, 'properties') ? row.properties : [];
                const locations: any[] = row.properties
                  .filter((property: any) => _.has(property, 'location') && !!property.location)
                  .map((property: any) => property.location)
                  .reduce((acc: any, cur: any) => [
                    ...acc.filter((location: any) => location.id !== cur.id), cur
                  ], []);

                if (_.isEmpty(properties) || _.isEmpty(locations)) {
                  return (
                    <span>-</span>
                  );
                } else if (locations.length === 1) {
                  return (
                    <RouterLink className='primaryColor' to={ locations[0].path }>
                      { locations[0].title }
                    </RouterLink>
                  );
                } else {
                  return (
                    <>
                      <Link onClick={ () => this.setState({ activeRowKey: row.key }) }>
                        { locations.length }
                      </Link>
                    </>
                  );
                }
              }
            },
            {
              title: 'Specification Type',
              width: 180,
              render: (serviceSpecification: any) => {
                if (!_.has(serviceSpecification, 'specification_type.title')) return <span>-</span>;
                return (
                  <span>{ serviceSpecification.specification_type.title }</span>
                );
              }
            },
            {
              title: <span>Commencement Date</span>,
              width: 180,
              render: (row: any) => {
                return (
                  <DatePicker
                    format={ getUserSetting('date_format') }
                    defaultValue={ moment(row.start_date).isValid() ? moment(row.start_date) : undefined }
                    onChange={ (dateString: any) => {
                      const newAvailableServiceSpecifications = availableServiceSpecifications
                        .map((serviceSpecification: any) => {
                          if (`${serviceSpecification.target_bundle}-${serviceSpecification.target_type}-${serviceSpecification.target_id}` === `${row.target_bundle}-${row.target_type}-${row.target_id}`) {
                            return {
                              ...serviceSpecification,
                              start_date: !!dateString ? dateString.format('YYYY-MM-DD') : null,
                            };
                          }
                          return serviceSpecification;
                        });
                      this.setState({
                        availableServiceSpecifications: newAvailableServiceSpecifications
                      }, () => {
                        this.updateFieldValues(selectedServiceSpecifications, newAvailableServiceSpecifications);
                      });
                    } }
                  />
                );
              }
            },
            {
              title: <span>Expiry Date</span>,
              width: 180,
              render: (row: any) => {
                return (
                  <DatePicker
                    format={ getUserSetting('date_format') }
                    defaultValue={ moment(row.end_date).isValid() ? moment(row.end_date) : undefined }
                    onChange={ (dateString: any) => {
                      const newAvailableServiceSpecifications = availableServiceSpecifications
                        .map((serviceSpecification: any) => {
                          if (`${serviceSpecification.target_bundle}-${serviceSpecification.target_type}-${serviceSpecification.target_id}` === `${row.target_bundle}-${row.target_type}-${row.target_id}`) {
                            return {
                              ...serviceSpecification,
                              end_date: !!dateString ? dateString.format('YYYY-MM-DD') : null,
                            };
                          }
                          return serviceSpecification;
                        });
                      this.setState({
                        availableServiceSpecifications: newAvailableServiceSpecifications
                      }, () => {
                        this.updateFieldValues(selectedServiceSpecifications, newAvailableServiceSpecifications);
                      });
                    } }
                  />
                );
              }
            }
          ] }
          dataSource={ serviceSpecifications || [] }
          pagination={ false }
          rowSelection={{
            hideSelectAll: !this.canSelectMultiple(),
            selectedRowKeys: selectedServiceSpecifications,
            renderCell: (checked: boolean, record: any, index: number, originNode: any) => {

              if (!this.canSelectMultiple() && !_.isEmpty(selectedServiceSpecifications) && !selectedServiceSpecifications.includes(record.key)) {
                return (
                  <Tooltip key={ index } title={ 'Can only select one Service' } placement={ 'top' }>
                    { React.cloneElement((originNode), { disabled: true }) }
                  </Tooltip>
                );
              }

              return originNode;
            },
            onSelectAll: (selected: boolean, selectedRows: any, changeRows: any) => {
              if (selected) {
                // Select everything in the filtered list
                const newSelectedServiceSpecifications = selectedServiceSpecifications.concat(selectedRows.filter((selectedRow: any) => !!selectedRow).map((selectedRow: any) => selectedRow.key));
                this.setState({
                  selectedServiceSpecifications: _.uniq(newSelectedServiceSpecifications)
                });
              } else {
                // Remove everything from the filtered list. Keep everything that's not visible due to filter.
                const keys = changeRows.map((serviceSpecification: any) => serviceSpecification.key);
                this.setState({ selectedServiceSpecifications: selectedServiceSpecifications.filter((_selectedServiceSpecification: any) => !keys.includes(_selectedServiceSpecification)) });
              }
            },
            onSelect: (serviceSpecification: any) => {
              if (selectedServiceSpecifications.includes(serviceSpecification.key)) {
                this.setState({ selectedServiceSpecifications: selectedServiceSpecifications.filter((_serviceSpecification: any) => _serviceSpecification !== serviceSpecification.key) });
              } else {
                if (!this.canSelectMultiple()) {
                  this.setState({ selectedServiceSpecifications: [serviceSpecification.key] });
                } else {
                  this.setState({ selectedServiceSpecifications: selectedServiceSpecifications.concat([serviceSpecification.key]) });
                }
              }
            }
          }}
        />
        { activeRowKey !== null && this.renderDetailModal(activeRowKey, serviceSpecifications) }
      </>
    );
  };

  render = () => {
    const { field, activeTab, selectedServiceSpecifications, isFetchingServiceSpecification } = this.state;
    const footer = (
      <div className='d-f jc-sb ai-c'>
        <div>
          { activeTab === 'serviceSpecifications' && this.canSelectMultiple() && !isFetchingServiceSpecification &&
            <span>{`Selected ${selectedServiceSpecifications.length} item`}{ `${ selectedServiceSpecifications.length > 1 ? 's' : '' }` }</span>
          }
        </div>
        <div>
          <Button
            key={ 'cancel' }
            onClick={ () => this.props.onClose() }
          >
            Cancel
          </Button>
          <Button
            key={ 'ok' }
            disabled={ _.isEqual(field.values, this.props.field.values) }
            onClick={ () => this.props.onSave(field) }
          >
            Ok
          </Button>
        </div>
      </div>
    );

    return (
      <Modal
        centered
        closable={ false }
        visible
        title={ 'Service Specification Picker' }
        footer={ footer }
        style={{ minWidth: '90%' }}
      >
        <Tabs
          centered
          activeKey={ activeTab }
          onTabClick={ (tab: string) => this.setState({ activeTab: tab }) }
        >
          <TabPane tab="Regions" key="regions">
            { this.renderRegionTab() }
          </TabPane>
          <TabPane tab="Specification Types" key="serviceTypes">
            { this.renderServiceTypeTab() }
          </TabPane>
          <TabPane tab="Service Specifications" key="serviceSpecifications">
            { this.renderServiceSpecificationTab() }
          </TabPane>
        </Tabs>
      </Modal>
    );
  };
};

export default SpecificationSelectionModal;
