// Libs
import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Components
import RecordView from 'views/common/RecordView';
import CoverModal from 'components/cover-modal';

// Interfaces
import { RecordFormEntity } from 'types/entities';

// Utils
import history from 'utils/history';

interface Props {
  record: RecordFormEntity;
  setRecord: (record: RecordFormEntity) => void;
  onRefreshForm: () => void;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
};

interface State {
  isModified: boolean;
};

class ProjectRecord extends React.Component<RouteComponentProps<{}> & Props, State> {
  state: State = {
    // the property 'isModified' needed to determine if the form of the activity (inside the modal) has been submitted
    isModified: false,
  };

  renderRecordView = () => {
    const { record, setRecord } = this.props;

    return (
      <RecordView
        verboseTabs
        id={ record.id }
        entity={ 'record' }
        type={ 'project' }
        record={ record }
        setRecord={ setRecord }
      />
    );
  };

  renderActivityModal = (activityId: number) => {
    const { onRefreshForm } = this.props;
    const { isModified } = this.state;

    const modalTitle = 'Project Activity';

    const onClose = () => {
      // refresh the record form if the activity form has been submitted
      if (isModified) {
        this.setState({ isModified: false }, onRefreshForm);
      }
      history.push(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')));
    };

    return (
      <CoverModal style={{ width: '90%', height: '90%' }} middleContent={ modalTitle } onClose={ onClose }>
        <RecordView
          pure
          id={ activityId }
          type={ 'project-activity' }
          entity={ 'activity' }
          onSaveHandle={ () => {
            // if the form is submitted, the isModified property is set to true
            this.setState({ isModified: true });
          } }
        />
      </CoverModal>
    );
  };

  render = () => {
    const activityId = this.props.match?.params.activity_id;

    return (
      <>
        { this.renderRecordView() }
        { !!activityId && this.renderActivityModal(+activityId) }
      </>
    );
  };
};

export default withRouter(ProjectRecord);
