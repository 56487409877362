// Libs
import { Dispatch } from 'redux';

// Types
import NotificationActionTypes from './ActionTypes.enum';

// Interfaces
import AppState from 'store/AppState.interface';
import {
  GetNotificationsStartAction,
  GetNotificationsSuccessAction,
  GetNotificationsFailedAction,
  GetNotificationsCountStartAction,
  GetNotificationsCountSuccessAction,
  GetNotificationsCountFailedAction,
  ReadNotificationSuccessAction,
  DeleteNotificationsStartAction,
  DeleteNotificationsSuccessAction,
  DeleteNotificationsFailedAction,
  PollNotificationsStartAction,
  PollNotificationsEndAction,
} from "./Actions.interface";

// Store
import store from 'store/Store';

// Services
import { api } from 'store/Store';
import Console from 'utils/console';

export const NOTIFICATION_LOAD_COUNT: number = 50;

export const getNotificationsStart = (): GetNotificationsStartAction => ({
  type: NotificationActionTypes.GET_NOTIFICATIONS_START,
});

export const getNotificationsSuccess = (notification: any): GetNotificationsSuccessAction => ({
  type: NotificationActionTypes.GET_NOTIFICATIONS_SUCCESS,
  payload: notification,
});

export const getNotificationsFailure = (error: string): GetNotificationsFailedAction => ({
  type: NotificationActionTypes.GET_NOTIFICATIONS_FAILED,
  payload: { error: error },
});

export const getNotificationsCountStart = (): GetNotificationsCountStartAction => ({
  type: NotificationActionTypes.GET_NOTIFICATIONS_COUNT_START,
});

export const getNotificationsCountSuccess = (
  unread_count: number
): GetNotificationsCountSuccessAction => ({
  type: NotificationActionTypes.GET_NOTIFICATIONS_COUNT_SUCCESS,
  payload: {
    unread_total: unread_count
  },
});

export const getNotificationsCountFailure = (
  error: string
): GetNotificationsCountFailedAction => ({
  type: NotificationActionTypes.GET_NOTIFICATIONS_COUNT_FAILED,
  payload: { error: error },
});

export const readNotificationSuccess = (notification: any): ReadNotificationSuccessAction => ({
  type: NotificationActionTypes.READ_NOTIFICATION_SUCCESS,
  payload: notification,
});

export const deleteNotificationsStart = (): DeleteNotificationsStartAction => ({
  type: NotificationActionTypes.DELETE_NOTIFICATIONS_START,
});

export const deleteNotificationsSuccess = (notification: any): DeleteNotificationsSuccessAction => ({
  type: NotificationActionTypes.DELETE_NOTIFICATIONS_SUCCESS,
  payload: notification,
});

export const deleteNotificationsFailure = (error: string): DeleteNotificationsFailedAction => ({
  type: NotificationActionTypes.DELETE_NOTIFICATIONS_FAILED,
  payload: { error: error },
});

export function getNotifications(items_per_page = NOTIFICATION_LOAD_COUNT) {
  return (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {

      const appState: AppState = store.getState();
      const user_id = appState.UserState.user.id;
      const client_id = appState.UserState.user.active_client;

      dispatch(getNotificationsStart());

      api.instance.get(`client/${client_id}/user/${user_id}/notification`, {
        params: {
          items_per_page: items_per_page
        }
      })
      .then((response) => {
        dispatch(getNotificationsSuccess(response.data));
        resolve(null);
      })
      .catch((error) => {
        dispatch(getNotificationsFailure(error));
        reject(error);
      });

    });
  };
};

export function readNotification(notification_id: number) {
  return async (dispatch: (action: Record<string, any>) => void) => {

    const appState: AppState = store.getState();
    const notificationState = appState.NotificationState;

    const unreadtotal = notificationState.notifications
      .find((notification: any) => notification.id === notification_id)?.read ? notificationState.unread_total : notificationState.unread_total - 1;
    const notifications = notificationState.notifications
      .map((notification: any) => notification.id === notification_id ? { ...notification, read: 1 } : notification);

    const newState = {
      ...notificationState,
      unread_total: unreadtotal,
      notifications: notifications,
    };

    dispatch(readNotificationSuccess(newState));
  };
};

export function markNotificationsAsRead(notification_ids: number[]) {
  return async (dispatch: (action: Record<string, any>) => void) => {
    try {

      const appState: AppState = store.getState();
      const user_id = appState.UserState.user.id;
      const client_id = appState.UserState.user.active_client;

      await api.instance
        .put(`client/${client_id}/user/${user_id}/notification/mark-read`, {
          notification_ids: notification_ids
        })
        .then((__) => {
          notification_ids.forEach((notification_id: any) => {
            dispatch(readNotification(notification_id));
          });
        });

    } catch (error) {
      Console.info(error);
    }
  };
};

export function deleteNotifications(notification_ids: number[]) {
  return (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {

      const appState: AppState = store.getState();
      const notificationState = appState.NotificationState;
      const user_id = appState.UserState.user.id;
      const client_id = appState.UserState.user.active_client;

      dispatch(deleteNotificationsStart());

      api.instance
        .delete(`client/${client_id}/user/${user_id}/notification`, {
          params: {
            notification_ids: notification_ids.join(', ')
          }
        })
        .then((response) => {

          const newState = {
            ...notificationState,
            unread_total: response.data.unread_total,
            notifications: notificationState.notifications.filter((notification: any) => !notification_ids.includes(notification.id)),
          };

          dispatch(deleteNotificationsSuccess(newState));
          resolve(null);
        })
        .catch((error) => {
          dispatch(deleteNotificationsFailure(error));
          reject(error);
        });

    });
  };
};

export function searchNotifications(value: string) {
  return (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {

      const appState: AppState = store.getState();
      const notificationState = appState.NotificationState;
      const user_id = appState.UserState.user.id;
      const client_id = appState.UserState.user.active_client;

      api.instance
        .get(`client/${client_id}/user/${user_id}/notification`, {
          params: {
            search: value
          }
        })
        .then((response: any) => {

          const newState = {
            ...notificationState,
            notifications: response.data.notifications
          };

          dispatch(getNotificationsSuccess(newState));
          resolve(response.data.notifications);
        })
        .catch((error) => {
          reject(error);
        });

    });
  };
};

export function getNotificationsCount() {
  return async (dispatch: (action: Record<string, any>) => void) => {
    try {
      const appState: AppState = store.getState();
      const user_id = appState.UserState.user.id;
      const client_id = appState.UserState.user.active_client;

      if (!client_id) throw new Error('client_id does not exist');

      dispatch(getNotificationsCountStart());

      const notificationCount = await api.instance.get(`client/${client_id}/user/${user_id}/notification/count`);
      dispatch(getNotificationsCountSuccess(notificationCount.data));

    } catch (error) {
      dispatch(getNotificationsCountFailure(error));
    }
  };
};

export const pollNotificationsStart = (): PollNotificationsStartAction => ({
  type: NotificationActionTypes.POLL_NOTIFICATIONS_START,
});

export const pollNotificationsEnd = (): PollNotificationsEndAction => ({
  type: NotificationActionTypes.POLL_NOTIFICATIONS_END,
});

export function pollNotifications() {
  return async (dispatch: (action: Record<string, any>) => void) => {
    dispatch(pollNotificationsStart());
    dispatch(getNotificationsCount());
  };
};

export function stopPollingNotifications() {
  return async (dispatch: (action: Record<string, any>) => void) => {
    dispatch(pollNotificationsEnd());
  };
};