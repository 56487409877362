import React, { Component, BaseSyntheticEvent } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Input, Select } from 'antd';

// Services
import { Api } from 'services/api';

// Utils
import Console from 'utils/console';

// Interfaces
import { CountryOption } from './Address.interface';
import {
  FormField,
  FormValues,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
} from 'components/form/form-wrapper';

// Styles
import './Address.scss';

interface Props {
  clientId: number;
  field: FormField;
  originalState: FormValues;
  onChange(
    field: FormField,
    values: any,
    config: FormFieldConfig,
    column?: string
  ): FormField;
  state: FormValues;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  countryOptions: any[];
  isLoading: boolean;
  state: any,
};

const { Option } = Select;
const API: Api = new Api();

class Address extends Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    countryOptions: [],
    isLoading: false,
    state: this.props.state,
  };

  componentDidMount = async () => {
    const { state, clientId } = this.props;
    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));
      const options = await API.get(`client/${clientId}/available_countries`, {
        limited: true
      });

      this.mounted && this.setState({
        countryOptions: options.map((countryOption: CountryOption) => {
          return {
            value: countryOption.code,
            label: countryOption.title,
          };
        })
      });

    } catch (error) {
      Console.error(error);
    } finally {
      this.mounted && this.setState({ isLoading: false });
    }

    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!_.isEqual(prevProps.state, this.props.state)) {
      // Set state downwards
      this.setState({
        state: this.props.state
      });
    } else if (!_.isEqual(prevState.state, this.state.state)) {
      // Roll state upwards
      this.handleChange([{
        ...this.state.state
      }]);
    }

    if (!_.isEqual(prevProps.field, this.props.field)) {
      this.validate(this.props.state);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, state, config, onChange } = this.props;

    if (!_.isEqual(originalState, state)) {
      const newState = onChange(field, [originalState], config);
      this.validate(newState);
    }

    this.mounted = false;
  };

  validate = (state: any) => {
    const { field, originalState } = this.props;
    const columnKeys = Object.keys(field.columns);

    columnKeys.forEach((columnKey: string) => {
      this.generateModifiedState(originalState[columnKey], state[columnKey], columnKey);
      this.generateErrorState(state[columnKey], columnKey);
    });
  };

  hasErrors = (field: FormField, config: FormFieldConfig, fieldErrorMessages: any) => {
    const columnKeys = Object.keys(field.columns);

    if (columnKeys) {
      return columnKeys.some((columnKey: string) => {

        const id = field.id;
        const cardinality = config.fieldIndex || 0;
        const key = `${id}_${cardinality}_${columnKey}`;

        return _.has(fieldErrorMessages, key);
      });
    }

    return false;
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue)) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: field.id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string) => {
    const { field, config, setFieldErrorMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;
    const isRequired = !!field.columns[columnKey].required;

    const errors = [];

    if (isRequired && (!value || value === ' ')) {
      errors.push('Cannot be empty');
    }

    if (!_.isEmpty(errors)) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  handleChange = _.debounce((state: any) => {
    this.props.onChange(this.props.field, state, this.props.config);
  }, 500);

  renderAutoCompleteComponent = (columnKey: string) => {
    const { field, config, fieldErrorMessages, fieldModifiedMessages, isDisabled } = this.props;
    const { state, countryOptions, isLoading } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const options = countryOptions;
    const value = state.country || undefined;
    const hasErrors = _.has(fieldErrorMessages, key);
    const isModified = _.has(fieldModifiedMessages, key);

    if (!!isDisabled) {
      return (
        <span className="d-b pY-5">{ value }</span>
      );
    }

    return (
      <Select
        key={ `${field.id}-${columnKey}` }
        className={ classNames('Select-Field', {
          'Select-Field--has-warning': !hasErrors && isModified,
        }) }
        allowClear={ !isLoading && !field.columns[columnKey].required }
        showSearch
        loading={ isLoading }
        disabled={ isDisabled }
        options={ options }
        value={ value }
        filterOption={ (input: any, option: any) => {
          return _.toLower(option?.label).indexOf(_.toLower(input)) >= 0;
        } }
        placeholder={ field.columns[columnKey].label }
        onSelect={ (__: any, option: any) => {
          this.setState({ state: {
            ...state,
            country_code: option.value,
            country: option.label,
          }});
        } }
        onClear={ () => {
          this.setState({ state: {
            ...state,
            country_code: null,
            country: null,
          }});
        }}
        // @ts-ignore
        autoComplete="none"
      >
        { options.map((option: any, index: number) =>
          <Option key={ `${field.id}-${columnKey}-${index}` } value={ option.id }>{ option.label }</Option>
        ) }
      </Select>
    );
  };

  renderTextComponent = (columnKey: string) => {
    const { field, config, isDisabled, fieldErrorMessages, fieldModifiedMessages } = this.props;
    const { state } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const value: string | null = state[columnKey] ? `${state[columnKey]}` : '';
    const hasErrors = _.has(fieldErrorMessages, key);
    const isModified = _.has(fieldModifiedMessages, key);
    const columnConfig = field.columns[columnKey];

    if (!!isDisabled) {
      return (
        <span className={`d-b ${ !!value ? 'pY-5' : 'pY-15' }`}>{ value }</span>
      );
    }

    return (
      <Input
        autoComplete="off"
        className={ classNames('Field', {
          'Field--has-warning border-warning': isModified && !hasErrors,
        }) }
        placeholder={ columnConfig.label || '' }
        required={ !!columnConfig.required }
        value={ value }
        disabled={ isDisabled }
        onChange={ (event: BaseSyntheticEvent) => this.setState({ state: _.set(_.cloneDeep(state), columnKey, event.target.value) }) }
      />
    );
  };

  renderComponent = (columnKey: string) => {
    const { field, config, isDisabled, border, state, fieldErrorMessages } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const label = (<span className="fsz-xs fw-500">{ field.columns[columnKey].label || field.label || '' }</span>);

    let value: string = !_.isEmpty(state[columnKey]) ? `${state[columnKey]}` : '';

    if (!!isDisabled) {

      if (columnKey === 'country_code') {
        value = !_.isEmpty(state['country']) ? `${state['country']}` : '';
      }

      return (
        <FieldWrapper
          col={ config.fieldColSpan }
          label={ label }
          errors={ errors || [] }
          required={ !!field.columns[columnKey].required }
          border={ border }
        >
          <span className={ `d-b ${ !!value ? 'pY-5' : 'pY-15' }` }>{ value }</span>
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper
        col={ config.fieldColSpan }
        label={ label }
        errors={ errors || [] }
        required={ !!field.columns[columnKey].required }
        border={ border }
      >
        { columnKey === 'country_code' ? (
          this.renderAutoCompleteComponent(columnKey)
        ) : (
          this.renderTextComponent(columnKey)
        ) }
      </FieldWrapper>
    );
  };

  render = () => {
    const { field, config, border, fieldErrorMessages } = this.props;
    const columnKeys = Object.keys(field.columns);
    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        label={ field.label }
        col={ config.fieldColSpan }
        required={ field.config.required }
        border={ border }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
        hasErrors={ this.hasErrors(field, config, fieldErrorMessages) }
      >
        <div className="Address-field-container">
          { columnKeys.map((columnKey: string, index: number) => (
            <div className="Address-field" key={`address-${columnKey}-${index}`}>
              { this.renderComponent(columnKey) }
            </div>
          ) ) }
        </div>
      </FieldWrapper>
    );
  };
};

export default Address;
