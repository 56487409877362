// Libs
import * as React from 'react';
import _ from 'lodash';

// Components
import CoverModal from 'components/cover-modal';
import { Button, Transfer, Table, Input } from 'antd';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import { KpiPrioritySet } from 'components/kpi-library/KpiLibrary.interfaces';

const { Search } = Input;
const API: Api = new Api();

interface Props {
  clientId?: number;
  onAttach(kpiPrioritySets: KpiPrioritySet[]): void;
  onClose(): void;
};

interface State {
  kpiPrioritySets: KpiPrioritySet[];
  keywordFilter: string | null;
  selectedPrioritySets: string[];
  targetKeys: string[];
  isFetching: boolean;
};

class AttachPrioritySetModal extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    kpiPrioritySets: [],
    keywordFilter: null,
    selectedPrioritySets: [],
    targetKeys: [],
    isFetching: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.fetchPrioritySet();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchPrioritySet = async () => {
    const { clientId } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const response = await API.get(`client/${clientId}/kpi-library/priority-set`);

      if (!!response && _.isArray(response)) {
        this.mounted && this.setState({
          kpiPrioritySets: response.map((_kpiPrioritySet: KpiPrioritySet) => {
            return {
              ..._kpiPrioritySet,
              key: `${_kpiPrioritySet.id}`
            };
          })
        });
      }
    } catch (error) {
      Notification('error', 'Failed to fetch core priority set', 'Failed');
    } finally {
      this.mounted && this.setState({ isFetching: false });
    }
  };

  handleSave = (kpiPrioritySets: KpiPrioritySet[], targetKeys: string[]) => {
    this.props.onAttach(kpiPrioritySets.filter((kpiPrioritySet: KpiPrioritySet) => targetKeys.includes(String(kpiPrioritySet.id))));
  };

  filter = (dataSource: KpiPrioritySet[]) => {
    return dataSource.filter((kpiPrioritySet: KpiPrioritySet) => {
      if (!!this.state.keywordFilter) {
        return kpiPrioritySet.title.toLowerCase().includes(this.state.keywordFilter.toLowerCase());
      }
      return true;
    });
  };

  renderTransfer = (dataSource: any, targetKeys: string[], keywordFilter: string | null) => {
    const filteredDataSource = this.filter(dataSource);
    const availableSourceItems = filteredDataSource.length - filteredDataSource.filter((_kpiPrioritySet: KpiPrioritySet) => targetKeys.includes(_kpiPrioritySet.key)).length;
    return (
      <Transfer
        className='MetricModalTransfer h-100p'
        listStyle={{ width: '100%', height: '100%' }}
        titles={ [`Available (${availableSourceItems})`, `Selected (${targetKeys.length})`] }
        dataSource={ dataSource }
        targetKeys={ targetKeys }
        onChange={ (newTargetKeys: string[]) => {
          this.setState({
            targetKeys: newTargetKeys
          });
        } }
        render={ (kpiPrioritySet: KpiPrioritySet) => {
          return kpiPrioritySet.title;
        } }
      >
        {
          ( {
              direction,
              onItemSelectAll,
              onItemSelect,
              selectedKeys: listSelectedKeys,
              disabled: listDisabled,
          } ) => {

            const rowSelection = {
              getCheckboxProps: (item: any) => ({ disabled: listDisabled || item.disabled }),
              onSelectAll(selected: any, selectedRows: KpiPrioritySet[]) {

                const treeSelectedKeys = selectedRows
                  .filter((kpiPrioritySet: KpiPrioritySet) => {
                    if (!!keywordFilter && direction === 'left') {
                      return kpiPrioritySet.title.toLowerCase().includes(keywordFilter.toLowerCase());
                    }
                    return true;
                  })
                  .map((kpiPrioritySet: KpiPrioritySet) => kpiPrioritySet.key);

                const diffKeys = selected ? _.difference(treeSelectedKeys, listSelectedKeys) : _.difference(listSelectedKeys, treeSelectedKeys);

                onItemSelectAll(diffKeys, selected);
              },
              onSelect(item: any, selected: any) {
                onItemSelect(item.key, selected);
              },
              selectedRowKeys: listSelectedKeys,
            };

            const displayType: string = direction === 'left' ? 'source' : 'target';
            const columns = direction === 'left' ? [
              {
                dataIndex: 'title',
                title: 'Title',
                ellipsis: true,
                render: (__: any, kpiPrioritySet: KpiPrioritySet) => <span className="us-n">{ kpiPrioritySet.title }</span>
              }
            ] : [
              {
                dataIndex: 'title',
                title: 'Title',
                ellipsis: true,
                render: (__: any, kpiPrioritySet: KpiPrioritySet) => <span className="us-n">{ kpiPrioritySet.title }</span>
              },
            ];

            switch (displayType) {
              case 'source':
                return (
                  <Table
                    sticky
                    size="small"
                    className='h-100p'
                    pagination={ false }
                    dataSource={ filteredDataSource.filter((kpiPrioritySet: KpiPrioritySet) => !targetKeys.includes(kpiPrioritySet.key)) }
                    columns={ columns }
                    rowSelection={ rowSelection }
                    onRow={ ({ key }, idx) => ({
                      index: idx,
                      onClick: () => {
                        onItemSelect(key, !listSelectedKeys.includes(key));
                      }
                    }) }
                    scroll={{
                      x: columns.length * 200,
                      y: 400,
                    }}
                  />
                );
              case 'target':
                return (
                  <Table
                    sticky
                    size="small"
                    className='h-100p'
                    pagination={ false }
                    columns={ columns }
                    rowSelection={ rowSelection }
                    dataSource={ dataSource.filter((kpiPrioritySet: KpiPrioritySet) => targetKeys.includes(kpiPrioritySet.key)) }
                    onRow={ ({ key }, idx) => ({
                      index: idx,
                      onClick: () => {
                        onItemSelect(key, !listSelectedKeys.includes(key));
                      }
                    }) }
                    scroll={{
                      x: columns.length * 200,
                      y: 400,
                    }}
                  />
                );
            }
          }
        }
      </Transfer>
    );
  };

  render = () => {
    const { onClose } = this.props;
    const { kpiPrioritySets, targetKeys, keywordFilter, isFetching } = this.state;

    return (
      <CoverModal
        isLoading={ isFetching }
        showCloseIcon={ false }
        style={{ width: '90vw', maxWidth: 1300, height: '80vh', maxHeight: 800 }}
        middleContent={ 'Attach Kpi Priority Set' }
        onClose={ onClose }
      >
        <div className='d-f fxd-c pX-50 pT-30 h-100p'>
          <div className="d-f">
            <div>
              <Search
                style={{ width: 200 }}
                allowClear
                placeholder="Search"
                disabled={ _.isEmpty(kpiPrioritySets) }
                onSearch={ (value: string) => this.setState({
                  keywordFilter: !!value ? value : null,
                  selectedPrioritySets: [],
                }) }
              />
            </div>
          </div>
          <div className="d-f fxd-c fxg-1 mT-10">
            { this.renderTransfer(kpiPrioritySets, targetKeys, keywordFilter) }
          </div>
          <div className='mB-40 pT-20 ta-r'>
            <Button
              onClick={ () => this.props.onClose() }
            >
              Cancel
            </Button>
            <Button
              type='primary'
              className="mL-5"
              disabled={ _.isEmpty(targetKeys) }
              onClick={ () => this.handleSave(kpiPrioritySets, targetKeys) }
            >
              Attach
            </Button>
          </div>
        </div>
      </CoverModal>
    );
  };
};

export default AttachPrioritySetModal;
