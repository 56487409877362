// Libs
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

// Components
import { Table, TreeSelect, Typography, Input, Tooltip, Checkbox, Dropdown, Menu, Button, Modal, Form, Select } from 'antd';
import { ProcessImport } from 'components/import/ProcessImport';

// Icons
import Icon, { QuestionCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
} from "components/form/form-wrapper";
import { RecordFormEntity } from 'types/entities';
import FieldWrapper from 'components/form/field/field-wrapper';
import TemplateModal from 'components/template-modal/TemplateModal';

// Services
import { getFormatedNumber } from 'services/settings';
import Notification from 'services/notification';
import { Api } from 'services/api';

// Utils
import { findFirst } from 'utils/utils';

const { Option } = Select;
const { Text, Link } = Typography;
const { SHOW_PARENT } = TreeSelect;
const API: Api = new Api();

interface Props {
  numberFormat: any;
  clientId: number;
  record: RecordFormEntity;
  field: any;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  getRecord?: (silent?: boolean) => void;
  originalState: any;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  excludeZeros: boolean;
  showScoped: boolean;
  showChanged: boolean;
  activeRowKey: string | null;
  expandedRowKeys: string[];
  activeServicesList: any[];
  filter: number[];
  showDuplicationDialog: boolean;
  showFilter: boolean;
  showTemplateModal: boolean;
  showServiceModal: boolean;
  showExportModal: boolean;
  showImportModal: boolean;
  isFetchingServices: boolean;
  isLoadingAction: boolean;
  exportOptions: {
    finance_template: [],
    coa: []
  } | null;
};

const nestedSet = (data: any = []) => {
  return !_.isEmpty(data) && data.map((entity: any) => {
    const appendChildrenKeys = (children: any) => {

      // Prevent nesting
      if (_.isEmpty(children)) return null;

      return children.map((childEntity: any) => {
        return {
          ...childEntity,
          'key': childEntity.id,
          'id': childEntity.id,
          'value': childEntity.id,
          'title': childEntity.title,
          'children': appendChildrenKeys(childEntity.children),
        };
      });
    };

    return {
      ...entity,
      'key': entity.id,
      'id': entity.id,
      'value': entity.id,
      'title': entity.title,
      'children': appendChildrenKeys(entity.children),
    };
  });
};

const getBlankState: any = (default_currency_code: string = 'EUR') => {
  return {
    opex_id: null,
    currency_code: default_currency_code,
    forecast: 0,
    value: 0,
    comment: '',
    template_rows: []
  };
};

class OpexCostSummary extends Component<Props> {

  state: State = {
    excludeZeros: false,
    showScoped: false,
    showChanged: false,
    activeRowKey: null,
    expandedRowKeys: [],
    activeServicesList: [],
    filter: [],
    showDuplicationDialog: false,
    showFilter: false,
    showTemplateModal: false,
    showServiceModal: false,
    showExportModal: false,
    showImportModal: false,
    isFetchingServices: false,
    isLoadingAction: false,
    exportOptions: null
  };

  componentDidMount = () => {
    this.validate(this.props.state);

    this.setState({
      expandedRowKeys: this.getFlatten(this.getOpexTree(this.props.field)).map((opex: any) => opex.key)
    });
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { clientId, field, state } = this.props;
    const { activeRowKey, showServiceModal } = this.state;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);
    }

    if (prevState.showServiceModal !== showServiceModal && showServiceModal && activeRowKey) {
      const opexTree = this.getOpexTree(field);
      const activeService = activeRowKey && findFirst({ children: opexTree }, 'children', { key: activeRowKey });
      try {

        await new Promise((resolve) => this.setState({ isFetchingServices: true }, () => resolve(null) ));
        const services = await API.get(`client/${clientId}/category/opex-coa/${activeService.id}/child-services`);

        this.setState({
          activeServicesList: services
        });

      } catch {
        console.error('Failed to fetch services');
      } finally {
        this.setState({
          isFetchingServices: false,
        });
      }
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);
  };

  validate = (state: any) => {
    this.generateModifiedState(this.props.originalState, state, 'opex_cost_summary');
  };

  generateModifiedState = (pastValue: string | number, newValue: string | number, columnKey: string) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue)) {

      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  handleExport = async () => {
    const { clientId, field, record } = this.props;
    const { exportOptions } = this.state;
    const filename = field?.export_filename || 'pacs_cost_export';

    try {

      await new Promise((resolve) => this.setState({ isLoadingAction: true }, () => resolve(null) ));

      await API.download(`client/${clientId}/field/${field.type}/${field.id}/export`, `${filename}__${moment().unix()}.xlsx`, {
        entity_id: record.id,
        entity_bundle: record.bundle,
        entity_type: record.type,
        coa_id: exportOptions?.coa ? exportOptions.coa : [],
        template_id: exportOptions?.finance_template ? exportOptions.finance_template : [],
      });

      Notification('success', `Budget exported`);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isLoadingAction: false
      });
    }
  };

  getFlatten = (data: any) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({ ..._value });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  getRolledUpTotal = (opexTree: any, values: any[], key: string) => {
    const opexList = this.getFlatten([values]);
    return opexList.reduce((acc: any, opex: any) => {
      const _opex = opexTree.find((branch: any) => branch.opex_id === opex.id);
      const _value: any = _opex ? parseFloat(_opex[key]) : 0;
      return parseFloat(acc) + parseFloat(_value);
    }, 0);
  };

  getPreviousBudgetTotal = (field: any, opexTree: any[]) => {
    const opexList = this.getFlatten(opexTree).map((branch: any) => branch.id);
    if (_.has(field, 'previous_values') && !_.isEmpty(field.previous_values)) {
      return field.previous_values.reduce((acc: any, previousBudget: any) => {
        if (opexList.includes(previousBudget.opex_id)) {
          return parseFloat(acc) + parseFloat(previousBudget['value'] || 0);
        }
        return parseFloat(acc);
      }, 0);
    }
    return 0;
  };

  getCalculatedTotal = (values: any, key: string, opexTree: any) => {
    const opexList = this.getFlatten(opexTree).map((branch: any) => branch.id);
    return values.reduce((acc: any, value: any) => {
      if (opexList.includes(value.opex_id)) {
        return parseFloat(acc) + parseFloat(value[key] || 0);
      }
      return parseFloat(acc);
    }, 0);
  };

  getRolledUpTemplateTotals = (field: any, opexId: number, state: any[]) => {
    const partialOpexTree = findFirst({ children: field.opex_tree }, 'children', { id: opexId });
    if (partialOpexTree) {
      const flatten = this.getFlatten([partialOpexTree]);
      return flatten.reduce((acc: any, branch: any) => {
        const stateOpex = state.find((value: any) => value.opex_id === branch.id);
        if (stateOpex) {
          const sum = stateOpex.template_rows.reduce((_acc: any, row: any) => {
            const template = field.templates.find((template: any) => template.id === row.template_id);
            const totalColumn = template && template.columns.find((column: any) => column.context === 'TOTAL');
            if (totalColumn && _.has(row, `values.${totalColumn.reference}`) && !!row.values[totalColumn.reference]) {
              return parseFloat(_acc) + parseFloat(row.values[totalColumn.reference]);
            }
            return parseFloat(_acc);
          }, 0);
          return parseFloat(acc) + parseFloat(sum);
        }
        return parseFloat(acc);
      }, 0);
    }
    return 0;
  };

  getOpexTree = (field: any) => {
    const generateTreeList = (value: any) => {
      return {
        key: `${value.id}`,
        value: `${value.id}`,
        ...value,
        children: _.has(value, 'children') && !_.isEmpty(value.children) ? value.children
          .map((_value: any) => generateTreeList(_value))
          .filter((_value: any) => _value) : null
      };
    };

    return _.has(field, 'opex_tree') && field.opex_tree
      .map((opex: any) => generateTreeList(opex))
      .filter((opex: any) => opex);
  };

  filterTree = (tree: any[], filters: number[]) => {
    const collector: any = [];

    tree.forEach((branch: any) => {

      const filter = (_branch: any, filters: any[]) => {

        if (filters.includes(_branch.id)) {
          collector.push({
            ..._branch,
          });
        }

        if (_.has(_branch, 'children') && !_.isEmpty(_branch.children)) {
          _branch.children.forEach((__branch: any) => {
            filter(__branch, filters);
          });
        }
      };

      return filter(branch, filters);
    });

    return collector;
  };

  filterChangedValues = (values: any[], state: any[], field: any) => {
    const recordDataMapping = (data: any[]) => {
      return data.map((entity: any) => {
        const appendChildrenKeys = (children: any) => {
          return children
            .filter((childEntity: any) => {
              const stateOpex = state.find((value: any) => value.opex_id === childEntity.id);
              const previousBudget = field.previous_values.find((value: any) => value.opex_id === childEntity.id);
              const previousBudgetValue = _.has(previousBudget, 'value') ? parseFloat(previousBudget.value) : 0;
              const currentBudgetValue = _.has(stateOpex, 'value') ? parseFloat(stateOpex.value) : 0;

              if (stateOpex && (previousBudgetValue !== currentBudgetValue)) {
                return true;
              }
              return false;
            })
            .map((childEntity: any) => {
              return {
                ...childEntity,
                'children': !_.isEmpty(childEntity.children) ? appendChildrenKeys(childEntity.children) : null,
              };
            });
        };
        return {
          ...entity,
          'children': !_.isEmpty(entity.children) ? appendChildrenKeys(entity.children) : null,
        };
      });
    };

    return recordDataMapping(values);
  };

  filterExcludeZeros = (tree: any[], state: any[], field: any) => {
    const recordDataMapping = (data: any[]) => {
      return data.map((entity: any) => {
        const appendChildrenKeys = (children: any) => {
          return children
            .filter((childEntity: any) => {
              const stateOpex = state.find((value: any) => value.opex_id === childEntity.id);

              // Check if there is a stateOpex and if the value or template totals are non-zero
              if (stateOpex) {
                const totalValue = parseFloat(stateOpex.value);
                const totalTemplate = this.getRolledUpTemplateTotals(field, childEntity.id, state);
                return totalValue !== 0 || totalTemplate !== 0;
              }
              return false;
            })
            .map((childEntity: any) => {
              return {
                ...childEntity,
                'children': !_.isEmpty(childEntity.children) ? appendChildrenKeys(childEntity.children) : null,
              };
            });
        };
        return {
          ...entity,
          'children': !_.isEmpty(entity.children) ? appendChildrenKeys(entity.children) : null,
        };
      });
    };

    return recordDataMapping(tree);
  };

  filterScoped = (services: any[]) => {
    return services.filter((service) => !!service?.is_scoped);
  };

  getTemplateRows = (field: any, activeService: any) => {
    if (!activeService.children) {
      const activeServiceValue = activeService && field.values.find((value: any) => value.opex_id === activeService.id);

      if (activeServiceValue) {
        return activeServiceValue.template_rows;
      }

      return [];
    }

    return field.values
      .filter((value: any) => {
        return this.getFlatten([activeService]).find((service: any) => {
          return service.id === value.opex_id;
        });
      }).map((value: any) => {
        return value.template_rows;
      }).reduce((acc: any, curr: any) => {
        return acc.concat(curr);
      }, []);
  };

  renderDuplicationDialog = (field: any) => {
    return (
      <Modal
        visible
        centered
        title={ 'Duplicate Previous Values' }
        okText={ 'Yes' }
        onOk={ () => {
          this.setState({
            showDuplicationDialog: false
          }, () => {
            this.props.onChange(field, _.cloneDeep(this.props.state.map((value: any) => {
              const previousBudget = field.previous_values.find((previous_values: any) => previous_values.opex_id === value.opex_id);
              value['value'] = previousBudget ? previousBudget.value : 0;
              return value;
            })), field.config);
          });
        } }
        onCancel={() => this.setState({
          showDuplicationDialog: false,
        }) }
        okButtonProps={{
          danger: true
        }}
      >
        <p>This will copy all <b>previous</b> column values into the <b>current</b> column.</p>
        <p className="mT-10">Any existing values will be replaced. Are you sure you want to duplicate?</p>
      </Modal>
    );
  };

  renderExportModal = () => {
    const { field } = this.props;
    const { isLoadingAction, exportOptions } = this.state;
    const financeTemplates: any = field?.templates;
    const opexCoas: any = this.getOpexTree(field);
    return (
      <Modal
        centered
        visible
        closable={ false }
        title={ 'Export Budget' }
        onCancel={ () => this.setState({ showExportModal: false, exportOptions: null }) }
        okText={ 'Export' }
        okButtonProps={ {
          loading: isLoadingAction
        } }
        onOk={ this.handleExport }
        style={ { minWidth: 500 } }
      >
        <Form
          layout="vertical"
        >
          <Form.Item
            label="Finance Template"
            name="template"
          >
            <Select
              disabled
              allowClear
              showSearch
              mode={ 'multiple' }
              style={ { width: '100%' } }
              placeholder={ 'All' }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              onChange={ (value: any) => {
                this.setState({ exportOptions: { ...exportOptions, finance_template: value } });
              } }
              value={ exportOptions?.finance_template ? exportOptions.finance_template : null }
            >
              { financeTemplates.map((financeTemplate: any) => (
                <Option key={ financeTemplate.id } value={ financeTemplate.id }>
                  { financeTemplate.title }
                </Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item
            label="COA"
            name="coa"
          >
            <TreeSelect
              disabled
              showSearch
              treeCheckable
              dropdownMatchSelectWidth={ false }
              maxTagCount={ 5 }
              style={ { width: '100%' } }
              placeholder={ 'All' }
              value={ exportOptions?.coa ? exportOptions.coa : null }
              treeData={ opexCoas }
              filterTreeNode={ (input: string, option: any) => {
                if (option) {
                  const filteredInput = input.toLocaleLowerCase();
                  const title = option.title && option.title.toLowerCase();

                  if (title.includes(filteredInput)) {
                    return true;
                  }
                }
                return false;
              } }
              onChange={ (selectedTarget: any) => {
                this.setState({ exportOptions: { ...exportOptions, coa: selectedTarget } });
              } }
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderImportModal = () => {
    const { clientId, field } = this.props;
    return (
      <ProcessImport
        title={ 'Import Budget' }
        clientId={ clientId }
        filePrefix={ field?.export_filename }
        verifyEndpoint={ `field/${field.type}/${field.id}/import/verify` }
        importEndpoint={ `field/${field.type}/${field.id}/import/run` }
        downloadEndpoint={ `field/${field.type}/${field.id}/import/download` }
        onClose={ (shouldReload: boolean) => {
          this.setState({
            showImportModal: false
          }, () => {
            if (shouldReload && this.props.getRecord) {
              this.props.getRecord();
            }
          });
        } }
      />
    );
  };

  render = () => {
    const {
      field,
      state,
      originalState,
      config,
      numberFormat,
      isDisabled,
      fieldModifiedMessages,
      onChange,
      record,
    } = this.props;
    const {
      excludeZeros,
      showScoped,
      showChanged,
      activeRowKey,
      showDuplicationDialog,
      expandedRowKeys,
      showFilter,
      filter,
      showTemplateModal,
      showServiceModal,
      showExportModal,
      showImportModal,
      activeServicesList,
      isFetchingServices,
      isLoadingAction,
    } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_opex_cost_summary`;
    const decimal = _.has(field, 'config.decimal') ? field.config.decimal : 2;
    const isFieldModified = _.has(fieldModifiedMessages, key);
    const currencySymbol = _.has(field, 'currency') && !_.isEmpty(field.currency) ? field.currency.symbol : '';
    const currencyCode = _.has(field, 'currency') && !_.isEmpty(field.currency) ? field.currency.code : '';
    const showForcecast = _.has(field, 'forecast_visible') ? !!field.forecast_visible : false;
    const editableForcecast = _.has(field, 'forecast_editable') ? !!field.forecast_editable : false;
    const rawOpexTree = this.getOpexTree(field);
    let opexTree = rawOpexTree;

    const rolledUpTemplateTotals = field.opex_tree.reduce((acc: any, opex: any) => {
      return parseFloat(acc) + parseFloat(this.getRolledUpTemplateTotals(field, opex.id, state));
    }, 0);
    const previousBudgetTotal = this.getPreviousBudgetTotal(field, opexTree);
    const currentBudgetTotal = this.getCalculatedTotal(field.values, 'value', opexTree);
    const forecastTotal = this.getCalculatedTotal(field.values, 'forecast', opexTree);
    const budgetVarianceTotal = currentBudgetTotal - previousBudgetTotal;
    const forecastVarianceTotal = forecastTotal - currentBudgetTotal;
    const activeService = activeRowKey && findFirst({ children: opexTree }, 'children', { key: activeRowKey });
    const hasPreviousBudget = _.has(field, 'previous_values') && !_.isEmpty(field.previous_values);
    const templates = activeService && _.has(activeService, 'compatible_template_ids') && !_.isEmpty(field.templates) ? field.templates.filter((template: any) => activeService.compatible_template_ids.includes(template.id)) : [];

    const canImport: boolean = _.has(field, 'can_import') && !!field.can_import;
    const canExport: boolean = _.has(field, 'can_export') && !!field.can_export;
    const actions: { title: string, onClick: () => void, disabled: boolean, tooltipText: string }[] = [
      {
        title: 'Export',
        onClick: () => {
          if (!isDisabled) {
            this.setState({ showExportModal: true });
          }
        },
        disabled: !canExport || !!isDisabled,
        tooltipText: !canExport || !!isDisabled ? 'Export feature is disabled.' : 'Export budget',
      },
      {
        title: 'Import',
        onClick: () => {
          if (!isDisabled) {
            this.setState({ showImportModal: true });
          }
        },
        disabled: !canImport || !!isDisabled,
        tooltipText: !canImport ? 'Import feature is disabled.\nCannot import budget, please set it to draft first.' : !!isDisabled ? 'Import feature is disabled' : 'Import pricing',
      }
    ];

    if (!_.isEmpty(filter)) {
      opexTree = this.filterTree(opexTree, filter);
    }

    if (excludeZeros) {
      opexTree = this.filterExcludeZeros(opexTree, state, field);
    }

    if (showScoped) {
      opexTree = this.filterScoped(opexTree);
    }

    if (showChanged) {
      opexTree = this.filterChangedValues(opexTree, state, field);
    }

    if (!isDisabled && hasPreviousBudget) {
      actions.push(
        {
          title: 'Duplicate Previous Values',
          onClick: () => this.setState({ showDuplicationDialog: true }),
          disabled: false,
          tooltipText: 'Duplicates the previous values', // Tooltip text for the duplicate action
        }
      );
    };

    const columns: any = [
      {
        key: 'title',
        dataIndex: 'title',
        title: (
          <>
            <span>Title</span>
            <Tooltip
              placement="top"
              title={ _.isEmpty(expandedRowKeys) ? 'Expand all' : 'Collapse all' }
            >
              <button
                type="button"
                style={{
                  marginTop: '2.5005px',
                  marginRight: '8px',
                  backgroundColor: '#f5f5f5',
                }}
                className={ classNames('ant-table-row-expand-icon', {
                  'ant-table-row-expand-icon-collapsed': _.isEmpty(expandedRowKeys),
                  'ant-table-row-expand-icon-expanded': !_.isEmpty(expandedRowKeys),
                }) }
                onClick={ () => {
                  if (_.isEmpty(expandedRowKeys)) {
                    this.setState({ expandedRowKeys: this.getFlatten(opexTree).map((opex: any) => opex.key) });
                  } else {
                    this.setState({ expandedRowKeys: [] });
                  }
                } }
              />
            </Tooltip>
          </>
        ),
        width: 400,
        fixed: 'left',
        ellipsis: true,
        render: (__: any, row: any) => {

          let previousVersionBudgetChanged = false;

          if (!!field.config.version_changed) {
            const previousBudget = field.previous_values.find((value: any) => value.opex_id === row.id);
            const stateOpex = state.find((value: any) => value.opex_id === row.id);
            const previousBudgetValue = previousBudget && _.has(previousBudget, 'value') ? parseFloat(previousBudget.value) : 0;
            const currentBudgetValue = stateOpex && _.has(stateOpex, 'value') ? parseFloat(stateOpex.value) : 0;

            if (stateOpex && (previousBudgetValue !== currentBudgetValue)) {
              previousVersionBudgetChanged = true;
            }
          }

          return (
            <Link
              className={ classNames({
                'text-warning': previousVersionBudgetChanged,
              }) }
              onClick={ () => this.setState({ activeRowKey: row.key, showServiceModal: true }) }
            >
              { _.has(row, 'description') && !!row.description ?
                (
                  <Tooltip
                    placement="top"
                    title={ row.description }
                  >
                    { row.title }
                  </Tooltip>
                ) : (
                  row.title
                )
              }
            </Link>
          );
        }
      },
      {
        key: 'current_budget',
        dataIndex: 'current_budget',
        width: 200,
        title: (
          <>
            <span>{ _.has(field, 'current_values_label') ? field.current_values_label : 'Current Budget' }</span>
            <Tooltip
              className="mL-5"
              placement="top"
              title={ 'Proposed budget' }
            >
              <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
            </Tooltip>
          </>
        ),
        render: (__: any, row: any) => {
          const stateOpex = state.find((value: any) => value.opex_id === row.id);
          const originalStateOpex = originalState.find((value: any) => value.opex_id === row.id);
          const newValue = stateOpex ? parseFloat(stateOpex.value) : 0;
          const oldValue = originalStateOpex ? parseFloat(originalStateOpex.value) : 0;
          const isModified = newValue !== oldValue;
          const canEdit = !row.children;
          const total = this.getRolledUpTotal(state, row, 'value');

          return (
            <NumberFormat
              { ...numberFormat }
              fixedDecimalScale={ !!decimal }
              decimalScale={ decimal }
              customInput={ Input }
              className={ classNames('Field pR-20 ta-r', {
                'Field--has-warning border-warning': isModified,
              }) }
              prefix={ currencySymbol }
              required={ field.config.required }
              disabled={ !canEdit || isDisabled }
              value={ canEdit ? (_.has(stateOpex, 'value') ? stateOpex.value : 0) : total }
              onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                const index = state.findIndex((value: any) => value.opex_id === row.id);
                const value = parseFloat(event.target.value.replace(currencySymbol, '').replaceAll(',', ''));

                if (index !== -1) {
                  onChange(field, _.set(_.cloneDeep(state), index, {
                    ...stateOpex,
                    value: value || 0
                  }), config);
                } else if (value) { // Only create a new state if it's not empty
                  const newState = _.cloneDeep(state).concat([{
                    ...getBlankState(currencyCode),
                    opex_id: row.id,
                    value: value || 0
                  }]);
                  onChange(field, newState, config);
                }
              } }
            />
          );
        }
      },
      {
        key: 'templates',
        dataIndex: 'templates',
        width: 150,
        align: 'right',
        title: (
          <>
            <span>Templates</span>
            <Tooltip
              className="mL-5"
              placement="top"
              title={ '' }
            >
              <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
            </Tooltip>
          </>
        ),
        render: (__: any, row: any) => {
          const total = this.getRolledUpTemplateTotals(field, row.id, state);
          return (
            <div className='ta-r'>
              <Link onClick={ () => this.setState({ activeRowKey: row.key, showTemplateModal: true }) }>
                <NumberFormat
                  { ...numberFormat }
                  displayType={ 'text' }
                  fixedDecimalScale={ !!decimal }
                  decimalScale={ decimal }
                  customInput={ Input }
                  prefix={ currencySymbol }
                  value={ total }
                />
              </Link>
            </div>
          );
        }
      },
      {
        key: 'comments',
        dataIndex: 'comments',
        title: 'Comments',
        width: 300,
        render: (__: any,row: any) => {
          const stateOpex = state.find((value: any) => value.opex_id === row.id);
          const originalStateOpex = originalState.find((value: any) => value.opex_id === row.id);
          const newValue = stateOpex ? stateOpex.comment : '';
          const oldValue = originalStateOpex ? originalStateOpex.comment : '';
          const isModified = newValue !== oldValue;

          return (
            <Input
              autoComplete="off"
              className={ classNames('Field', {
                'Field--has-warning border-warning': isModified,
              }) }
              defaultValue={ _.has(stateOpex, 'comment') ? stateOpex.comment : '' }
              disabled={ isDisabled }
              onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                const index = state.findIndex((value: any) => value.opex_id === row.id);
                if (index !== -1) {
                  onChange(field, _.set(_.cloneDeep(state), index, {
                    ...stateOpex,
                    comment: event.target.value || ''
                  }), config);
                } else if (!!event.target.value) {
                  const newState = _.cloneDeep(state).concat([{
                    ...getBlankState(currencyCode),
                    opex_id: row.id,
                    comment: event.target.value || '',
                  }]);
                  onChange(field, newState, config);
                }
              } }
            />
          );
        }
      }
    ];

    if (hasPreviousBudget) {

      // Add previous budget
      columns.splice(columns.findIndex((column: any) => column.key === 'title') + 1, 0,
        {
          key: 'previous_values',
          dataIndex: 'previous_values',
          width: 200,
          title: (
            <>
              <span>{ _.has(field, 'previous_values_label') ? field.previous_values_label : 'Previous Budget' }</span>
              <Tooltip
                className="mL-5"
                placement="top"
                title={ 'Last Approved Budget' }
              >
                <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
              </Tooltip>
            </>
          ),
          render: (__: any, row: any) => {
            const previousBudgetValue = this.getRolledUpTotal(field.previous_values, row, 'value');
            return (
              <div className='ta-r'>{ getFormatedNumber(`${previousBudgetValue}`, null, currencySymbol) }</div>
            );
          }
        }
      );

      // Add budget variance
      columns.splice(columns.findIndex((column: any) => column.key === 'current_budget') + 1, 0,
        {
          key: 'budget_variance',
          dataIndex: 'budget_variance',
          width: 200,
          title: (
            <>
              <span>Budget Variance</span>
              <Tooltip
                className="mL-5"
                placement="top"
                title={ 'The difference between the previous and current budget' }
              >
                <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
              </Tooltip>
            </>
          ),
          render: (__:any, row: any) => {
            const stateOpex = state.find((value: any) => value.opex_id === row.id);
            const previousBudget = field.previous_values.find((value: any) => value.opex_id === row.id);
            const canEdit = !row.children;

            let previousBudgetValue = 0;
            let currentBudgetValue = 0;

            if (canEdit) {
              currentBudgetValue = _.has(stateOpex, 'value') ? parseFloat(stateOpex.value) : 0;
            } else {
              currentBudgetValue = this.getRolledUpTotal(state, row, 'value');
            }

            if (canEdit) {
              previousBudgetValue = previousBudget ? parseFloat(previousBudget.value) : 0;
            } else {
              previousBudgetValue = this.getRolledUpTotal(field.previous_values, row, 'value');
            }

            const sum = currentBudgetValue - previousBudgetValue;

            return (
              <div
                className={ classNames('ta-r', {
                  'text-danger': sum > 0,
                  'text-success': sum < 0,
                }) }
              >
                { getFormatedNumber(`${sum}`, null, currencySymbol) }
              </div>
            );
          }
        }
      );
    }

    if (showForcecast) {
      // Add forecast
      columns.splice(columns.findIndex((column: any) => column.key === (hasPreviousBudget ? 'budget_variance' : 'current_budget')) + 1, 0,
        {
          key: 'forecast',
          dataIndex: 'forecast',
          width: 200,
          title: (
            <>
              <span>Forecast</span>
              <Tooltip
                className="mL-5"
                placement="top"
                title={ 'Enter forecast for current budget year' }
              >
                <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
              </Tooltip>
            </>
          ),
          render: (__: any, row: any) => {
            const stateOpex = state.find((value: any) => value.opex_id === row.id);
            const originalStateOpex = originalState.find((value: any) => value.opex_id === row.id);
            const newValue = stateOpex ? parseFloat(stateOpex.forecast) : 0;
            const oldValue = originalStateOpex ? parseFloat(originalStateOpex.forecast) : 0;
            const isModified = newValue !== oldValue;
            const canEdit = !row.children;

            let forecastValue = 0;

            if (canEdit) {
              forecastValue = _.has(stateOpex, 'forecast') ? stateOpex.forecast : 0;
            } else {
              forecastValue = this.getRolledUpTotal(state, row, 'forecast');
            }

            return (
              <NumberFormat
                { ...numberFormat }
                fixedDecimalScale={ !!decimal }
                decimalScale={ decimal }
                customInput={ Input }
                className={ classNames('Field pR-20 ta-r', {
                  'Field--has-warning border-warning': isModified,
                }) }
                prefix={ currencySymbol }
                required={ field.config.required }
                disabled={ !canEdit || !editableForcecast }
                value={ forecastValue }
                onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                  const index = state.findIndex((value: any) => value.opex_id === row.id);
                  const value = parseFloat(event.target.value.replace(currencySymbol, '').replaceAll(',', ''));
                  if (index !== -1) {
                    onChange(field, _.set(_.cloneDeep(state), index, {
                      ...stateOpex,
                      forecast: value || 0
                    }), config);
                  } else if (value) {
                    const newState = _.cloneDeep(state).concat([{
                      ...getBlankState(currencyCode),
                      opex_id: row.id,
                      forecast: value || 0,
                    }]);
                    onChange(field, newState, config);
                  }
                } }
              />
            );
          }
        },
        {
          key: 'forecast_variance',
          dataIndex: 'forecast_variance',
          width: 200,
          title: (
            <>
              <span>Forecast Variance</span>
              <Tooltip
                className="mL-5"
                placement="top"
                title={ 'Last Approved Budget - Forecast' }
              >
                <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
              </Tooltip>
            </>
          ),
          render: (__: any, row: any) => {
            const stateOpex = state.find((value: any) => value.opex_id === row.id);
            const canEdit = !row.children;
            let currentBudgetValue = 0;
            let forecastValue = 0;

            if (canEdit) {
              currentBudgetValue = _.has(stateOpex, 'value') ? stateOpex.value : 0;
              forecastValue = _.has(stateOpex, 'forecast') ? stateOpex.forecast : 0;
            } else {
              currentBudgetValue = this.getRolledUpTotal(state, row, 'value');
              forecastValue = this.getRolledUpTotal(state, row, 'forecast');
            }

            const sum = forecastValue - currentBudgetValue;

            return (
              <div
                className={ classNames('ta-r', {
                  'text-danger': sum > 0,
                  'text-success': sum < 0,
                }) }
              >
                { getFormatedNumber(`${sum}`, null, currencySymbol) }
              </div>
            );
          }
        },
      );
    }

    return (
      <>
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          label={ (
            <div>
              <div className="mT-5">
                <span>{ field.label }</span>
                <span
                  className={ classNames('mL-15', {
                    'link': true,
                    'active': showFilter
                  }) }
                  onClick={ () => {
                    this.setState({
                      showFilter: !showFilter
                    });
                  } }
                >
                  <Icon component={ FilterIcon } />
                  <span>Filter</span>
                </span>
              </div>
              { showFilter &&
                <div className='mT-15'>
                  <span>
                    <TreeSelect
                      style={{ width: 300 }}
                      dropdownMatchSelectWidth={ false }
                      placeholder={ 'Select filter' }
                      showCheckedStrategy={ SHOW_PARENT }
                      maxTagCount={ 2 }
                      treeCheckable
                      multiple
                      treeData={ nestedSet(rawOpexTree) }
                      filterTreeNode={ (input: string, option: any) => {
                        if (option) {
                          const filteredInput = input.toLocaleLowerCase();
                          const title = option.title && option.title.toLowerCase();

                          if (title.includes(filteredInput)) {
                            return true;
                          }
                        }

                        return false;
                      } }
                      onChange={(items: any[]) => {
                        this.setState({
                          filter: items
                        });
                      }}
                    />
                  </span>
                  <span className="mL-10">
                    <Checkbox
                      onChange={ (event) => {
                        this.setState({
                          showChanged: event.target.checked
                        });
                      } }
                      checked={ showChanged }
                    >
                      Show Changed
                    </Checkbox>
                    <Checkbox
                      onChange={ (event) => {
                        this.setState({
                          excludeZeros: event.target.checked
                        });
                      } }
                      checked={ excludeZeros }
                    >
                      Exclude Zeros
                    </Checkbox>
                    <Checkbox
                      onChange={ (event) => {
                        this.setState({
                          showScoped: event.target.checked
                        });
                      } }
                      checked={ showScoped }
                    >
                      In Scope Only
                    </Checkbox>
                  </span>
                </div>
              }
            </div>
          ) }
          hideErrorInfo
          required={ field.config.required }
          border
          description={ !!field.description && field.description }
          isModified={ isFieldModified }
          refreshOnChange={ !!field.config.refresh_on_change }
          versionChanged={ !!field.config.version_changed }
          rightActions={ [
            {
              node: (
                <Dropdown
                  placement={ 'bottomRight' }
                  overlay={ () => (
                    <Menu>
                      { actions.map((action: { title: string, onClick: () => void, disabled: boolean, tooltipText: string }, index: number) => (
                        <Menu.Item
                          key={ index }
                          onClick={ () => action.onClick() }
                          disabled={ action.disabled }
                        >
                          { action.disabled ? (
                            <Tooltip title={ action.tooltipText }>
                              <span className="cur-na">{ action.title }</span>
                            </Tooltip>
                          ) : (
                            action.title
                          ) }
                        </Menu.Item>
                      ) ) }
                    </Menu>
                  ) }
                  trigger={ ['click'] }
                >
                  <Button
                    loading={ isLoadingAction }
                    disabled={ isLoadingAction }
                    style={{
                      marginLeft: 5,
                      padding: '4px 7px',
                      width: '32px',
                    }}
                    onClick={ () => {} }
                  >
                    { !isLoadingAction && <EllipsisOutlined className="fsz-def text-ant-default" /> }
                  </Button>
                </Dropdown>
              )
            }
          ] }
        >
          <>
            <Table
              size={ 'small' }
              sticky
              bordered
              columns={ columns }
              dataSource={ opexTree || [] }
              pagination={ false }
              scroll={{
                x: columns.length * 200,
                y: 600,
              }}
              expandable={{
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded: boolean, row: any) => {
                  if (expanded) {
                    this.setState({ expandedRowKeys: [...expandedRowKeys, row.key] });
                  } else {
                    this.setState({ expandedRowKeys: !_.isEmpty(expandedRowKeys) ? expandedRowKeys.filter((rowKey) => rowKey !== row.key) : expandedRowKeys });
                  }
                },
              }}
              summary={ () => {
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={ 0 } colSpan={ 1 } className="fw-600">
                        <Text>{ 'Total' }</Text>
                      </Table.Summary.Cell>
                      { hasPreviousBudget &&
                        <Table.Summary.Cell index={ 1 } colSpan={ 1 } className="ta-r fw-600">
                          <Text>
                            { getFormatedNumber(`${previousBudgetTotal}`, null, currencySymbol) }
                          </Text>
                        </Table.Summary.Cell>
                      }
                      <Table.Summary.Cell index={ 2 } colSpan={ 1 } className="ta-r fw-600">
                        <Text>
                          { getFormatedNumber(`${currentBudgetTotal}`, null, currencySymbol) }
                        </Text>
                      </Table.Summary.Cell>
                      { hasPreviousBudget &&
                        <Table.Summary.Cell index={ 3 } colSpan={ 1 } className="ta-r fw-600">
                          <Text
                            className={ classNames('ta-r', {
                              'text-danger': budgetVarianceTotal > 0,
                              'text-success': budgetVarianceTotal < 0,
                            }) }
                          >
                            { getFormatedNumber(`${budgetVarianceTotal}`, null, currencySymbol) }
                          </Text>
                        </Table.Summary.Cell>
                      }
                      { showForcecast &&
                        <>
                          <Table.Summary.Cell index={ 4 } colSpan={ 1 } className="ta-r fw-600">
                            <Text>
                              { getFormatedNumber(`${forecastTotal}`, null, currencySymbol) }
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={ 5 } colSpan={ 1 } className="ta-r fw-600">
                            <Text
                              className={ classNames('ta-r', {
                                'text-danger': forecastVarianceTotal > 0,
                                'text-success': forecastVarianceTotal < 0,
                              }) }
                            >
                              { getFormatedNumber(`${forecastVarianceTotal}`, null, currencySymbol) }
                            </Text>
                          </Table.Summary.Cell>
                        </>
                      }
                      <Table.Summary.Cell index={ 2 } colSpan={ 1 } className="ta-r fw-600">
                        <Text>
                          { getFormatedNumber(`${rolledUpTemplateTotals}`, null, currencySymbol) }
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
            { activeRowKey && showTemplateModal &&
              <TemplateModal
                record={ record }
                coa={ activeService }
                contractPricingId={ field?.preselected_contract_pricing_id || null }
                templates={ templates }
                defaultCurrency={ field?.currency || null }
                financialYear={ field?.financial_year || null }
                values={ this.getTemplateRows(field, activeService) }
                numberFormat={ numberFormat }
                isDisabled={ isDisabled }
                onSave={ (values: any) => {
                  const index = state.findIndex((value: any) => value.opex_id === activeService.id);
                  if (index !== -1) {
                    onChange(field, _.set(_.cloneDeep(state), index, {
                      ...state[index],
                      template_rows: values
                    }), config);
                  } else {
                    onChange(field, _.cloneDeep(state).concat([{
                      ...getBlankState(currencyCode),
                      opex_id: activeService.id,
                      template_rows: values
                    }]), config);
                  }
                } }
                onClose={ () => this.setState({ activeRowKey: null, showTemplateModal: false }) }
              />
            }
            { activeRowKey && showServiceModal &&
              <Modal
                centered
                visible
                title={ `${ activeService.title || '' } - Services` }
                onCancel={ () => this.setState({ activeRowKey: null, showServiceModal: false, activeServicesList: [] }) }
                cancelText={ 'Close' }
                okButtonProps={{ style: { display: 'none' } }}
              >
                <Table
                  className="ov-a"
                  size={ 'small' }
                  columns={ [
                    {
                      key: 'title',
                      dataIndex: 'title',
                      title: 'Name',
                      render: (__: any, item: any) => {
                        if (_.has(item, 'path') && !!item.path) {
                          return (
                            <RouterLink className='primaryColor' to={ item.path }>
                              { item.title }
                            </RouterLink>
                          );
                        }
                        return item.title;
                      },
                      sorter: true,
                      ellipsis: true,
                    }
                  ] }
                  dataSource={ nestedSet(activeServicesList) }
                  loading={ isFetchingServices }
                  pagination={ false }
                  expandable={{
                    defaultExpandAllRows: true
                  }}
                />
              </Modal>
            }
            { showDuplicationDialog && this.renderDuplicationDialog(field) }
          </>
        </FieldWrapper>
        { showExportModal && this.renderExportModal() }
        { showImportModal && this.renderImportModal() }
      </>
    );
  };

};

export default OpexCostSummary;
