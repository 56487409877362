import store from 'store/Store';

// Actions
import {
  expireSession as expireSessionAction,
  logoutUser
} from 'store/User/Actions';
import { setIsBlocked } from 'store/UI/ActionCreators';

export function logout(hasSessionExpired?: boolean) {
  store.dispatch(logoutUser(hasSessionExpired));
};

export function expireSession() {
  store.dispatch(setIsBlocked(false));
  store.dispatch(expireSessionAction());
};
