// Libs
import * as React from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Select, Table, Button, Typography, TreeSelect, Tooltip } from 'antd';
import Dropdown from 'components/dropdown';

// Utils
import { nestedSet, appendDepth, flattenSet } from 'utils/utils';

// Services
import { getNumberFormatProps } from 'services/settings';

// Icons
import { QuestionCircleOutlined } from '@ant-design/icons';

// Styles
import './CostCalendar.scss';

const { Text } = Typography;
const { Option } = Select;

interface IColumn {
  reference: string;
  tooltip: string;
  title: string;
};

interface IDataRow {
  key: any;
  id: number;
  title: string;
  display_title: string;
  type: string;
  bundle: string;
  path: string;
  reference: string;
  children: IDataRow[];
  values: {
    [key: string]: number | null
  };
};

interface IConfig {
  currency: {
    id: number;
    code: string;
    title: string;
    symbol: string;
  };
};

interface IFilter {
  id: string;
  label: string;
  type: 'select' | 'multiselect';
  required: boolean;
  has_dependency: boolean;
  selected: string[] | number[];
  options: IFilterOption[];
};

interface IFilterOption {
  id: number;
  title: string;
};

export interface IStateFilter {
  [key: string]: string[] | number[];
};

interface Props {
  config: IConfig | null;
  columns: IColumn[];
  data: IDataRow[];
  filters: IFilter[];
  isLoading: boolean;
  onFilter: (filters: IStateFilter) => void;
  onExport?: (filters: IStateFilter, callback: (successful: boolean) => void) => void;
};

interface State {
  expandedRowKeys: string[];
  filters: IStateFilter;
  isLoading: boolean;
  isExporting: boolean;
};

const getDefaultFilters = (filters: IFilter[]) => {
  const _filters: IStateFilter = {};

  filters.forEach((filter: IFilter) => {
    if (!!filter?.selected && !_.isEmpty(filter.selected)) {
      _filters[filter.id] = filter.selected;
    }
  });

  return _filters;
};

class CostCalendar extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    expandedRowKeys: [],
    filters: getDefaultFilters(this.props.filters),
    isLoading: false,
    isExporting: false,
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!_.isEqual(this.props.filters, prevProps.filters)) {
      const defaultFilters = getDefaultFilters(this.props.filters);
      if (!_.isEqual(this.state.filters, defaultFilters)) {
        this.setState({
          filters: defaultFilters
        });
      }
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  calculateColumnTotal = (column: IColumn, data: IDataRow[]) => {
    return data.reduce((acc: any, dataRow: IDataRow) => {
      const value = !_.isNull(dataRow.values[column.reference]) ? `${dataRow.values[column.reference]}` : '0';
      return parseFloat(acc) + parseFloat(value);
    }, 0);
  };

  calculateChildren = (data: IDataRow[]) => {
    let count = 0;

    data.forEach((row: IDataRow) => {
      const check = (_row: IDataRow) => {
        count++;
        if (!_.isEmpty(_row?.children)) {
          _row.children.forEach((__row: IDataRow) => {
            check(__row);
          });
        }
      };

      check(row);
    });

    return count;
  };

  renderSelectFilter = (filter: IFilter, multiple: boolean) => {
    const options = filter?.options || [];

    return (
      <Select
        key={ `${filter.label}-${filter.id}` }
        className="mB-5 mR-5"
        showSearch
        allowClear
        mode={ multiple ? 'multiple' : undefined }
        style={{ width: 200 }}
        placeholder={ filter.label }
        dropdownMatchSelectWidth={ false }
        filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
        onChange={(value: number | string | string[] | number[]) => {
          let newFilters: any = _.cloneDeep(this.state.filters);

          if (!value || (_.isArray(value) && _.isEmpty(value))) {
            delete newFilters[filter.id];
          } else {
            newFilters = {
              ...this.state.filters,
              [filter.id]: multiple ? value : [value],
            };
          }

          this.setState({
            filters: newFilters
          });
        }}
        value={ multiple ? this.state.filters[filter.id] : !_.isEmpty(this.state.filters[filter.id]) && this.state.filters[filter.id][0] || undefined }
        // @ts-ignore
        autoComplete="none"
      >
        { options.map((option: any) => (
          <Option key={ option.id } value={ option.id }>
            { option.title }
          </Option>
        )) }
      </Select>
    );
  };

  renderNestedFilter = (filter: IFilter) => {
    return (
      <TreeSelect
        key={ `${filter.label}-${filter.id}` }
        className="mB-5 mR-5"
        style={{ width: 200 }}
        dropdownMatchSelectWidth={ false }
        placeholder={ filter.label }
        showCheckedStrategy={ TreeSelect.SHOW_PARENT }
        maxTagCount={ 5 }
        treeDefaultExpandedKeys={ filter.options.length > 1 ? [] : [filter.options[0].id] }
        treeCheckable
        multiple
        value={ this.state.filters[filter.id] ? this.state.filters[filter.id] : [] }
        treeData={ nestedSet(filter.options) }
        filterTreeNode={ (input: string, option: any) => {
          if (option) {
            const filteredInput = input.toLocaleLowerCase();
            const title = option.title && option.title.toLowerCase();

            if (title.includes(filteredInput)) {
              return true;
            }
          }

          return false;
        } }
        onChange={(value: any) => {
          let newFilters = this.state.filters;
          if (!value.length) {
            delete newFilters[filter.id];
          } else {
            newFilters = {
              ...this.state.filters,
              [filter.id]: value,
            };
          }
          this.setState({
            filters: newFilters,
          });
        }}
      />
    );
  };

  renderFilters = (filters: IFilter[]) => {
    return (
      <div style={{ marginBottom: 10 }}>
        { filters
          .map((filter: any, index: number) => {
            switch (filter.type) {
              case 'select':
              case 'multiselect':
                return this.renderSelectFilter(filter, filter.type === 'multiselect');
              case 'nested':
                return this.renderNestedFilter(filter);
              default:
                return <React.Fragment key={ index }></React.Fragment>;
            }
          })
        }
        <Button
          type='primary'
          ghost
          onClick={ () => this.props.onFilter(this.state.filters) }
          disabled={ this.props.isLoading || this.state.isLoading }
        >
          { 'Apply Filters' }
        </Button>
      </div>
    );
  };

  renderColumns = (data: any[], columns: IColumn[], config: IConfig | null, expandedRowKeys: string[]) => {

    const onCell = (dataRow: any) => {
      switch (dataRow?.depth) {
        case 0:
          return { className: 'bg-table-level-0' };
        case 1:
          return { className: 'bg-table-level-1' };
        default:
          return { className: 'bg-table-level-2' };
      }
    };

    const _columns: any = [
      {
        key: 'COA',
        title: (
          <>
            <span className="fw-600">COA</span>
            { !_.isEmpty(data) &&
              <Tooltip
                placement="top"
                title={ _.isEmpty(expandedRowKeys) ? 'Expand all' : 'Collapse all' }
              >
                <button
                  type="button"
                  style={{
                    marginTop: '2.5px',
                    marginRight: '8px',
                  }}
                  className={ classNames('ant-table-row-expand-icon', {
                    'ant-table-row-expand-icon-collapsed': _.isEmpty(expandedRowKeys),
                    'ant-table-row-expand-icon-expanded': !_.isEmpty(expandedRowKeys),
                  }) }
                  onClick={ () => {
                    if (_.isEmpty(expandedRowKeys)) {
                      this.setState({ expandedRowKeys: flattenSet(data).map((capex: any) => capex.key) });
                    } else {
                      this.setState({ expandedRowKeys: [] });
                    }
                  } }
                />
              </Tooltip>
            }
          </>
        ),
        width: 350,
        fixed: true,
        ellipsis: true,
        onCell: onCell,
        render: (__: any, dataRow: IDataRow) => {
          const suffix = !_.isEmpty(dataRow?.children) ? `(${this.calculateChildren(dataRow.children)})` : '';
          return `${dataRow?.title || '-'}${!!suffix ? ` ${suffix}` : ''}`;
        }
      }
    ];

    columns.forEach((column: IColumn, index: number) => {
      _columns.push({
        key: column?.reference || index,
        title: (
          <div className='d-f ai-c jc-fe'>
            <span className="fw-600">
              { column?.title || '-' }
            </span>
            { !!column?.tooltip &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ column.tooltip }
              >
                <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
              </Tooltip>
            }
          </div>
        ),
        width: 180,
        onCell: onCell,
        render: (__: any, dataRow: IDataRow) => {
          const value = !_.isNull(dataRow.values[column.reference]) ? dataRow.values[column.reference] : '-';
          return (
            <div className="ta-r">
              <NumberFormat
                { ...getNumberFormatProps() }
                value={ value }
                prefix={ config?.currency?.symbol }
                fixedDecimalScale={ 2 }
                decimalScale={ 2 }
                displayType={ 'text' }
              />
            </div>
          );
        }
      });
    });

    return _columns;
  };

  renderSummary = (columns: IColumn[], data: IDataRow[], config: IConfig | null) => {
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell key={ 'coa' } index={ 0 } colSpan={ 1 } />
          { columns
            .map((column: IColumn, index: number) => {
              return (
                <Table.Summary.Cell className="ta-r" key={ index+1 } index={ index+1 } colSpan={ 1 }>
                  <Text className="fw-600">
                    <NumberFormat
                      { ...getNumberFormatProps() }
                      fixedDecimalScale={ 2 }
                      decimalScale={ 2 }
                      displayType={ 'text' }
                      prefix={ config?.currency?.symbol }
                      value={ this.calculateColumnTotal(column, data) }
                    />
                  </Text>
                </Table.Summary.Cell>
              );
            })
          }
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  render = (): JSX.Element => {
    const { expandedRowKeys } = this.state;
    const data = appendDepth(nestedSet(this.props?.data || []));
    const columns: any[] = this.renderColumns(data, this.props.columns, this.props.config, expandedRowKeys);

    return (
      <div>
        <div className="d-f jc-sb ai-fs">
          <div className="d-if">
            { !_.isEmpty(this.props.filters) &&
              this.renderFilters(this.props.filters)
            }
          </div>
          <div className="d-if">
            { !!this.props?.onExport &&
              <Dropdown
                actions={ [{
                  disabled: _.isEmpty(data) ? ['Nothing to export'] : false,
                  isLoading: this.state.isExporting || this.state.isLoading || this.props.isLoading,
                  node: 'Export',
                  onClick: () => {
                    this.setState({ isExporting: true }, () => {
                      this.props.onExport && this.props.onExport(this.state.filters, (successful: boolean) => {
                        if (!successful) {
                          console.error('Failed');
                        }
                        this.setState({ isExporting: false });
                      });
                    });
                  }
                }] }
              />
            }
          </div>
        </div>
        <div className='Layout-box'>
          <Table
            className='CostCalendar'
            bordered
            sticky
            size={ 'small' }
            columns={ columns }
            showSorterTooltip={ false }
            dataSource={ data }
            pagination={ false }
            loading={ this.props.isLoading }
            scroll={{
              x: columns.reduce((acc: any, curr: any) => acc += curr?.width || 200, 0),
              y: `calc(100vh - 200px)`
            }}
            expandable={{
              expandedRowKeys: expandedRowKeys,
              onExpand: (expanded: boolean, dataRow: IDataRow) => {
                if (expanded) {
                  this.setState({ expandedRowKeys: [...expandedRowKeys, dataRow.key] });
                } else {
                  this.setState({ expandedRowKeys: !_.isEmpty(expandedRowKeys) ? expandedRowKeys.filter((rowKey) => rowKey !== dataRow?.key) : expandedRowKeys });
                }
              },
            }}
            summary={ () => this.renderSummary(this.props.columns, data, this.props.config) }
          />
        </div>
      </div>
    );
  };
};

export default CostCalendar;
