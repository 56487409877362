// Libs
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Table, Menu, Dropdown as AntDropdown, Modal, Button, Empty } from 'antd';
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import Badge, { BadgeType } from 'components/badge';
import AddResourceDialog from './AddResourceDialog';

// Interfaces
import { RecordFormEntity } from 'types/entities';

// Icons
import { PlusOutlined } from '@ant-design/icons';

interface State {
  showApproveDialog: boolean;
  showRejectDialog: boolean;
  showDeleteDialog: boolean;
  showAddDialog: boolean;
  activeRow: number | string | null;
};

interface Props {
  record: RecordFormEntity;
  field: FormField | any;
  clientId: number;
  originalState: any;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
    callback?: () => void,
  ): void;
  onRefreshForm(field_id: string): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

class Resource extends Component<Props, State> {

  state: State = {
    showApproveDialog: false,
    showRejectDialog: false,
    showDeleteDialog: false,
    showAddDialog: false,
    activeRow: null,
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  validate = (state: any) => {
    const { originalState } = this.props;
    this.generateModifiedState(originalState, state, 'resource');
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string) => {
    const { setFieldModifiedMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const _past = pastValue.map((value: any) => _.omit(value, ['id', 'key']));
    const _new = newValue.map((value: any) => _.omit(value, ['id', 'key']));

    if (!_.isEqual(_past, _new)) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  renderApproveDialog = (activeRow: string | number, values: any[]) => {
    const { field, config, onChange } = this.props;
    return (
      <Modal
        centered
        visible
        title={ 'Approve' }
        onOk={ () => {
          this.setState({
            showApproveDialog: false
          }, () => {
            onChange(field, _.cloneDeep(values).map((value: any) => {
              if (value.id === value.id || value.key === activeRow) {
                return {
                  ...value,
                  status: 'APPROVED'
                };
              }
              return value;
            }), config, undefined, () => {
              this.setState({ showApproveDialog: false, activeRow: null });
            });
          });
        } }
        onCancel={() => this.setState({ showApproveDialog: false, activeRow: null })}
      >
        <p>Are you sure you want to approve this resource?</p>
      </Modal>
    );
  };

  renderRejectDialog = (activeRow: string | number, values: any[]) => {
    const { field, config, onChange } = this.props;
    return (
      <Modal
        centered
        visible
        title={ 'Reject' }
        okButtonProps={{
          danger: true,
        }}
        onOk={ () => {
          this.setState({
            showRejectDialog: false
          }, () => {
            onChange(field, _.cloneDeep(values).map((value: any) => {
              if (value.id === value.id || value.key === activeRow) {
                return {
                  ...value,
                  status: 'REJECTED'
                };
              }
              return value;
            }), config, undefined, () => {
              this.setState({ showRejectDialog: false, activeRow: null });
            });
          });
        } }
        onCancel={() => this.setState({ showRejectDialog: false, activeRow: null })}
      >
        <p>Are you sure you want to reject this resource?</p>
      </Modal>
    );
  };

  renderDeleteDialog = (activeRow: string | number, values: any[]) => {
    const { field, config, onChange } = this.props;
    return (
      <Modal
        centered
        visible
        title={ 'Remove' }
        okButtonProps={{
          danger: true,
        }}
        onOk={ () => {
          onChange(field, _.cloneDeep(values).filter((value: any) => value.key !== activeRow && value.id !== activeRow), config, undefined, () => {
            this.setState({ showDeleteDialog: false, activeRow: null });
          });
        } }
        onCancel={ () => this.setState({ showDeleteDialog: false, activeRow: null }) }
      >
        <p>Are you sure you want to remove this resource?</p>
      </Modal>
    );
  };

  render = () => {
    const { field, record, config, clientId, border, state, onChange, isDisabled, fieldModifiedMessages, fieldErrorMessages } = this.props;
    const {
      activeRow,
      showAddDialog,
      showApproveDialog,
      showRejectDialog,
      showDeleteDialog,
    } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_resource`;
    const isModified = _.has(fieldModifiedMessages, key);

    const data = state ? state.map((value: any, index: number) => {
      return {
        key: index+1,
        ...value
      };
    }) : [];

    const columns: any = [
      {
        dataIndex: 'type',
        key: 'type',
        title: 'Type',
        render: (type: 'DIRECT' | 'INHERIT') => {
          if (type) {
            return <span>{ _.upperFirst(_.toLower(type)) }</span>;
          }
          return <>-</>;
        },
      },
      {
        title: 'User',
        key: 'user',
        dataIndex: 'user',
        render: (user: { id: number, title: string, company: string} ) => {
          if (user) {
            return user?.title || '-';
          }
          return <></>;
        },
      },
      {
        title: 'Company',
        key: 'company',
        dataIndex: 'company',
        render: (__: any, record: any) => {

          if (!!record?.user?.company && !!record?.user?.company?.path) {
            return (
              <RouterLink className='d-f primaryColor' to={ record?.user?.company?.path }>
                { record?.user?.company?.title }
              </RouterLink>
            );
          }
          return (
            <span>-</span>
          );
        },
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: (status: 'APPROVED' | 'REJECTED' | 'PENDING') => {
          switch (status) {
            case 'APPROVED':
              return <Badge type={ BadgeType.Success } text={ 'Approved' } />;
            case 'REJECTED':
              return <Badge type={ BadgeType.Danger } text={ 'Rejected' } />;
            case 'PENDING':
              return <Badge type={ BadgeType.Warning } text={ 'Pending' } />;
            default:
              return <>-</>;
          }
        },
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        render: (__: any, record: any) => {
          let options = [];

          if (field?.config?.can_approve && record.status === 'PENDING') {
            options.push('APPROVE');
          }

          if (field?.config?.can_reject && record.status === 'PENDING') {
            options.push('REJECT');
          }

          if (field?.config?.can_delete) {
            options.push('DELETE');
          }

          if (record?.type === 'INHERITED') {
            options = [];
          }

          return (
            <AntDropdown.Button
              disabled={ _.isEmpty(options) }
              trigger={['click']}
              overlay={
                <Menu>
                  { options.includes('APPROVE') &&
                    <Menu.Item
                      key="APPROVE"
                      onClick={ () => {
                        this.setState({ activeRow: record.key, showApproveDialog: true });
                      } }
                    >
                      Approve
                    </Menu.Item>
                  }
                  { options.includes('REJECT') &&
                    <Menu.Item
                      key="REJECT"
                      onClick={ () => {
                        this.setState({ activeRow: record.key, showRejectDialog: true });
                      } }
                    >
                      Reject
                    </Menu.Item>
                  }
                  { options.includes('DELETE') &&
                    <Menu.Item
                      key="DELETE"
                      danger
                      onClick={ () => {
                        this.setState({ activeRow: record.key, showDeleteDialog: true });
                      } }
                    >
                      Remove
                    </Menu.Item>
                  }
                </Menu>
              }
            />
          );
        },
        width: 100,
        sorter: false,
        ellipsis: true,
        filterable: false,
        align: 'center'
      },
    ];

    return (
      <>
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ 12 }
          label={ field.label }
          hideErrorInfo
          required={ field.config.required }
          border={ border }
          description={ !!field.description && field.description }
          isModified={ isModified }
          refreshOnChange={ !!field.config.refresh_on_change }
          versionChanged={ !!field.config.version_changed }
          rightActions={[
            {
              node: (
                <>
                  { !isDisabled &&
                    <Button
                      className="mL-5 mB-10"
                      style={{
                        marginLeft: 5,
                        padding: '4px 7px',
                        width: '32px',
                      }}
                      onClick={ () => this.setState({ showAddDialog: true }) }
                    >
                      <PlusOutlined />
                    </Button>
                  }
                </>
              )
            }
          ]}
        >
          <div className="w-100p">
            <Table
              size={ 'small' }
              pagination={ false }
              columns={ columns }
              dataSource={ data }
              locale={ {
                emptyText: () => (
                  <Empty
                    style={{ marginTop: 10, marginBottom: 10 }}
                    imageStyle={{ height: 30 }}
                    image={ Empty.PRESENTED_IMAGE_SIMPLE }
                    description={ <span className='fsz-xs'>No data</span> }
                  />
                )
              } }
            />
            { showApproveDialog && activeRow && this.renderApproveDialog(activeRow, data) }
            { showRejectDialog && activeRow && this.renderRejectDialog(activeRow, data) }
            { showDeleteDialog && activeRow && this.renderDeleteDialog(activeRow, data) }
            { showAddDialog &&
              <AddResourceDialog
                clientId={ clientId }
                record={ record }
                selectedRoleId={ field.role.id }
                excludeUsers={ data.map((resource: any) => resource.user.id) }
                onCreate={ (user: {
                  id: number,
                  company: string,
                  title: string
                }) => {
                  this.setState({
                    showAddDialog: false
                  }, () => {
                    onChange(field, field.values.concat([{
                      key: uuidv4(),
                      id: null,
                      status: 'PENDING',
                      type: 'DIRECT',
                      user: user
                    }]), config);
                  });
                } }
                onClose={ () => this.setState({ showAddDialog: false }) }
              />
            }
          </div>
        </FieldWrapper>
      </>
    );
  };
};

export default Resource;
