// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Modal, Tabs, Table, Select, Input, Tooltip, Button } from 'antd';
import { regionMap } from 'components/resources-table';
import BlockingSpinner from 'components/blocking-spinner/BlockingSpinner';

// Interfaces
import { FormField } from 'components/form/form-wrapper';
import { RecordFormEntity } from 'types/entities';

// Services
import { Api } from 'services/api';

interface Props {
  record: RecordFormEntity;
  field: any;
  clientId: number;
  onSave(field: FormField): void;
  onClose(): void;
};

interface State {
  activeTab: string;
  field: any;
  isFetchingRegions: boolean,
  isFetchingProperties: boolean,
  selectedRegions: any,
  selectedProperties: any[],
  filter: string | null,
  search: string | null,
  availableProperties: any[],
  availableRegions: any[],
};

const API: Api = new Api();
const { TabPane } = Tabs;
const { Option } = Select;
const { Search } = Input;

const getSelectedRegions = (field: any) => {
  const selectedRegions: any[] = [];

  field.values.forEach((property: any) => {
    property.selected_regions.forEach((region_id: number) => {
      selectedRegions.push(region_id);
    });
  });

  return _.union(selectedRegions);
};

const getSelectedProperties = (field: any) => {
  return field.values.map((property: any) => `${property.target_bundle}-${property.target_type}-${property.target_id}`);
};

class PropertySelectionModal extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    activeTab: 'regions',
    field: _.cloneDeep(this.props.field),
    selectedRegions: getSelectedRegions(this.props.field),
    selectedProperties: getSelectedProperties(this.props.field),
    availableRegions: [],
    availableProperties: [],
    filter: null,
    search: null,
    isFetchingRegions: false,
    isFetchingProperties: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getRegions();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { activeTab, selectedRegions, selectedProperties, availableProperties } = this.state;

    if (prevState.activeTab !== activeTab) {
      switch (activeTab) {
        case 'regions':
          this.getRegions();
        break;
        case 'properties':
          this.getProperties(selectedRegions);
        break;
      }
    }

    if (prevState.selectedProperties !== selectedProperties) {
      const properties = selectedProperties.map((selectedProperty: any) => {
        return availableProperties.find((availableProperty: any) => `${availableProperty.target_bundle}-${availableProperty.target_type}-${availableProperty.target_id}` === selectedProperty);
      });
      this.setState({
        field: _.set(this.state.field, ['values'], properties),
      });
    }

    // Filter out properties that's not tied to the selected regions
    if (prevState.availableProperties !== availableProperties) {
      this.setState({
        selectedProperties: selectedProperties.filter((selectedProperty: any) => {
          return availableProperties.find((property: any) => `${property.target_bundle}-${property.target_type}-${property.target_id}` === selectedProperty);
        })
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleChange = (field: any) => {
    this.setState({
      field: field
    });
  };

  canSelectMultiple = () => {
    return this.props.field.config.cardinality !== 1;
  };

  filter = (properties: any[], value: any) => {
    return properties.filter((_property: any) => _.has(_property, 'location') && !!_property.location && _property.location.title.toLowerCase().includes(value.toLocaleLowerCase()));
  };

  search = (properties: any[], value: any) => {
    return properties.filter((_property: any) => _property.title.toLowerCase().includes(value.toLocaleLowerCase()));
  };

  getRegions = async () => {
    try {
      await new Promise((resolve) => this.setState({ isFetchingRegions: true }, () => resolve(null)));

      const availableRegions = await API.get(`client/${this.props.clientId}/field/property_relationship/parent_property/regions`);

      this.mounted && this.setState({
        availableRegions: availableRegions
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetchingRegions: false,
      });
    }
  };

  getProperties = async (selectedRegions: any[]) => {
    const { record } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isFetchingProperties: true }, () => resolve(null)));

      const availableProperties = await API.get(`client/${this.props.clientId}/field/property_relationship/parent_property/properties`, {
        entity_id: record.id,
        entity_type: record.type.replaceAll('-', '_'),
        entity_bundle: record.bundle.replaceAll('-', '_'),
        regions: selectedRegions
      });

      this.mounted && this.setState({
        availableProperties: availableProperties
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetchingProperties: false,
      });
    }
  };

  renderRegionTab = () => {
    const { availableRegions, isFetchingRegions, selectedRegions } = this.state;

    if (isFetchingRegions || _.isEmpty(availableRegions)) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    return (
      <Table
        className="ov-s"
        size={ 'small' }
        style={{ height: 450 }}
        bordered
        showHeader={ false }
        columns={ [
          {
            render: (region: any) => <span>{ region.title }</span>
          }
        ] }
        dataSource={ !_.isEmpty(availableRegions) ? regionMap(availableRegions) : [] }
        pagination={ false }
        expandable={{
          defaultExpandAllRows: true
        }}
        rowSelection={{
          checkStrictly: false,
          selectedRowKeys: selectedRegions,
          onChange: (selectedRowKeys: any, _selectedRegions: any) => {
            this.setState({
              selectedRegions: selectedRowKeys,
            });
          }
        }}
      />
    );
  };

  renderPropertiesTab = () => {
    const { availableProperties, isFetchingProperties, selectedProperties, filter, search } = this.state;

    if (isFetchingProperties) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    let properties = availableProperties.map((property: any) => {
      return {
        key: `${property.target_bundle}-${property.target_type}-${property.target_id}`,
        title: property.target_title,
        ...property
      };
    });

    const locations: any[] = availableProperties
      .filter((property: any) => _.has(property, 'location') && !!property.location)
      .map((property: any) => property.location)
      .reduce((acc, cur) => [
        ...acc.filter((location: any) => location.id !== cur.id), cur
      ], []);

    if (!!filter) {
      properties = this.filter(properties, filter);
    }

    if (!!search) {
      properties = this.search(properties, search);
    }

    return (
      <>
        <div className="d-f">
          <Search
            className="d-if"
            disabled={ _.isEmpty(availableProperties) }
            style={{ width: 350 }}
            placeholder="Search for Property"
            onSearch={ (value: string) => this.setState({ search: value }) }
          />
          <Select
            className="d-if mL-10"
            style={{ width: 350 }}
            allowClear
            showSearch
            onSelect={ (title: string) => {
              this.setState({ filter: title });
            } }
            onClear={ () => {
              this.setState({ filter: null });
            } }
            placeholder={ 'Filter by Location' }
            disabled={ _.isEmpty(locations) }
            filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
            // @ts-ignore
            autoComplete="none"
          >
            { locations.map((location: any) => {
              return (
                <Option
                  key={ location.id }
                  value={ location.title }
                >
                  { location.title }
                </Option>
              );
            }) }
          </Select>
        </div>
        <Table
          className="ov-s mT-10"
          size={ 'small' }
          style={{ height: 408 }}
          bordered
          columns={ [
            {
              title: 'Property',
              render: (property: any) => <span>{ property.title }</span>,
              width: 300,
            },
            {
              title: 'Ownership',
              render: (property: any) => {
                if (!_.has(property, 'ownership')) return <span>-</span>;
                return (
                  <span>{ property.ownership }</span>
                );
              }
            },
            {
              title: 'Location',
              render: (property: any) => {
                if (!_.has(property, 'location') || _.isEmpty(property.location)) return <span>-</span>;
                return (
                  <span>{ property.location.title }</span>
                );
              }
            },
          ] }
          dataSource={ properties || [] }
          pagination={ false }
          rowSelection={{
            hideSelectAll: !this.canSelectMultiple(),
            selectedRowKeys: selectedProperties,
            renderCell: (checked: boolean, record: any, index: number, originNode: any) => {

              if (!this.canSelectMultiple() && !_.isEmpty(selectedProperties) && !selectedProperties.includes(record.key)) {
                return (
                  <Tooltip key={ index } title={ 'Can only select one property' } placement={ 'top' }>
                    { React.cloneElement((originNode), { disabled: true }) }
                  </Tooltip>
                );
              }

              return originNode;
            },
            onSelectAll: (selected: boolean, selectedRows: any, changeRows: any) => {
              if (selected) {
                // Select everything in the filtered list
                const newSelectedProperties = selectedProperties.concat(selectedRows.filter((selectedRow: any) => !!selectedRow).map((selectedRow: any) => selectedRow.key));
                this.setState({
                  selectedProperties: _.uniq(newSelectedProperties)
                });
              } else {
                const keys = changeRows.map((property: any) => property.key);
                this.setState({ selectedProperties: selectedProperties.filter((_selectedProperty: any) => !keys.includes(_selectedProperty)) });
              }
            },
            onSelect: (property: any) => {
              if (selectedProperties.includes(property.key)) {
                this.setState({ selectedProperties: selectedProperties.filter((_property: any) => _property !== property.key) });
              } else {
                if (!this.canSelectMultiple()) {
                  this.setState({ selectedProperties: [property.key] });
                } else {
                  this.setState({ selectedProperties: selectedProperties.concat([property.key]) });
                }
              }
            }
          }}
        />
      </>
    );
  };

  render = () => {
    const { field, activeTab, selectedProperties, isFetchingProperties } = this.state;
    const footer = (
      <div className='d-f jc-sb ai-c'>
        <div>
          { activeTab === 'properties' && this.canSelectMultiple() && !isFetchingProperties &&
            <span>{`Selected ${selectedProperties.length} item`}{ `${ selectedProperties.length > 1 ? 's' : '' }` }</span>
          }
        </div>
        <div>
          <Button
            key={ 'cancel' }
            onClick={ () => this.props.onClose() }
          >
            Cancel
          </Button>
          <Button
            key={ 'ok' }
            disabled={ _.isEqual(field.values, this.props.field.values) }
            onClick={ () => this.props.onSave(field) }
          >
            Ok
          </Button>
        </div>
      </div>
    );
    return (
      <Modal
        centered
        closable={ false }
        visible
        title={ 'Property Picker' }
        footer={ footer }
        style={{ minWidth: 1000 }}
      >
        <Tabs
          centered
          activeKey={ activeTab }
          onTabClick={ (tab: string) => this.setState({ activeTab: tab }) }
        >
          <TabPane tab="Regions" key="regions">
            { this.renderRegionTab() }
          </TabPane>
          <TabPane tab="Properties" key="properties">
            { this.renderPropertiesTab() }
          </TabPane>
        </Tabs>
      </Modal>
    );
  };
};

export default PropertySelectionModal;
