// Libs
import * as React from 'react';

// Components
import { Form, Input, Modal, Select, Tooltip } from 'antd';

// Icons
import { EyeOutlined } from "@ant-design/icons";

// Services
import { Api } from 'services/api';

// Interfaces
import { FinanceTemplateColumn as IFinanceTemplateColumn } from 'views/admin/templates/Templates.interfaces';

const API: Api = new Api();
const { TextArea } = Input;

interface Props {
  client_id: number;
  templateColumn?: IFinanceTemplateColumn;
  onSave(templateColumn: IFinanceTemplateColumn): void;
  onClose(): void;
};

interface State {
  availableTypes: string[] | null;
  templateColumn: IFinanceTemplateColumn | null;
  isLoading: boolean;
};

class FinanceTemplateColumnModal extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    templateColumn: this.props.templateColumn || null,
    availableTypes: null,
    isLoading: false,
  };

  componentDidMount = async () => {
    const { client_id } = this.props;

    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const availableTypes = await API.get(`client/${client_id}/admin/templates/finance/available_template_column_types`);

      this.mounted && this.setState({
        availableTypes: availableTypes,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderConfig = (templateColumn: IFinanceTemplateColumn) => {

    let config = null;

    switch (templateColumn.type) {
      case 'formula':
        config = (
          <Input
            disabled
            value={ templateColumn?.config.formula }
          />
        );
    }

    if (config) {
      return (
        <Form.Item
          label={ (
            <>
              <span>Config</span>
              <Tooltip
                className="mL-5"
                placement="top"
                title={ 'Read Only' }
              >
                <EyeOutlined className="cur-p fsz-def text-ant-default" />
              </Tooltip>
            </>
          ) }
        >
          { config }
        </Form.Item>
      );
    }

    return <></>;
  };

  render = () => {
    const { onSave, onClose } = this.props;
    const { isLoading, availableTypes, templateColumn } = this.state;

    return (
      <Modal
        title={ 'Template Column' }
        maskClosable={ !isLoading }
        closable={ !isLoading }
        centered
        visible
        onCancel={ () => onClose() }
        onOk={ () => this.setState({
          isLoading: true
        }, () => templateColumn && onSave(templateColumn)) }
        okText={ 'Save' }
        okButtonProps={{
          disabled: isLoading || !templateColumn?.label || !templateColumn.type,
          loading: isLoading,
        }}
        cancelButtonProps={{
          disabled: isLoading,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Label" required>
            <Input
              value={ templateColumn?.label }
              onChange={ (e) => {
                this.setState({
                  templateColumn: Object.assign(templateColumn, { label: e.target.value })
                });
              } }
            />
          </Form.Item>
          <Form.Item label="Description">
            <TextArea
              rows={ 4 }
              value={ templateColumn?.description }
              onChange={ (e) => {
                this.setState({
                  templateColumn: Object.assign(templateColumn, { description: e.target.value })
                });
              } }
            />
          </Form.Item>
          <Form.Item
            label={ (
              <>
                <span>Type</span>
                <Tooltip
                  className="mL-5"
                  placement="top"
                  title={ 'Read Only' }
                >
                  <EyeOutlined className="cur-p fsz-def text-ant-default" />
                </Tooltip>
              </>
            ) }
          >
            <Select
              disabled
              value={ templateColumn?.type }
              placeholder={ 'Please select a type' }
              onChange={ (type: any) => {
                this.setState({
                  templateColumn: Object.assign(templateColumn, { type: type })
                });
              } }
            >
              { availableTypes && availableTypes.map((type) => (
                <Select.Option key={ type } value={ type }>
                  { type }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          <div className="mT-10">
            { templateColumn && this.renderConfig(templateColumn) }
          </div>
        </Form>
      </Modal>
    );
  };
}

export default FinanceTemplateColumnModal;
