// Libs
import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

// Components
import Jumbotron from "components/jumbotron";
import BlockingSpinner from 'components/blocking-spinner';
import BasicList, { Config } from "components/basic-list";

// Api
import { Api } from 'services/api';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";

interface Props {
  client_id: number,
  intl: IntlShape;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  columns: any[];
  items: any[];
  config: Config | null;
  isFetching: boolean;
};

const API: Api = new Api();

const messages = defineMessages({
  create: {
    id: 'general.create',
    defaultMessage: 'Create',
    description: '',
  },
  title: {
    id: 'locations.column_title',
    defaultMessage: 'Title',
    description: '',
  },
  created: {
    id: 'locations.column_created',
    defaultMessage: 'Created',
    description: '',
  },
  list_view: {
    id: 'locations.list_view',
    defaultMessage: 'List View',
    description: '',
  },
  map_view: {
    id: 'locations.map_view',
    defaultMessage: 'Map View',
    description: '',
  },
});

class HelpArticle extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    columns: [],
    items: [],
    config: null,
    isFetching: false,
  };

  componentDidMount = async () => {
    const { client_id } = this.props;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Help Articles', path: '/help-articles' }
    ], false);

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));
      const record = await API.get(`client/${client_id}/help-articles`);

      this.mounted && this.setState({
        columns: record.columns,
        items: record.data,
        config: record.config,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderListView = () => {
    return <BasicList config={ this.state.config } columns={ this.state.columns } items={ this.state.items } />;
  };

  render = () => {
    const { intl: { formatMessage } } = this.props;
    const { isFetching } = this.state;

    const tabs = [
      {
        label: formatMessage(messages.list_view),
        node: this.renderListView(),
      }
    ];

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ 'Help Articles' }
          tabs={ tabs }
        />
      </BlockingSpinner>
    );
  };

};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HelpArticle));
