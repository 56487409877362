// Libs
import React from 'react';
import classNames from 'classnames';

// Components
import { Modal, Form, Select } from 'antd';

// Api
import { Api } from 'services/api';

interface Props {
  clientId: number;
  onCreate(bundle: string, type: string, record_id: number, roleId: number): void;
  onClose(): void;
};

interface State {
  bundle: string | null;
  entityType: string | null;
  recordId: number | null;
  roleId: number | null;
  availableEntityTypes: any[];
  availableRecords: any[];
  availableRoles: any[];
  isCreating: boolean;
  isLoadingRecordTypes: boolean;
  isLoadingRecords: boolean;
  isLoadingRoles: boolean;
};

const API: Api = new Api();
const { Option } = Select;

class CreateResourceDialog extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    bundle: null,
    entityType: null,
    recordId: null,
    roleId: null,
    availableEntityTypes: [],
    availableRecords: [],
    availableRoles: [],
    isCreating: false,
    isLoadingRecordTypes: false,
    isLoadingRecords: false,
    isLoadingRoles: false,
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { clientId } = this.props;

    // Load record types
    if (prevState.isLoadingRecordTypes !== this.state.isLoadingRecordTypes && this.state.isLoadingRecordTypes) {
      try {

        const recordTypes = await API.get(`client/${clientId}/admin/roles/available_record_types`, {
          bundle: this.state.bundle
        });

        this.mounted && this.setState({
          availableEntityTypes: recordTypes
        });

      } catch (error) {
        console.error('Error: ', error);
      } finally {
        this.mounted && this.setState({
          isLoadingRecordTypes: false
        });
      }
    }

    // Load records from record type
    if ((prevState.isLoadingRecords !== this.state.isLoadingRecords && this.state.isLoadingRecords && !!this.state.entityType)) {
      try {

        const records = await API.get(`client/${clientId}/admin/roles/available_records/`, {
          bundle: this.state.bundle,
          type: this.state.entityType,
        });

        this.mounted && this.setState({
          availableRecords: records,
          recordId: null,
          roleId: null,
        });

      } catch (error) {
        console.error('Error: ', error);
      } finally {
        this.mounted && this.setState({
          isLoadingRecords: false
        });
      }
    }

    // Load roles from record
    if ((prevState.isLoadingRoles !== this.state.isLoadingRoles && this.state.isLoadingRoles && !!this.state.entityType)) {
      try {

        const roles = await API.get(`client/${clientId}/admin/roles/available_record_roles`, {
          bundle: this.state.bundle,
          type: this.state.entityType,
        });

        this.mounted && this.setState({
          availableRoles: roles,
          roleId: null,
        });

      } catch (error) {
        console.error('Error: ', error);
      } finally {
        this.mounted && this.setState({
          isLoadingRoles: false
        });
      }
    }
  };

  render = () => {
    const { onCreate, onClose } = this.props;
    const {
      bundle,
      entityType,
      recordId,
      roleId,
      availableEntityTypes,
      availableRecords,
      availableRoles,
      isLoadingRecordTypes,
      isLoadingRecords,
      isLoadingRoles,
      isCreating,
    } = this.state;
    return (
      <Modal
        visible
        title={ 'Create Role' }
        maskClosable={ !isCreating }
        closable={ !isCreating }
        okButtonProps={{
          disabled: !entityType || !recordId || !roleId,
          loading: isCreating
        }}
        cancelButtonProps={{
          disabled: isCreating,
        }}
        onOk={ () => this.setState({
            isCreating: true,
          }, () => {
            if (!!bundle && !!entityType && !!recordId && !!roleId) {
              onCreate(bundle, entityType, recordId, roleId);
            }
          }
        ) }
        onCancel={ () => onClose() }
      >
        <Form layout="vertical">
          <Form.Item label="Bundle" required>
            <Select
              className={ classNames('Select-Field') }
              onChange={ (bundle: string) => this.setState({
                bundle: bundle,
                isLoadingRecordTypes: true
              }) }
            >
              <Option key={ 'record' } value={ 'record' }>{ 'Record' }</Option>
              <Option key={ 'company' } value={ 'company' }>{ 'Company' }</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Record Type" required>
            <Select
              className={ classNames('Select-Field') }
              disabled={ !bundle || isLoadingRecordTypes }
              loading={ isLoadingRecordTypes }
              onChange={ (type: string) => this.setState({
                entityType: type,
                isLoadingRecords: true
              }) }
            >
              { availableEntityTypes.map( (entity_type: any) => (
                <Option key={ entity_type.type } value={ entity_type.type }>{ entity_type.label }</Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item label="Record" required>
            <Select
              className={ classNames('Select-Field') }
              disabled={ !entityType || isLoadingRecords }
              loading={ isLoadingRecords }
              onChange={ (recordId: number) => this.setState({
                recordId: recordId,
                isLoadingRoles: true,
              }) }
              value={ recordId || undefined }
            >
              { availableRecords.map( (record: any) => (
                <Option key={ record.id } value={ record.id }>{ record.title }</Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item label="Role" required>
            <Select
              className={ classNames('Select-Field') }
              disabled={ !recordId || !entityType || isLoadingRoles }
              loading={ isLoadingRoles }
              onChange={ (roleId: number) => this.setState({
                roleId: roleId
              }) }
              value={ roleId || undefined }
            >
              { availableRoles.map( (role: any) => (
                <Option key={ role.id } value={ role.id }>{ role.title }</Option>
              )) }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
};

export default CreateResourceDialog;
