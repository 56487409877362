// Libs
import React, { Component } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';

// Component
import FieldWrapper from 'components/form/field/field-wrapper';

// Interfaces
import {
  Currency as CurrencyProp,
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
  FormValues
} from 'components/form/form-wrapper';

interface Props {
  field: FormField;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  onRefreshForm(field_id: string): void;
  originalState: FormValues;
  state: FormValues;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

const { Option } = Select;

class CurrencyUnitField extends Component<Props> {

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { field, originalState } = this.props;
    const columnKeys = Object.keys(field.columns);

    columnKeys.forEach((columnKey: string) => {
      this.generateModifiedState(originalState[columnKey], state[columnKey], columnKey, shouldClear);
      this.generateErrorState(state[columnKey], columnKey, shouldClear);
    });
  };

  generateLabel = (value: string | number, currencies: CurrencyProp[]): string => {
    const currency = currencies.find((currency: CurrencyProp) => currency.code === value);

    if (!!currency) {
      return `[${currency.code}] ${currency.title} (${currency.symbol})`;
    }

    return '';
  };

  generateModifiedState = (pastValue: string | number, newValue: string | number, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { setFieldErrorMessage, field, config, validate } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const errors = validate(field, columnKey, value);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  render = () => {
    const {
      field,
      state,
      config,
      border,
      isDisabled,
      fieldErrorMessages,
      fieldModifiedMessages,
      onChange,
    } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_currency`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const currencies = field.currencies ? field.currencies : [];
    const value = state.currency || '';

    if (!!isDisabled) {
      return (
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          border={ border }
          refreshOnChange={ !!field.config.refresh_on_change }
          versionChanged={ !!field.config.version_changed }
          description={ !!field.description && field.description }
        >
          <span className="d-b pY-5">{ this.generateLabel(value, currencies) }</span>
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ errors }
        required={ field.config.required }
        border={ border }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <Select
          showSearch
          className={classNames('Select-Field', {
            'Select-Field--has-warning border-warning': isModified && _.isEmpty(errors),
          })}
          allowClear={ !field.config.required }
          onSelect={ (__: any, option: any) => onChange(field, option.value, config, 'currency') }
          onClear={ () => onChange(field, null, config) }
          placeholder={ field.label }
          value={ this.generateLabel(value, currencies) || undefined }
          disabled={ isDisabled }
          filterOption={ (input: string, option) => {
            const index = option?.value || 0;
            const currencyOption = currencies[index];

            if (currencyOption && currencyOption.title) {
              const filteredInput = input.toLocaleLowerCase();
              const title = currencyOption.title.toLowerCase();
              const code = currencyOption.code.toLowerCase();
              const label = this.generateLabel(value, currencies);
              const simplifiedLabel = `${code} ${title} ${currencyOption.symbol}`;

              if (
                title.includes(filteredInput) ||
                code.includes(filteredInput) ||
                label.includes(filteredInput) ||
                simplifiedLabel.includes(filteredInput) ||
                (currencyOption.symbol && currencyOption.symbol.includes(filteredInput))
              ) {
                return true;
              }
            }

            return false;
          } }
        >
          { currencies.map((currency: CurrencyProp) => {
            return (
              <Option
                key={ `${field.id}-list-currency_unit-option-${currency.id}` }
                value={ currency.code }
              >
                { this.generateLabel(currency.code, currencies) }
              </Option>
            );
          }) }
        </Select>
      </FieldWrapper>
    );
  };
}

export default CurrencyUnitField;
