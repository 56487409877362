// Libs
import * as React from 'react';

// Components
import GroupView from 'views/common/GroupView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  getRecord(silent?: boolean): void;
};

class CustomerGroups extends React.Component<Props> {
  render = () => {
    return <GroupView record={ this.props.record } type={ 'customer' } entity={ 'company' } getRecord={ this.props.getRecord } />;
  };
};

export default CustomerGroups;
