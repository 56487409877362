// Libs
import * as React from 'react';
import _ from 'lodash';

// Components
import { TabView } from 'components/tab-view';

// Icons
import { LoadingOutlined } from '@ant-design/icons';

// Interfaces
import { Tab } from 'components/tab-view/TabView.interface';

export interface Action {
  node: React.ReactElement;
  isLoading?: boolean;
};

export interface Props {
  title?: React.ReactNode;
  content?: React.ReactNode;
  tabs: Tab[];
  onChange?: (tab: string) => void;
  defaultActiveTab?: string;
  destroyInactiveTabPane?: boolean;
  rightActions?: Action[];
};

class Jumbotron extends React.Component<Props> {

  render = () => {
    const {
      content,
      title,
      tabs,
      rightActions = [],
      onChange = () => {},
      defaultActiveTab,
    } = this.props;

    const hideBar = this.props.tabs?.length === 1 && _.has(this.props.tabs[0], 'hidden');

    return (
      <div className="Layout-wrapper">
        <div id="Jumbotron" className='d-f jc-sb bg-tab-grey pT-30 pL-30 pR-30 pB-15'>
          <div className="d-f fxd-c jc-sb bg-tab-grey">
            { title &&
              <div className="fsz-lg fw-600">
                { title }
              </div>
            }
            <div className="fsz-sm fw-500">
              { content }
            </div>
          </div>
          { !_.isEmpty(rightActions) && rightActions.map((action: Action, index: number) => (
              <span key={ index }>
                { !!action.isLoading ? (
                  <LoadingOutlined className="fsz-def text-ant-default" />
                ) : (
                  action.node
                ) }
              </span>
            ) )
          }
        </div>
        <TabView
          barStyle={ hideBar ? { display: 'none' } : {} }
          paneStyle={ hideBar ? { padding: 0 } : {} }
          tabs={ tabs }
          defaultActiveTab={ defaultActiveTab }
          destroyInactiveTabPane={ this.props.destroyInactiveTabPane }
          onChange={ onChange }
        />
      </div>
    );
  };
};

export default Jumbotron;
