// Libs
import React, { Component } from 'react';
import { Select, Input } from 'antd';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

// Component
import FieldWrapper from 'components/form/field/field-wrapper';

// Interfaces
import {
  FormField,
  FormValues,
  Currency as CurrencyProp,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
} from 'components/form/form-wrapper';

// Utils
import { formatCostToNumber } from 'utils/utils';

// Styles
import './Currency.scss';

interface Props {
  numberFormat: any;
  field: FormField;
  originalState: FormValues;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  onRefreshForm(field_id: string): void;
  state: FormValues;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  state: FormValues;
};

const { Option } = Select;

class Currency extends Component<Props, State> {

  state: State = {
    state: this.props.state
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { field } = this.props;

    if (!_.isEqual(prevProps.state, this.props.state)) {
      // Set state downwards
      this.setState({
        state: this.props.state
      });
    } else if (!_.isEqual(prevState.state, this.state.state)) {
      // Roll state upwards
      this.handleChange(this.state.state);
    }

    if (!_.isEqual(prevProps.field, this.props.field)) {
      this.validate(this.props.state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, [originalState], config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { field, originalState } = this.props;
    const columnKeys = Object.keys(field.columns);

    columnKeys.forEach((columnKey: string) => {
      this.generateModifiedState(originalState[columnKey], state[columnKey], columnKey, shouldClear);
      this.generateErrorState(state[columnKey], columnKey, shouldClear);
    });
  };

  handleChange = _.debounce((state: any) => {
    this.props.onChange(this.props.field, state.value, this.props.config, 'value');
  }, 500);

  generateModifiedState = (pastValue: string | number, newValue: string | number, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {

      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { setFieldErrorMessage, field, config, validate } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const errors = validate(field, columnKey, value);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  render = () => {
    const {
      field,
      config,
      border,
      isDisabled,
      fieldErrorMessages,
      fieldModifiedMessages,
      onChange,
      numberFormat,
    } = this.props;
    const { state } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const currencies = field.currencies ? field.currencies : [];
    const currencyUnit = !!state.currency ? _.isString(state.currency) ? state.currency : state.currency.toString() : '';
    const value = state.value ? state.value : undefined;
    const decimal = _.has(field, 'config.decimal') ? field.config.decimal : 2;

    const unitErrors = _.has(fieldErrorMessages, `${id}_${cardinality}_currency`) ? fieldErrorMessages[`${id}_${cardinality}_currency`].errors : [];
    const unitModified = _.has(fieldModifiedMessages, `${id}_${cardinality}_currency`);
    const valueErrors = _.has(fieldErrorMessages, `${id}_${cardinality}_value`) ? fieldErrorMessages[`${id}_${cardinality}_value`].errors : [];
    const valueModified = _.has(fieldModifiedMessages, `${id}_${cardinality}_value`);

    if (!!isDisabled) {
      return (
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          border={ border }
          refreshOnChange={ !!field.config.refresh_on_change }
          versionChanged={ !!field.config.version_changed }
          description={ !!field.description && field.description }
        >
          <NumberFormat
            { ...numberFormat }
            fixedDecimalScale={ !!decimal }
            decimalScale={ decimal }
            value={ state.value }
            suffix={ currencyUnit ? ` ${currencyUnit}` : undefined }
            displayType={ 'text' }
          />
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ !_.isEmpty(unitErrors) ? unitErrors : !_.isEmpty(valueErrors) ? valueErrors : [] }
        required={ field.config.required }
        border={ border }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <div className="w-100p">
          <div className="Currency-Field-Select">
            <Select
              showSearch
              className={classNames('Select-Field', {
                'Select-Field--has-warning border-warning': unitModified && _.isEmpty(unitErrors),
              })}
              onChange={ (code: string) => onChange(field, code, config, 'currency') }
              placeholder={ field.label }
              value={ currencyUnit || undefined }
              disabled={ true || isDisabled }
              filterOption={(input: string, option) => {
                const index: any = option?.value || 0;
                const currencyOption = currencies[index];

                if (currencyOption.title) {
                  const title = currencyOption.title.toLowerCase();
                  const code = currencyOption.code.toLowerCase();

                  if (title.includes(input) || code.includes(input)) {
                    return true;
                  }
                }

                return false;
              }}
            >
              { currencies.map((option: CurrencyProp) => {
                return (
                  <Option
                    key={`${field.id}-list-currency-option-${option.id}`}
                    value={option.code}
                  >
                    {option.code}
                  </Option>
                );
              }) }
            </Select>
          </div>
          <div className="Currency-Field-Value">
            <NumberFormat
              { ...numberFormat }
              customInput={ Input }
              autoComplete="newpassword" // hack
              className={classNames('Field Field-Number pR-20 ta-r', {
                'Field--has-warning border-warning': valueModified && _.isEmpty(valueErrors),
              })}
              fixedDecimalScale={ !!decimal }
              decimalScale={ decimal }
              required={ field.config.required }
              disabled={ isDisabled }
              value={ value }
              onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({
                  state: _.set(_.cloneDeep(state), 'value', event.target.value !== '' ? formatCostToNumber(event.target.value, numberFormat.thousandSeparator, numberFormat.decimalSeparator) : null)
                });
              } }
            />
          </div>
        </div>
      </FieldWrapper>
    );
  };
}

export default Currency;
