// Libs
import * as React from 'react';

// Styles
import './FieldLoadingSpinner.scss';

interface Props {
  isLoading: Boolean,
};

const FieldLoadingSpinner: React.FC<Props> = ({ isLoading }) => {
  return (
    <>
      { isLoading &&
        <div className='FieldLoadingSpinner'>
          <div className='FieldLoadingSpinner-spinner' />
        </div>
      }
    </>
  );
};

export default FieldLoadingSpinner;