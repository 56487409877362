// Libs
import React, { Component } from 'react';
import _ from 'lodash';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Components
import UploadDialog from 'components/upload-dialog/UploadDialog';

// Interfaces
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { Document, Folder } from 'components/upload-dialog/UploadDialog.interfaces';

// Styles
import './ActivityManagement.scss';

const API: Api = new Api();

interface Props {
  getDocumentsEndpoint: string;
  uploadDocumentsEndpoint: string;
  downloadDocumentsEndpoint: string;
  title: string;
  uploadDialogTitle: string;
  folderIds?: number[];
  onClose: () => void;
  onSave: (count: number) => void;
};

interface State {
  uploadFolder: Document[] | null;
  isFetching: boolean;
  isUploading: boolean;
  isDownloading: boolean;
};

const findDocument = (documents: Document[], document_key: string): Document | null => {
  let document = null;
  documents.some(function iter(_document: Document) {
    if (_document['key'] === document_key) {
      document = _document;
      return true;
    }
    return !!_document.children && _document.children.some(iter);
  });
  return document;
};

class ActivityUploadDialog extends Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    uploadFolder: null,
    isFetching: false,
    isUploading: false,
    isDownloading: false,
  };

  componentDidMount = async () => {
    this.mounted = true;

    const { getDocumentsEndpoint, folderIds } = this.props;

    try {

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));

      const documents = await API.get(getDocumentsEndpoint, {
        folders: folderIds
      });

      this.mounted && this.setState({
        uploadFolder: documents,
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({ isFetching: false });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  onUpload = async (fileList: UploadFile[], folder: Folder, cb: () => void): Promise<void> => {
    const { uploadDocumentsEndpoint, onSave, folderIds } = this.props;

    try {
      const form = new FormData();

      fileList.forEach((file) => {
        form.append('key', String(folder.key));
        form.append('folder_id', String(folder.id));
        form.append('context_id', folder.context.id);
        form.append('context_type', folder.context.type);
        form.append('context_bundle', folder.context.bundle);
        form.append('documents[]', file.originFileObj as RcFile);
      });

      if (folderIds) {
        folderIds.forEach((id) => {
          form.append('folders[]', id as any);
        });
      }

      await new Promise((resolve) => this.setState({ isUploading: true }, () => resolve(null) ));
      const uploadFolder: Document[] = await API.post(uploadDocumentsEndpoint, form);

      this.mounted && this.setState({
        uploadFolder: uploadFolder,
      }, () => {
        Notification('success', `Sucessfully uploaded documents`);
      });

      const documentCount: number = uploadFolder.reduce((acc, curr) => acc += curr?.children?.length, 0);
      onSave(documentCount || 0);
    } catch (error) {
      console.error(error);
      Notification('error', `Failed to upload`);
    } finally {
      this.mounted && this.setState({ isUploading: false }, cb);
    }
  };

  onDownload = async (keys: string[]): Promise<void> => {
    const { downloadDocumentsEndpoint } = this.props;

    try {

      let filename = 'download.zip';
      await new Promise((resolve) => this.setState({ isDownloading: true }, () => resolve(null) ));
      await API.downloadBundle(downloadDocumentsEndpoint, filename, { list: JSON.stringify(keys) });

    } catch (error) {
      console.error(error);
      Notification('error', `Failed to download`);
    } finally {
      this.mounted && this.setState({
        isDownloading: false
      });
    }
  };

  render = () => {
    const { onClose, title, uploadDialogTitle } = this.props;
    const { uploadFolder, isFetching, isUploading, isDownloading } = this.state;

    return (
      <UploadDialog
        onClose={ onClose }
        onUpload={ this.onUpload }
        onDownload={ this.onDownload }
        documents={ !!uploadFolder ? uploadFolder : [] }
        title={ title }
        uploadDialogTitle={ uploadDialogTitle }
        isFetching={ isFetching }
        isUploading={ isUploading }
        isDownloading={ isDownloading }
      />
    );
  };
};

export default ActivityUploadDialog;