// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';

// Services
import { Api } from 'services/api';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Views
import OwnedDisposalRecord from 'views/portfolio/transaction/owned-disposal/OwnedDisposalRecord';
import OwnedDisposalDocuments from 'views/portfolio/transaction/owned-disposal/OwnedDisposalDocuments';
import OwnedDisposalAudit from 'views/portfolio/transaction/owned-disposal/OwnedDisposalAudit';
import OwnedDisposalResources from 'views/portfolio/transaction/owned-disposal/OwnedDisposalResources';
import OwnedDisposalWorkflow from 'views/portfolio/transaction/owned-disposal/OwnedDisposalWorkflow';
import OwnedDisposalComments from 'views/portfolio/transaction/owned-disposal/OwnedDisposalComments';
import GenericRecordWrapper from 'views/common/GenericRecordWrapper';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { RecordFormEntity, StagedEntity } from 'types/entities';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbs,
  setBreadcrumbsLoading,
} from 'store/UI/ActionCreators';

// Utils
import Console from 'utils/console';

interface Props {
  client_id: number;
  permissions: any;
  match: { params: Record<string, any> };
  location: {
    pathname: string;
  };
  routes: any[];
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  record: RecordFormEntity | null;
  isFetching: boolean;
};

const API: Api = new Api();

class LeaseAquisitionContainer extends React.Component<Props> {

  mounted: boolean = false;
  state: State = {
    record: null,
    isFetching: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.getRecord();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.params.record_id !== this.props.match.params.record_id) {
      this.getRecord();
    }
  };

  componentWillUnmount = () => {
    this.props.setSecondarySidebarRoutes([]);
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getRecord = async (silent: boolean = false) => {
    try {

      if (!silent) {
        // Set default breadcrumbs
        this.props.setBreadcrumbs([
          { title: 'Home', path: '/' },
          { title: 'Portfolio', path: '/portfolio' },
          { title: 'Transactions', path: '/portfolio/transactions' },
        ], false);

        // Set default routes
        this.props.setSecondarySidebarRoutes([]);
      }

      if (this.props.match.params.record_id && this.props.client_id) {

        if (!silent) {
          await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));
          this.props.setBreadcrumbsLoading(true);
        }

        const record = await API.get(`client/${this.props.client_id}/transaction/owned-disposal/${this.props.match.params.record_id}`);

        this.setRecord(record, silent);
      }
    } catch (error) {
      Console.error(error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.setState({
        isFetching: false
      });
    }
  };

  setRecord = (record: RecordFormEntity, silent?: boolean) => {
    const showLoadingState = this.state.record?.title !== record.title;

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(true);
    }

    if (_.has(record, 'breadcrumbs')) {
      this.props.setBreadcrumbs(record.breadcrumbs, false);
    }

    if (_.has(record, 'permissions')) {
      const { permissions, new_content } = record;

      const routes: any = [
        { title: 'Owned Disposal', path: 'record', isDisabled: !hasPermission(permissions, 'transaction_owned_disposal_view') },
        { title: 'Documents', path: 'documents', isIndented: true, isDisabled: !hasPermission(permissions, 'transaction_owned_disposal_document_view'), hasUpdates: !!new_content.documents },
        { title: 'Comments', path: 'comments', isIndented: true, isDisabled: !hasPermission(permissions, 'transaction_owned_disposal_comment_view'), hasUpdates: !!new_content.comments },
        { title: 'Resources', path: 'resources', isIndented: true, isDisabled: !hasPermission(permissions, 'transaction_owned_disposal_resource_view'), hasUpdates: !!new_content.resources },
        { title: 'Workflow', path: 'workflow', isIndented: true, isDisabled: !hasPermission(permissions, 'transaction_owned_disposal_workflow_view') },
        { title: 'Audit', path: 'audit', isIndented: true, isDisabled: !hasPermission(permissions, 'transaction_owned_disposal_audit_view') },
      ];

      if (_.has(record, 'staged_entities') && !_.isEmpty(record.staged_entities)) {
        record.staged_entities
          .sort((a, b) => a.title.localeCompare(b.title))
          .forEach((stagedEntity: StagedEntity) => {
            routes.splice(routes.findIndex((column: any) => column.path === 'record') + 1, 0, {
              title: stagedEntity.label,
              path: stagedEntity.type,
              isIndented: true,
              isDisabled: false,
            });
          });
      }

      this.props.setSecondarySidebarRoutes(routes);
    }

    this.setState({
      record: record,
      isFetching: false
    });

    if (showLoadingState && !silent) {
      this.props.setBreadcrumbsLoading(false);
    }
  };

  render = () => {
    const { isFetching, record } = this.state;
    const permissions = record && _.has(record, 'permissions') ? record.permissions : this.props.permissions;
    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Switch>

          <Redirect exact from="/portfolio/transactions/owned-disposal" to="/portfolio/transactions" />
          <Redirect exact from="/portfolio/transactions/owned-disposal/:record_id" to="/portfolio/transactions/owned-disposal/:record_id/record" />

          { record &&
            <Route exact path="/portfolio/transactions/owned-disposal/:record_id/record" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'transaction_owned_disposal_view') }>
                  <OwnedDisposalRecord record={ record } setRecord={ this.setRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/portfolio/transactions/owned-disposal/:record_id/documents" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'transaction_owned_disposal_document_view') }>
                  <OwnedDisposalDocuments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/portfolio/transactions/owned-disposal/:record_id/audit" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'transaction_owned_disposal_audit_view') }>
                  <OwnedDisposalAudit record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/portfolio/transactions/owned-disposal/:record_id/resources" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'transaction_owned_disposal_resource_view') }>
                  <OwnedDisposalResources record={ record } getRecord={ this.getRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/portfolio/transactions/owned-disposal/:record_id/workflow" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'transaction_owned_disposal_workflow_view') }>
                  <OwnedDisposalWorkflow record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/portfolio/transactions/owned-disposal/:record_id/comments" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'transaction_owned_disposal_comment_view') }>
                  <OwnedDisposalComments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record && _.has(record, 'staged_entities') && !_.isEmpty(record.staged_entities) && record.staged_entities.map(entity => (
            <Route
              exact
              key={ entity.id }
              path={ `/portfolio/transactions/owned-disposal/:record_id/${entity.type}` }
              render={ () =>
                <GenericRecordWrapper id={ entity.id } type={ entity.type } bundle={ entity.bundle } />
              }
            />
          ) ) }

          <Route render={ () => <NotFound /> } />

        </Switch>
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    routes: store.UIState.secondarySidebarRoutes,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaseAquisitionContainer);
