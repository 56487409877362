// Libs
import _ from 'lodash';

// Interfaces
import { FormTab } from 'views/admin/entity/Entity.interfaces';
import { Config, TableType } from 'components/drag-sorting-list/DragSortingList.interfaces';

export function arrayMoveMutable<T>(array: Array<T>, fromIndex: number, toIndex: number) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
};
export function arrayMoveImmutable<T>(array: Array<T>, fromIndex: number, toIndex: number) {
  array = [...array];
  arrayMoveMutable(array, fromIndex, toIndex);
  return array;
};

/**
 * Order function by key.
 * @default "asc"
 * data => field to order (Object, List, Array).
 * key => the data to be order by this key.
 */
export function orderListByKey<T extends { [key: string]: any }>(
  data: Array<T>,
  key: keyof T,
  order: 'asc' | 'desc' = 'asc',
) {
  const compareValues = (key: keyof T, order: 'asc' | 'desc') => (elemA: T, elemB: T) => {
    if (!elemA.hasOwnProperty(key) || !elemB.hasOwnProperty(key) || typeof elemA[key] !== 'string' || typeof elemB[key] !== 'string') return 0;
    const comparison = elemA[key].replaceAll(' ', '').localeCompare(elemB[key].replaceAll(' ', ''));
    return order === 'desc' ? comparison * -1 : comparison;
  };
  return data.sort(compareValues(key, order));
};

export const changeOrder = (formTabs: FormTab[]) => {
  return formTabs.map((tab: FormTab, tabIdx) => {
    const groups = tab.groups.map((group, groupIdx) => {
      const elements = group.elements.map((element, elementIdx) => ({ ...element, order: elementIdx }));
      return { ...group, order: groupIdx, elements: elements };
    });
    return { ...tab, order: tabIdx, groups: groups };
  });
};
export const getOrder = (formTabs: FormTab[]) => {
  return formTabs.map((tab: FormTab, tabIdx) => {
    const groups = tab.groups.map((group, groupIdx) => {
      const elements = group.elements.map((element, elementIdx) => ({ id: element.id, order: elementIdx }));
      return { id: group.id, order: groupIdx, elements: elements };
    });
    return { id: tab.id, order: tabIdx, groups: groups };
  });
};

function reorderRows(
  data: Array<FormTab>,
  dragIndex: number,
  hoverIndex: number,
  type: TableType,
  references: Array<{ type: TableType; id: number }>,
): Array<FormTab> {
  // Drag Sorting Tabs
  if (_.isEmpty(references)) {
    return arrayMoveImmutable(data, dragIndex, hoverIndex).filter((el: any) => !!el);
  }

  const cloneFormTabs = _.cloneDeep(data);
  const tabId = _.first(references)?.id;
  const tabIdx = cloneFormTabs.findIndex((tab) => tab.id === tabId);
  const groups = cloneFormTabs[tabIdx].groups;

  // Drag Sorting Groups
  if (type === TableType.Group) {
    const updatedGroups = arrayMoveImmutable(groups, dragIndex, hoverIndex).filter((el: any) => !!el);
    cloneFormTabs[tabIdx].groups = updatedGroups;
  }

  // Drag Sorting Elements
  if (type === TableType.Element) {
    const groupId = _.last(references)?.id;
    const groupIdx = groups.findIndex((group) => group.id === groupId);
    const updatedElements = arrayMoveImmutable(groups[groupIdx].elements, dragIndex, hoverIndex).filter(
      (el: any) => !!el,
    );
    groups[groupIdx].elements = updatedElements;
  }

  return cloneFormTabs;
};

function moveRowToTable(
  data: Array<FormTab>,
  dragIndex: number,
  hoverIndex: number,
  type: TableType,
  sourceReferences: Array<{ type: TableType; id: number }>,
  targetReferences: Array<{ type: TableType; id: number }>,
): Array<FormTab> {
  const cloneFormTabs = _.cloneDeep(data);
  const sourceTabId = _.first(sourceReferences)?.id;
  const targetTabId = _.first(targetReferences)?.id;
  const sourceTabIdx = cloneFormTabs.findIndex((tab) => tab.id === sourceTabId);
  const targetTabIdx = cloneFormTabs.findIndex((tab) => tab.id === targetTabId);
  const sourceGroups = cloneFormTabs[sourceTabIdx].groups;
  const targetGroups = cloneFormTabs[targetTabIdx].groups;

  // move Group
  if (type === TableType.Group) {
    cloneFormTabs[sourceTabIdx].groups = sourceGroups.filter((group) => group.id !== sourceGroups[dragIndex].id);
    targetGroups.splice(hoverIndex, 0, sourceGroups[dragIndex]);
  }

  // move Element
  if (type === TableType.Element) {
    const sourceGroupId = _.last(sourceReferences)?.id;
    const targetGroupId = _.last(targetReferences)?.id;
    const sourceGroupIdx = sourceGroups.findIndex((group) => group.id === sourceGroupId);
    const targetGroupIdx = targetGroups.findIndex((group) => group.id === targetGroupId);
    const sourceElements = sourceGroups[sourceGroupIdx].elements;
    const targetElements = targetGroups[targetGroupIdx].elements;
    sourceGroups[sourceGroupIdx].elements = sourceElements.filter((el) => el.id !== sourceElements[dragIndex].id);
    targetElements.splice(hoverIndex, 0, sourceElements[dragIndex]);
  }

  return cloneFormTabs;
};

export function moveRow(
  data: FormTab[],
  dragIndex: number,
  hoverIndex: number,
  sourceTableConfig: Config,
  targetTableConfig: Config,
): FormTab[] {
  // Reorder elements in one table
  if (_.isEqual(sourceTableConfig.references, targetTableConfig.references) && dragIndex !== hoverIndex) {
    return changeOrder(reorderRows(data, dragIndex, hoverIndex, sourceTableConfig.type, sourceTableConfig.references));
  }
  // Move element to another table
  if (!_.isEqual(sourceTableConfig.references, targetTableConfig.references)) {
    return changeOrder(
      moveRowToTable(
        data,
        dragIndex,
        hoverIndex,
        targetTableConfig.type,
        sourceTableConfig.references,
        targetTableConfig.references,
      ),
    );
  }
  return data;
};

export function moveRowInEmptyTable(
  data: FormTab[],
  dragIndex: number,
  sourceTableConfig: Config,
  targetTableConfig: Config,
) {
  const cloneFormTabs = _.cloneDeep(data);
  const sourceTabId = _.first(sourceTableConfig.references)?.id;
  const targetTabId = _.first(targetTableConfig.references)?.id;
  const sourceTabIdx = cloneFormTabs.findIndex((tab) => tab.id === sourceTabId);
  const targetTabIdx = cloneFormTabs.findIndex((tab) => tab.id === targetTabId);
  const sourceGroups = cloneFormTabs[sourceTabIdx].groups;
  const targetGroups = cloneFormTabs[targetTabIdx].groups;

  // move Group
  if (sourceTableConfig.type === TableType.Group && _.isEmpty(targetGroups)) {
    targetGroups.push(sourceGroups[dragIndex]);
    sourceGroups.splice(dragIndex, 1);
  }
  // move Element
  if (sourceTableConfig.type === TableType.Element) {
    const sourceGroupId = _.last(sourceTableConfig.references)?.id;
    const targetGroupId = _.last(targetTableConfig.references)?.id;
    const sourceGroupIdx = sourceGroups.findIndex((group) => group.id === sourceGroupId);
    const targetGroupIdx = targetGroups.findIndex((group) => group.id === targetGroupId);
    const sourceElements = sourceGroups[sourceGroupIdx].elements;
    const targetElements = targetGroups[targetGroupIdx].elements;

    if (_.isEmpty(targetElements)) {
      targetElements.push(sourceElements[dragIndex]);
      sourceElements.splice(dragIndex, 1);
    }
  }

  return changeOrder(cloneFormTabs);
};