// Libs
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InfoWindow, AdvancedMarker, useMap, Pin } from '@vis.gl/react-google-maps';
import { Marker, MarkerClusterer } from '@googlemaps/markerclusterer';

export type ILocation = {
  key: string;
  tooltip: {
    header: React.ReactNode;
    content: React.ReactNode;
  } | undefined;
  position: google.maps.LatLngLiteral;
};

export type ClusterMarkerProps = {
  locations: ILocation[];
};

export type IMarkerProps = {
  location: ILocation;
  onClick: (location: ILocation) => void;
  setMarkerRef: (marker: Marker | null, key: string) => void;
};

export const MarkerComponent = (props: IMarkerProps) => {
  const {location, onClick, setMarkerRef} = props;
  const handleClick = useCallback(() => onClick(location), [onClick, location]);
  const ref = useCallback((marker: google.maps.marker.AdvancedMarkerElement) => setMarkerRef(marker, location.key), [setMarkerRef, location.key]);
  const pin = false ? (<Pin background={ '#0f9aee' } glyphColor={ '#084d77' } borderColor={'#0c7bbe'} />) : undefined;

  return (
    <AdvancedMarker
      ref={ ref }
      style={{ cursor: !!location?.tooltip ? 'pointer' : 'default' }}
      position={ location.position }
      onClick={ handleClick }
      children={ pin }
    />
  );
};

export const ClusterMarker = ({ locations }: ClusterMarkerProps) => {
  const [markers, setMarkers] = useState<{[key: string]: Marker}>({});
  const [selectedLocationKey, setSelectedLocationKey] = useState<string | null>(null);
  const map = useMap();

  const selectedLocation = useMemo(() => {
    if (locations && selectedLocationKey) {
      return locations.find(t => t.key === selectedLocationKey)!;
    }
    return null;
    }, [locations, selectedLocationKey]
  );

  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({map});
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    // reposition markers
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));

  }, [clusterer, markers]);

  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers(markers => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return {...markers, [key]: marker};
      } else {
        const {[key]: _, ...newMarkers} = markers;

        return newMarkers;
      }
    });
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedLocationKey(null);
  }, []);

  const handleMarkerClick = useCallback((location: ILocation) => {
    !!location?.tooltip && setSelectedLocationKey(location.key);
  }, []);

  return (
    <>
      { locations.map(location => (
        <MarkerComponent
          key={ location.key }
          location={ location }
          onClick={ handleMarkerClick }
          setMarkerRef={ setMarkerRef }
        />
      )) }
      { selectedLocationKey && selectedLocation?.tooltip && (
        <InfoWindow
          style={{ minWidth: 200 }}
          headerContent={ selectedLocation?.tooltip.header }
          anchor={ markers[selectedLocationKey] }
          onCloseClick={ handleInfoWindowClose }
        >
          { selectedLocation?.tooltip.content }
        </InfoWindow>
      ) }
    </>
  );
};
