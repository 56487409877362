// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Popconfirm } from 'antd';
import BasicList from "components/basic-list";
import Jumbotron from 'components/jumbotron';
import Dropdown from 'components/dropdown';
import { RestrictionHoC, hasPermission } from 'components/restriction';
import Badge, { BadgeType } from 'components/badge';

// Icons
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Views
import InsightModal from 'views/insight/InsightModal';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';
import { IInsight, OnSaveCallback } from 'components/insight/Insight.interfaces';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

interface Props {
  client_id: number;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
}

interface State {
  reports: any[];
  isFetching: boolean;
  isDeleting: boolean;
  editInsightId: number | null;
  deleteId: string | null;
  showInsightModal: boolean;
};

class Insights extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    reports: [],
    editInsightId: null,
    deleteId: null,
    isFetching: false,
    isDeleting: false,
    showInsightModal: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Insights', path: null },
    ], false);

    this.fetchReports(client_id);
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  fetchReports = async (clientId: number) => {
    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const reports = await API.get(`client/${clientId}/report`);

      this.mounted && this.setState({
        reports: reports
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  handleSave = async (clientId: number, editInsightId: number | null, insight: IInsight, callback: OnSaveCallback) => {
    let newInsightId = undefined;

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      if (!editInsightId) {
        // Create
        const newInsight: any = await API.post(`client/${clientId}/insight/builder/report`, {
          data: insight
        });

        newInsightId = !!newInsight ? newInsight?.id : undefined;
      } else {
        // Update
        await API.put(`client/${clientId}/insight/builder/report/${editInsightId}`, {
          data: insight
        });
      }

      this.fetchReports(clientId);

    } catch (error) {
      console.error(error);
    } finally {
      callback(newInsightId);
    }
  };

  renderListView = (reports: any[]) => {
    const { client_id, permissions } = this.props;
    const { isFetching } = this.state;
    return (
      <BasicList
        rawData
        isLoading={ isFetching }
        columns={ [
          {
            key: 'title',
            dataIndex: 'title',
            title: 'Title',
            filterable: true,
            width: '30%',
            render: (__: any, record: any) => {
              return <Link  className='primaryColor' to={ record.path }>{ record.title }</Link>;
            },
          },
          {
            key: 'type',
            dataIndex: 'type',
            title: 'Type',
            filterable: true,
            ellipsis: true,
            render: (__: any, record: any) => {
              switch (_.toLower(record?.type)) {
                case 'insight':
                  return <Badge type={ BadgeType.Default } text={ 'Insight' } />;
                case 'report':
                  return <Badge type={ BadgeType.Disabled } text={ 'Report' } />;
              }
              return '-';
            },
          },
          {
            key: 'tool',
            dataIndex: 'tool',
            title: 'Tool',
            filterable: true,
            ellipsis: true,
            render: (__: any, record: any) => {
              return !!record?.tool?.label ? record.tool.label : '-';
            },
          },
          {
            key: 'description',
            dataIndex: 'description',
            title: 'Description',
            filterable: false,
            ellipsis: true,
            width: '30%',
            render: (__: any, record: any) => {
              return !!record.description ? record.description : '-';
            },
          },
          {
            key: 'actions',
            dataIndex: 'actions',
            title: '',
            render: (__: any, report: any) => {
              const canManage = report?.type !== 'report' && (hasPermission(permissions, 'insight_manage_all_reports') || hasPermission(permissions, 'insight_manage_own_reports'));
              return (
                <>
                  <EditOutlined
                    className={ classNames('fsz-def link', {
                      'text-ant-disabled': !canManage,
                      'cur-na': !canManage,
                    }) }
                    onClick={ () => {
                      canManage && this.setState({
                        showInsightModal: true,
                        editInsightId: report.id,
                      });
                    } }
                  />
                  <Popconfirm
                    title={ 'Are you sure?' }
                    icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                    visible={ this.state.deleteId === `${report.type}-${report.id}` }
                    okButtonProps={{
                      danger: true
                    }}
                    onConfirm={ async () => {
                      try {
                        await API.delete(`client/${client_id}/insight/builder/report/${report.id}`);
                        this.fetchReports(client_id);

                        Notification('success', 'The report has been deleted.', 'Deleted');
                      } catch (error) {
                        console.error('Error: ', error);
                      } finally {
                        this.mounted && this.setState({
                          deleteId: null
                        });
                      }
                    }}
                    onCancel={ () => this.setState({ deleteId: null }) }
                  >
                    <DeleteOutlined
                      className={ classNames('fsz-def link mL-20', {
                        'text-ant-disabled': !canManage,
                        'cur-na': !canManage,
                      }) }
                      onClick={ () => {
                        canManage && this.setState({
                          deleteId: `${report.type}-${report.id}`
                        });
                      } }
                    />
                  </Popconfirm>
                </>
              );
            },
            width: 100,
            sorter: false,
            ellipsis: true,
            filterable: false,
            align: 'center'
          },
        ] }
        items={ reports.map((report: any, index: number) => {
          return {
            'key': index,
            ...report,
          };
        } ) }
      />
    );
  };

  render = () => {
    const { client_id } = this.props;
    const { reports, editInsightId, showInsightModal } = this.state;
    return (
      <>
        <Jumbotron
          title={ 'Insights' }
          tabs={ [
            {
              label: 'List View',
              node: this.renderListView(reports),
            }
          ] }
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Create Insight',
                      onClick: () => this.setState({ showInsightModal: true })
                    }
                  ] }
                />
              )
            }
          ] }
        />
        { showInsightModal &&
          <InsightModal
            clientId={ client_id }
            insightId={ editInsightId }
            onSave={ (insight: IInsight, callback: OnSaveCallback) => this.handleSave(client_id, editInsightId, insight, callback) }
            onClose={ () => this.setState({
              editInsightId: null,
              showInsightModal: false,
            }) }
          />
        }
      </>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(Insights, 'record_invoice_view_list'));
