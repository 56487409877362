// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import BasicList from 'components/basic-list';
import Jumbotron from 'components/jumbotron';
import { RestrictionHoC } from 'components/restriction';
import { Checkbox, Form, Input, Modal, Popconfirm, Tooltip } from 'antd';
import Dropdown from 'components/dropdown';

// Services
import { Api } from 'services/api';
import { getFormatedDate } from 'services/settings';
import Notification from 'services/notification';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Icons
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined, EyeOutlined } from '@ant-design/icons';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { ActivityTemplate as IActivityTemplate, Activity, Feature, Role, Competence } from 'views/admin/templates/Templates.interfaces';

const API: Api = new Api();

interface Props {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  recordTitle: string;
  templates: IActivityTemplate[],
  roles: Role[];
  competencies: Competence[];
  newTemplate: Partial<IActivityTemplate> | null;
  isLoading: boolean;
  isAddingTemplate: boolean;
  isDeletingTemplate: boolean;
  isEditMode: boolean;
  showAddDialog: boolean;
};

class ActivityTemplate extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    recordTitle: '',
    templates: [],
    roles: [],
    competencies: [],
    newTemplate: null,
    isLoading: false,
    isAddingTemplate: false,
    isDeletingTemplate: false,
    isEditMode: false,
    showAddDialog: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const type_id = this.props.match.params.type_id;

    this.mounted = true;

    try {

      this.props.setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));
      const templateType = await API.get(`client/${client_id}/admin/activities/types/${type_id}`);

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Template Types', path: '/admin/templates' },
        { title: `${templateType.title} Templates`, path: null },
      ], false);

      this.mounted && this.setState({
        recordTitle: templateType.title,
        templates: templateType.templates,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderAddDialog = () => {
    const { client_id } = this.props;
    const type_id = this.props.match.params.type_id;
    const { isAddingTemplate, isEditMode, newTemplate } = this.state;

    const submitHandler = async () => {
      if (!newTemplate?.title?.trim()) return;
      this.mounted && this.setState({ isAddingTemplate: true }, async () => {
        try {
          const method = !!isEditMode ? 'put' : 'post';
          const endpoints = {
            'post': `client/${client_id}/admin/activities/templates`,
            'put': `client/${client_id}/admin/activities/templates/${newTemplate.id}`,
          };
          const response = await API[method](endpoints[method], {
            data: { title: newTemplate.title, fixed: !!newTemplate.fixed, type_id: type_id }
          });
          this.setState({
            templates: response.templates,
          });
          Notification('success', '', `Template ${isEditMode ? 'updated' : 'created'}`);
        } catch (error) {
          console.error('Error: ', error);
          Notification('error', '', `Failed to ${isEditMode ? 'update' : 'create'} template`);
        } finally {
          this.setState({
            newTemplate: null,
            isAddingTemplate: false,
            isEditMode: false,
            showAddDialog: false,
          });
        }
      });
    };

    return (
      <Modal
        visible
        centered
        title={ `${isEditMode ? 'Update' : 'Create'} Template` }
        maskClosable={ !isAddingTemplate }
        okText={ isEditMode ? 'Save' : 'Create' }
        onOk={ submitHandler }
        onCancel={ () => this.setState({
          showAddDialog: false,
          isEditMode: false,
          newTemplate: null,
        }) }
        cancelButtonProps={{
          disabled: isAddingTemplate
        }}
        okButtonProps={{
          disabled: !newTemplate?.title,
          loading: isAddingTemplate,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Title" required>
            <Input
              value={ newTemplate?.title }
              onChange={ (event) => {
                this.setState({ newTemplate: Object.assign({}, newTemplate, { title: event.target.value }) });
              } }
            />
          </Form.Item>
          <Form.Item>
            <Tooltip placement="top" title="If ticked, users will be unable to adapt or modify this template in the front end">
              <Checkbox
                onChange={ (event) => {
                  this.setState({ newTemplate: Object.assign({}, newTemplate, { fixed: event.target.checked }) });
                } }
                checked={ !!newTemplate?.fixed }
              >
                Fixed
              </Checkbox>
            </Tooltip>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderTemplates = () => {
    const type_id = this.props.match.params.type_id;
    const { client_id } = this.props;
    const { isLoading, isDeletingTemplate } = this.state;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Title',
          render: (title: string, record: IActivityTemplate) => {
            return (
              <Link className='primaryColor' to={ `/admin/templates/activity/${type_id}/activities/${record.id}` }>
                { title }
              </Link>
            );
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'fixed',
          dataIndex: 'fixed',
          title: 'Fixed',
          render: (fixed: boolean) => <div>{ fixed ? 'YES' : 'NO' }</div>,
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: string) => getFormatedDate(created_at, undefined, true),
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
        {
          key: 'actions',
          dataIndex: 'actions',
          title: '',
          render: (__: any, record: IActivityTemplate) => {
            return (
              <>
                <EditOutlined
                  className="link mL-15 mR-15"
                  style={{ fontSize: 18 }}
                  onClick={ () => {
                    this.setState({ showAddDialog: true, isEditMode: true, newTemplate: _.cloneDeep(record) });
                  } }
                />
                <Popconfirm
                  title={ 'Are you sure?' }
                  icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                  okButtonProps={{
                    danger: true
                  }}
                  placement="topRight"
                  onConfirm={ async () => {
                    this.mounted && this.setState({ isDeletingTemplate: true }, async () => {
                      try {
                        const response = await API.delete(`client/${client_id}/admin/activities/templates/${record.id}`, {
                          data: { type_id: type_id }
                        });
                        this.setState({
                          templates: response.templates,
                        });
                        Notification('success', '', `Template deleted`);
                      } catch (error) {
                        console.error('Error: ', error);
                        Notification('error', '', `Failed to delete template`);
                      } finally {
                        this.setState({
                          isDeletingTemplate: false,
                        });
                      }
                    });
                  } }
                >
                  <DeleteOutlined
                    className="link"
                    style={{ fontSize: 18 }}
                  />
                </Popconfirm>
              </>
            );
          },
          width: 120,
          ellipsis: true,
          align: 'center' as 'center',
        },
      ];
    };

    const mapData = (templates: IActivityTemplate[]) => {
      return templates.map((template: IActivityTemplate, index: number) => {
        return {
          'key': index,
          'id': template.id,
          'title': template.title,
          'fixed': template.fixed,
          'created_at': template.created_at,
        };
      });
    };

    return (
      <BasicList
        rawData
        isLoading={ isDeletingTemplate }
        columns={ mapColumns() }
        items={ mapData(this.state.templates) }
      />
    );
  };

  render = () => {
    const { isLoading, showAddDialog, recordTitle, roles, competencies } = this.state;

    return (
      <BlockingSpinner isLoading={ isLoading }>
        <Jumbotron
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Create',
                      onClick: () => this.setState({ showAddDialog: true })
                    },
                  ] }
                />
              )
            }
          ] }
          content={ `${recordTitle} Templates` }
          tabs={[
            {
              label: 'Overview',
              node: this.renderTemplates(),
            }
          ]}
        />
        { showAddDialog && this.renderAddDialog() }
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(ActivityTemplate), 'access_admin_templates'));
