// Libs
import * as React from 'react';

// Components
import RecordView from 'views/common/RecordView';

interface Props {
  id: number;
  type: string,
  bundle: string
};

class GenericRecordWrapper extends React.Component<Props> {
  render = () => {
    return (
      <RecordView
        id={ this.props.id }
        type={ this.props.type }
        entity={ this.props.bundle }
      />
    );
  };
};

export default GenericRecordWrapper;
