// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import { Table, Form, TreeSelect, Dropdown as AntDropdown, Button, Menu, Typography, Upload, List, Modal, Input, Tooltip } from 'antd';
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import ProfileCard from 'components/profile-card';
import CoverModal from 'components/cover-modal';
import { hasPermission } from 'components/restriction';
import Dropdown, { Action } from 'components/dropdown';

// Icons
import {
  FilePdfOutlined,
  FolderOutlined,
  FolderViewOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
  DownloadOutlined,
  InboxOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

// Services
import { getFormatedDate } from 'services/settings';
import { Api } from 'services/api';
import Notification from 'services/notification';
import { filterFilenameExtention, extractFilenameExtention } from 'utils/utils';
import { findFirst } from 'utils/utils';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';
import { UserPermissions } from 'types/permissions';

const { Link } = Typography;
const API: Api = new Api();

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
};

interface Props {
  client_id?: number;
  permissions?: UserPermissions;
  record: RecordFormEntity;
  record_id?: number,
  type: string;
  entity: string;
  pure?: boolean;
};

interface State {
  documents: any;
  selectedRowKeys: string[];
  fileList: any[];
  profile_id: number | null;
  folder_id: number | null;
  selectedDocument: any;
  showUploadDialog: boolean;
  showInfoDialog: boolean;
  showDeleteDialog: boolean;
  showRenameDialog: boolean;
  isDeleting: boolean;
  isFetching: boolean;
  isUploading: boolean;
  isDownloading: boolean;
  isRenaming: boolean;
};

class DocumentView extends React.Component<Props, State> {

  mounted: boolean = false;
  renameFormRef: any = React.createRef();

  state: State = {
    documents: null,
    selectedRowKeys: [],
    fileList: [],
    folder_id: null,
    profile_id: null,
    selectedDocument: null,
    showUploadDialog: false,
    showInfoDialog: false,
    showDeleteDialog: false,
    showRenameDialog: false,
    isDeleting: false,
    isFetching: false,
    isUploading: false,
    isDownloading: false,
    isRenaming: false,
  };

  componentDidMount = async () => {
    this.mounted = true;

    const { client_id, type, entity, record } = this.props;

    if (!client_id) return;

    try {

      if (!record) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));

      const documents = await API.get(`client/${client_id}/${entity}/${_.kebabCase(type)}/${record.id}/document`);

      this.mounted && this.setState({
        documents: documents
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleUpload = async (folder: any) => {
    const { client_id, type, entity, record } = this.props;
    const { fileList } = this.state;

    try {

      if (!record) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isUploading: true }, () => resolve(null) ));

      const form = new FormData();

      fileList.forEach((file: any) => {
        form.append('folder_id', folder.id);
        form.append('context_id', folder.context.id);
        form.append('context_type', folder.context.type);
        form.append('context_bundle', folder.context.bundle);
        form.append('documents[]', file.originFileObj);
      });

      const documents = await API.post(`client/${client_id}/${entity}/${type}/${record.id}/document`, form);

      this.mounted && this.setState({
        documents: documents
      }, () => {
        Notification('success', `Sucessfully uploaded documents`);
      });

    } catch (error) {
      console.error(error);
      Notification('error', `Failed to upload`);
    } finally {
      this.mounted && this.setState({
        isUploading: false,
        showUploadDialog: false,
      });
    }
  };

  handleDownloadBundle = async (list: any[]) => {
    const { client_id, type, entity, record } = this.props;

    try {

      if (!record) throw new Error('Failed');

      let filename = 'download.zip';

      await new Promise((resolve) => this.setState({ isDownloading: true }, () => resolve(null) ));
      await API.downloadBundle(`client/${client_id}/${entity}/${type}/${record.id}/document/download`, filename, { list: JSON.stringify(list) });

    } catch (error) {
      console.error(error);
      Notification('error', `Failed to download`);
    } finally {
      this.mounted && this.setState({
        isDownloading: false,
      });
    }
  };

  handleDownloadSingle = async (documentKey: string) => {
    const { client_id, type, entity, record } = this.props;
    const { documents } = this.state;

    try {

      if (!record) throw new Error('Failed');

      const flattenDocuments = this.getFlatten(documents);
      const document = flattenDocuments.find((document: any) => document.key === documentKey);

      if (!document) throw new Error('Failed to find document');

      await new Promise((resolve) => this.setState({ isDownloading: true }, () => resolve(null) ));
      await API.downloadSingle(`client/${client_id}/${entity}/${type}/${record.id}/document/download`, document.title, { key: document.key });

    } catch (error) {
      console.error(error);
      Notification('error', `Failed to download document`);
    } finally {
      this.mounted && this.setState({
        isDownloading: false,
      });
    }
  };

  getFileCount = (folders: any = []) => {
    let count = 0;
    folders && folders.forEach((folder: any) => {
      if (folder.type === 'folder') {
        count += this.getFileCount(folder.children);
      } else {
        count++;
      }
    });
    return count;
  };

  getFlatten = (data: any) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({ ..._value });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  getFilteredNestedSet = (nestedSet: any = [], selectedRowKeys: any) => {
    return !_.isEmpty(nestedSet) ? nestedSet
      .filter((entity: any) => {
        return selectedRowKeys.some((rowKey: string) => {
          return findFirst(entity, 'children', { key: rowKey });
        });
      })
      .map((entity: any) => {
        return {
          ...entity,
          'children': this.getFilteredNestedSet(entity.children, selectedRowKeys),
        };
      }) : [];
  };

  isReadOnlyList = (list: any) => {
    return this.getFlatten(list)
      .filter((listItem: any) => listItem.type === 'folder')
      .every((listItem: any) => listItem?.config?.view_only);
  };

  renderUploadDialog = () => {
    const { documents, fileList, isUploading, folder_id } = this.state;

    const mapFolders = (data: any = []) => {
      return data
        .filter((document: any) => (document.type === 'folder' && document.id !== null))
        .map((entity: any) => {

          const appendChildrenKeys = (children: any) => {
            // Prevent nesting
            if (_.isEmpty(children)) return [];
            return children
              .filter((document: any) => (document.type === 'folder' && document.id !== null))
              .map((childEntity: any) => {
                return {
                  'id': childEntity.id,
                  'key': childEntity.key || childEntity.id,
                  'value': childEntity.key || childEntity.id,
                  'title': childEntity.title,
                  'context': childEntity.context,
                  'selectable': !childEntity.config.view_only,
                  'disabled': !!childEntity.config.view_only,
                  'children': appendChildrenKeys(childEntity.children),
                };
              });
          };

        return {
          'id': entity.id,
          'key': entity.key || entity.id,
          'value': entity.key || entity.id,
          'title': entity.title,
          'context': entity.context,
          'selectable': !entity.config.view_only,
          'disabled': !!entity.config.view_only,
          'children': appendChildrenKeys(entity.children),
        };
      });
    };

    const folders = !_.isEmpty(documents) ? mapFolders(documents) : [];

    return (
      <CoverModal
        style={{ minWidth: '800px', minHeight: '500px', maxHeight: '90vh' }}
        middleContent={ 'Upload' }
        onClose={ () => !isUploading && this.setState({ showUploadDialog: false }) }
      >
        <Form
          className="pL-100 pR-100"
          name="upload"
          layout="vertical"
          autoComplete="off"
          onFinish={ (fields: any) => {
            const folder = findFirst({ children: folders }, 'children', { key: fields.folder });
            if (folder) {
              this.handleUpload(folder);
            }
          } }
        >
          <Form.Item
            name="folder"
            label="Folder"
          >
            <TreeSelect
              style={{ width: '100%' }}
              treeDefaultExpandAll
              treeData={ folders }
              onChange={ (folder_id: any) => this.setState({ folder_id: folder_id }) }
              placeholder="Please select"
            />
          </Form.Item>
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={ (e: any) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            } }
            rules={[
              {
                required: true,
                message: 'Required'
              },
            ]}
          >
            <Upload.Dragger
              multiple
              fileList={ fileList }
              beforeUpload={ () => false }
              onChange={ ({ fileList }) => this.setState({ fileList }) }
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Support for a single or bulk upload.</p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item className="ta-r">
            { _.isEmpty(fileList) || !folder_id ? (
              <Tooltip
                placement="left"
                title={ 'Please select Folder and File(s) to upload.' }
              >
                <Button type="primary" loading={ isUploading } disabled>
                  Upload
                </Button>
              </Tooltip>
            ) : (
              <Button type="primary" loading={ isUploading } disabled={ isUploading } htmlType="submit">
                Upload
              </Button>
            ) }
          </Form.Item>
        </Form>
      </CoverModal>
    );
  };

  renderDeleteDialog = (selectedDocument: any) => {
    const { client_id, type, entity, record } = this.props;
    const { isDeleting } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Remove' }
        maskClosable={ !isDeleting }
        closable={ !isDeleting }
        okButtonProps={{
          danger: true,
          disabled: isDeleting,
          loading: isDeleting,
        }}
        cancelButtonProps={{
          disabled: isDeleting,
        }}
        onOk={() => this.mounted && this.setState({
          isDeleting: true,
        }, async () => {
          try {

            if (!record) throw new Error('Failed');

            await API.delete(`client/${client_id}/${entity}/${type}/${record.id}/document/${selectedDocument.id}`);
            const documents = await API.get(`client/${client_id}/${entity}/${type}/${record.id}/document`);

            this.mounted && this.setState({
              documents: documents
            }, () => {
              Notification('success', `Sucessfully removed document`);
            });

          } catch (error) {
            Notification('error', `Failed to remove document`);
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              selectedDocument: null,
              isDeleting: false,
              showDeleteDialog: false,
            });
          }
        } )}
        onCancel={() => this.setState({ showDeleteDialog: false, selectedDocument: null })}
      >
        <p>Are you sure you want to remove this document?</p>
      </Modal>
    );
  };

  renderRenameDialog = (selectedDocument: any) => {
    const { client_id, type, entity, record } = this.props;
    const { isRenaming } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Rename' }
        maskClosable={ !isRenaming }
        closable={ !isRenaming }
        okButtonProps={{
          disabled: isRenaming,
          loading: isRenaming,
        }}
        cancelButtonProps={{
          disabled: isRenaming,
        }}
        onOk={() => this.mounted && this.setState({
          isRenaming: true,
        }, async () => {

          const title = this.renameFormRef.current.getFieldValue("title");
          const newTitle = title + extractFilenameExtention(selectedDocument.title);

          try {

            if (!record) throw new Error('Failed');

            const documents = await API.put(`client/${client_id}/${entity}/${type}/${record.id}/document/${selectedDocument.id}`, { data: JSON.stringify({
              title: newTitle
            }) });

            this.mounted && this.setState({
              documents: documents
            }, () => {
              Notification('success', `Sucessfully renamed document`);
            });

          } catch (error) {
            Notification('error', `Failed to rename document`);
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              selectedDocument: null,
              isRenaming: false,
              showRenameDialog: false,
            });
          }
        } )}
        onCancel={() => this.setState({ showRenameDialog: false, selectedDocument: null })}
      >
        <Form
          ref={ this.renameFormRef }
          layout="vertical"
          initialValues={{
            "title": filterFilenameExtention(selectedDocument.title)
          }}
        >
          <Form.Item
            required
            name="title"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Name is required'
              },
            ]}
          >
            <Input disabled={ isRenaming } />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderProfileModal = (permissions: any, client_id: number, profile_user_id: number) => {
    return (
      <ProfileCard
        clientId={ client_id }
        userId={ profile_user_id }
        canMasquerade={ hasPermission(permissions, 'masquerade') }
        onMasquerade={ async () => {
          try {

            await API.put(`/client/${client_id}/user/masquerade/start`, {
              user_id: profile_user_id
            });
            window.location.reload();

          } catch (error) {
            Notification('error', '', 'Failed to start masquerading');
          }
        } }
        onClose={ () => this.setState({ profile_id: null }) }
      />
    );
  };

  renderInfoModal = (document: any) => {
    const { isDownloading } = this.state;
    return (
      <CoverModal
        style={{ minWidth: '700px', maxHeight: '700px' }}
        middleContent={ 'Document' }
        onClose={ () => !isDownloading && this.setState({ selectedDocument: null, showInfoDialog: false }) }
      >
        <div className="pL-100 pR-100">
          <List
            bordered
            dataSource={ [
              <p><b>Name:</b> { document.title }</p>,
              <p><b>Type:</b> { document.type }</p>,
              <p><b>Size:</b> { document.size }</p>,
              <p><b>Modified:</b> { getFormatedDate(document.updated_at) }</p>,
              <p><b>Created:</b> { getFormatedDate(document.updated_at) }</p>,
            ] }
            renderItem={ item => <List.Item>{ item }</List.Item> }
          />
          <div className="ta-c mT-50 mB-30">
            <Button type="primary" loading={ isDownloading } disabled={ isDownloading } onClick={ () => this.handleDownloadSingle(document.key) }>Download</Button>
          </div>
        </div>
      </CoverModal>
    );
  };

  renderActionList = (document: any) => {

    if (document.type === 'folder' || (_.has(document, 'config.view_only') && !!document.config.view_only)) return <></>;

    return (
      <div onClick={ (e: any) => e.stopPropagation() }>
        <AntDropdown
          overlay={
            <Menu>
              <Menu.Item
                key='rename'
                disabled={ !document.config.can_edit }
                icon={ <EditOutlined /> }
                onClick={ () => this.setState({
                  showRenameDialog: true,
                  selectedDocument: document,
                }) }
              >
                Rename
              </Menu.Item>
              <Menu.Item
                key='remove'
                danger={ !!document.config.can_delete }
                disabled={ !document.config.can_delete }
                icon={ <DeleteOutlined /> }
                onClick={ () => this.setState({
                  showDeleteDialog: true,
                  selectedDocument: document,
                }) }
              >
                Remove
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button style={{ padding: '4px 7px', width: 32 }}>...</Button>
        </AntDropdown>
      </div>
    );
  };

  renderDocumentsList = (documents: any, actions?: Action[]) => {
    const columns = [
      {
        title: 'Name',
        key: 'title',
        dataIndex: 'title',
        width: '55%',
        render: (__: any, record: any) => {

          let icon = <FileUnknownOutlined className="fsz-md" />;

          switch (record.type) {
            case 'folder':
              if (_.has(record, 'config.view_only') && !!record.config.view_only) {
                icon = <FolderViewOutlined className="fsz-md" />;
              } else {
                icon = <FolderOutlined className="fsz-md" />;
              }
              break;
            case 'pdf':
              icon = <FilePdfOutlined className="fsz-md" />;
              break;
            case 'docx':
              icon = <FileWordOutlined className="fsz-md" />;
              break;
          }

          return (
            <span className='d-f'>
              <span className="d-if ai-c" style={{ fontSize: 17 }}>{ icon }</span>
              <span className='d-if' style={{ maxWidth: '95%' }}>
                <span className='whs-nw ov-h tov-e'>
                  { record.type !== 'folder' ? (
                    <span className="mL-10 ai-c"><Link onClick={ () => this.setState({ selectedDocument: record, showInfoDialog: true }) }>{ record.title }</Link></span>
                  ) : (
                    <span className="mL-10 ai-c">{ record.title}</span>
                  ) }
                </span>
                { record?.type === 'folder' &&
                  <span className='ai-c mL-5'>
                    { `(${this.getFileCount(record.children)})` }
                  </span>
                }
              </span>
              { !!record.version_changed &&
                <Tooltip
                  className="mL-5 va-m"
                  placement="top"
                  title={ 'This document has been modified since the previous version' }
                >
                  <InfoCircleOutlined className="fsz-def text-warning" />
                </Tooltip>
              }
            </span>
          );
        },
      },
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        width: '10%',
        render: (type: string) => {
          return (
            <span style={{ minWidth: 800 }}>
              { type === 'folder' ? '-' : type }
            </span>
          );
        }
      },
      {
        title: 'Size',
        key: 'size',
        dataIndex: 'size',
        width: '10%',
      },
      {
        title: 'Modified',
        key: 'modified',
        dataIndex: 'modified',
        width: '20%',
        render: (__: any, record: any) => {
          const date = record?.updated_at ? getFormatedDate(record.updated_at) : false;
          return (
            <div onClick={ (e: any) => e.stopPropagation() }>
              { date && record?.updated_by?.fullname ? (
                <>
                  <span>{ date }</span>
                  <span> by <Link onClick={ () => this.setState({ profile_id: record.updated_by.id }) }>{ record.updated_by.fullname }</Link></span>
                </>
              ) : (
                <span>-</span>
              ) }
            </div>
          );
        }
      },
      {
        title: !!actions ? (
          <Dropdown actions={ actions } />
        ) : '',
        key: 'actions',
        dataIndex: 'actions',
        align: Alignment.Center,
        width: 50,
        render: (_: any, document: any) => this.renderActionList(document)
      },
    ];

    return (
      <Table
        size={ 'small' }
        columns={ columns }
        dataSource={ documents }
        pagination={ false }
        rowClassName={ (record: any) => {
          return _.isEmpty(record.children) ? 'us-n' : 'us-n cur-p';
        }}
        expandable={{
          expandRowByClick: true,
          defaultExpandedRowKeys: !_.isEmpty(documents) ? [documents[0].key] : []
        }}
        rowSelection={{
          checkStrictly: false,
          onChange: (selectedRowKeys: any) => {
            const filteredNestedSet = this.getFilteredNestedSet(documents, selectedRowKeys);
            this.setState({ selectedRowKeys: filteredNestedSet });
          }
        }}
      />
    );
  };

  render = () => {
    const { client_id, permissions, entity, record, pure } = this.props;
    const {
      profile_id,
      selectedDocument,
      showUploadDialog,
      showInfoDialog,
      showDeleteDialog,
      isFetching,
      isDownloading,
      documents,
      selectedRowKeys,
      showRenameDialog,
    } = this.state;

    const type = _.snakeCase(this.props.type);
    const actions: Action[] = [
      {
        disabled: _.isEmpty(selectedRowKeys),
        isLoading: isDownloading,
        onClick: () => this.handleDownloadBundle(selectedRowKeys),
        node: <><DownloadOutlined /><span className="mL-10">Download</span></>
      },
    ];
    if (!_.isEmpty(documents) && !this.isReadOnlyList(documents)) {
      actions.unshift({
        disabled: !hasPermission(record.permissions, `${entity}_${type}_document_create`) && ["You don't have permissions to upload documents"],
        onClick: () => this.setState({ showUploadDialog: true }),
        node: <><UploadOutlined /><span className="mL-10">Upload</span></>
      });
    }

    return (
      <BlockingSpinner isLoading={ isFetching }>
        { pure ? (
          <div>
            { this.renderDocumentsList(documents || [], actions) }
          </div>
        ) : (
          <Jumbotron
            content={ 'Documents' }
            title={ this.props.record?.title }
            tabs={ [
              {
                label: '',
                node: this.renderDocumentsList(documents || []),
              },
            ] }
            rightActions={ [
              {
                node: (
                  <Dropdown actions={ actions } />
                )
              }
            ] }
          />
        ) }
        { showUploadDialog && this.renderUploadDialog() }
        { !!client_id && !!profile_id && this.renderProfileModal(permissions, client_id, profile_id) }
        { !!selectedDocument && showInfoDialog && this.renderInfoModal(selectedDocument) }
        { !!selectedDocument && showDeleteDialog && this.renderDeleteDialog(selectedDocument) }
        { !!selectedDocument && showRenameDialog && this.renderRenameDialog(selectedDocument) }
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    client_id: store.ClientState.client_id,
  };
};

export default connect(mapStateToProps, null)(DocumentView);