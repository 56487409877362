// Libs
import * as React from 'react';
import { Switch } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';

// Styles
import './Switch.scss';

interface Props {
  className?: string;
  wrapperClassName?: string;
  size?: 'default' | 'small';
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  loading?: boolean;
  wrapper?: boolean;
};

const SwitchComponent: React.FC<Props> = props => {
  return (
    <div className="Switch">
      <div
        className={ classNames(`${props.wrapperClassName || ''}`, {
          'Switch-Wrapper': !!props.wrapper,
          'Switch--deselected': !props.checked,
        }) }
      >
        { props.children && props.children }
        <Switch { ..._.omit(props, ['wrapperClassName', 'wrapper']) } />
      </div>
    </div>
  );
};

export default SwitchComponent;