// Libs
import _ from 'lodash';

// Interfaces
import { ArrowHeadType, FlowElement, HandleType } from 'react-flow-renderer';
import { CustomFlowElementType, WorkflowStage, WorkflowTransition } from 'components/workflow';

export const convertToFlowElements = (
  data: WorkflowStage[],
  actions: Partial<{
    onDoubleClickByNode: (stage: WorkflowStage) => void;
    onDoubleClickByEdge: (transition: WorkflowTransition) => void;
  }> = {},
) => {
  const { onDoubleClickByNode, onDoubleClickByEdge } = actions;
  const elements: FlowElement[] = [];

  data.forEach((stage: WorkflowStage, index: number) => {
    elements.push({
      id: stage.id.toString(),
      data: { stage, onDoubleClickByNode },
      type: CustomFlowElementType.Node,
      position: {
        x: parseInt(stage.position.x),
        y: parseInt(stage.position.y),
      },
    });

    if (!_.isEmpty(stage.transitions)) {
      stage.transitions.forEach((transition: WorkflowTransition) => {
        elements.push({
          id: `edge-${transition.id}-${index}`,
          source: `${transition.source_stage_id}`,
          sourceHandle: generateHandleId(transition.source_handle, 'source'),
          target: `${transition.target_stage_id}`,
          targetHandle: generateHandleId(transition.target_handle, 'target'),
          type: CustomFlowElementType.Edge,
          data: {
            transition,
            onDoubleClickByEdge,
          },
          animated: true,
          arrowHeadType: ArrowHeadType.Arrow,
        });
      });
    }
  });

  return elements;
};

export const generateHandleId = (id: any, type: HandleType, separator: string = '_') => `${type}${separator}${id}`;

export const getHandleId = (id: string, separator: string = '_') => Number(_.last(id.split(separator)));

export const getBadges = (config: { [key: string]: string[] }, showRoles: number = 2): { title: string; tooltip?: string }[] => {
  const { roles, field } = config;
  if (roles) {
    if (roles.length > showRoles) {
      const hiddenItems = roles.slice(showRoles);
      const items = roles.slice(0, showRoles).concat(`+${ hiddenItems.length }`);
      return items.map((title, index, arr) => arr.length - 1 === index ? { title, tooltip: hiddenItems.join(', ') } : { title });
    }
    return roles.map((title) => ({ title }));
  }
  if (field) {
    return [{ title: field[0] }];
  }
  return [];
};

export const transformFieldTitle = (title: string) => title.split('_').map(str => _.capitalize(str)).join(' ');