// Libs
import * as React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';

// Components
import { DatePicker, Select, Table, Pagination, Tooltip, Typography, Input } from 'antd';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import ProfileCard from 'components/profile-card';
import { hasPermission } from 'components/restriction';

// Interfaces
import { AuditChangeEntity, AuditEntity } from 'types/entities';

// Icons
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg';

// Services
import { getFormatedDate, getUserSetting } from 'services/settings';
import { Api } from 'services/api';
import Notification from 'services/notification';

// Styles
import './AuditList.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Link } = Typography;
const { Search } = Input;
const API: Api = new Api();

const messages = defineMessages({
  filter_by: {
    id: 'basic_list_view.filter_by',
    defaultMessage: 'Filter by',
    description: '',
  },
  show: {
    id: 'basic_list_view.show',
    defaultMessage: 'Show',
    description: '',
  },
  entries_of: {
    id: 'basic_list_view.entries_of',
    defaultMessage: 'Entries of',
    description: '',
  },
  quick_filter: {
    id: 'basic_list_view.quick_search',
    defaultMessage: 'Quick Search',
    description: '',
  },
  filter: {
    id: 'basic_list_view.filter',
    defaultMessage: 'Filter',
    description: '',
  },
});

enum Type {
  Range = 'range',
  Select = 'select'
};

interface Column {
  title: string;
  dataIndex: string;
  key: string;
  type: Type;
  render?: any;
};

interface ChangeColumn {
  key: string;
  field: string;
  before: Record<string, any>[];
  after: Record<string, any>[];
};

interface Props {
  client_id: number,
  permissions?: any;
  intl: IntlShape,
  items: AuditEntity[],
};

interface State {
  columns: Column[];
  currentPage: number;
  filters: any;
  items: AuditEntity[];
  itemsPerPage: number;
  quickFilter: string | null;
  showFilter: boolean;
  showSort: boolean;
  sorter: any;
  tooltip: any;
  profileId: number | null;
};

class AuditList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      columns: [
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          type: Type.Select
        },
        {
          title: 'Title',
          dataIndex: 'entity_title',
          key: 'entity_title',
          type: Type.Select
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          type: Type.Select
        },
        {
          title: 'Created At',
          dataIndex: 'created_at',
          key: 'created_at',
          type: Type.Range,
          render: (created_at: any) => {
            return getFormatedDate(created_at, undefined, true);
          }
        },
        {
          title: 'User',
          dataIndex: 'user_name',
          key: 'user_name',
          type: Type.Select,
          render: (__: any, item: AuditEntity) => {

            if (!_.has(item, 'user.id') || !_.has(item, 'user.full_name')) {
              return <>-</>;
            }

            return (
              <Link onClick={ () => this.setState({ profileId: item.user.id }) }>
                { item.user.full_name }
              </Link>
            );
          }
        }
      ],
      currentPage: 1,
      filters: {},
      items: this.formatData(props.items),
      itemsPerPage: 25,
      quickFilter: null,
      showFilter: false,
      showSort: false,
      sorter: null,
      tooltip: null,
      profileId: null,
    };
  }

  formatData = (data: AuditEntity[]) => {
    return data.map((item: any) => ({
      ...item,
      key: item.id,
      user: item.user,
      type: item.type.title,
    }));
  };

  handleSort = (pagination: any, filters: any, sorter: any, extra: any) => {
    this.setState({
      sorter: sorter
    });
  };

  paginateItems = (items: any[], currentPage = 1, itemsPerPage = 25) => {
    return _.drop(items, (currentPage - 1) * itemsPerPage).slice(0, itemsPerPage);
  };

  sortData = (items: any[], sorter: any) => {
    return _.orderBy(items, sorter.field, sorter.order === 'descend' ? 'desc' : 'asc');
  };

  filter = (items: any[], filters: any) => {
    return items.filter((item: any) => {
      return Object.keys(filters).every(key => {

        if (!filters[key].length) return false;

        const column = this.state.columns.find((column: any) => column.dataIndex === key);

        switch (column?.type) {
          case Type.Range:
            const userFormat = `${getUserSetting('date_format')} HH:mm:ss`;
            const unixFormat = 'YYYY-MM-DD HH:mm:ss';
            return moment(moment(item[key], unixFormat).format(unixFormat)).isBetween(moment(filters[key][0], userFormat).format(unixFormat), moment(filters[key][1], userFormat).format(unixFormat));

          case Type.Select:
            return filters[key].includes(item[key]);
        }

        return false;
      });
    });
  };

  quickFilter = (haystack: AuditEntity[], needle: string) => {
    return haystack.filter((entity: AuditEntity) => {
      return ['action', 'entity_title', 'type.title', 'user.full_name', 'created_at'].some((path: string) => {
        return _.hasIn(entity, path) && !!_.get(entity, path) && _.toLower(_.get(entity, path)).includes(_.toLower(needle));
      });
    });
  };

  renderProfileModal = (permissions: any, client_id: number, profile_user_id: number) => {
    return (
      <ProfileCard
        clientId={ client_id }
        userId={ profile_user_id }
        canMasquerade={ hasPermission(permissions, 'masquerade') }
        onMasquerade={ async () => {
          try {

            await API.put(`/client/${client_id}/user/masquerade/start`, {
              user_id: profile_user_id
            });
            window.location.reload();

          } catch (error) {
            Notification('error', '', 'Failed to start masquerading');
          }
        } }
        onClose={ () => this.setState({ profileId: null }) }
      />
    );
  };

  renderFilterSelect = (column: Column, items: any) => {
    const { intl: { formatMessage } } = this.props;
    const {
      filters,
      tooltip
    } = this.state;

    const uniqItems = _.uniqBy(items, column.key)
      .filter((item: any) => {
        return !!item[column.key];
      }).map((item: any) => {
        return item[column.key];
      });

    // No items found
    if (_.isEmpty(uniqItems)) return;

    return (
      <Tooltip key={column.key} visible={tooltip === column.key} title={`${formatMessage(messages.filter_by)} ${column.title}`}>
        <Select
          allowClear
          style={{ width: 200, margin: 5 }}
          placeholder={column.title}
          onMouseEnter={() => {
            filters[column.key] && this.setState({
              tooltip: column.key
            });
          }}
          onMouseLeave={() => {
            tooltip && this.setState({
              tooltip: null
            });
          }}
          onChange={(value: any) => {
            let newFilters = filters;
            if (!value || !value.length) {
              delete newFilters[column.key];
            } else {
              newFilters = Object.assign({}, filters, { [column.key]: value });
            }
            this.setState({
              filters: newFilters,
              currentPage: 1,
            });
          }}
          value={filters[column.key] ? filters[column.key] : []}
        >
          {uniqItems.map((uniqItem: any) => (
            <Option key={uniqItem} value={uniqItem}>
              {uniqItem}
            </Option>
          ))}
        </Select>
      </Tooltip>
    );
  };

  renderFilterRange = (column: Column) => {
    const { filters } = this.state;
    const format = `${getUserSetting('date_format')} HH:mm`;
    return (
      <div className="m-5">
        <RangePicker
          format={ format }
          showTime
          onChange={(dates: any, dateStrings: any) => {
            let newFilters = filters;

            // Check if dateStrings contains empty values e.i ["", ""]
            if (_.some(dateStrings, value => !value.length)) {
              delete newFilters[column.key];
            } else {
              newFilters = Object.assign({}, filters, { [column.key]: dateStrings });
            }

            this.setState({
              filters: newFilters
            });
          }}
        />
      </div>
    );
  };

  renderFilters = (columns: any, items: any) => {
    return (
      <div className="d-f mB-10">
        { columns.filter((column: any) => column.key !== 'action').map((column: any) => {
          return (
            <div key={ column.key }>
              { this.renderFilter(column, items) }
            </div>
          );
        })}
      </div>
    );
  };

  renderFilter = (column: Column, items: AuditEntity) => {
    switch (column.type) {
      case Type.Range:
        return this.renderFilterRange(column);

      case Type.Select:
        return this.renderFilterSelect(column, items);
    }
  };

  renderDetailedRow = (record: AuditEntity) => {

    if (_.isEmpty(record.changes)) return <div />;

    const data = record.changes.map((change: AuditChangeEntity) => {
      const getDiff = (oldObject: any, newObject: any) => {
        return _.omitBy(oldObject, (value, key) => (!_.has(newObject, key) || _.isEqual(newObject[key], value)));
      };

      const removeEmptyElements = (arr: Array<{ [key: string]: any }>) => {
        return arr.map((item) => {
          return Object.keys(item).reduce(
            (acc, key) => (!_.isEmpty(item[key]) ? Object.assign(acc, { [key]: item[key] }) : acc),
            {},
          );
        }).filter((item) => !_.isEmpty(item));
      };

      const before = !_.isEmpty(change.display.before) ? change.display.before.map((item, index) => {
        if (_.isEmpty(change.display.after[index])) {
          return item;
        } else {
          return getDiff(item, change.display.after[index]);
        }
      }) : [];

      const after = !_.isEmpty(change.display.after) ? change.display.after.map((item, index) => {
        if (_.isEmpty(change.display.before[index]) || !change.display.before[index]) {
          return item;
        } else {
          return getDiff(item, change.display.before[index] || {});
        }
      }) : [];

      return {
        key: change.field_id,
        field: change.display.field,
        before: removeEmptyElements(before),
        after: removeEmptyElements(after),
      };
    }).filter(item => !_.isEmpty(item.before) || !_.isEmpty(item.after));

    const shouldShowBeforeColumn = data.some(item => !_.isEmpty(item.before));
    const shouldShowAfterColumn = data.some(item => !_.isEmpty(item.after));

    const columns = [
      {
        title: 'Field',
        dataIndex: 'field',
        key: 'field',
        width: '30%'
      },
      {
        title: shouldShowBeforeColumn ? 'Removed' : '',
        key: 'before',
        dataIndex: 'before',
        width: '35%',
        render: (field: Record<string, any>[], column: ChangeColumn) => {
          return field.map((entry: any, index: number) => {
            return (
              <div className={ classNames({ 'bdB pB-15 mB-15': index < (field.length - 1) }) } key={ `${column.key}-${index}` }>
                { Object.keys(entry).map((key: any) => {
                  const value = !!entry[key] ? entry[key].toString() : ' ';
                  return (
                    <p key={ `${column.key}-${key}` }>
                      <span>{ key }</span>
                      { value && <span className="bg-inverse-danger mL-50 pT-2 pB-2 pL-10 pR-10">{ value }</span> }
                    </p>
                  );
                }) }
              </div>
            );
          });
        }
      },
      {
        title: shouldShowAfterColumn ? 'Added' : '',
        key: 'after',
        dataIndex: 'after',
        width: '35%',
        render: (field: Record<string, any>[], column: ChangeColumn) => {
          return field.map((entry, index: number) => {
            return (
              <div className={ classNames({ 'bdB pB-15 mB-15': index < (field.length - 1) }) } key={ `${column.key}-${index}` }>
                { Object.keys(entry).map((key: any) => {
                  const value = !!entry[key] ? entry[key].toString() : ' ';
                  return (
                    <p key={ `${column.key}-${key}` }>
                      <span>{ key }</span>
                      { value && <span className="bg-inverse-success mL-50 pT-2 pB-2 pL-10 pR-10 ">{ value }</span> }
                    </p>
                  );
                }) }
              </div>
            );
          });
        }
      }
    ];

    return (
      <Table
        size={ 'small' }
        columns={ columns }
        dataSource={ data }
        pagination={ false }
      />
    );
  };

  render = () => {
    const { intl: { formatMessage }, client_id, permissions } = this.props;
    const {
      currentPage,
      itemsPerPage,
      showFilter,
      sorter,
      filters,
      columns,
      items,
      profileId,
      quickFilter,
    } = this.state;

    let data = items;

    // Quickfilter
    if (quickFilter) {
      data = this.quickFilter(data, quickFilter);
    }

    // Filter
    if (!_.isEmpty(filters)) {
      data = this.filter(data, filters);
    }

    // Sort data
    if (sorter) {
      data = this.sortData(data, sorter);
    }

    // Paginate items
    const paginatedData = this.paginateItems(data || [], currentPage, itemsPerPage);

    return (
      <>
        <div className="d-f jc-sb ai-c mB-20 mT-5">
          <div className="d-if">
            <Search
              disabled={ _.isEmpty(columns) || _.isEmpty(items) }
              placeholder={ formatMessage(messages.quick_filter) }
              style={{ width: 300 }}
              onBlur={ event => {
                this.setState({
                  quickFilter: event.target.value || null,
                  currentPage: 1,
                });
              }}
              onSearch={ value => {
                this.setState({
                  quickFilter: value || null,
                  currentPage: 1,
                });
              }}
            />
          </div>
        </div>
        <div
          className="d-f jc-sb ai-c mB-10"
          style={{ userSelect: 'none' }}
        >
          <div className="d-if mL-10">
            <span>{formatMessage(messages.show)}</span>
            <span className="mL-10 mR-10">
              <Select
                size={'small'}
                onChange={(value: number) => {
                  this.setState({
                    currentPage: 1,
                    itemsPerPage: value
                  });
                }}
                defaultValue={itemsPerPage}
              >
                <Option value={25}>25</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
            </span>
            <span>{formatMessage(messages.entries_of)} <b>{data.length}</b></span>
            <span
              className={classNames('link mL-35', { 'active': showFilter || !_.isEmpty(filters) })}
              onClick={() => {
                this.setState({
                  showSort: false,
                  showFilter: !showFilter
                });
              }}
            >
              <Icon
                component={FilterIcon}
              />
              <span> {formatMessage(messages.filter)}</span>
            </span>
          </div>
          <div className="d-if">
            <Pagination
              showSizeChanger={false}
              current={currentPage}
              total={data.length}
              pageSize={itemsPerPage}
              onChange={page => {
                this.setState({
                  currentPage: page
                });
              }}
            />
          </div>
        </div>
        <div>
          { showFilter && this.renderFilters(columns, items) }
        </div>
        <div className='Layout-box'>
          <Table
            size={ 'small' }
            columns={ columns }
            dataSource={ paginatedData }
            onChange={ this.handleSort }
            pagination={ false }
            expandable={{
              rowExpandable: record => !_.isEmpty(record.changes),
              expandedRowRender: record => this.renderDetailedRow(record)
            }}
          />
        </div>
        { profileId && this.renderProfileModal(permissions, client_id, profileId) }
      </>
    );
  };

};

export default (injectIntl(AuditList));