// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Services
import { Api } from 'services/api';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import BasicListView from 'components/basic-list';

const API: Api = new Api();

interface Props {
  client_id?: number;
  customEndpoint: string,
  record: RecordFormEntity;
};

interface State {
  record: any;
  isFetching: boolean;
};

class ListView extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    record: null,
    isFetching: true,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.getData();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.record.updated_at !== this.props.record.updated_at) {
      this.getData();
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  getData = async () => {
    const { client_id, customEndpoint } = this.props;
    try {

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const record = await API.get(`client/${client_id}/${customEndpoint}`);

      this.mounted && this.setState({
        record: record
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  render = () => {
    const { client_id } = this.props;
    const { record, isFetching } = this.state;

    if (!client_id) return <></>;

    return (
      <BlockingSpinner isLoading={ isFetching } style={{ minHeight: 200 }}>
        <BasicListView
          rightActions={ [] }
          columns={ _.has(record, 'columns') ? record.columns : [] }
          items={ _.has(record, 'data') ? record.data : [] }
          hideFilter={ _.has(record, 'hideFilter') ? record.hideFilter : false }
        />
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
  };
};

export default connect(mapStateToProps, null)(ListView);
