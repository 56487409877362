// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _, { isEmpty, isEqual } from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { TreeSelect, Tooltip } from 'antd';
import { regionMap } from 'components/resources-table';

// Icons
import { PartitionOutlined } from '@ant-design/icons';

// Interfaces
import {
  Category as CategoryProp,
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
  FormValues,
} from 'components/form/form-wrapper';

interface Props {
  config: FormFieldConfig;
  field: FormField;
  state: any;
  originalState: FormValues[];
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    values: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  onRefreshForm(field_id: string): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  showPickerModal: boolean;
};

interface CategoryList {
  key: number;
  value: number;
  title: string;
  children?: CategoryList[];
};

const { SHOW_PARENT } = TreeSelect;

class CategoryParentField extends Component<Props, State> {

  state: State = {
    showPickerModal: false,
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { originalState } = this.props;

    this.generateModifiedState(originalState, state, 'category_parent', shouldClear);
    this.generateErrorState(state, 'category_parent', shouldClear);
  };

  generateModifiedState = (rawPastValues: any, rawNewValues: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!isEqual(rawPastValues, rawNewValues) && !shouldClear) {

      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: 0,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (values: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldErrorMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    let errors: string[] = [];

    if (field.config.required && isEmpty(values)) {
      errors.push('Cannot be empty');
    }

    if (!isEmpty(errors) && !shouldClear) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: errors
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  generateCategoriesList = (category: CategoryProp): CategoryList => {
    const { field } = this.props;
    return {
      key: category.id,
      value: category.id,
      title: category.title,
      children: field.categories && field.categories
        .filter((_category: CategoryProp) => category.id === _category.parent_id)
        .map((_category: CategoryProp) => this.generateCategoriesList(_category))
    };
  };

  render = () => {
    const { field, config, border, state, fieldErrorMessages, fieldModifiedMessages, onChange, isDisabled } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_category_parent`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const categories = regionMap(field.categories);

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        required={ field.config.required }
        errors={ errors || [] }
        border={ border }
        description={ !!field.description && field.description }
        versionChanged={ !!field.config.version_changed }
        leftActions={ !!field.config.refresh_on_change ? [
          {
            node: (
              <Tooltip
                className="mL-5"
                placement="top"
                title={ 'Changing the value in the field will make updates in the wider form' }
              >
                <PartitionOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            )
          }
        ] : [] }
      >
        <div className="w-100p">
          <TreeSelect
            id={ field.label } // Resolves a duplication warning
            style={{
              width: '100%',
            }}
            className={ classNames('Select-Field', {
              'Select-Field--has-warning': isModified && _.isEmpty(errors)
            }) }
            showSearch
            treeCheckable={ false }
            treeData={ categories }
            value={ _.has(state, 'parent_id') ? state.parent_id : undefined }
            multiple={ false }
            showCheckedStrategy={ SHOW_PARENT }
            filterTreeNode={ (input: string, option: any) => _.toLower(option.title).includes(_.toLower(input)) }
            disabled={ isDisabled }
            onChange={ (category_id: number) => {
              onChange(field, { parent_id: category_id }, config);
            } }
            placeholder={ field.label }
          />
        </div>
      </FieldWrapper>
    );
  };
};

export default CategoryParentField;
