// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import _, { isEmpty } from 'lodash';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';
import { UserPermissions } from 'types/permissions';
import { IGroup, IGroupUser } from 'views/admin/groups/Groups';

// Components
import Jumbotron from 'components/jumbotron';
import { hasPermission } from 'components/restriction';
import ProfileCard from 'components/profile-card';
import { Table, Dropdown, Button, Menu, Modal, Form, Select, Typography, Empty } from 'antd';
import BlockingSpinner from 'components/blocking-spinner';
import Badge, { BadgeType } from 'components/badge';

// Utils
import { nestedSet } from 'utils/utils';
import history from 'utils/history';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

const API: Api = new Api();

interface Props {
  id?: number;
  type: string;
  entity: string;
  client_id?: number;
  permissions?: UserPermissions;
  pure?: boolean;
  record?: RecordFormEntity;
  getRecord(silent?: boolean): void;
}

interface State {
  record: RecordFormEntity | null;
  profile_id: number | null;
  availableUsers: IGroupUser[];
  addUserPlaceholder: {
    groupId: number;
    userId: number | null;
  } | null;
  groups: IGroup[];
  isFetching: boolean;
  isFetchingUsers: boolean;
  isDeleting: boolean;
  isAddingUser: boolean;
  deleteUser: {
    groupId: number;
    userId: number;
  } | null;
};

class GroupView extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    record: null,
    profile_id: null,
    groups: [],
    availableUsers: [],
    addUserPlaceholder: null,
    deleteUser: null,
    isFetching: false,
    isFetchingUsers: false,
    isAddingUser: false,
    isDeleting: false,
  };

  componentDidMount = async () => {
    const { type, client_id, record, entity } = this.props;

    this.mounted = true;

    try {

      if (!client_id || !record) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const groups = await API.get(`client/${client_id}/${_.kebabCase(entity)}/${_.kebabCase(type)}/${record.id}/group`);

      this.mounted && this.setState({
        groups: groups
      });
    } catch (error) {
      console.error('Error: ', error);
    } finally {
        this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { type, client_id, record, entity } = this.props;
    if (!_.isEqual(this.state.addUserPlaceholder, prevState.addUserPlaceholder) && !!this.state.addUserPlaceholder) {
      try {

        if (!client_id || !record) throw new Error('Failed');

        await new Promise((resolve) => this.setState({ isFetchingUsers: true }, () => resolve(null)));
        let userId = null;

        const availableUsers = await API.get(`client/${client_id}/${_.kebabCase(entity)}/${_.kebabCase(type)}/${record.id}/group/${this.state.addUserPlaceholder.groupId}/available_group_users`);

        if (availableUsers && availableUsers.length === 1) {
          userId = availableUsers[0].id;
        }

        this.mounted && this.setState({
          availableUsers: availableUsers,
          addUserPlaceholder: {
            ...this.state.addUserPlaceholder,
            userId: userId,
          }
        });
      } catch (error) {
        console.error('Error: ', error);
      } finally {
          this.mounted && this.setState({
          isFetchingUsers: false,
        });
      }
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderAddUserModal = (addUserPlaceholder: { groupId: number, userId: number | null}) => {
    return (
      <Modal
        centered
        visible
        closable
        title={ 'Add User' }
        okText={ 'Add' }
        maskClosable={ false }
        okButtonProps={{
          disabled: !addUserPlaceholder?.groupId || !addUserPlaceholder?.userId,
        }}
        cancelButtonProps={{
          disabled: this.state.isFetchingUsers || this.state.isAddingUser,
        }}
        confirmLoading={ this.state.isAddingUser }
        onCancel={ () => this.setState({ addUserPlaceholder: null }) }
        onOk={ async () => {
          try {

            if (!this.props.record) throw new Error('Failed');

            await new Promise((resolve) => this.setState({ isAddingUser: true }, () => resolve(null)));

            const groups = await API.post(`client/${this.props.client_id}/${_.kebabCase(this.props.entity)}/${_.kebabCase(this.props.type)}/${this.props.record.id}/group/${addUserPlaceholder.groupId}/user`, {
              user_ids: [addUserPlaceholder.userId]
            });

            this.mounted && this.setState({
              groups: groups,
              deleteUser: null,
            });
          } catch (error) {
            console.error('Error: ', error);
            Notification('error', 'Failed to add user from group', 'Failed');
          } finally {
              this.mounted && this.setState({
              isAddingUser: false,
              addUserPlaceholder: null
            });
          }
        } }
      >
        <Form layout="vertical">
          <Form.Item label={ 'User' } required>
            <Select
              showSearch
              placeholder={ this.state.availableUsers.length === 0 ? 'No Users Found' : 'Select User' }
              filterOption={ (input: any, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              } }
              onChange={(userId: number) => {
                this.setState({
                  addUserPlaceholder: {
                    groupId: addUserPlaceholder.groupId,
                    userId: userId,
                  }
                });
              }}
              value={ addUserPlaceholder?.userId || undefined }
              loading={ this.state.isAddingUser || this.state.isFetchingUsers }
              disabled={ _.isEmpty(this.state.availableUsers) }
            >
              { this.state.availableUsers.map((user: any) => (
                <Select.Option key={`add-user-${user.id}`} value={user.id}>
                  { `${user.full_name} - ${user.email}` }
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderDeleteDialog = (deleteUser: { groupId: number, userId: number }) => {
    return (
      <Modal
        visible
        centered
        title={ 'Remove' }
        okText={ 'Remove' }
        maskClosable={ false }
        closable
        okButtonProps={{
          danger: true,
          disabled: this.state.isDeleting,
          loading: this.state.isDeleting,
        }}
        cancelButtonProps={{
          disabled: this.state.isDeleting,
        }}
        onOk={ async () => {
          try {

            if (!this.props.record) throw new Error('Failed');

            await new Promise((resolve) => this.setState({ isDeleting: true }, () => resolve(null)));

            const groups = await API.delete(`client/${this.props.client_id}/${_.kebabCase(this.props.entity)}/${_.kebabCase(this.props.type)}/${this.props.record.id}/group/${deleteUser.groupId}/user`, {
              user_ids: [deleteUser.userId]
            });

            this.mounted && this.setState({
              groups: groups,
              deleteUser: null,
            });
          } catch (error) {
            console.error('Error: ', error);
            Notification('error', 'Failed to remove user from group', 'Failed');
          } finally {
              this.mounted && this.setState({
              isDeleting: false,
              deleteUser: null,
            });
          }
        } }
        onCancel={ () => this.setState({ deleteUser: null }) }
      >
        <p>Are you sure you want to remove this user?</p>
      </Modal>
    );
  };

  renderProfileModal = (permissions: any, client_id: number, profile_userId: number) => {
    return (
      <ProfileCard
        clientId={ client_id }
        userId={ profile_userId }
        canMasquerade={ hasPermission(permissions, 'masquerade') }
        onMasquerade={ async () => {
          try {

            await API.put(`/client/${client_id}/user/masquerade/start`, {
              user_id: profile_userId
            });
            window.location.reload();

          } catch (error) {
            Notification('error', '', 'Failed to start masquerading');
          }
        } }
        onClose={ () => this.setState({ profile_id: null }) }
      />
    );
  };

  renderTable = () => {
    const { permissions, client_id } = this.props;
    const { record, profile_id, groups, deleteUser, addUserPlaceholder } = this.state;
    const type = _.snakeCase(this.props.type);
    const bundle = _.snakeCase(this.props.entity);

    const canManage = hasPermission(permissions, `${bundle}_${type}_group_manage_user`) || hasPermission(record, `permissions.${bundle}_${type}_group_manage_user`);

    return (
      <>
        <div className='Layout-box'>
          { !_.isEmpty(groups) ? (
            <Table
              className="cur-p"
              size={ 'small' }
              columns={ [
                {
                  title: 'Group',
                  dataIndex: 'group',
                  key: 'group',
                  render: (__: any, _group: IGroup) => {
                    return <span>{ `${_group.title} (${_group.users.length})` }</span>;
                  },
                },
                {
                  title: '',
                  key: 'actions',
                  align: 'right',
                  render: (__: any, _group: IGroup) => {
                    if (canManage) {
                      return (
                        <Button
                          style={{
                            padding: '4px 7px',
                            width: 32,
                          }}
                          onClick={async (e: React.MouseEvent) => {
                            e.stopPropagation();
                            this.setState({ addUserPlaceholder: { groupId: _group.id, userId: null } });
                          }}
                        >
                          +
                        </Button>
                      );
                    }

                    return <></>;
                  },
                },
              ] }
              dataSource={ nestedSet(groups) }
              pagination={ false }
              expandable={{
                defaultExpandAllRows: true,
                expandRowByClick: true,
                expandedRowRender: (_group: IGroup) => {
                  return (
                    <Table
                      columns={ [
                        {
                          title: 'Name',
                          key: 'name',
                          width: '30%',
                          render: (__: any, _user: IGroupUser) => (
                            <Typography.Link onClick={ () => this.setState({ profile_id: _user.id }) }>
                              { _user.full_name }
                            </Typography.Link>
                          ),
                        },
                        {
                          title: 'Company',
                          key: 'company',
                          width: '30%',
                          render: (__: any, _user: IGroupUser) => (
                            <span>{ _user?.company?.title || '' }</span>
                          ),
                        },
                        {
                          title: 'Record',
                          key: 'record',
                          dataIndex: 'record',
                          width: 150,
                          render: (_: any, _user: IGroupUser) => {
                            if (!!_user?.entity?.path) {
                              return (
                                <Typography.Link onClick={ () => history.push(_user?.entity?.path) }>
                                  { _user?.entity?.title }
                                </Typography.Link>
                              );
                            }

                            return _user?.entity?.title || '-';
                          },
                        },
                        {
                          title: 'Status',
                          key: 'status',
                          width: 100,
                          render: (__: any, _user: IGroupUser) => {
                            switch (_user.type) {
                              case 'DIRECT':
                                return <Badge type={ BadgeType.Success } text={ 'Direct' } />;
                              case 'INHERITED':
                                return <Badge type={ BadgeType.Default } text={ 'Inherited' } />;
                              default:
                                return '-';
                            }
                          },
                        },
                        {
                          title: '',
                          key: 'actions',
                          align: 'right',
                          width: 100,
                          render: (__: any, _user: IGroupUser) => {
                            if (canManage && _user.type === 'DIRECT') {
                              return (
                                <Dropdown
                                  trigger={ ['click'] }
                                  overlay={
                                    <Menu>
                                      <Menu.Item
                                        key={ `delete` }
                                        danger
                                        onClick={ () => {
                                          this.setState({
                                            deleteUser: {
                                              groupId: _group.id,
                                              userId: _user.id,
                                            }
                                          });
                                        } }
                                      >
                                        Remove
                                      </Menu.Item>
                                    </Menu>
                                  }>
                                    <Button
                                      style={{
                                        padding: '4px 7px',
                                        width: 32,
                                      }}
                                    >
                                      ...
                                    </Button>
                                </Dropdown>
                              );
                            }
                            return <></>;
                          },
                        },
                      ] }
                      dataSource={ nestedSet(_group.users) }
                      pagination={ false }
                    />
                  );
                }
              }}
            />
          ) : (
            <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } description={ 'No Groups' } />
          ) }
        </div>
        { !!addUserPlaceholder && addUserPlaceholder?.groupId && this.renderAddUserModal(addUserPlaceholder) }
        { !!deleteUser && this.renderDeleteDialog(deleteUser) }
        { !!client_id && !!profile_id && this.renderProfileModal(permissions, client_id, profile_id) }
      </>
    );
  };

  render = () => {
    return (
      <BlockingSpinner isLoading={ this.state.isFetching }>
        <Jumbotron
          content={ 'Groups' }
          title={ this.props.record?.title }
          tabs={[
            {
              label: '',
              node: this.renderTable(),
            },
          ]}
        />
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    client_id: store.ClientState.client_id,
  };
};

export default connect(mapStateToProps, null)(GroupView);
