// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

// Components
import { RestrictionHoC } from 'components/restriction';

// Views
import Insights from 'views/insight/Insights';
import Report from 'views/insight/Report';
import Insight from 'views/insight/Insight';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';

interface Props extends RouteComponentProps {
  permissions: any;
};

class InsightsContainer extends React.Component<Props> {

  render = () => {
    return (
      <Switch>
        <Route exact path="/insights" component={ Insights } />
        <Route path="/insights/:insight_id/:mode" component={ Insight } />
        <Route path="/insights/:insight_id" component={ Insight } />
        <Route path="/reports/:report_id" component={ Report } />
        <Route render={ () => <NotFound /> } />
      </Switch>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(RestrictionHoC(InsightsContainer, 'access_reporting'));