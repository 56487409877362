// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Views
import Entities from 'views/admin/entity/Entities';
import Entity from 'views/admin/entity/Entity';
import NotFound from 'views/NotFound';

class EntityContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Redirect exact from="/admin/record-settings" to="/admin/record-settings/record" />

        <Route exact path="/admin/record-settings/:entity_bundle" component={ Entities } />

        <Route exact path="/admin/record-settings/:entity_bundle/:type" component={ Entity } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

export default EntityContainer;
