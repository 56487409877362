// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Form, InputNumber, Button } from 'antd';

// Components
import BlockingSpinner from 'components/blocking-spinner';

// Services
import { Api } from "services/api";
import Notification from 'services/notification';
import history from 'utils/history';

// Actions
import { logoutUser } from 'store/User/Actions';
import { setActiveClient } from 'store/Client/Actions';

// Views
import ClientPortal from 'views/auth/ClientPortal';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';

const API: Api = new Api();

interface Props {
  user: UserEntity;
  logoutUser: () => Promise<void>;
  setActiveClient: (client_id: number) => void;
};

interface State {
  isLoading: boolean;
  showPortal: boolean;
};

class MultiFactorAuthentication extends React.Component<Props, State> {

  mounted: boolean = false;

  state = {
    isLoading: false,
    showPortal: false,
  };

  componentDidMount = () => {
    this.mounted = true;

    // Not authenticated
    if (!this.props.user) {
      history.push('/');
    } else {
      this.handleNewRequest(false);
    }

  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleLogout = () => {
    this.setState({
      isLoading: false,
      showPortal: false
    }, () => {
      this.props.logoutUser();
      history.push('/');
    });
  };

  handleNewRequest = (showNotification: boolean = true) => {
    const { user } = this.props;
    this.setState(
      {
        isLoading: true,
      }, async () => {
        try {
          await API.put(`request_mfa`, { data: { user_id: user.id }});
          showNotification && Notification('success', 'A new MFA code has been sent to you');
        } catch (error) {
          console.error('Error: ', error);
          Notification('error', error.data ? error.data : 'Something went wrong', 'Failed');
        } finally {
          this.mounted && this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  handleSubmit = (values: any) => {
    const { user } = this.props;
    this.setState(
      {
        isLoading: true,
      }, async () => {
        try {
          await API.put(`verify_mfa`, { data: { user_id: user.id, code: values.code } });

          if (user.clients.length === 1) {
            this.props.setActiveClient(user.clients[0].id);
          } else {
            await new Promise((resolve) => this.setState({ showPortal: true }, () => resolve(null) ));
          }

        } catch (error) {
          console.error('Error: ', error);
          Notification('error', error.data ? error.data : 'Something went wrong', 'Failed');
        } finally {
          this.mounted && this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  render = () => {
    const { showPortal, isLoading } = this.state;

    // Loading spinner
    if (isLoading) return <div className="d-f jc-c ai-c mH-500"><BlockingSpinner isLoading /></div>;

    return (
      <>
        <div className="mB-40 ta-c">
          <h1>Multi-Factor Authentication</h1>
          <p>An authentication code has been sent to your email address.</p>
        </div>
        <Form
          className="auth-form"
          layout="vertical"
          onFinish={ this.handleSubmit }
        >
          <Form.Item
            name="code"
            rules={[{ required: true, message: 'Code is required' }]}
          >
            <InputNumber className="w-100p" />
          </Form.Item>
          <Form.Item>
            <div className="ta-c">
              <Button
                block
                type="primary"
                className="login-form-button"
                htmlType="submit"
                disabled={ isLoading }
                loading={ isLoading }
              >
                Login
              </Button>
            </div>
          </Form.Item>
          <Form.Item>
            <div className="ta-c">
              <span>Didn't receive the code? </span><span className="link primaryColor" onClick={ () => this.handleNewRequest() }>Request code</span>
            </div>
          </Form.Item>
        </Form>
        { showPortal && <ClientPortal initial onCancel={ () => this.handleLogout() } /> }
      </>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    user: store.UserState.user,
    clients: store.UserState.user.clients || [],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    setActiveClient: (client_id: number) => dispatch(setActiveClient(client_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiFactorAuthentication);