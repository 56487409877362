// Services
import store from 'store/Store';

// Interfaces
import {
  AppInitialisationStartAction,
  AppInitialisationFailedAction,
  AppInitialisationCompleteAction,
  IsPrimarySidebarCollapsedAction,
  IsSecondarySidebarCollapsedAction,
  setBreadcrumbsLoadingAction,
  setBreadcrumbsAction,
  setSecondarySidebarRoutesAction,
  setIsBlockedAction,
} from './Actions.interface';

// Types
import UIActionTypes from './ActionTypes.enum';

export function initialiseAppStart(): AppInitialisationStartAction {
  return {
    type: UIActionTypes.APP_INITIALISATION_START,
  };
};

export function initialiseAppComplete(): AppInitialisationCompleteAction {
  return {
    type: UIActionTypes.APP_INITIALISATION_SUCCESS,
  };
};

export function initialiseAppFailed(error: any): AppInitialisationFailedAction {
  return {
    type: UIActionTypes.APP_INITIALISATION_FAILED,
    initialisationError: error
  };
};

export const setPrimarySidebarCollapsed = (value: boolean): IsPrimarySidebarCollapsedAction => {
  return {
    type: UIActionTypes.SET_PRIMARY_SIDEBAR_COLLAPSED,
    value: value,
  };
};

export const setSecondarySidebarCollapsed = (value: boolean): IsSecondarySidebarCollapsedAction => {
  return {
    type: UIActionTypes.SET_SECONDARY_SIDEBAR_COLLAPSED,
    value: value,
  };
};

export const setBreadcrumbsLoading = (value: boolean): setBreadcrumbsLoadingAction => {
  return {
    type: UIActionTypes.SET_BREADCRUMBS_LOADING,
    value: value
  };
};

export const setBreadcrumbs = (value: any[], concat: boolean = false): setBreadcrumbsAction => {
  return {
    type: UIActionTypes.SET_BREADCRUMBS,
    value: concat ? store.getState().UIState.breadcrumbs.concat(value) : value,
  };
};

export const setSecondarySidebarRoutes = (value: any): setSecondarySidebarRoutesAction => {
  return {
    type: UIActionTypes.SET_SECONDARY_SIDEBAR_ROUTES,
    value: value
  };
};

export const setIsBlocked = (value: boolean): setIsBlockedAction => {
  return {
    type: UIActionTypes.SET_IS_BLOCKED,
    value: value
  };
};