// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import { Skeleton, Tooltip } from 'antd';
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import Badge, { BadgeType } from 'components/badge';
import Insight from 'components/insight';
import Dropdown from 'components/dropdown';

// Services
import { Api } from 'services/api';
import { getFormatedDate } from 'services/settings';
import Notification from 'services/notification';

// Actions
import { setBreadcrumbs, setBreadcrumbsLoading } from 'store/UI/ActionCreators';

// Views
import InsightModal from 'views/insight/InsightModal';
import InsightCopyModal from 'views/insight/InsightCopyModal';

// Utils
import history from 'utils/history';

// Interfaces
import { UserEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';
import { IPreview, IInsight, OnSaveCallback } from 'components/insight/Insight.interfaces';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  match: { params: Record<string, any> };
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  insight: IPreview | null;
  isFetching: boolean;
  isExporting: boolean;
  showInsightModal: boolean;
  showInsightCopyModal: boolean;
};

class InsightView extends React.Component<Props, State> {

  mounted: boolean = false;
  insightComponent: any = React.createRef();

  state: State = {
    insight: null,
    isFetching: false,
    isExporting: false,
    showInsightModal: this.props.match?.params?.mode === 'edit',
    showInsightCopyModal: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.getInsight();
    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Insights', path: '/insights' }
    ], false);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.params.insight_id !== this.props.match.params.insight_id) {
      this.getInsight();
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getInsight = async () => {
    try {

      if (this.props.match.params.insight_id && this.props.client_id) {

        await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

        this.props.setBreadcrumbsLoading(true);
        const insight = await API.get(`client/${this.props.client_id}/insight/report/${this.props.match.params.insight_id}`);

        this.mounted && this.setState({
          insight: insight
        }, () => {
          this.props.setBreadcrumbs([
            { title: 'Home', path: '/' },
            { title: 'Insights', path: '/insights' },
            { title: insight.title, path: null },
          ], false);
        });

        if (_.has(insight, 'error')) {
          Notification('error', insight?.error, 'Report Failed');
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  handleSave = async (clientId: number, insight: any, callback: OnSaveCallback) => {
    try {

      await API.put(`client/${clientId}/insight/builder/report/${insight.id}`, {
        data: insight
      });

      this.getInsight();

    } catch (error) {
      console.error(error);
    } finally {
      callback();
    }
  };

  onCopy = async (insightId: number, title: string, callback: () => void) => {
    try {
      const { client_id } = this.props;
      const copiedRecord = await API.post(`client/${client_id}/insight/report/copy/${insightId}`, {
        title: title
      });

      Notification('success', 'The Record has been copied', 'Copied');

      this.setState({
        showInsightCopyModal: false
      }, () => {
        if (!!copiedRecord?.id) {
          history.push(`/insights/${copiedRecord?.id}`);
        }
      });
    } catch (error: any) {
      console.error(error);
      Notification('error', _.has(error, 'data') && !!error.data.message ? error.data.message : 'Failed to copy Record', 'Failed');
    } finally {
      callback();
    }
  };

  renderListView = (clientId: number, insight: IPreview | null) => {
    const { isFetching } = this.state;

    if (!insight || isFetching) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    return (
      <Insight
        ref={ insightComponent => this.insightComponent = insightComponent }
        clientId={ clientId }
        insight={ insight }
        isLoading={ this.state.isFetching }
        isPreview={ false }
      />
    );
  };

  render = () => {
    const { client_id } = this.props;
    const { insight, showInsightModal, showInsightCopyModal } = this.state;

    return (
      <>
        <Jumbotron
          content={
            <>
              <div className="fsz-lg fw-600 mB-0">
                <span className='d-if'>
                  <Skeleton
                    paragraph={ false }
                    title={{
                      style: {
                        width: 200,
                        margin: 0,
                      }
                    }}
                    loading={ !insight?.title }
                    active={ !insight?.title }
                  >
                    <p className="fsz-lg fw-600">{ insight?.title }</p>
                  </Skeleton>
                </span>
                <span className="mL-10">
                  { insight?.generated_at ? (
                    <Tooltip
                      placement="top"
                      title={ `Last generated ${getFormatedDate(insight?.generated_at)}` }
                    >
                      <span>
                        <Badge type={ BadgeType.Default } text={ 'Insight' } />
                      </span>
                    </Tooltip>
                  ) : (
                    <span>
                      <Badge type={ BadgeType.Default } text={ 'Insight' } />
                    </span>
                  ) }
                </span>
              </div>
            </>
          }
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Export',
                      isLoading: this.state.isExporting,
                      onClick: () => {
                        this.setState({
                          isExporting: true
                        }, () => this.insightComponent.export((successful: boolean) => {
                          this.setState({
                            isExporting: false
                          });
                        }));
                      },
                    },
                    {
                      node: 'Edit',
                      disabled: !insight?.can_edit ? [`You don't have permissions to edit this insight`] : false,
                      onClick: () => this.setState({ showInsightModal: true })
                    },
                    {
                      node: 'Copy',
                      onClick: () => this.setState({ showInsightCopyModal: true })
                    }
                  ] }
                />
              )
            }
          ] }
          tabs={ [
            {
              hidden: true,
              label: 'Details',
              node: this.renderListView(client_id, insight),
            },
          ] }
        />
        { showInsightModal && insight &&
          <InsightModal
            clientId={ client_id }
            insightId={ insight.id }
            onSave={ (insight: IInsight, callback: OnSaveCallback) => this.handleSave(client_id, insight, callback) }
            onClose={ () => this.setState({
              showInsightModal: false
            }, () => {
              history.push(`/insights/${insight?.id}`);
            }) }
          />
        }
        { showInsightCopyModal && insight &&
          <InsightCopyModal
            insight={ insight }
            onClose={ () => this.setState({ showInsightCopyModal: false }) }
            onCopy={ this.onCopy }
          />
        }
      </>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    user: store.UserState.user,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightView);
