// Libs
import * as React from 'react';
import _ from 'lodash';

// Components
import Badge, { BadgeSize, BadgeType } from 'components/badge';
import BlockingSpinner from 'components/blocking-spinner';
import BasicList from "components/basic-list";
import { hasPermission } from 'components/restriction';
import Dropdown, { Action as DropdownAction }  from 'components/dropdown';
import { Form, Input, Modal, Select } from "antd";

// Utils
import { timeConvert } from 'utils/utils';

// Interfaces
import { KpiRecord, KpiRecordStatus } from './KpiLibrary.interfaces';
import { UserPermissions } from 'types/permissions';

// Styles
import './KpiLibrary.scss';

const { TextArea } = Input;

interface Props {
  kpis: KpiRecord[];
  permissions?: UserPermissions;
  onChangeStatus(kpiId: number, status: string | null, reason: string | null): void;
  isFetching: boolean;
};

interface State {
  showChangeStatusModal: boolean;
  kpiStatus: string | null;
  kpiStatusReason: string | null;
  selectedKpiRow: any;
  isChangingStatus: boolean;
};

class KpiList extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    showChangeStatusModal: false,
    kpiStatus: null,
    kpiStatusReason: null,
    selectedKpiRow: null,
    isChangingStatus: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderTarget = (target: KpiRecord['target_measure']): JSX.Element => {
    if (_.has(target, 'minutes')) {
      const { hours, minutes } = timeConvert(target.minutes);
      return (
        <span>{ `${hours}h ${minutes}min` }</span>
      );
    }

    if (_.has(target, 'from') && _.has(target, 'to')) {
      return (
        <>
          <span className="mR-20">From: { target.from }%</span>
          <span>To: { target.to }%</span>
        </>
      );
    }

    return <span>-</span>;
  };

  renderStatus = (status: KpiRecordStatus): JSX.Element => {
    switch (status) {
      case KpiRecordStatus.Pass:
        return <Badge type={ BadgeType.Success } text={ 'Pass' } size={ BadgeSize.Large } />;

      case KpiRecordStatus.Pending:
        return <Badge type={ BadgeType.Disabled } text={ 'Pending' } size={ BadgeSize.Large } />;

      case KpiRecordStatus.Fail:
        return <Badge type={ BadgeType.Danger } text={ 'Fail' } size={ BadgeSize.Large } />;
    }

    return <span>-</span>;
  };

  renderList = (kpis: KpiRecord[]): JSX.Element => {
    const { permissions } = this.props;

    const columns = [
      {
        key: 'title',
        dataIndex: 'title',
        title: 'Title',
        render: (title: string) => {
          return <span>{ title || '-' }</span>;
        },
        sorter: true,
        filterable: true,
        ellipsis: true,
        width: 120,
      },
      {
        key: 'target_measure',
        dataIndex: 'target_measure',
        title: 'Target',
        render: (target: KpiRecord['target_measure']) => {
          return this.renderTarget(target);
        },
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 120,
      },
      {
        key: 'actual',
        dataIndex: 'actual',
        title: 'Actual',
        render: (actual: KpiRecord['actual_measure']) => {
          return  <span>{ actual || '-' }</span>;
        },
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 120,
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: 'Status',
        render: (status: KpiRecordStatus) => {
          return this.renderStatus(status);
        },
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 120,
      },
    ];

    if (hasPermission(permissions, 'kpi_change_status')) {
      columns.push(
        {
          key: 'actions',
          dataIndex: 'actions',
          title: '',
          render: (row: any) => {
            const _actions: DropdownAction[] = [
              {
                node: '',
                onClick: () => {}
              },
              {
                node: 'Change Status',
                onClick: () => {
                  let initialStatus: any = null;
                  if ([KpiRecordStatus.Pending, KpiRecordStatus.Pass].includes(row?.status) === false) {
                    initialStatus = KpiRecordStatus.Pass;
                  }  else if([KpiRecordStatus.Pending, KpiRecordStatus.Fail].includes(row?.status) === false) {
                    initialStatus = KpiRecordStatus.Fail;
                  }

                  this.setState({ kpiStatus: initialStatus, showChangeStatusModal: true, selectedKpiRow: row });
                },
              }
            ];

            return <Dropdown actions={ _actions } />;
          },
          sorter: false,
          filterable: false,
          ellipsis: true,
          width: 30,
        }
      );
    }

    const mapData = (kpis: KpiRecord[]) => {
      return kpis.map((kpiRecord: KpiRecord, index: number) => {
        return {
          'key': index,
          'id': kpiRecord.id,
          'title': kpiRecord.title,
          'status': kpiRecord.status,
          'target_measure': kpiRecord.target_measure,
          'actual_measure': kpiRecord.actual_measure,
          'actions': kpiRecord,
        };
      });
    };

    return <BasicList columns={ columns } items={ mapData(kpis) } rawData />;
  };

  handleStatusChange = (kpiId: number, status: string | null, reason: string | null) => {
    this.setState({
      isChangingStatus: true
    }, () => {
      this.props.onChangeStatus(kpiId, status, reason);
    });
  };

  renderStatusModal = () => {
    const { isChangingStatus, kpiStatus, kpiStatusReason, selectedKpiRow } = this.state;

    return (
      <Modal
        visible
        centered
        maskClosable={ !isChangingStatus }
        closable={ !isChangingStatus }
        title={ 'Change Kpi Status' }
        okText={ 'Update' }
        onOk={ () => this.setState({
            isChangingStatus: true
          }, () => {
            this.handleStatusChange(selectedKpiRow.id, kpiStatus, kpiStatusReason);
          }
        ) }
        onCancel={ () => this.setState({ showChangeStatusModal: false, selectedKpiRow: null, kpiStatus: null, kpiStatusReason: null }) }
        okButtonProps={ {
          loading: isChangingStatus,
          disabled: _.isEmpty(kpiStatus) || _.isEmpty(kpiStatusReason)
        } }
        cancelButtonProps={ {
          disabled: isChangingStatus
        } }
      >
        <p>This will change kpi pass/fail status.</p>
        <div className="mT-20">
          <Form
            layout="horizontal"
            labelCol={{ span: 4 }}
            initialValues={ {
              status: kpiStatus,
              reason: '',
            } }
          >
            <Form.Item
              label="Status"
              name="status"
              required
            >
              <Select
                style={ { width: '100%' } }
                onChange={ (status: string) => this.setState({ kpiStatus: status }) }
              >
                { [KpiRecordStatus.Pending, KpiRecordStatus.Pass].includes(selectedKpiRow?.status) === false && <Select.Option value={ 'PASS' } key={ 'status_pass_option' }>{ 'Pass' }</Select.Option> }
                { [KpiRecordStatus.Pending, KpiRecordStatus.Fail].includes(selectedKpiRow?.status) === false && <Select.Option value={ 'FAIL' } key={ 'status_fail_option' }>{ 'Fail' }</Select.Option> }
              </Select>
            </Form.Item>
            <Form.Item
              label="Reason"
              name="reason"
              rules={ [{ max: 250, message: "Value should be less than 250 character" }] }
              required
            >
              <TextArea
                onChange={ (event) => this.setState({ kpiStatusReason: event.target.value }) }
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  };

  render = (): JSX.Element => {
    const { kpis, isFetching } = this.props;
    const { showChangeStatusModal } = this.state;

    return (
      <BlockingSpinner isLoading={ isFetching }>
        { this.renderList(kpis) }
        { showChangeStatusModal && this.renderStatusModal() }
      </BlockingSpinner>
    );
  };
};

export default KpiList;
