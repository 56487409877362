// Libs
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

// Containers
import MaintenancePlanContainer from 'containers/workplace-service/maintenance-plan/MaintenancePlanContainer';
import AssetCategoryContainer from './maintenance-plan/AssetCategoryContainer';
import AssetTypeContainer from './maintenance-plan/AssetTypeContainer';

// Views
import MaintenancePlans from 'views/workplace-service/MaintenancePlans';
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

class MaintenancePlansContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Route exact path="/workplace-services/maintenance-plan" component={ MaintenancePlans } />

        <Route path="/workplace-services/maintenance-plan/:maintenance_plan_id/asset-category/:category_id/asset-type/:type_id" component={ AssetTypeContainer } />

        <Route path="/workplace-services/maintenance-plan/:maintenance_plan_id/asset-category/:category_id" component={ AssetCategoryContainer } />

        <Route path="/workplace-services/maintenance-plan/:maintenance_plan_id" component={ MaintenancePlanContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

export default MaintenancePlansContainer;
