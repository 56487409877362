// Libs
import React from 'react';

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import Jumbotron from 'components/jumbotron';
import DragSortingList from 'components/drag-sorting-list';
import Dropdown, { Action } from 'components/dropdown';
import { Tooltip, Button, Popconfirm, Input, Select } from 'antd';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Utils
import { isBlank } from 'utils/utils';
import { arrayMoveImmutable } from 'utils/formSetup';

// Icons
import Icon, { DeleteOutlined, MenuOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { TableType } from 'components/drag-sorting-list/DragSortingList.interfaces';
import { ClassificationTemplateType, Classification, ClassificationData, Modified } from 'views/admin/templates/Templates.interfaces';

// Styles
import './ClassificationTemplate.scss';

const API: Api = new Api();

const getBlankState = (order: number): any => {
  const uniqueKey: string = uuidv4();
  return {
    id: uniqueKey,
    name: '',
    order: order,
    field_id: null,
    operator: null,
    workflow: [],
    isNewClassification: true, // The parameter is needed to recognize the new classification
  };
};

const getDragAndDropDisableConfig = () => {
  return {
    draggable: true,
    onDragStart: (event: React.DragEvent<HTMLInputElement>) => event.preventDefault(),
  };
};

interface Props {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  classifications: Classification[];
  originalClassifications: Classification[];
  classificationData: ClassificationData | null;
  recordTitle: string;
  isLoading: boolean;
  isSaving: boolean;
};

class ClassificationTemplate extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    classifications: [],
    originalClassifications: [],
    classificationData: null,
    recordTitle: '',
    isLoading: false,
    isSaving: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const classification_type_id = this.props.match.params.classification_type_id;

    this.mounted = true;

    try {

      this.props.setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));
      const classificationData: ClassificationData = await API.get(`client/${client_id}/admin/activities/classifications`);
      const classifications: Classification[] = await API.get(`client/${client_id}/admin/activities/classifications/${classification_type_id}`);

      const classificationType = classificationData.types.find((type: ClassificationTemplateType) => type.id === +classification_type_id);
      const recordTitle = classificationType?.title || 'Classification Template';

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Template Types', path: '/admin/templates/classifications' },
        { title: recordTitle, path: null },
      ], false);

      this.mounted && this.setState({
        recordTitle: recordTitle,
        classificationData: classificationData,
        classifications: classifications,
        originalClassifications: classifications,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getModified = (): Modified => {
    const { classifications, originalClassifications } = this.state;
    const modified: Modified = {};
    const schema: Array<keyof Classification> = ['name', 'field_id', 'operator', 'workflow', 'order'];

    if (!_.isEqual(classifications, originalClassifications)) {

      // check if a classification has been removed
      const isRemoved = _.some(originalClassifications, (originalClassification) => {
        return _.every(classifications, (classification) => classification.id !== originalClassification.id);
      });

      if (isRemoved) {
        modified['removed'] = null;
      }

      // check if the classification has changed
      classifications.forEach((classification) => {
        const oldValue = originalClassifications.find((_oldValue: Classification) => _oldValue.id === classification.id);
        // check if a new classification has been added
        if (!oldValue) {
          modified[classification.id] = schema;
        } else if (!_.isEqual(classification, oldValue)) {
          schema.forEach((key) => {
            if (_.has(classification, key) && !_.isEqual(classification[key], oldValue[key])) {
              modified[classification.id] = _.concat(modified[classification.id] || [], key);
            }
          });
        }
      });
    }

    return modified;
  };

  getErrors = (values: Classification[]): Modified => {
    const errors: Modified = {};

    values.forEach((value: Classification) => {
      if (_.isEmpty(value.name.trim())) {
        errors[value.id] = ['name'];
      }
    });

    return errors;
  };

  isModified = (modified: Modified, classificationId: number, fieldKey: string): boolean => {
    return _.has(modified, classificationId) && modified[classificationId].includes(fieldKey);
  };

  hasError = (errors: Modified, classificationId: number, fieldKey: string): boolean => {
    return _.has(errors, classificationId) && errors[classificationId].includes(fieldKey);
  };

  delete = (identifier: number, values: Classification[]): Classification[] => {
    return values.filter((value: Classification) => value.id !== identifier);
  };

  modifyValues = (identifier: number, values: Classification[], newValue: any, key: string): Classification[] => {
    return values.map((value: Classification) => {
      if (value.id === identifier) {
        value = _.set(value, [key], newValue);
      }

      return {
        ...value,
      };
    });
  };

  handleSave = async (): Promise<void> => {
    const classification_type_id = this.props.match.params.classification_type_id;
    const { client_id } = this.props;
    const { classifications } = this.state;

    try {
      await new Promise((resolve) => this.setState({ isSaving: true }, () => resolve(null)));

      const response = await API.put(`client/${client_id}/admin/activities/classifications/${classification_type_id}`, {
        data: classifications.map((classification: Classification) => {
          return _.has(classification, 'isNewClassification') ? _.omit(classification, ['id', 'isNewClassification']) : classification;
        }),
      });

      this.mounted && this.setState({
        classifications: response,
        originalClassifications: response,
      });
      Notification('success', '', 'Successfully updated classifications');
    } catch (error) {
      Notification('error', 'Failed to update classifications', 'Failed');
    } finally {
      this.mounted && this.setState({ isSaving: false });
    }
  };

  onReorder = async (dragIndex: number, hoverIndex: number): Promise<void> => {
    const { classifications } = this.state;
    if (dragIndex !== hoverIndex) {
      const rows = arrayMoveImmutable<Classification>(classifications, dragIndex, hoverIndex).filter((element) => !!element);
      const reorderedRows = rows.map((row, idx) => ({ ...row, order: idx }));
      this.mounted && this.setState({ classifications: reorderedRows });
    }
  };

  renderColumnTitle = (rows: string[], tooltip: string, required: boolean = false): JSX.Element => {
    return (
      <div className='d-f'>
        <div className='d-f fxd-c'>
          { rows.map((row, idx) => <span key={ `${row}_${idx}` }>{ row }</span>) }
        </div>
        { required && <span className="text-required mL-2 fsz-md va-t">*</span> }
        <Tooltip
          className="mL-5 pT-1"
          placement="top"
          title={ tooltip }
        >
          <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
        </Tooltip>
      </div>
    );
  };

  renderClassifications = (): JSX.Element => {
    const { classifications, classificationData, isLoading } = this.state;

    const modified = this.getModified();
    const errors = this.getErrors(classifications);

    const columns = [
      {
        dataIndex: 'sort',
        title: this.renderColumnTitle(
          ['Sort'],
          'Drag and Drop the rows in order of priority. Note, where a Helpdesk ticket meets the conditions for more than one Classification it is assigned to whichever classification is higher in this table',
        ),
        render: () => <MenuOutlined className="DragMenu" />,
        width: 70,
        ellipsis: true,
        align: 'center' as 'center',
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: this.renderColumnTitle(
          ['Name'],
          'This title will be used to name the created classification',
          true
        ),
        render: (name: Classification['name'], row: Classification) => {
          const hasErrors = this.hasError(errors, row.id, 'name');
          const isModified = this.isModified(modified, row.id, 'name');

          return (
            <Input
              className={ classNames('Field', {
                'Field--has-error border-danger': hasErrors,
                'Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                this.mounted && this.setState({
                  classifications: this.modifyValues(row.id, _.cloneDeep(classifications), e.target.value, 'name'),
                });
              } }
              value={ name }
              // disable dragging
              { ...getDragAndDropDisableConfig() }
            />
          );
        },
        width: 220,
        ellipsis: true,
      },
      {
        key: 'operator',
        dataIndex: 'operator',
        title: this.renderColumnTitle(
          ['Operator'],
          `Operator Relative to today's date`,
        ),
        render: (operator: Classification['operator'], row: Classification) => {
          const isModified = this.isModified(modified, row.id, 'operator');

          // check should be highlighted as modified cell
          const shouldHighlightModified = _.has(row, 'isNewClassification') ? !_.isEmpty(operator) : isModified;

          return (
            <Select
              showSearch
              allowClear
              dropdownMatchSelectWidth={ false }
              style={{ width: '100%' }}
              placeholder={ '-' }
              className={ classNames('Select-Field', {
                'Select-Field--has-warning border-warning': shouldHighlightModified,
              }) }
              filterOption={(input: string, option: any) => {
                return !!classificationData?.operators.find((record: string) => record.toLowerCase() === option.children.toLowerCase() && record.toLowerCase().includes(input.toLowerCase()) );
              } }
              value={ operator }
              onChange={ (fieldValue: string) => {
                // if no value is selected, the value will be set to null
                const value = isBlank(fieldValue) ? null : fieldValue;
                this.mounted && this.setState({
                  classifications: this.modifyValues(row.id, _.cloneDeep(classifications), value, 'operator'),
                });
              } }
            >
              { classificationData?.operators.map( (operator: string) => (
                <Select.Option key={ operator } value={ operator }>{ operator }</Select.Option>
              )) }
            </Select>
          );
        },
        width: 150,
        ellipsis: true,
      },
      {
        key: 'field_id',
        dataIndex: 'field_id',
        title: this.renderColumnTitle(
          ['Date Field'],
          'Please select the date field from the helpdesk form that you wish to use for the classification',
        ),
        render: (dateField: Classification['field_id'], row: Classification) => {
          const isModified = this.isModified(modified, row.id, 'field_id');

          // check should be highlighted as modified cell
          const shouldHighlightModified = _.has(row, 'isNewClassification') ? !_.isEmpty(dateField) : isModified;

          return (
            <Select
              showSearch
              allowClear
              dropdownMatchSelectWidth={ false }
              style={{ width: '100%' }}
              placeholder={ '-' }
              className={ classNames('Select-Field', {
                'Select-Field--has-warning border-warning': shouldHighlightModified,
              }) }
              filterOption={(input: string, option: any) => {
                return !!classificationData?.fields.find((record) => record.label.toLowerCase() === option.children.toLowerCase() && record.label.toLowerCase().includes(input.toLowerCase()) );
              } }
              value={ dateField }
              onChange={ (fieldId: number) => {
                // if no value is selected, the value will be set to null
                const value = isBlank(fieldId) ? null : fieldId;
                this.mounted && this.setState({
                  classifications: this.modifyValues(row.id, _.cloneDeep(classifications), value, 'field_id'),
                });
              } }
            >
              { classificationData?.fields.map( (dateField) => (
                <Select.Option key={ dateField.field_id } value={ dateField.field_id }>{ dateField.label }</Select.Option>
              )) }
            </Select>
          );
        },
        width: 220,
        ellipsis: true,
      },
      {
        key: 'workflow',
        dataIndex: 'workflow',
        title: this.renderColumnTitle(
          ['Workflow Context'],
          'Please select the workflow contexts that the classification applies to. Note a Ticket has to meet the date conditions AND it’s workflow stage be in one of the selected workflow contexts for the classification to be applied',
        ),
        render: (workflowContext: Classification['workflow'], row: Classification) => {
          const isModified = this.isModified(modified, row.id, 'workflow');

          // check should be highlighted as modified cell
          const shouldHighlightModified = _.has(row, 'isNewClassification') ? !_.isEmpty(workflowContext) : isModified;

          return (
            <Select
              showSearch
              allowClear
              mode='multiple'
              dropdownMatchSelectWidth={ false }
              maxTagCount={ 'responsive' }
              maxTagTextLength={ workflowContext?.length === 1 ? 22 : 12 }
              style={{ width: '100%' }}
              placeholder={ '-' }
              className={ classNames('Select-Field', {
                'Select-Field--has-warning border-warning': shouldHighlightModified,
              }) }
              filterOption={(input: string, option: any) => {
                return !!classificationData?.workflows.find((record: string) => record.toLowerCase() === option.children.toLowerCase() && record.toLowerCase().includes(input.toLowerCase()) );
              } }
              value={ workflowContext }
              onChange={ (workflowIds: string[]) => {
                this.mounted && this.setState({
                  classifications: this.modifyValues(row.id, _.cloneDeep(classifications), workflowIds, 'workflow'),
                });
              } }
            >
              { classificationData?.workflows.map( (workflowContext: string) => (
                <Select.Option key={ workflowContext } value={ workflowContext }>{ workflowContext }</Select.Option>
              )) }
            </Select>
          );
        },
        width: 260,
        ellipsis: true,
      },
      {
        key: 'actions',
        dataIndex: '',
        title: (
          <Button
            className={ 'ActionButton' }
            onClick={ () => this.mounted && this.setState({ classifications: _.concat(classifications, getBlankState(classifications.length)) }) }
          >
            <PlusOutlined />
          </Button>
        ),
        render: (__: any, row: Classification) => {
          return (
            <Popconfirm
              title={ 'Are you sure?' }
              icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
              okButtonProps={{
                danger: true
              }}
              placement="topRight"
              onConfirm={ () => {
                this.mounted && this.setState({
                  classifications: this.delete(row.id, classifications),
                });
              } }
            >
              <DeleteOutlined
                className="link"
                style={{ fontSize: 18 }}
              />
            </Popconfirm>
          );
        },
        width: 80,
        ellipsis: true,
        fixed: 'right' as 'right',
        align: 'center' as 'center',
      },
    ];

    const sortedClassifications = classifications.sort((a: Classification, b: Classification) => a.order - b.order);

    const scrollX = columns.reduce((acc, curr) => acc += curr.width, 0);

    return (
      <div className='Layout-box'>
        <DragSortingList
          className={ 'ClassificationsTable' }
          columns={ columns }
          items={ sortedClassifications }
          isParent
          bordered
          sticky
          pagination={ false }
          scroll={{
            x: scrollX,
          }}
          loading={{
            spinning: isLoading,
            indicator: <BlockingSpinner isLoading />,
          }}
          config={{
            type: TableType.Tab,
            references: [],
          }}
          moveRow={ this.onReorder }
        />
      </div>
    );
  };

  render = () => {
    const { classifications, originalClassifications, recordTitle, isSaving } = this.state;
    const hasErrors = !_.isEmpty(this.getErrors(classifications));
    const isModified = !_.isEmpty(this.getModified());

    const actions: Action[] = [
      {
        node: 'Save',
        type: 'primary',
        onClick: this.handleSave,
        disabled: hasErrors ? ['Error on form'] : !isModified ? ['No unsaved changes'] : false,
        isLoading: isSaving
      },
      {
        node: 'Cancel',
        onClick: () => this.setState({ classifications: originalClassifications }),
        disabled: !isModified ? ['No unsaved changes'] : false,
      }
    ];

    return (
      <BlockingSpinner isLoading={ false }>
        <Jumbotron
          content={ recordTitle }
          tabs={[
            {
              label: 'Classifications',
              node: this.renderClassifications(),
            }
          ]}
          rightActions={ [
            {
              node: (
                <div className="d-if">
                  { (hasErrors || isModified) && (
                    <Tooltip
                      placement="top"
                      title={ hasErrors ? 'Please resolve all errors' : 'You have unsaved changes' }
                    >
                      <Button
                        type={ 'primary' }
                        className={ classNames('InfoBox__button InfoBox__button--info InfoBox__button--with-spacing', {
                          'InfoBox__button--warning': isModified
                        }) }
                        danger={ hasErrors }
                      >
                        <Icon component={ InfoIcon } />
                      </Button>
                    </Tooltip>
                  ) }
                  <Dropdown
                    actions={ actions }
                  />
                </div>
              )
            }
          ] }
        />
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(ClassificationTemplate), 'access_admin_content_manager'));
