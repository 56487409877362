// Libs
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import Jumbotron from "components/jumbotron";
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import DragSortingList from 'components/drag-sorting-list';
import { Modal, Form, Input, Popconfirm, Tooltip, Spin, Popover } from 'antd';
import { TwitterPicker, ColorResult } from '@hello-pangea/color-picker';
import Dropdown from 'components/dropdown';

// Icons
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined, MenuOutlined, CloseCircleOutlined } from '@ant-design/icons';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';
import { TableType } from 'components/drag-sorting-list/DragSortingList.interfaces';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Utils
import { arrayMoveImmutable } from 'utils/formSetup';

// Services
import Notification from 'services/notification';
import { getFormatedDate } from 'services/settings';
import { Api } from 'services/api';

export interface ISelectList {
  id: number;
  list_id: string;
  option: string;
  reference: string;
  order: number;
  color: string | null;
  protected: number;
  created_at: string;
  updated_at: string | null;
};

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  list: ISelectList[] | null;
  placeholderList: ISelectList[] | null;
  placeholderColor: string | undefined;
  isLoading: boolean;
  isFetching: boolean;
  showCreateModal: boolean;
  showOrderConfirmationDialog: boolean;
  isCreating: boolean;
  activeOptionId: number | null;
  deleteConfirmId: number | null;
  isReordering: boolean;
  isReorderingAlphabetically: boolean;
};

const API: Api = new Api();

class SelectList extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;
  formRef: any = React.createRef();

  state: State = {
    list: null,
    placeholderList: null,
    placeholderColor: undefined,
    isLoading: false,
    isFetching: false,
    showCreateModal: false,
    showOrderConfirmationDialog: false,
    isCreating: false,
    activeOptionId: null,
    deleteConfirmId: null,
    isReordering: false,
    isReorderingAlphabetically: false,
  };

  componentDidMount = async () => {
    this.mounted = true;

    try {
      const { client_id, setBreadcrumbs, setBreadcrumbsLoading } = this.props;
      const type = this.props.match.params.type;

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Content Management', path: '/admin/content-manager' },
      ], false);

      setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const list = await API.get(`client/${client_id}/admin/content-manager/select-lists/${type}`);

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Content Management', path: '/admin/content-manager' },
        { title: 'Select List', path: '/admin/content-manager/select-lists' },
        { title: _.startCase(_.toLower(type)).split('_').join(' '), path: null },
      ], false);

      this.mounted && this.setState({
        list: list,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isFetching: false
      });
    }

  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  onReorder = async (list: ISelectList[], dragIndex: number, hoverIndex: number) => {
    const { client_id } = this.props;
    const type = this.props.match.params.type;

    if (dragIndex !== hoverIndex) {
      try {

        await new Promise((resolve) => this.setState({ isReordering: true }, () => resolve(null)));

        const reorderedOptions = arrayMoveImmutable<ISelectList>(list, dragIndex, hoverIndex).filter((element: any) => !!element);
        const newList = await API.put(`client/${client_id}/admin/content-manager/select-lists/${type}`, {
          data: reorderedOptions
        });

        this.mounted && this.setState({
          list: newList
        });

      } catch (error) {
        console.error('Error: ', error);
      } finally {
        this.mounted && this.setState({
          isReordering: false
        });
      }
    }
  };

  renderAddDialog = () => {
    const { isLoading, placeholderColor } = this.state;
    const { client_id } = this.props;
    const type = this.props.match.params.type;
    return (
      <Modal
        visible
        centered
        title={ 'Add Option' }
        onOk={ () => this.mounted && this.setState({
            isCreating: false
          }, () => {
            this.formRef
              .current
              .validateFields()
              .then( async (values: any) => {
                try {

                  await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

                  const list = await API.post(`client/${client_id}/admin/content-manager/select-lists/${type}`, {
                    data: {
                      option: values.option,
                      reference: values.reference,
                      color: placeholderColor || null
                    }
                  });

                  this.mounted && this.setState({
                    showCreateModal: false,
                    list: list,
                  }, () => {
                    Notification('success', 'The option has been added to the list.', 'Option Added');
                  });

                } catch (error) {
                  Notification('error', 'The reference must be different from other options.', 'Reference Not Unique');
                } finally {
                  this.mounted && this.setState({
                    isLoading: false,
                    placeholderColor: undefined,
                  });
                }
              })
              .catch((info: any) => {
                console.error('Invalid state');
              });
          }
        )}
        okText={ 'Add' }
        onCancel={ () => this.setState({
          showCreateModal: false,
          placeholderColor: undefined,
        }) }
        okButtonProps={{
          loading: isLoading,
          disabled: isLoading
        }}
      >
        <Form
          ref={ this.formRef }
          layout="vertical"
        >
          <Form.Item
            label="Label"
            name="option"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Reference"
            name="reference"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Colour"
            name="color"
          >
            <Popover
              placement={ 'bottom' }
              trigger={ 'click' }
              content={
                <TwitterPicker
                  styles={{ card: { boxShadow: 'none' } }}
                  color={ placeholderColor }
                  colors={ ['#c4d8de', '#0f9aee', '#00875a', '#efb636', '#d6494a'] }
                  width={ 350 }
                  triangle={ 'hide' }
                  onChange={ (color: ColorResult) => {
                    this.setState({
                      placeholderColor: color.hex
                    });
                  } }
                />
              }
            >
              <Input
                addonBefore={ '#' }
                suffix={ placeholderColor ? (
                  <>
                    <CloseCircleOutlined
                      onClick={ () => {
                        this.setState({
                          placeholderColor: undefined
                        });
                      } }
                    />
                    <span
                      style={{
                        background: placeholderColor || '#ffffff',
                        height: 20,
                        width: 20,
                        position: 'relative',
                        outline: 'none',
                        float: 'left',
                        borderRadius: 4,
                      }}
                    />
                  </>
                ) : (
                  <span />
                ) }
                value={ placeholderColor?.replace('#', '')?.toUpperCase() || undefined }
              />
            </Popover>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderOrderConfirmationDialog = (list: any) => {
    const { isReorderingAlphabetically } = this.state;
    const { client_id } = this.props;
    const type = this.props.match.params.type;
    return (
      <Modal
        visible
        centered
        title={ 'Order Alphabetically' }
        maskClosable={ !isReorderingAlphabetically }
        closable={ !isReorderingAlphabetically }
        okButtonProps={{
          danger: true,
          disabled: isReorderingAlphabetically,
          loading: isReorderingAlphabetically,
        }}
        cancelButtonProps={{
          disabled: isReorderingAlphabetically,
        }}
        onOk={ () => {
          this.setState({ isReorderingAlphabetically: true }, async () => {
            try {

              const newList = await API.put(`client/${client_id}/admin/content-manager/select-lists/${type}`, {
                data: list.sort((a: any, b: any) => {
                  return a.option.localeCompare(b.option);
                })
              });

              this.mounted && this.setState({
                list: newList,
              }, () => {
                Notification('success', `Order changed`);
              });

            } catch(error) {
              Notification('error', `Failed to change order`);
              console.error('Error: ', error);
            } finally {
              this.mounted && this.setState({
                isReorderingAlphabetically: false,
                showOrderConfirmationDialog: false,
              });
            }
          });
        } }
        onCancel={ () => this.setState({ showOrderConfirmationDialog: false }) }
      >
        <p className="mB-10">Are you sure you want to change the order?</p>
        <p>This will sort all options in this set alphabetically and cannot be undone.</p>
      </Modal>
    );
  };

  renderEditDialog = (list: ISelectList[], option_id: number) => {
    const { client_id } = this.props;
    const { isLoading, placeholderList } = this.state;
    const type = this.props.match.params.type;
    const option = list.find((_option: ISelectList) => _option.id === option_id);
    return (
      <Modal
        visible
        centered
        title={ 'Edit Option' }
        onOk={ () => this.mounted && this.setState({
            isCreating: false
          }, () => {
            this.formRef
              .current
              .validateFields()
              .then( async (values: any) => {
                try {

                  await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

                  const newList = await API.put(`client/${client_id}/admin/content-manager/select-lists/${type}`, {
                    data: list.map((_option: ISelectList) => {
                      if (_option.id === option_id) {
                        const placeholderOption = placeholderList?.find((_list: ISelectList) => _list.id === option_id);
                        return {
                          ...placeholderOption,
                          option: values.option,
                          reference: values.reference,
                        };
                      }
                      return _option;
                    })
                  });

                  this.mounted && this.setState({
                    activeOptionId: null,
                    list: newList
                  }, () => {
                    Notification('success', 'The option has been changed', 'Option Changed');
                  });

                } catch (error) {
                  Notification('error', 'The reference must be different from other options.', 'Reference Not Unique');
                } finally {
                  this.mounted && this.setState({
                    isLoading: false,
                    placeholderList: null,
                  });
                }
              })
              .catch((info: any) => {
                console.error('Invalid state');
              });
          }
        )}
        okText={ 'Save' }
        onCancel={() => this.setState({
          activeOptionId: null,
          placeholderList: null,
        })}
        okButtonProps={{
          disabled: isLoading,
          loading: isLoading,
        }}
      >
        <Form
          ref={ this.formRef }
          layout="vertical"
          preserve={ false }
          initialValues={{
            'option': option?.option,
            'reference': option?.reference,
          }}
        >
          <Form.Item
            label="Label"
            name="option"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Reference"
            name="reference"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Colour"
            name="color"
          >
            <Popover
              placement={ 'bottom' }
              trigger={ 'click' }
              content={
                <TwitterPicker
                  styles={{ card: { boxShadow: 'none' } }}
                  color={ option?.color || undefined }
                  triangle={ 'hide' }
                  colors={ ['#c4d8de', '#0f9aee', '#00875a', '#efb636', '#d6494a'] }
                  width={ 350 }
                  onChange={ (color: ColorResult) => {
                    this.setState({
                      placeholderList: list.map((option: ISelectList) => {
                        if (option.id === option_id) {
                          return {
                            ...option,
                            color: color.hex
                          };
                        }
                        return option;
                      })
                    });
                  } }
                />
              }
            >
              <Input
                addonBefore={ '#' }
                suffix={ option?.color ? (
                  <>
                    <CloseCircleOutlined
                      onClick={ () => {
                        this.setState({
                          placeholderList: list.map((option: ISelectList) => {
                            if (option.id === option_id) {
                              return {
                                ...option,
                                color: null
                              };
                            }
                            return option;
                          })
                        });
                      } }
                    />
                    <span
                      style={{
                        background: option?.color || '#ffffff',
                        height: 20,
                        width: 20,
                        position: 'relative',
                        outline: 'none',
                        float: 'left',
                        borderRadius: 4,
                      }}
                    />
                  </>
                ) : (
                  <span />
                ) }
                value={ option?.color?.replace('#', '')?.toUpperCase() }
              />
            </Popover>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderView = (columns: any, list: any) => {
    const { showCreateModal, activeOptionId, isReordering, showOrderConfirmationDialog, placeholderList } = this.state;
    return (
      <div className='Layout-box'>
        <Spin indicator={ <BlockingSpinner isLoading /> } spinning={ isReordering }>
          <DragSortingList
            columns={ columns }
            items={ list.sort((a: any, b: any) => a.order - b.order) }
            isParent
            pagination={ false }
            config={{
              type: TableType.Tab,
              references: [],
            }}
            moveRow={ (dragIndex: number, hoverIndex: number) => this.onReorder(list, dragIndex, hoverIndex) }
          />
        </Spin>
        { showCreateModal && this.renderAddDialog() }
        { showOrderConfirmationDialog && this.renderOrderConfirmationDialog(list) }
        { activeOptionId && placeholderList && this.renderEditDialog(placeholderList, activeOptionId) }
      </div>
    );
  };

  render = () => {
    const { client_id } = this.props;
    const { list, isFetching } = this.state;
    const type = this.props.match.params.type;

    const mapColumns = () => {
      return [
        {
          title: 'Sort',
          dataIndex: 'sort',
          width: 80,
          render: () => <MenuOutlined className="EntityRolesTab-Table-DragMenu" />,
        },
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Title',
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'color',
          dataIndex: 'color',
          title: 'Colour',
          filterable: false,
          sorter: false,
          ellipsis: false,
          width: 80,
          render: (__: any, list: ISelectList) => {
            if (list?.color) {
              return (
                <span
                  style={{
                    background: list?.color,
                    height: 20,
                    width: 20,
                    position: 'relative',
                    outline: 'none',
                    float: 'left',
                    borderRadius: 4,
                  }}
                />
              );
            }
            return <></>;
          },
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: any) => getFormatedDate(created_at),
          sorter: true,
          ellipsis: true,
          filterable: false,
          width: 200,
        },
        {
          key: 'actions',
          dataIndex: 'actions',
          title: '',
          render: (__: any, option: any) => {
            if (option.protected) {
              return (
                <>
                  <Tooltip placement={ 'top' } title={ 'Protected' }>
                    <EditOutlined
                      disabled
                      className="cur-na text-ant-disabled"
                      style={{ fontSize: 18 }}
                    />
                  </Tooltip>
                  <Tooltip placement={ 'top' } title={ 'Protected' }>
                    <DeleteOutlined
                      disabled
                      className="mL-20 cur-na text-ant-disabled"
                      style={{ fontSize: 18 }}
                    />
                  </Tooltip>
                </>
              );
            }
            return (
              <>
                <EditOutlined
                  className="link"
                  style={{ fontSize: 18 }}
                  onClick={ () => this.setState({
                    activeOptionId: option.id,
                    placeholderList: list
                  })}
                />
                <Popconfirm
                  title={ 'Are you sure?' }
                  icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                  visible={ this.state.deleteConfirmId === option.id }
                  okButtonProps={{
                    danger: true
                  }}
                  onConfirm={ async () => {
                    try {
                      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

                      const list = await API.delete(`client/${client_id}/admin/content-manager/select-lists/${type}`, {
                        option_id: this.state.deleteConfirmId
                      });

                      this.mounted && this.setState({
                        list: list
                      }, () => {
                        Notification('success', 'The option has been deleted.', 'Option Deleted');
                      });

                    } catch (error) {
                      console.error('Error: ', error);
                    } finally {
                      this.mounted && this.setState({
                        isLoading: false,
                        deleteConfirmId: null
                      });
                    }
                  }}
                  onCancel={ () => this.setState({ deleteConfirmId: null }) }
                >
                  <DeleteOutlined
                    disabled={ option.protected }
                    className="mL-20 link"
                    style={{ fontSize: 18 }}
                    onClick={ () => {
                      this.setState({
                        deleteConfirmId: option.id
                      });
                    } }
                  />
                </Popconfirm>
              </>
            );
          },
          width: 150,
          sorter: false,
          ellipsis: true,
          filterable: false,
          align: 'center'
        },
      ];
    };

    const mapData = (list: ISelectList[]) => {
      return list.map((option: ISelectList) => {
        return {
          ...option,
          'key': option.id,
          'title': option.option,
          'protected': !!option.protected,
        };
      });
    };

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          content={ <p className="mB-0">{ !!type ? _.startCase(_.toLower(type)).split('_').join(' ') : '' }</p> }
          tabs={[
            {
              label: 'Overview',
              node: this.renderView(mapColumns(), mapData(list || [])),
            }
          ]}
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Create Option',
                      onClick: () => this.setState({ showCreateModal: true })
                    },
                    {
                      node: 'Order Alphabetically',
                      onClick: () => this.setState({ showOrderConfirmationDialog: true })
                    }
                  ] }
                />
              )
            }
          ] }
        />
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(withRouter(SelectList)), 'access_admin_content_manager'));