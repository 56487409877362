// Libs
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import Badge, { BadgeType } from 'components/badge';
import Progress from 'components/progress';
import { Link } from 'react-router-dom';

// Services
import { getFormatedDate, getNumberFormatProps } from 'services/settings';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormValues
} from "components/form/form-wrapper";

interface Props {
  field: FormField;
  originalState: FormValues;
  state: any;
  config: FormFieldConfig;
  border?: boolean;
};

class CalculatedField extends Component<Props> {

  render = () => {
    const {
      field,
      config,
      border,
      state,
    } = this.props;

    let content: React.ReactNode = <span>{ state.value || '-' }</span>;

    switch (state.type) {
      case 'datetime':
        content = <span>{ getFormatedDate(`${state.value}`) }</span>;
      break;
      case 'number':
      case 'currency':
      case 'area':
        content = (
          <div className="ta-r">
            <NumberFormat
              { ...getNumberFormatProps() }
              value={ state.value !== undefined ? state.value : '-' }
              prefix={ state.prefix || undefined }
              suffix={ state.suffix ? ` ${state.suffix}` : undefined }
              displayType={ 'text' }
            />
          </div>
        );
      break;
      case 'integer':
        content = (
          <div className="ta-r">
            { state.prefix ? `${state.prefix} ` : '' }{ state.value }{ state.suffix ? ` ${state.suffix}` : '' }
          </div>
        );
      break;
      case 'badge':
        let type = BadgeType.Default;
        if (_.has(state, 'color')) {
          switch (_.toUpper(state.color)) {
            case 'GREEN':
              type = BadgeType.Success;
            break;
            case 'AMBER':
              type = BadgeType.Warning;
            break;
            case 'RED':
              type = BadgeType.Danger;
            break;
          }
        }

        if (state.text === null) {
          content = <span>-</span>;
        } else {
          content = <Badge type={ type } size={ state.size } text={ _.startCase(_.toLower(state.text)) } />;
        }
      break;
      case 'progress':
        content = <Progress total={ state.total } value={ state.value } />;
      break;
      case 'route':
        content = <Link className="pY-5" to={ state.value.path }>{ state.value.title }</Link>;
      break;
    }

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        required={ field.config.required }
        border={ border }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        { content }
      </FieldWrapper>
    );
  };

};

export default CalculatedField;
