// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Table, Modal, Switch, Button, Dropdown, Menu } from 'antd';
import TimeRangePicker from 'components/timerangepicker';

// Interfaces
import { FormField } from 'components/form/form-wrapper';

// Utils
import { findAndModifyFirst } from 'utils/utils';

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
};

interface Props {
  spaceRecord: any;
  presets: any;
  isDisabled?: boolean;
  onSave(field: FormField): void;
  onClose(): void;
};

interface State {
  spaceRecord: any;
};

class BusinessHoursModal extends React.Component<Props, State> {

  state: State = {
    spaceRecord: _.cloneDeep(this.props.spaceRecord),
  };

  handleChange = (spaceRecord: any) => {
    this.setState({
      spaceRecord: spaceRecord
    });
  };

  modify = (spaceRecord: any, day: number, newValue: any) => {
    return findAndModifyFirst(_.cloneDeep(spaceRecord), 'opening_hours', { day: day }, newValue);
  };

  getWeekDay = (day: number): string => {
    const days: any = {
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      7: 'Sunday',
    };
    return days[day];
  };

  render = () => {
    const { presets } = this.props;
    const { spaceRecord, isDisabled }: any = this.state;
    const data: any[] = _.has(spaceRecord, 'opening_hours') && spaceRecord.opening_hours.map((opening_hour: any) => {
      return {
        ...opening_hour,
        key: opening_hour.day,
      };
    });
    const columns: any = [
      {
        title: '',
        render: (row: any) => {
          return <span>{ this.getWeekDay(row.day) }</span>;
        }
      },
      {
        title: '',
        render: (row: any) => {
          return (
            <span>
              <Switch
                disabled={ isDisabled }
                checked={ !row.disabled }
                onChange={ (checked: boolean) => {
                  delete row.key;
                  this.handleChange(this.modify(_.cloneDeep(spaceRecord), row.day, {
                    ...row,
                    disabled: !checked,
                  }));
                }}
              />
              <span className="mL-5">{ !row.disabled ? 'Open' : 'Closed' }</span>
            </span>
          );
        }
      },
      {
        title: '',
        width: 400,
        render: (row: any) => {

          if (!!row.disabled) return <></>;

          return (
            <TimeRangePicker
              isDisabled={ isDisabled }
              onChangeTime={ (values: string[]) => {
                delete row.key;
                this.handleChange(this.modify(_.cloneDeep(spaceRecord), row.day, {
                  ...row,
                  start: values[0],
                  end: values[1],
                }));
              } }
              values={[ row.start, row.end ]}
            />
          );
        }
      },
    ];

    return (
      <Modal
        centered
        visible
        closable={ false }
        title={ (
          <div className="d-f jc-sb">
            <span>Business Hours</span>
            <span>
              { !_.isEmpty(presets) &&
                <Dropdown
                  trigger={ ['click'] }
                  overlay={
                    <Menu>
                      { presets.map((preset: any) => (
                        <Menu.Item
                          key={ preset.reference }
                          onClick={ () => this.handleChange(_.set(_.cloneDeep(spaceRecord), 'opening_hours', preset.preset)) }
                        >
                          { preset.title }
                        </Menu.Item>
                      ) ) }
                    </Menu>
                  }
                >
                  <Button>Presets</Button>
                </Dropdown>
              }
            </span>
          </div>
        ) }
        style={{ minWidth: 700 }}
        onCancel={ () => this.props.onClose() }
        okText={ 'Save' }
        okButtonProps={{
          disabled: isDisabled
        }}
        onOk={ () => {
          this.props.onSave(spaceRecord);
          this.props.onClose();
        } }
      >
        <Table
          className="w-100p"
          size={ 'small' }
          showHeader={ false }
          columns={ columns }
          dataSource={ data }
          pagination={ false }
        />
      </Modal>
    );
  };
};

export default BusinessHoursModal;
