// Libs
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import { RestrictionHoC } from 'components/restriction';

// Views
import HelpArticles from 'views/help-articles/HelpArticles';
import NotFound from 'views/NotFound';

// Interfaces
import { Breadcrumb } from 'store/UI/State.interface';
import AppState from 'store/AppState.interface';

// Actions
import { setSecondarySidebarRoutes, setBreadcrumbs } from 'store/UI/ActionCreators';

interface Props {
  user_id: number,
  permissions: any;
  routes: any;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
};

class HelpArticleContainer extends React.Component<Props> {

  mounted: boolean = false;

  constructor(props: Props) {
    super(props);
    this.instantiate();
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  componentDidUpdate = (prevProps: Props) => {
    if (_.isEmpty(prevProps.routes)) {
      this.instantiate();
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.props.setSecondarySidebarRoutes([]);
    this.mounted = false;
  };

  instantiate = () => {

    // Set default breadcrumbs
    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Help Articles', path: '/help-articles' }
    ], false);
  };

  render = () => {
    return (
      <Switch>
        <Route exact path="/help-articles" component={ HelpArticles } />
        <Route render={ () => <NotFound /> } />
      </Switch>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    user_id: store.UserState.user.id,
    routes: store.UIState.secondarySidebarRoutes,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(HelpArticleContainer, 'access_admin'));