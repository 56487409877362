// Libs
import * as React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import BasicList from "components/basic-list";
import Jumbotron from 'components/jumbotron';
import { hasPermission } from 'components/restriction';
import CreateRecordView from 'views/common/CreateRecordView';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';

// Services
import { Api } from 'services/api';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbsLoading,
  setBreadcrumbs,
} from 'store/UI/ActionCreators';

// Interfaces
import { UserEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

const messages = defineMessages({
  create: {
    id: 'general.create',
    defaultMessage: 'Create',
    description: '',
  },
  title: {
    id: 'locations.column_title',
    defaultMessage: 'Title',
    description: '',
  },
  created: {
    id: 'locations.column_created',
    defaultMessage: 'Created',
    description: '',
  },
  list_view: {
    id: 'locations.list_view',
    defaultMessage: 'List View',
    description: '',
  },
  map_view: {
    id: 'locations.map_view',
    defaultMessage: 'Map View',
    description: '',
  },
});

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
};

interface State {
  columns: any[];
  items: any[];
  isFetching: boolean;
  isCreateLoading: boolean;
  showCreateModal: boolean;
};

class MaintenancePlans extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    columns: [],
    items: [],
    isFetching: false,
    isCreateLoading: false,
    showCreateModal: false,
  };

  componentDidMount = async () => {
    const { client_id } = this.props;

    this.mounted = true;

    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Workplace Services', path: '/workplace-services' },
      { title: 'Maintenance Plan', path: '/workplace-services/maintenance-plane' }
    ], false);

    this.props.setSecondarySidebarRoutes([]);

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));
      const response = await API.get(`client/${client_id}/asset-maintenance-plan`);
      const columns = response.columns;
      const items = response.data;

      this.mounted && this.setState({
        columns: columns,
        items: items,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  renderListView = () => {
    return <BasicList columns={ this.state.columns } items={ this.state.items } exportTitle={ 'Maintenance Plan' } />;
  };

  render = () => {
    const { permissions, intl: { formatMessage } } = this.props;
    const { showCreateModal, isFetching, isCreateLoading } = this.state;

    const tabs = [
      {
        label: formatMessage(messages.list_view),
        node: this.renderListView(),
      }
    ];

    const actions: DropdownAction[] = [];

    if (hasPermission(permissions, 'category_asset_maintenance_plan_create')) {
      actions.push({
        node: 'Create Maintenance Plan',
        onClick: () => this.mounted && this.setState({ isCreateLoading: true, showCreateModal: true }),
        isLoading: isCreateLoading
      });
    }

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ 'Maintenance Plan' }
          tabs={ tabs }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
        />
        { showCreateModal &&
          <CreateRecordView
            type={ 'asset-maintenance-plan' }
            entity={ 'category' }
            onReady={ () => this.mounted && this.setState({ isCreateLoading: false }) }
            onClose={ () => this.mounted && this.setState({ showCreateModal: false }) }
          />
        }
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
    client_id: store.ClientState.client_id,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[]) => dispatch(setBreadcrumbs(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MaintenancePlans));
