// Libs
import React from 'react';
import _ from 'lodash';

// Components
import Carousel from 'components/carousel';
import Base64Image from 'components/base64-image';
import CoverModal from 'components/cover-modal';

// Icons
import { FileUnknownOutlined, FilePdfOutlined, FileExcelOutlined, DownloadOutlined, LoadingOutlined } from "@ant-design/icons";

// Services
import { Api } from 'services/api';

const API: Api = new Api();

interface Props {
  clientId: number;
  fileRecord: any;
  files: any[],
  onClose: () => void;
};

interface State {
  isDownloading: boolean;
  currentFileRecord: any;
  files: any;
};

class PreviewModal extends React.Component<Props, State> {

  state: State = {
    isDownloading: false,
    currentFileRecord: this.props.fileRecord,
    files: [this.props.fileRecord, ...this.props.files.filter((file: any) => file?.file?.id !== this.props.fileRecord?.file?.id)]
  };

  handleDownload = async (fileRecord: any, clientId: number) => {
    try {

      if (!fileRecord) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isDownloading: true }, () => resolve(null) ));

      await API.download(`client/${clientId}/media/${fileRecord?.file?.file_path}`, fileRecord?.file?.title);

    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isDownloading: false
      });
    }
  };

  renderFile = (clientId: number, file: any) => {

    if (!!file.is_image) {
      return (
        <Base64Image
          client_id={ clientId }
          image_id={ file?.file_path }
          width={ '90%' }
          classes={ 'bd' }
        />
      );
    }

    switch (file.extension) {
      case 'pdf':
        return <FilePdfOutlined style={{ fontSize: 50 }} />;
      case 'xlsx':
        return <FileExcelOutlined style={{ fontSize: 50 }} />;
      default:
        return <FileUnknownOutlined style={{ fontSize: 50 }} />;
    }
  };

  renderFiles = (clientId: number, files: any) => {
    return files.map((fileEntity: any) => {
      return (
        <div className="d-f jc-c ai-c fxd-c mH-350 h-100p" key={ fileEntity?.file?.id }>
          <div className="d-f jc-c mh-100p pB-60">
            { this.renderFile(clientId, fileEntity?.file) }
          </div>
        </div>
      );
    });
  };

  render = () => {
    const { clientId, onClose } = this.props;
    const { isDownloading, currentFileRecord, files } = this.state;

    if (!_.has(currentFileRecord, 'file')) return <></>;

    return (
      <CoverModal
        middleContent={
          <>
            <span>{ currentFileRecord?.file?.title }</span>
            <span className="mL-20">
              { isDownloading ? (
                <LoadingOutlined />
              ) : (
                <DownloadOutlined
                  disabled={ isDownloading }
                  onClick={ () => this.handleDownload(currentFileRecord, clientId) }
                />
              ) }
            </span>
          </>
        }
        style={{ maxWidth: 1200, width: '80%', height: '80%' }}
        onClose={ onClose }
      >
        <Carousel
          arrows
          dots
          onChange={ (currentSlide: number) => this.setState({
            currentFileRecord: files[currentSlide]
          }) }
        >
          { this.renderFiles(clientId, files) }
        </Carousel>
      </CoverModal>
    );
  };
};

export default PreviewModal;
