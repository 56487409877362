// Libs
import React, { memo } from 'react';
import classNames from 'classnames';
import { Handle, NodeProps, Position } from 'react-flow-renderer';

// Interfaces
import { WorkflowStage } from 'components/workflow';

// Utils
import { generateHandleId } from 'utils/workflow';

// Styles
import './WorkflowMesh.scss';

interface CustomNodeData {
  stage: WorkflowStage;
  onDoubleClickByNode?: (stage: WorkflowStage) => void;
};

interface Props extends NodeProps<CustomNodeData> {};

function RenderCustomNode({ data: { stage, onDoubleClickByNode } }: Props) {
  return (
    <div
      onDoubleClick={ () => onDoubleClickByNode?.(stage) }
      className={ classNames('CustomNode', {
        'CustomNode--is-current': !!stage.current,
      }) }
    >
      <div
        className={ classNames('CustomNode-title', {
          'CustomNode-title--is-current': !!stage.current,
        }) }
      >
        { stage.title }
      </div>
      <Handle
        type="target"
        position={ Position.Top }
        id={ generateHandleId(1, 'target') }
        className={ 'CustomNode-target l-20p' }
      />
      <Handle
        type="source"
        position={ Position.Top }
        id={ generateHandleId(1, 'source') }
        className={ 'CustomNode-source l-80p' }
      />
      <Handle
        type="target"
        position={ Position.Right }
        id={ generateHandleId(2, 'target') }
        className={ 'CustomNode-target t-20p' }
      />
      <Handle
        type="source"
        position={ Position.Right }
        id={ generateHandleId(2, 'source') }
        className={ 'CustomNode-source t-80p' }
      />
      <Handle
        type="target"
        position={ Position.Bottom }
        id={ generateHandleId(3, 'target') }
        className={ 'CustomNode-target l-80p' }
      />
      <Handle
        type="source"
        position={ Position.Bottom }
        id={ generateHandleId(3, 'source') }
        className={ 'CustomNode-source l-20p' }
      />
      <Handle
        type="target"
        position={ Position.Left }
        id={ generateHandleId(4, 'target') }
        className={ 'CustomNode-target t-80p' }
      />
      <Handle
        type="source"
        position={ Position.Left }
        id={ generateHandleId(4, 'source') }
        className={ 'CustomNode-source t-20p' }
      />
    </div>
  );
};

export const WorkflowCustomNode = memo(RenderCustomNode);
