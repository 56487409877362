// Libs
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import FormWrapper from 'components/form/form-wrapper';
import { hasPermission } from 'components/restriction';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Utils
import { instanceOfFormErrorResponse, instanceOfRecordFormEntity } from 'utils/guards';

// Services
import Notification from 'services/notification';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Api
import { Api } from 'services/api';

interface Props {
  id: number;
  type: string;
  client_id?: number;
  permissions?: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  record: RecordFormEntity | null;
  categoryType: string;
  isLoading: boolean;
};

const API: Api = new Api();

class CategoryRecord extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    record: null,
    categoryType: '',
    isLoading: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.handleFetch();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.type !== prevProps.type) {
      this.handleFetch();
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleFetch = async () => {
    const { client_id, id, type, setBreadcrumbs, setBreadcrumbsLoading } = this.props;

    try {

      setBreadcrumbsLoading(true);
      await new Promise((resolve) => this.setState({ isLoading: true }, () => {
        resolve(null);
      }));

      const record = await API.get(`client/${client_id}/admin/content-manager/categories/${type}/${id}`);

      if (_.has(record, 'breadcrumbs')) {
        setBreadcrumbs(record.breadcrumbs, false);
      }

      this.mounted && this.setState({
        record: record,
        categoryType: this.getCategoryType(record.type)
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  getCategoryType(categoryType: string): string {
    return _.startCase(_.toLower(categoryType)).split('_').join(' ');
  }

  saveForm = async (record: RecordFormEntity, callback: (response: any) => void) => {
    try {
      const API: Api = new Api();
      const { client_id, type, id } = this.props;

      if (instanceOfRecordFormEntity(record)) {
        const updatedForm = await API.put(`client/${client_id}/admin/content-manager/categories/${type}/${id}`, { data: JSON.stringify(record) });

        if (instanceOfFormErrorResponse(updatedForm)) {
          callback(updatedForm);
        } else {
          const type = this.getCategoryType(updatedForm.type);
          this.setState({
            record: updatedForm,
            categoryType: type
          }, () => {
            Notification('success', 'The category item has been updated', `${type} Saved`);
            callback(updatedForm);
          });
        }

      }
    } catch (error) {
      Notification('error', '', 'Unknown Error saving record');
    }
  };

  onArchive = async (record_id: number, callback: () => void) => {
    const { client_id, type, id } = this.props;
    try {
      const record = await API.put(`client/${client_id}/admin/content-manager/categories/${type}/${id}/archive`, {
        archiving_tag: 'ARCHIVED'
      });
      this.setState({
        record: record
      }, () => {
        Notification('success', '', 'Category successfully archived');
        callback();
      });
    } catch (error) {
      Notification('error', '', 'Failed to archive category');
      console.error('Error: ', error);
    }
  };

  onHide = async (record_id: number, callback: () => void) => {
    const { client_id, type, id } = this.props;
    try {
      const record = await API.put(`client/${client_id}/admin/content-manager/categories/${type}/${id}/archive`, {
        archiving_tag: 'HIDDEN'
      });
      this.setState({
        record: record
      }, () => {
        Notification('success', '', 'Category successfully hidden');
        callback();
      });
    } catch (error) {
      Notification('error', '', 'Failed to hide category');
      console.error('Error: ', error);
    }
  };

  render = () => {
    const { permissions, client_id } = this.props;
    const { record, isLoading } = this.state;

    return (
      <BlockingSpinner isLoading={ isLoading }>
        { record && client_id &&
          <FormWrapper
            clientId={ client_id }
            canCreate={ !!record?.form_config?.can_edit }
            canEdit={ record?.form_config?.can_edit }
            canView={ hasPermission(permissions, 'access_admin_content_manager') }
            canArchive={ !!record?.form_config?.can_archive }
            isLockedTitle={ !!record?.form_config?.is_locked_title }
            record={ record }
            onSave={ this.saveForm }
            onArchive={ this.onArchive }
            onHide={ this.onHide }
          />
        }
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryRecord);
