// Libs
import * as React from 'react';
import _ from 'lodash';

// Components
import CoverModal from 'components/cover-modal';
import { Button, Transfer, Table, Input, Select } from 'antd';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import { KpiMetric } from 'components/kpi-library/KpiLibrary.interfaces';

const { Search } = Input;
const API: Api = new Api();

interface Props {
  clientId?: number;
  onAttach(metrics: KpiMetric[]): void;
  onClose(): void;
};

interface State {
  metrics: KpiMetric[];
  keywordFilter: string | null;
  prioritySetFilter: number | null;
  metricTypeFilter: number | null;
  selectedMetrics: string[];
  targetKeys: string[];
  isFetching: boolean;
};

class AttachMetricModal extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    metrics: [],
    keywordFilter: null,
    prioritySetFilter: null,
    metricTypeFilter: null,
    selectedMetrics: [],
    targetKeys: [],
    isFetching: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.fetchMetrics();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchMetrics = async () => {
    const { clientId } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const response = await API.get(`client/${clientId}/kpi-library/metric`);

      this.mounted && this.setState({
        metrics: response.kpi_metrics.map((metric: KpiMetric) => {
          return {
            ...metric,
            key: `${metric.id}`
          };
        })
      });
    } catch (error) {
      Notification('error', 'Failed to fetch core metric', 'Failed');
    } finally {
      this.mounted && this.setState({ isFetching: false });
    }
  };

  handleSave = (metrics: KpiMetric[], targetKeys: string[]) => {
    this.props.onAttach(metrics.filter((metric: KpiMetric) => targetKeys.includes(String(metric.id))));
  };

  filter = (dataSource: KpiMetric[]) => {
    return dataSource.filter((metric: KpiMetric) => {
      if (!!this.state.keywordFilter) {
        return metric.title.toLowerCase().includes(this.state.keywordFilter.toLowerCase());
      }
      return true;
    })
    .filter((metric: KpiMetric) => {
      if (!!this.state.prioritySetFilter) {
        return metric.kpi_priority_set_id === this.state.prioritySetFilter;
      }
      return true;
    })
    .filter((metric: KpiMetric) => {
      if (!!this.state.metricTypeFilter) {
        return metric.kpi_metric_type_id === this.state.metricTypeFilter;
      }
      return true;
    });
  };

  renderTransfer = (dataSource: any, targetKeys: string[], prioritySetFilter: number | null, metricTypeFilter: number | null, keywordFilter: string | null) => {
    const filteredDataSource = this.filter(dataSource);
    const availableSourceItems = filteredDataSource.length - filteredDataSource.filter((_metric: KpiMetric) => targetKeys.includes(_metric.key)).length;
    return (
      <Transfer
        className='MetricModalTransfer h-100p'
        listStyle={{ width: '100%', height: '100%' }}
        titles={ [`Available (${availableSourceItems})`, `Selected (${targetKeys.length})`] }
        dataSource={ dataSource }
        targetKeys={ targetKeys }
        onChange={ (newTargetKeys: string[]) => {
          this.setState({
            targetKeys: newTargetKeys
          });
        } }
        render={ (metric: KpiMetric) => {
          return metric.title;
        } }
      >
        {
          ( {
              direction,
              onItemSelectAll,
              onItemSelect,
              selectedKeys: listSelectedKeys,
              disabled: listDisabled,
          } ) => {

            const rowSelection = {
              getCheckboxProps: (item: any) => ({ disabled: listDisabled || item.disabled }),
              onSelectAll(selected: any, selectedRows: KpiMetric[]) {

                const treeSelectedKeys = selectedRows
                  .filter((metric: KpiMetric) => {
                    if (!!keywordFilter && direction === 'left') {
                      return metric.title.toLowerCase().includes(keywordFilter.toLowerCase());
                    }
                    return true;
                  })
                  .filter((metric: KpiMetric) => {
                    if (!!prioritySetFilter) {
                      return metric.kpi_priority_set_id === prioritySetFilter;
                    }
                    return true;
                  })
                  .filter((metric: KpiMetric) => {
                    if (!!metricTypeFilter) {
                      return metric.kpi_metric_type_id === metricTypeFilter;
                    }
                    return true;
                  })
                  .map((metric: KpiMetric) => metric.key);

                const diffKeys = selected ? _.difference(treeSelectedKeys, listSelectedKeys) : _.difference(listSelectedKeys, treeSelectedKeys);

                onItemSelectAll(diffKeys, selected);
              },
              onSelect(item: any, selected: any) {
                onItemSelect(item.key, selected);
              },
              selectedRowKeys: listSelectedKeys,
            };

            const displayType: string = direction === 'left' ? 'source' : 'target';
            const columns = direction === 'left' ? [
              {
                dataIndex: 'title',
                title: 'Title',
                ellipsis: true,
                render: (__: any, metric: KpiMetric) => <span className="us-n">{ metric.title }</span>
              }
            ] : [
              {
                dataIndex: 'title',
                title: 'Title',
                ellipsis: true,
                render: (__: any, metric: KpiMetric) => <span className="us-n">{ metric.title }</span>
              },
            ];

            switch (displayType) {
              case 'source':
                return (
                  <Table
                    sticky
                    size="small"
                    className='h-100p'
                    pagination={ false }
                    dataSource={ filteredDataSource.filter((metric: KpiMetric) => !targetKeys.includes(metric.key)) }
                    columns={ columns }
                    rowSelection={ rowSelection }
                    onRow={ ({ key }, idx) => ({
                      index: idx,
                      onClick: () => {
                        onItemSelect(key, !listSelectedKeys.includes(key));
                      }
                    }) }
                    scroll={{
                      x: columns.length * 200,
                      y: 400,
                    }}
                  />
                );
              case 'target':
                return (
                  <Table
                    sticky
                    size="small"
                    className='h-100p'
                    pagination={ false }
                    columns={ columns }
                    rowSelection={ rowSelection }
                    dataSource={ dataSource.filter((metric: KpiMetric) => targetKeys.includes(metric.key)) }
                    onRow={ ({ key }, idx) => ({
                      index: idx,
                      onClick: () => {
                        onItemSelect(key, !listSelectedKeys.includes(key));
                      }
                    }) }
                    scroll={{
                      x: columns.length * 200,
                      y: 400,
                    }}
                  />
                );
            }
          }
        }
      </Transfer>
    );
  };

  render = () => {
    const { onClose } = this.props;
    const { metrics, targetKeys, prioritySetFilter, metricTypeFilter, keywordFilter, isFetching } = this.state;
    const uniquePrioritySetMetrics = _.uniqBy(metrics, 'kpi_priority_set_id');
    const uniqueMetricTypeMetrics = _.uniqBy(metrics, 'kpi_metric_type_id');

    return (
      <CoverModal
        isLoading={ isFetching }
        showCloseIcon={ false }
        style={{ width: '90vw', maxWidth: 1300, height: '80vh', maxHeight: 800 }}
        middleContent={ 'Attach Metric' }
        onClose={ onClose }
      >
        <div className='d-f fxd-c pX-50 pT-30 h-100p'>
          <div className="d-f">
            <div>
              <Search
                style={{ width: 200 }}
                allowClear
                placeholder="Search"
                disabled={ _.isEmpty(metrics) }
                onSearch={ (value: string) => this.setState({
                  keywordFilter: !!value ? value : null,
                  selectedMetrics: [],
                }) }
              />
            </div>
            <div className='mL-10'>
              <Select
                allowClear
                style={{ width: 170 }}
                disabled={ _.isEmpty(uniquePrioritySetMetrics) }
                placeholder={ 'Filter by Priority Set' }
                dropdownMatchSelectWidth={ false }
                onClear={ () => {
                  this.setState({
                    prioritySetFilter: null,
                  });
                } }
                onChange={ (priority_set_id: number) => {
                  this.setState({
                    prioritySetFilter: priority_set_id,
                  });
                } }
              >
                { uniquePrioritySetMetrics
                  .map((metric: KpiMetric, index: number) => (
                    <Select.Option key={ index } value={ metric.kpi_priority_set_id }>{ metric.kpi_priority_set_title }</Select.Option>
                  ))
                }
              </Select>
            </div>
            <div className='mL-10'>
              <Select
                allowClear
                style={{ width: 170 }}
                disabled={ _.isEmpty(uniqueMetricTypeMetrics) }
                placeholder={ 'Filter by Metric Type' }
                dropdownMatchSelectWidth={ false }
                onClear={ () => {
                  this.setState({
                    metricTypeFilter: null,
                  });
                } }
                onChange={ (metric_type_id: number) => {
                  this.setState({
                    metricTypeFilter: metric_type_id,
                  });
                } }
              >
                { uniqueMetricTypeMetrics
                  .map((metric: KpiMetric, index: number) => (
                    <Select.Option key={ index } value={ metric.kpi_metric_type_id }>{ metric.kpi_metric_type_title }</Select.Option>
                  ))
                }
              </Select>
            </div>
          </div>
          <div className="d-f fxd-c fxg-1 mT-10">
            { this.renderTransfer(metrics, targetKeys, prioritySetFilter, metricTypeFilter, keywordFilter) }
          </div>
          <div className='mB-40 pT-20 ta-r'>
            <Button
              onClick={ () => this.props.onClose() }
            >
              Cancel
            </Button>
            <Button
              type='primary'
              className="mL-5"
              disabled={ _.isEmpty(targetKeys) }
              onClick={ () => this.handleSave(metrics, targetKeys) }
            >
              Attach
            </Button>
          </div>
        </div>
      </CoverModal>
    );
  };
};

export default AttachMetricModal;
