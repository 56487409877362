// Libs
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import BasicList, { Action } from 'components/basic-list';
import Dropdown from 'components/dropdown';
import { Select, Tooltip } from 'antd';
import RearrangeModal from 'components/rearrange-modal';
import CreateRecordView from 'views/common/CreateRecordView';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();
const { Option } = Select;

interface Region {
  id: number;
  title: string;
  country_ids: number[];
  isDisabled: boolean;
  children: Region[];
  path: string;
};

interface Country {
  id: number;
  title: string;
  code: string;
  dial_code: string;
};

interface Props {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  originalRegions: Region[];
  manipulatedRegions: Region[];
  availableCountries: Country[];
  showRearrangeModal: boolean;
  showCreateModal: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isUpdating: boolean;
  isCreating: boolean;
};

class Regions extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    originalRegions: [],
    manipulatedRegions: [],
    availableCountries: [],
    showRearrangeModal: false,
    showCreateModal: false,
    isLoading: false,
    isSaving: false,
    isUpdating: false,
    isCreating: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.fetch();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.match.params.type === 'regions' && prevProps.match.params.type !== this.props.match.params.type) {
      this.fetch();
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  fetch = async () => {
    const { client_id, setBreadcrumbs } = this.props;

    try {
      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'General Settings', path: '/admin/general-settings' },
        { title: 'Regions', path: null },
      ], false);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const regions = await API.get(`client/${client_id}/admin/regions`);
      const availableCountries = await API.get(`client/${client_id}/available_countries`, {
        limited: true
      });

      this.mounted && this.setState({
        originalRegions: _.cloneDeep(regions),
        manipulatedRegions: _.cloneDeep(regions),
        availableCountries: availableCountries,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  getData = (regions: Region[]) => {
    return regions.map((region: Region) => {
      const appendChildrenKeys = (entity: any, isDisabled: boolean) => {
        return _.has(entity, 'children') && entity.children
          .map((childEntity: any) => {

            if (!_.isEmpty(entity.country_ids) ) {
              childEntity.country_ids = entity.country_ids;
            }

            return {
              ...childEntity,
              'key': childEntity.id,
              'label': childEntity.title,
              'isDisabled': isDisabled,
              'children': !_.isEmpty(childEntity.children) ? appendChildrenKeys(childEntity, isDisabled || !_.isEmpty(childEntity.country_ids)) : null,
            };
          });
      };
      return {
        ...region,
        'key': region.id,
        'label': region.title,
        'isDisabled': false,
        'children': appendChildrenKeys(region, !_.isEmpty(region.country_ids)),
      };
    });
  };

  getFlatten = (data: any) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({ ..._value });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  setRegionCountries = (regions: Region[], region_id: number, country_ids: number[]) => {
    return regions.map((region: Region) => {
      const appendChildrenKeys = (children: any) => {
        return children
          .map((childEntity: any) => {

            if (childEntity.id === region_id ) {
              childEntity.country_ids = country_ids;
            } else {
              childEntity.country_ids = [];
            }

            return {
              ...childEntity,
              'children': !_.isEmpty(childEntity.children) ? appendChildrenKeys(childEntity.children) : null,
            };
          });
      };

      if (region.id === region_id ) {
        region.country_ids = country_ids;
      } else {
        region.country_ids = [];
      }

      return {
        ...region,
        'isDisabled': false,
        'children': appendChildrenKeys(region.children),
      };
    });
  };

  renderRearrangeModal = (categories: any) => {
    const { client_id } = this.props;
    const {isUpdating } = this.state;

    return (
      <RearrangeModal
        isNestable
        treeData={ categories }
        isLoading={ isUpdating }
        onOk={ async (treeData: any) => {

          const getFlattenedCategories = (parent_id: number, categories: any) => {
            const _categories = Array.isArray(categories) ? categories : [categories];
            return _categories.reduce((acc, category, index) => {
              acc.push({
                id: category.id,
                order: index + 1,
                parent_id: parent_id,
              });
              if (category.children) {
                acc = acc.concat(getFlattenedCategories(category.id, category.children));
                delete category.children;
              }
              return acc;
            }, []);
          };

          const manipulatedTreeData = getFlattenedCategories(0, treeData);

          try {
            if (!_.isEmpty(manipulatedTreeData)) {

              await new Promise((resolve) => this.setState({ isUpdating: true }, () => resolve(null)));
              await API.put(`client/${client_id}/admin/content-manager/categories/region`, { data: manipulatedTreeData });
              const regions = await API.get(`client/${client_id}/admin/regions`);

              this.mounted && this.setState({
                originalRegions: _.cloneDeep(regions),
                manipulatedRegions: _.cloneDeep(regions),
              });
            } else {
              throw new Error();
            }
          } catch (error) {
            Notification('error', '', 'Failed to update record');
          } finally {
            this.mounted && this.setState({
              isUpdating: false,
              showRearrangeModal: false,
            }, () => {
              Notification('success', '', 'Changed Order');
            });
          }
        } }
        onClose={ () => this.setState({ showRearrangeModal: false }) }
      />
    );
  };

  render = () => {
    const { client_id } = this.props;
    const {
      originalRegions,
      manipulatedRegions,
      availableCountries,
      showRearrangeModal,
      showCreateModal,
      isLoading,
      isSaving,
      isCreating,
    } = this.state;

    if (isLoading || !manipulatedRegions) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const actions: Action[] = [
      {
        node: (
          <Dropdown
            actions={ [
              {
                node: 'Save',
                onClick: async () => {
                  try {

                    await new Promise((resolve) => this.setState({ isSaving: true }, () => resolve(null)));
                    const regions = await API.put(`client/${client_id}/admin/regions`, {
                      data: {
                        'regions': this.getFlatten(manipulatedRegions)
                      }
                    });

                    this.mounted && this.setState({
                      originalRegions: _.cloneDeep(regions),
                      manipulatedRegions: _.cloneDeep(regions),
                    }, () => {
                      Notification('success', 'Saved settings.', '');
                    });

                  } catch (error) {
                    console.error('Error: ', error);
                    Notification('success', 'Failed to save settings.', '');
                  } finally {
                    this.mounted && this.setState({
                      isSaving: false
                    });
                  }
                },
                isLoading: isSaving,
                disabled: _.isEqual(originalRegions, manipulatedRegions)
              },
              {
                node: 'Create Region',
                onClick: () => this.setState({ showCreateModal: true }),
                isLoading: isCreating,
                disabled: isCreating,
              },
              {
                node: 'Change Order',
                onClick: () => this.setState({ showRearrangeModal: true })
              }
            ] }
          />
        ),
      },
    ];

    return (
      <>
        <BasicList
          rawData
          hideFilter
          defaultExpandAllRows
          rightActions={ actions }
          columns={ [
            {
              key: 'title',
              title: 'Title',
              filterable: false,
              sorter: false,
              ellipsis: false,
              render: (__: any, region: Region) => {
                return (
                  <>
                    { _.has(region, 'path') && region.path ? (
                        <RouterLink className='primaryColor' to={ region.path }>
                          { region.title }
                        </RouterLink>
                      ) : (
                        region.title
                      )
                    }
                  </>
                );
              }
            },
            {
              key: 'country',
              dataIndex: 'county',
              title: 'Country',
              filterable: false,
              sorter: false,
              render: (__: any, region: Region) => {
                return (
                  <Tooltip placement={ 'top' } title={ _.has(region, 'isDisabled') && !!region.isDisabled ? 'Inherited' : '' }>
                    <Select
                      showSearch
                      allowClear
                      mode={ 'multiple' }
                      disabled={ _.has(region, 'isDisabled') && !!region.isDisabled }
                      dropdownMatchSelectWidth={ false }
                      maxTagCount={ 3 }
                      style={{ width: 350 }}
                      className={ 'Select-Field' }
                      placeholder="-"
                      value={ _.has(region, 'country_ids') && !_.isEmpty(region.country_ids) ? region.country_ids : undefined }
                      filterOption={ (input: any, option: any) => {
                        const country = availableCountries.find((country: any) => country.code === option.value);
                        return _.toLower(country?.title).indexOf(_.toLower(input)) >= 0;
                      } }
                      onChange={ (country_ids: number[]) => {
                        this.setState({
                          manipulatedRegions: _.cloneDeep(this.setRegionCountries(_.cloneDeep(this.state.manipulatedRegions), region.id, country_ids))
                        });
                      } }
                    >
                      { availableCountries.map((country: Country, index: number) => (
                        <Option key={ index } value={ country.id }>{ `${country.title} (${country.code})` }</Option>
                      )) }
                    </Select>
                  </Tooltip>
                );
              }
            },
          ] }
          items={ this.getData(_.cloneDeep(manipulatedRegions)) }
        />
        { showRearrangeModal && this.renderRearrangeModal(manipulatedRegions) }
        { showCreateModal &&
          <CreateRecordView
            type={ 'region' }
            entity={ 'admin/content-manager/categories' }
            onReady={ () => this.setState({ isCreating: false }) }
            onClose={ () => this.setState({ showCreateModal: false }) }
            onCreated={ async () => {
              const regions = await API.get(`client/${client_id}/admin/regions`);
              this.setState({
                originalRegions: _.cloneDeep(regions),
                manipulatedRegions: _.cloneDeep(regions),
                showCreateModal: false,
              });
            } }
          />
        }
      </>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(Regions), 'access_admin_general_settings'));
