// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Select, Input } from 'antd';

// Services
import { convertMeasurement } from 'components/form/field/space-definition/SpaceDefinition';

// Interfaces
import {
  FormField,
  FormValues,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  AreaUnit,
} from 'components/form/form-wrapper';

interface Props {
  numberFormat: any;
  field: FormField;
  originalState: FormValues;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  onRefreshForm(field_id: string): void;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: any): void;
  setFieldErrorMessage(id: string, message?: any): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  state: {
    unit: string,
    value: string | null,
  };
};

class AreaField extends Component<Props, State> {

  state: State = {
    state: this.props.state
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { field } = this.props;

    if (!_.isEqual(prevProps.state, this.props.state)) {
      // Set state downwards
      this.setState({
        state: this.props.state
      });
    } else if (!_.isEqual(prevState.state, this.state.state)) {
      // Roll state upwards
      this.handleChange(this.state.state);
    }

    if (!_.isEqual(prevProps.field, this.props.field)) {
      this.validate(this.props.state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { field, originalState } = this.props;
    const columnKeys = Object.keys(field.columns);

    columnKeys.forEach((columnKey: string) => {
      this.generateModifiedState(originalState[columnKey], state[columnKey], columnKey, shouldClear);
      this.generateErrorState(state[columnKey], columnKey, shouldClear);
    });
  };

  handleChange = _.debounce((state: any) => {
    this.props.onChange(this.props.field, [state], this.props.config);
  }, 500);

  handleUnitChange = (unit: string) => {
    const { field } = this.props;
    const { state } = this.state;

    const newState = _.cloneDeep(state);
    const units = !!field.units ? field.units : [];
    const defaultUnit = units.find((_unit: AreaUnit) => !!_unit?.default);
    const newUnit = units.find((_unit: AreaUnit) => _unit.unit === unit);
    const sourceUnit = state?.unit ? units.find((_unit: AreaUnit) => _unit.unit === state.unit) : defaultUnit;

    if (newUnit) {
      newState.unit = newUnit.unit;
    }

    if (sourceUnit && newUnit) {
      newState.value = convertMeasurement(parseFloat(state?.value || `0`),parseFloat(`${newUnit.conversion}`), parseFloat(`${sourceUnit.conversion}`));
    }

    this.setState({
      state: newState
    });
  };

  generateModifiedState = (pastValue: string | number, newValue: string | number, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {

      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { setFieldErrorMessage, field, config, validate } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const errors = validate(field, columnKey, value);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  render = () => {
    const { field, config, border, fieldErrorMessages, fieldModifiedMessages, isDisabled } = this.props;
    const { state } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const value = state.value ? Number(state.value) : undefined;
    const decimal = _.has(field, 'config.decimal') ? field.config.decimal : 0;
    const units: AreaUnit[] = field?.units || [];
    const defaultUnit = units.find((_unit: AreaUnit) => !!_unit?.default);
    const currentUnit = state.unit || defaultUnit?.unit;
    const currentConversion = currentUnit && units.find((_unit: AreaUnit) => _unit.unit === currentUnit);

    const areaValueErrors = _.has(fieldErrorMessages, `${id}_${cardinality}_value`) ? fieldErrorMessages[`${id}_${cardinality}_value`].errors : [];
    const areaUnitErrors = _.has(fieldErrorMessages, `${id}_${cardinality}_unit`) ? fieldErrorMessages[`${id}_${cardinality}_unit`].errors : [];
    const areaValueModified = _.has(fieldModifiedMessages, `${id}_${cardinality}_value`);
    const areaUnitModified = _.has(fieldModifiedMessages, `${id}_${cardinality}_unit`);

    let defaultConversion: string | boolean = false;

    if (defaultUnit && currentUnit && currentConversion && defaultUnit.unit !== currentUnit) {
      defaultConversion = convertMeasurement(parseFloat(state?.value || `0`), parseFloat(`${defaultUnit.conversion}`), parseFloat(`${currentConversion.conversion}`));
    }

    if (!!isDisabled) {
      return (
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          border={ border }
          refreshOnChange={ !!field.config.refresh_on_change }
          versionChanged={ !!field.config.version_changed }
          description={ !!field.description && field.description }
        >
          <NumberFormat
            value={ state.value }
            suffix={ currentUnit ? ` ${_.toUpper(currentUnit)}` : undefined }
            displayType={ 'text' }
          />
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        required={ field.config.required }
        errors={ !_.isEmpty(areaUnitErrors) ? areaUnitErrors : !_.isEmpty(areaValueErrors) ? areaValueErrors : [] }
        border={ border }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <div className="w-100p d-f fxd-r">
          <div className="d-f fxd-c">
            <Select
              style={{ maxWidth: 100 }}
              className={ classNames('Select-Field', {
                'Select-Field--has-warning border-warning': areaUnitModified,
              }) }
              onChange={ (unit: string) => this.handleUnitChange(unit) }
              placeholder={ field.columns.unit.label }
              value={ currentUnit }
              disabled={ isDisabled }
            >
              { units.map((unit: AreaUnit) => {
                return (
                  <Select.Option
                    key={ `${field.id}-list-area-option-${unit.unit}` }
                    value={ unit.unit }
                  >
                    { unit.unit }
                  </Select.Option>
                );
              }) }
            </Select>
            { defaultConversion !== false &&
              <div style={{ paddingLeft: 12 }} className="w-100p fsz-xs color-grey-700">
                { `${_.toUpper(defaultUnit?.symbol)}` }
              </div>
            }
          </div>
          <div className="d-f fxd-c fxg-1 mL-5">
            <NumberFormat
              customInput={ Input }
              autoComplete="newpassword" // hack
              className={ classNames('Field Field-Number pR-20 ta-r', {
                'Field--has-warning border-warning': _.isEmpty(areaValueErrors) && areaValueModified,
              }) }
              fixedDecimalScale={ !!decimal }
              decimalScale={ decimal }
              required={ field.config.required }
              disabled={ isDisabled }
              value={ value }
              onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                const newState = _.cloneDeep(state);

                if (!newState?.unit) {
                  newState.unit = defaultUnit?.unit || 'SQM';
                }

                newState.value = event.target.value !== '' ? event.target.value : null;

                this.setState({
                  state: newState
                });
              } }
            />
            { defaultConversion !== false &&
              <div style={{ paddingRight: 21 }} className="w-100p ta-r fsz-xs color-grey-700 fw-300">
                <NumberFormat
                  value={ defaultConversion }
                  displayType={ 'text' }
                />
              </div>
            }
          </div>
        </div>
      </FieldWrapper>
    );
  };
};

export default AreaField;
