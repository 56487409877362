// Libs
import React, { BaseSyntheticEvent, useState } from 'react';
import classnames from 'classnames';

// Components
import { Input, Tooltip } from 'antd';

// Icons
import Icon from '@ant-design/icons';
import { ReactComponent as WarningIcon } from 'assets/svg/warning-triangle.svg';

// Styles
import './Input.scss';

interface Props {
  isPlain?: boolean;
  isDanger?: boolean;
  isDisabled?: boolean;
  shouldAutoGrow?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  type?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  classNames?: string;
  autoGrow?: (modifiedValue: string) => number;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
};

export default function InputComponent({
  isPlain,
  isDanger,
  isDisabled,
  shouldAutoGrow,
  autoFocus = false,
  required,
  type,
  value,
  defaultValue,
  placeholder,
  style,
  prefix,
  suffix,
  onChange,
  onBlur,
  autoGrow,
  classNames = '',
}: Props) {

  function autoGrowInput(value: string) {
    if (!!autoGrow) {
      return autoGrow(value);
    }

    return (value.length + 1) * 8;
  }

  const [ stateValue, setStateValue ] = useState<string>(defaultValue || '');
  const [ autoWidth, setAutoWidth ] = useState<number>(autoGrowInput(stateValue));

  const styleBundle = {
    ...style,
    ...shouldAutoGrow || !!autoGrow ? { width: autoWidth } : {},
    minWidth: '100px',
  };

  return (
    <>
      <Input
        className={ classnames(`Input ${classNames}`, {
          'border-danger': isDanger,
          'is-plain': isPlain,
        }) }
        type={ type }
        autoFocus={ autoFocus }
        style={ styleBundle }
        disabled={ isDisabled || false }
        prefix={ prefix }
        suffix={ suffix }
        onChange={ (event: BaseSyntheticEvent) => {
          const value = event.target.value;
          setStateValue(value);
          setAutoWidth(autoGrowInput(value));
          onChange && onChange(value);
        } }
        onBlur={ onBlur }
        value={ value }
        defaultValue={ defaultValue }
        placeholder={ placeholder }
      />
      { required && !defaultValue &&
        <span className="mL-10 va-s">
          <Tooltip
            overlayClassName={ 'Field-Error text-white' }
            placement="right"
            title={ 'Title Required' }
          >
            <Icon className="text-inverse-danger fsz-lg" component={ WarningIcon } />
          </Tooltip>
        </span>
      }
    </>
  );
};