// Libs
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import Badge, { BadgeType } from 'components/badge';
import { Select, Tooltip, DatePicker } from 'antd';

// Icons
import { LoadingOutlined } from '@ant-design/icons';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';
import { getUserSetting } from 'services/settings';

// Interfaces
import {
  AssetCategory,
  AssetType,
  Modified,
  Field,
  FieldValues,
  MaintenancePlan,
  ICriticality,
  ICriticalityOption,
  ISpecialCase,
} from './AssetTypeSelection.interfaces';
import {
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
  FormValues,
} from 'components/form/form-wrapper';
import { RecordFormEntity } from 'types/entities';

// Utils
import { orderListByKey } from 'utils/formSetup';

const API: Api = new Api();

interface IMaintenanceStatus {
  label: string;
  status: string;
  tooltip: string;
};

interface Props {
  clientId: number;
  record: RecordFormEntity;
  config: FormFieldConfig;
  field: Field;
  originalValues: FormValues[];
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: Field,
    values: Record<string, string | number>[] | [],
    config: FormFieldConfig,
    column?: string,
  ): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  border?: boolean;
  isDisabled?: boolean;
};

interface State {
  maintenanceStatus: IMaintenanceStatus | null;
  isFetching: boolean;
};

class AssetTypeSelection extends React.Component<Props, State> {

  state: State = {
    maintenanceStatus: null,
    isFetching: false,
  };

  componentDidMount = () => {
    this.validate(this.props.field.values as FieldValues, this.props.originalValues as FieldValues);
    this.fetchMaintenanceStatus();
  };

  componentDidUpdate = async (prevProps: Props) => {
    if (!_.isEqual(prevProps.field, this.props.field)) {
      this.validate(this.props.field.values as FieldValues, this.props.originalValues as FieldValues);
    }

    if (
      (prevProps.field.values[0]?.asset_type_id !== this.props.field.values[0]?.asset_type_id) ||
      (prevProps.field.values[0]?.criticality_id !== this.props.field.values[0]?.criticality_id) ||
      (prevProps.field.values[0]?.special_case !== this.props.field.values[0]?.special_case)
    ) {
      this.fetchMaintenanceStatus();
    }
  };

  fetchMaintenanceStatus = async () => {
    const { clientId, record, field } = this.props;

    if (!field.values[0]?.asset_type_id || !field.values[0]?.criticality_id) {
      return this.setState({
        maintenanceStatus: null
      });
    }

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const maintenanceStatus = await API.get(`client/${clientId}/field/${field.id}/asset_type/check_maintenance_status`, {
        entity_id: record.id,
        type: record.type,
        bundle: record.bundle,
        asset_type_id: field.values[0]?.asset_type_id || null,
        criticality_id: field.values[0]?.criticality_id || null,
        special_case: field.values[0]?.special_case || null,
      });

      this.setState({
        maintenanceStatus: maintenanceStatus
      });

    } catch (error) {
      Notification('error', 'Failed to fetch Maintenance Status', 'Failed');
    } finally {
      this.setState({ isFetching: false });
    }
  };

  validate = (values: FieldValues, originalValues: FieldValues): void => {
    const { field, config, setFieldModifiedMessage, setFieldErrorMessage } = this.props;

    const fieldKey = this.getFieldKey();
    const modified = this.getModified(values, originalValues);
    const errors = this.getErrors(values);

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const errorMessage = _.isEmpty(errors) ? undefined : { ...generalMessageInfo, errors: errors };
    const modifiedMessage = _.isEmpty(modified) ? undefined : { ...generalMessageInfo, modified: modified };

    setFieldErrorMessage(fieldKey, errorMessage);
    setFieldModifiedMessage(fieldKey, modifiedMessage);
  };

  getErrors = (values: FieldValues): Modified => {
    const errors: Modified = {};

    if (!values[0]?.maintenance_plan_id) {
      errors.maintenancePlan = true;
    }

    if (!values[0]?.asset_category_id) {
      errors.assetCategory = true;
    }

    if (!values[0]?.asset_type_id) {
      errors.assetType = true;
    }

    if (!values[0]?.criticality_id) {
      errors.criticality = true;
    }

    return errors;
  };

  getModified = (values: FieldValues, originalValues: FieldValues): Modified => {

    const modified: Modified = {};

    if (!_.isEqual(values, originalValues)) {

      // Maintenance Plan
      if (!_.isEqual(values[0]?.maintenance_plan_id, originalValues[0]?.maintenance_plan_id)) {
        modified.maintenancePlan = true;
      }

      // Asset Category
      if (!_.isEqual(values[0]?.asset_category_id, originalValues[0]?.asset_category_id)) {
        modified.assetCategory = true;
      }

      // Asset Type
      if (!_.isEqual(values[0]?.asset_type_id, originalValues[0]?.asset_type_id)) {
        modified.assetType = true;
      }

      // Criticality
      if (!_.isEqual(values[0]?.criticality_id, originalValues[0]?.criticality_id)) {
        modified.criticality = true;
      }

      // Special Case
      if (!_.isEqual(values[0]?.special_case, originalValues[0]?.special_case)) {
        modified.specialCase = true;
      }

      // Start Date
      if (!_.isEqual(values[0]?.maintenance_start_date, originalValues[0]?.maintenance_start_date)) {
        modified.maintenanceStartDate = true;
      }
    }

    return modified;
  };

  getFieldKey = (): string => {
    return `${this.props.field.id}_${this.props.config.fieldIndex || 0}_${this.props.field.type}`;
  };

  hasError = (fieldErrorMessages: any, fieldKey: string, key: keyof Modified): boolean => {
    return (
      _.has(fieldErrorMessages, [fieldKey, 'errors', key]) &&
      fieldErrorMessages[fieldKey]['errors'][key]
    );
  };

  isModified = (fieldModifiedMessages: any, fieldKey: string, key: keyof Modified): boolean => {
    return (
      _.has(fieldModifiedMessages, [fieldKey, 'modified', key]) &&
      fieldModifiedMessages[fieldKey]['modified'][key]
    );
  };

  renderMaintenancePlanSelect = (values: any): JSX.Element => {
    const { field, config, onChange, fieldModifiedMessages, fieldErrorMessages, isDisabled } = this.props;

    const fieldKey = this.getFieldKey();
    const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, 'maintenancePlan');
    const hasError: boolean = this.hasError(fieldErrorMessages, fieldKey, 'maintenancePlan');

    return (
      <Select
        className={ classNames('Select-Field', {
          'Select-Field--has-error border-danger': !!hasError,
          'Select-Field--has-warning border-warning': isModified,
        }) }
        showSearch
        allowClear
        disabled={ !!isDisabled }
        dropdownMatchSelectWidth={ false }
        placeholder={ 'Please Select' }
        value={ values?.maintenance_plan_id || undefined }
        filterOption={(input: string, option: any) => {
          return !!field.maintenance_plans?.find((record) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
        } }
        onChange={ (maintenance_plan_id: number) => {
          const newValues: any = {
            maintenance_plan_id: maintenance_plan_id,
            asset_category_id: undefined,
            asset_type_id: undefined,
            criticality_id: undefined,
            special_case_id: undefined,
          };
          onChange(field, [newValues], config);
        } }
      >
        { orderListByKey(field?.maintenance_plans || [], 'title')?.map((plan: MaintenancePlan) => (
          <Select.Option key={ plan.id } value={ plan.id }>{ plan.title }</Select.Option>
        )) }
      </Select>
    );
  };

  renderAssetCategorySelect = (values: any): JSX.Element => {
    const { field, config, onChange, fieldModifiedMessages, fieldErrorMessages, isDisabled } = this.props;

    const fieldKey = this.getFieldKey();
    const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, 'assetCategory');
    const hasError: boolean = this.hasError(fieldErrorMessages, fieldKey, 'assetCategory');
    const categories = orderListByKey(field?.asset_categories || [], 'title').filter((category: AssetCategory) => category.maintenance_plan_id === values?.maintenance_plan_id);

    return (
      <Select
        className={ classNames('Select-Field', {
          'Select-Field--has-error border-danger': !!hasError,
          'Select-Field--has-warning border-warning': isModified,
        }) }
        showSearch
        allowClear
        disabled={ !!isDisabled || !values?.maintenance_plan_id }
        dropdownMatchSelectWidth={ false }
        placeholder={ 'Please Select' }
        value={ values?.asset_category_id || undefined }
        filterOption={(input: string, option: any) => {
          return !!categories?.find((record) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
        } }
        onChange={ (asset_category_id: number) => {
          const newValues: any = _.cloneDeep(values);
          newValues.asset_category_id = asset_category_id;
          newValues.asset_type_id = undefined;
          newValues.criticality_id = undefined;
          newValues.special_case_id = undefined;
          onChange(field, [newValues], config);
        } }
      >
        { categories.map((category: AssetCategory) => (
          <Select.Option key={ category.id } value={ category.id }>{ category.title }</Select.Option>
        )) }
      </Select>
    );
  };

  renderAssetTypeSelect = (values: any): JSX.Element => {
    const { field, config, onChange, fieldModifiedMessages, fieldErrorMessages, isDisabled } = this.props;

    const fieldKey = this.getFieldKey();
    const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, 'assetType');
    const hasError: boolean = this.hasError(fieldErrorMessages, fieldKey, 'assetType');
    const types = orderListByKey(field?.asset_types || [], 'title').filter((type: AssetType) => type.category_id === values?.asset_category_id);

    return (
      <Select
        className={ classNames('Select-Field', {
          'Select-Field--has-error border-danger': !!hasError,
          'Select-Field--has-warning border-warning': isModified,
        }) }
        showSearch
        allowClear
        disabled={ !!isDisabled || !values?.maintenance_plan_id || !values?.asset_category_id }
        dropdownMatchSelectWidth={ false }
        placeholder={ 'Please Select' }
        value={ values?.asset_type_id || undefined }
        filterOption={(input: string, option: any) => {
          return !!types?.find((record) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()) );
        } }
        onChange={ (asset_type_id: number) => {
          const newValues: any = _.cloneDeep(values);
          newValues.asset_type_id = asset_type_id;
          newValues.criticality_id = undefined;
          newValues.special_case_id = undefined;
          onChange(field, [newValues], config);
        } }
      >
        { types?.map((type: AssetType) => (
          <Select.Option key={ type.id } value={ type.id }>{ type.title }</Select.Option>
        )) }
      </Select>
    );
  };

  renderCriticalitySelect = (values: any): JSX.Element => {
    const { field, config, onChange, fieldModifiedMessages, fieldErrorMessages, isDisabled } = this.props;

    const fieldKey = this.getFieldKey();
    const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, 'criticality');
    const hasError: boolean = this.hasError(fieldErrorMessages, fieldKey, 'criticality');
    const selectedAssetType = field?.asset_types && !_.isEmpty(field?.asset_types) && field?.asset_types.find((type: AssetType) => type.id === values?.asset_type_id);
    const criticalityList = selectedAssetType && !!selectedAssetType?.criticality_list_id && !!field?.criticality_lists && field.criticality_lists.find((criticality_list: ICriticality) => criticality_list.id === selectedAssetType?.criticality_list_id);

    return (
      <Select
        className={ classNames('Select-Field', {
          'Select-Field--has-error border-danger': !!hasError,
          'Select-Field--has-warning border-warning': isModified,
        }) }
        allowClear
        disabled={ !!isDisabled || !values?.maintenance_plan_id || !values?.asset_category_id || !values?.asset_type_id }
        dropdownMatchSelectWidth={ false }
        placeholder={ 'Please Select' }
        value={ values?.criticality_id || undefined }
        onChange={ (criticality_id: number) => {
          const newValues: any = _.cloneDeep(values);
          newValues.criticality_id = criticality_id;
          newValues.special_case_id = undefined;
          onChange(field, [newValues], config);
        } }
      >
        { criticalityList && !_.isEmpty(criticalityList.options) && criticalityList.options.map((option: ICriticalityOption) => (
          <Select.Option key={ option.id } value={ option.id }>{ option.option }</Select.Option>
        )) }
      </Select>
    );
  };

  renderSpecialCases = (values: any): JSX.Element => {
    const { field, config, onChange, fieldModifiedMessages, fieldErrorMessages, isDisabled } = this.props;

    const fieldKey = this.getFieldKey();
    const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, 'specialCase');
    const hasError: boolean = this.hasError(fieldErrorMessages, fieldKey, 'specialCase');
    const options: ISpecialCase[] = !!field?.special_cases && !_.isEmpty(field.special_cases) ? field.special_cases : [];

    return (
      <Select
        className={ classNames('Select-Field', {
          'Select-Field--has-error border-danger': !!hasError,
          'Select-Field--has-warning border-warning': isModified,
        }) }
        showSearch
        allowClear
        disabled={ !!isDisabled || !values?.maintenance_plan_id || !values?.asset_category_id || !values?.asset_type_id || !values?.criticality_id }
        dropdownMatchSelectWidth={ false }
        placeholder={ 'Please Select' }
        value={ values?.special_case || undefined }
        onChange={ (special_case: string) => {
          const newValues: any = _.cloneDeep(values);
          newValues.special_case = special_case;
          onChange(field, [newValues], config);
        } }
      >
        { options.map((option: ISpecialCase) => (
          <Select.Option key={ option.id } value={ option.id }>{ option.title }</Select.Option>
        )) }
      </Select>
    );
  };

  renderMaintenancePlanStatus = (maintenanceStatus: IMaintenanceStatus | null, isFetching: boolean) => {

    if (isFetching) {
      return <LoadingOutlined className="fsz-def text-ant-default" />;
    }

    if (!!maintenanceStatus?.status) {

      let badge = <>-</>;
      let type = BadgeType.Default;

      switch (_.toLower(maintenanceStatus.status)) {
        case 'active':
          type = BadgeType.Success;
        break;
        case 'inactive':
          type = BadgeType.Disabled;
        break;
      }

      badge = <Badge type={ type } text={ maintenanceStatus.label } />;

      if (!!maintenanceStatus.tooltip) {
        return (
          <div className='d-f'>
            <Tooltip
              placement="top"
              title={ maintenanceStatus.tooltip }
            >
              <span>{ badge }</span>
            </Tooltip>
          </div>
        );
      }

      return (
        <div className='d-if'>{ badge }</div>
      );
    }

    return '-';
  };

  renderMaintenancePlanStartDate = (values: any): JSX.Element => {
    const { field, config, onChange, fieldModifiedMessages, fieldErrorMessages, isDisabled } = this.props;

    const fieldKey = this.getFieldKey();
    const isModified: boolean = this.isModified(fieldModifiedMessages, fieldKey, 'maintenanceStartDate');
    const hasError: boolean = this.hasError(fieldErrorMessages, fieldKey, 'maintenanceStartDate');

    return (
      <DatePicker
        className={ classNames('Field', {
          'Field--has-error border-danger': hasError,
          'Field--has-warning border-warning': isModified && !hasError,
        }) }
        disabled={ isDisabled }
        placeholder={ 'Select Start Date' }
        format={ getUserSetting('date_format') }
        value={ values?.maintenance_start_date ? moment(values.maintenance_start_date) : undefined }
        onChange={ (date: moment.Moment | null) => {
          const newValues: any = _.cloneDeep(values);

          if (!!date) {
            newValues.maintenance_start_date = date.format('YYYY-MM-DD HH:mm:ss');
          } else {
            delete newValues.maintenance_start_date;
          }

          onChange(field, [newValues], config);
        } }
      />
    );
  };

  render = () => {
    const { field, border, fieldErrorMessages, config } = this.props;

    const fieldKey = this.getFieldKey();
    const errors = _.has(fieldErrorMessages, fieldKey) ? ['Cannot be empty'] : [];
    const isRequired = field.config.required;
    const assetCategory: AssetCategory | undefined = field?.asset_categories?.find((category: AssetCategory) => category.id === field?.values[0]?.asset_category_id);
    const assetType: AssetType | undefined = field?.asset_types?.find((type: AssetType) => type.id === field?.values[0]?.asset_type_id);

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ 12 }
        label={ field.label }
        description={ field.description }
        required={ isRequired }
        border={ border }
        errors={ errors }
        hideErrorInfo
      >
        <div className="Form-Grid">
          <FieldWrapper
            col={ 4 }
            label={ <p className="fsz-xs fw-500">Maintenance Plan</p> }
            description={ `Please select a 'Maintenance Plan' using the dropdown list.` }
            required={ isRequired }
            border
          >
            { this.renderMaintenancePlanSelect((field.values as FieldValues)[0]) }
          </FieldWrapper>
          <FieldWrapper
            col={ 4 }
            label={ <p className="fsz-xs fw-500">Asset Category</p> }
            description={ `Please select an 'Asset Category' using the dropdown list.` }
            required={ isRequired }
            border
          >
            { this.renderAssetCategorySelect((field.values as FieldValues)[0]) }
          </FieldWrapper>
          <FieldWrapper
            col={ 4 }
            label={ <p className="fsz-xs fw-500">Asset Type</p> }
            description={ `Please select an 'Asset Type' using the dropdown list.` }
            required={ isRequired }
            border
          >
            { this.renderAssetTypeSelect((field.values as FieldValues)[0]) }
          </FieldWrapper>
          <FieldWrapper
            col={ 4 }
            label={ <p className="fsz-xs fw-500">Criticality</p> }
            description={ `Please select a 'Criticality' using the dropdown list.` }
            required={ isRequired }
            border
          >
            { this.renderCriticalitySelect((field.values as FieldValues)[0]) }
          </FieldWrapper>
          <FieldWrapper
            col={ 4 }
            label={ <p className="fsz-xs fw-500">Special Cases</p> }
            description={ `Please select a 'Case' using the dropdown list.` }
            border
          >
            { this.renderSpecialCases((field.values as FieldValues)[0]) }
          </FieldWrapper>
          <FieldWrapper
            col={ 4 }
            label={ <p className="fsz-xs fw-500">Maintenance Start Date</p> }
            description={ `Please select a 'Maintenance Start Date' using the date picker.` }
            border
          >
            { this.renderMaintenancePlanStartDate((field.values as FieldValues)[0]) }
          </FieldWrapper>
          <FieldWrapper
            col={ 12 }
            label={ <p className="fsz-xs fw-500">Additional Information</p> }
            border
          >
            <div className="Form-Grid">
              <FieldWrapper
                col={ 4 }
                label={ <p className="fsz-xs fw-400">Maintenance Plan Status</p> }
                border
              >
                { this.renderMaintenancePlanStatus(this.state.maintenanceStatus, this.state.isFetching) }
              </FieldWrapper>
              <FieldWrapper
                col={ 4 }
                label={ <p className="fsz-xs fw-400">Asset Category Tag</p> }
                border
              >
                { !!assetCategory ? (
                  <span>{ assetCategory.tag || '-' }</span>
                ) : (
                  <span>-</span>
                ) }
              </FieldWrapper>
              <FieldWrapper
                col={ 4 }
                label={ <p className="fsz-xs fw-400">Asset Type Tag</p> }
                border
              >
                { !!assetType ? (
                  <span>{ assetType.tag || '-' }</span>
                ) : (
                  <span>-</span>
                ) }
              </FieldWrapper>
            </div>
          </FieldWrapper>
        </div>
      </FieldWrapper>
    );
  };
}

export default AssetTypeSelection;
