export interface Field {
  id: string;
  class: string;
  config: any;
  created_at: string;
  description: string | null;
  label: string;
  protected: boolean;
  type: string;
  entity_bundle: string;
  entity_type: string;
  group_id?: number;
  extensions?: string[];
  max_file_size?: number;
  advanced_config?: any;
};

export interface Entity {
  bundle: string;
  label: string;
  label_plural: string;
  workflow_id: number;
  count: number;
  type: string;
  tool_id: number | null;
  workflow_assignment: WorkflowAssignment[];
};

export interface EntityField {
  entity_bundle: string;
  entity_type: string;
  field_id: string;
  type: string;
  config: any;
  protected: boolean;
};

export interface FormElement {
  id: number;
  type: ElementTypes;
  config: any;
  order: number;
  hide_on_create: boolean;
  dependencies: Array<any>;
  data: any;
};

export interface FormGroup {
  id: number;
  title: string;
  elements: FormElement[];
  config: any;
  order: number;
};

export interface FormTab {
  id: number;
  title: string;
  groups: FormGroup[];
  config: any;
  order: number;
};

export interface Relation {
  id: string;
  class: string;
  config: { target: Array<{ bundle: string; type: string }>, can_create: boolean };
  label: string;
  description: string;
  created_at: string;
  protected: boolean;
};

export interface Role {
  id: number;
  title: string;
  description: string;
  type: string;
  status: string;
  reference: string;
  users: Array<any>;
  permissions: Array<any>;
  updated_at: string;
  created_at: string;
};

export interface EntityRole {
  id: number;
  order: number;
  role_id: number;
  description: string;
  entity_bundle: string;
  entity_type: string;
  reference: string;
  title: string;
  type: EntityRoleTypes;
  required: boolean
};

export interface WorkflowAssignment {
  id: number;
  entity_bundle: string;
  entity_type: string;
  field_id: string;
  category_type: string;
  category_id: number[];
  workflow_id: number;
  order: number;
};

export interface WorkflowAssignmentCategory {
  field_id: string;
  field_label: string;
  category_type: string;
  categories: Category[];
};

export interface Category {
  id: number;
  relation_id: number;
  title: string;
  display_title: string;
  reference: string;
  parent_id: number;
  path: string;
  order: number;
  lft?: number;
  rgt?: number;
  user_id: number;
  created_at: string;
  updated_at: string;
  archived_at?: string;
  deleted_at?: string;
};

export interface DetailsTabErrors {
  label?: boolean;
  workflow_id?: boolean;
  workflow_assignment?: { [key: number]: string[] };
};

export interface DetailsTabModified extends DetailsTabErrors {};

export enum ElementTypes {
  Field = 'field',
  Divider = 'divider',
  View = 'view',
  Relation = 'relation',
  Foreign = 'foreign',
  Empty = 'empty',
};

export enum EntityRoleTypes {
  DIRECT = 'DIRECT',
  INHERIT = 'INHERIT',
};

export enum RoleType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
};

export interface RecordKey {
  key: string
};