// Libs
import React, { Component } from 'react';
import moment from 'moment';
import _ from "lodash";

// Components
import { Button, Form, Steps, Upload, Spin, Result, Typography } from 'antd';
import CoverModal from 'components/cover-modal';

// Interfaces
import { IProcess, IProcessResponse, IProcessResponseStatus, IProcessStatus } from 'components/import/Import.interface';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Icons
import { InboxOutlined, LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";

interface Props {
  clientId: number;
  title: string;
  filePrefix: string;
  verifyEndpoint: string;
  importEndpoint: string;
  downloadEndpoint: string;
  onClose: (shouldReload: boolean) => void;
};

interface State {
  currentStep: number;
  error: any;
  fileList: any[];
  isUploading: boolean;
  isImporting: boolean;
  isDownloading: boolean;
  process: IProcess | null;
};

const API: Api = new Api();

export class ProcessImport extends Component<Props, State> {

  intervalID: NodeJS.Timeout | null = null;
  mounted: boolean = false;

  state: State = {
    fileList: [],
    error: null,
    isUploading: false,
    isImporting: false,
    isDownloading: false,
    currentStep: 1,
    process: null,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
    this.clearInterval();
  };

  componentDidUpdate = () => {
    if (!this.intervalID && this.state.process?.status === IProcessStatus.IN_PROGRESS) {
      this.intervalID = setInterval(this.poll, 5000);
    }
  };

  clearInterval = () => {
    if (this.intervalID) {
      clearInterval(this.intervalID);
      this.intervalID = null;
    }
  };

  poll = async () => {
    const { clientId } = this.props;
    const { process, currentStep } = this.state;
    try {
      const response: IProcess = await API.get(`client/${clientId}/process/${process?.id}`);

      this.setState({
        process: response,
        currentStep: response?.response?.status === IProcessResponseStatus.IMPORTED ? 3 : currentStep
      }, () => {
        if (response.status !== 'IN_PROGRESS') {
          this.clearInterval();
        }
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  handleClose = () => {
    this.props.onClose(!!(this.state.process && this.state.process.response?.status === IProcessResponseStatus.IMPORTED));
  };

  handleUpload = async () => {
    const { clientId, verifyEndpoint } = this.props;
    const { fileList } = this.state;

    try {

      await new Promise((resolve) => this.setState({ isUploading: true }, () => resolve(null) ));

      const form = new FormData();
      form.append('file', fileList[0].originFileObj);

      const response: IProcess = await API.post(`client/${clientId}/${verifyEndpoint}`, form);

      this.mounted && this.setState({
        process: response,
        currentStep: 2,
      });
    } catch (error: any) {
      this.mounted && this.setState({
        error: _.has(error, 'data.message') ? error.data.message : error
      }, () => {
        Notification('error', `Failed to import data`);
      });
    } finally {
      this.mounted && this.setState({
        isUploading: false,
        fileList: [],
      });
    }
  };

  handleImport = async (processResponse: IProcessResponse) => {
    const { clientId, importEndpoint } = this.props;

    try {

      await new Promise((resolve) => this.setState({ isImporting: true }, () => resolve(null) ));

      const response: IProcess = await API.put(`client/${clientId}/${importEndpoint}`, {
        export_id: processResponse.export_id
      });

      this.mounted && this.setState({
        process: response
      });
    } catch (error: any) {
      this.mounted && this.setState({
        error: _.has(error, 'data.message') ? error.data.message : error
      }, () => {
        Notification('error', `Failed to import data`);
      });
    } finally {
      this.mounted && this.setState({
        isImporting: false,
      });
    }
  };

  handleDownload = async (processResponse: IProcessResponse) => {
    const { clientId, downloadEndpoint, filePrefix } = this.props;
    const filename = filePrefix || 'pacs_pricing_errors';

    try {

      await new Promise((resolve) => this.setState({ isDownloading: true }, () => resolve(null) ));

      await API.download(`client/${clientId}/${downloadEndpoint}`, `${filename}__${moment().unix()}.xlsx`, {
        export_id: processResponse.export_id
      });

      Notification('success', `File generated`);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isDownloading: false
      });
    }
  };

  renderUpload = () => {
    const { fileList, isUploading } = this.state;
    return (
      <div className="pT-50" style={{ minHeight: 200 }}>
        <Form
          className="pL-100 pR-100"
          name="upload"
          layout="vertical"
          autoComplete="off"
          onFinish={ () => {
            this.handleUpload();
          } }
        >
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={ (event: any) => {
              return event && event.fileList;
            } }
          >
            { _.isEmpty(fileList) ? (
              <Upload.Dragger
                beforeUpload={ () => false }
                maxCount={ 1 }
                multiple={ false }
                onChange={ ({ fileList }) => this.setState({ fileList }) }
                showUploadList={ false }
              >
                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                <p className="ant-upload-text">Click or drag file to this area to start</p>
              </Upload.Dragger>
            ) : (
              <Upload
                listType="picture"
                defaultFileList={ [...fileList] }
                onRemove={ () => {
                  this.setState({
                    fileList: []
                  });
                } }
              />
            ) }
          </Form.Item>
          <Form.Item className="ta-r">
            <Button
              type="primary"
              loading={ isUploading }
              disabled={ _.isEmpty(fileList) || isUploading }
              htmlType="submit"
            >
              Upload
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  renderValidate = (process: IProcess) => {
    const actions: JSX.Element[] = [];

    let title = undefined;

    switch (process?.response?.status) {
      case IProcessResponseStatus.ERROR:
      case IProcessResponseStatus.ERROR_ALREADY_IMPORTED:
        title = 'Failed';
        actions.push(
          <Button
            key={ 'restart'}
            type="default"
            onClick={ () => this.setState({
              fileList: [],
              process: null,
              currentStep: 1,
            }) }
          >
            Upload New File
          </Button>
        );
        if (process?.response?.status === IProcessResponseStatus.ERROR) {
          actions.push(
            <Button
              key={ 'download' }
              type="primary"
              loading={ this.state.isDownloading }
              disabled={ this.state.isDownloading }
              onClick={ () => process.response && this.handleDownload(process.response) }
            >
              Download Errors
            </Button>
          );
        }
      break;
      case IProcessResponseStatus.VERIFIED:
        title = 'Validation Successful';
        actions.push(
          <Button
            key={ 'import' }
            type="primary"
            loading={ this.state.isImporting }
            disabled={ this.state.isImporting }
            onClick={ () => process.response && this.handleImport(process.response) }
          >
            Import Data
          </Button>
        );
      break;
    }

    return this.renderResult(
      process?.response?.status && [IProcessResponseStatus.ERROR, IProcessResponseStatus.ERROR_ALREADY_IMPORTED].includes(process?.response?.status) ? 'error' : 'success',
      title,
      actions,
      !!process?.response?.messages && [IProcessResponseStatus.ERROR, IProcessResponseStatus.ERROR_ALREADY_IMPORTED].includes(process?.response?.status) ? process.response.messages : []
    );
  };

  renderImported = () => {
    return this.renderResult(
      'success',
      'Import Successful',
      [
        <Button
            key={ 'close' }
            type="primary"
            onClick={ () => this.handleClose() }
          >
            Close
          </Button>
      ],
      []
    );
  };

  renderResult = (
    status: 'error' | 'success',
    title: string | undefined,
    actions: JSX.Element[],
    errors: string[]
  ) => {
    return (
      <Result
        className='d-f jc-c ai-c fxd-c h-100p'
        status={ status }
        title={ title }
        extra={ actions }
      >
        { !_.isEmpty(errors) && (
          <div>
            <Typography.Paragraph>
              <Typography.Text strong className='fsz-md'>
                The file has the following errors:
              </Typography.Text>
            </Typography.Paragraph>
            { errors.map((error: string, index: number) => (
              <Typography.Paragraph key={ index }>
                <CloseCircleOutlined style={{ color: 'red' }} /> { error }
              </Typography.Paragraph>
            )) }
          </div>
        ) }
      </Result>
    );
  };

  renderStep = (step: number, process: IProcess | null) => {

    if (process?.status === 'IN_PROGRESS') {
      return (
        <div className="d-f jc-c ai-c fxd-c h-100p">
          <Spin indicator={ <LoadingOutlined style={{ fontSize: 50 }} spin /> } />
          <span className="mT-40">{ 'Processing...' }</span>
        </div>
      );
    }

    switch (step) {
      case 1:
        return this.renderUpload();
      case 2:
        return process ? this.renderValidate(process) : <></>;
      case 3:
        return this.renderImported();
    }
  };

  renderImport = () => {
    const { currentStep, process } = this.state;
    let validationStepStatus: 'wait' | 'process' | 'finish' | 'error' | undefined = undefined;
    let importedStepStatus: 'wait' | 'process' | 'finish' | 'error' | undefined = undefined;

    switch (process?.response?.status) {
      case IProcessResponseStatus.ERROR:
        validationStepStatus = 'error';
        break;
      case IProcessResponseStatus.IMPORTED:
        importedStepStatus = 'finish';
        break;
    }

    return (
      <div className='Layout-box h-100p d-f fxd-c'>
        <div className="mT-30 mX-30">
          <Steps current={ currentStep - 1 }>
            <Steps.Step
              title="Upload"
            />
            <Steps.Step
              title="Validate"
              status={ validationStepStatus }
            />
            <Steps.Step
              title="Import"
              status={ importedStepStatus }
            />
          </Steps>
        </div>
        <div className="fx-1">
          { this.renderStep(currentStep, process) }
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <CoverModal
        style={ { width: '80%', height: '80%', maxHeight: 1000 } }
        middleContent={ this.props.title }
        onClose={ () => this.handleClose() }
        smallHeader
      >
        { this.renderImport() }
      </CoverModal>
    );
  };
};
