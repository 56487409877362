// Libs
import React, { Component } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
  FormValues,
} from 'components/form/form-wrapper';
import FieldWrapper from 'components/form/field/field-wrapper';

// Interfaces
import { Country } from './Country.interface';

// Styles
import './Country.scss';

interface Props {
  field: FormField;
  originalState: FormValues[];
  state: FormValues[];
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

const { Option } = Select;

class CountryField extends Component<Props> {

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { originalState } = this.props;

    this.generateModifiedState(originalState, state, 'country', shouldClear);
    this.generateErrorState(state, 'country', shouldClear);
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { setFieldErrorMessage, field, config, validate } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;
    const errors = validate(field, columnKey, value);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  canSelectMultiple = () => {
    return this.props.field.config.cardinality !== 1;
  };

  render = () => {
    const { field, config, border, fieldErrorMessages, state, fieldModifiedMessages, onChange, isDisabled } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_country`;
    const isLocked = isDisabled;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const countries = field.countries ? field.countries : [];
    const values = !_.isEmpty(state) ? state.map((value: any) => value.country_code) : [];

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ errors }
        required={ field.config.required }
        border={ border }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <div className="w-100p">
          <div className="Country-Field-Select w-100p">
            <Select
              showSearch
              mode={ this.canSelectMultiple() ? 'multiple' : undefined }
              allowClear={ !field.config.required }
              className={classNames('Select-Field', {
                'border-warning Select-Field--has-warning': isModified && _.isEmpty(errors),
              })}
              onChange={ (options: any) => {
                if (this.canSelectMultiple()) {
                  const newList = options.map((option: any) => {
                    const _country = countries.find((country: Country) => country.code === option);
                    return {
                      country: _country?.title,
                      country_code: _country?.code,
                    };
                  });
                  onChange(field, newList, config);
                } else {
                  if (!options) {
                    onChange(field, [], config);
                  } else {
                    const _country = countries.find((country: Country) => country.code === options);
                    onChange(field, [{
                      country: _country?.title,
                      country_code: _country?.code,
                    }], config);
                  }
                }
              } }
              placeholder={ field.columns.country_code.label }
              defaultValue={ values }
              disabled={ isLocked }
              // @ts-ignore
              autoComplete="none"
            >
              { countries.map((country: Country) => {
                return (
                  <Option
                    key={ `${field.id}-list-country_code-option-${country.id}` }
                    value={ country.code }
                  >
                    { country.title }
                  </Option>
                );
              }) }
            </Select>
          </div>
        </div>
      </FieldWrapper>
    );
  };
};

export default CountryField;
