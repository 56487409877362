// Libs
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import _ from 'lodash';

// Interfaces
import { Config } from './DragSortingList.interfaces';
import { moveRowFunc, moveRowInEmptyTableFunc } from './DragSortingList';

interface Props {
  index: number;
  moveRow: moveRowFunc;
  moveRowInEmptyTable: moveRowInEmptyTableFunc;
  config: Config;
  style: React.CSSProperties;
  className: string;
  restProps: any;
};

export default function DraggableBodyRow({
  index,
  moveRow,
  moveRowInEmptyTable,
  className,
  style,
  config,
  ...restProps
}: Props) {
  const { type } = config;
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex, config: itemConfig } = monitor.getItem() || {};

      if (dragIndex === index && _.isEqual(config, itemConfig)) {
        return {};
      }

      const getClassName = () => {
        if (_.isUndefined(index)) return ` move-in-empty-table`;
        if (_.isEqual(config, itemConfig) && dragIndex < index) {
          return ` drop-over-downward`;
        }
        return ` drop-over-upward`;
      };

      return {
        isOver: monitor.isOver(),
        dropClassName: getClassName(),
      };
    },
    drop: (item: any) => {
      if (_.isUndefined(index) && !_.isEqual(item.config, config)) {
        return item.moveRowInEmptyTable?.(item.index, item.config, config);
      }
      moveRow?.(item.index, index, item.config, config);
    },
  });

  const [, drag] = useDrag({
    item: { index, type, config: config, moveRowInEmptyTable },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      // @ts-ignore
      ref={ ref }
      className={ `${className}${isOver ? dropClassName : ''}` }
      style={{ cursor: 'move', ...style }}
      { ...restProps }
    />
  );
};
