// Libs
import React, { Component } from 'react';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import PreviewModal from 'components/preview-modal';
import Base64Image from 'components/base64-image';
import Upload from 'components/upload';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormValues,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage
} from "components/form/form-wrapper";

// Services
import { Api } from 'services/api';

// Styles
import './File.scss';

interface Props {
  clientId: number;
  field: FormField;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
    callback?: () => void,
  ): void;
  originalState: FormValues[];
  state: FormValues[];
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage: (id: string, message?: FormFieldInfoBoxModifiedMessage) => void;
  setFieldErrorMessage: (id: string, message?: FormFieldInfoBoxErrorMessage) => void;
  validate: (field: FormField, column: string, value: string | number) => string[];
  border?: boolean;
};

interface State {
  preview_id: number | null;
  isDownloading: boolean;
  isModified: boolean;
};

const API: Api = new Api();

class FileField extends Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    preview_id: null,
    isDownloading: false,
    isModified: false,
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.mounted = true;

    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);

    this.mounted = false;
  };

  validate = (state: any, shouldClear = false) => {
    const { originalState } = this.props;

    this.generateModifiedState(originalState, state, 'file', shouldClear);
    this.generateErrorState(state, 'file', shouldClear);
  };

  generateModifiedState = (rawPastValue: any, rawNewValue: any, columnKey: string = 'file', shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = _.has(field, 'config.cardinality') ? field.config.cardinality : 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(rawPastValue, rawNewValue) && !shouldClear) {

      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (values: FormValues[], columnKey: string = 'file', shouldClear = false) => {
    const { field, config, setFieldErrorMessage } = this.props;

    const id = field.id;
    const cardinality = _.has(field, 'config.cardinality') ? field.config.cardinality : 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const errors: string[] = [];

    if (field.config.required && _.isEmpty(values)) {
      errors.push('Cannot be empty');
    }

    if (!_.isEmpty(errors) && !shouldClear) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: errors
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  handleChange = (file: any, callback?: () => void) => {
    const { field } = this.props;
    this.props.onChange(_.cloneDeep(field), field.values.concat([file]), this.props.config, undefined, () => {
      callback && callback();
    });
  };

  handleRemove = (file: any) => {
    const { field, onChange, config } = this.props;
    onChange(field, field.values.filter((record: any) => record.file_id !== file.uid), config);
  };

  renderOverview = (clientId: number, files: any) => {
    return (
      <div className="fxg-1 mT-10 mB-10 ta-c bd p-10" style={{ minHeight: 200 }}>
        <Base64Image client_id={ clientId } image_id={ files[0].file_path } styles={{ maxHeight: '400px' }} />
      </div>
    );
  };

  renderPreview = (file_id: number) => {
    const { field, clientId } = this.props;
    const files: any[] = _.has(field, 'values') ? field.values : [];
    const fileRecord: any = files.find((record: any) => record.file_id === file_id);
    return (
      <PreviewModal
        clientId={ clientId }
        fileRecord={ fileRecord }
        files={ files }
        onClose={ () => {
          this.setState({
            preview_id: null
          });
        }}
      />
    );
  };

  render = () => {
    const {
      field,
      config,
      border,
      clientId,
      isDisabled = false,
      fieldErrorMessages,
      fieldModifiedMessages,
    } = this.props;
    const { preview_id } = this.state;

    const id = field.id;
    const cardinality = _.has(field, 'config.cardinality') ? field.config.cardinality : 0;
    const files: any[] = _.has(field, 'values') ? field.values : [];

    const label = cardinality === 0 ? field.label : `${field.label} (${files.length}/${cardinality})`;
    const allowedExtensions = _.has(field, 'config.allowed_extensions') && !_.isEmpty(field.config.allowed_extensions) && field.config.allowed_extensions.join(', .');

    const key = `${id}_${cardinality}_file`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const showPreview = cardinality === 1 && (_.has(field, 'config.show_preview') && !!field.config.show_preview) && (!_.isEmpty(files) && _.has(files[0], 'extension') && ['png', 'jpeg', 'jpg'].includes(files[0].extension));
    const maxFileSize = ((field?.config?.max_file_size || 0) / 1024 / 1024) || 50;
    const allowMultiple = cardinality !== 1;
    const isLocked = cardinality === 0 ? false : files.length >= cardinality;

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ label }
        required={ field.config.required }
        border={ border }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
        isModified={ isModified }
        errors={ errors }
      >
        <div className="d-f fxd-c">
          <div>
            <Upload
              clientId={ clientId }
              classes={ 'File-Field' }
              uploadEndpoint={ `client/${clientId}/field/file/${field.id}/upload` }
              allowedExtensions={ allowedExtensions }
              onRemove={ (file: any) => this.handleRemove(file) }
              onPreview={ (file: any) => this.setState({
                preview_id: file.uid
              }) }
              isDisabled={ isDisabled }
              isLocked={ isLocked }
              multiple={ allowMultiple }
              files={ files }
              onUpload={ (fileRecord: any, callback?: () => void) => {
                this.handleChange(fileRecord, callback);
              } }
              maxFileSize={ maxFileSize }
              showAllowedTypes={ !files.length }
            />
            { showPreview && this.renderOverview(clientId, files) }
            { preview_id && this.renderPreview(preview_id) }
          </div>
        </div>
      </FieldWrapper>
    );
  };

};

export default FileField;
