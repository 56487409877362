// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Select } from "antd";

// Interfaces
import {
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
  FormValues
} from 'components/form/form-wrapper';
import { TemplateField } from 'components/form/field/cost-template/CostTemplate.interfaces';

interface Props {
  originalValues: TemplateField['values']
  field: TemplateField;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: Record<string, FormFieldInfoBoxErrorMessage>;
  fieldModifiedMessages: Record<string, FormFieldInfoBoxModifiedMessage>;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  onChange(
    field: TemplateField,
    value: any,
    config: FormFieldConfig,
    column?: string
  ): void;
  onRefreshForm(field_id: string): void;
  border?: boolean;
};

const { Option } = Select;

class CostTemplate extends Component<Props> {
  componentDidMount = () => {
    const { field, originalValues } = this.props;

    if (!this.props.isDisabled) {
      this.validate(field.values, originalValues);
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, originalValues } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(field.values, originalValues);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  validate = (values: FormValues[], originalValues: FormValues[]) => {
    this.generateModifiedState(values, originalValues);
    this.generateErrorState(values);
  };

  generateModifiedState = (values: FormValues[], originalValues: FormValues[]) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const type = field.type;
    const key = `${type}_${id}`;

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    if (!_.isEqual(values, originalValues)) {
      const modifiedMessage = { ...generalMessageInfo, modified: {} };
      setFieldModifiedMessage(key, modifiedMessage);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (values: FormValues[]) => {
    const { field, config, setFieldErrorMessage } = this.props;

    const id = field.id;
    const type = field.type;
    const key = `${type}_${id}`;

    let errors: string[] = [];

    if (!!field.config.required && _.isEmpty(values)) {
      errors.push('Cannot be empty');
    }

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const errorMessage = _.isEmpty(errors) ? undefined : { ...generalMessageInfo, errors: errors };

    if (!_.isEmpty(errors)) {
      setFieldErrorMessage(key, errorMessage);
    } else {
      setFieldErrorMessage(key);
    }
  };

  getValues: any = (field: TemplateField) => {
    return !_.isEmpty(field.values) ? field.values.map((value: any) => value.cost_template_id ) : [];
  };

  getTemplates: any = (field: TemplateField) => {
    return !_.isEmpty(field.templates) ? field.templates : [];
  };

  handleChange = (field: TemplateField, value: FormValues[]|null, config: FormFieldConfig) => {
    if (_.isEmpty(value)) {
      this.props.onChange(
        field,
        null,
        config
      );
    } else {
      this.props.onChange(
        field,
        value,
        config
      );
    }
  };

  render = () => {
    const { field, config, fieldErrorMessages, fieldModifiedMessages, isDisabled } = this.props;

    const key = `${field.type}_${field.id}`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const values = this.getValues(field);
    const options = this.getTemplates(field);

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        description={ field.description }
        label={ field.label }
        required={ field.config.required }
        errors={ errors }
        isModified={ isModified }
        border
      >
        <Select
          allowClear={ !isDisabled && !field.config.required }
          className={ classNames('Select-Field Dynamic-Field-Select', {
            'Select-Field--has-warning border-warning': isModified,
            'ant-select-disabled': isDisabled
          }) }
          dropdownClassName={ classNames('Dynamic-Field-Select', {
            'ant-select-disabled': isDisabled
          }) }
          style={{ width: '100%' }}
          placeholder={ field.label }
          value={ values }
          onChange={ (option: any) => {

            if (isDisabled) return;

            if (!option) {
              this.handleChange(field, null, config);
            } else {
              this.handleChange(field, [{ 'cost_template_id': option }], config);
            }
          } }
        >
          { options.map(( option: any) => (
            <Option key={ option.id } value={ option.id }>
              { option.title }
            </Option>
          ) ) }
        </Select>
      </FieldWrapper>
    );
  };

};

export default CostTemplate;
