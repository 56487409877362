// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

// Components
import { RestrictionHoC } from 'components/restriction';

// Views
import Dashboards from 'views/dashboard/Dashboards';
import Dashboard from 'views/dashboard/Dashboard';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';

interface Props extends RouteComponentProps {
  permissions: any;
};

class DashboardContainer extends React.Component<Props> {

  render = () => {
    return (
      <Switch>
        <Route exact path="/dashboards" component={ Dashboards } />
        <Route path="/dashboards/:dashboard_id" component={ Dashboard } />

        <Route render={ () => <NotFound /> } />
      </Switch>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(RestrictionHoC(DashboardContainer, 'access_dashboards'));