// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity } from 'types/entities';
import { UserPermissions } from 'types/permissions';

// Components
import Jumbotron from 'components/jumbotron';
import ServiceSpecificationScopeMatrix from "components/service-specification-scope/ServiceSpecificationScopeMatrix";
import ServiceSpecificationScopeList from "components/service-specification-scope/ServiceSpecificationScopeList";
import BlockingSpinner from 'components/blocking-spinner';

// Services
import { Api } from 'services/api';

// Actions
import {
  setSecondarySidebarCollapsed,
} from 'store/UI/ActionCreators';

// Utils
import history from 'utils/history';

const API: Api = new Api();

interface Props extends RouteComponentProps {
  record: RecordFormEntity;
  id: number;
  client_id: number;
  permissions: UserPermissions;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setSecondarySidebarCollapsed(value: boolean): void;
};

interface State {
  operations: any[];
  scopeRecord: RecordFormEntity | null,
  isLoading: boolean;
};

class ServiceSpecificationScope extends React.Component<Props> {

  mounted: boolean = false;
  component: any = React.createRef();

  state: State = {
    operations: [],
    scopeRecord: null,
    isLoading: false,
  };

  componentDidMount = async () => {
    const { client_id, id } = this.props;
    this.mounted = true;

    try {

      // Set loading
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      // Fetch
      const operations = await API.get(`client/${client_id}/record/service-specification/${id}/scope-matrix/bulk-operation`);

      this.setState({
        operations: operations,
      });

      if (!!this.props.match?.params?.scope_id) {
        this.props.setSecondarySidebarCollapsed(true);
      }

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render = () => {
    const { id, record } = this.props;
    const { operations, isLoading } = this.state;

    if (isLoading) {
      return <BlockingSpinner isLoading style={{ minHeight: '50vh' }} />;
    }

    return (
      <>
        { record &&
          <Jumbotron
            title={ this.props.record?.title }
            content={ 'Service Specification Scope' }
            destroyInactiveTabPane
            tabs={ [
              {
                label: 'List',
                node: (
                  <ServiceSpecificationScopeList
                    id={ id }
                    record={ record }
                    clientId={ this.props.client_id }
                    operations={ operations }
                    permissions={ this.props.permissions }
                    scopeId={ this.props.match?.params?.scope_id }
                    setSecondarySidebarCollapsed={ this.props.setSecondarySidebarCollapsed }
                  />
                ),
              },
              {
                label: 'Matrix',
                node: (
                  <ServiceSpecificationScopeMatrix
                    id={ id }
                    record={ record }
                    clientId={ this.props.client_id }
                    scopeId={ this.props.match?.params?.scope_id }
                    operations={ operations }
                  />
                ),
              }
            ] }
            onChange={ () => {
              if (!!this.props.match?.params?.scope_id) {
                history.push(`/workplace-services/service-specifications/${id}/scope`);
              }
            } }
          />
        }
      </>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSecondarySidebarCollapsed: (value: boolean) => dispatch(setSecondarySidebarCollapsed(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceSpecificationScope));
