// Libs
import React, { Component } from 'react';
import { Select, Input } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import {
  FormField,
  FormValues,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import Flag from 'components/flag';

// Interfaces
import { PhoneCountry } from './Phone.interface';

// Styles
import './Phone.scss';

interface Props {
  field: FormField;
  originalState: FormValues;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

const { Option } = Select;

class PhoneField extends Component<Props> {

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { field, originalState } = this.props;
    const columnKeys = Object.keys(field.columns);

    columnKeys.forEach((columnKey: string) => {
      this.generateModifiedState(originalState[columnKey], state[columnKey], columnKey, shouldClear);
      this.generateErrorState(state[columnKey], columnKey, shouldClear);
    });
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string, shouldClear = false) => {
    const { setFieldModifiedMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { setFieldErrorMessage, validate, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const errors = validate(field, columnKey, value);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  render = () => {
    const { field, config, fieldErrorMessages, fieldModifiedMessages, border, state, isDisabled, onChange } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const phoneNumberErrors = _.has(fieldErrorMessages, `${id}_${cardinality}_value`) ? fieldErrorMessages[`${id}_${cardinality}_value`].errors : [];
    const countryCodeErrors = _.has(fieldErrorMessages, `${id}_${cardinality}_country_code`) ? fieldErrorMessages[`${id}_${cardinality}_country_code`].errors : [];
    const countryCodeModified = _.has(fieldModifiedMessages, `${id}_${cardinality}_country_code`);
    const phoneNumberModified = _.has(fieldModifiedMessages, `${id}_${cardinality}_value`);

    const countryCodes = field.countries ? field.countries : [];
    const countryCode: string | undefined = state.country_code || '';

    if (!!isDisabled) {
      const country = _.has(state, 'country_code') && countryCodes.find((country: any) => country.code === state.country_code);
      return (
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          border={ border }
          versionChanged={ !!field.config.version_changed }
          description={ !!field.description && field.description }
        >
          <div className="w-100p d-f">
            <div className="Phone-Field-Text pY-5">
              { state && state.country_code ? (
                <>
                  <Flag code={ state.country_code.toLowerCase() } />
                  <span className="va-m mL-5">
                    { country && (
                      <span>{ `+${country.dial_code} ` }</span>
                    ) }
                    <span>{ `${state.value}` }</span>
                  </span>
                </>
              ) : (
                <span>-</span>
              ) }
            </div>
          </div>
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ !_.isEmpty(countryCodeErrors) ? countryCodeErrors : !_.isEmpty(phoneNumberErrors) ? phoneNumberErrors : [] }
        required={ field.config.required }
        border={ border }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <div className="w-100p">
          <div className="Phone-Field-Select">
            <Select
              showSearch
              className={ classNames('Select-Field', {
                'border-warning Select-Field--has-warning': countryCodeModified && _.isEmpty(countryCodeErrors),
              }) }
              dropdownMatchSelectWidth={ false }
              optionLabelProp={ 'label' }
              onChange={ (code: string) => onChange(field, code, config, 'country_code') }
              placeholder={ field.columns.country_code.label }
              filterOption={(input: any, option: any): any => {
                return countryCodes.find((country: any) => country.code.toLowerCase() === option.value.toLowerCase() && country.title.toLowerCase().includes(input.toLowerCase()) );
              } }
              value={ countryCode }
              disabled={ isDisabled }
            >
              { countryCodes
                .sort((a: any, b: any) => a.title.localeCompare(b.title))
                .map((country: PhoneCountry) => {
                  return (
                    <Option key={ country.id } value={ country.code } label={ <span><Flag code={ country.code.toLowerCase() } /><span className="va-m mL-5">{ `+${country.dial_code}` }</span></span> }>
                      <Flag code={ country.code.toLowerCase() } /><span className="va-m mL-5">{ `${country.title} +${country.dial_code}` }</span>
                    </Option>
                  );
                })
              }
            </Select>
          </div>
          <div className="Phone-Field-Text">
            <Input
              key={ JSON.stringify(state.value) } // Force rerender if states been manipulated (needed because we're using defaultValue)
              className={classNames('Field', {
                'border-warning Field--has-warning': phoneNumberModified && _.isEmpty(phoneNumberErrors),
              })}
              onBlur={ (e: React.ChangeEvent<HTMLInputElement>) => onChange(field, e.target.value, config, 'value') }
              placeholder={ field.columns.value.label }
              required={ field.config.required }
              defaultValue={ state.value }
              disabled={ isDisabled }
              prefix={ <span className="op-50p">(0)</span> }
            />
          </div>
        </div>
      </FieldWrapper>
    );
  };
};

export default PhoneField;
