// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Table, Tooltip, Typography } from 'antd';
import CoverModal from 'components/cover-modal';
import BlockingSpinner from 'components/blocking-spinner';
import OverlaySpinner from 'components/overlay-spinner';
import Dropdown, { Action } from 'components/dropdown';
import UploadForm from './UploadForm';

// Services
import { getFormatedDate } from 'services/settings';
import { findFirst } from 'utils/utils';

// Icons
import {
  DownloadOutlined,
  FilePdfOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  FolderOutlined,
  FolderViewOutlined,
  InfoCircleOutlined,
  UploadOutlined
} from '@ant-design/icons';

// Interfaces
import { UploadFile } from 'antd/lib/upload/interface';
import { Folder, Document } from './UploadDialog.interfaces';

const { Link } = Typography;

interface Props {
  onClose: () => void;
  onUpload: (fileList: UploadFile[], folder: Folder, cb: () => void) => void;
  onDownload?: (keys: string[]) => void;
  documents: Document[];
  title?: string;
  uploadDialogTitle?: string;
  defaultFolderId?: number
  modalStyle?: React.CSSProperties;
  isFetching?: boolean;
  isUploading?: boolean;
  isDownloading?: boolean;
};

interface State {
  selectedRowKeys: string[];
  showUploadDialog: boolean;
};

class UploadDialog extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    selectedRowKeys: [],
    showUploadDialog: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  getFileCount = (folders: any = []) => {
    let count = 0;
    folders && folders.forEach((folder: any) => {
      if (folder.type === 'folder') {
        count += this.getFileCount(folder.children);
      } else {
        count++;
      }
    });
    return count;
  };

  getFilteredNestedSet = (nestedSet: any = [], selectedRowKeys: any) => {
    return !_.isEmpty(nestedSet) ? nestedSet
      .filter((entity: any) => {
        return selectedRowKeys.some((rowKey: string) => {
          return findFirst(entity, 'children', { key: rowKey });
        });
      })
      .map((entity: any) => {
        return {
          ...entity,
          'children': this.getFilteredNestedSet(entity.children, selectedRowKeys),
        };
      }) : [];
  };

  renderUploadDialog = () => {
    const { uploadDialogTitle, documents, onUpload } = this.props;

    return (
      <CoverModal
        style={{ minWidth: '800px', minHeight: '500px', maxHeight: '90vh' }}
        middleContent={ uploadDialogTitle || 'Upload' }
        onClose={ () => this.mounted && this.setState({ showUploadDialog: false }) }
      >
        <UploadForm
          onSave={ (fileList: UploadFile[], folder: Folder) => {
            onUpload(fileList, folder, () => this.mounted && this.setState({ showUploadDialog: false }));
          } }
          documents={ documents || [] }
          defaultFolderId={ documents.length === 1 ? documents[0].id  : undefined}
        />
      </CoverModal>
    );
  };

  renderDocumentsList = (documents: Document[], actions: Action[]) => {
    const columns = [
      {
        title: 'Name',
        key: 'title',
        dataIndex: 'title',
        render: (__: any, record: Document) => {

          let icon = <FileUnknownOutlined className="fsz-md" />;

          switch (record.type) {
            case 'folder':
              if (_.has(record, 'config.view_only') && !!record.config.view_only) {
                icon = <FolderViewOutlined className="fsz-md" />;
              } else {
                icon = <FolderOutlined className="fsz-md" />;
              }
              break;
            case 'pdf':
              icon = <FilePdfOutlined className="fsz-md" />;
              break;
            case 'docx':
              icon = <FileWordOutlined className="fsz-md" />;
              break;
          }

          return (
            <span>
              <span className="va-m" style={{ fontSize: 17 }}>{ icon }</span>
              { record.type !== 'folder' ? (
                <span className="mL-10 va-m"><Link>{ record.title }</Link></span>
              ) : (
                <span className="mL-10 va-m">{ `${record.title} (${this.getFileCount(record.children)})`}</span>
              ) }
              { !!record.version_changed &&
                <Tooltip
                  className="mL-5 va-m"
                  placement="top"
                  title={ 'This document has been modified since the previous version' }
                >
                  <InfoCircleOutlined className="fsz-def text-warning" />
                </Tooltip>
              }
            </span>
          );
        },
      },
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        render: (type: string) => {
          return (
            <>{ type === 'folder' ? '-' : type }</>
          );
        }
      },
      {
        title: 'Size',
        key: 'size',
        dataIndex: 'size',
      },
      {
        title: 'Modified',
        key: 'modified',
        dataIndex: 'modified',
        render: (__: any, record: Document) => {
          const date = getFormatedDate(record.updated_at);
          return (
            <div onClick={ (e: React.BaseSyntheticEvent) => e.stopPropagation() }>
              <span>{ date }</span>
              { _.has(record, 'updated_by.fullname') &&
                <span> by <Link>{ record.updated_by.fullname }</Link></span>
              }
            </div>
          );
        }
      },
      {
        title: <Dropdown actions={ actions } />,
        key: 'actions',
        dataIndex: 'actions',
        align: 'right' as 'right',
      },
    ];

    return (
      <div className="p-15">
        <Table
          size={ 'small' }
          columns={ columns }
          dataSource={ documents }
          pagination={ false }
          rowClassName={ (record: Document) => {
            return _.isEmpty(record.children) ? 'us-n' : 'us-n cur-p';
          }}
          expandable={{
            expandRowByClick: true,
            defaultExpandedRowKeys: !_.isEmpty(documents) ? [documents[0]?.key] : []
          }}
          rowSelection={{
            checkStrictly: false,
            onChange: (selectedRowKeys: any) => {
              const filteredNestedSet = this.getFilteredNestedSet(documents, selectedRowKeys);
              this.setState({ selectedRowKeys: filteredNestedSet });
            }
          }}
        />
      </div>
    );
  };

  render = () => {
    const { documents, title, modalStyle, isFetching, isUploading, isDownloading, onClose, onDownload } = this.props;
    const { selectedRowKeys, showUploadDialog } = this.state;

    const actions = [
      {
        onClick: () => this.setState({ showUploadDialog: true }),
        node: <><UploadOutlined /><span className="mL-10">Upload</span></>
      },
      {
        disabled: !onDownload || _.isEmpty(selectedRowKeys),
        isLoading: isDownloading,
        onClick: () => onDownload?.(selectedRowKeys),
        node: <><DownloadOutlined /><span className="mL-10">Download</span></>
      },
    ];

    return (
      <CoverModal
        style={{ minWidth: '800px', minHeight: '500px', maxHeight: '90vh', ...modalStyle }}
        middleContent={ title || 'Upload' }
        onClose={ () => !isUploading && onClose() }
      >
        <BlockingSpinner isLoading={ !!isFetching } style={{ minHeight: '40vh' }}>
          { this.renderDocumentsList(documents, actions) }
          { showUploadDialog && this.renderUploadDialog() }
        </BlockingSpinner>
        { isUploading && <OverlaySpinner /> }
      </CoverModal>
    );
  };
}

export default UploadDialog;