enum NotificationActionTypes {
  GET_NOTIFICATIONS_START = "GET_NOTIFICATIONS_START",
  GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILED = "GET_NOTIFICATIONS_FAILED",
  GET_NOTIFICATIONS_COUNT_START = "GET_NOTIFICATIONS_COUNT_START",
  GET_NOTIFICATIONS_COUNT_SUCCESS = "GET_NOTIFICATIONS_COUNT_SUCCESS",
  GET_NOTIFICATIONS_COUNT_FAILED = "GET_NOTIFICATIONS_COUNT_FAILED",
  READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS",
  DELETE_NOTIFICATIONS_START = "DELETE_NOTIFICATIONS_START",
  DELETE_NOTIFICATIONS_SUCCESS = "DELETE_NOTIFICATIONS_SUCCESS",
  DELETE_NOTIFICATIONS_FAILED = "DELETE_NOTIFICATIONS_FAILED",
  POLL_NOTIFICATIONS_START = "POLL_NOTIFICATIONS_START",
  POLL_NOTIFICATIONS_END = "POLL_NOTIFICATIONS_END",
};

export default NotificationActionTypes;