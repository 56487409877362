// Libs
import * as React from 'react';
import classNames from 'classnames';

// Components
import CoverModal from 'components/cover-modal';
import Icon from '@ant-design/icons';
import BlockingSpinner from 'components/blocking-spinner';
import { Button } from 'antd';

// Services
import { Api } from 'services/api';

// Icons
import { ReactComponent as AvatarIcon } from 'assets/svg/avatar.svg';

// Styles
import './ProfileCard.scss';

interface Props {
  clientId: number;
  userId: number;
  canMasquerade: boolean;
  onMasquerade(): void;
  onClose(): void;
};

interface State {
  user: any;
  isLoading: boolean;
};

const API: Api = new Api();

class ProfileCard extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    user: null,
    isLoading: false,
  };

  componentDidMount = async () => {
    const { clientId, userId } = this.props;

    this.mounted = true;

    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));

      const user = await API.get(`client/${clientId}/user/${userId}`);

      this.mounted && this.setState({
        user: user
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render = () => {
    const { onClose, onMasquerade, canMasquerade } = this.props;
    const { user, isLoading } = this.state;
    return (
      <CoverModal
        closeOnTop
        style={{ width: 350, height: 460 }}
        onClose={ onClose }
      >
        <div
          className={ classNames('d-f jc-c mH-380', {
            'ai-c': isLoading
          }) }
        >
          <BlockingSpinner isLoading={ isLoading }>
            { user &&
              <div className="ProfileCard">
                <div className='mT-10'>
                  <Icon style={{ fontSize: '100px' }} component={ AvatarIcon } />
                </div>
                <div className='mT-20'>
                  <h3 className="mB-0">{ user.name }</h3>
                </div>
                <div>
                  <p>{ user.company }</p>
                </div>
                { canMasquerade &&
                  <div className='mT-10'>
                    <Button
                      shape="round"
                      size='small'
                      onClick={ onMasquerade }
                    >
                      Masquerade
                    </Button>
                  </div>
                }
                <div className='bdT ta-l mT-20'>
                  <div className="mT-20">
                    <div className="mT-10">
                      <small>Email Address</small>
                      <p
                        className={ classNames('fw-500', {
                          'text-blur': !user.email
                        }) }
                      >
                        { !user.email ? 'Lorem ipsum dolar' : user.email }
                      </p>
                    </div>
                    <div className="mT-10">
                      <small>Phone</small>
                      <p
                        className={ classNames('fw-500', {
                          'text-blur': !user.phone
                        }) }
                      >
                        { !user.phone ? 'Lorem ipsum dolar' : user.phone }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
          </BlockingSpinner>
        </div>
      </CoverModal>
    );
  };
};

export default ProfileCard;
