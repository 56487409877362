// Libs
import * as React from 'react';

// Components
import RecordView from 'views/common/RecordView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  getRecord(silent?: boolean): void;
  setRecord: (record: RecordFormEntity) => void;
};

class ScheduledTicketRecord extends React.Component<Props> {
  render = () => {
    return (
      <RecordView
        verboseTabs={ false }
        id={ this.props.record.id }
        entity={ 'help-desk-ticket' }
        type={ 'scheduled-ticket' }
        record={ this.props.record }
        getRecord={ this.props.getRecord }
        setRecord={ this.props.setRecord }
      />
    );
  };
};

export default ScheduledTicketRecord;
