// Libs
import React, { Component } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';

// Component
import FieldWrapper from 'components/form/field/field-wrapper';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormValues,
  AreaUnit,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
} from 'components/form/form-wrapper';

interface Props {
  field: FormField;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  originalState: FormValues;
  state: FormValues;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

const { Option } = Select;

class AreaUnitField extends Component<Props> {

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { field, originalState } = this.props;
    const columnKeys = Object.keys(field.columns);

    columnKeys.forEach((columnKey: string) => {
      this.generateModifiedState(originalState[columnKey], state[columnKey], columnKey, shouldClear);
      this.generateErrorState(state[columnKey], columnKey, shouldClear);
    });
  };

  generateLabel = (value: string | number, units: AreaUnit[]): string => {
    const unit = units.find((unit: AreaUnit) => unit.unit === value);

    if (!!unit) {
      return `${unit.title} (${unit.unit})`;
    }

    return '';
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;
    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {

      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { field, config, validate, setFieldErrorMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const errors = validate(field, columnKey, value);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: errors
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  render = () => {
    const { field, config, state, fieldErrorMessages, fieldModifiedMessages, border, onChange, isDisabled } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_unit`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const units = field.units || [];
    const value = state.unit || '';

    if (!!isDisabled) {
      return (
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          border={ border }
          versionChanged={ !!field.config.version_changed }
          description={ !!field.description && field.description }
        >
          <span className="d-b pY-5">{ this.generateLabel(value, units) }</span>
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ errors || [] }
        required={ field.config.required }
        border={ border }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <Select
          showSearch
          className={classNames('Select-Field', {
            'border-warning Select-Field--has-warning': isModified && _.isEmpty(errors),
          })}
          allowClear={ !field.config.required }
          onSelect={ (__: any, option: any) => onChange(field, option.value, config, 'unit') }
          onClear={ () => onChange(field, null, config) }
          placeholder={ field.label }
          value={ this.generateLabel(value, units) || undefined }
          disabled={ isDisabled }
          filterOption={(input: string, option) => {
            const index = option?.value || 0;
            const unitOption = units[index];

            if (unitOption && unitOption.title) {
              const filteredInput = input.toLocaleLowerCase();
              const title = unitOption.title.toLowerCase();
              const unit = unitOption.unit.toLowerCase();
              const label = this.generateLabel(unit, units);
              const simplifiedLabel = `${unit} ${title} ${unitOption.symbol}`;

              if (
                title.includes(filteredInput) ||
                unit.includes(filteredInput) ||
                label.includes(filteredInput) ||
                simplifiedLabel.includes(filteredInput) ||
                (unitOption.symbol && unitOption.symbol.includes(filteredInput))
              ) {
                return true;
              }
            }

            return false;
          }}
        >
          { units.map((unit: AreaUnit) => {
            return (
              <Option
                key={ `${field.id}-list-area-option-${unit.unit}` }
                value={ unit.unit }
              >
                { this.generateLabel(unit.unit, units) }
              </Option>
            );
          }) }
        </Select>
      </FieldWrapper>
    );
  };

};

export default AreaUnitField;
