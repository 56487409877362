// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Slider, Tooltip, Checkbox } from 'antd';
import ActionWrapper from 'components/form/field/dynamic/common/ActionWrapper';
import { getActionControls } from 'components/form/field/dynamic/common/ActionControls';
import { getIconComponent } from 'views/admin/content-manager/slider-range/SliderRangeOptions';

// Interfaces
import { DynamicField } from 'components/form/field/dynamic/Dynamic.interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

// Icons
import Icon from '@ant-design/icons';

// Styles
import 'components/form/field/slider/Slider.scss';

interface Props {
  clientId: number;
  dynamicField: DynamicField;
  originalDynamicField: DynamicField;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  isLocked: boolean;
  isPreviewing: boolean;
  extensions: string[];
  setFieldModifiedMessage(id: string, message?: any): void;
  setFieldErrorMessage(id: string, message?: any): void;
  onChange(field: DynamicField): void;
  onComment(comment: string | null): void;
  onUpload(fieldRecord: any, callback?: () => void): void;
  onRemove(field: DynamicField): void;
  onCreateAction(field: DynamicField): void;
  onScore(field: DynamicField): void;
};

interface State {
  width: number;
  height: number;
  showComment: boolean;
  showAttachments: boolean;
  showActions: boolean;
  showScoring: boolean;
};

class SliderField extends Component<Props, State> {

  component: any = React.createRef();

  state: State = {
    width: 0,
    height: 0,
    showComment: !!this.props.dynamicField?.attachments?.comment,
    showAttachments: !!this.props.dynamicField?.attachments?.files.length,
    showActions: !!this.props.dynamicField?.action_list?.data.length,
    showScoring: !!this.props.dynamicField?.scoring?.value,
  };

  componentDidMount = () => {
    if (!this.props.isLocked) {
      this.validate(this.props.dynamicField);
    }

    if (this.component) {
      this.widthObserver();
      this.heightObserver();
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    const { dynamicField } = this.props;

    if (!_.isEqual(prevProps.dynamicField, dynamicField)) {
      this.validate(dynamicField);
    }

    if (this.component) {
      this.widthObserver();
      this.heightObserver();
    }
  };

  widthObserver = () => {
    const width: number = this.component?.current?.clientWidth || 0;

    if (this.state.width !== width) {
      this.setState({
        width: width
      });
    }
  };

  heightObserver = () => {

    const elements = Array.from(document.querySelectorAll('.ant-slider-mark-text'));
    let height = 0;

    elements.forEach((element) => {
      height = height + (element?.clientHeight + 50) || 200;
    });

    if (this.state.height !== height) {
      this.setState({
        height: height
      });
    }
  };

  validate = (dynamicField: DynamicField) => {
    const { originalDynamicField } = this.props;

    this.generateModifiedState(originalDynamicField, dynamicField);
  };

  generateModifiedState = (pastField: DynamicField, newField: DynamicField) => {
    const { dynamicField, setFieldModifiedMessage } = this.props;

    const id = dynamicField.id;
    const ref = dynamicField.reference;
    const key = `${id}_${ref}`;

    if (!_.isEqual(pastField, newField)) {
      const message = {
        id: id,
        cardinality: 0,
        content: {
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  getValue = (dynamicField: DynamicField) => {
    return !_.isEmpty(dynamicField.values) ? dynamicField.values[0]['value'] : 0;
  };

  getOptions = (dynamicField: DynamicField) => {
    return !_.isEmpty(dynamicField.options) ? dynamicField.options : [];
  };

  getRangeOptions = (dynamicField: DynamicField) => {
    const values: number[] = dynamicField.options.map(option => option.value);
    const placeholder = {
      min: Math.min(...values),
      max: Math.max(...values),
      step: '1.00',
    };
    return !_.isEmpty(dynamicField.range_options) ? dynamicField.range_options : placeholder;
  };

  handleChange = (value: any) => {
    this.props.onChange(_.set(_.cloneDeep(this.props.dynamicField), ['values'], [{ value: value }]));
  };

  handleFieldChange = (dynamicField: any) => {
    this.props.onChange(_.cloneDeep(dynamicField));
  };

  render = () => {
    const { clientId, dynamicField, fieldErrorMessages, fieldModifiedMessages, isLocked, isPreviewing, extensions, onComment, onUpload, onRemove, onCreateAction, onScore } = this.props;
    const { width, height, showComment, showAttachments, showActions, showScoring } = this.state;

    if (!_.has(dynamicField, 'options')) return <></>;

    const key = `${dynamicField.id}_${dynamicField.reference}`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const isVertical = dynamicField?.config?.vertical;
    const options = this.getOptions(dynamicField);
    const rangeOptions = this.getRangeOptions(dynamicField);
    const marks: any = {};

    const value = this.getValue(dynamicField);
    const { min, max, step } = rangeOptions;

    options.forEach((option: any, index: number) => {

      const iconComponent: any = option?.icon?.file && getIconComponent(option?.icon?.file);

      const title = iconComponent ? (
        <Icon style={{ fontSize: 25 }} component={ iconComponent } />
      ) : (
        isVertical ? (
          <span className="d-f ta-l whs-n" style={{ width: width - 100 || 300 }}>
            { option.title }
          </span>
        ) : (
          <span>
            { _.truncate(option.title, {
              'length': 70,
              'separator': /,? +/
            }) }
          </span>
        )
      );

      let label = title;

      if (!isVertical && _.has(option, 'description') && !!option.description) {
        label = (
          <Tooltip key={ index } title={ option.description } placement={ 'bottom' }>
            { title }
          </Tooltip>
        );
      }

      if (isVertical) {
        label = (
          <div>
            <b>{ title }</b>
            { !!option?.description &&
              <span className="d-f ta-l whs-n" style={{ width: width - 100 || 300 }}>
                { option.description }
              </span>
            }
          </div>
        );
      }

      marks[option.value] = {
        'text': option.title,
        'label': label,
      };
    });

    let rightActions: any = [];

    if (dynamicField.config?.can_mark_not_applicable) {
      rightActions.push({
        node: (
          <Tooltip
            placement="top"
            title={ 'Select N/A if the question is not Applicable' }
          >
            <span className="mL-10"> { 'N/A' } </span>
            <Checkbox
              className="mL-5"
              onChange={ (e: CheckboxChangeEvent) => {
                let _dynamicField = _.cloneDeep(dynamicField);
                _dynamicField.not_applicable = !!e.target.checked;
                this.handleFieldChange(_dynamicField);
              }}
              checked={ !!dynamicField?.not_applicable }
            />
        </Tooltip>
        )
      });
    }

    rightActions = rightActions.concat(getActionControls(dynamicField, this.state, (state: any) => this.setState(state), isPreviewing));

    const wrapperStyle: React.CSSProperties = {};

    if (isVertical) {
      wrapperStyle.paddingTop = height / (Object.keys(marks).length + 2),
      wrapperStyle.paddingBottom = height / (Object.keys(marks).length + 2),
      wrapperStyle.height = height;
    } else {
      wrapperStyle.paddingTop = 50;
      wrapperStyle.paddingBottom = 50;
      wrapperStyle.width = '80%';
    }

    return (
      <FieldWrapper
        description={ dynamicField.description }
        label={ dynamicField.label }
        required={ dynamicField.config.required }
        versionChanged={ !!dynamicField.config.version_changed }
        rightActions={ rightActions }
        errors={ errors }
        isModified={ isModified }
        border
      >
        { !!dynamicField?.not_applicable ? (
            <div> Not Applicable </div>
          ) : (
            <div ref={ this.component }>
              <div
                className={ classNames('d-f fxd-c', {
                  'ai-c jc-c': !isVertical,
                  'pL-50': isVertical
                }) }
                style={{ minHeight: 200 }}
              >
                <div style={ wrapperStyle }>
                  <Slider
                    key={ JSON.stringify(value) } // Force rerender if states been manipulated (needed because we're using defaultValue)
                    className={ classNames(`Slider-Field`, {
                      'is-modified': isModified && _.isEmpty(errors),
                    }) }
                    vertical={ isVertical }
                    reverse={ isVertical }
                    onAfterChange={ (value: any) => this.handleChange(parseFloat(value)) }
                    disabled={ isLocked || _.isEmpty(marks) }
                    marks={ marks }
                    min={ min }
                    max={ max }
                    step={ parseFloat(step) }
                    defaultValue={ parseFloat(value) }
                  />
                </div>
              </div>
              <ActionWrapper
                clientId={ clientId }
                dynamicField={ dynamicField }
                isLocked={ isLocked }
                isPreviewing={ isPreviewing }
                showAttachments={ showAttachments }
                showComment={ showComment }
                showActions={ showActions }
                showScoring={ showScoring }
                hideScoring={ () => this.setState({ showScoring: false }) }
                extensions={ extensions }
                onComment={ onComment }
                onUpload={ onUpload }
                onRemove={ onRemove }
                onCreateAction={ onCreateAction }
                onScore={ onScore }
              />
            </div>
          )
        }
      </FieldWrapper>
    );
  };
};

export default SliderField;
