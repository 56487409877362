// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Select, Modal, Form, Input, Tooltip, Checkbox } from "antd";
import ActionWrapper from 'components/form/field/dynamic/common/ActionWrapper';
import { getActionControls } from 'components/form/field/dynamic/common/ActionControls';

// Interfaces
import { DynamicField } from 'components/form/field/dynamic/Dynamic.interface';
import { RecordFormEntity } from 'types/entities';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Icons
import { PlusCircleOutlined } from "@ant-design/icons";

interface Props {
  clientId: number;
  record?: RecordFormEntity;
  dynamicField: DynamicField;
  originalDynamicField: DynamicField;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  isLocked: boolean;
  isPreviewing: boolean;
  extensions: string[];
  setFieldModifiedMessage(id: string, message?: any): void;
  setFieldErrorMessage(id: string, message?: any): void;
  onChange(field: DynamicField): void;
  onComment(comment: string | null): void;
  onUpload(fieldRecord: any, callback?: () => void): void;
  onRemove(field: DynamicField): void;
  onCreateAction(field: DynamicField): void;
  onScore(field: DynamicField): void;
};

interface State {
  showCreateModal: boolean;
  isCreateLoading: boolean;
  showComment: boolean;
  showAttachments: boolean;
  showActions: boolean;
  showScoring: boolean;
};

const { Option } = Select;
const API: Api = new Api();

class SelectField extends Component<Props, State> {

  formRef: any = React.createRef();

  state: State = {
    showCreateModal: false,
    isCreateLoading: false,
    showComment: !!this.props.dynamicField?.attachments?.comment,
    showAttachments: !!this.props.dynamicField?.attachments?.files.length,
    showActions: !!this.props.dynamicField?.action_list?.data.length,
    showScoring: !!this.props.dynamicField?.scoring?.value,
  };

  componentDidMount = () => {
    if (!this.props.isLocked) {
      this.validate(this.props.dynamicField);
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    const { dynamicField } = this.props;

    if (!_.isEqual(prevProps.dynamicField, dynamicField)) {
      this.validate(dynamicField);
    }
  };

  validate = (dynamicField: DynamicField) => {
    const { originalDynamicField } = this.props;

    this.generateModifiedState(originalDynamicField, dynamicField);
  };

  generateModifiedState = (pastField: DynamicField, newField: DynamicField) => {
    const { dynamicField, setFieldModifiedMessage } = this.props;

    const id = dynamicField.id;
    const ref = dynamicField.reference;
    const key = `${id}_${ref}`;

    if (!_.isEqual(pastField, newField)) {
      const message = {
        id: id,
        cardinality: 0,
        content: {
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  getValues = (dynamicField: DynamicField) => {
    return !_.isEmpty(dynamicField.values) ? dynamicField.values.map((value: any) => value.option_id ) : [];
  };

  getOptions = (dynamicField: DynamicField) => {
    return !_.isEmpty(dynamicField.options) ? dynamicField.options : [];
  };

  handleChange = (values: any) => {
    if (_.isEmpty(values)) {
      this.props.onChange(_.set(_.cloneDeep(this.props.dynamicField), ['values'], []));
    } else {
      this.props.onChange(_.set(_.cloneDeep(this.props.dynamicField), ['values'], values));
    }
  };

  handleFieldChange = (dynamicField: any) => {
    this.props.onChange(_.cloneDeep(dynamicField));
  };

  renderCreateOptionModal = () => {
    const { clientId, record, dynamicField, onChange } = this.props;
    const { isCreateLoading } = this.state;
    return (
      <Modal
        centered
        visible
        closable={ false }
        title={ 'Add Option' }
        onCancel={ () => this.setState({ showCreateModal: false }) }
        okText={ 'Ok' }
        okButtonProps={{
          loading: isCreateLoading
        }}
        onOk={ async () => {
          this.formRef
            .current
            .validateFields()
            .then( async (values: any) => {
              try {

                await new Promise((resolve) => this.setState({ isCreateLoading: true }, () => resolve(null)));

                const options = await API.post(`client/${clientId}/field/dynamic/${dynamicField.id}/create_option`, {
                  entity_id: record?.id,
                  entity_type: record?.type,
                  entity_bundle: record?.bundle,
                  option: values.option
                });

                const option = options.find((option: any) => option.option === values.option);

                onChange({
                  ...dynamicField,
                  options: options,
                  values: [{
                    option_id: option.id
                  }]
                });

                Notification('success', 'Option Created', 'Created');

              } catch (error: any) {
                Notification('error', error.data, 'Failed');
              } finally {
                this.setState({
                  showCreateModal: false,
                  isCreateLoading: false,
                });
              }
            })
            .catch((info: any) => {
              console.error('Invalid state');
            });
          }
        }
        style={{ minWidth: 500 }}
      >
        <Form
          ref={ this.formRef }
          layout="vertical"
        >
          <Form.Item
            label="New option"
            name="option"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  render = () => {
    const { clientId, dynamicField, record, fieldErrorMessages, fieldModifiedMessages, isLocked, isPreviewing, extensions, onComment, onUpload, onRemove, onCreateAction, onScore } = this.props;
    const { showCreateModal, isCreateLoading, showComment, showAttachments, showActions, showScoring } = this.state;

    const key = `${dynamicField.id}_${dynamicField.reference}`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const values = this.getValues(dynamicField);
    const options = this.getOptions(dynamicField);
    const cardinality = _.has(dynamicField, 'config.cardinality') ? dynamicField.config.cardinality : 0;
    const canInlineCreate = _.has(dynamicField, 'config.can_inline_create') && dynamicField.config.can_inline_create;

    let rightActions: any = [];

    if (!!record && canInlineCreate) {
      rightActions.push({
        node: (
          <PlusCircleOutlined
            className={ classNames('fsz-def text-ant-default', {
              'text-ant-disabled': isLocked || isPreviewing,
              'cur-na': isLocked || isPreviewing,
            }) }
            onClick={ () => !isLocked && !isPreviewing && this.setState({ showCreateModal: true }) }
          />
        ),
        isLoading: isCreateLoading,
      });
    }

    if (dynamicField.config?.can_mark_not_applicable) {
      rightActions.push({
        node: (
          <Tooltip
            placement="top"
            title={ 'Select N/A if the question is not Applicable' }
          >
            <span className="mL-10"> { 'N/A' } </span>
            <Checkbox
              className="mL-5"
              onChange={ (e: CheckboxChangeEvent) => {
                let _dynamicField = _.cloneDeep(dynamicField);
                _dynamicField.not_applicable = !!e.target.checked;
                this.handleFieldChange(_dynamicField);
              }}
              checked={ !!dynamicField?.not_applicable }
            />
        </Tooltip>
        )
      });
    }

    rightActions = rightActions.concat(getActionControls(dynamicField, this.state, (state: any) => this.setState(state), isPreviewing));

    return (
      <>
        <FieldWrapper
          description={ dynamicField.description }
          label={ dynamicField.label }
          required={ dynamicField.config.required || dynamicField.config?.required }
          versionChanged={ !!dynamicField.config.version_changed }
          rightActions={ rightActions }
          errors={ errors }
          isModified={ isModified }
          border
        >
          { !!dynamicField?.not_applicable ? (
              <div> Not Applicable </div>
            ) : (
              <div>
                <Select
                  allowClear={ !isLocked && !dynamicField.config.required }
                  mode={ !!cardinality || dynamicField?.config?.allow_multiselect ? 'multiple' : undefined }
                  className={ classNames('Select-Field Dynamic-Field-Select', {
                    'Select-Field--has-warning border-warning': isModified,
                    'ant-select-disabled': isLocked
                  }) }
                  dropdownClassName={ classNames('Dynamic-Field-Select', {
                    'ant-select-disabled': isLocked
                  }) }
                  style={{ width: '100%' }}
                  placeholder={ dynamicField.label }
                  value={ values }
                  onChange={ (options: any) => {
                    if (isLocked) return;

                    if (!!cardinality || dynamicField?.config?.allow_multiselect) {
                      // Multiples
                      this.handleChange(options.map((option: any) => {
                        return { option_id: option };
                      }));
                    } else {
                      // Single
                      if (!options) {
                        this.handleChange([]);
                      } else {
                        this.handleChange([{ option_id: options }]);
                      }
                    }
                  } }
                >
                  { options.map(( option: any) => (
                    <Option key={ option.id } value={ option.id }>
                      { option.option }
                    </Option>
                  ) ) }
                </Select>
                <ActionWrapper
                  clientId={ clientId }
                  dynamicField={ dynamicField }
                  isLocked={ isLocked }
                  isPreviewing={ isPreviewing }
                  showAttachments={ showAttachments }
                  showComment={ showComment }
                  showActions={ showActions }
                  showScoring={ showScoring }
                  hideScoring={ () => this.setState({ showScoring: false }) }
                  extensions={ extensions }
                  onComment={ onComment }
                  onUpload={ onUpload }
                  onRemove={ onRemove }
                  onCreateAction={ onCreateAction }
                  onScore={ onScore }
                />
              </div>
            )
          }
        </FieldWrapper>
        { showCreateModal && this.renderCreateOptionModal() }
      </>
    );
  };

};

export default SelectField;
